import * as React from "react";
import Box from "@mui/material/Box";
import CustomButton from "../../../components/CustomButton";
import Typography from "@mui/material/Typography";
import { Grid, Divider } from "@mui/material";
import Modal from "@mui/material/Modal";
import { updateDefaultUsergroup } from "./queries";
import Toaster from "../../../utils/Toaster";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 420,
  bgcolor: "background.paper",
  boxShadow: "0px 0px 5px rgba(31, 65, 115, 0.1)",
  borderRadius: "10px",
  textAlign: "center",
  p: 4,
};

export function SetDefaultModal({ data, open, handleClose }) {
  const setDefaultToBackend = () => {
    updateDefaultUsergroup(data.id).then((res) => {
      Toaster("success", "기본값으로 설정 완료되었습니다.");
      window.location.reload();
    });
  };

  return (
    <div disable={data === undefined ? true : false}>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            sx={{ mt: 2 }}
            style={{
              textAlign: "center",
              fontWeight: "bold",
              fontSize: "20px",
            }}
          >
            해당 그룹을 기본값으로
            <br />
            설정하시겠습니까?
          </Typography>
          <Divider sx={{ mt: 8 }} />
          <Grid container sx={{ justifyContent: "right" }}>
            <CustomButton
              width="100px"
              style={{
                marginTop: "30px",
                borderRadius: "10px",
                backgroundColor: "#5B8FF7",
              }}
              onClick={setDefaultToBackend}
            >
              설정
            </CustomButton>
            <CustomButton
              width="100px"
              onClick={handleClose}
              style={{
                marginTop: "30px",
                borderRadius: "10px",
                marginLeft: "10px",
                backgroundColor: "#F8F8FA",
                color: "#000000",
              }}
            >
              취소
            </CustomButton>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}
