import React from "react";
import {
	Page,
	Text,
	View,
	Document,
	StyleSheet,
	Image,
	Svg,
	Rect,
	Font,
} from "@react-pdf/renderer";

import UZPostIcon from "../../Common/assets/Images/UZ-Post-Icon.JPG";
import Signature from "../../Common/assets/Images/signature-icon.JPG";
import PostNLPB1 from "../../Common/assets/Images/PostNL_PB1.jpg";
import phoneSymbol from "../../Common/assets/Images/phone-symbol.png";
import Scan from "../../Common/assets/Images/Scan.jpg";
import ReturnLogo from "../../Common/assets/Images/Return-Logo.jpg";
import moment from "moment";
import PackageTable from "./PackageTable";

export const UZPostR = (props) => {
	const { barcode, selectedRequests } = props;
	// console.log("barcode", barcode, "selectedRequests", selectedRequests);
	let { tableData, ...item1 } = selectedRequests;
	// console.log(props);

	const calculateTotal = (products) => {
		let total = 0;
		products.forEach((item) => {
			let product = parseInt(item.quantity) * parseInt(item.unitPrice);

			total = total + product;
		});

		return total;
	};

	return (
		<>
			{tableData && <PackageTable tableData={tableData} />}
			<Page size="A6" orientation="landscape" style={styles.page}>
				<View style={styles.mainView}>
					<View style={styles.leftSec}>
						<View style={styles.sec1}>
							<View style={styles.sec11}>
								<View style={styles.bold}>
									<Text>CUSTOMS</Text>
									<Text>DECLARATION</Text>
									<Text>May be opened officially</Text>
									<View style={styles.row}>
										<Text>Designated operator</Text>
										<Text style={styles.blueConNoPadding}>
											Uzbekiston Pochtasi
										</Text>
									</View>
								</View>

								<View style={styles.postCode}>
									<Text>CN22</Text>
								</View>
							</View>
						</View>

						<View style={{ marginBottom: "-10px" }}>
							<View style={styles.rowNoSpaceMB}>
								<View style={styles.rowNoSpace}>
									<Svg viewBox="0 0 220 100">
										<Rect
											x="1"
											rx="2"
											ry="2"
											width="20"
											height="20"
											stroke="black"
										/>
									</Svg>
									<Text style={styles.ML40}>GIFT</Text>
								</View>

								{/* Comm Box -------------------- */}
								{/*  Empty box */}

								<View style={styles.rowNoSpace}>
									<Svg viewBox="0 0 220 100">
										<Rect
											x="1"
											rx="2"
											ry="2"
											width="20"
											height="20"
											stroke="black"
										/>
									</Svg>
									<Text style={styles.ML47}>Comm. sample</Text>
								</View>

								{/* Others -------------------- */}
								{/*  Empty box */}

								<View style={styles.rowNoSpace}>
									<Svg viewBox="0 0 220 100">
										<Rect
											x="1"
											rx="2"
											ry="2"
											width="20"
											height="20"
											stroke="black"
										/>
									</Svg>
									<Text style={styles.ML40}>Others</Text>
								</View>
							</View>

							<View style={styles.rowNoSpaceMT}>
								{/* Docs -------------------- */}
								{/*  Empty box */}

								<View style={styles.rowNoSpace}>
									<Svg viewBox="0 0 220 100">
										<Rect
											x="1"
											rx="2"
											ry="2"
											width="20"
											height="20"
											stroke="black"
										/>
									</Svg>
									<Text style={styles.ML40}>Docs</Text>
								</View>

								{/* Returned Goods -------------------- */}
								{/*  Empty box */}

								<View style={styles.rowNoSpace}>
									<Svg viewBox="0 0 220 100">
										<Rect
											x="1"
											rx="2"
											ry="2"
											width="20"
											height="20"
											stroke="black"
										/>
									</Svg>
									<Text style={styles.ML47}>Returned goods</Text>
								</View>

								{/* Sales of Goods -------------------- */}

								<View style={styles.rowNoSpace}>
									<Svg viewBox="0 0 220 100">
										<Rect
											x="1"
											rx="2"
											ry="2"
											width="20"
											height="20"
											stroke="black"
										/>
									</Svg>
									<Text style={styles.sOGCross}>X</Text>
									<Text style={styles.sOGAfterCross}>Sale of goods</Text>
								</View>
							</View>
						</View>

						<View style={styles.sec14}>
							<View style={styles.sec13}>
								<View>
									<Text style={styles.borderBottom}>
										Quantity and detailed description of contents
									</Text>
								</View>
								<View>
									{item1.productInformation?.productInformation
										?.slice(0, 3)
										.map((product, index) => (
											<Text key={index}>
												{product.productName.length > 25
													? product.productName.slice(0, 25).concat("...")
													: product.productName}
											</Text>
										))}
								</View>
							</View>
							<View style={styles.weightInKg}>
								<View>
									<Text style={styles.borderBottom}>Weight (in kg)</Text>
								</View>
								<View>
									{item1.productInformation?.productInformation
										?.slice(0, 3)
										.map((product, index) => (
											<Text key={index}>
												{item1.miscellaneousInformation?.applicableWeight}kg
											</Text>
										))}
								</View>
							</View>
							<View style={styles.valueInUSD}>
								<View>
									<Text style={styles.borderBottom}>Value (in USD)</Text>
								</View>
								<View>
									{item1.productInformation.productInformation
										?.slice(0, 3)
										.map((item, index) => (
											<Text key={index}>
												{item.unitPrice}
												{item1.productInformation?.currency === "USD"
													? "$"
													: "€"}
											</Text>
										))}
								</View>
							</View>
						</View>
						<View style={styles.sec14}>
							<View style={styles.sec13}>
								<View style={styles.borderBottom}>
									<Text>
										For commercial items only If known HS tariff number and
										country of origin of goods
									</Text>
								</View>
								<View style={styles.borderBottom}>
									{item1.productInformation?.productInformation
										?.slice(0, 3)
										.map((product) => (
											<Text>{product.hscode}</Text>
										))}
								</View>
							</View>
							<View style={styles.sec15}>
								<View style={styles.borderBottom}>
									<Text>Total Weight</Text>
								</View>
								<View>
									<Text>{item1.miscellaneousInformation?.applicableWeight}kg</Text>
								</View>
							</View>
							<View style={styles.sec15}>
								<View style={styles.borderBottom}>
									<Text>Total Value</Text>
								</View>
								<View>
									<Text>
										{calculateTotal(
											item1.productInformation.productInformation
										)}
										{item1.productInformation?.currency === "USD" ? "$" : "€"}
									</Text>
								</View>
							</View>
						</View>
						<View style={styles.sec14last}>
							<View>
								<Text>
									I, the undersigned whose name and address are given on the
									item, certify that the particulars given in this decleration
									are correct and that this item does not contain any dangerous
									article or articles prohibited by legislation or by custom
									regulations
								</Text>
								<Text style={styles.dateAndSender}>
									Date and Sender's signature
								</Text>

								<View style={styles.greenCon}>
									<Text>{item1.senderInformation?.name}</Text>
									<Text>{moment().format("YYYY-MM-DD")}</Text>
								</View>

								{/* <View style={styles.greenCon}>
                                                <Text>No :ABR000013435KR</Text>
                                                <Text>OrderNo:00300116275</Text>
                                                <Text>bb cream 50ml_ampoule 50ml</Text>
                                            </View> */}
							</View>
						</View>
					</View>
					<View style={styles.rightSec}>
						{/* <View style={styles.sec16}>
								        <Text>Return if undeliverable, Vilniaus logistikos centras, P.d. 5045, Metalo g. 5, 02190 Vilnius, LITHUANIA</Text>
							        </View> */}
						<View style={styles.from}>
							<View style={styles.fromLeft}>
								<View style={styles.fromAddress}>
									<Text style={styles.fromRotate}>FROM</Text>
									<View>
										<Text>Sender</Text>
										<Text>"Xalqaro Pochtamt" br.</Text>
										<Text>P.O. Box 0371</Text>
										<Text>Turkestanskaya Str. 4</Text>
										<Text>100015, TASHKENT</Text>
										<Text>Republic of Uzbekistan</Text>
									</View>
								</View>
							</View>

							<View style={styles.fromRight}>
								<View style={styles.row}>
									<Image style={styles.TrackImg} src={UZPostIcon} />
								</View>
								{/* <View>
                                                <Text style={styles.packetCon}>Packet Tracked</Text>
                                                <Text style={styles.standardText}>STANDARD</Text>                
                                            </View> */}
							</View>
						</View>
						<View style={styles.to}>
							<View style={styles.toLeft}>
								<Text>To</Text>
							</View>

							<View style={styles.toRight}>
								<View style={styles.row}>
									<Text>{item1?.payeeInformation?.name}</Text>
									<View style={styles.row}>
										<Image style={styles.phoneSym} src={phoneSymbol} />
										<Text>{item1?.payeeInformation?.phoneNumber1}</Text>
									</View>
								</View>

								<Text>{item1?.payeeInformation?.streetAddress1}</Text>
								{/* <Text>{item1?.payeeeInformation?.streetAddress2}</Text> */}
								<View style={styles.UzAddress}>
									<Text>{item1?.payeeInformation?.streetAddress2}</Text>
									<View style={styles.rowWithoutSpace}>
										<Text>{item1?.payeeInformation?.zipCode} </Text>
										<Text>{item1?.payeeInformation?.states} </Text>
										<Text>{item1?.payeeInformation?.cities} </Text>
									</View>
									<Text>
										{" "}
										{item1?.payeeInformation?.shippingCountry?.country_name_en}
									</Text>
								</View>
							</View>
							<View style={styles.countryCode}>
								<Text>RU</Text>
							</View>
						</View>
						<View style={styles.scanRow}>
							<View style={styles.Icon}>
								<Image style={styles.scanImg} src={Scan} />
								<Image style={styles.SignImg} src={Signature} />
							</View>
							<View>
								<Text>No: {item1.trackingNumber}</Text>
								<Text>OrderNo: {item1.productInformation?.orderNo1}</Text>
								{item1.productInformation?.productInformation
									?.slice(0, 3)
									.map((product) => (
										<Text>
											{product.productName} * {product.quantity}
										</Text>
									))}
							</View>
						</View>
						<View style={styles.greenCon}>
							<Text>{item1.senderInformation?.name}</Text>
							<Text>{moment().format("YYYY-MM-DD")}</Text>
							<View style={styles.row2}>
								<View style={styles.barcodeRow}>
									<View style={styles.countryCode}>
										<Text style={{ fontSize: "30px" }}>R</Text>
									</View>
									<View>
										<Image src={barcode} width="100%" height={50} />
									</View>
								</View>
								{/* <Text style={styles.shippingNo}>{item1.shippingNumber}</Text> */}
							</View>
						</View>
					</View>
				</View>
			</Page>
		</>
	);
};

const fRow = {
	diaplay: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
};
const fCol = {
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	justifyContent: "center",
};
Font.register({
	family: "SpoqaHanSans",
	src: "https://cdn.jsdelivr.net/gh/spoqa/spoqa-han-sans@01ff0283e4f36e159ffbf744b36e16ef742da6d8/Subset/SpoqaHanSans/SpoqaHanSansLight.ttf",
});
const styles = StyleSheet.create({
	page: {
		border: "2px solid #000",
		fontFamily: "SpoqaHanSans",

		fontSize: 6,
		padding: 10,
	},
	barcodeRow: {
		display: "flex",
		flexDirection: "row",
		alignItems: "flex-start",
		justifyContent: "center",
	},
	sec1: {
		display: "flex",
		flexDirection: "row",
		width: "100%",
		color: "#fff",
	},
	sec11: {
		backgroundColor: "white",
		padding: 2,
		color: "#000",
		borderBottom: "2px solid #000",
		display: "flex",
		flexDirection: "row",
		justifyContent: "flex-start",
		alignItems: "flex-start",
	},
	sec12: {
		...fCol,
		backgroundColor: "white",
		width: "65%",
		padding: 15,
		color: "red",
		fontWeight: 700,
		border: "2px solid #000",
	},
	sec13: {
		width: "50%",
		borderRight: "1px solid #000",
	},
	sec14: {
		backgroundColor: "white",
		color: "#000",
		borderBottom: "2px solid #000",
		display: "flex",
		flexDirection: "row",
	},
	sec14last: {
		backgroundColor: "white",
		color: "#000",

		display: "flex",
		flexDirection: "row",
	},
	sec15: {
		width: "25%",
		borderRight: "1px solid #000",
	},
	sec16: {
		borderBottom: "1px solid #000",
		fontSize: 6,
	},
	leftSec: {
		width: "40%",
		borderRight: "1px solid black",
	},
	rightSec: {
		width: "60%",
		color: "#000",
		backgroundColor: "#fff",
	},
	from: {
		display: "flex",
		flexDirection: "row",
		borderBottom: "1px solid black",
	},
	fromLeft: {
		width: "55%",
		borderRight: "1px solid black",
		// backgroundColor: "#afdcec",
		// borderRadius: 5,
		// border: "0.5px solid blue",
		marginLeft: 5,
		marginRight: 5,

		padding: 0,
	},
	fromRight: {
		// ...fRow,
		width: "45%",
		// backgroundColor: "#afdcec",
		// borderRadius: 5,
		// border: "0.5px solid blue",
		marginLeft: 5,
		// marginRight: 5,
		// marginBottom: 5,
	},
	to: {
		display: "flex",
		flexDirection: "row",
		borderBottom: "1px solid black",
	},
	toLeft: {
		...fCol,
		width: "15%",
		borderRight: "1px solid black",
		fontSize: 12,
	},
	toRight: {
		width: "85%",
		// backgroundColor: "#ddead1",
		// borderRadius: 5,
		// border: "0.5px solid blue",
		marginLeft: 5,
		marginRight: 5,
		marginBottom: 5,
		padding: 5,
	},
	Icon: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "flex-start",
	},
	mainView: {
		display: "flex",
		flexDirection: "row",
		width: "100%",
	},
	red: {
		color: "red",
	},
	sec2: {
		...fRow,
		color: "red",
		fontWeight: 700,
		padding: 10,
		alignItems: "center",
		justifyContent: "center",
		border: "2px solid #000",
	},
	sec3: {
		...fCol,
		alignItems: "flex-start",
		height: 170,
		paddingLeft: 15,
		paddingRight: 15,
		border: "2px solid #000",
	},
	sec3a: {
		...fRow,
	},
	sec4: {
		...fCol,
		height: 170,
		alignItems: "flex-start",
		paddingLeft: 15,
		paddingRight: 15,
		border: "2px solid #000",
	},
	sec5: {
		...fRow,
		width: "100%",
		paddingLeft: 15,
		paddingRight: 15,
	},
	sec6: {
		...fRow,
		width: "100%",
		paddingLeft: 15,
		paddingRight: 15,
		border: "2px solid #000",
	},
	sec6a: {
		...fCol,
		width: "100%",
		paddingLeft: 15,
		paddingRight: 15,
	},
	sec5a: {
		...fCol,
		justifyContent: "flex-start",
		alignItems: "flex-start",
		height: 200,
		border: "2px solid #000",
	},
	sec51: {
		...fCol,
		alignItems: "flex-start",
		justifyContent: "flex-start",
		width: "50%",
		height: 150,
		paddingLeft: 15,
		paddingRight: 15,
	},
	sec52: {
		...fCol,
		height: 150,
		width: "50%",
		justifyContent: "flex-start",
		alignItems: "flex-start",

		paddingLeft: 15,
		paddingRight: 15,
	},

	listItem: {
		...fRow,
		width: "100%",
		marginBottom: 10,
		gap: "20px",
		justifyContent: "flex-end",
		padding: "0 10px",
	},
	list: {
		...fRow,
		marginRight: 10,
	},
	listItemLabel: {},
	listItemValue: {
		color: "red",
	},
	line: {
		height: 1,
		backgroundColor: "black",
		marginBottom: 2,
	},
	body: {
		padding: 10,
	},
	row: {
		...fRow,
	},
	rowWithoutSpace: {
		...fRow,
		justifyContent: "flex-start",
	},
	row2: {
		...fCol,
		justifyContent: "center",
		alignItems: "center",
		marginBottom: 10,
		marginTop: 10,
	},
	row3: {
		...fCol,
	},
	center: {
		...fCol,
		marginTop: 25,
	},
	image: {
		maxWidth: 130,
		height: "auto",
	},
	postCode: {
		fontSize: 16,
	},
	border: {
		border: "1px solid black",
	},
	borderBottom: {
		borderBottom: "1px solid black",
	},
	borderTop: {
		borderTop: "1px solid black",
	},
	bold: {
		fontWeight: "bold",
	},
	greenCon: {
		//     backgroundColor: "#ddead1",
		//     borderRadius: 5,
		//     border: "0.5px solid blue",
		marginLeft: 5,
		marginRight: 5,
		padding: 5,
	},
	blueCon: {
		//     backgroundColor: "#afdcec",
		//     borderRadius: 5,
		//     border: "0.5px solid blue",
		marginLeft: 5,
		marginRight: 5,
		marginBottom: 5,
		padding: 5,
	},
	dateAndSender: {
		marginTop: 5,
		marginBottom: 3,
	},
	weightInKg: {
		...fCol,
		width: "25%",

		borderRight: "1px solid #000",
	},
	valueInUSD: {
		...fCol,
		width: "25%",
		borderRight: "1px solid #000",
	},

	col: {
		...fRow,
	},
	TrackImg: {
		// width: 50,
		// height: 50
	},
	PostNLImg: {
		// width: 150,
		// height: 70
	},
	phoneSym: {
		width: 8,
		height: 8,
	},
	scanImg: {
		width: 40,
		height: 40,
		// backgroundColor: "#afdcec",
		// borderRadius: 5,
		// border: "0.5px solid blue",
		// margin: 10,
		padding: 5,
	},
	returnLogoImg: {
		width: 15,
		height: 15,
	},
	blueConNoPadding: {
		//     backgroundColor: "#afdcec",
		//     border: "0.5px solid blue",
		marginLeft: 5,
		marginRight: 5,
		marginBottom: 5,
	},
	countryCode: {
		display: "flex",
		justifyContent: "center",
		fontSize: "30px",
		padding: 3,
	},
	fromAddress: {
		display: "flex",
		flexDirection: "row",
		fontSize: 5,
		marginRight: 12,
		marginBottom: 10,
	},
	fromRotate: {
		transform: "rotate(-90deg)",
		fontSize: 10,
		marginTop: 10,
	},
	standardText: {
		backgroundColor: "#000",
		color: "#fff",
		textAlign: "center",
		padding: 2,
	},
	packetCon: {
		textAlign: "center",
	},
	SignImg: {
		width: 30,
		height: 30,
		marginTop: 5,
	},
	rowNoSpace: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "flex-start",
		alignItems: "flex-start",
		alignContent: "flex-start",
	},
	ML40: {
		marginLeft: -40,
		marginTop: -1,
	},
	ML47: {
		marginLeft: -47,
		marginTop: -1,
	},
	rowNoSpaceMT: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "flex-start",
		alignItems: "flex-start",
		alignContent: "flex-start",
		marginTop: -15,
	},
	rowNoSpaceMB: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "flex-start",
		alignItems: "flex-start",
		alignContent: "flex-start",
		marginTop: 3,
	},
	UzAddress: {
		marginTop: 10,
	},
	scanRow: {
		...fRow,
		borderBottom: "1px solid black",
	},
	shippingNo: {
		marginBottom: 5,
		marginTop: 5,
		fontSize: 14,
	},
	giftCross: {
		marginLeft: -49,
		fontSize: 5,
		marginTop: -1,
	},
	giftAfterCross: {
		marginLeft: 5,
		marginTop: -1,
	},
	CommCross: {
		marginLeft: -55,
		fontSize: 5,
		marginTop: -1,
	},
	CommAfterCross: {
		marginLeft: 4,
		marginTop: -1,
	},
	otherCross: {
		marginLeft: -50,
		fontSize: 5,
		marginTop: -1,
	},
	otherAfterCross: {
		marginLeft: 4,
		marginTop: -1,
	},
	docsCross: {
		marginLeft: -47,
		fontSize: 5,
		marginTop: -1,
	},
	docsAfterCross: {
		marginLeft: 4,
		marginTop: -1,
	},
	rGCross: {
		marginLeft: -55,
		fontSize: 5,
		marginTop: -1,
	},
	rGAfterCross: {
		marginLeft: 4,
		marginTop: -1,
	},
	sOGCross: {
		marginLeft: -52,
		fontSize: 5,
		marginTop: -1,
	},
	sOGAfterCross: {
		marginLeft: 4,
		marginTop: -1,
	},
});
