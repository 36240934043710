import React, { useState, useEffect } from "react";
import axios from "axios";

function useFetch(url) {
	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(null);
	const [error, setError] = useState(null);

	useEffect(() => {
		// setLoading("loading...");
		// setData(null);
		// setError(null);
		// axios
		// 	.get(url)
		// 	.then((res) => {
		// 		setLoading(false);
		// 		//checking for multiple responses for more flexibility
		// 		//with the url we send in.
		// 		console.log({ res });
		// 		res.data.content && setData(res.data.content);
		// 		res.content && setData(res.content);
		// 	})
		// 	.catch((err) => {
		// 		setLoading(false);
		// 		setError("An error occurred. Awkward..");
		// 	});
		(async () => {
			const response = await fetch(url, {
				headers: {
					"Content-Type": "image/png",
				},
			});
			// console.log({ response });

			const imageBlob = await response.blob();
			// 	const canvas = document.createElement("canvas");
			// const viewport = page.getViewport({ scale: 1 });
			// const context = canvas.getContext("2d");
			// canvas.height = viewport.height;
			// canvas.width = viewport.width;
			// await page.render({ canvasContext: context, viewport: viewport }).promise;

			// canvas.remove();
			setData(imageBlob);
			// const reader = new FileReader();
			// reader.readAsDataURL(imageBlob);
			// reader.onloadend = () => {
			// 	const base64data = reader.result;
			// 	console.log(base64data);
			// };
		})();
	}, [url]);

	return data;
}
export default useFetch;
