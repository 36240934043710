import { FilterList } from "@mui/icons-material";
import { Button, Grid, MenuItem, Pagination, Stack } from "@mui/material";
import React from "react";
import { SelectBox, TextFieldBox } from "../../components/CustomInput";
import TableContainer from "../../components/TableContainer";

export default function ClientDeliveryRequests() {
  return (
    <Stack spacing={"20px"}>
      <Grid container spacing={"30px"}>
        <Grid item xs={6}>
          <Grid container spacing="10px">
            <Grid item xs={3}>
              <Button
                size="large"
                variant="outlined"
                startIcon={<FilterList />}
                fullWidth
              >
                필터
              </Button>
            </Grid>

            <Grid item xs={3}>
              <SelectBox value="접수번호">
                <MenuItem value="접수번호">접수번호</MenuItem>
              </SelectBox>
            </Grid>

            <Grid item xs={6}>
              <TextFieldBox placeholder="접수번호를 입력해주세요." />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid container spacing="10px">
            <Grid item xs={1.5}>
              <TextFieldBox placeholder="출력" />
            </Grid>
            <Grid item xs={1.5}>
              <TextFieldBox placeholder="보류" />
            </Grid>
            <Grid item xs={1.5}>
              <TextFieldBox placeholder="삭제" />
            </Grid>

            <Grid item xs={3.5}>
              <TextFieldBox placeholder="엑셀 다운로드" />
            </Grid>

            <Grid item xs={4}>
              <SelectBox value="10개씩 보기">
                <MenuItem value="10개씩 보기">10개씩 보기</MenuItem>
              </SelectBox>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <TableContainer
        {...{ isLoading: false, requestCompleted: true }}
        headers={[
          "아니요",
          "유저명",
          "업체명",
          "전화번호",
          "휴대폰 번호",
          "사업자 등록 번호",
        ]}
        data={[1, 1, 1, 1, 1, 1, 1, 1].map((item, index) => [
          index + 1,
          "홍길동",
          "엠비케이 엠비케이투",
          "010-1234-5678",
          "010-1234-5678",
          "264-81-01766	",
        ])}
      />

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: 30,
        }}
      >
        <Pagination count={10} color="primary" shape="rounded" />
      </div>
    </Stack>
  );
}
