import React, { memo, useCallback, useEffect, useState } from "react";
import { useDataProvider, useResourceContext, useGetMany } from "react-admin";
import { Pagination, Stack } from "@mui/material";
import { renameResource } from "../utils/utils";
import Toaster from "../utils/Toaster";

function ListHOC({
	children: Children,
	pagination = true,
	setData = (data) => {},
	width = "",
	render: RenderChildren,
	showFilter,
	showSort,
	_resource,
	loader = true,
	...rest
}) {
	const dataProvider = useDataProvider();
	const [page, setPage] = useState(1);
	const [perPage, setPerPage] = useState(10);
	const [filter, setFilter] = useState(null);
	const [newFilter, setNewFilter] = useState(null);
	const [sort, setSort] = useState({});
	const resource = useResourceContext();
	const [isLoading, setIsLoading] = useState(false);
	const [requestCompleted, setRequestCompleted] = useState(false);

	const [_data, _setData] = useState({ data: [], total: 0 });
	const [excelData, setExcelData] = useState([]);
	const [isNoPagination, setIsNoPagination] = useState(false);

	const listRequest = (isNoPagination) => {
		setIsLoading(true);

		const _filter = {
			...(filter && { ...filter }),
			...(showFilter && { ...showFilter }),
		};
		const _sort = {
			...(sort && { ...sort }),
			...(showSort && { ...showSort }),
		};
		setNewFilter(_filter);

		//Creating payload for req
		let payload = {}; //For paged
		let payloadExcel = {}; //For excel with no pagination
		if (Object.keys(_filter).length !== 0) {
			//Adding filter if there are filter
			payload.filter = _filter;
			payloadExcel.filter = _filter;
		}
		if (resource !== "Notice" && resource !== "shipping-request/list") {
			//Adding pagination
			payload.pagination = { page, perPage };
			// payloadExcel.pagination = {};
		} else if (resource === "shipping-request/list" && !isNoPagination) {
			//Adding pagination
			payload.pagination = { page, perPage };
			// payloadExcel.pagination = {};
		}

		if (Object.keys(_sort).length !== 0) {
			//Adding sorting object
			payload.sort = { field: _sort.sortField, order: _sort.sortOrder };
			payloadExcel.sort = { field: _sort.sortField, order: _sort.sortOrder };
		}

		// for normal data
		dataProvider
			.getList(_resource || renameResource(resource), payload)
			.then((res) => {
				if (resource === "Notice") {
					res.data = res.data
						.sort((a, b) => b.id - a.id)
						.slice((page - 1) * perPage, (page - 1) * perPage + perPage);
				}
				_setData({ ...res });
				setData(res.data);
			})
			.catch((error) => {
				Toaster(
					"error",
					"일시적인 오류입니다. cs@shipter.kr로 문의 부탁드립니다."
				);
				console.log(error);
			})
			.finally(() => {
				setIsLoading(false);
				setRequestCompleted(true);
			});

		// for exceldata
		dataProvider
			.getList(_resource || renameResource(resource), payloadExcel)
			.then((res) => {
				setExcelData(res.data);
			})
			.catch((error) => {
				Toaster(
					"error",
					"일시적인 오류입니다. cs@shipter.kr로 문의 부탁드립니다."
				);
			});
	};

	const bulkRequest = () => {
		setIsLoading(true);
		const _sort = {
			...(sort && { ...sort }),
			...(showSort && { ...showSort }),
		};

		dataProvider
			.getList(_resource || renameResource(resource), {
				pagination: { page, perPage },
				...(_sort && { sortOrder: sort.sortOrder, sortField: sort.sortField }),
			})
			.then((res) => {
				const { data } = res;
				const key = Object.keys(filter)[0];
				const filteredData = data.filter((row) =>
					filter[key].some((each) =>
						row[key].toString().toLowerCase().includes(each.toLowerCase())
					)
				);
				if (filteredData.length > 0) {
					res["data"] = filteredData;
					res["total"] = filteredData.length;
				}
				_setData({ ...res });
				setData(res.data);
			})
			.catch((error) => {
				Toaster(
					"error",
					"일시적인 오류입니다. cs@shipter.kr로 문의 부탁드립니다."
				);
			})
			.finally(() => {
				setIsLoading(false);
				setRequestCompleted(true);
			});
	};

	const handleSetPerPage = (value) => {
		setPerPage(+value);
	};
	const getList = useCallback(
		async (isNoPagination) => {
			if (filter && Array.isArray(filter[Object.keys(filter)[0]])) {
				bulkRequest();
			} else {
				listRequest(isNoPagination);
			}
		},
		[page, perPage, filter, showFilter, sort, isNoPagination]
	);

	useEffect(() => {
		(async () => {
			await getList(isNoPagination);
		})();
	}, [page, perPage, filter, showFilter, sort, isNoPagination]);

	const handleChange = (e, number) => {
		setPage(number);
	};

	const handleSearch = (filterParams, doNotUsePage) => {
		setFilter(JSON.parse(JSON.stringify(filterParams)));

		if (doNotUsePage) {
			setIsNoPagination(true);
		} else {
			setIsNoPagination(false);
		}
	};

	const handleSort = (sortParams) => {
		setSort(JSON.parse(JSON.stringify(sortParams)));
	};

	return (
		<Stack spacing={4.3} sx={{ height: "100%" }}>
			{RenderChildren ? (
				<RenderChildren
					{...{
						data: _data.data,
						setData: _setData,
						page,
						handleSetPerPage,
						handlePageChange: handleChange,
						count: Math.ceil(_data.total / perPage),
						countTotal: _data.total,
						handleSearch,
						handleSort,
						perPage,
						isLoading,
						filter: newFilter,
						requestCompleted,
						...rest,
					}}
				/>
			) : (
				React.cloneElement(Children, {
					data: _data.data,
					setData: _setData,
					page,
					handleSetPerPage,
					handlePageChange: handleChange,
					count: Math.ceil(_data.total),
					countTotal: _data.total,
					handleSearch,
					handleSort,
					perPage,
					isLoading,
					filter: newFilter,
					requestCompleted,
					excelData,
					...rest,
				})
			)}
			{pagination && (
				<div
					style={{
						display: "flex",
						justifyContent: "center",
						marginTop: 30,
						maxWidth: width ? width : "",
					}}
				>
					<Pagination
						page={page}
						onChange={handleChange}
						count={Math.ceil(_data.total / perPage)}
						color="primary"
						shape="rounded"
					/>
				</div>
			)}
		</Stack>
	);
}

export default memo(ListHOC);
