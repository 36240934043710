import { ConstructionOutlined } from "@mui/icons-material";
import { atom } from "jotai";
import _ from "lodash";

const addBagAtom = atom(false);
const shippingProcessingNumberAtom = atom("");
const apiCallAtom = atom("");
const printAtom = atom("");
const messageAtom = atom({
  type: "",
  text: "",
});
const priceCheckingAtom = atom({ price: 0, isCheck: false });
const nodeBagIdAtom = atom(null);

const initialWeightValue = {
  actualWeight: "",
  applicableWeight: "",
  bulkWeight: "",
  horizontal: "",
  portrait: "",
  height: "",
  perValue: 5000,
};

const weightAtom = atom(initialWeightValue);

const getValidWeightAtom = atom((get) => {
  const weightObj = get(weightAtom);
  const validWeight = _.pickBy(weightObj, (value, key) => {
    return !!Boolean(value) && key !== "perValue";
  });
  return validWeight;
});

const getBulkWeight = ({ horizontal, portrait, height, perValue }) => {
  const allExist = _.every([horizontal, portrait, height, perValue]);
  console.log(allExist);
  if (!allExist) {
    return null;
  } else {
    return (horizontal * portrait * height) / Number(perValue);
  }
};
const getAcceptableWeight = ({ bulkWeight, actualWeight }) => {
  return _.max([bulkWeight, actualWeight]);
};

const changeWeightAtom = atom(
  (get) => get(weightAtom),
  (get, set, newValue) => {
    // const applicableWeight =
    //   _.max([newValue.actualWeight, newValue.bulkWeight]) || 0;
    // set({ ...newValue, applicableWeight: applicableWeight });
    const prev = get(weightAtom);
    if (!!!newValue.bulkWeight) {
      const bulkWeight = getBulkWeight({ ...prev, ...newValue });
      if (!!bulkWeight) {
        newValue.bulkWeight = bulkWeight;
      }
    }
    const applicableWeight = getAcceptableWeight({ ...prev, ...newValue });
    set(weightAtom, { ...prev, ...newValue, applicableWeight });
  }
);

const weightValidationAtom = atom(false);
const fixedWeightAtom = atom(false);
const resetWeightAtom = atom(null, (get, set) => {
  const fixedWeight = get(fixedWeightAtom);
  if (fixedWeight) {
    set(
      weightAtom,
      _.omit(initialWeightValue, ["actualWeight", "applicableWeight"])
    );
  } else {
    set(weightAtom, initialWeightValue);
  }
});

export {
  addBagAtom,
  apiCallAtom,
  printAtom,
  priceCheckingAtom,
  messageAtom,
  nodeBagIdAtom,
  weightAtom,
  weightValidationAtom,
  fixedWeightAtom,
  changeWeightAtom,
  getValidWeightAtom,
  resetWeightAtom,
  shippingProcessingNumberAtom,
};
