import CustomInput from "../../../../components/CustomInput";
import { Divider, Typography } from "@mui/material";
import React from "react";

export const CreateStepOne = ({
	handleChange,
	handleBlur,
	values,
	handleSubmit,
	errors,
}) => {
	// console.log({ values });
	return (
		<>
			<div
				style={{
					borderRadius: 10,
					overflow: "hidden",
					border: "1px solid #E5E5E5",
					overflowX: "auto",
				}}
			>
				<CustomInput
					labelWidth="400px"
					placeholder="이름을 입력해주세요."
					label="이름"
					required={true}
					name="senderInformation.name"
					onChange={handleChange}
					value={values.senderInformation.name}
					stepper={true}
					// handleBlur={e=>console.log("bluring")}
					// error={errors}
					onBlur={handleBlur}
				/>
				{}
				{errors?.senderInformation?.name && (
					<Typography
						sx={{ padding: "15px" }}
						fontSize={"14px"}
						margin="0px "
						color="#ff0000"
					>
						{"이름을 입력해주세요."}
					</Typography>
				)}

				<Divider />

				<CustomInput
					labelWidth="400px"
					placeholder="전화번호를 입력해주세요."
					label="전화번호"
					name="senderInformation.phoneNumber"
					type={"tel"}
					required={true}
					stepper={true}
					value={values.senderInformation.phoneNumber}
					onChange={handleChange}
				/>
				{errors?.senderInformation?.phoneNumber && (
					<Typography
						sx={{ padding: "15px" }}
						fontSize={"14px"}
						margin="0px "
						color="#ff0000"
					>
						{"전화번호를 입력해주세요."}
					</Typography>
				)}
				<Divider />

				<CustomInput
					labelWidth="400px"
					label="영문 주소"
					placeholder={"전체 영문주소를 입력해주세요"}
					name="senderInformation.address"
					value={values.senderInformation?.address}
					onChange={handleChange}
					required={true}
					stepper={true}
				/>
				{errors?.senderInformation?.address && (
					<Typography
						sx={errors != undefined && { padding: "15px" }}
						fontSize={"14px"}
						margin="0px"
						color="#ff0000"
					>
						{"전체 영문주소를 입력해주세요"}
					</Typography>
				)}
			</div>
		</>
	);
};
