import React from "react";
import {
	Page,
	Text,
	View,
	Document,
	StyleSheet,
	Image,
	Font,
} from "@react-pdf/renderer";

import shipter from "../../Common/assets/Images/shipter.png";
import TrackedExpresLogo from "../../Common/assets/Images/Tracked-Expres-logo.jpg";
import PostNLPB1 from "../../Common/assets/Images/PostNL_PB1.jpg";
import phoneSymbol from "../../Common/assets/Images/phone-symbol.png";
import Scan from "../../Common/assets/Images/Scan.jpg";
import ReturnLogo from "../../Common/assets/Images/Return-Logo.jpg";
import moment from "moment";
import PackageTable from "./PackageTable";

export const NLPost = (props) => {
	const { barcode, selectedRequests } = props;
	// console.log("barcode", barcode, "selectedRequests", selectedRequests);
	let { tableData, ...item1 } = selectedRequests;
	// console.log(props);
	const calculateTotal = (products) => {
		let total = 0;
		products.forEach((item) => {
			let product = parseInt(item.quantity) * parseInt(item.unitPrice);

			total = total + product;
		});

		return total;
	};
	return (
		<>
			{/* return ( */}
			{tableData && <PackageTable tableData={tableData} />}
			<Page size="A6" orientation="landscape" style={styles.page}>
				<View style={styles.mainView}>
					<View style={styles.leftSec}>
						<View style={styles.sec1}>
							<View style={styles.sec11}>
								<View style={styles.bold}>
									<Text>CUSTOMS</Text>
									<Text>DECLARATION</Text>
									<Text>May be opened officially</Text>
									<View style={styles.row}>
										<Text>Designated operator</Text>
										<Text style={styles.blueConNoPadding}>Post NL</Text>
									</View>
								</View>

								<View style={styles.postCode}>
									<Text>CN22</Text>
								</View>
							</View>
						</View>
						<View style={styles.sec11}>
							<View style={styles.row}>
								<Text>Category:</Text>
								<Text style={styles.blueConNoPadding}>Sale of Goods</Text>
							</View>
						</View>
						<View style={styles.sec14}>
							<View style={styles.sec13}>
								<View>
									<Text style={styles.borderBottom}>
										Quantity and detailed description of contents
									</Text>
								</View>
								<View>
									{item1.productInformation?.productInformation
										.slice(0, 3)
										.map((product) => (
											<Text>
												{product.productName.length > 25
													? product.productName.slice(0, 25).concat("...")
													: product.productName}
											</Text>
										))}
								</View>
							</View>
							<View style={styles.weightInKg}>
								<View style={styles.borderBottom}>
									<Text>Weight (in kg)</Text>
								</View>
								<View>
									{item1.productInformation?.productInformation
										.slice(0, 3)
										.map((product) => (
											<Text>
												{" "}
												{item1.miscellaneousInformation?.actualWeight}kg
											</Text>
										))}
								</View>
							</View>
							<View style={styles.weightInKg}>
								<View style={styles.borderBottom}>
									<Text>Value (in USD)</Text>
								</View>
								<View>
									{item1.productInformation.productInformation
										.slice(0, 3)
										.map((item) => (
											<Text>
												{" "}
												{item.unitPrice}
												{item1.productInformation?.currency === "USD"
													? "$"
													: "€"}
											</Text>
										))}
								</View>
							</View>
						</View>
						<View style={styles.sec14}>
							<View style={styles.sec13}>
								<View style={styles.borderBottom}>
									<Text>
										For commercial items only If known HS tariff number and
										country of origin of goods
									</Text>
								</View>
								<View style={styles.hsCode}>
									{item1.productInformation?.productInformation
										.slice(0, 3)
										.map((product) => (
											<Text>{product.hscode}</Text>
										))}
								</View>
							</View>
							<View style={styles.sec15}>
								<View style={styles.borderBottom}>
									<Text>Total Weight</Text>
								</View>
								<View>
									<Text style={styles.greenCon}>
										{item1.miscellaneousInformation?.actualWeight}kg
									</Text>
								</View>
							</View>
							<View style={styles.sec15}>
								<View style={styles.borderBottom}>
									<Text>Total Value</Text>
								</View>
								<View>
									<Text>
										{calculateTotal(
											item1.productInformation.productInformation
										)}
										{item1.productInformation?.currency === "USD" ? "$" : "€"}
									</Text>
								</View>
							</View>
						</View>
						<View style={styles.sec14}>
							<View>
								<Text>
									I, the undersigned whose name and address are given on the
									item, certify that the particulars given in this decleration
									are correct and that this item does not contain any dangerous
									article or articles prohibited by legislation or by custom
									regulations
								</Text>
								<Text style={styles.dateAndSender}>
									Date and Sender's signature
								</Text>

								<View style={styles.greenCon}>
									<Text>{item1.senderInformation?.name}</Text>
									<Text>{moment().format("YYYY-MM-DD")}</Text>
								</View>

								<View style={styles.greenCon}>
									<Text>No. {item1?.trackingNumber} </Text>
									<Text>Order No: {item1?.productInformation.orderNo1} </Text>
									{item1.productInformation.productInformation
										.slice(0, 3)
										.map((product) => {
											return (
												<View style={styles.row}>
													{" "}
													<Text>
														{product.productName.length > 25
															? product.productName.slice(0, 25).concat("...")
															: product.productName}
													</Text>
													<Text>{product.quantity}ea</Text>
												</View>
											);
										})}
								</View>
							</View>
						</View>
					</View>
					<View style={styles.rightSec}>
						<View style={styles.sec16}>
							<Text>
								Return if undeliverable to: H-108505, Postbus 7270, 3109 AF
								Schiedam, Netherlands
							</Text>
						</View>
						<View style={styles.from}>
							<View style={styles.fromLeft}>
								<View style={styles.fromAddress}>
									<Text style={styles.fromRotate}>FROM</Text>
									<View>
										<Text>H3NETWORKS</Text>
										<Text>#B103, Hwagok-ro 416 The Sky Vallery 5th</Text>
										<Text>07548, Seoul</Text>
										<Text>South Korea</Text>
									</View>
								</View>

								<View style={styles.row}>
									<Text></Text>
									<Image style={styles.returnLogoImg} src={ReturnLogo} />
								</View>
							</View>

							<View style={styles.fromRight}>
								<View style={styles.row}>
									<Image style={styles.TrackImg} src={TrackedExpresLogo} />
									<Image style={styles.PostNLImg} src={PostNLPB1} />
								</View>
								<View>
									<Text style={styles.packetCon}>Packet Tracked</Text>
									<Text style={styles.standardText}>STANDARD</Text>
								</View>
							</View>
						</View>
						<View style={styles.to}>
							<View style={styles.toLeft}>
								<Text>To</Text>
							</View>

							<View style={styles.toRight}>
								<View style={styles.row}>
									<Text>{item1?.payeeInformation?.name}</Text>
									<View style={styles.row}>
										<Image style={styles.phoneSym} src={phoneSymbol} />
										<Text>{item1?.payeeInformation?.phoneNumber1}</Text>
									</View>
								</View>

								<Text>
									{item1?.payeeInformation?.streetAddress1}{" "}
									{item1?.payeeInformation?.streetAddress2}
								</Text>
								<Text>
									{item1?.payeeInformation?.zipCode}{" "}
									{item1?.payeeInformation?.states}{" "}
									{item1?.payeeInformation?.cities}
								</Text>
								<Text>
									{item1.payeeInformation.shippingCountry.country_name_en}
								</Text>
							</View>
							<View style={styles.countryCode}>
								<Text>{item1.payeeInformation.shippingCountry.code}</Text>
							</View>
						</View>
						<View style={styles.Icon}>
							<Image style={styles.scanImg} src={Scan} />
						</View>
						<View style={styles.col}>
							<Image src={barcode} width="100%" height={50} />
							{/* <Text style={styles.shippingNo}>{item1.shippingNumber}</Text> */}
						</View>
					</View>
				</View>
			</Page>
			{/* ); */}
		</>
	);
};

const fRow = {
	diaplay: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
};
const fCol = {
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	justifyContent: "center",
};

Font.register({
	family: "SpoqaHanSans",
	src: "https://cdn.jsdelivr.net/gh/spoqa/spoqa-han-sans@01ff0283e4f36e159ffbf744b36e16ef742da6d8/Subset/SpoqaHanSans/SpoqaHanSansLight.ttf",
});
const styles = StyleSheet.create({
	page: {
		fontSize: 5,
		padding: 10,

		fontFamily: "SpoqaHanSans",
	},
	sec1: {
		display: "flex",
		flexDirection: "row",
		width: "100%",
		color: "#fff",
	},
	sec11: {
		backgroundColor: "white",
		padding: 2,
		color: "#000",
		borderBottom: "2px solid #000",
		display: "flex",
		flexDirection: "row",
		justifyContent: "flex-start",
		alignItems: "flex-start",
	},
	sec12: {
		...fCol,
		backgroundColor: "white",
		width: "65%",
		padding: 15,
		color: "red",
		fontWeight: 700,
		border: "2px solid #000",
	},
	sec13: {
		width: "50%",
		borderRight: "1px solid #000",
	},
	sec14: {
		backgroundColor: "white",
		color: "#000",
		borderBottom: "2px solid #000",
		display: "flex",
		flexDirection: "row",
	},
	sec15: {
		width: "25%",
		borderRight: "1px solid #000",
	},
	sec16: {
		borderBottom: "1px solid #000",
		fontSize: 6,
	},
	leftSec: {
		width: "40%",
		borderRight: "1px solid black",
	},
	rightSec: {
		width: "60%",
		color: "#000",
		backgroundColor: "#fff",
	},
	from: {
		display: "flex",
		flexDirection: "row",
		borderBottom: "1px solid black",
	},
	fromLeft: {
		width: "55%",
		borderRight: "1px solid black",
		// backgroundColor: "#afdcec",
		// borderRadius: 5,
		// border: "0.5px solid blue",
		marginLeft: 5,
		marginRight: 5,

		padding: 0,
	},
	fromRight: {
		// ...fRow,
		width: "45%",
		// backgroundColor: "#afdcec",
		// borderRadius: 5,
		// border: "0.5px solid blue",
		marginLeft: 5,
	},
	to: {
		display: "flex",
		flexDirection: "row",
		borderBottom: "1px solid black",
	},
	toLeft: {
		...fCol,
		width: "15%",
		borderRight: "1px solid black",
		fontSize: 12,
	},
	toRight: {
		width: "85%",
		// backgroundColor: "#ddead1",
		// borderRadius: 5,
		// border: "0.5px solid blue",
		marginLeft: 5,
		marginRight: 5,
		marginBottom: 5,
		padding: 5,
	},
	Icon: {
		...fCol,
		borderBottom: "1px solid black",
	},
	mainView: {
		display: "flex",
		flexDirection: "row",
		width: "100%",
	},
	red: {
		color: "red",
	},
	sec2: {
		...fRow,
		color: "red",
		fontWeight: 700,
		padding: 10,
		alignItems: "center",
		justifyContent: "center",
		border: "2px solid #000",
	},
	sec3: {
		...fCol,
		alignItems: "flex-start",
		height: 170,
		paddingLeft: 15,
		paddingRight: 15,
		border: "2px solid #000",
	},
	sec3a: {
		...fRow,
	},
	sec4: {
		...fCol,
		height: 170,
		alignItems: "flex-start",
		paddingLeft: 15,
		paddingRight: 15,
		border: "2px solid #000",
	},
	sec5: {
		...fRow,
		width: "100%",
		paddingLeft: 15,
		paddingRight: 15,
	},
	sec6: {
		...fRow,
		width: "100%",
		paddingLeft: 15,
		paddingRight: 15,
		border: "2px solid #000",
	},
	sec6a: {
		...fCol,
		width: "100%",
		paddingLeft: 15,
		paddingRight: 15,
	},
	sec5a: {
		...fCol,
		justifyContent: "flex-start",
		alignItems: "flex-start",
		height: 200,
		border: "2px solid #000",
	},
	sec51: {
		...fCol,
		alignItems: "flex-start",
		justifyContent: "flex-start",
		width: "50%",
		height: 150,
		paddingLeft: 15,
		paddingRight: 15,
	},
	sec52: {
		...fCol,
		height: 150,
		width: "50%",
		justifyContent: "flex-start",
		alignItems: "flex-start",

		paddingLeft: 15,
		paddingRight: 15,
	},

	listItem: {
		...fRow,
		width: "100%",
		marginBottom: 10,
		gap: "20px",
		justifyContent: "flex-end",
		padding: "0 10px",
	},
	list: {
		...fRow,
		marginRight: 10,
	},
	listItemLabel: {},
	listItemValue: {
		color: "red",
	},
	line: {
		height: 1,
		backgroundColor: "black",
		marginBottom: 2,
	},
	body: {
		padding: 10,
	},
	row: {
		...fRow,
	},
	center: {
		...fCol,
		marginTop: 25,
	},
	image: {
		maxWidth: 130,
		height: "auto",
	},
	postCode: {
		fontSize: 16,
	},
	border: {
		border: "1px solid black",
	},
	borderBottom: {
		borderBottom: "1px solid black",
	},
	hsCode: {
		display: "flex",
		borderBottom: "1px solid black",
	},
	borderTop: {
		borderTop: "1px solid black",
	},
	bold: {
		// fontWeight: "bold",
		fontWeight: 800,
		// fontWeight: "800",
	},
	greenCon: {
		//     backgroundColor: "#ddead1",
		//     borderRadius: 5,
		//     border: "0.5px solid blue",
		padding: 3,
	},
	blueCon: {
		//     backgroundColor: "#afdcec",
		//     borderRadius: 5,
		//     border: "0.5px solid blue",
		marginLeft: 5,
		marginRight: 5,
		marginBottom: 5,
		padding: 5,
	},
	dateAndSender: {
		margin: 5,
	},
	weightInKg: {
		display: "flex",
		justifyContent: "center",
		width: "25%",
		borderRight: "1px solid #000",
	},
	valueInUSD: {
		...fCol,
		width: "25%",
		borderRight: "1px solid #000",
	},

	col: {
		...fCol,
	},
	TrackImg: {
		// width: 50,
		// height: 50
	},
	PostNLImg: {
		// width: 150,
		// height: 70
	},
	phoneSym: {
		width: 8,
		height: 8,
	},
	scanImg: {
		width: 40,
		height: 40,
		// backgroundColor: "#afdcec",
		// borderRadius: 5,
		// border: "0.5px solid blue",
		margin: 10,
		padding: 5,
	},
	returnLogoImg: {
		width: 15,
		height: 15,
	},
	blueConNoPadding: {
		//     backgroundColor: "#afdcec",
		//     border: "0.5px solid blue",
		marginLeft: 5,
		marginRight: 5,
		marginBottom: 5,
	},
	countryCode: {
		display: "flex",
		justifyContent: "flex-end",
		fontSize: 17,
	},
	fromAddress: {
		display: "flex",
		flexDirection: "row",
		fontSize: 5,
		marginRight: 12,
		marginBottom: 10,
	},
	fromRotate: {
		transform: "rotate(-90deg)",
		fontSize: 10,
		marginTop: 10,
	},
	standardText: {
		backgroundColor: "#000",
		color: "#fff",
		textAlign: "center",
		padding: 2,
	},
	packetCon: {
		textAlign: "center",
	},
});
