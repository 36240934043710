/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Checkbox, Grid, Link, Stack } from "@mui/material";
import styled from "styled-components";
import ListHOC from "../../../SharedComponents/ListHOC";
import React, { useEffect, useState, useCallback } from "react";
import CustomButton from "../../../components/CustomButton";
import CustomPopover from "../../../components/CustomPopover";
import TableContainer from "../../../components/TableContainer";
import Modal1 from "../../../modals/whereAboutModal";
import Modal2 from "../../../modals/requestModal";
import Modal3 from "../../../modals/printWaybillModal";
import TrackingModal from "../../../modals/trackingModal";
import Moment from "moment";
import DropdownAndSearchInput from "./DropAndSearch";
import { onDropDownSearchChange } from "../../../utils/onDropDownSearchChange";
import ShippingFilter from "../../../components/shippingFilter";
import CustomSelect from "../../../components/CustomSelect";
import Toaster from "../../../utils/Toaster";
import { ClientDeliveryRequestTable } from "../../../tables/ClientDeliveryRequestTable";
import {
	useDelete,
	useDeleteManyWithIds,
	useSetRequestShippingOnHold,
	exportJsonToXlsx,
	generateSearchData,
} from "../../../utils/utils";
import { gql } from "@apollo/client/core";
import ConfirmModal from "../../../modals/ConfirmModal";
import { APIClient } from "../../../utils/apiGraphql";
import usePrintWaybill from "../../../resources/WaybillPrint";
import {
	localFilter,
	updateAllReqPacking,
	updateAllReqShipping,
} from "./utils/updateAllReqShipping";
import { getAllCountries } from "../../admin/RatesManagament/queries";

function ShippingList(props) {
	return (
		<ListHOC
			_resource={"RequestShipping"}
			{...props}
			showFilter={{ isDraft: false }}
		>
			<ClientShippingRequest />
		</ListHOC>
	);
}

const ClientShippingRequest = ({
	data: _Data,
	page,
	perPage,
	handlePageChange,
	handleSetPerPage,
	handleSearch,
	countTotal,
	handleSort,
	...hocProps
}) => {
	// const [page, setPage] = React.useState(1);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);
	const [openDialog, setOpenDialog] = useState(false);
	const [openDialog2, setOpenDialog2] = useState(false);
	const [openDialog3, setOpenDialog3] = useState(false);
	const [checkedList, setCheckedList] = useState([]);
	const [activeScreen, setActiveScreen] = useState(false);
	const [filterQuery, setFilterQuery] = useState(null);
	const [openDeleteConfirmModal, setOpenDeleteConfirmModal] = useState(null);
	const [openDeleteManyConfirmModal, setOpenDeleteManyConfirmModal] =
		useState(null);
	const [selectedDeleteId, setSelectedDeleteId] = useState(null);
	const { isLoading, deleteOne } = useDelete();
	const { holdMany } = useSetRequestShippingOnHold();
	const { deleteMany } = useDeleteManyWithIds();
	const [selectedHoldId, setSelectedHoldId] = useState(null);
	const [requestDescription, setRequestDescription] = useState(null);
	const [requestId, setRequestId] = useState(null);
	const [selectedRequests, setSelectedRequests] = useState([]);
	const [countries, setCountries] = useState([]);

	const { printRequestedLabels } = usePrintWaybill(selectedRequests); //Print waybill

	const [shippingRequestsCount, setShippingRequestsCount] = useState(0);
	const [shippingRequests, setShippingRequests] = useState([]);
	const [shipmentTrackingData, setShipmentTrackingData] = useState(null);

	const handleClose = () => {
		setShipmentTrackingData(null);
		setActiveScreen(false);
	};
	const [data, setData] = useState([]);

	useEffect(() => {
		setData(_Data);
	}, [_Data]);

	//Getting countries
	useEffect(() => {
		getAllCountries().then((res) => {
			setCountries(res.data.allShippingCountries);
		});

		return () => {};
	}, []);

	// const handleChangePage = (event, newPage) => {
	// 	setPage(newPage);
	// };
	const handleCheck = ({ target }) => {
		const { value, checked } = target;
		setCheckedList((prevState) => {
			const temp = [...prevState];
			if (checked) {
				temp.push(+value);
				return temp;
			} else {
				return temp.filter((res) => res !== +value);
			}
		});
	};

	const openShipmentTracking = (item) => {
		setActiveScreen(true);
		setShipmentTrackingData(item);
		console.log("item", item);
	};

	// const handleChangeRowsPerPage = (event) => {
	// 	console.log(event);
	// 	setRowsPerPage(parseInt(event.target.value, 10));
	// 	setPage(0);
	// };

	const handleOpen = (data) => {
		setOpenDialog(true);
		// setSelectedData(data);
	};

	const handleCloseConfirmDialog = () => {
		setOpenDialog(false);
		// setSelectedData(null);
	};

	const handleOpen2 = (data) => {
		setOpenDialog2(true);
		// setSelectedData(data);
	};

	const handleCloseConfirmDialog2 = () => {
		setOpenDialog2(false);
		// setSelectedData(null);
	};

	const handleCloseConfirmDialog3 = () => {
		setOpenDialog3(false);
	};

	const handleDeleteSuccess = () => {
		setCheckedList([]);
		setOpenDeleteConfirmModal(false);
		setOpenDeleteManyConfirmModal(false);
		if (selectedDeleteId) setSelectedDeleteId(null);
	};
	const handleHoldSuccess = () => {
		setCheckedList([]);
		if (selectedHoldId) setSelectedHoldId(null);
		generateSearchData(handleSearch, {});
	};

	const handleDelete = () => {
		if (!selectedDeleteId) {
			return Toaster(
				"error",
				"You have to select atleast one master to perform this action."
			);
		}
		const deleteMutation = gql(`
    mutation deleteRequestShipping($input: RequestShippingDeleteInput!){
      deleteRequestShipping(deleteInput: $input){
        id
      }
    }`);
		deleteOne({
			id: selectedDeleteId,
			setData,
			deleteMutation,
			onSuccess: handleDeleteSuccess,
			responseKey: "deleteRequestShipping",
		});
	};

	//Waybill print confirm function
	const wayBillPrint = (d) => {
		//Updating packing list
		if (d) {
			updateAllReqPacking(selectedRequests.flat()).then(
				(res) => {
					//Updating table row state data
					let cloneData = [...data];
					const ids = selectedRequests.flat().map((re) => re.id);
					ids.forEach((id) => {
						let index = cloneData.findIndex((el) => el.id === id);
						if (index !== -1) {
							cloneData[index].packingListPrintStatus = true;
						}
					});
					setData(cloneData);
				},
				(err) => {
					Toaster("error", "Error occured updating the Print Status");
				}
			);
		}
		//Updating waybill print status

		updateAllReqShipping(selectedRequests.flat()).then(
			(res) => {
				let tableData = null;
				if (d) {
					tableData = handleExport(checkedList);
				}
				const selectedData = JSON.parse(JSON.stringify(selectedRequests));
				if (tableData) {
					tableData.forEach(([data]) => {
						const [find] = selectedData.find(([res]) => {
							return res.trackingNumber === data["접수번호"];
						});
						if (find) {
							find.tableData = data;
						}
					});
				}
				printRequestedLabels(selectedData, null);
				//Updating table row state data
				let cloneData = [...data];
				const ids = selectedData.flat().map((re) => re.id);
				ids.forEach((id) => {
					let index = cloneData.findIndex((el) => el.id === id);
					if (index !== -1) {
						cloneData[index].waybillPrintStatus = true;
					}
				});
				setData(cloneData);
			},
			(err) => {
				Toaster("error", "Error occured updating the Print Status");
			}
		);

		setOpenDialog3(false);
	};

	const handleDeleteMany = () => {
		if (!checkedList.length) {
			return Toaster(
				"error",
				"You have to select atleast one bag to perform this action."
			);
		}
		const deleteMutation = gql(`
  mutation deleteManyRequestShipping($ids: RequestShippingDeleteManyInput!){
    deleteManyRequestShipping(ids: $ids){
      id
    }
  }`);
		deleteMany({
			ids: selectedDeleteId ? [selectedDeleteId] : checkedList,
			setData,
			deleteMutation,
			onSuccess: handleDeleteSuccess,
			responseKey: "deleteManyRequestShipping",
		});
	};

	const handleOnHold = () => {
		if (!(selectedHoldId || checkedList.length)) {
			return Toaster(
				"error",
				"You have to select atleast one master to perform this action."
			);
		}

		//Checking if the status is not waiting_warehousing
		let selectedData = [];
		checkedList.forEach((d) => {
			let ind = data.findIndex((el) => el.id === d);
			if (ind !== -1) {
				selectedData.push(data[ind]);
			}
		});
		let status = selectedData.some((el) => el.status !== "waiting_warehousing");
		if (status)
			return Toaster(
				"warning",
				"선택하신 배송접수건 중, 이미 입고완료 혹은 출고완료된 배송건이 있습니다. 입고대기 상태인 접수건들만 보류가 가능하오니, 확인 부탁드립니다."
			);
		const holdMutation = gql(`
    mutation setRequestShippingOnHold($ids: RequestShippingUpdateIsDraftStatusInput!){
      setRequestShippingOnHold(ids: $ids){
        id
      }
    }`);
		holdMany({
			ids: selectedHoldId ? [selectedHoldId] : checkedList,
			setData,
			holdMutation,
			onSuccess: handleHoldSuccess,
			responseKey: "setRequestShippingOnHold",
		});
	};

	const requestHandle = async () => {
		const createRequest = gql(`mutation
		createWhereAbout(
			$createInput: WhereAboutCreateInput!
		){
			createWhereAbout(createInput: $createInput){
				id
		}
	}
		`);

		try {
			const res = await APIClient.mutate({
				mutation: createRequest,
				variables: {
					createInput: {
						requestShippingId: requestId,
						memo: requestDescription,
					},
				},
			});
			Toaster("success", "Added Where About");
			setTimeout(() => {
				window.location.reload();
			}, 2000);
		} catch (e) {
			Toaster("error", e.message);
		}
	};

	const getShippingRequests = async () => {
		const Query = gql(`
		query($page:Float, $perPage:Float){
			allRequestShippings(page:$page, perPage:$perPage){
				id
				waybillPrintStatus
				packingListPrintStatus
				masterNo
				totalSKU
				bag {
					id
					name
					bagNumber
					shippingCountry{
						id
						__typename
					}
					deliveryType{
						id
						__typename
					}
					deliveryService{
						id
						__typename
					}
				
					howToPayCustomDuties
					dgPackagingManagementCode
					userProfile{
						id
						__typename
					}
					userId
					numberOfUses
					weight
					usedWeight
					__typename
				}
				senderInformation {
					id
					name
					phoneNumber
					address
					requestShippingId
					deleted
					createdAt
					updatedAt
					__typename
				}
				whereAbout{
					id
					status
					requestShippingId
					requestShipping{
						id
						__typename
					}
					memo
					__typename
				}
				payeeInformation {
					id
					name
					shippingCountryId
					shippingCountry{
						id
						name
						code
						monetaryUnit
						country_name_en
						__typename
					}
					phoneNumber1
					phoneNumber2
					zipCode
					ssn
					ukeori
					euioss
					states
					cities
					streetAddress1
					streetAddress2
					email
					requestShippingId
					mexicoColony
					deleted
					createdAt
					updatedAt
					__typename
				}
				miscellaneousInformation {
					id
					shippingMessage
					horizontal
					portrait
					height
					actualWeight
					applicableWeight
					bulkWeight
					userData
					userDate2
					userData3
					boxQuantity
					productType
					exportDeclarationNumber
					requestShippingId
					deleted
					coupon
					createdAt
					updatedAt
					__typename
				}
				productInformation {
					id
					orderNo1
					orderNo2
					departureCountryCourierNumber
					currency
					productInformation {
						id
						barcode
						productName
						quantity
						unitPrice
						brandName
						sku
						purchaseURL
						hscode
						deleted
						createdAt
						updatedAt
						__typename
					}
					totalPriceAmount
					requestShippingId
					deleted
					createdAt
					updatedAt
					__typename
				}
			
				user {
					id
					username
					userID
					company_registration_number
					company_name_kr
					company_name_en
					customer_name
					totalDeposit
					email
					memo
					request_issuance_of_tax_invoice
					e_tax_invoice_email
					balance
					business_condition
					phone_number
					mobile_number
					mallId
					sellerPartyId
					apiKey
					__typename
				}
				shippingType{
					id
					shippingType
					apiType
					apiType_{
						name
						id
					}
					shippingWeight
					memo
					
					createdAt
					__typename
				}
				shippingCountry{
					id
					name
					code
					monetaryUnit
					country_name_en
					shippingTypesDetailsIds
					isActive
					createdAt
					__typename
				}
				userId
				deleted
				deliveryFee
				createdAt
				updatedAt
				status
				trackingNumber
				shippingNumber
				additionalFee
				orderNumber
				weightValidation
				printWaybillAfterWeightChange
				sampoongAmountCheck
				treatmentAfterWeightChange
				processReleaseDate
				exportDeclarationRequest
				__typename
			}
		}
	    `);

		try {
			const {
				data: { allRequestShippings },
			} = await APIClient.query({
				query: Query,
				variables: {
					page: 0,
					perPage: shippingRequestsCount,
				},
			});
			setShippingRequests(allRequestShippings);
		} catch (e) {
			console.log(e);
		}
	};

	const getShippingRequestsCount = async () => {
		const Query = gql(`
		query{
			_allRequestShippingsMeta{
				count
			}
		}
      `);

		try {
			const {
				data: {
					_allRequestShippingsMeta: { count },
				},
			} = await APIClient.query({
				query: Query,
			});
			setShippingRequestsCount(count);
		} catch (e) {
			console.log(e);
		}
	};

	useEffect(() => {
		getShippingRequestsCount();
	}, []);

	useEffect(() => {
		getShippingRequests();
	}, [shippingRequestsCount]);

	const handleExportAll = (exportData) => {
		var cleanJson = [];
		let selectedData = [];

		if (checkedList.length === 0) {
			Toaster("error", "Please select a checkbox");
		}

		if (perPage === checkedList.length) {
			selectedData = shippingRequests;
		} else {
			selectedData = data;
		}

		selectedData.map((item, index) => {
			if (
				checkedList.indexOf(item.id) === -1 &&
				perPage !== checkedList.length
			) {
				return;
			}

			var productNames = [];
			var productQuantity = [];
			var productHscode = [];
			var productPurchaseUrl = [];
			var productBarcode = [];

			var totalQuantity = 0;

			item.productInformation.productInformation.map((subItem, index) => {
				totalQuantity = totalQuantity + Number(subItem.quantity);

				productNames.push(`${subItem.productName}`);
				productHscode.push(`${subItem.hscode}`);
				productQuantity.push(`${subItem.quantity}`);
				productPurchaseUrl.push(`${subItem.purchaseURL}`);
				productBarcode.push(`${subItem.barcode}`);
			});

			cleanJson.push({
				// 국가: item.payeeInformation.shippingCountry.code + index,
				// 접수번호: "EHK220006974KR",
				// 주문번호: item.productInformation.orderNo1,
				// 배송번호: item.productInformation.departureCountryCourierNumber,
				// 아이템1: productNames,
				// 아이템2: productHscode,
				// 아이템3: productQuantity,
				// 아이템4: "-",
				// 아이템5: "-",
				// nauman: totalQuantity.toString(),
				// 총수량: item.productInformation.totalPriceAmount,

				"No.": countTotal - ((page - 1) * 10 + index),
				"배송 상태":
					item.status === "waiting_warehousing"
						? "입고대기"
						: item.status === "warehousing_completed"
						? "입고완료"
						: item.status === "waiting_delivery"
						? "출고대기"
						: item.status === "started_delivery"
						? "출고완료"
						: item.status === "completed_delivery" && "배송완료",

				"운송장 출력": item.waybillPrintStatus ? "O" : "X",
				"팩킹리스트 출력": item.packingListPrintStatus ? "O" : "X",
				타입: "일반",
				"고객사 이름": item.user?.company_name_kr,
				배송국가: item.payeeInformation.shippingCountry.code,
				배송종류: item.shippingType?.shippingType,
				접수일: item?.createdAt
					? Moment(item?.createdAt).format("YYYY-MM-DD")
					: "",
				출고일: item.processReleaseDate
					? Moment(item.processReleaseDate).format("YYYY-MM-DD")
					: "",
				접수번호: item.trackingNumber,
				주문번호1: item.productInformation.orderNo1,

				주문번호2: item.productInformation.orderNo2,

				배송번호: item.shippingNumber,
				"출발국가 택배번호":
					item.productInformation.departureCountryCourierNumber,
				배송비: item.deliveryFee,
				"택배 추가요금": item.additionalFee,
				화폐: "KRW",
				"가로(Cm)": item.miscellaneousInformation.horizontal,
				"세로(Cm)": item.miscellaneousInformation.portrait,
				"높이(Cm)": item.miscellaneousInformation.height,
				"Box 수량": item.miscellaneousInformation.boxQuantity,
				"실제무게(KG)": item.miscellaneousInformation.actualWeight,
				"부피무게(KG)": item.miscellaneousInformation.bulkWeight,
				"적용무게(KG)": item.miscellaneousInformation.applicableWeight,
				"주민번호/통관번호/CPF": "",
				IOSS: item.payeeInformation.euioss,
				EORI: item.payeeInformation.ukeori,
				VOEC: "",
				"상품 바코드": productBarcode,
				상품목록: productNames,

				상품수량: productQuantity,

				"총 상품금액": item.productInformation.totalPriceAmount,
				화폐단위: item.productInformation.currency,
				"발송인 이름": item.senderInformation.name,
				"발송인 전화번호": item.senderInformation.phoneNumber,

				"발송인 주소": item.senderInformation.address,
				"수취인 이름": item.payeeInformation.name,
				"수취인 이름 (YOMIGANA)": "",
				"수취인 전화번호": item.payeeInformation.phoneNumber1,
				"수취인 휴대폰 번호": item.payeeInformation.phoneNumber2,
				"수취인 이메일": item.payeeInformation.email,
				"수취인 우편번호": item.payeeInformation.zipCode,
				Colony: item.payeeInformation.mexicoColony,
				주: item.payeeInformation.states,
				도시: item.payeeInformation.cities,
				"수취인 주소1": item.payeeInformation.streetAddress1,
				"수취인 주소2": item.payeeInformation.streetAddress2,
				배송메세지: item.miscellaneousInformation.shippingMessage,
				HSCODE: productHscode,
				상품종류: item.miscellaneousInformation.productType,
				통관구분: "",
				수출신고번호:
					item.miscellaneousInformation.exportDeclarationNumber != null
						? item.miscellaneousInformation.exportDeclarationNumber
						: "",
				"판매 site": productPurchaseUrl,
				"사용자 데이터 1":
					item.miscellaneousInformation.userData != null
						? item.miscellaneousInformation.userData
						: "",

				"사용자 데이터 2":
					item.miscellaneousInformation.userData2 != null
						? item.miscellaneousInformation.userData2
						: "",
				"사용자 데이터 3":
					item.miscellaneousInformation.userData3 != null
						? item.miscellaneousInformation.userData3
						: "",
			});
		});

		if (cleanJson.length) {
			const data = cleanJson;
			const fileName = "배송 신청 리스트" + new Date().toLocaleDateString();

			exportJsonToXlsx(data, fileName);
		}
	};

	const handleExport = (exportData) => {
		var filtered_array = [];
		data.filter((res) => {
			exportData.map((item, index) => {
				if (item == res.id) {
					filtered_array.push(res);
				}
			});
		});

		return filtered_array.map((item, index) => {
			var getSpecific = [];
			var productString = [];
			var totalQuantity = 0;

			item.productInformation.productInformation.map((subItem, index) => {
				totalQuantity = totalQuantity + Number(subItem.quantity);

				var oneString = `${subItem.productName},${subItem.quantity},${subItem.unitPrice}`;
				productString.push(oneString);
				// {index <
				//   item.productInformation.productInformation.length -
				//     1 && ","}
			});

			getSpecific.push({
				국가: item.payeeInformation.shippingCountry.code,
				접수번호: item.trackingNumber,
				주문번호: item.productInformation.orderNo1,
				배송번호: item.productInformation.departureCountryCourierNumber,
				아이템1: productString[0],
				아이템2: productString[1] ? productString[1] : "",
				아이템3: productString[2] ? productString[2] : "",
				아이템4: productString[3] ? productString[3] : "",
				아이템5: productString[4] ? productString[4] : "",
				아이템6: productString[5] ? productString[5] : "",
				아이템7: productString[6] ? productString[6] : "",
				아이템8: productString[7] ? productString[7] : "",
				아이템9: productString[8] ? productString[8] : "",
				아이템10: productString[9] ? productString[9] : "",
				"총 수량": totalQuantity.toString(),
				"총 금액": item.productInformation.totalPriceAmount,
			});

			// console.log("///");
			// console.log(getSpecific);
			// const data = getSpecific;
			// const fileName = "배송신청리스트" + Moment().format("YYYY-MM-DD");
			// exportJsonToXlsx(data, fileName);
			return getSpecific;
		});
	};

	const [initialValues, setInitialValues] = useState({
		startDate: `${Moment().format("YYYY-MM-DD")}`,
		endDate: `${Moment().format("YYYY-MM-DD")}`,
		alignment: "접수번호 내림차순",
		deliveryCountry: "접수번호 내림차순",
		companyName: "택배회사명",
		salesSite: "택배회사명",
		deliveryStatus: "배송상태",
		waybillPrintStatus: "출력여부",
		packingListPrintStatus: "출력여부",
	});

	const calculateTotal = (products) => {
		let total = 0;
		products.forEach((item) => {
			let product = parseInt(item.quantity) * parseInt(item.unitPrice);
			total = total + product;
		});
		return total;
	};

	/**
	 * Filter function that gets filter value and fetched data
	 */
	const setFilter = useCallback((d) => {
		let filterPack = {};
		console.log("d", d);
		if (d.dateOfReceipt) {
			const splitted = d.dateOfReceipt.split(" ");
			filterPack.createdAt = {
				gte: splitted[0],
				lte: splitted[2],
			};
		}
		if (d.dateOfRelease) {
			const splitted = d.dateOfRelease.split(" ");
			filterPack.processReleaseDate = {
				gte: splitted[0],
				lte: splitted[2],
			};
		}
		if (d.deliveryCountry)
			filterPack.payeeInformation123shippingCountryId = d.deliveryCountry;
		if (d.salesSite) filterPack.productInformation123purchaseURL = d.salesSite;
		if (d.deliveryStatus) filterPack.status = d.deliveryStatus;
		if (d.waybillPrintStatus !== "")
			filterPack.waybillPrintStatus = d.waybillPrintStatus;
		if (d.packingListPrintStatus !== "")
			filterPack.packingListPrintStatus = d.packingListPrintStatus;

		handleSearch(filterPack);

		if (d.sort) {
			const SortOrder = {};
			SortOrder.sortOrder = { sortOrder: d.sort };
			SortOrder.sortField = { sortField: "id" };
			handleSort(SortOrder);
		}
	}, []);

	useEffect(() => {
		if (checkedList.length > 0) {
			let arr = [];
			checkedList.forEach((item) => {
				getSelectedShippingRequests(item).then((res) => {
					if (selectedRequests.filter((item2) => item2.id !== res.id))
						setSelectedRequests((old) => [...old, res]);
				});
			});

			setSelectedRequests(arr);
			Toaster("success", "All Requests Fetched");
		}
	}, [checkedList]);

	//Local Search
	const localSearch = (d) => {
		if (d.field === "receipt_number") {
			d.query = d.query.split(",").map((each) => each.trim());
			let filterPack = {};
			filterPack.trackingNumber = d ? (d.query ? d.query[0] : "") : "";
			handleSearch(filterPack, true);
		} else {
			d.query = d.query.split(",").map((each) => each.trim());
			const res = localFilter(_Data, d);
			setData(res);
		}
	};

	const [columns, setColumns] = useState({
		// "특이사항(메모)": "",
		// "No.": "",
		// "배송 상태": "",
		// "배송 추적": "",
		// "고객사 이름": "",
		// 배송국가: "",
		// 배송종류: "",
		// 관세지불방식: "",
		// 상태: "",
		// 접수일: "",
		// 출고일: "",
		// 접수번호: "",
		// 배송번호: "",
		// "적용무게(KG)": "",
		// "Master No": "",
		// "SUB MASTER": "",
		// 주문번호1: "",
		// 주문번호2: "",
		// 배송회사: "",
		// "CUSTOM NUMBER": "",
		// "출발국가 택배번호": "",
		// "배송비 ": "",
		// "택배 추가요금": "",
		// "화폐 ": "",
		// "가로(Cm)": "",
		// "세로(Cm)": "",
		// "높이(Cm)": "",
		// "Box 수량": "",
		// "실제무게(KG)": "",
		// "부피무게(KG)": "",
		// "적용무게(KG) ": "",
		// "주민번호/통관번호/CPF": "",
		// IOSS: "",
		// EORI: "",
		// VOEC: "",
		// "상품 바코드": "",
		// 상품목록: "",
		// 상품수량: "",
		// "총 상품금액": "",
		// 화폐단위: "",
		// "발송인 이름": "",
		// "발송인 전화번호": "",
		// "발송인 주소": "",
		// "수취인 이름": "",
		// "수취인 이름 (YOMIGANA)": "",
		// "수취인 휴대폰 번호": "",
		// "수취인 전화번호": "",
		// "수취인 이메일": "",
		// "수취인 우편번호": "",
		// Colony: "",
		// 주: "",
		// 도시: "",
		// "수취인 주소1": "",
		// "수취인 주소2": "",
		// 배송메세지: "",
		// HSCODE: "",
		// "상품종류(1:Commercial,2:Gift,3:ReturnedGood,4:Documents,5:Other)": "",
		// 통관구분: "",
		// "수출신고 유형": "",
		// "판매 site": "",
		// "사용자 데이터 1": "",
		// "사용자 데이터 2": "",
		// "사용자 데이터 3": "",
		// 배송추적: "",

		"특이사항(메모)": "",
		"No.": "",
		"배송 상태": "",
		"배송 추적": "",
		"운송장 출력": "",
		"팩킹리스트 출력": "",
		타입: "",
		"고객사 이름": "",
		배송국가: "",
		배송종류: "",
		접수일: "",
		출고일: "",
		접수번호: "",
		주문번호1: "",
		주문번호2: "",
		배송번호: "",
		"출발국가 택배번호": "",
		배송비: "",
		"택배 추가요금": "",
		화폐: "",
		"가로(Cm)": "",
		"세로(Cm)": "",
		"높이(Cm)": "",
		"Box 수량": "",
		"실제무게(KG)": "",
		"부피무게(KG)": "",
		"적용무게(KG)": "",
		"주민번호/통관번호/CPF": "",
		IOSS: "",
		EORI: "",
		VOEC: "",
		"상품 바코드": "",
		상품목록: "",
		상품수량: "",
		"총 상품금액": "",
		화폐단위: "",
		"발송인 이름": "",
		"발송인 전화번호": "",
		"발송인 주소": "",
		"수취인 이름": "",
		"수취인 이름 (YOMIGANA)": "",
		"수취인 전화번호": "",
		"수취인 휴대폰 번호": "",
		"수취인 이메일": "",
		"수취인 우편번호": "",
		Colony: "",
		주: "",
		도시: "",
		"수취인 주소1": "",
		"수취인 주소2": "",
		배송메세지: "",
		HSCODE: "",
		상품종류: "",
		통관구분: "",
		수출신고번호: "",
		"판매 site": "",
		"사용자 데이터 1": "",
		"사용자 데이터 2": "",
		"사용자 데이터 3": "",
		행방조사: "",
		"수정 / 삭제": "",
	});

	const resetFilter = () => {
		generateSearchData(handleSearch, {});
	};

	return (
		<Stack spacing={"35px"}>
			<Grid container justifyContent={"space-between"} alignItems={"center"}>
				<Grid xs={5}>
					<Grid container xs={12} columnGap={2}>
						<Grid item xs={2} alignItems={"center"}>
							<CustomPopover label="필터" outlined={true}>
								{(close) => {
									return (
										<ShippingFilter
											getFilter={setFilter}
											countries={countries.filter((el) => el.isActive === true)}
											resetFilter={resetFilter}
											closePop={close}
										/>
									);
								}}
							</CustomPopover>
						</Grid>
						<Grid item xs={8}>
							<DropdownAndSearchInput
								onChange={localSearch}
								value={filterQuery}
								defaultValue={{ key: "default", value: "" }}
								inputFieldPlaceHolder={"검색어를 입력하세요."}
								dropDownValues={[
									{ value: "all", title: "검색필터 선택" },
									{ value: "receipt_number", title: "접수번호" },
									{ value: "order_number", title: "주문번호" },
									{ value: "departure_number", title: "출발국가 택배번호" },
									{ value: "arival_number", title: "도착국가 택배번호" },
									{ value: "recipt_name", title: "수취인 이름" },
									{ value: "recipient_address", title: "수취인 주소" },
									{
										value: "recipient_phone",
										title: "수취인 전화번호 ",
									},
								]}
							/>
						</Grid>
					</Grid>
				</Grid>
				<Grid xs={7}>
					<Grid
						container
						columnGap={1}
						xs={12}
						alignItems={"center"}
						justifyContent={"flex-end"}
					>
						<Grid padding="0" xs={2}>
							<WayBillPrint
								onClick={() => setOpenDialog3(true)}
								disabled={checkedList.length <= 0}
							>
								운송장 출력
							</WayBillPrint>
						</Grid>
						<Grid item xs={3} padding="0">
							<CustomButton
								variant={"outlined"}
								onClick={handleExportAll}
								styles={{
									color: "black",
									fontWeigh: "light",
									border: "1px solid #C4C4C4",
									height: "40px",
									borderRadius: "10px",
									cursor: checkedList.length ? "pointer" : "unset",
								}}
							>
								<img
									style={{ paddingRight: "10px" }}
									src="/assets/images/excel.png"
									alt="excel"
								/>
								엑셀 다운로드
							</CustomButton>
						</Grid>

						<Grid item xs={1} padding="0">
							<CustomButton
								styles={{
									border: "1px solid #FF000F",
									backgroundColor: "transparent",
									color: "#FF000F",
									borderRadius: 5,
									alignSelf: "center",
									minWidth: "100%",
									height: "40px",
									cursor: checkedList.length ? "pointer" : "unset",
								}}
								disabled={checkedList.length === 0 ? true : false}
								onClick={() => handleOnHold()}
							>
								보류
							</CustomButton>
						</Grid>
						{/* <Grid item xs={1} padding="0">
              <CustomButton
                onClick={() => {
                  if (checkedList.length) {
                    setOpenDeleteManyConfirmModal(true);
                  }
                }}
                styles={{
                  border: "1px solid #FF000F",
                  backgroundColor: "transparent",
                  color: "#FF000F",
                  borderRadius: 5,
                  alignSelf: "center",
                  minWidth: "100%",
                  height: "40px",
                  cursor: checkedList.length ? "pointer" : "unset",
                }}
              >
                삭제
              </CustomButton>
            </Grid> */}
						<Grid item xs={1}>
							<CustomSelect
								value={String(perPage)}
								label=""
								onChange={({ target }) => {
									handleSetPerPage(target.value);
								}}
								options={["10", "20", "30", "40", "50", "100"]}
							></CustomSelect>
						</Grid>
					</Grid>
				</Grid>

				{/* <Grid item xs={2} alignItems={"end"}>
          <SelectBox onChange={e=>handleChangeRowsPerPage(e)}  value={rowsPerPage}>
            <MenuItem  value="1">10개씩 보기</MenuItem>
            <MenuItem value="2">20개씩 보기</MenuItem>
            <MenuItem value="3">30개씩 보기</MenuItem>
            <MenuItem value="40">40개씩 보기</MenuItem>
            <MenuItem value="50">50개씩 보기</MenuItem>
            <MenuItem value="100">100개씩 보기</MenuItem>

          </SelectBox>
        </Grid> */}
			</Grid>
			<ClientDeliveryRequestTable
				data={data}
				header={[
					<Checkbox
						checked={data.every((res, index) => checkedList.includes(res.id))}
						onChange={({ target }) => {
							if (target.checked) {
								setCheckedList(data.map((res, index) => res.id));
							} else {
								setCheckedList([]);
							}
						}}
					/>,
				].concat(
					Object.keys(columns).map((item, index) => (
						<CustomHeading key={index}>{item}</CustomHeading>
					))
				)}
				handleCheck={handleCheck}
				checkedList={checkedList}
				countTotal={countTotal}
				page={page}
				calculateTotal={calculateTotal}
				setCheckedList={setCheckedList}
				openShipmentTracking={openShipmentTracking}
				handleOpen2={handleOpen2}
				setRequestId={setRequestId}
				handleOpen={handleOpen}
				{...hocProps}
			/>
			{/* <div style={{ position: "relative" }}>
				<TableContainer
					{...{ isLoading: hocProps.isLoading, requestCompleted: true }}
					flipped={"Container Flipped"}
					contentFlip={"Content"}
					headers={[
						"특이사항(메모)",
						<Checkbox
							checked={data.every((res) => checkedList.includes(res.id))}
							onChange={({ target }) => {
								if (target.checked) {
									setCheckedList(data.map((res) => res.id));
								} else {
									setCheckedList([]);
								}
							}}
						/>,

						"No.",
						<div style={{ width: "100px" }}>배송 상태</div>,

						"배송 추적",
						"운송장 출력",
						"팩킹리스트 출력",
						<div style={{ width: "100px" }}>타입</div>,
						<div style={{ width: "150px" }}>고객사 이름</div>,

						"배송국가",
						<div style={{ width: "120px" }}>배송종류</div>,
						<div style={{ width: "150px" }}>접수일</div>,
						"출고일",
						"접수번호",
						"주문번호1",
						"주문번호2",
						"배송번호",
						"출발국가 택배번호",
						"배송비",
						"택배 추가요금",
						<div style={{ width: "100px" }}>화폐</div>,
						"가로(Cm)",
						"세로(Cm)",
						"높이(Cm)",
						"Box 수량",
						"실제무게(KG)",
						"부피무게(KG)",
						"적용무게(KG)",
						"주민번호/통관번호/CPF",
						<div style={{ width: "100px" }}>IOSS</div>,
						<div style={{ width: "100px" }}>EORI</div>,
						<div style={{ width: "100px" }}>VOEC</div>,
						<div style={{ width: "100px" }}>상품 바코드</div>,
						<div style={{ width: "150px" }}>상품목록</div>,

						"상품수량",
						"총 상품금액",
						"화폐단위",
						<div style={{ width: "150px" }}>발송인 이름</div>,

						"발송인 전화번호",
						"발송인 주소",
						"수취인 이름",
						"수취인 이름 (YOMIGANA)",
						"수취인 전화번호",
						"수취인 휴대폰 번호",
						"수취인 이메일",
						"수취인 우편번호",
						<div style={{ width: "150px" }}>Colony</div>,
						"주",
						"도시",
						<div style={{ width: "250px" }}>수취인 주소1</div>,
						<div style={{ width: "250px" }}>수취인 주소2</div>,

						"배송메세지",
						"HSCODE",
						"상품종류",
						"통관구분",
						"수출신고번호",
						"판매 site",
						"사용자 데이터 1",
						"사용자 데이터 2",
						"사용자 데이터 3",
						"행방조사",
						"수정 / 삭제",
					]}
					data={data.map((item, index) => [
						item.miscellaneousInformation?.coupon &&
							`고객사 쿠폰 적용(${item.miscellaneousInformation.coupon})`,
						<Checkbox
							onChange={handleCheck}
							value={item.id}
							checked={checkedList.some((res) => res === item.id)}
						/>,
						countTotal - ((page - 1) * 10 + index),
						item.status === "waiting_warehousing"
							? "입고대기"
							: item.status === "warehousing_completed"
							? "입고완료"
							: item.status === "waiting_delivery"
							? "출고대기"
							: item.status === "started_delivery"
							? "출고완료"
							: item.status === "completed_delivery" && "배송완료",
						<CustomButton
							onClick={() => {
								setActiveScreen(true);
							}}
							styles={{
								backgroundColor: "rgb(91, 143, 247)",
								color: "#FFFFFF",
								borderRadius: 4,
								height: "40px",
								width: "80px",
							}}
						>
							배송추적
						</CustomButton>,
						item.waybillPrintStatus ? "O" : "X",
						item.packingListPrintStatus ? "O" : "X",
						"일반",
						item.user?.company_name_kr,
						item.payeeInformation.shippingCountry.code,
						item.shippingType?.shippingType,
						item.createdAt ? Moment(item.createdAt).format("YYYY-MM-DD") : "",
						<div style={{ width: "120px" }}>
							{item.processReleaseDate
								? Moment(item.processReleaseDate).format("YYYY-MM-DD")
								: ""}
						</div>,
						item.trackingNumber,
						item.productInformation.orderNo1,
						item.productInformation.orderNo2,
						item.shippingNumber,
						item.productInformation.departureCountryCourierNumber,
						item.deliveryFee,
						item.additionalFee,
						"KRW",
						item.miscellaneousInformation.horizontal,
						item.miscellaneousInformation.portrait,
						item.miscellaneousInformation.height,
						item.miscellaneousInformation.boxQuantity,
						item.miscellaneousInformation.actualWeight,
						item.miscellaneousInformation.bulkWeight,
						item.miscellaneousInformation.applicableWeight,
						item.payeeInformation.ssn,
						item.payeeInformation.euioss,

						item.payeeInformation.ukeori,
						item.payeeInformation.ssn,
						<div>
							{item.productInformation.productInformation.map(
								(subItem, index) => {
									return (
										<div>
											<p>
												{subItem.barcode}{" "}
												{subItem.barcode !== "" &&
													index <
														item.productInformation.productInformation.length -
															1 &&
													","}
											</p>
										</div>
									);
								}
							)}
						</div>,
						<div>
							{item.productInformation.productInformation.map(
								(subItem, index) => {
									return (
										<div>
											<p>
												{subItem.productName}{" "}
												{subItem.productName !== "" &&
													index <
														item.productInformation.productInformation.length -
															1 &&
													","}
											</p>
										</div>
									);
								}
							)}
						</div>,
						item?.productInformation?.productInformation?.reduce(
							(previousValue, currentValue) =>
								previousValue + currentValue.quantity,
							0
						),
						calculateTotal(item.productInformation?.productInformation),
						item.productInformation.currency,
						item.senderInformation.name,
						item.senderInformation.phoneNumber,
						<div style={{ width: "250px" }}>
							{" "}
							{item.senderInformation.address}
						</div>,
						item.payeeInformation.name,
						"",
						item.payeeInformation.phoneNumber1,
						item.payeeInformation.phoneNumber2,
						item.payeeInformation.email,
						item.payeeInformation.zipCode,
						item.payeeInformation.mexicoColony,
						item.payeeInformation.states,
						item.payeeInformation.cities,
						item.payeeInformation.streetAddress1,
						item.payeeInformation.streetAddress2,
						item.miscellaneousInformation.shippingMessage,
						<div>
							{item.productInformation.productInformation.map(
								(subItem, index) => {
									return (
										<div>
											<p>
												{subItem.hscode}{" "}
												{subItem.hscode !== "" &&
													index <
														item.productInformation.productInformation.length -
															1 &&
													","}
											</p>
										</div>
									);
								}
							)}
						</div>,
						item.miscellaneousInformation.productType,
						"",
						item.miscellaneousInformation.exportDeclarationNumber != null
							? item.miscellaneousInformation.exportDeclarationNumber
							: "",
						<div>
							{item.productInformation.productInformation.map(
								(subItem, index) => {
									return (
										<div>
											<p>
												{subItem.purchaseURL}
												{subItem.purchaseURL !== "" &&
													index <
														item.productInformation.productInformation.length -
															1 &&
													","}
											</p>
										</div>
									);
								}
							)}
						</div>,
						item.miscellaneousInformation.userData != null
							? item.miscellaneousInformation.userData
							: "",

						item.miscellaneousInformation.userDate2 != null
							? item.miscellaneousInformation.userDate2
							: "",
						item.miscellaneousInformation.userData3 != null
							? item.miscellaneousInformation.userData3
							: "",

						item.whereAbout.length > 0 ? (
							item.whereAbout[0]?.status === "REQUESTED" ? (
								<CustomButton
									styles={{
										width: "140px",
										// border: "1px solid #5B8FF7",
										backgroundColor: "#F2994A",
										color: "white",
										borderRadius: 5,
										alignSelf: "center",
									}}
								>
									행방조사 요청완료
								</CustomButton>
							) : (
								<CustomButton
									styles={{
										width: "140px",
										// border: "1px solid #5B8FF7",
										backgroundColor: "#27AE60",
										color: "white",
										borderRadius: 5,
										alignSelf: "center",
									}}
								>
									행방조사 완료
								</CustomButton>
							)
						) : Moment().isAfter(
								Moment(Moment(item.processReleaseDate).add(15, "days"))
						  ) ? (
							<CustomButton
								styles={{
									width: "140px",
									// border: "1px solid #5B8FF7",
									backgroundColor: "#427DF3",
									color: "white",
									borderRadius: 5,
									alignSelf: "center",
								}}
								onClick={(e) => {
									handleOpen2(index);
									setRequestId(item.productInformation?.requestShippingId);
								}}
							>
								행방조사 요청
							</CustomButton>
						) : (
							<CustomButton
								styles={{
									width: "140px",
									// border: "1px solid #5B8FF7",
									backgroundColor: "#E2E8F0",
									color: "black",
									borderRadius: 5,
									alignSelf: "center",
								}}
								onClick={(e) => {
									handleOpen(index);
								}}
							>
								행방조사 요청
							</CustomButton>
						),

						// index == 1 ? (
						// 	<CustomButton
						// 		styles={{
						// 			width: "140px",

						// 			// border: "1px solid #5B8FF7",
						// 			backgroundColor: "#F2994A",
						// 			color: "white",
						// 			borderRadius: 5,
						// 			alignSelf: "center",
						// 		}}
						// 	>
						// 		행방조사 요청완료
						// 	</CustomButton>
						// ) : index == 2 ? (
						// 	 : (
						// 	<CustomButton
						// 		styles={{
						// 			width: "140px",

						// 			// border: "1px solid #5B8FF7",
						// 			backgroundColor: "#27AE60",
						// 			color: "white",
						// 			borderRadius: 5,
						// 			alignSelf: "center",
						// 		}}
						// 	>
						// 		행방조사 완료
						// 	</CustomButton>
						// ),

						<Box sx={{ display: "flex", justifyContent: "center" }}>
							<Link
								href={
									item.status === "waiting_warehousing"
										? `/RequestShipping?id=${item.id}`
										: "#"
								}
								onClick={() => {
									if (item.status !== "waiting_warehousing")
										Toaster(
											"error",
											"이미 입고 완료 또는 출고 완료된 접수건은 수정 및 삭제가 불가합니다."
										);
								}}
							>
								{
									// eslint-disable-next-line jsx-a11y/alt-text
									<img
										style={{ cursor: "pointer" }}
										src="/assets/images/Edit.svg"
									></img>
								}
							</Link>
							<div
								onClick={() => {
									if (item.status === "waiting_warehousing") {
										setOpenDeleteConfirmModal(true);
										setSelectedDeleteId(item.id);
									} else {
										console.log("not ");
									}
								}}
							>
								{
									// eslint-disable-next-line jsx-a11y/alt-text
									<img
										style={{ width: "16px", cursor: "pointer" }}
										src="/assets/images/Delete.png"
									></img>
								}
							</div>
						</Box>,
					])}
					// data={data.map((item, index) => [
					//   <Checkbox />,
					//   {item.no}
					//   "엠비케이 엠비케이투",
					//   "0",
					//   <div style={{ color: "blue" }}> 0</div>,
					//   <div style={{ color: "red" }}> 0</div>,
					//   // <Link>배송비 내역</Link>,
					// ])}
				/>
			</div> */}
			{/* <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: 30,
        }}
      >
        <Pagination count={10} color="primary" shape="rounded" />
      </div> */}
			<Modal1
				description={
					<div>
						<span style={{ color: "#427DF3", lineHeight: "70px" }}>
							아직 행방조사를 접수할 수 없습니다.
						</span>
						<br />
						<span style={{ color: "#427DF3" }}>화물 출고 이후 15일 </span>{" "}
						뒤부터 행방조사 접수가 가능합니다. 행방 조사 접수 전에 아래에 대한
						사항을 확인 부탁드립니다.
						<br />
						<br />
						<span>
							i. 현재 화물이 수취인의 부재 또는 배송사의 사정으로 인해 픽업이
							요청된 화물인지 확인 부탁드립니다.
							<br />
							<br />
						</span>
						<span>
							ii. 올바르지 않은 배송주소 (아파트 호수 누락 등) 또는 올바르지
							않은 수취인의 휴대전화번호 로 인해 배송이 진행되고 있지 않은 경우
							수취인께서 현지 우체국에 직접 연락을 취하셔야 되기 때문에 확인
							부탁드립니다.
							<br />
							<br />
						</span>
						<span>
							iii. 발송화물에 대해 세관에서 확인을 위하여 수취인께 연락을 취할
							수 있습니다. 수취인께서 유선 연락 또는 메시지를 받으신 것이 있는지
							확인 부탁드립니다.
							<br />
						</span>
					</div>
				}
				title={"행방조사 접수"}
				warningIcon={false}
				buttonText={"닫기"}
				onClose={handleCloseConfirmDialog}
				isOpen={openDialog}
			/>

			<Modal3
				title="운송장 출력"
				description={
					<div>
						<span style={{ color: "#427DF3", lineHeight: "70px" }}>
							아직 행방조사를 접수할 수 없습니다.
						</span>
						<br />
						<span style={{ color: "#427DF3" }}>화물 출고 이후 15일 </span>{" "}
						뒤부터 행방조사 접수가 가능합니다. 행방 조사 접수 전에 아래에 대한
						사항을 확인 부탁드립니다.
						<br />
						<br />
						<span>
							i. 현재 화물이 수취인의 부재 또는 배송사의 사정으로 인해 픽업이
							요청된 화물인지 확인 부탁드립니다.
							<br />
							<br />
						</span>
						<span>
							ii. 올바르지 않은 배송주소 (아파트 호수 누락 등) 또는 올바르지
							않은 수취인의 휴대전화번호 로 인해 배송이 진행되고 있지 않은 경우
							수취인께서 현지 우체국에 직접 연락을 취하셔야 되기 때문에 확인
							부탁드립니다.
							<br />
							<br />
						</span>
						<span>
							iii. 발송화물에 대해 세관에서 확인을 위하여 수취인께 연락을 취할
							수 있습니다. 수취인께서 유선 연락 또는 메시지를 받으신 것이 있는지
							확인 부탁드립니다.
							<br />
						</span>
					</div>
				}
				warningIcon={false}
				buttonText={"닫기"}
				onClose={handleCloseConfirmDialog3}
				isOpen={openDialog3}
				onConfirm={wayBillPrint}
			/>

			<Modal2
				buttonText={"닫기"}
				warningIcon={false}
				title={"행방조사 접수"}
				onClose={handleCloseConfirmDialog2}
				isOpen={openDialog2}
				onConfirm={requestHandle}
				setRequestDescription={setRequestDescription}
				description={
					<div>
						<span>
							행방조사를 접수하신 화물에 대해 빠른 답변을 드릴 수 있도록
							조치하겠습니다. 행방 조사 접수 전 아래에 대한 사항을 확인
							부탁드립니다.
						</span>
						<br />
						<br />
						<span>
							i. 현재 화물이 수취인의 부재 또는 배송사의 사정으로 인해 픽업이
							요청된 화물인지 확인 부탁드립니다.
							<br />
							<br />
						</span>
						<span>
							ii. 올바르지 않은 배송주소 (아파트 호수 누락 등) 또는 올바르지
							않은 수취인의 휴대전화번호 로 인해 배송이 진행되고 있지 않은 경우
							수취인께서 현지 우체국에 직접 연락을 취하셔야 되기 때문에 확인
							부탁드립니다.
							<br />
							<br />
						</span>
						<span>
							iii. 발송화물에 대해 세관에서 확인을 위하여 수취인께 연락을 취할
							수 있습니다. 수취인께서 유선 연락 또는 메시지를 받으신 것이 있는지
							확인 부탁드립니다.
							<br />
						</span>
					</div>
				}
			/>

			<TrackingModal
				isOpen={activeScreen}
				onClose={handleClose}
				shipmentTrackingData={shipmentTrackingData}
			/>

			{/* <DepositProcessingModal
        selectedUserId={selectedUserId}
        isOpen={openDepositModal}
        onClose={handleClose}
        onOpen={handleOpenModal}
      /> */}
			{openDeleteConfirmModal && (
				<ConfirmModal
					isLoading={isLoading}
					isOpen={openDeleteConfirmModal}
					onClose={() => setOpenDeleteConfirmModal(false)}
					onConfirm={handleDelete}
					title={"삭제"}
					description={"해당 배송 접수건을 삭제하시겠습니까?"}
				/>
			)}
			{openDeleteManyConfirmModal && (
				<ConfirmModal
					isLoading={isLoading}
					isOpen={openDeleteManyConfirmModal}
					onClose={() => setOpenDeleteManyConfirmModal(false)}
					onConfirm={handleDeleteMany}
					title={"삭제"}
					description={"해당 배송 접수건을 삭제하시겠습니까?"}
				/>
			)}
		</Stack>
	);
};

export default ShippingList;

const CustomHeading = styled.div`
	padding: ${(props) => (props.padding ? props.padding : "0 30px")};
`;

const WayBillPrint = styled(CustomButton)`
	padding: 7px !important;
	background: transparent;
	color: #000;
	border: 1px solid #c4c4c4;

	&:hover {
		background-color: transparent;
		border: 1px solid #000;
	}
`;

const getSelectedShippingRequests = async (filter) => {
	const Query = gql(`
	  query($filter:RequestShippingFilterInput)
	{
	allRequestShippings(filter:$filter){
		  id
		  bag {
			  id
		  
		  }
		  senderInformation {
			  id
			  name
			  phoneNumber
			  address
			  requestShippingId
			  deleted
			  createdAt
			  updatedAt
		  }
		  whereAbout{
			  id
			  status
			  requestShippingId
			  requestShipping{
				  id
			  
			  }
			  memo
	  
		  }
		  payeeInformation {
			  id
			  name
			  shippingCountryId
			  shippingCountry{
				  id
				  name
				  code
				  monetaryUnit
			  }
			  phoneNumber1
			  phoneNumber2
			  zipCode
			  ssn
			  ukeori
			  euioss
			  states
			  cities
			  streetAddress1
			  streetAddress2
			  email
			  requestShippingId
			  mexicoColony
			  deleted
			  createdAt
			  updatedAt
			  stateCode
			  
		  }
		  miscellaneousInformation {
			  id
			  shippingMessage
			  horizontal
			  portrait
			  height
			  actualWeight
			  applicableWeight
			  bulkWeight
			  userData
			  userDate2
			  userData3
			  boxQuantity
			  productType
			  exportDeclarationNumber
			  requestShippingId
			  deleted
			  createdAt
			  updatedAt
			  
		  }
		  productInformation {
			  id
			  orderNo1
			  orderNo2
			  departureCountryCourierNumber
			  currency
			  productInformation {
				  id
				  barcode
				  productName
				  quantity
				  unitPrice
				  brandName
				  sku
				  purchaseURL
				  hscode
				  deleted
				  createdAt
				  updatedAt
	  
			  }
			  totalPriceAmount
			  requestShippingId
			  deleted
			  createdAt
			  updatedAt
		  
		  }
	  
		  user {
			  id
			  username
			  userID
			  company_registration_number
			  company_name_kr
			  company_name_en
			  customer_name
			  totalDeposit
			  email
			  memo
			  request_issuance_of_tax_invoice
			  e_tax_invoice_email
			  balance
			  business_condition
			  phone_number
			  mobile_number
			  
		  }
		  shippingType{
			  id
			  shippingType
			  apiType
			  apiType_{
			  id
			  name
			  }
			  shippingWeight
			  memo
			  shippingTypeId
			  createdAt
		  }
		  shippingCountry{
			  id
			  name
			  code
			  monetaryUnit
			  shippingTypesDetailsIds
			  isActive
		  }
		  userId
		  deleted
		  createdAt
		  updatedAt
		  status
		  trackingNumber
		  shippingNumber
		  additionalFee
		  orderNumber
		  weightValidation
		  printWaybillAfterWeightChange
		  sampoongAmountCheck
		  treatmentAfterWeightChange
		  processReleaseDate
	  }
  }
		  `);

	try {
		const res = await APIClient.query({
			query: Query,
			variables: {
				filter: {
					id: filter,
				},
			},
		});
		return res.data?.allRequestShippings;
	} catch (e) {
		console.log(e);
	}
};
