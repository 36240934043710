import React, { createContext, useState } from "react";
import { Box } from "@mui/material";

export const DeliveryRequestContext = createContext({
  userId: null,
  setUserId: (userId) => {},
});

export const DeliveryRequestContextProvider = ({ children, props }) => {
  const [userId, setUserId] = useState(null);

  const handleSetUserId = (id) => {
    if (id) {
      setUserId(id);
    }
  };
  return (
    <DeliveryRequestContext.Provider
      value={{
        userId,
        setUserId: handleSetUserId,
      }}
    >
      {React.cloneElement(children, { ...props })}
    </DeliveryRequestContext.Provider>
  );
};
