import { Box, Divider, Modal, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import CustomButton from "../components/CustomButton";
import CustomCard from "../components/CustomCard";
import Icons from "../utils/icon";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";

export default function PrintWaybillModal({
	isOpen,
	onClose,
	onConfirm,
	isLoading = false,
	title = "",
	description = "",
	buttonText = "",
	warningIcon,
	showPackage = true,
}) {
	const [shipexport, setShipexport] = useState(false);

	return (
		<Modal
			open={isOpen}
			onClose={onClose}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Box
				style={{
					padding: 20,
					overflowX: "hidden",
					minHeight: "100vh",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				<CustomCard>
					<Stack spacing={"20px"} width={350} p="20px">
						<Stack direction="row" alignItems={"center"}>
							<div>{warningIcon ? <Icons.Warning /> : ""}</div>

							<Typography fontSize={"20px"} fontWeight="700">
								{title ? title : " 고객 문의사항"}
							</Typography>
						</Stack>

						<div>
							<Typography
								// lineHeight={"0.5"}
								fontSize={"14px"}
								color="rgba(0,0,0,0.6)"
								component={"div"}
							>
								선택하신 접수건의 운송장을 출력하시겠습니까?
							</Typography>
						</div>
						{showPackage && (
							<div>
								<Typography
									fontWeight={"600"}
									color="rgba(0,0,0,0.6)"
									component={"div"}
								>
									팩킹리스트 포함 여부
								</Typography>
								<FormControl>
									<RadioGroup
										row
										aria-labelledby="demo-row-radio-buttons-group-label"
										name="row-radio-buttons-group"
										value={shipexport}
										onClick={() => setShipexport((shipexport) => !shipexport)}
									>
										<FormControlLabel
											value={true}
											control={<Radio />}
											label="예"
										/>
										<FormControlLabel
											value={false}
											control={<Radio />}
											label="아니오"
										/>
									</RadioGroup>
								</FormControl>
							</div>
						)}
						<Divider />

						<div
							style={{
								display: "flex",
								justifyContent: "flex-end",
								gap: "20px",
							}}
						>
							<CustomButton
								onClick={onClose}
								styles={{
									background: "#F8F8FA",
									color: "#000",
									width: "auto",
								}}
							>
								{buttonText ? buttonText : "취소"}
							</CustomButton>
							<CustomButton
								disabled={isLoading}
								styles={{ width: "auto" }}
								onClick={() => onConfirm(shipexport)}
							>
								출력
							</CustomButton>
						</div>
					</Stack>
				</CustomCard>
			</Box>
		</Modal>
	);
}
