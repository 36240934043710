import { Divider, Grid, Switch, Typography } from "@mui/material";

import CustomInput, { TextFieldBox } from "../CustomInput";
import { useWebSocket } from "react-use-websocket/dist/lib/use-websocket";
import WeightInfoBulkWeight from "./WeightInfoBulkWeight";
import { useAtom } from "jotai";
import { fixedWeightAtom, weightValidationAtom } from "./atom";
import { changeWeightAtom } from "./atom";
import styled from "styled-components";
import { useEffect } from "react";

const WeightInfo = () => {
  const { lastJsonMessage, readyState } = useWebSocket("ws://127.0.0.1:2012");
  const [weight, setWeight] = useAtom(changeWeightAtom);
  const [weightValidation, setWeightValidation] = useAtom(weightValidationAtom);
  const onChange = (e) => {
    if (e.target.name === "actualWeight" && fixedWeight) {
      return;
    }
    setWeight({ [e.target.name]: parseFloat(e.target.value) });
  };
  const [fixedWeight, setFixedWeight] = useAtom(fixedWeightAtom);

  useEffect(() => {
    if (lastJsonMessage && !!!fixedWeight) {
      let dataW = parseFloat(lastJsonMessage.data);
      setWeight({ actualWeight: dataW });
    }
  }, [lastJsonMessage, fixedWeight]);

  return (
    <Grid
      container
      style={{
        borderRadius: "10px",
        overflow: "hidden",
        border: "1px solid #E5E5E5",
      }}
    >
      <Grid item container xs={12}>
        <Grid item xs={6}>
          <CustomInput
            input={
              <>
                <LockInputContainer>
                  <TextFieldBox
                    key={"miscellaneousInformation.actualWeight"}
                    onChange={onChange}
                    value={weight?.actualWeight}
                    name="actualWeight"
                    type={"number"}
                    styles={{ width: 100 }}
                    step={0.01}
                    disable={fixedWeight}
                  />
                  <Typography>kg</Typography>
                </LockInputContainer>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <Switch
                    name={"fixedWeight"}
                    onChange={({ target }) => setFixedWeight(target.checked)}
                    checked={fixedWeight}
                  />
                </div>
                <p>(*고정 무게값 적용)</p>
              </>
            }
            label="측정 무게"
            labelWidth="55%"
            padding="14px 20px 10px 20px"
          />
        </Grid>
        <Grid item xs={6}>
          <LockInputContainer>
            <CustomInput
              input={<Span>{readyState === 1 ? "연결성공" : "연결실패"}</Span>}
              label="연결 상태"
              padding="14px 20px 10px 20px"
              labelWidth="55%"
            />
          </LockInputContainer>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Divider />
      </Grid>

      <Grid item xs={12}>
        <CustomInput
          input={<WeightInfoBulkWeight />}
          label="부피 무게"
          labelWidth="22%"
          padding="15px 20px 10px 20px"
        />
      </Grid>

      <Grid item xs={12}>
        <Divider />
      </Grid>

      <Grid item container xs={12}>
        <Grid item xs={6}>
          <CustomInput
            input={
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  marginLeft: "10px",
                }}
              >
                <TextFieldBox
                  readOnly={true}
                  onChange={onChange}
                  min={0}
                  step={0.001}
                  type={"number"}
                  value={weight?.applicableWeight}
                  name="applicableWeight"
                  styles={{ width: 100 }}
                />
                <Typography>kg</Typography>
              </div>
            }
            label="적용 무게"
            labelWidth="55%"
            padding="15px 20px 10px 20px"
          />
        </Grid>
        <Grid item xs={6}>
          <CustomInput
            input={
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <Switch
                  name={"weightValidation"}
                  onChange={({ target }) => setWeightValidation(target.checked)}
                  value={weightValidation}
                />
              </div>
            }
            label="최고무게 체크"
            labelWidth="55%"
            padding="15px 20px 10px 20px"
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default WeightInfo;

const LockInputContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: 10px;
  * {
    -webkit-text-fill-color: black !important;
  }
`;

const Span = styled.span`
  padding: 0 10px;
`;
