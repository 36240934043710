import { Divider, Grid, MenuItem, Stack } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import CustomButton from "../../components/CustomButton";
import { SelectBox } from "../../components/CustomInput";
import CustomPopover from "../../components/CustomPopover";
import TableContainer from "../../components/TableContainer";
import ListHOC from "../../SharedComponents/ListHOC";
import DropdownAndSearchInput from "../../components/DropdownAndSearchInput";
import { onDropDownSearchChange } from "../../utils/onDropDownSearchChange";
import { Form, Formik } from "formik";
import Moment from "moment";
import {
	generateSearchData,
	handleOtherSearch,
	useUtils,
} from "../../utils/utils";
import DateRangePicker from "../../components/DateRangePicker";

export default function ClientDepositHistory(props) {
	const [filter, setFilter] = useState(null);
	const { userData } = useUtils();

	useEffect(() => {
		if (userData) {
			setFilter({ userId: userData.id });
		}
	}, []);

	return filter ? (
		<ListHOC showFilter={filter} {...{ hocFilter: filter, ...props }}>
			<ClientDepositHistoryContainer />
		</ListHOC>
	) : (
		<></>
	);
}

function ClientDepositHistoryContainer({
	data: _Data,
	handleSearch,
	perPage,
	handlePageChange,
	...hocProps
}) {
	const [data, setData] = useState([]);

	useEffect(() => {
		setData(_Data);
	}, [_Data]);

	const [filterQuery, setFilterQuery] = useState(null);

	const doSearch = useCallback(() => {
		generateSearchData(handleSearch, filterQuery);
	}, [filterQuery]);

	useEffect(() => {
		doSearch();
	}, [filterQuery]);

	return (
		<Stack spacing={"35px"}>
			<Grid container justifyContent={"space-between"}>
				<Grid item xs={7}>
					<Grid container spacing="10px">
						<Grid item xs={3}>
							<CustomPopover>
								{({ close }) => {
									return (
										<Formik
											enableReinitialize
											initialValues={
												filterQuery || {
													createdAt: `${Moment().format(
														"YYYY-MM-DD HH:mm"
													)} - ${Moment().format("YYYY-MM-DD HH:mm")}`,
												}
											}
											onSubmit={(values) => {
												handleOtherSearch(values, setFilterQuery);
											}}
										>
											{({ values, handleSubmit, setFieldValue, resetForm }) => {
												return (
													<Form onSubmit={handleSubmit}>
														<Stack spacing={"20px"} width={370} p="20px">
															<div>
																<DateRangePicker
																	name={"createdAt"}
																	value={values?.["createdAt"]}
																	setFieldValue={setFieldValue}
																/>
															</div>

															<Divider />

															<div
																style={{
																	display: "flex",
																	justifyContent: "flex-end",
																	gap: "20px",
																}}
															>
																<CustomButton
																	styles={{
																		backgroundColor: "#F8F8FA",
																		padding: "5px",
																		color: "#000",
																		width: "auto",
																	}}
																>
																	취소
																</CustomButton>
																<CustomButton
																	variant={"outlined"}
																	onClick={() => {
																		resetForm();
																	}}
																	styles={{
																		width: "auto",
																		padding: "8px",
																	}}
																>
																	필터 초기화
																</CustomButton>
																<CustomButton
																	type={"submit"}
																	onClick={handleSubmit}
																	styles={{ padding: "5px", width: "auto" }}
																>
																	전송
																</CustomButton>
															</div>
														</Stack>
													</Form>
												);
											}}
										</Formik>
									);
								}}
							</CustomPopover>
						</Grid>
						<DropdownAndSearchInput
							onChange={(data) => onDropDownSearchChange(data, setFilterQuery)}
							value={filterQuery}
							defaultValue={{ key: "company_name_en", value: "" }}
							dropDownValues={[{ value: "company_name_en", title: "업체명" }]}
						/>
					</Grid>
				</Grid>

				<Grid item xs={1.5}>
					<Grid container spacing="10px">
						<Grid item xs={12}>
							<SelectBox value="엑셀 다운로드">
								<MenuItem value="엑셀 다운로드">엑셀 다운로드</MenuItem>
								<MenuItem value="예치금 내역 다운로드">
									예치금 내역 다운로드
								</MenuItem>
							</SelectBox>
						</Grid>
					</Grid>
				</Grid>
			</Grid>

			<TableContainer
				{...hocProps}
				headers={[
					"아니요",
					"업체명",
					"입금액",
					"출금액",
					"잔액",
					"메모",
					"입금 처리 날짜",
					"배송번호",
					"내용",
				]}
				data={data.map((item, index) => [
					item.id,
					item.userId,
					item.type === "deposit" ? item.amount : "",
					item.type === "withdraw" ? item.amount : "",
					item.leftBalance,
					item.memo,
					item.deposit_processing_date,
					item.shipping_number,
					item.details,
				])}
			/>
		</Stack>
	);
}
