import {
	Box,
	Divider,
	FormControl,
	MenuItem,
	Modal,
	Select,
	Stack,
	Typography,
} from "@mui/material";
import React, { useRef, useState } from "react";
import CustomButton from "../components/CustomButton";
import CustomCard from "../components/CustomCard";
import CustomInput, { TextFieldBox } from "../components/CustomInput";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import CreateHOC from "../SharedComponents/CreateHOC";
import UpdateHOC from "../SharedComponents/UpdateHOC";
import Moment from "moment";
import { useDataProvider } from "react-admin";
import { styled } from "@mui/styles";
import { makeStyles } from "@mui/styles";
import FormHelperText from "@mui/material/FormHelperText";
import { isPageKeys } from "@mui/x-data-grid/utils/keyboardUtils";
import { useMovemany } from "../utils/utils";
import Toaster from "../utils/Toaster";
import { gql } from "@apollo/client";

const MyMenuItem = styled(MenuItem)({
	"&:focus::after": {
		opacity: 0,
	},
});

const useStyles = makeStyles({
	formHelper: { color: "#d32f2f !important" },
});

// function updateMasterModal({ selected, onClose, ...props }) {
//   return React.createElement(selected ? UpdateHOC : CreateHOC, {
//     ...props,
//     selected,
//     handleClose: onClose,
//     children: BagManagementModalContainer,
//   });
// }

// export default React.memo(updateMasterModal);

export default function BagManagementModalContainer({
	isOpen,
	handleClose,
	selected,
	onSuccess,
	additionalFormDetails,
	handelCloseUpdateModal,
	setIsMasterPackagingModalOpen,
	data,
	checkedList,
	setCheckedList,
	setData,
	selectedDeleteId,
	setSelectedDeleteId,
	freshData,
	...hocProps
}) {
	const classes = useStyles();
	const [initialValues, setInitialValues] = useState({
		master: null,
	});

	const [deliveryTypes, setDeliveryTypes] = useState([]);
	const [deliveryServices, setDeliveryServices] = useState([]);
	const { isLoading, moveMany } = useMovemany();

	const [bagId, setbagId] = useState(null);

	const dataProvider = useDataProvider();

	React.useEffect(() => {
		if (selected) {
			const { id, "master.id": master } = selected;
			setInitialValues({
				id,
				master,
			});
		}
	}, [selected]);

	const validationSchema = () => {
		return Yup.object().shape({
			master: Yup.number().required("selection is required"),
		});
	};

	const handleSubmit = async (values, formikUtils) => {
		let input = {};
		if (selected) {
			input = {
				updateInput: {
					...values,
				},
			};
		} else {
			input = {
				createInput: {
					...values,
				},
			};
		}

		const res = await hocProps.handleSubmit({ ...input }, formikUtils);
		if (res) {
			const { data, reason } = res;
			onSuccess(data, reason);
		}
	};

	const handleMoveToBag = async () => {
		if (!(selectedDeleteId || checkedList.length)) {
			return Toaster(
				"error",
				"You have to select atleast one bag to perform this action."
			);
		}
		const moveMutation = gql(`
  mutation moveManyToBag($input: MoveManyRequestToBag_Input!){
    moveManyToBag(input: $input){
      ids
    }
  }`);

		moveMany({
			ids: selectedDeleteId ? [selectedDeleteId] : checkedList,
			bagId: bagId,
			setData,
			moveMutation,
			onSuccess: handleMoveSuccess,
			responseKey: "moveManyToBag",
		});
	};
	const handleMoveSuccess = () => {
		freshData();
		setCheckedList([]);
		setIsMasterPackagingModalOpen(false);
	};

	return (
		<>
			<Modal
				open={isOpen}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
				sx={{ overflow: "scroll" }}
			>
				<Box
					style={{
						padding: 20,
						overflowX: "hidden",
						minHeight: "100vh",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					<Formik
						initialValues={initialValues}
						onSubmit={handleSubmit}
						enableReinitialize
						validationSchema={validationSchema}
					>
						{({
							errors,
							handleChange,
							touched,
							handleSubmit,
							values,
							submitCount,
							isSubmitting,
							isValid,
						}) => {
							return (
								<Form onSubmit={handleSubmit}>
									<CustomCard>
										<Stack spacing={"20px"} width={500} p="20px">
											<Typography fontSize={"20px"} fontWeight="700">
												BAG 이동
											</Typography>
											<Divider />
											<Box
												my={2}
												display={"flex"}
												flexDirection={"column"}
												gap={1.5}
											>
												<Box
													display={"flex"}
													flexDirection={"column"}
													gap={1.5}
												>
													<label>
														<Typography variant={"body2"} fontWeight={"bold"}>
															이동할 BAG을 선택해주세요.
														</Typography>
													</label>
													<FormControl
														size="small"
														sx={{
															width: "100%",
															m: 0,
														}}
														variant="outlined"
													>
														<Select
															sx={{ borderRadius: 8 }}
															name={"master"}
															// defaultValue={bagId ? bagId : "default"}
															value={bagId}
															onChange={(e) => setbagId(e.target.value)}
															error={
																submitCount > 0 &&
																!isSubmitting &&
																!isValid &&
																!!errors.master
															}
															MenuProps={{
																sx: {
																	"&& .Mui-selected": {
																		backgroundColor: "#E0E0E0",
																	},
																},
															}}
															style={{
																borderRadius: "5px",
																boxShadow: "0px 0px 5px rgba(31, 65, 115, 0.1)",
																border: "1px solid #EBEBEB",
																width: "100%",
															}}
														>
															{/* <MenuItem value="default" disabled hidden>
																default
															</MenuItem> */}
															{additionalFormDetails.BagList.map(
																(item, index) => (
																	<MyMenuItem value={item.id} key={index}>
																		{item.name}
																	</MyMenuItem>
																)
															)}
														</Select>
														<FormHelperText
															className={classes.formHelper}
															sx={{ ml: "0px" }}
														>
															{errors.master && touched.master && errors.master}
														</FormHelperText>
													</FormControl>
												</Box>
											</Box>

											<Divider />

											<div
												style={{
													display: "flex",
													justifyContent: "flex-end",
													gap: "20px",
												}}
											>
												<CustomButton
													onClick={(e) => setIsMasterPackagingModalOpen(false)}
													styles={{
														backgroundColor: "#F8F8FA",
														color: "#000",
														width: "auto",
													}}
												>
													취소
												</CustomButton>
												<CustomButton
													styles={{
														width: "auto",
													}}
													onClick={handleMoveToBag}
												>
													전송
												</CustomButton>
											</div>
										</Stack>
									</CustomCard>
								</Form>
							);
						}}
					</Formik>
				</Box>
			</Modal>
		</>
	);
}
