import React, { useState, memo } from "react";
import { Form, Formik } from "formik";
import CustomButton from "../components/CustomButton";
import DateRangePicker from "../components/DateRangePicker";
import CustomInput, { SelectBox } from "../components/CustomInput";
import Moment from "moment";
import { Divider, MenuItem, Stack } from "@mui/material";

const initialValues = {
	dateOfReceipt: "",
	dateOfRelease: "",
	startDate: "",
	endDate: "",
	sort: "",
	alignment: "",
	deliveryCountry: "",
	companyName: "",
	salesSite: "",
	deliveryStatus: "",
	waybillPrintStatus: "",
	packingListPrintStatus: "",
};

const ShippingFilter = memo(
	({ closePop, getFilter, countries = [], resetFilter }) => {
		return (
			<Formik
				enableReinitialize
				initialValues={initialValues}
				onSubmit={(values, { setSubmitting }) => {
					setTimeout(() => {
						getFilter(values);
						closePop.close();
						setSubmitting(false);
					}, 400);
				}}
			>
				{({ values, handleSubmit, setFieldValue, handleChange, resetForm }) => {
					return (
						<Form onSubmit={handleSubmit}>
							<Stack spacing={"20px"} width={600} p="20px">
								<div>
									{/* <CustomInput
                  type="date"
                  value={values?.dateOfReceipt}
                  onChange={(e) => handleChange(e, setFieldValue)}
                  name="dateOfReceipt"
                  label="접수일"
                  labelColor=""
                  placeholder={"검색어를 입력해주세요."}
                /> */}
									<DateRangePicker
										label="접수일"
										name={"dateOfReceipt"}
										value={values?.dateOfReceipt}
										onChange={handleChange}
										setFieldValue={setFieldValue}
									/>
									<DateRangePicker
										label="출고일"
										name={"dateOfRelease"}
										value={values?.dateOfRelease}
										setFieldValue={setFieldValue}
										onChange={handleChange}
									/>
									{/* <CustomInput
                  type="date"
                  value={values.dateOfRelease}
                  onChange={(e) => handleChange(e, setFieldValue)}
                  label="출고일"
                  name="dateOfRelease"
                  labelColor=""
                  placeholder={"검색어를 입력해주세요."}
                /> */}
									<CustomInput
										select
										labelColor=""
										label="정렬"
										name="정렬"
										input={
											<SelectBox
												name={"sort"}
												onChange={handleChange}
												value={values.sort}
											>
												<MenuItem value="desc">접수번호 내림차순</MenuItem>
												<MenuItem value="asc">접수번호 오름차순</MenuItem>
											</SelectBox>
										}
									/>

									<CustomInput
										select
										labelColor=""
										label="배송국가"
										name="배송국가"
										input={
											<SelectBox
												name={"deliveryCountry"}
												onChange={handleChange}
												value={values.deliveryCountry}
											>
												<MenuItem value={""}>Select a Country</MenuItem>
												{countries.map((d) => (
													<MenuItem key={d.id} value={d.id}>
														{d.name}
													</MenuItem>
												))}
											</SelectBox>
										}
									/>

									<CustomInput
										label={"판매 사이트"}
										labelColor=""
										name="salesSite"
										value={values.salesSite}
										onChange={handleChange}
										placeholder={"판매 사이트"}
									/>

									<CustomInput
										select
										labelColor=""
										label="배송상태"
										name="배송상태"
										input={
											<SelectBox
												name={"deliveryStatus"}
												onChange={handleChange}
												value={values.deliveryStatus}
											>
												<MenuItem value={""}>배송상태</MenuItem>
												<MenuItem value={"waiting_warehousing"}>
													입고대기
												</MenuItem>
												<MenuItem value={"warehousing_completed"}>
													입고완료
												</MenuItem>
												<MenuItem value={"waiting_delivery"}>출고대기</MenuItem>
												<MenuItem value={"started_delivery"}>출고완료</MenuItem>
												<MenuItem value={"delivery_completed"}>
													배송완료
												</MenuItem>
											</SelectBox>
										}
									/>

									<CustomInput
										select
										labelColor=""
										label="운송장 출력"
										name="운송장 출력"
										input={
											<SelectBox
												name={"waybillPrintStatus"}
												onChange={handleChange}
												value={values.waybillPrintStatus}
											>
												<MenuItem value={""}>출력여부</MenuItem>
												<MenuItem
													value={false}
													selected={values.waybillPrintStatus === false}
												>
													출력전
												</MenuItem>
												<MenuItem
													value={true}
													selected={values.waybillPrintStatus === true}
												>
													출력후
												</MenuItem>
											</SelectBox>
										}
									/>
									<CustomInput
										select
										labelColor=""
										label="팩킹리스트 출력"
										name="팩킹리스트 출력"
										input={
											<SelectBox
												name={"packingListPrintStatus"}
												onChange={handleChange}
												value={values.packingListPrintStatus}
											>
												<MenuItem value={""}>출력여부</MenuItem>
												<MenuItem value={false}>출력전</MenuItem>
												<MenuItem value={true}>출력후</MenuItem>
											</SelectBox>
										}
									></CustomInput>
								</div>

								<Divider />

								<div
									style={{
										display: "flex",
										justifyContent: "flex-end",
										gap: "20px",
									}}
								>
									{/* <CustomButton
                  styles={{
                    backgroundColor: "#F8F8FA",
                    padding: "5px",
                    color: "#000",
                    width: "auto",
                  }}
                >
                  검색
                </CustomButton> */}
									<CustomButton
										variant={"outlined"}
										styles={{
											width: "auto",
											padding: "8px",
										}}
										onClick={() => {
											resetFilter();
											closePop.close();
										}}
									>
										필터 초기화
									</CustomButton>
									<CustomButton
										type="submit"
										styles={{
											padding: "5px",
											width: "auto",
										}}
									>
										적용
									</CustomButton>
								</div>
							</Stack>
						</Form>
					);
				}}
			</Formik>
		);
	}
);
export default ShippingFilter;
