import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";

export default function CustomSelect({
	label = "업체명",
	options = ["Option 1"],
	onChange = (e) => {},
	value = "10",
}) {
	return (
		<FormControl size="small" sx={{ width: "100%", m: 0 }}>
			<InputLabel
				style={{ background: "#fff", padding: "0 5px" }}
				variant="outlined"
			>
				{label}
			</InputLabel>
			<Select
				variant="outlined"
				value={value}
				onChange={onChange}
				style={{
					borderRadius: "10px",
					// boxShadow: "0px 0px 5px rgba(31, 65, 115, 0.1)",
					// border: "1px solid #EBEBEB",
					width: "100%",
				}}
			>
				{options.map((item, index) => (
					<MenuItem value={item} key={index}>
						{item == 10
							? "10개씩 보기"
							: item == 20
							? "20개씩 보기"
							: item == 30
							? "30개씩 보기"
							: item == 40
							? "40개씩 보기"
							: item == 50
							? "50개씩 보기"
							: item == 100
							? "100개씩 보기"
							: item}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
}
