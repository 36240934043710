import {
	Box,
	Divider,
	FormControl,
	MenuItem,
	Modal,
	Select,
	Stack,
	Typography,
} from "@mui/material";
import React, { useRef, useState } from "react";
import CustomButton from "../components/CustomButton";
import CustomCard from "../components/CustomCard";
import CustomInput, { TextFieldBox } from "../components/CustomInput";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import CreateHOC from "../SharedComponents/CreateHOC";
import UpdateHOC from "../SharedComponents/UpdateHOC";
import Moment from "moment";
import { useDataProvider } from "react-admin";
import { styled } from "@mui/styles";
import { makeStyles } from "@mui/styles";
import FormHelperText from "@mui/material/FormHelperText";
import { gql } from "@apollo/client";
import { APIClient } from "../utils/apiGraphql";
import Toaster from "../utils/Toaster";

const MyMenuItem = styled(MenuItem)({
	"&:focus::after": {
		opacity: 0,
	},
});

const useStyles = makeStyles({
	formHelper: { color: "#d32f2f !important" },
});

function updateMasterModal({ selected, onClose, ...props }) {
	return React.createElement(selected ? UpdateHOC : CreateHOC, {
		...props,
		selected,
		handleClose: onClose,
		children: BagManagementModalContainer,
	});
}

export default React.memo(updateMasterModal);

const BagManagementModalContainer = ({
	isOpen,
	handleClose,
	selected,
	onSuccess,
	additionalFormDetails,
	...hocProps
}) => {
	const classes = useStyles();
	const [initialValues, setInitialValues] = useState({
		master: null,
	});

	const [deliveryTypes, setDeliveryTypes] = useState([]);
	const [deliveryServices, setDeliveryServices] = useState([]);
	const [selectedData, setSelectedData] = useState({
		bagIds: [],
		masterId: "",
	});

	const dataProvider = useDataProvider();

	React.useEffect(() => {
		if (selected) {
			const { id, "master.id": master } = selected;
			setInitialValues({
				id,
				master,
			});
		}
	}, [selected]);

	// React.useEffect(() => {
	// 	if (selected.length > 0) {
	// 		// const [select] = selected[0];
	// 		for (var i = 0; i < selected.length; i++) {
	// 			const [select] = [selected[i]];
	// 			console.log({ select });
	// 			if (select?.receptionLists.length > 0) {
	// 				const [request] = select?.receptionLists[0];
	// 				if (request.status === "delivery_started") {
	// 					Toaster("error", "failed", { autoClose: false });
	// 				}
	// 			}
	// 		}
	// 	}
	// }, [selected]);
	// React.useEffect(() => {
	// 	if (selected) {
	// 		let arr = [];
	// 		selected.forEach((item) => {
	// 			arr.push(item.id);
	// 		});
	// 		const data = {
	// 			bagIds: arr,
	// 			masterIds: master,
	// 		};
	// 		setSelectedData();
	// 		console.log({ selected });
	// 	}
	// }, []);

	const validationSchema = () => {
		return Yup.object().shape({
			master: Yup.number().required("selection is required"),
		});
	};

	// const handleSubmit = async (values, formikUtils) => {
	// 	let input = {};
	// 	if (selected) {
	// 		input = {
	// 			updateInput: {
	// 				...values,
	// 			},
	// 		};
	// 	} else {
	// 		input = {
	// 			createInput: {
	// 				...values,
	// 			},
	// 		};
	// 	}

	// 	const res = await hocProps.handleSubmit({ ...input }, formikUtils);
	// 	if (res) {
	// 		const { data, reason } = res;
	// 		onSuccess(data, reason);
	// 	}
	// };

	const getDetails = async (id, provider, type) => {
		return new Promise((resolve, reject) => {
			dataProvider
				.getOne(provider, { id })
				.then(({ data }) => {
					setDeliveryTypes(() => {
						return [data.deliveryType];
					});
					if (type === "update") {
						const { details } = data.deliveryType;
						if (details) {
							setDeliveryServices(details);
						}
					}
					resolve(data);
				})
				.catch((e) => {
					reject(e);
				});
		});
	};

	const handleSubmit = async (values) => {
		// e.preventDefault();
		let arr = [];
		selected.forEach((item) => {
			arr.push(item.id);
		});
		const data = {
			bagsId: arr,
			masterId: values.master,
		};
		// console.log({ data });

		const moveBagsToMaster = gql(`mutation
		moveManyBagToMaster($input:BagsToMasterInput!){
		  moveManyBagToMaster(input:$input){
		    id
		  }
		}`);

		try {
			const res = await APIClient.mutate({
				mutation: moveBagsToMaster,
				variables: {
					input: data,
				},
			});
			// setCountriesName(res.data?.allShippingCountries);
			if (res) {
				const { data, reason } = res;
				onSuccess(data, reason);
			}
		} catch (e) {
			Toaster('error', e.message)
		}
	};

	// console.log({ selected });

	return (
		<>
			<Modal
				open={isOpen}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
				sx={{ overflow: "scroll" }}
			>
				<Box
					style={{
						padding: 20,
						overflowX: "hidden",
						minHeight: "100vh",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					<Formik
						initialValues={initialValues}
						onSubmit={handleSubmit}
						enableReinitialize
						validationSchema={validationSchema}
					>
						{({
							errors,
							handleChange,
							touched,
							handleSubmit,
							TestHandle,
							values,
							submitCount,
							isSubmitting,
							isValid,
						}) => {
							return (
								<Form onSubmit={handleSubmit}>
									<CustomCard>
										<Stack spacing={"20px"} width={500} p="20px">
											<Typography fontSize={"20px"} fontWeight="700">
												마스터 패키징
											</Typography>
											<Divider />
											<Box
												my={2}
												display={"flex"}
												flexDirection={"column"}
												gap={1.5}
											>
												<Box
													display={"flex"}
													flexDirection={"column"}
													gap={1.5}
												>
													<label>
														<Typography variant={"body2"} fontWeight={"bold"}>
															마스터 선택*
														</Typography>
													</label>
													<FormControl
														size="small"
														sx={{
															width: "100%",
															m: 0,
														}}
														variant="outlined"
													>
														<Select
															sx={{ borderRadius: 8 }}
															name={"master"}
															value={values.master}
															onChange={handleChange}
															error={
																submitCount > 0 &&
																!isSubmitting &&
																!isValid &&
																!!errors.master
															}
															MenuProps={{
																sx: {
																	"&& .Mui-selected": {
																		backgroundColor: "#E0E0E0",
																	},
																},
															}}
															style={{
																borderRadius: "5px",
																boxShadow: "0px 0px 5px rgba(31, 65, 115, 0.1)",
																border: "1px solid #EBEBEB",
																width: "100%",
															}}
														>
															{additionalFormDetails.MasterManagement.map(
																(item, index) => (
																	<MyMenuItem value={item.id} key={index}>
																		{item.masterNumber}
																	</MyMenuItem>
																)
															)}
														</Select>
														<FormHelperText
															className={classes.formHelper}
															sx={{ ml: "0px" }}
														>
															{errors.master && touched.master && errors.master}
														</FormHelperText>
													</FormControl>
												</Box>
											</Box>

											<Divider />

											<div
												style={{
													display: "flex",
													justifyContent: "flex-end",
													gap: "20px",
												}}
											>
												<CustomButton
													onClick={handleClose}
													styles={{
														backgroundColor: "#F8F8FA",
														color: "#000",
														width: "auto",
													}}
												>
													취소
												</CustomButton>
												<CustomButton
													styles={{
														width: "auto",
													}}
													type={"submit"}
												>
													전송
												</CustomButton>
											</div>
										</Stack>
									</CustomCard>
								</Form>
							);
						}}
					</Formik>
				</Box>
			</Modal>
		</>
	);
};
