/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Typography } from "@mui/material";
import { TextFieldBox } from "../../../components/CustomInput";
import TableContainer from "../../../components/TableContainer";
import { deleteRateDetail } from "./queries";
import Toaster from "../../../utils/Toaster";

function Table1({ data, index, getData }) {
	const initialState = [
		{
			weight: 0,
			deliveryFee: 0,
			shippingTypeDetailId: data.id,
		},
	];
	const [data1, setData1] = useState([]);

	//Setting we get data from backend for rates
	useEffect(() => {
		if (data.ratesDetails) {
			const cloneData = { ...data };
			const filterData = cloneData.ratesDetails.filter(
				(el) => el.shippingTypeDetailId === data.id
			);
			if (filterData.length > 0) {
				setData1(filterData);
			} else {
				setData1(initialState);
			}
		} else {
			setData1(initialState);
		}
		return () => {};
	}, [data]);

	//Input change of weight
	const weightChange = (e) => {
		if (e.target.value < 0) return;
		const cloneData = [...data1];
		let obj = {
			weight: parseFloat(e.target.value),
		};
		cloneData[e.target.name] = { ...cloneData[e.target.name], ...obj };
		setData1(cloneData);
	};
	//Input change of rate
	const rateChange = (e) => {
		if (e.target.value < 0) return;
		const cloneData = [...data1];
		let obj = {
			deliveryFee: parseFloat(e.target.value),
		};
		cloneData[e.target.name] = { ...cloneData[e.target.name], ...obj };
		setData1(cloneData);
	};

	//Add Row
	const addRow = (e) => {
		const cloneData = [...data1];
		let obj = {
			weight: 0,
			deliveryFee: 0,
			shippingTypeDetailId: data.id,
		};
		cloneData.push(obj);
		setData1(cloneData);
	};

	//Remove Row
	const removeRow = (idx, id) => {
		if (data1.length === 1) return; //Limiting field of length 1
		const cloneData = [...data1];
		cloneData.splice(idx, 1);
		setData1(cloneData);

		//Finding if the current data was from backend
		let index = data.ratesDetails.findIndex((el) => el.id === id);
		if (index !== -1) {
			deleteRateDetail({ id: id })
				.then((res) => {
					Toaster("success", "요율이 삭제되었습니다.");
				})
				.catch((err) => {
					Toaster(err.message);
				});
		}
	};

	useEffect(() => {
		if (data1.length !== 0) getData({ index: index, data: data1 });
		return () => {};
	}, [data1]);

	return (
		<TableContainer
			{...{ isLoading: false, requestCompleted: true }}
			title={data.shippingType}
			headers={["배송 무게(Kg)", "배송비", "추가", "삭제"]}
			data={data1.map((item, index) => [
				<div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
					<Typography>{">="}</Typography>
					<TextFieldBox
						type="number"
						step="0.1"
						styles={{ width: "60%", margin: "auto" }}
						name={index}
						value={item.weight}
						onChange={weightChange}
					/>
				</div>,
				<TextFieldBox
					type="number"
					step="0.1"
					placeholder="6600"
					styles={{ width: "80%", margin: "auto" }}
					name={index}
					value={item.deliveryFee}
					onChange={rateChange}
				/>,
				<span onClick={addRow}>
					<svg
						style={{ width: "40px", height: "40px", cursor: "pointer" }}
						width="28"
						height="22"
						viewBox="0 0 28 22"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<rect
							x="0.5"
							y="0.5"
							width="26.5514"
							height="20.2897"
							rx="5.76168"
							stroke="#427DF3"
						/>
						<path
							d="M13.7758 7.3584V13.9332"
							stroke="#427DF3"
							strokeWidth="1.25234"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							d="M10.4883 10.6455H17.063"
							stroke="#427DF3"
							strokeWidth="1.25234"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</svg>
				</span>,
				<span color="#D5495A" onClick={() => removeRow(index, item.id)}>
					<svg
						style={{ width: "40px", height: "40px", cursor: "pointer" }}
						width="28"
						height="22"
						viewBox="0 0 28 22"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<rect
							x="0.313084"
							y="0.313084"
							width="26.9252"
							height="20.6636"
							rx="5.9486"
							stroke="#FF000F"
							strokeWidth="0.626168"
						/>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M15.4201 7.05111C15.3004 6.69874 14.9894 6.4707 14.6373 6.4707H12.9141L12.8486 6.47335C12.5022 6.50138 12.2066 6.74996 12.1156 7.09909L12.0122 7.62625L12.0001 7.67265C11.9506 7.82292 11.8122 7.92722 11.6541 7.92722L11.6534 7.92728H10.324L10.2826 7.93014C10.1335 7.95087 10.0187 8.08187 10.0187 8.24037C10.0187 8.41329 10.1554 8.55347 10.324 8.55347H17.2274L17.2688 8.55061C17.4178 8.52988 17.5327 8.39888 17.5327 8.24037C17.5327 8.06746 17.396 7.92728 17.2274 7.92728H15.9029C15.901 7.92724 15.8991 7.92722 15.8972 7.92722L15.8505 7.92415C15.697 7.90388 15.5702 7.78512 15.5391 7.62612L15.4402 7.11861L15.4201 7.05111ZM14.9923 7.92728C14.9784 7.89191 14.9664 7.85548 14.9564 7.81808L14.9404 7.74896L14.8459 7.26084C14.8238 7.17615 14.7556 7.11415 14.6733 7.09997L14.6373 7.09689H12.9141C12.8284 7.09689 12.7523 7.14831 12.7206 7.21293L12.7099 7.24132L12.611 7.74908C12.5989 7.81092 12.5814 7.87048 12.5591 7.92728H14.9923ZM16.6888 9.27604C16.8429 9.28877 16.9609 9.41671 16.9691 9.5707L16.9652 9.65657L16.8339 11.2642L16.6962 12.8332C16.667 13.1464 16.6409 13.4106 16.6184 13.6185C16.5402 14.3432 16.0697 14.7914 15.3606 14.8047C14.2555 14.8251 13.1932 14.8249 12.1617 14.8026C11.473 14.7881 11.0095 14.3351 10.9327 13.6214L10.8796 13.0957L10.7867 12.0758L10.6917 10.9566L10.5829 9.61321C10.5694 9.44085 10.6947 9.28989 10.8628 9.27603C11.0169 9.26333 11.1534 9.37024 11.1855 9.52091L11.198 9.64404L11.3001 10.9035L11.4116 12.2089C11.4615 12.774 11.5049 13.2313 11.5396 13.5526C11.5834 13.9593 11.7976 14.1686 12.1744 14.1765C13.1979 14.1986 14.2522 14.1988 15.3495 14.1786C15.7492 14.1711 15.9668 13.9639 16.0115 13.5495L16.0644 13.0265C16.0799 12.8654 16.0964 12.6874 16.1139 12.4942L16.2255 11.2103L16.3599 9.56296C16.3723 9.40496 16.4971 9.28385 16.6473 9.27547L16.6888 9.27604Z"
							fill="#FF000F"
						/>
					</svg>
				</span>,
			])}
		/>
	);
}

export default Table1;
