import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Toaster from "../utils/Toaster";
// import moment from "moment";
import BackupTableIcon from "@mui/icons-material/BackupTable";
import statusSwitch from "../utils/statusSwitch";
import CustomButton from "../components/CustomButton";
import { Box, Checkbox } from "@mui/material";
import Moment from "moment";
import { Link } from "react-router-dom";
import Loader from "../components/Loader";

// import { useState } from "react";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		fontWeight: "bold",
		backgroundColor: "#F8F8FA",
		color: "rgba(0, 0, 0, 0.6)",
		whiteSpace: "nowrap",
	},
	[`&.${tableCellClasses.body}`]: {
		// backgroundColor: "white",
		fontSize: 14,
		whiteSpace: "nowrap",
		border: "1px solid #E5E5E5",
		color: "inherit",
	},
}));

const StyledTableCellWhiteSpace = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		fontWeight: "bold",
		backgroundColor: "#F8F8FA",
		color: "rgba(0, 0, 0, 0.6)",
		whiteSpace: "nowrap",
	},
	[`&.${tableCellClasses.body}`]: {
		// backgroundColor: "white",
		fontSize: 14,
		whiteSpace: "break-spaces",
		border: "1px solid #E5E5E5",
		color: "inherit",
	},
}));

const StyledTableRow = styled(TableRow)`
	// "&:nth-of-type(odd)": {
	// 	backgroundColor: theme.palette.action.hover,
	// },
	background-color: ${(props) => props.bg && props.bg};
	color: ${(props) =>
		props.bg
			? "rgba(255, 255, 255, 0.9) !important"
			: "rgba(0, 0, 0, 0.87) !important"};
	// hide last border
	// "&:last-child td, &:last-child th": {
	// 	border: 0,
	// },
`;

// dataProvider

export const ClientDeliveryRequestTable = ({
	data = [],
	header = [],
	countTotal,
	page,
	pageSize,
	handleCheck,
	setCheckedList,
	checkedList,
	calculateTotal,
	isLoading,
	requestCompleted,
	openShipmentTracking,
	handleOpen2,
	setRequestId,
	handleOpen,
	props,
}) => {
	// console.log(
	// 	"count",
	// 	count,
	// 	"length",
	// 	data.length,
	// 	"page",
	// 	page,
	// 	"pageSize",
	// 	pageSize
	// );
	// console.log({
	// 	countTotal,
	// 	page,
	// 	pageSize,
	// 	handleCheck,
	// 	setCheckedList,
	// 	checkedList,
	// 	calculateTotal,
	// 	isLoading,
	// 	requestCompleted,
	// 	data,
	// 	header,
	// });
	return !isLoading && requestCompleted ? (
		<TableContainer component={Paper} sx={{ maxHeight: "calc(100vh - 230px)" }}>
			<Table
				sx={{ minWidth: 700, border: "1px solid rgba(224, 224, 224, 1)" }}
				aria-label="customized table"
				stickyHeader
			>
				<TableHead>
					<TableRow>
						{header?.map((item, index) => (
							<StyledTableCell align="center">{item}</StyledTableCell>
						))}
						{/* <StyledTableCell align="center">No</StyledTableCell>
						<StyledTableCell align="center">업체명</StyledTableCell>
						<StyledTableCell align="center">배송번호</StyledTableCell>
						<StyledTableCell align="center">접수번호</StyledTableCell>
						<StyledTableCell align="center">배송비</StyledTableCell>
						<StyledTableCell align="center">배송비 차감날짜</StyledTableCell>
						<StyledTableCell align="center">상품이름</StyledTableCell>
						<StyledTableCell align="center">상품수량</StyledTableCell>
						<StyledTableCell align="center">총상품금액</StyledTableCell>
						<StyledTableCell align="center">적용무게</StyledTableCell>
						<StyledTableCell align="center">실제무게</StyledTableCell>
						<StyledTableCell align="center">부피무게</StyledTableCell>
						<StyledTableCell align="center">주문번호</StyledTableCell>
						<StyledTableCell align="center">배송국가</StyledTableCell>
						<StyledTableCell align="center">배송종류</StyledTableCell>
						<StyledTableCell align="center">배송상태</StyledTableCell>
						<StyledTableCell align="center">출고날짜</StyledTableCell> */}
					</TableRow>
				</TableHead>

				{data?.length > 0 ? (
					<TableBody>
						{data.map((item, index) => (
							<StyledTableRow
								key={index}
								bg={item.status === "started_delivery" && "#5b8ff7"}
								// "#8ebedc"
							>
								<StyledTableCell align="center">
									<Checkbox
										key={index}
										onChange={handleCheck}
										value={item.id}
										checked={checkedList.some((res) => res === item.id)}
									/>
								</StyledTableCell>

								<StyledTableCell align="center">{""}</StyledTableCell>

								<StyledTableCell align="center">
									{countTotal - ((page - 1) * 10 + index)}
								</StyledTableCell>

								<StyledTableCell align="center">
									{item.status === "waiting_warehousing"
										? "입고대기"
										: item.status === "warehousing_completed"
										? "입고완료"
										: item.status === "waiting_delivery"
										? "출고대기"
										: item.status === "started_delivery"
										? "출고완료"
										: item.status === "completed_delivery" && "배송완료"}
								</StyledTableCell>

								<StyledTableCell align="center">
									<CustomButton
										onClick={() => {
											openShipmentTracking(item);
										}}
										styles={{
											backgroundColor: "rgb(91, 143, 247)",
											color: "#FFFFFF",
											borderRadius: 4,
											height: "40px",
											width: "80px",
										}}
									>
										배송추적
									</CustomButton>
								</StyledTableCell>

								<StyledTableCell align="center">
									{item.waybillPrintStatus ? "O" : "X"}
								</StyledTableCell>

								<StyledTableCell align="center">
									{item.packingListPrintStatus ? "O" : "X"}
								</StyledTableCell>

								<StyledTableCell align="center">{"일반"}</StyledTableCell>

								<StyledTableCell align="center">
									{item.user?.company_name_kr}
								</StyledTableCell>

								<StyledTableCell align="center">
									{item.payeeInformation?.shippingCountry.code}
								</StyledTableCell>

								<StyledTableCell align="center">
									{item.shippingType?.shippingType}
								</StyledTableCell>

								<StyledTableCellWhiteSpace align="center">
									{item.createdAt
										? Moment(item.createdAt).format("YYYY-MM-DD \n hh:mm:ss")
										: ""}
								</StyledTableCellWhiteSpace>

								<StyledTableCellWhiteSpace align="center">
									{item.processReleaseDate &&
										Moment(item.processReleaseDate).format(
											"YYYY-MM-DD \n hh:mm:ss"
										)}
								</StyledTableCellWhiteSpace>

								<StyledTableCell align="center">
									{item.trackingNumber}
								</StyledTableCell>

								<StyledTableCell align="center">
									{item.productInformation?.orderNo1}
								</StyledTableCell>

								<StyledTableCell align="center">
									{item.productInformation?.orderNo2}
								</StyledTableCell>

								<StyledTableCell align="center">
									{item.shippingNumber}
								</StyledTableCell>

								<StyledTableCell align="center">
									{item.productInformation?.departureCountryCourierNumber}
								</StyledTableCell>

								<StyledTableCell align="center">
									{item.deliveryFee}
								</StyledTableCell>

								<StyledTableCell align="center">
									{item.additionalFee}
								</StyledTableCell>

								<StyledTableCell align="center">{"KRW"}</StyledTableCell>

								<StyledTableCell align="center">
									{item.miscellaneousInformation?.horizontal}
								</StyledTableCell>

								<StyledTableCell align="center">
									{item.miscellaneousInformation?.portrait}
								</StyledTableCell>

								<StyledTableCell align="center">
									{item.miscellaneousInformation?.height}
								</StyledTableCell>

								<StyledTableCell align="center">
									{item.miscellaneousInformation?.boxQuantity}
								</StyledTableCell>

								<StyledTableCell align="center">
									{item.miscellaneousInformation?.actualWeight}
								</StyledTableCell>

								<StyledTableCell align="center">
									{item.miscellaneousInformation?.bulkWeight}
								</StyledTableCell>

								<StyledTableCell align="center">
									{item.miscellaneousInformation?.applicableWeight}
								</StyledTableCell>

								<StyledTableCell align="center">
									{item.payeeInformation?.ssn}
								</StyledTableCell>

								<StyledTableCell align="center">
									{item.payeeInformation?.euioss}
								</StyledTableCell>

								<StyledTableCell align="center">
									{item.payeeInformation?.ukeori}
								</StyledTableCell>

								<StyledTableCell align="center">
									{item.payeeInformation?.ssn}
								</StyledTableCell>

								<StyledTableCell align="center">
									<div>
										{item.productInformation.productInformation.map(
											(subItem, index) => {
												return (
													<div>
														<p>
															{subItem.barcode}{" "}
															{subItem.barcode !== "" &&
																index <
																	item.productInformation.productInformation
																		.length -
																		1 &&
																""}
														</p>
													</div>
												);
											}
										)}
									</div>
								</StyledTableCell>

								<StyledTableCell align="center">
									{item.productInformation?.productInformation?.map(
										(subItem, subIndex) => {
											return (
												<div>
													<p>
														{subItem.productName}{" "}
														{subItem.productName !== "" &&
															subIndex <
																item.productInformation.productInformation
																	.length -
																	1 &&
															""}
													</p>
												</div>
											);
										}
									)}
								</StyledTableCell>

								<StyledTableCell align="center">
									{item?.productInformation?.productInformation?.reduce(
										(previousValue, currentValue) =>
											previousValue + currentValue.quantity,
										0
									)}
								</StyledTableCell>

								<StyledTableCell align="center">
									{calculateTotal(item.productInformation?.productInformation)}
								</StyledTableCell>

								<StyledTableCell align="center">
									{item.productInformation?.currency}
								</StyledTableCell>

								<StyledTableCell align="center">
									{item.senderInformation?.name}
								</StyledTableCell>

								<StyledTableCell align="center">
									{item.senderInformation?.phoneNumber}
								</StyledTableCell>

								<StyledTableCell align="center">
									{item.senderInformation?.address}
								</StyledTableCell>

								<StyledTableCell align="center">
									{item.payeeInformation?.name}
								</StyledTableCell>

								<StyledTableCell align="center">{""}</StyledTableCell>

								<StyledTableCell align="center">
									{item.payeeInformation?.phoneNumber1}
								</StyledTableCell>

								<StyledTableCell align="center">
									{item.payeeInformation?.phoneNumber2}
								</StyledTableCell>

								<StyledTableCell align="center">
									{item.payeeInformation?.email}
								</StyledTableCell>

								<StyledTableCell align="center">
									{item.payeeInformation?.zipCode}
								</StyledTableCell>

								<StyledTableCell align="center">
									{item.payeeInformation?.mexicoColony}
								</StyledTableCell>

								<StyledTableCell align="center">
									{item.payeeInformation?.states}
								</StyledTableCell>

								<StyledTableCell align="center">
									{item.payeeInformation?.cities}
								</StyledTableCell>

								<StyledTableCell align="center">
									{item.payeeInformation?.streetAddress1}
								</StyledTableCell>

								<StyledTableCell align="center">
									{item.payeeInformation?.streetAddress2}
								</StyledTableCell>

								<StyledTableCell align="center">
									{item.miscellaneousInformation?.shippingMessage}
								</StyledTableCell>

								<StyledTableCell align="center">
									{item.productInformation?.productInformation?.map(
										(subItem, subIndex) => {
											return (
												<>
													{subItem.hscode}
													{subItem.hscode !== "" &&
														subIndex <
															item.productInformation.productInformation
																.length -
																1 &&
														""}
												</>
											);
										}
									)}
								</StyledTableCell>

								<StyledTableCell align="center">
									{item.miscellaneousInformation?.productType}
								</StyledTableCell>

								<StyledTableCell align="center">{""}</StyledTableCell>

								<StyledTableCell align="center">
									{item.miscellaneousInformation.exportDeclarationNumber != null
										? item.miscellaneousInformation.exportDeclarationNumber
										: ""}
								</StyledTableCell>

								<StyledTableCell align="center">
									{item.productInformation?.productInformation?.map(
										(subItem, subIndex) => {
											return (
												<div>
													<p>
														{subItem.purchaseURL}{" "}
														{subItem.purchaseURL !== "" &&
															subIndex <
																item.productInformation?.productInformation
																	?.length -
																	1 &&
															""}
													</p>
												</div>
											);
										}
									)}
								</StyledTableCell>

								<StyledTableCell align="center">
									{item.miscellaneousInformation?.userData}
								</StyledTableCell>

								<StyledTableCell align="center">
									{item.miscellaneousInformation?.userData2}
								</StyledTableCell>

								<StyledTableCell align="center">
									{item.miscellaneousInformation?.userData3}
								</StyledTableCell>

								<StyledTableCell align="center">
									{item.whereAbout.length > 0 ? (
										item.whereAbout[0]?.status === "REQUESTED" ? (
											<CustomButton
												styles={{
													width: "140px",
													// border: "1px solid #5B8FF7",
													backgroundColor: "#F2994A",
													color: "white",
													borderRadius: 5,
													alignSelf: "center",
												}}
											>
												행방조사 요청완료
											</CustomButton>
										) : (
											<CustomButton
												styles={{
													width: "140px",
													// border: "1px solid #5B8FF7",
													backgroundColor: "#27AE60",
													color: "white",
													borderRadius: 5,
													alignSelf: "center",
												}}
											>
												행방조사 완료
											</CustomButton>
										)
									) : Moment().isAfter(
											Moment(Moment(item.processReleaseDate).add(15, "days"))
									  ) ? (
										<CustomButton
											styles={{
												width: "140px",
												// border: "1px solid #5B8FF7",
												backgroundColor: "#427DF3",
												color: "white",
												borderRadius: 5,
												alignSelf: "center",
											}}
											onClick={(e) => {
												handleOpen2(index);
												setRequestId(
													item.productInformation?.requestShippingId
												);
											}}
										>
											행방조사 요청
										</CustomButton>
									) : (
										<CustomButton
											styles={{
												width: "140px",
												// border: "1px solid #5B8FF7",
												backgroundColor: "#E2E8F0",
												color: "black",
												borderRadius: 5,
												alignSelf: "center",
											}}
											onClick={(e) => {
												handleOpen(index);
											}}
										>
											행방조사 요청
										</CustomButton>
									)}
								</StyledTableCell>

								<StyledTableCell align="center">
									<Box sx={{ display: "flex", justifyContent: "center" }}>
										<Link
											to={
												item.status === "waiting_warehousing"
													? `/RequestShipping?id=${item.id}`
													: "#"
											}
											onClick={() => {
												if (item.status !== "waiting_warehousing")
													Toaster(
														"error",
														"이미 입고 완료 또는 출고 완료된 접수건은 수정 및 삭제가 불가합니다."
													);
											}}
										>
											{
												// eslint-disable-next-line jsx-a11y/alt-text
												<img
													style={{ cursor: "pointer" }}
													src="/assets/images/Edit.svg"
												></img>
											}
										</Link>
										<div
										// onClick={() => {
										//   if (item.status === "waiting_warehousing") {
										//     setOpenDeleteConfirmModal(true);
										//     setSelectedDeleteId(item.id);
										//   } else {
										//     console.log("not ");
										//   }
										// }}
										>
											{
												// eslint-disable-next-line jsx-a11y/alt-text
												<img
													style={{ width: "16px", cursor: "pointer" }}
													src="/assets/images/Delete.png"
												></img>
											}
										</div>
									</Box>
								</StyledTableCell>
							</StyledTableRow>
						))}
					</TableBody>
				) : (
					<tbody>
						<tr>
							<td colSpan="16">
								<Empty>
									<div>
										<BackupTableIcon fontSize="large" />
										<p>데이터 없음</p>
									</div>
								</Empty>
							</td>
						</tr>
					</tbody>
				)}
			</Table>
		</TableContainer>
	) : (
		<Loader />
	);
};

const Empty = styled("div")({
	height: "300px",
	display: "grid",
	placeItems: "center",
	textAlign: "center",
	opacity: "0.6",
});
