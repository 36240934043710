export const AGREEMENT_FIRST = `[SHIPTER] 국제 운송 서비스 이용 약관
1. 약관의 적용 
(주)에이치3네트웍스의 서비스 [SHIPTER]는 국제 운송 서비스를 대행할 수 있도록 권한을 부여 받은 파트너사로써, 모든 분쟁이 발생시 국제 운송 서비스 제공 업체와 고객 사이에 발생한 분쟁을 중재하고 해결하기 위해 최선의 노력을 기울입니다. [SHIPTER]의 서비스를 이용하는 경우, 귀하는 “송화인”으로서, 귀하를 대신하여 그리고 운송물의 수취인(“수취인”) 및 운송물의 기타 이해 당사자를 대신하여 본 약관이 적용된다는 점에 합의하는 것으로 간주됩니다. “운송 물”이란 항공, 도로 등을 포함하여 [SHIPTER]가 선택하는 수단에 의하여 운송되는 모든 서류 및 화물을 의미합니다. “운송장”에는 라벨, 바코드, 운송장, 탁송화물송장 및 동 항목들의 전자 서류와 같이 [SHIPTER] 또는 송하인의 자동 시스템으로 발행된 운송물 식별 표시 또는 문서 가 포함됩니다. 

2. 서비스의 이용
2.1 배송 가능 지역 
배송은 목적 국가의 본토를 제외한 섬 및 부서와 영토를 포함하지 않습니다. 송화인은 운송물을 해당 지역의 부서 및 지역으로 보내서는 안 되며, 이를 지키지 않아 해당 국가 의 운송 업체로부터 [SHIPTER]에 운송료를 적용하여 해당 지역으로 배송하는 경우 송화 인에게 추가 금액을 청구할 수 있습니다. 
2.2 [SHIPTER] 국제 운송 서비스 
A. [SHIPTER]의 국세 운송 서비스를 이용시 [SHIPTER]의 최신 요율표에 기재된 국제 운송 서비스에 따른 운송물의 무게 제한과 접수 가능한 운송물의 최대 사 이즈를 확인해야 합니다. 
B. 국제 운송 서비스의 종류에 따라 운송물의 실제 중량과 부피 중량 중 더 높 은 수치에 따라 운송 비용이 청구되는 서비스가 있으며 이는 [SHIPTER]의 요율 표에 고지됩니다. 
C. 국제 운송 서비스의 종류에 따라 유류할증료가 추가로 적용될 수 있으며 해 당 서비스와 유류할증료는 사전에 고지됩니다. 

3.위험 고지 및 보상 기준 
3.1 [SHIPTER]는 [SHIPTER]의 정기적인 배송 일정에 따라 운송물을 배송하기 위하여 모든 합리적인 노력을 기울이며, 그렇다 하더라도 이와 같은 일정은 구속력을 갖지 아니하며 계약의 일부를 구성하지 않습니다. 그리고 어떠한 경우에도 [SHIPTER]는 본 약관 제3.2조 에 규정된 한도를 넘어 책임을 지지 않으며, 지연으로 인한 결과손해, 간접손해, 특별손 해에 대해서도 책임을 지지 않습니다. 
3.2 배송 시에 운송장에 명시한 가격에 관계없이, 본 약관 제4.11조에 규정된 사항 외에 는 송화인의 SHIPTER 서비스 제공에 대한 최대 보상 한도는 운송장 당 USD 40을 초과할 수 없으며 배송비용은 반환하지 않습니다. 
3.3 송화인이 본 계약에 따라 손해배상을 청구하고자 하는 경우, 송화인은 다음 각 호의 관련 자료를 제공하여야 합니다. 
A. 플랫폼 상의 온라인 거래 화면 캡처+환불 화면 캡처 
B. 송화인이 고객의 환불 없이 운송물의 추가 선적이 이루어진 경우에는 온라인 거래 화면 캡처 + 클레임 내역 + 추가 운송업체의 정보를 제출해야 합니다. 
3.4 송화인은 한 운송장에 대해 1회 손해배상을 청구할 수 있으며, [SHIPTER]는 송화인에 게 손해배상을 할 경우 최종 변상된 것으로 봅니다. 송화인은 본 약관의 배상 관련 규정 이 손실 및 보상에 불충분하다고 판단되는 경우에는 송화인이 스스로 보험에 가입하여 위험을 예방하거나 송화인이 모든 손실 및 손해의 위험을 부담합니다. 
3.5 통관 외 현지 배송 단계에서 30일 이상 운송물 이동이 없는 경우 송화인은 기한(출 하 일로부터 6개월) 내에 서면으로 후속조사를 신청할 수 있으며, 목적지 국가의 세관에 서 운송물을 스캔 한 경우에만 문의가 허용됩니다. 송화인은 인터넷의 운송 조회를 통해 배송시도 또는 완료된 정보가 확인되면 [SHIPTER]에게 손해배상을 청구할 수 없습니다. 송화인은 송화인의 신청에 의한 후속조사에도 불구하고 신청일로부터 3개월 이내에 배송 시도 또는 완료된 배송정보가 확인되지 않을 경우 손해배상청구기한(출하일로부터 6개월) 내에 서면으로 손해배상을 청구할 수 있습니다. 이 경우 갑은 제3.3조에 따른 자료를 포 함한 모든 관련 서류를 손해배상 청구일로부터 3일 이내에 [SHIPTER]에게 제출하여야 합 니다. 송화인이 [SHIPTER]에게 모든 운송비를 지불하지 않을 경우, [SHIPTER]는 어떠한 손 해에 대해서도 책임을 지지 않습니다. 송화인은 [SHIPTER]에게 운송비를 지급할 의무를 이 약관에 따른 손해배상청구권과 상계하여서는 안됩니다. 
3.6 송화인이 제3.4조에 따른 보상을 받은 경우, 송화인은 해당 건에 대해 제3자를 대신 하여 손해배상청구권을 포함한 각종 권리를 행사할 수 없습니다. 
3.8 송화인의 의무A. 송화인은 "운송물"이 파손되거나 내용물이 유실되지 않도록 포장 박스나 포 
장재로 두 접착부분의 표면이 완전히 밀봉되도록 포장해야 합니다. 
B. "운송물"의 포장이 배송에 적합하지 않은 것으로 판단되는 경우, 송화인은 적 합한 포장재로 재포장을 해야 하며, 그러한 행위가 어려울 경우 [SHIPTER]는 화 주를 대신하여 "운송물"을 재 포장할 수 있습니다. 
C. SHIPTER가 송화인을 대신하여 "운송물"을 재포장하는 경우 그 과정에서 발생 한 비용은 송화인에게 청구됩니다. 
D. 송화인은 배송에 필요한 정보를 [SHIPTER]에 제공하는 바코드 라벨 또는 운 송장에 표시될 수 있도록 [SHIPTER] 물류시스템에 정확이 기재해야 합니다. 
3.9 운송물은 배송에 실패하거나 수취인이 거부한 경우 반환되며. 국제 운송 서비스의 종류에 따라 발생되는 반품 비용은 송화인에게 청구됩니다. 

4. 위약책임 
4.1 운송물에 포함된 물품 품질의 결함과 파손으로 다른 운송물, 운송수단, 기계 및 장비 의 오염, 부식, 손상 또는 인사 사고가 발생하였을 경우, 송화인은 [SHIPTER] 또는 제3자 에게 발생한 손해에 대하여 전적으로 보상을 하여야 합니다. 
4.2 법령 또는 계약에 따른 금지물품이 포함된 배송물품이나 제한물품에 따른 검사, 몰 수, 압류, 배송경로의 변경 등 선적물의 인도 지연 또는 가치 상실이 발생한 경우에는 송 화인이 배상책임을 집니다. 
4.3 송화인이나 그 관련된 인물 및 업체가 계약을 위반하거나 권리를 침해하여 [SHIPTER] 및 배송에 관한 계약을 체결한 자에 대한 소송 또는 분쟁 발생, 또는 제3자에게 배상책 임을 지는 분쟁이 발생한 경우에 [SHIPTER]는 이 사건의 책임과 의무를 부담할 필요가 없습니다. 송화인은 상기의 소송 또는 분쟁에 적시에 상소하고, 송화인이나 그 관련된 인 물 및 업체가 계약을 위반하거나 권리를 침해하여 발생한 제3자에 대한 배상에 발생하는 비용 또는 손실을 [SHIPTER]에게 보상하여야 합니다. 
4.4 송화인이 정부기관, 공항, 항만관리청 등 기관으로부터 강제조치 또는 행정·형사상의 처벌을 받거나 제3자에게 보상 청구를 받는 경우에는 [SHIPTER]의 연루를 방지하기 위한 효과적인 조치를 취하여야 합니다. [SHIPTER]의 연루를 피할 수 없는 경우, 송화인에게 그 로 인한 손실에 대한 손해배상을 청구할 수 있습니다. 
4.5 국제우편법 또는 우편접수 규정에 위배되어 접수불가로 처리된 품목의 반환 "운송물 "에 대하여 반송비용 및 이로 인하여 발생하는 추가 비용은 송화인 부담으로 처리해야 합니다 
4.6 송화인은 운송과 관련된 모든 요금 및 수수료에 대하여 항상 종국적인 책임을 지며, [SHIPTER]가 요금을 징수하는 과정에서 발생된 모든 합리적인 비용에 대한 지불 책임을 집니다. 이러한 비용에는 변호사 비용, 수금대리 비용, 이자 및 재판비용이 포함되며, 이 에 한하지 않습니다. 본 운송 약관에 명시되지 않는 기타 사항들은 일반 상관습에 따라 처리하며, 본 약관의 일부 조항이 무효가 되거나 또는 집행이 불가능하더라도 이는 본 약관의 다른 부분에 영향을 미치지 않습니다. 
4.7 [SHPTER]는 모든 운송료가 징수될 때까지 어떠한 클레임 청구에 대해서도 응할 의무 를 지지 않습니다. 
4.8 수취인 정보의 불일치, 수취인 부재, 수취인 거부, 해당 국가 세관의 통관 문제, 세금 납부 거절 및 보관기간만료로 인해 “운송물”이 반송되는 경우는 [SHIPTER]에 배상에 대 한 요청을 할 수 없습니다. 
4.9 송화인이 보상을 신청하여 배상 받은 상품은 대한민국으로 반송이 되어도 소유권은 [SHIPTER]에 있으며, [SHIPTER]에서 처리하는 방식에 이의를 제기할 수 없습니다. 
4.10 송화인이 “운송물”을 발송한 후 90일이 지나도 반송에 대한 배송정보가 확인되는 경우 손해배상청구기한(출하일로부터 6개월) 내에 서면으로 손해배상을 청구할 수 없습 니다. 
4.11 도난 및 분실로 인한 보상은 접수 시 상품에 기입된 가격(최대 USD 40)과 배송비 (발송한 날짜 기준)를 합한 가격으로 계산되어 [SHIPTER]가 송화인에게 배상합니다. 

5. 면책 조항
5.1 다음 각 호의 사유로 인한 지연, 배송 불가 또는 손해가 발생한 경우,[SHIPTER]는 배상책임을 지지 않습니다 
A. 지진, 물난리, 홍수 및 홍수 재해, 화재, 태풍, 폭우, 폭설 등 자연재해, 초미세 먼지, 질병, 내란, 항공기 추락, 안개, 전쟁 등 심각한 기상 상황 및 운송 수단에 대한 타격, 테러 등 예측불허 및 통제불능 또는 피할 수 없는 객관적 요인 또는 사건 등으로 인한 불가항력적인 상황의 경우. 또한 예상 외의 교통사고, 규정의 변경, 법 집행 기관의 조치 또는 결정. 여기에는 전자 또는 사진 이미지, 데이터 또는 기록물의 전기적 또는 자성에 의한손상이나 삭제 ([SHIPTER]에 고지된 경우를 포함하여) 운송물의 성격과 관련된 결함이나 특성, 송하인, 수취인, 제3자, 세관 또는 기타 공무원과 같이 [SHIPTER]가 고용하거나 [SHIPTER]와 계약하지 않은 자에 의한 작위나 부작위와 같은 “불가항력”이 포함되나 이에 국한되지 않 습니다. 
B. 운송물의 자연적 특성 및 내부 결함의 경우 
C. 송화인 또는 수취인의 원인에 의한 발생: 송화인이 사실이 아닌 배송정보를 신고한 경우, 수취인의 정보를 제대로 기재하지 않은 경우, 송화인이 발송한 운 송물이 법률에 명시된 금지 또는 제한 배송에 속하거나 수취인의 귀책사유로 인 해 수취인의 배송이 지연되는 경우. 
5.2 송화인은 관련 관세청 또는 관련 감독기관의 요청이 있을 경우 법령 및 계약에 따라 [SHIPTER]에게 수취인의 정보를 조회하여 제공하도록 하여야 합니다. [SHIPTER]가 수취인 의 배송정보를 확인하지 못하거나 관세청 또는 해당 감독기관에 제출하지 않아 배송이 지연되거나 배송되지 않는 경우 책임을 지지 않습니다. 
5.3 송화인의 배송이 배송국가, 판매처 및 관련 국가의 해당 관리법에 적합하지 않고 압 류기간 중 처벌·몰수·손상·손실 등에 해당하는 경우에는 송화인이 자진하여 배상하여야 하며, [SHIPTER] 및 [SHIPTER]와 관련된 업체는 이에 대한 책임을 지지 않습니다. 
5.4 국제 우체국 표준 규정 및 해당 국가의 법률에 따른 배송 금지 물품에 대해서는 [SHIPTER]는 책임을 지지 않으며, 이로 인해 발생하는 문제 및 피해에 대해서는 송화인에 게 법적 조치를 취할 수 있습니다. 
5.5 [SHIPTER]는 송화인이 요청한 배송 물품의 직접 검사 요구할 수 있는 권리가 있으며, 국가의 금지 또는 제한 물품에 속하는지 여부, 물품의 명칭, 종류, 수량 등이 운송 명세 서에 기재된 물품과 일치하는지 여부를 검사할 수 있는 권리를 가지며, [SHIPTER]는 법률 또는 감독기관의 요청에 따라 송화인이 배송한 물품에 대하여 개시 및 검사를 요구할 수 있습니다. 관련 국가 규정에 근거하여 송화인에게 관련 증빙서류를 제출하도록 요구할 경우, 송화인은 증빙서류 원본의 제출 의무를 지며, [SHIPTER]는 오류 여부를 검토한 후 이를 접수 및 운반하여야 합니다. 송화인이 검사를 거부하고 사실대로 운송 명세서를 제 출하지 않고 이에 상응하는 서면 증빙서류 제출을 거부하는 경우, [SHIPTER]는 수령 및 운송을 거부할 수 있습니다. 
5.6 [SHIPTER]는 이미 발송한 발송물품(배송물품)에서 금지된 물품이 하나라도 발견되면 배송을 중단할 수 있으며, 그 중 법에 따른 몰수 또는 처분대상 물품은 관계기관에 즉시 신고할 수 있는 권리를 가지며 관계기관과 협조하여 처분합니다. 이미 출하된 물품으로 써 압류 또는 처분할 필요가 없는 금지 물품과 조사 및 처리가 완료된 금지 물품은 [SHIPTER]가 송화인에게 연락하여 적절하게 처리합니다. 
5.7 국제적으로 발송된 제품이 목적지 국가에서 반입될 수 있는지를 명확히 하는 것은 송화인의 책임입니다. "운송물"의 반입이 허용되지 않는 곳으로의, 또는 그로부터 반송으 로 인한 모든 관련 비용에 대하여 [SHIPTER]는 어떠한 책임도 지지 않습니다. 
5.8. 해당 국가의 세관 또는 그 외 다른 기관의 조치나 누락으로 인하여 "운송물"의 배송 이 완료되지 못하였거나 지연된 경우에 [SHIPTER]는 어떠한 책임도 지지 않습니다. 
5.9 [SHIPTER]는 우편번호만으로 배송이 불가능하며, 수취인의 주소가 불완전하거나 부정 확한 경우 [SHIPTER]는 수취인 주소 확인을 위한 합리적인 노력을 할 것이나, 그럼에도 불구하고 정확한 주소지 확인이 어려울 경우 배송을 완료할 수 없음에 대한 책임은 지지 않습니다. 만약 "운송물"이 취급 불가한 것으로 간주되거나 상품가격 고의 기재 오류 등 의 문제로 인해 세관 및 통관상의 문제가 발생하여 "운송물"의 수취거절이나 관련 비용 의 지불을 거절할 경우 [SHIPTER]는 이에 대한 어떠한 책임도 지지 않습니다.
5.10 각 국의 세관 마다 수취인에게 세금납부와 관련된 연락 여부가 상이합니다. 세금납 부와 관련하여 송화인과 수취인 측에서 확인을 해야 하며 미확인으로 인해 운송물이 자 동으로 반송되는 경우 [SHIPTER]에서는 반송에 대한 어떠한 책임도 지지 않습니다. 
5.11 미출고 건의 경우 프로그램 상 운송물 접수일 기준으로부터 14일 이내에 문의를 해 야 하며,14일 이후 미 출고건의 경우 문의 접수를 받지 않습니다. 
5.12 2021.03.10일 출고 이후 건에 대한 운송물의 파손 신청 문의는 받지 않으며 파손으 로 인해 발생하는 불이익에 대해서는 [SHIPTER]에서 책임을 지지 않습니다.`;

export const AGREEMENT_SECOND = `개인정보 수집 및 이용 안내
 
(주)에이치3네트웍스의 서비스 SHIPTER는 여러분이 고객사에 가입하시거나 서비스를 이용하시고자 할 경우에 필요한 최소한의 개인정보를 수집하며, 이에 대한 동의를 얻고 있습니다.
 
1. 수집하는 개인정보 항목
가. 필수항목 : 아이디, 비밀번호, 업체 이름, 사업자 등록번호, 업체 주소, 전화번호, 휴대폰번호, 대표자 이름, 이메일 주소
나. 컴퓨터에 의해 자동 수집되는 항목 : IP주소, 쿠키, MAC주소, 서비스 이용기록, 방문기록
 
2. 개인정보의 수집 및 이용목적
(주)에이치3네트웍스의 서비스 SHIPTER는 수집한 개인정보를 다음의 목적을 위해 활용합니다.
 
가. SHIPTER 이용과 관련되는 서비스 제공
나. 고객사 관리
- 고객사 서비스 이용에 따른 업체 확인, 업체 식별, 불량고객사의 부정이용 방지와 비인가 사용방지, 가입의사 확인 정보 수집 시 고객사 동의 확인, 분쟁 조정을 위한 기록 보존, 불만 처리 등 민원처리, 고지사항 전달
다. 그 밖의 활용
- 이벤트 및 광고성 정보 제공 및 참여기회 제공, 서비스의 유효성 확인, 접속빈도 파악이나 고객사의 서비스 이용 통계 등
 
3. 개인정보의 보유 및 이용기간
(주)에이치3네트웍스의 서비스 SHIPTER 고객사정보는 원칙적으로 개인정보의 수집 및 이용목적이 달성되면 지체 없이 파기됩니다.
 
홈페이지 고객사 정보의 보유기간은 2년이며, 2년마다 개인정보 수집 및 이용에 대하여 재동의를 하셔야 개인정보의 보유 및 이용기간이 연장됩니다.
 
고객사 탈퇴를 요청하거나, 이용기간이 종료한 경우 해당 개인정보를 지체 없이 파기합니다.
 
단, 보유기간 내 홈페이지에 로그인한 경우 개인정보 수집 및 이용에 재동의 한 것으로 간주합니다.
 
4. 정보주체의 권리
가. (주)에이치3네트웍스의 서비스 SHIPTER 고객사는 정보주체로서 다음과 같은 권리를 행사할 수 있습니다.
- 업체의 개인정보의 조회, 수정 및 가입해지의 요청
- 업체 오류의 정정 및 삭제의 요청
- 고객사 정보의 조회, 수정은 ‘설정’-‘고객사정보’등을 클릭하여 직접 열람, 정정 가능하며, 해지, 삭제 등의 요청은 전화 및 이메일로 신청할 수 있습니다.
나. (주)에이치3네트웍스의 서비스 SHIPTER는 정보주체의 권리 보장을 위하여 최선을 다하겠습니다.
5. (주)에이치3네트웍스의 서비스 SHIPTER 업체 정보 수집 동의를 거부하실 수 있으며, 다만 이 경우 고객사 가입이 제한 또는 거부되거나, 서비스 안내 등의 고지사항을 수신하지 못할 수 있습니다.`;

export const AGREEMENT_THIRD = `제1조 [목적]
이 약관은 예치금 제도로 운영되는 서비스를 제공하는 주식회사 에이치3네트웍스(이하 '회사'라 합니다)와 이용자 사이의 전자금융거래에 관한 기본적인 사항을 정함을 목적으로 한다.
 
제2조 [용어의 정의]
이 약관에서 정하는 용어의 정의는 다음과 같습니다.
1. '전자금융거래'라 함은 회사가 전자적 장치를 통하여 예치금 제도로 운영되는 서비스(이하 '전자금융거래 서비스'라고 합니다)를 제공하고, 이용자가 회사의 종사자와 직접 대면하거나 의사소통을 하지 아니하고 자동화된 방식으로 이를 이용하는 거래를 말합니다.
2. '예치금 제도로 운영되는 서비스'라 함은 전자적 방법으로 서비스 이용에 있어서 배송 비용 또는 기타 비용 정보를 송신하거나 수신하는 것 또는 그 대가의 정산하는 서비스를 말합니다.
3. '이용자'라 함은 이 약관에 동의하고 회사가 제공하는 예치금 제도로 운영되는 서비스를 이용하는 자를 말합니다.
4. '거래지시'라 함은 이용자가 전자금융거래계약에 따라 금융기관 또는 전자금융업자에게 전자금융거래의 처리를 지시하는 것을 말합니다.
5. '오류'라 함은 이용자의 고의 또는 과실 없이 전자금융거래가 전자금융거래계약 또는 이용자의 거래지시에 따라 이행되지 아니한 경우를 말합니다.
6. ‘업체확인’은 ‘이용자’의 업체명 또는 고객사 이름, 사업자등록번호, 전화번호, 내외국인, 은행 계좌번호 등의 정보를 이용하여 ‘이용자’ 본인임을 확인하는 절차를 말합니다.
 
제3조 [약관의 명시 및 변경]
1. 회사는 이용자가 예치금 제도로 운영되는 서비스를 이용하기 전에 이 약관을 게시하고 이용자가 이 약관의 중요한 내용을 확인할 수 있도록 합니다.
2. 회사는 이용자의 요청이 있는 경우 전자문서의 전송방식에 의하여 본 약관의 사본을 이용자에게 교부합니다.
3. 회사가 약관을 변경하는 때에는 그 시행일 1월 전에 변경되는 약관을 지급결제정보 입력화면 및 회사의 홈페이지에 게시함으로써 이용자에게 공지합니다.
 
제4조 [예치금의 관리 등]
1. 회사는 예치금 제도로 운영되는 서비스 제공 시 이용자의 신원, 권한 및 거래지시의 내용 등을 확인할 수 있습니다.
2. 이용자는 예치금을 제3자에게 대여하거나 사용을 위임하거나 양도 또는 담보 목적으로 제공할 수 없으며, 회사는 이로 인해 발생하는 손해에 대하여 책임지지 않습니다.
3. 이용자는 자신의 예치금을 제3자에게 누설 또는 노출하거나 방치하여서는 안 되며, 예치금의 도용이나 위조 또는 변조를 방지하기 위하여 충분한 주의를 기울여야 합니다.
4. 회사는 이용자로부터 예치금의 분실이나 도난 등의 통지를 받은 때에는 그 때부터 제3자가 그 예치금을 사용함으로 인하여 이용자에게 발생한 손해를 배상할 책임이 있습니다.
 
제5조 [회사의 책임]
1. 예치금의 위조나 변조로 발생한 사고로 인하여 이용자에게 발생한 손해에 대하여 배상책임이 있습니다. 다만, 이용자가 제6조 제2항에 위반하거나 제3자가 권한 없이 이용자의 예치금을 이용하여 전자금융거래를 할 수 있음을 알았거나 알 수 있었음에도 불구하고 이용자가 자신의 예치금을 누설 또는 노출하거나 방치한 경우 그 책임의 전부 또는 일부를 이용자가 부담하게 할 수 있습니다.
2. 회사는 계약체결 또는 거래지시의 전자적 전송이나 처리과정에서 발생한 사고로 인하여 이용자에게 손해가 발생한 경우에는 그 손해를 배상할 책임이 있습니다. 다만, 본 조 제1항 단서에 해당하거나 법인('중소기업기본법' 제2조 제2항에 의한 소기업을 제외한다)인 이용자에게 손해가 발생한 경우로서 회사가 사고를 방지하기 위하여 보안절차를 수립하고 이를 철저히 준수하는 등 합리적으로 요구되는 충분한 주의의무를 다한 경우 책임의 전부 또는 일부를 이용자가 부담하게 할 수 있습니다.
3. 회사는 이용자로부터의 거래지시가 있음에도 불구하고 천재지변, 회사의 귀책사유가 없는 정전, 화재, 통신장애 기타의 불가항력적인 사유로 처리 불가능하거나 지연된 경우로서 이용자에게 처리 불가능 또는 지연사유를 통지한 경우(금융기관 또는 결제수단 발행업체나 통신판매업자가 통지한 경우를 포함합니다)에는 이용자에 대하여 이로 인한 책임을 지지 아니합니다.
4. 회사는 전자금융거래를 위한 전자적 장치 또는 ‘정보통신망 이용촉진 및 정보보호 등에 관한 법률’ 제2조제1항제1호에 따른 정보통신망에 침입하여 거짓이나 그 밖의 부정한 방법으로 획득한 접근매체의 이용으로 발생한 사고로 인하여 이용자에게 그 손해가 발생한 경우에는 그 손해를 배상할 책임이 있습니다.
 
제6조 [거래내용의 확인]
1. 회사는 이용자와 미리 약정한 전자적 방법을 통하여 이용자의 거래내용(이용자의 '오류정정 요구사실 및 처리결과에 관한 사항'을 포함합니다)을 확인할 수 있도록 하며, 이용자의 요청이 있는 경우에는 요청을 받은 날로부터 2주 이내에 모사전송 등의 방법으로 거래내용에 관한 서면을 교부합니다.
2. 회사가 이용자에게 제공하는 거래내용 중 거래계좌의 명칭 또는 번호, 거래의 종류 및 금액, 거래상대방을 나타내는 정보, 거래일자, 전자적 장치의 종류 및 전자적 장치를 식별할 수 있는 정보와 해당 전자금융거래와 관련한 전자적 장치의 접속기록은 5년간, 건당 거래금액이 1만원 이하인 소액 전자금융거래에 관한 기록, 전자지급수단 이용시 거래승인에 관한 기록, 이용자의 오류정정 요구사실 및 처리결과에 관한 사항은 1년간의 기간을 대상으로 합니다.
3. 이용자가 제1항에서 정한 서면교부를 요청하고자 할 경우 다음의 주소 및 전화번호로 요청할 수 있습니다.
- 주소: 서울시 강서구 화곡로 416 더스카이벨리 5차 제지층 제비103호
- 이메일 주소: CS@SHIPTER.KR
- 전화번호: 010-2304-1331
 
제7조 [오류의 정정 등]
1. 이용자는 예치금 제도로 운영되는 서비스를 이용함에 있어 오류가 있음을 안 때에는 회사에 대하여 그 정정을 요구할 수 있습니다.
2. 회사는 전항의 규정에 따른 오류의 정정요구를 받은 때에는 이를 즉시 조사하여 처리한 후 정정요구를 받은 날부터 2주 이내에 그 결과를 이용자에게 알려 드립니다.
 
제8조 [예치금 제도로 운영되는 서비스 이용 기록의 생성 및 보존]
1. 회사는 이용자가 예치금 제도로 운영되는 서비스 이용거래의 내용을 추적, 검색하거나 그 내용에 오류가 발생한 경우에 이를 확인하거나 정정할 수 있는 기록을 생성하여 보존합니다.
2. 전항의 규정에 따라 회사가 보존하여야 하는 기록의 종류 및 보존방법은 제6조 제2항에서 정한 바에 따릅니다.
 
제9조 [거래지시의 철회 제한]
1. 이용자는 전자지급거래에 관한 거래지시의 경우 지급의 효력이 발생하기 전까지 거래지시를 철회할 수 있습니다.
2. 전항의 지급의 효력이 발생 시점이란 (i) 전자자금이체의 경우에는 거래 지시된 금액의 정보에 대하여 수취인의 계좌가 개설되어 있는 금융기관의 계좌 원장에 입금기록이 끝난 때 (ii) 그 밖의 전자지급수단으로 지급하는 경우에는 거래 지시된 금액의 정보가 수취인의 계좌가 개설되어 있는 금융기관의 전자적 장치에 입력이 끝난 때를 말합니다.
3. 이용자는 지급의 효력이 발생한 경우에는 전자상거래 등에서의 소비자보호에 관한 법률 등 관련 법령상 청약의 철회의 방법 또는 본 약관 제5조에서 정한 바에 따라 결제대금을 반환 받을 수 있습니다.
 
제10조 [전자금융거래정보의 제공금지]
회사는 예치금 제도로 운영되는 서비스를 제공함에 있어서 취득한 이용자의 인적 사항, 이용자의 계좌, 예치금 및 전자금융거래의 내용과 실적에 관한 정보 또는 자료를 이용자의 동의를 얻지 아니하고 제3자에게 제공, 누설하거나 업무상 목적 외에 사용하지 아니합니다.
 
제11조 [분쟁처리 및 분쟁조정]
1. 이용자는 다음의 분쟁처리 책임자 및 담당자에 대하여 예치금 제도로 운영되는 서비스 이용과 관련한 의견 및 불만의 제기, 손해배상의 청구 등의 분쟁처리를 요구할 수 있습니다.
- 전화번호: 010-2304-1331
- 전자우편주소: CS@SHIPTER.KR
 2. 이용자가 회사에 대하여 분쟁처리를 신청한 경우에는 회사는 15일 이내에 이에 대한 조사 또는 처리 결과를 이용자에게 안내합니다.
3. 이용자는 '금융감독기구의 설치 등에 관한 법률' 제51조의 규정에 따른 금융감독원의 금융분쟁조정위원회나 '소비자보호법' 제31조 제1항의 규정에 따른 소비자보호원에 회사의 예치금 제도로 운영되는 서비스의 이용과 관련한 분쟁조정을 신청할 수 있습니다.
 
제12조 [회사의 안정성 확보 의무]
회사는 전자금융거래의 안전성과 신뢰성을 확보할 수 있도록 전자금융거래의 종류별로 전자적 전송이나 처리를 위한 인력, 시설, 전자적 장치 등의 정보기술부문 및 전자금융업무에 관하여 금융감독위원회가 정하는 기준을 준수합니다.
 
제13조 [약관 외 준칙 및 관할]
1. 이 약관에서 정하지 아니한 사항에 대하여는 전자금융거래법, 전자상거래 등에서의 소비자 보호에 관한 법률, 통신판매에 관한 법률, 여신전문금융업법 등 소비자보호 관련 법령에서 정한 바에 따릅니다.
2. 회사와 이용자 간에 발생한 분쟁에 관한 관할은 민사소송법에서 정한 바에 따릅니다.`;
