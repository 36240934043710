import ListHOC from "../../../SharedComponents/ListHOC";
import CustomCard from "../../../components/CustomCard";
import { Grid, MenuItem, Pagination, Stack } from "@mui/material";
import CustomSelect from "../../../components/CustomSelect";
import { gql } from "@apollo/client/core";
import { SelectBox, TextFieldBox } from "../../../components/CustomInput";
import CustomButton from "../../../components/CustomButton";
import { APIClient } from "../../../utils/apiGraphql";
import TableContainer from "../../../components/TableContainer";
import React, { useCallback, useEffect, useState } from "react";
import { useDataProvider } from "react-admin";
import Toaster from "../../../utils/Toaster";
import ShippingTypeModal from "../../../modals/ShippingTypeModal";
import { onDropDownSearchChange } from "../../../utils/onDropDownSearchChange";
import DropdownAndSearchInput from "../../../components/DropdownAndSearchInput";
import { SearchBox } from "../../../components/Searchbox";
import { spacing } from "@mui/system";
import { generateSearchData } from "../../../utils/utils";

function ShippingTypeContainer({
	data: _Data,
	count,
	page,
	perPage,
	handlePageChange,
	handleSearch,
	handleSetPerPage,
	countTotal,
	...hocProps
}) {
	const dataProvider = useDataProvider();
	const [data, setData] = useState([]);
	const [selected, setSelected] = useState(null);
	const [filterQuery, setFilterQuery] = useState(null);
	const [shippingTypes, setShippingTypes] = useState([]);

	const [apiTypes, setApiTypes] = useState([]);

	const [openDialog, setOpenDialog] = useState(false);

	const getShippingTypes = async () => {
		const Query = gql(`
		query{
			allShippingTypes{
				id
			}
		}
      `);

		try {
			const {
				data: { allShippingTypes: shipping },
			} = await APIClient.query({
				query: Query,
			});
			setShippingTypes(shipping);
		} catch (e) {
			console.log(e);
		}
	};

	useEffect(() => {
		getShippingTypes();
	}, []);

	useEffect(() => {
		setData(_Data);
	}, [_Data]);

	useEffect(() => {
		dataProvider
			.getList("ApiType", {})
			.then(({ data }) => {
				setApiTypes(data.map(({ id, name }) => ({ id, name })));
			})
			.catch((e) => {
				Toaster("error", e.message);
			});
	}, []);

	const handleClose = () => {
		setOpenDialog(false);
		setSelected(null);
	};

	const doSearch = useCallback(() => {
		if (filterQuery) {
			generateSearchData(handleSearch, filterQuery);
		}
	}, [filterQuery]);

	// useEffect(() => {
	// 	doSearch();
	// }, [filterQuery]);

	const handleUpdate = (data) => {
		setSelected(data);
		setOpenDialog(true);
	};

	const handleSuccess = (data, reason) => {
		if (reason === "update") {
			setData((prevState) => {
				const temp = [...prevState];
				const index = prevState.findIndex((res) => res.id === data.id);
				if (index > -1) {
					temp[index] = data;
				}
				return temp;
			});
		} else {
			setData((prevState) => {
				const temp = [...prevState];
				temp.push(data);
				return temp;
			});
		}
	};
	// console.log("Filter", filterQuery);
	return (
		<>
			<CustomCard>
				<Stack spacing={"35px"}>
					<Grid xs={12} container justifyContent={"space-between"}>
						<Grid
							item
							xs={3}
							style={{
								justifyContent: "start",
								display: "flex",
								gap: "20px",
							}}
							// padding='8px'
						>
							<CustomButton
								onClick={() => setOpenDialog(true)}
								styles={{ width: "auto", padding: "10px" }}
							>
								배송 타입 추가
							</CustomButton>
						</Grid>
						<Grid item xs={9}>
							<Grid xs={12} container sx={{ justifyContent: "right" }}>
								<Grid sx={{ marginRight: "10px" }} xs={2}>
									<CustomButton
										variant={"outlined"}
										styles={{
											color: "black",
											fontWeigh: "light",
											border: "1px solid #C4C4C4",
											borderRadius: 4,
											height: "40px",
											borderRadius: "10px",
										}}
									>
										배송 타입
									</CustomButton>
								</Grid>

								<Grid sx={{ marginRight: "10px" }} item xs={5}>
									<TextFieldBox
										placeholder="배송 타입을  입력해주세요."
										onChange={(event) =>
											// setData(
											// 	_Data.filter((row) =>
											// 		row.deliveryType.includes(event.target.value)
											// 	)
											// )
											setFilterQuery({
												deliveryType: event.target.value.toUpperCase(),
											})
										}
									/>
								</Grid>

								<Grid item xs={1}>
									<CustomButton
										style={{ height: "38px", borderRadius: 10 }}
										onClick={doSearch}
									>
										검색
									</CustomButton>
								</Grid>

								<Grid item xs={2}>
									<CustomSelect
										value={String(perPage)}
										label=""
										onChange={({ target }) => {
											handleSetPerPage(target.value);
										}}
										options={["10", "20", "30", "40", "50", "100"]}
									></CustomSelect>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					<TableContainer
						{...hocProps}
						headers={["No.", "배송 타입", "배송 종류"]}
						data={data
							.sort((a, b) => b.id - a.id)
							.map((item, index) => [
								shippingTypes.length - (page - 1) * perPage - index,
								<div
									style={{ cursor: "pointer" }}
									onClick={() => handleUpdate(item)}
								>
									{item.deliveryType}
								</div>,
								item.details.map((res) => res["shippingType"]).join(","),
							])}
					/>
				</Stack>
			</CustomCard>{" "}
			<div
				style={{
					display: "flex",
					justifyContent: "center",
					marginTop: 30,
				}}
			>
				<Pagination
					count={Math.ceil(countTotal / perPage)}
					page={page}
					onChange={handlePageChange}
					color="primary"
					shape="rounded"
				/>
			</div>
			{openDialog && (
				<ShippingTypeModal
					apiTypes={apiTypes}
					onSuccess={handleSuccess}
					selected={selected}
					isOpen={openDialog}
					onClose={handleClose}
					{...hocProps}
				/>
			)}
		</>
	);
}

export const ShippingTypeList = (props) => {
	return (
		<ListHOC pagination={false} {...props}>
			<ShippingTypeContainer />
		</ListHOC>
	);
};
