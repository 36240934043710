import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import moment from "moment";
import BackupTableIcon from "@mui/icons-material/BackupTable";
import statusSwitch from "../utils/statusSwitch";
// import { useState } from "react";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		fontWeight: "bold",
		backgroundColor: "#F8F8FA",
		color: "rgba(0, 0, 0, 0.6)",
		whiteSpace: "nowrap",
	},
	[`&.${tableCellClasses.body}`]: {
		backgroundColor: "white",
		fontSize: 14,
		whiteSpace: "nowrap",
	},
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
	"&:nth-of-type(odd)": {
		backgroundColor: theme.palette.action.hover,
	},
	// hide last border
	"&:last-child td, &:last-child th": {
		border: 0,
	},
}));

// dataProvider

export const ShippingFeeHistoryTable = ({
	data = [],
	count,
	page,
	pageSize,
}) => {
	// console.log(
	// 	"count",
	// 	count,
	// 	"length",
	// 	data.length,
	// 	"page",
	// 	page,
	// 	"pageSize",
	// 	pageSize
	// );
	return (
		<TableContainer component={Paper} sx={{ maxHeight: 700 }}>
			<Table
				sx={{ minWidth: 700, border: "1px solid rgba(224, 224, 224, 1)" }}
				aria-label="customized table"
				stickyHeader
			>
				<TableHead>
					<TableRow>
						<StyledTableCell align="center">No</StyledTableCell>
						<StyledTableCell align="center">업체명</StyledTableCell>
						<StyledTableCell align="center">배송번호</StyledTableCell>
						<StyledTableCell align="center">접수번호</StyledTableCell>
						<StyledTableCell align="center">배송비</StyledTableCell>
						<StyledTableCell align="center">배송비 차감날짜</StyledTableCell>
						<StyledTableCell align="center">상품이름</StyledTableCell>
						<StyledTableCell align="center">상품수량</StyledTableCell>
						<StyledTableCell align="center">총상품금액</StyledTableCell>
						<StyledTableCell align="center">적용무게</StyledTableCell>
						<StyledTableCell align="center">실제무게</StyledTableCell>
						<StyledTableCell align="center">부피무게</StyledTableCell>
						<StyledTableCell align="center">주문번호</StyledTableCell>
						<StyledTableCell align="center">배송국가</StyledTableCell>
						<StyledTableCell align="center">배송종류</StyledTableCell>
						<StyledTableCell align="center">배송상태</StyledTableCell>
						<StyledTableCell align="center">출고날짜</StyledTableCell>
					</TableRow>
				</TableHead>

				{data?.length > 0 ? (
					<TableBody>
						{data.map((row, index) => (
							<StyledTableRow key={row.id}>
								<StyledTableCell align="center">
									{count - (page * pageSize + index)}
								</StyledTableCell>
								<StyledTableCell align="center">
									{row.company_name || ""}
								</StyledTableCell>
								<StyledTableCell align="center">
									{row.shipping_number || ""}
								</StyledTableCell>
								<StyledTableCell align="center">
									{row.trackingNumber || ""}
								</StyledTableCell>
								<StyledTableCell align="center">
									{parseInt(row.shipping_fee).toLocaleString() || ""}
								</StyledTableCell>
								<StyledTableCell align="center">
									{row?.shipping_fee_deducted_date
										? moment(row.shipping_fee_deducted_date).format(
												"YYYY-MM-DD"
										  )
										: ""}
								</StyledTableCell>
								<StyledTableCell align="center">
									{row.product_name || ""}
								</StyledTableCell>
								<StyledTableCell align="center">
									{row.product_quantity || ""}
								</StyledTableCell>
								<StyledTableCell align="center">
									{row.total_product_price || ""}
								</StyledTableCell>
								<StyledTableCell align="center">
									{row.applicable_weight || ""}
								</StyledTableCell>
								<StyledTableCell align="center">
									{row.actual_weight || ""}
								</StyledTableCell>
								<StyledTableCell align="center">
									{row.bulky_weight || ""}
								</StyledTableCell>
								<StyledTableCell align="center">
									{(row?.requestShipping !== null &&
										row?.requestShipping.productInformation.orderNo1 &&
										row?.requestShipping.productInformation.orderNo1) ||
										""}
								</StyledTableCell>
								<StyledTableCell align="center">
									{row.shipping_country || ""}
								</StyledTableCell>
								<StyledTableCell align="center">
									{row.delivery_type || ""}
								</StyledTableCell>
								<StyledTableCell align="center">
									{(row.delivery_status && statusSwitch(row.delivery_status)) ||
										""}
								</StyledTableCell>
								<StyledTableCell align="center">
									{row?.delivery_date
										? moment(row.delivery_date).format("YYYY-MM-DD")
										: ""}
								</StyledTableCell>
							</StyledTableRow>
						))}
					</TableBody>
				) : (
					<tbody>
						<tr>
							<td colSpan="16">
								<Empty>
									<div>
										<BackupTableIcon fontSize="large" />
										<p>데이터 없음</p>
									</div>
								</Empty>
							</td>
						</tr>
					</tbody>
				)}
			</Table>
		</TableContainer>
	);
};

const Empty = styled("div")({
	height: "300px",
	display: "grid",
	placeItems: "center",
	textAlign: "center",
	opacity: "0.6",
});
