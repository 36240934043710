import React from "react";

function DocumentIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      fill="none"
      viewBox="0 0 18 18"
    >
      <path
        fill="#427DF3"
        fillRule="evenodd"
        d="M6.103 16.5c-.309 0-.602-.024-.878-.071l-.163-.031c-1.779-.37-2.812-1.732-2.812-3.805V5.407c0-.25.015-.49.045-.72C2.557 2.673 3.94 1.5 6.103 1.5h5.794c2.397 0 3.83 1.44 3.853 3.849v7.244c0 2.443-1.437 3.907-3.853 3.907H6.103zm0-13.907c-1.842 0-2.775.947-2.775 2.814v7.186c0 1.867.933 2.814 2.775 2.814h5.787c1.841 0 2.77-.947 2.77-2.814V5.407c0-1.867-.928-2.814-2.77-2.814H6.103zm6.126 9.582c0-.3-.239-.543-.534-.543H6.285a.54.54 0 00-.535.543c0 .3.24.543.535.543h5.409a.539.539 0 00.534-.543zm-.534-3.722a.542.542 0 01.518.255c.11.179.11.405 0 .585a.542.542 0 01-.518.254H6.285A.548.548 0 015.804 9c0-.28.208-.516.482-.547h5.409zM8.832 5.828a.547.547 0 00-.483-.546H6.293a.547.547 0 00-.483.546c0 .28.208.515.483.546H8.35a.547.547 0 00.483-.546z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default DocumentIcon;
