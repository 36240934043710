import styled from "styled-components";
import CustomInput, { SelectBox, TextFieldBox } from "../CustomInput";

import { Divider, Grid, MenuItem, Switch, Typography } from "@mui/material";
import { useRef, useState } from "react";
import { useQuery } from "@apollo/client";
import { FIND_REQUEST_SHIPPING } from "./queries";
import { changeWeightAtom } from "./atom";
import { useAtom } from "jotai";
import _ from "lodash";

const WeightInfoBulkWeight = () => {
  const [weight, setWeight] = useAtom(changeWeightAtom);
  const onChange = (e) => {
    setWeight({ [e.target.name]: parseFloat(e.target.value) });
  };
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        gap: "10px",
        marginLeft: "10px",
      }}
    >
      <TextFieldBox
        type={"number"}
        min={0}
        name="horizontal"
        onChange={onChange}
        styles={{ width: 100 }}
        step={0.01}
      />
      <Typography>cm *</Typography>

      <TextFieldBox
        type={"number"}
        min={0}
        name="portrait"
        onChange={onChange}
        styles={{ width: 100 }}
        step={0.001}
      />
      <Typography>cm *</Typography>

      <TextFieldBox
        type={"number"}
        min={0}
        step={0.001}
        name="height"
        onChange={onChange}
        styles={{ width: 100 }}
      />
      <Typography>cm /</Typography>

      <SelectBox
        defaultValue="5000"
        name="perValue"
        styles={{ width: 100 }}
        onChange={onChange}
        value={weight.perValue}
      >
        <MenuItem value="5000">5000</MenuItem>
        <MenuItem value="6000">6000</MenuItem>
      </SelectBox>
      <Typography>=</Typography>

      <TextFieldBox
        key={"miscellaneousInformation.bulkWeight"}
        type={"number"}
        min={0}
        step={0.001}
        name="bulkWeight"
        onChange={onChange}
        value={weight.bulkWeight}
        styles={{ width: 100 }}
      />
      <Typography>kg</Typography>
    </div>
  );
};

export default WeightInfoBulkWeight;
