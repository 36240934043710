import { Grid, MenuItem } from "@mui/material";
import { SelectBox, TextFieldBox } from "../../../components/CustomInput";
import React, { useState } from "react";
import CustomButton from "../../../components/CustomButton";

function DropdownAndSearchInput({
  onChange,
  defaultValue = { key: "", value: "" },
  value = { key: "", value: "" },
  dropDownValues = [{ value: "", title: "" }],
  inputFieldPlaceHolder = "",
  ...props
}) {
  const [data, setData] = useState({
    field: "all",
    query: "",
  });

  return (
    <>
      <Grid container xs={11} gap={1}>
        <Grid item xs={3}>
          <SelectBox
            name={"key"}
            onChange={(e) => setData({ ...data, field: e.target.value })}
            defaultValue={defaultValue.key}
            value={data.field}
          >
            {dropDownValues.map(({ value, title }, index) => {
              return (
                <MenuItem key={index} value={value}>
                  {title}
                </MenuItem>
              );
            })}
          </SelectBox>
        </Grid>

        <Grid xs={7}>
          <TextFieldBox
            onChange={(e) => setData({ ...data, query: e.target.value })}
            name="value"
            value={data.query}
            placeholder={inputFieldPlaceHolder}
          />
        </Grid>
        <Grid xs={1}>
          <CustomButton
            onClick={() => onChange(data)}
            style={{
              height: "38px",
              borderRadius: 10,
              backgroundColor: "#5B8FF7",
            }}
          >
            검색
          </CustomButton>
        </Grid>
      </Grid>

      {/* <Grid container item xs={6} spacing={0.5} alignItems={"center"}>
        <Grid item flexGrow={1} sx={{ display: "flex" }}>
          <TextFieldBox
            onChange={handleFilterChange}
            onKeyDown={(event) => {
              if (event.keyCode === 13) {
                handleOnChange();
              }
            }}
            name="value"
            value={filterQuery.value}
            placeholder={
              inputFieldPlaceHolder ||
              `${
                dropDownValues.find((res) => res.value === filterQuery.key)
                  ?.title || "업체명"
              } 입력해주세요`
            }
          />
          <IconButton
            variant={"contained"}
            color={"primary"}
            onClick={handleOnChange}
            sx={{ml:1}}
          >
            <SearchIcon />
          </IconButton>
        </Grid>
      </Grid> */}
    </>
  );
}

export default DropdownAndSearchInput;
