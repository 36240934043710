import { Button, Divider, Grid, MenuItem, Typography } from "@mui/material";
import CustomInput, { TextFieldBox } from "../../../../components/CustomInput";
import { FieldArray } from "formik";
import React, { useCallback, useEffect, useMemo } from "react";

export const CreateStepThree = ({
	handleChange,
	handleBlur,
	values,
	errors,
	setFieldValue,
}) => {
	// const [productInfos, setProductInfos] = useState([]);

	// const handleAddInfo = () => {
	//   setProductInfos((productInfos) => [...productInfos, {}]);
	// };

	// remove
	// const handleRemoveInfo = (index) => {
	//   const newInfos = productInfos.filter((e, i) => i !== index);
	//   setProductInfos(newInfos);
	// };

	const totalPrice = useMemo(() => {
		if (values) {
			const { productInformation } = values;
			if (productInformation) {
				return productInformation.productInformation.reduce(
					(a, b) => (b ? b.quantity * b.unitPrice + a : 0),
					0
				);
			}
		} else {
			return 0;
		}
	}, [values.productInformation]);

	const calculateTotalValue = useCallback(() => {
		setFieldValue("productInformation.totalPriceAmount", totalPrice);
	}, [totalPrice]);

	useEffect(() => {
		calculateTotalValue();
	}, [totalPrice]);

	return (
		<div
			style={{
				borderRadius: 10,
				overflow: "hidden",
				border: "1px solid #E5E5E5",
				overflowX: "auto",
			}}
		>
			<Grid container>
				<Grid item xs={6}>
					<div>
						<CustomInput
							placeholder="주문번호를 입력해주세요."
							label="주문번호1"
							labelWidth="300px"
							required={false}
							name="productInformation.orderNo1"
							value={values.productInformation.orderNo1}
							onChange={handleChange}
							onBlur={handleBlur}
							stepper={true}
						/>
						{errors?.productInformation?.orderNo1 && (
							<Typography fontSize={"14px"} margin="10px " color="#ff0000">
								{"주문번호를 입력해주세요."}
							</Typography>
						)}
					</div>
				</Grid>

				<Grid item xs={6}>
					<div>
						<CustomInput
							placeholder="주문번호를 입력해주세요."
							label="주문번호2"
							labelWidth="300px"
							name="productInformation.orderNo2"
							value={values.productInformation.orderNo2}
							onChange={handleChange}
							onBlur={handleBlur}
							stepper={true}
						/>
						{errors?.productInformation?.orderNo2 && (
							<Typography fontSize={"14px"} margin="10px " color="#ff0000">
								{"주문번호를 입력해주세요."}
							</Typography>
						)}
					</div>
				</Grid>

				<Grid item xs={12}>
					<div>
						<Divider />
					</div>
				</Grid>

				<Grid item xs={6}>
					<div>
						<CustomInput
							placeholder="출발국가 택배번호를 입력해주세요."
							label={"출발국가 택배번호 (CJ, 한진, 롯데택배…택배번호)"}
							labelWidth="300px"
							name="productInformation.departureCountryCourierNumber"
							value={values.productInformation.departureCountryCourierNumber}
							onChange={handleChange}
							stepper={true}
							onBlur={handleBlur}
						/>
						{errors?.productInformation?.departureCountryCourierNumber && (
							<Typography fontSize={"14px"} margin="10px " color="#ff0000">
								{"출발국가 택배번호를 입력해주세요."}
							</Typography>
						)}
					</div>
				</Grid>

				<Grid item xs={6}>
					<div>
						<CustomInput
							select
							placeholder="화폐단위 선택"
							label="화폐단위"
							labelWidth="300px"
							name="productInformation.currency"
							defaultValue={values.productInformation.currency}
							onChange={handleChange}
							onBlur={handleBlur}
							stepper={true}
							required={true}
						>
							<MenuItem value={`USD`}>{"USD"}</MenuItem>
							<MenuItem value={`EUR`}>{"EUR"}</MenuItem>
						</CustomInput>
						{errors?.productInformation?.currency && (
							<Typography fontSize={"14px"} margin="10px " color="#ff0000">
								{"화폐단위 선택"}
							</Typography>
						)}
					</div>
				</Grid>

				<Grid item xs={12}>
					<Divider />
				</Grid>

				{/* field Array */}
				<FieldArray name="productInformation.productInformation">
					{({ push, remove, form }) => {
						const { values } = form;
						// console.log("props", values.productInformation?.productInformation);

						return (
							<div>
								{values.productInformation?.productInformation.map(
									(item, index) => (
										<div key={index}>
											<Grid item xs={12}>
												<Divider />
											</Grid>

											<Grid item xs={12}>
												<div>
													<ProductInfoInput
														color={index === 0 ? "primary" : "error"}
														btnText={index === 0 ? "추가" : "삭제"}
														btnClick={() => {
															index === 0
																? push({
																		barcode: "",
																		productName: "",
																		quantity: null,
																		unitPrice: "",
																		brandName: "",
																		sku: "",
																		purchaseURL: "",
																		hscode: "",
																  })
																: remove(index);
														}}
														index={index}
														handleChange={handleChange}
														name={`productInformation`}
														error={errors}
														values={values}
														setFieldValue={setFieldValue}
													/>
												</div>
											</Grid>
										</div>
									)
								)}
							</div>
						);
					}}
				</FieldArray>

				<Grid item xs={12}>
					<Divider />
				</Grid>

				<Grid item xs={12}>
					<CustomInput
						placeholder="이름을 입력해주세요."
						label="총 상품 금액"
						required={false}
						labelWidth="245px"
						stepper={true}
						input={
							<Grid spacing="10px" container>
								<Grid item xs={4.5}>
									<div>
										<TextFieldBox
											stepper={true}
											readonly={true}
											placeholder="총 상품 금액을 입력해주세요."
											name="productInformation.totalPriceAmount"
											value={values.productInformation.totalPriceAmount}
											onChange={handleChange}
											labelWidth="220px"
											type="number"
										/>

										<Typography
											fontSize={"14px"}
											margin="10px "
											color="#ff0000"
										>
											{errors?.productInformation?.totalPriceAmount}
										</Typography>
									</div>
								</Grid>
							</Grid>
						}
					/>
				</Grid>
			</Grid>
		</div>
	);
};

const ProductInfoInput = ({
	color = "primary",
	label = "상품정보",
	btnText = "추가",
	btnClick,
	handleChange,
	index,
	values,
	errors,
	name,
	setFieldValue,
}) => {
	return (
		<CustomInput
			label={label}
			labelWidth="245px"
			stepper={true}
			input={
				<Grid spacing="10px" container>
					<Grid item xs={3}>
						<TextFieldBox
							stepper={true}
							onChange={handleChange}
							required={false}
							name={`${name}.productInformation[${index}].barcode`}
							value={
								values?.[`${name}`]?.["productInformation"]?.[index]?.barcode
							}
							error={
								!!errors?.[`${name}`]?.["productInformation"]?.[index]?.barcode
							}
							placeholder="바코드"
						/>
					</Grid>
					<Grid item xs={4}>
						<TextFieldBox
							stepper={true}
							onChange={handleChange}
							required={true}
							name={`${name}.productInformation[${index}].productName`}
							value={
								values?.[`${name}`]?.["productInformation"]?.[index]
									?.productName
							}
							error={
								!!errors?.[`${name}`]?.["productInformation"]?.[index]
									?.productName
							}
							placeholder="상품명*"
						/>
					</Grid>
					<Grid item xs={2}>
						<TextFieldBox
							stepper={true}
							onChange={(e) => {
								handleChange(e);
							}}
							type={"number"}
							required={true}
							name={`${name}.productInformation[${index}].quantity`}
							value={
								values?.[`${name}`]?.["productInformation"]?.[index]?.quantity
							}
							error={
								!!errors?.[`${name}`]?.["productInformation"]?.[index]?.quantity
							}
							placeholder="수량*"
						/>
					</Grid>
					<Grid item xs={2}>
						<TextFieldBox
							stepper={true}
							onChange={(e) => {
								handleChange(e);
							}}
							type={"number"}
							step={0.01}
							required={true}
							name={`${name}.productInformation[${index}].unitPrice`}
							value={
								values?.[`${name}`]?.["productInformation"]?.[index]?.unitPrice
							}
							error={
								!!errors?.[`${name}`]?.["productInformation"]?.[index]
									?.unitPrice
							}
							placeholder="단가*"
						/>
					</Grid>
					<Grid item xs={2.2}>
						<TextFieldBox
							stepper={true}
							onChange={handleChange}
							required={true}
							name={`${name}.productInformation[${index}].brandName`}
							value={
								values?.[`${name}`]?.["productInformation"]?.[index]?.brandName
							}
							error={
								!!errors?.[`${name}`]?.["productInformation"]?.[index]
									?.brandName
							}
							placeholder="브랜드명*"
						/>
					</Grid>
					<Grid item xs={2.2}>
						<TextFieldBox
							stepper={true}
							onChange={handleChange}
							name={`${name}.productInformation[${index}].sku`}
							value={values?.[`${name}`]?.["productInformation"]?.[index]?.sku}
							error={
								!!errors?.[`${name}`]?.["productInformation"]?.[index]?.sku
							}
							placeholder="SKU"
						/>
					</Grid>
					<Grid item xs={2.2}>
						<TextFieldBox
							stepper={true}
							onChange={handleChange}
							name={`${name}.productInformation[${index}].purchaseURL`}
							value={
								values?.[`${name}`]?.["productInformation"]?.[index]
									?.purchaseURL
							}
							error={
								!!errors?.[`${name}`]?.["productInformation"]?.[index]
									?.purchaseURL
							}
							placeholder="URL"
						/>
					</Grid>
					<Grid item xs={2.2}>
						<TextFieldBox
							stepper={true}
							onChange={handleChange}
							required={true}
							name={`${name}.productInformation[${index}].hscode`}
							value={
								values?.[`${name}`]?.["productInformation"]?.[index]?.hscode
							}
							error={
								!!errors?.[`${name}`]?.["productInformation"]?.[index]?.hscode
							}
							placeholder="HSCODE*"
						/>
					</Grid>
					<Grid item xs={2.2}>
						<Button
							fullWidth
							style={{ padding: "6px 0", marginTop: 10, marginLeft: 10 }}
							color={color}
							size="medium"
							variant="outlined"
							onClick={btnClick}
						>
							{btnText}
						</Button>
					</Grid>
				</Grid>
			}
		/>
	);
};
