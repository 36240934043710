import {
	Box,
	Divider,
	Modal,
	Stack,
	TextareaAutosize,
	Typography,
} from "@mui/material";
import React from "react";
import CustomButton from "../components/CustomButton";
import CustomCard from "../components/CustomCard";
import Icons from "../utils/icon";
import { useNavigate } from "react-router-dom";
import ExclamationIcon from "../Common/assets/Images/exclamationIcon";
import ConfirmIcon from "../Common/assets/Images/confirmIcon";

export default function ExportDeclarationModal({
	isOpen,
	onClose,
	orderNumber,
	onConfirm,
	row,
	isLoading = false,
	title = "",
	description = "",
	buttonText = "",
	setRequestDescription,
	warningIcon,
	update,
	api,
}) {
	const navigate = useNavigate();
	return (
		<Modal
			open={isOpen}
			onClose={onClose}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Box
				style={{
					padding: 20,
					overflowX: "hidden",
					minHeight: "100vh",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				<CustomCard>
					<Stack
						spacing={"20px"}
						width={370}
						height={400}
						p="20px"
						justifyContent={"space-evenly"}
					>
						<Stack
							direction="row"
							alignItems={"center"}
							spacing="10px"
							justifyContent={"center"}
						>
							{api ? <ConfirmIcon /> : <ExclamationIcon />}
						</Stack>

						<Stack
							direction="column"
							alignItems={"center"}
							spacing="10px"
							justifyContent={"center"}
						>
							{api ? (
								<>
									<Typography
										fontSize={"14px"}
										color="rgba(0,0,0,0.6)"
										component={"div"}
									>
										해당 접수건 (주문번호 {orderNumber})의
									</Typography>
									<Typography
										fontSize={"14px"}
										color="rgba(0,0,0,0.6)"
										component={"div"}
									>
										수출신고 접수를 진행 하시겠습니까?
									</Typography>
								</>
							) : (
								<>
									<Typography
										fontSize={"14px"}
										color="rgba(0,0,0,0.6)"
										component={"div"}
									>
										수출 신고를 진행하기 위해서는
									</Typography>
									<Typography
										fontSize={"14px"}
										color="rgba(0,0,0,0.6)"
										component={"div"}
									>
										수출신고 API 설정이 필요합니다.
									</Typography>
								</>
							)}
						</Stack>
						{api ? (
							<div
								style={{
									display: "flex",
									justifyContent: "center",
									gap: "20px",
								}}
							>
								<CustomButton
									onClick={onClose}
									styles={{
										width: "auto",
										padding: 10,
										color: "#000",
									}}
									bg="#F8F8FA"
								>
									아니오
								</CustomButton>
								<CustomButton
									onClick={() =>
										onConfirm(row.payeeInformation?.requestShippingId)
									}
									styles={{
										width: "auto",
										padding: 10,
									}}
									bg="#5B8FF7"
								>
									예
								</CustomButton>
							</div>
						) : (
							<div
								style={{
									display: "flex",
									justifyContent: "center",
									gap: "20px",
								}}
							>
								<CustomButton
									onClick={() => {
										navigate("/settings-api");
									}}
									styles={{
										width: "80%",
										padding: 10,
									}}
									bg="#5B8FF7"
								>
									수출신고 API 설정하기
								</CustomButton>
							</div>
						)}
					</Stack>
				</CustomCard>
			</Box>
		</Modal>
	);
}
