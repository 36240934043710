import { APIClient } from "../../../../utils/apiGraphql";
import { gql } from "@apollo/client/core";
import Toaster from "../../../../utils/Toaster";

//Gets all the shipping countries
export const getAllCountries = async () => {
	const queryAllCountries = gql(`query allShippingCountries{
    allShippingCountries{
      name
      id
      shippingTypesDetailsIds
      code
    }
  }
    `);

	const res = await APIClient.query({
		query: queryAllCountries,
	});
	return res;
};

export const CustomMasterQuery = async () => {
	const queryAllMasters = gql(`
  query allMasterManagements(
  $page: Float
  $perPage: Float
  $filter: MasterManagementFilterInput
  ) {
  items: allMasterManagements(
      page: $page
      perPage: $perPage
      filter: $filter
  ) {
      id
      masterNumber
      userProfile{
        id
        userID
        customer_name
        representative_name
        business_condition
        business_item
        contact_name
        totalDeposit
        company_registration_number
        company_name_en
        company_name_kr
        phone_number
        address_1
        code
        business_registration
        balance
        mobile_number
        fax_number
        username
        email
        __typename
      }
      bags {
      id
      name
      bagNumber
      shippingCountry {
          id
          name
          code
          __typename
      }
      deliveryType {
          id
          __typename
      }
      deliveryService {
          id
          shippingType
          apiType
          shippingWeight
          shippingTypeId
          createdAt
          updatedAt
          __typename
      }
      masters {
          id
          __typename
      }
      howToPayCustomDuties
      dgPackagingManagementCode
      numberOfUses
      receptionLists {
          id
          shippingNumber
          trackingNumber
          feeDeductionWeight
          shippingCountry {
            id
            name
            code
            __typename
        }
          user {
              id
              userID
              customer_name
              company_name_kr
              phone_number
              address_1
              address_2
              code
              english_address_state
              english_address_city
              english_address_street
              english_address_detail
              english_address_full_address
              english_address_zip_code
              email
          }
          productInformation {
              productInformation {
                  id
                  productName
                  quantity
                  unitPrice
                  sku
                  hscode
                  purchaseURL
              }
              currency
              totalPriceAmount
              orderNo1
              orderNo2
              departureCountryCourierNumber
          }
          bag{
            id
            name
            bagNumber
            howToPayCustomDuties
            totalRequests

          }
          payeeInformation {
              id
              euioss
              shippingCountry {
                  id
                  name
                  code
                  country_name_en
                  __typename
              }
              name
              phoneNumber1
              phoneNumber2
              zipCode
              ssn
              ukeori
              euioss
              states
              cities
              streetAddress1
              streetAddress2
              mexicoColony
              email
              createdAt
              __typename
          }
          miscellaneousInformation{
            shippingMessage
            horizontal
            portrait
            height
            actualWeight
            applicableWeight
            bulkWeight
            userData
            userDate2
            userData3
            boxQuantity
            productType
            coupon
            exportDeclarationNumber
            __typename
          }
          senderInformation{
            name
            phoneNumber
            address
            requestShippingId
            createdAt
            __typename
          }
          shippingType{
            id
            shippingType
            apiType
            apiType_{
                id
                name
                waybillCheck
                createdAt
                __typename
            }
            shippingWeight
            memo
            shippingTypeId
            createdAt
            __typename
          }
          status
          masterNo
          totalSKU
          createdAt
          deliveryFee
          additionalFee
          processReleaseDate
          waybillPrintStatus
          bagAssignDate
          exportDeclarationRequest

          __typename
      }
      weight
      
      usedWeight
      updatedAt
      createdAt
      __typename
      }
      releaseDate
      flightNumber
      carrierAgentPhoneNo
      departureAirPort
      arrivalAirPort
      DepartureTime
      date
      memo
      arrivalTime
      totalBags
      totalRequests
      deleted
      createdAt
      updatedAt
      __typename
  }
  total: _allMasterManagementsMeta(
      page: $page
      perPage: $perPage
      filter: $filter
  ) {
      count
      __typename
  }
  }`);

	try {
		const res = await APIClient.query({
			query: queryAllMasters,
		});
		return res;
	} catch (err) {
		console.log(err.message);
	}
};
