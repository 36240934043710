import {
	Button,
	Divider,
	Grid,
	Link,
	MenuItem,
	Pagination,
	Stack,
	Typography,
} from "@mui/material";
import { Form, Formik } from "formik";
import CustomButton from "../../../components/CustomButton";
import CustomInput, {
	SelectBox,
	TextFieldBox,
} from "../../../components/CustomInput";
import FileUpload from "../../../components/FileUpload";
import TableContainer from "../../../components/TableContainer";
import styled from "styled-components";
import { useState } from "react";
import { gql } from "@apollo/client";
import { APIClient } from "../../../utils/apiGraphql";
import Toaster from "../../../utils/Toaster";
import { useNavigate } from "react-router-dom";
import { useUtils } from "../../../utils/utils";

export const ClientApiSettings = (props) => {
	const [formValues, setFormValues] = useState(null);
	const navigate = useNavigate();
	const { userData } = useUtils();
	const handleChange = (e) => {
		setFormValues({
			...formValues,
			[e.target.name]: e.target.value,
		});
	};
	// console.log("user", userData);
	const handleSubmit = async () => {
		// console.log("submit", ...formValues);
		const data = formValues;
		data["id"] = userData.id;

		const createRequest =
			gql(`mutation updateUser($updateInput: UpdateUserInput!) {
			updateUser(updateInput: $updateInput) {
				id
				mallId
				sellerPartyId
				apiKey
			}
		}
		
			`);

		try {
			const res = await APIClient.mutate({
				mutation: createRequest,
				variables: {
					updateInput: data,
				},
			});
			Toaster("success", "Added api-settings");
			// setTimeout(() => {
			// 	navigate("/export-declaration");
			// }, 3000);
		} catch (e) {
			Toaster("error", e.message);
		}
	};

	return (
		<>
			<Stack spacing={"50px"} sx={{ maxWidth: "1100px" }}>
				<Formik
					enableReinitialize
					initialValues={{
						MallId: "",
						SellerPartyId: "",
						apiKey: "",
						id: "",
					}}
					onSubmit={handleSubmit}
				>
					{({ values, handleSubmit, setFieldValue, resetForm }) => {
						return (
							<CustomForm onSubmit={handleSubmit}>
								<>
									<div
										style={{
											borderRadius: "10px",
											overflow: "hidden",
											border: "1px solid #E5E5E5",
										}}
									>
										<p
											style={{
												fontWeight: "bold",
												fontSize: "12px",
												paddingLeft: "16px",
											}}
										>
											uTradeHubAPI 연동
										</p>
										<Divider />

										<CustomInput
											placeholder="Mall ID를 입력해주세요."
											label="Mall ID"
											labelWidth="200px"
											stepper={true}
											name={"mallId"}
											onChange={handleChange}
										/>

										<Divider />

										<CustomInput
											placeholder="SellerParty ID를 입력해주세요."
											label="sellerParty ID"
											labelWidth="200px"
											stepper={true}
											name={"sellerPartyId"}
											onChange={handleChange}
										/>

										<Divider />

										<CustomInput
											placeholder="API Key를 입력해주세요."
											label="API Key"
											labelWidth="200px"
											stepper={true}
											name={"apiKey"}
											onChange={handleChange}
										/>
									</div>
									<CustomButton
										width="100px"
										styles={{ marginLeft: "auto", padding: "10px" }}
										bg="#5B8FF7"
										type="submit"
									>
										저장
									</CustomButton>
								</>
							</CustomForm>
						);
					}}
				</Formik>
			</Stack>
		</>
	);
};

// export const ClientShippingRateSettings = () => {
//   return (
//     <Stack spacing={"20px"}>
//       <div style={{ width: "180px" }}>
//         <SelectBox value="UNITED STATES(미국)">
//           <MenuItem value="UNITED STATES(미국)">UNITED STATES(미국)</MenuItem>
//         </SelectBox>
//       </div>

//       <TableContainer
//           {...{ isLoading: false, requestCompleted: true }}
//         headers={[
//           "무개(KG)",
//           "[A] EP_REG(KRW)",
//           "[H] EP_REG(KRW)",
//           "[I] EP_EMS(KRW)",
//         ]}
//         data={[1, 1, 1, 1, 1, 1, 1, 1].map((item, index) => [
//           "0.100",
//           "10,000",
//           "8,540",
//           "28,750",
//         ])}
//       />

//       <div
//         style={{
//           display: "flex",
//           justifyContent: "center",
//           marginTop: 30,
//         }}
//       >
//         <Pagination count={10} color="primary" shape="rounded" />
//       </div>
//     </Stack>
//   );
// };

const CustomForm = styled(Form)`
	display: flex;
	flex-direction: column;
	gap: 20px;
`;
