import React from "react";
import { Page, Text, View, StyleSheet, Font } from "@react-pdf/renderer";

export default function PackageTable({ tableData }) {
  const renderValue = (value, index) => {
    if (value) {
      if (String(value).split(",").length > 1) {
        return (
          <View style={styles.tableRow1} key={"view" + index}>
            {value.split(",")?.map((_value, indexx) => {
              return (
                <View style={styles.tableData} key={_value + index + indexx}>
                  <Text>{_value}</Text>
                </View>
              );
            })}
          </View>
        );
      } else {
        return (
          <View style={styles.tableData} key={"view" + index}>
            <Text>{value}</Text>
          </View>
        );
      }
    }
    return <></>;
  };
  return (
    <Page style={styles.page}>
      {Object.keys(tableData).map((key, index, array) => {
        return (
          <View
            style={{
              ...styles.tableRow,
              ...(index === array.length - 1 && styles.borderBottom),
            }}
            key={key + index}
          >
            <View style={styles.tableHeader}>
              <Text style={styles.fontBold}>{key}</Text>
            </View>
            <View style={styles.tableData}>
              {renderValue(tableData[key], index)}
            </View>
          </View>
        );
      })}
    </Page>
  );
}
Font.register({
  family: "SpoqaHanSans",
  src: "https://cdn.jsdelivr.net/gh/spoqa/spoqa-han-sans@01ff0283e4f36e159ffbf744b36e16ef742da6d8/Subset/SpoqaHanSans/SpoqaHanSansLight.ttf",
});

const row = {
  display: "flex",
  alignItems: "center",
  flexDirection: "row",
};

const styles = StyleSheet.create({
  page: {
    fontFamily: "SpoqaHanSans",
    padding: 8,
  },
  tableRow: {
    ...row,
    fontSize: 12,
    borderTop: "1px solid #000",
    borderRight: "1px solid #000",
    borderLeft: "1px solid #000",
  },
  borderBottom: {
    borderBottom: "1px solid #000",
  },
  tableHeader: {
    width: "20%",
    textAlign: "center",
    borderRight: "1px solid #000",
  },
  fontBold: {
    fontWeight: "bold",
  },
  tableData: {
    paddingLeft: 10,
  },
  tableRow1: {
    ...row,
    fontSize: 12,
    gap: 10,
  },
});
