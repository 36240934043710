import React, { useEffect, useState } from "react";
import { Box, Grid, Stack, Typography } from "@mui/material";
import styled from "styled-components";
import { Button } from "@mui/material";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import { useRedirect } from "react-admin";
import { MonthlyDataTable } from "../../components/MonthlyDataTable";
import { DeliveryTimeGrid } from "../../components/DeliveryTimeGrid";
import { ChevronRight } from "@mui/icons-material";
import { gql } from "@apollo/client/core";
import Moment from "moment";
import { useGetList } from "../../utils/utils";
import { APIClient } from "../../utils/apiGraphql";
import Loader from "../../components/Loader";
import Toaster from "../../utils/Toaster";

export default function ClientDashboard() {
  const [notices, setNotices] = useState([]);
  const { getData, isLoading } = useGetList();
  const [add1, setAdd1] = useState(null);
  const [add2, setAdd2] = useState(null);
  const [add3, setAdd3] = useState(null);
  const [rate1, setRate1] = useState(null);
  const [rate2, setRate2] = useState(null);
  const [addingfile, setAddingfile] = useState(false);
  const [addingimage, setAddingimage] = useState(false);
  const redirect = useRedirect();

  const uploadFile = async (event, type) => {
    if (type.substr(0, type.length - 1) === "Add") {
      setAddingimage(true);
    } else {
      setAddingfile(true);
    }
    const Mutation = gql(`
      mutation createGeneralDataWithAttachment($attachment: Upload!, $createInput: GeneralDataCreateInputWithAttachment!) {
        createGeneralDataWithAttachment(attachment: $attachment, createInput: $createInput) {
          id
          data
					isAttachment
        }
      }
    `);

    try {
      const {
        data: {
          createGeneralDataWithAttachment: { data, isAttachment },
        },
      } = await APIClient.mutate({
        mutation: Mutation,
        variables: {
          attachment: event.target.files[0],
          createInput: {
            type,
          },
        },
      });
      if (type === "Add1") {
        setAdd1(handleAttachmentData(data));
      } else if (type === "Add2") {
        setAdd2(handleAttachmentData(data));
      } else if (type === "Add3") {
        setAdd3(handleAttachmentData(data));
      } else if (type === "Rate1") {
        setRate1(handleAttachmentData(data));
      } else if (type === "Rate2") {
        setRate2(handleAttachmentData(data));
      }
      setAddingfile(false);
      setAddingimage(false);
      Toaster("success", `파일이 성공적으로 첨부되었습니다.`);
    } catch (e) {
      Toaster("error", e.message);
      setAddingfile(false);
    }
  };

  useEffect(() => {
    (async () => {
      const noticesResponseData = await getData("Notice");
      if (noticesResponseData) {
        setNotices(noticesResponseData);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchAddData = async (type) => {
    const Query = gql(`
      query getGeneralData($type: String!) {
        getGeneralData(type: $type) {
          id
          type
					data
					isAttachment
					createdAt
        }
      }
    `);

    try {
      const {
        data: { getGeneralData },
      } = await APIClient.query({
        query: Query,
        variables: { type },
      });
      if (type === "Add1") {
        setAdd1(handleAttachmentData(getGeneralData));
      } else if (type === "Add2") {
        setAdd2(handleAttachmentData(getGeneralData));
      } else if (type === "Add3") {
        setAdd3(handleAttachmentData(getGeneralData));
      }
    } catch (e) {
      console.log(e);
    }
  };

  const fetchRateData = async (type) => {
    const Query = gql(`
      query getGeneralData($type: String!) {
        getGeneralData(type: $type) {
          id
          type
					data
					isAttachment
					createdAt
        }
      }
    `);

    try {
      const {
        data: { getGeneralData },
      } = await APIClient.query({
        query: Query,
        variables: { type },
      });
      if (type === "Rate1") {
        setRate1(handleAttachmentData(getGeneralData));
      } else if (type === "Rate2") {
        setRate2(handleAttachmentData(getGeneralData));
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleAttachmentData = (data) => {
    if (data && data.isAttachment) {
      return JSON.parse(data.data);
    }
    return { url: data.data, name: null };
  };

  useEffect(() => {
    fetchAddData("Add1");
    fetchAddData("Add2");
    fetchAddData("Add3");
    fetchRateData("Rate1");
    fetchRateData("Rate2");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stack spacing={"20px"} style={{ maxWidth: "1150px" }}>
      <Grid container alignItems={"flex-start"} spacing={"20px"}>
        <Grid item xs={12} padding="0" marginTop={2}>
          <Box
            style={{
              padding: "20px",
              border: "1px solid #C6C6C6",
              borderRadius: 30,
            }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography
                variant="h6"
                style={{ marginLeft: "20px", fontWeight: "bold" }}
              >
                공지사항
              </Typography>
              <CustomButtom onClick={() => redirect("/notice")}>
                더보기
              </CustomButtom>
            </div>

            <Grid>
              {!isLoading ? (
                notices
                  .sort((a, b) => b.id - a.id)
                  .slice(0, 5)
                  .map((item, index) => (
                    <Grid
                      item
                      xs={12}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        margin: "auto 2%",
                        paddingTop: "2%",
                        paddingBottom: "2%",
                        borderBottom:
                          index < notices.slice(0, 5).length - 1 &&
                          "1px solid #C6C6C6",
                      }}
                    >
                      <div
                        onClick={() => redirect(`/notice/${item.id}/show`)}
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          width: "100%",
                        }}
                      >
                        <Typography color="#C1C1C1" fontSize={"14px"}>
                          {Moment(item.date).format("YYYY-MM-DD")}
                        </Typography>
                        <Typography
                          overflow={"hidden"}
                          textOverflow={"ellipsis"}
                          fontSize={"14px"}
                          sx={{ ml: 1, width: "75%" }}
                        >
                          {item.title}
                        </Typography>
                        <div
                          style={{
                            display: "flex",
                          }}
                        >
                          <div
                            style={{
                              width: "5px",
                              height: "5px",
                              backgroundColor: "#427DF3",
                              borderRadius: "100%",
                              position: "relative",
                              bottom: "-8.5px",
                              marginLeft: "20px",
                              marginRight: "10px",
                            }}
                          />
                          <p
                            style={{
                              color: "#427DF3",
                              fontWeight: "bold",
                              marginTop: "0px",
                              marginRight: "5px",
                              fontSize: "14px",
                            }}
                          >
                            "첨부파일"
                          </p>
                          <ArticleOutlinedIcon
                            sx={{ color: "#427DF3", cursor: "pointer" }}
                          />
                        </div>
                      </div>

                      <ChevronRight
                        color="#C1C1C1"
                        style={{ marginBottom: "15px" }}
                      />
                    </Grid>
                  ))
              ) : (
                <Loader />
              )}
            </Grid>
          </Box>
        </Grid>
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            paddingLeft: "30px",
            paddingTop: "50px",
            paddingBottom: "50px",
            position: "relative",
            width: "100%",
          }}
        >
          <Grid
            item
            xs={5.5}
            style={{
              display: "flex",
            }}
          >
            <Typography
              style={{
                fontWeight: "bold",
                minWidth: "80%",
              }}
            >
              최신 요율표
              <br />
              <span
                style={{
                  color: "grey",
                  fontWeight: "normal",
                  fontSize: "13px",
                  marginTop: "1em",
                  display: "inline-block",
                }}
              >
                {
                  // rate1
                  // 	? rate1.slice(rate1.lastIndexOf("/") + 1)
                  rate1 ? rate1.name : "현재 업로드된 파일이 없습니다."
                }
              </span>
            </Typography>
            <Button
              variant="contained"
              component="label"
              type="submit"
              style={{
                color: "#5B8FF7",
                backgroundColor: "#F5F8FE",
                borderRadius: "10px",
                height: "40px",
                marginTop: "20px",
                width: "95px",
                border: "none",
                cursor: "pointer",
                marginLeft: "5px",
              }}
            >
              업로드
              <input
                type="file"
                hidden
                onChange={(event) => uploadFile(event, "Rate1")}
              />
            </Button>
          </Grid>
          <Grid
            item
            xs={5.5}
            style={{
              display: "flex",
            }}
          >
            <Typography
              style={{
                fontWeight: "bold",
                minWidth: "80%",
              }}
            >
              이용설명서
              <br />
              <span
                style={{
                  color: "grey",
                  fontWeight: "normal",
                  fontSize: "13px",
                  marginTop: "1em",
                  display: "inline-block",
                }}
              >
                {rate2 ? rate2.name : "현재 업로드된 파일이 없습니다."}
              </span>
            </Typography>
            <Button
              variant="contained"
              component="label"
              type="submit"
              style={{
                color: "#5B8FF7",
                backgroundColor: "#F5F8FE",
                borderRadius: "10px",
                height: "40px",
                marginTop: "20px",
                width: "95px",
                border: "none",
                cursor: "pointer",
                marginLeft: "5px",
              }}
            >
              업로드
              <input
                type="file"
                hidden
                onChange={(event) => uploadFile(event, "Rate2")}
              />
            </Button>
          </Grid>
          {addingfile && <LoadingBox>Uploading...</LoadingBox>}
        </Box>
        <Box
          style={{
            display: "flex",
            position: "relative",
          }}
        >
          <Grid
            item
            xs={4}
            style={{
              border: "1px solid rgb(198, 198, 198)",
              borderRadius: "15px",
              padding: "25px",
              textAlign: "center",
              margin: "auto 30px",
            }}
          >
            <Typography
              style={{
                fontWeight: "bold",
              }}
            >
              광고 업로드
            </Typography>
            {add1 ? (
              <img
                style={{
                  width: "100%",
                  height: "200px",
                  objectFit: "cover",
                  marginTop: "20px",
                }}
                src={add1.url}
                alt={add1.name}
              />
            ) : (
              <Typography
                style={{
                  color: "grey",
                  fontSize: "14px",
                }}
              >
                현재 업로드된 광고 이미지가 없습니다.
              </Typography>
            )}
            <Button
              variant="contained"
              component="label"
              style={{
                color: "#5B8FF7",
                backgroundColor: "#F5F8FE",
                borderRadius: "10px",
                height: "40px",
                marginTop: "20px",
                width: "105px",
                border: "none",
                cursor: "pointer",
              }}
            >
              광고 업로드
              <input
                type="file"
                hidden
                onChange={(event) => uploadFile(event, "Add1")}
              />
            </Button>
          </Grid>
          <Grid
            item
            xs={4}
            style={{
              border: "1px solid rgb(198, 198, 198)",
              borderRadius: "15px",
              padding: "25px",
              textAlign: "center",
              margin: "auto 30px",
            }}
          >
            <Typography
              style={{
                fontWeight: "bold",
              }}
            >
              광고 업로드
            </Typography>
            {add2 ? (
              <img
                style={{
                  width: "100%",
                  height: "200px",
                  objectFit: "cover",
                  marginTop: "20px",
                }}
                src={add2.url}
                alt={add2.name}
              />
            ) : (
              <Typography
                style={{
                  color: "grey",
                  fontSize: "14px",
                }}
              >
                현재 업로드된 광고 이미지가 없습니다.
              </Typography>
            )}
            <Button
              variant="contained"
              component="label"
              style={{
                color: "#5B8FF7",
                backgroundColor: "#F5F8FE",
                borderRadius: "10px",
                height: "40px",
                marginTop: "20px",
                width: "105px",
                border: "none",
                cursor: "pointer",
              }}
            >
              광고 업로드
              <input
                type="file"
                hidden
                onChange={(event) => uploadFile(event, "Add2")}
              />
            </Button>
          </Grid>
          <Grid
            item
            xs={4}
            style={{
              border: "1px solid rgb(198, 198, 198)",
              borderRadius: "15px",
              padding: "25px",
              textAlign: "center",
              margin: "auto 30px",
            }}
          >
            <Typography
              style={{
                fontWeight: "bold",
              }}
            >
              광고 업로드
            </Typography>
            {add3 ? (
              <img
                style={{
                  width: "100%",
                  height: "200px",
                  objectFit: "cover",
                  marginTop: "20px",
                }}
                src={add3.url}
                alt={add3.name}
              />
            ) : (
              <Typography
                style={{
                  color: "grey",
                  fontSize: "14px",
                }}
              >
                현재 업로드된 광고 이미지가 없습니다.
              </Typography>
            )}
            <Button
              variant="contained"
              component="label"
              style={{
                color: "#5B8FF7",
                backgroundColor: "#F5F8FE",
                borderRadius: "10px",
                height: "40px",
                marginTop: "20px",
                width: "105px",
                border: "none",
                cursor: "pointer",
              }}
            >
              광고 업로드
              <input
                type="file"
                hidden
                onChange={(event) => uploadFile(event, "Add3")}
              />
            </Button>
          </Grid>
          {addingimage && <LoadingBox>Uploading...</LoadingBox>}
        </Box>
        <Grid
          item
          xs={12}
          style={{
            margin: "auto 30px",
          }}
        >
          <Typography
            style={{
              fontWeight: "bold",
              margin: "30px auto",
            }}
          >
            월 데이터
          </Typography>
          <MonthlyDataTable />
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            margin: "auto 30px",
          }}
        >
          <Typography
            style={{
              fontWeight: "bold",
              margin: "30px auto",
            }}
          >
            국가별 평균 배송 기간
          </Typography>
          <DeliveryTimeGrid isEditable />
        </Grid>
      </Grid>
    </Stack>
  );
}

const CustomButtom = styled.button`
  color: #5b8ff7;
  margin-right: 20px;
  border: none;
  background: transparent;
`;

const Dot = styled.div`
  width: 5px;
  height: 5px;
  background-color: #427df3;
  border-radius: 100%;
  position: relative;
  bottom: -8.5px;
  margin-left: 20px;
  margin-right: 10px;
`;

const DotText = styled.div`
  color: #427df3;
  font-weight: bold;
  margin-top: 0px;
  margin-right: 5px;
`;

const UploadButton = styled.button`
  color: #5b8ff7;
  background-color: #f5f8fe;
  border-radius: 10px;
  height: 40px;
  margin-top: 20px;
  width: 95px;
  border: none;
  cursor: pointer;
`;

const LoadingBox = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #00000047;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
`;
