/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Typography } from "@mui/material";
import { TextFieldBox } from "../../../components/CustomInput";
import TableContainer from "../../../components/TableContainer";
import { deleteRate, deleteRateDetail } from "./queries";
import Toaster from "../../../utils/Toaster";

function Table2({ data, index, getData }) {
  const initialState = [
    {
      weight: 0,
      deliveryFee: 0,
      shippingTypeDetailId: data.id,
    },
  ];
  const [data1, setData1] = useState([]);

  //Setting we get data from backend for rates
  useEffect(() => {
    if (data.ratesDetails) {
      const cloneData = { ...data };
      const filterData = cloneData.ratesDetails.filter(
        (el) => el.shippingTypeDetailId === data.id
      );
      setData1(filterData);
    } else {
      setData1(initialState);
    }
    return () => {};
  }, [data]);

  //Input change of weight
  const weightChange = (e) => {
    if (e.target.value < 0) return;
    const cloneData = [...data1];
    let obj = {
      weight: parseFloat(e.target.value),
    };
    cloneData[e.target.name] = { ...cloneData[e.target.name], ...obj };
    setData1(cloneData);
  };
  //Input change of rate
  const rateChange = (e) => {
    if (e.target.value < 0) return;
    const cloneData = [...data1];
    let obj = {
      deliveryFee: parseFloat(e.target.value),
    };
    cloneData[e.target.name] = { ...cloneData[e.target.name], ...obj };
    setData1(cloneData);
  };

  //Add Row
  const addRow = (e) => {
    const cloneData = [...data1];
    let obj = {
      weight: 0,
      deliveryFee: 0,
      shippingTypeDetailId: data.id,
    };
    cloneData.push(obj);
    setData1(cloneData);
  };

  //Remove Row
  const removeRow = (idx, id) => {
    if (data1.length === 1) return; //Limiting field of length 1
    const cloneData = [...data1];
    cloneData.splice(idx, 1);
    setData1(cloneData);

    //Finding if the current data was from backend
    let index = data.ratesDetails.findIndex((el) => el.id === id);
    if (index !== -1) {
      deleteRateDetail({ id: id }).then((res) => {
        Toaster("success", "요율이 삭제되었습니다.");
      });
    }
  };

  useEffect(() => {
    if (data1.length !== 0) getData({ index: index, data: data1 });
    return () => {};
  }, [data1]);

  return (
    <TableContainer
      {...{ isLoading: false, requestCompleted: true }}
      title={data.shippingType}
      headers={["배송 무게(Kg)", "배송비", "추가", "삭제"]}
      data={data1.map((item, index) => [
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <Typography>{">="}</Typography>
          <TextFieldBox
            type="number"
            step="0.1"
            styles={{ width: "50%", margin: "auto" }}
            name={index}
            value={item.weight}
            onChange={weightChange}
          />
        </div>,
        <TextFieldBox
          type="number"
          step="0.1"
          placeholder="6,600"
          styles={{ width: "50%", margin: "auto" }}
          name={index}
          value={item.deliveryFee}
          onChange={rateChange}
        />,
        <span
          onClick={addRow}
          style={{
            width: "40px",
            height: "40px",
            cursor: "pointer",
            color: "#5B8FF7",
            textDecoration: "underline",
          }}
        >
          추가
        </span>,
        <span
          onClick={() => removeRow(index, item.id)}
          style={{
            width: "40px",
            height: "40px",
            cursor: "pointer",
            color: "#D5495A",
            textDecoration: "underline",
          }}
        >
          삭제
        </span>,
      ])}
    />
  );
}

export default Table2;
