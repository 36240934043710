import {
	Box,
	Divider,
	Modal,
	Stack,
	TextareaAutosize,
	Typography,
} from "@mui/material";
import React from "react";
import CustomButton from "../components/CustomButton";
import CustomCard from "../components/CustomCard";
import Icons from "../utils/icon";
import { useNavigate } from "react-router-dom";

export default function BackendErrorModal({
	isOpen,
	onClose,
	errorMessage,
	onConfirm,
	isLoading = false,
	title = "",
	description = "",
	buttonText = "",
	setRequestDescription,
	warningIcon,
}) {
	const navigate = useNavigate();
	return (
		<Modal
			open={isOpen}
			onClose={onClose}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Box
				style={{
					padding: 20,
					overflowX: "hidden",
					minHeight: "100vh",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				<CustomCard>
					<Stack spacing={"20px"} width={450} p="20px">
						<Stack direction="row" alignItems={"center"} spacing="10px">
							<div>{!warningIcon ? "" : <Icons.Warning />}</div>

							<Typography fontSize={"20px"} fontWeight="700">
								{/* {title ? title : '고객 문의사항'} */}
								{title}
							</Typography>
						</Stack>

						<div>
							<Typography
								// lineHeight={"0.5"}
								fontSize={"14px"}
								color="rgba(0,0,0,0.6)"
								component={"div"}
							>
								{errorMessage ? errorMessage : "일시적인 오류입니다."}
							</Typography>
							<Typography
								// lineHeight={"0.5"}
								fontSize={"14px"}
								color="rgba(0,0,0,0.6)"
								component={"div"}
							>
								cs@shipter.kr로 문의 부탁드립니다.
							</Typography>
						</div>

						<Divider />

						<div
							style={{
								display: "flex",
								justifyContent: "flex-end",
								gap: "20px",
							}}
						>
							<CustomButton
								onClick={onClose}
								styles={{
									background: "#F8F8FA",
									color: "#000",
									width: "auto",
								}}
							>
								닫기
							</CustomButton>
						</div>
					</Stack>
				</CustomCard>
			</Box>
		</Modal>
	);
}
