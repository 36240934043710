import { MoreHoriz } from "@mui/icons-material";
import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, memo, useState } from "react";
import CustomButton from "../../components/CustomButton";
import CustomInput, { SelectBox } from "../../components/CustomInput";
import CustomPopover from "../../components/CustomPopover";
import TableContainer from "../../components/TableContainer";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import BagDrawer from "./BagDrawer";
import ListHOC from "../../SharedComponents/ListHOC";
import BagManagementModal from "../../modals/BagManagementModal";
import { useDataProvider } from "react-admin";
import Toaster from "../../utils/Toaster";
import { onDropDownSearchChange } from "../../utils/onDropDownSearchChange";
import DropdownAndSearchInput from "../../components/DropdownAndSearchInput2";
import {
  generateSearchData,
  getQueryParams,
  handleChange,
  handleOtherSearch,
  useDeleteMany,
  useDeleteManyFromMaster,
  useGetList,
} from "../../utils/utils";
import DateRangePicker from "../../components/DateRangePicker";
import Moment from "moment";
import { Formik, Form } from "formik";
import { useLocation } from "react-router-dom";
import { gql } from "@apollo/client/core";
import ConfirmModal from "../../modals/ConfirmModal";
import CustomModal from "../../components/CustomModal";
import CustomInputField from "../../components/CustomInputField";
import styled from "styled-components";
import SentIcon from "../../Common/assets/Images/SentIcon";
import BagItems from "../../components/Bag/BagItems";
import CustomSelect from "../../components/CustomSelect";
import { useNavigate } from "react-router-dom";

import Loader from "../../components/Loader";

import { useSearchParams } from "react-router-dom";
import moment from "moment";

export default function Bag(props) {
  return (
    <ListHOC _resource="MasterBag" {...props}>
      <BagManagementContainer />
    </ListHOC>
  );
}

const BagManagementContainer = memo(
  ({
    data: _Data,
    handleSetPerPage,
    perPage,
    handleSearch,
    page,
    countTotal,
    ...hocProps
  }) => {
    const dataProvider = useDataProvider();
    const [data, setData] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [selected, setSelected] = useState(null);
    const [shippingServices, setShippingServices] = useState([]);
    const navigate = useNavigate();

    const [additionalFormDetails, setAdditionalFormDetails] = useState({
      MasterManagement: [],
      ShippingCountries: [],
    });

    const [filterQuery, setFilterQuery] = useState(null);
    const [searchQuery, setSearchQuery] = useState(null);
    const [checkedList, setCheckedList] = useState([]);
    const [selectedDeleteId, setSelectedDeleteId] = useState(null);
    const { getData } = useGetList();
    const { state, search } = useLocation();
    const { isLoading, deleteMany } = useDeleteManyFromMaster();
    const [selectedBag, setSelectedBag] = useState(null);
    const [isMasterPackagingModalOpen, setIsMasterPackagingModalOpen] =
      useState(false);
    const [isMasterPackagingSentModalOpen, setIsMasterPackagingSentModalOpen] =
      useState(false);
    const [isMasterCreationModalOpen, setIsMasterCreationModalOpen] =
      useState(false);
    const [isAddBagModalOpen, setIsAddBagModalOpen] = useState(false);
    const [isChangeBagNameModalOpen, setIsChangeBagNameModalOpen] =
      useState(false);
    const [confirmModal, setConfirmModal] = useState(false);
    const [currentScreen, setCurrentScreen] = useState("main");
    const [searchParams] = useSearchParams();

    // loader for company name in advance
    const [masterLoader, setMasterLoader] = useState(
      searchParams.get("master") ? true : false
    );

    const handleRedirect = (url, id) => {
      navigate(`${url}?bag=${id}`, { state: { bag_id: id } });
    };

    useEffect(() => {
      dataProvider
        .getList("MasterManagement", {})
        .then(({ data }) => {
          setAdditionalFormDetails((prevState) => {
            const tempObj = { ...prevState };
            tempObj["MasterManagement"] = data;
            return tempObj;
          });
        })
        .catch((error) => {
          Toaster("error", error.message);
        });

      dataProvider
        .getList("ShippingCountries", {})
        .then(({ data }) => {
          setAdditionalFormDetails((prevState) => {
            const tempObj = { ...prevState };
            tempObj["ShippingCountries"] = data;
            return tempObj;
          });
        })
        .catch((error) => {
          Toaster("error", error.message);
        });

      (async () => {
        try {
          const data = await getData("ShippingTypeDetails");
          setShippingServices(data);
        } catch (e) {
          Toaster("error", e.message);
        }
      })();
    }, []);

    const handleOpenModal = () => {
      setOpenModal(true);
    };

    const handleClose = (reason) => {
      if ((reason = "confirm")) {
        setConfirmModal(false);
      }
      if (reason === "update") {
        setSelected(null);
      }
      setOpenModal(false);
    };

    const doSearch = useCallback(() => {
      let additionalValues = {};
      // console.log({ state });

      if (state) {
        const { master_id } = state;
        // console.log({ master_id });
        const queryParams = getQueryParams(search);
        const master = queryParams.get("master");
        // console.log({ master });
        additionalValues = {
          ...additionalValues,
          ...(master == master_id.toString() && {
            master_id: master_id.toString(),
            // isActive: true,
          }),
        };
        // console.log({ additionalValues });
      }

      generateSearchData(handleSearch, searchQuery, additionalValues);
    }, [searchQuery]);

    useEffect(() => {
      doSearch();
    }, [searchQuery]);

    const doSearch2 = useCallback(() => {
      if (filterQuery) {
        generateSearchData(handleSearch, filterQuery);
      }
    }, [filterQuery]);

    useEffect(() => {
      doSearch2();
    }, [filterQuery]);

    useEffect(() => {
      setData(_Data);
    }, [_Data]);

    useEffect(() => {
      if (data.length > 0 && masterLoader) {
        setSearchQuery({
          ...searchQuery,
          master_id: searchParams.get("master"),
          // isActive: true,
        });

        setTimeout(() => {
          setMasterLoader(false);
        }, 1000);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const handleSuccess = (data, reason) => {
      // if (reason === "update") {
      // 	setData((prevState) => {
      // 		const temp = [...prevState];
      // 		const index = prevState.findIndex((res) => res.id === data.id);
      // 		if (index > -1) {
      // 			temp[index] = data;
      // 		}
      // 		return temp;
      // 	});
      // } else {
      // 	setData((prevState) => {
      // 		const temp = [...prevState];
      // 		temp.push(data);
      // 		return temp;
      // 	});
      // }
      setConfirmModal(false);
      setTimeout(() => {
        generateSearchData(handleSearch, searchQuery);
      }, 5000);
    };

    const handleSelect = (item) => {
      setSelected(item);
      setOpenModal(true);
    };
    const handleCheck = ({ target }) => {
      const { value, checked } = target;
      setCheckedList((prevState) => {
        const temp = [...prevState];
        if (checked) {
          temp.push(+value);
          return temp;
        } else {
          return temp.filter((res) => res !== +value);
        }
      });
    };

    const handleDeleteSuccess = () => {
      setCheckedList([]);
      setConfirmModal(false);
      if (selectedDeleteId) setSelectedDeleteId(null);
    };

    const handleDelete = () => {
      if (!(selectedDeleteId || checkedList.length)) {
        return Toaster("error", "BAG을 선택해주세요.");
      }
      const deleteMutation = gql(`
    mutation deleteBagFromMaster($input: ManyIds!){
			deleteBagFromMaster(input: $input){
        ids
      }
    }`);
      deleteMany({
        ids: selectedDeleteId ? [selectedDeleteId] : checkedList,
        setData,
        deleteMutation,
        onSuccess: handleSuccess,
        responseKey: "deleteBagFromMaster",
      });
    };

    const handleMenuNameActionClick = (popup, setState) => {
      popup.close();
      setState(true);
    };

    // console.log({ filterQuery });

    return !masterLoader ? (
      <>
        {currentScreen === "main" ? (
          <Stack spacing={"35px"}>
            <Grid container justifyContent={"space-between"}>
              <Grid item xs={1}></Grid>

              <Grid item xs={10}>
                <Grid justifyContent={"flex-end"} container>
                  <Grid justifyContent={"flex-end"} item xs={6}>
                    <DropdownAndSearchInput
                      onChange={(data) =>
                        onDropDownSearchChange(data, setFilterQuery)
                      }
                      value={filterQuery}
                      defaultValue={{ key: "name", value: "" }}
                      dropDownValues={[{ value: "name", title: "BAG 이름" }]}
                      inputFieldPlaceHolder={"BAG 이름을 입력해주세요."}
                    />
                  </Grid>

                  <Grid
                    item
                    xs={3}
                    container
                    justifyContent={"flex-end"}
                    spacing="10px"
                    alignItems={"center"}
                  >
                    <Grid item xs={6}>
                      <CustomButton
                        onClick={() => setConfirmModal(true)}
                        disabled={!checkedList.length}
                        variant={"outlined"}
                        styles={{
                          color: "#FF000F",
                          border: "1px solid #C4C4C4",
                          borderRadius: 4,
                          height: "40px",
                        }}
                      >
                        BAG 빼기
                      </CustomButton>
                    </Grid>
                    <Grid item xs={6}>
                      <CustomSelect
                        value={String(perPage)}
                        label=""
                        onChange={({ target }) => {
                          handleSetPerPage(target.value);
                        }}
                        options={["10", "20", "30", "40", "50", "100"]}
                      ></CustomSelect>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <div style={{ position: "relative" }}>
              <TableContainer
                {...hocProps}
                headers={[
                  <Checkbox
                    checked={data.every((res) => checkedList.includes(res.id))}
                    onChange={({ target }) => {
                      if (target.checked) {
                        setCheckedList(data.map((res) => res.id));
                      } else {
                        setCheckedList([]);
                      }
                    }}
                  />,
                  "No.",
                  "BAG 이름",
                  "배송서비스",
                  "관세지불방식",
                  "DG포장관리코드",
                  "도착국가",
                  "배송타입",
                  "사용갯수",
                  "무게",
                  "사용무게",
                  "마스터 번호",
                  "생성날짜",
                  "접수 리스트",
                ]}
                data={data.map((item, index) => {
                  return [
                    <Checkbox
                      onChange={handleCheck}
                      value={item.id}
                      checked={checkedList.some((res) => res === item.id)}
                    />,
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => handleSelect(item)}
                    >
                      {countTotal - ((page - 1) * perPage + index)}
                    </div>,
                    // <PopupState variant="popover" popupId="demo-popup-menu">
                    // 	{(popupState) => (
                    // 		<React.Fragment>
                    <div
                      style={{ cursor: "pointer" }}
                      // onClick={popupState.open}
                    >
                      {item.name}
                    </div>,

                    item.deliveryService?.shippingType,
                    item.howToPayCustomDuties,
                    item.dgPackagingManagementCode,
                    <div>{item.shippingCountry?.name}</div>,
                    item.deliveryType?.deliveryType,
                    item.totalRequests,
                    item.weight,
                    item.usedWeight,
                    <div>{item.master?.masterNumber}</div>,
                    <div style={{ whiteSpace: "nowrap" }}>
                      {moment(item.createdAt).format("YYYY-MM-DD")}
                    </div>,
                    <CustomButton
                      onClick={() =>
                        handleRedirect("/reception-list-master", item.name)
                      }
                      styles={{
                        backgroundColor: "#0055FF",
                        color: "#FFFFFF",
                        borderRadius: 4,
                        height: "25px",
                        width: "120px",
                      }}
                    >
                      접수 리스트
                    </CustomButton>,
                  ];
                })}
              />
            </div>
          </Stack>
        ) : (
          <BagItems hocProps={hocProps} />
        )}

        {isMasterPackagingModalOpen && (
          <CustomModal
            title="마스터 패키징"
            onSuccess={() => {
              setIsMasterPackagingModalOpen(false);
              setIsMasterPackagingSentModalOpen(true);
            }}
            isOpen={isMasterPackagingModalOpen}
            handleClose={() => {
              setIsMasterPackagingModalOpen(false);
            }}
            hasFooter
          >
            <ModalLayout>
              <CustomInputField
                label="마스터를 선택해주세요"
                placeholder="마스터 번호를 입력해주세요."
              />
              <Divider />
            </ModalLayout>
          </CustomModal>
        )}

        {isMasterPackagingSentModalOpen && (
          <CustomModal
            isOpen={isMasterPackagingSentModalOpen}
            handleClose={() => {
              setIsMasterPackagingSentModalOpen(false);
            }}
          >
            <Box
              sx={{
                width: 300,
                height: "auto",
                alignItems: "center",
                justifyContent: "flex-start",
                display: "flex",
                flexDirection: "column",
                gap: 2,
              }}
            >
              <SentIcon />
              <ModalBodyText>마스터 패키징이 완료되었습니다</ModalBodyText>
            </Box>
          </CustomModal>
        )}

        {isMasterCreationModalOpen && (
          <CustomModal
            title="마스터 생성"
            onSuccess={() => {
              setIsMasterCreationModalOpen(false);
            }}
            isOpen={isMasterCreationModalOpen}
            handleClose={() => {
              setIsMasterCreationModalOpen(false);
            }}
            size="large"
            hasFooter
          >
            <ModalLayout>
              <CustomInputField
                label="마스터 번호"
                placeholder="마스터 번호를 입력해주세요."
                direction="row"
              />
              <CustomInputField
                label="항공편명"
                placeholder="마스터 이름을 입력해주세요."
                direction="row"
              />
              <CustomInputField
                label="날짜"
                placeholder="마스터 이름을 입력해주세요."
                direction="row"
              />
              <CustomInputField
                label="출발 공항"
                placeholder="마스터 이름을 입력해주세요."
                direction="row"
              />
              <CustomInputField
                label="도착 공항"
                placeholder="마스터 이름을 입력해주세요."
                direction="row"
              />
              <CustomInputField
                label="출발 시간"
                placeholder="마스터 이름을 입력해주세요."
                direction="row"
              />
              <CustomInputField
                label="도착 시간"
                placeholder="마스터 이름을 입력해주세요."
                direction="row"
              />
              <CustomInputField
                label="메모"
                placeholder="마스터 이름을 입력해주세요."
                direction="row"
              />
              <Divider />
            </ModalLayout>
          </CustomModal>
        )}

        {isAddBagModalOpen && (
          <CustomModal
            title="BAG에 같은 타입 담기"
            onSuccess={() => {
              setIsAddBagModalOpen(false);
            }}
            isOpen={isAddBagModalOpen}
            handleClose={() => {
              setIsAddBagModalOpen(false);
            }}
            hasFooter
          >
            <ModalLayout>
              <CustomInputField
                label="접수 시작 날짜"
                placeholder="마스터 번호를 입력해주세요."
              />
              <CustomInputField
                label="접수 종료 날짜"
                placeholder="마스터 번호를 입력해주세요."
              />
              <FormControlLabel
                value="end"
                control={<Checkbox />}
                label="배송 처리 된 건만 담기"
                labelPlacement="end"
              />
              <Divider />
            </ModalLayout>
          </CustomModal>
        )}

        {isChangeBagNameModalOpen && (
          <CustomModal
            title="BAG 이름 변경"
            onSuccess={() => {
              setIsChangeBagNameModalOpen(false);
            }}
            isOpen={isChangeBagNameModalOpen}
            handleClose={() => {
              setIsChangeBagNameModalOpen(false);
            }}
            hasFooter
          >
            <ModalLayout>
              <CustomInputField
                label="BAG 이름"
                placeholder="마스터 번호를 입력해주세요."
              />
              <Divider />
            </ModalLayout>
          </CustomModal>
        )}

        {openModal && (
          <BagManagementModal
            additionalFormDetails={additionalFormDetails}
            selected={selected}
            onClose={handleClose}
            isOpen={openModal}
            onSuccess={handleSuccess}
            {...hocProps}
          />
        )}
        {selectedBag && (
          <BagDrawer
            selectedBag={selectedBag}
            isOpen={!!selectedBag}
            onClose={() => {
              setSelectedBag(null);
            }}
          />
        )}

        {confirmModal && (
          <ConfirmModal
            isLoading={isLoading}
            isOpen={confirmModal}
            onClose={() => handleClose("confirm")}
            onConfirm={handleDelete}
            title={"BAG 삭제"}
            description={"Master에서 해당 Bag을 삭제 하시겠습니까?"}
          />
        )}
      </>
    ) : (
      <div style={{ height: "90vh" }}>
        <Loader />
      </div>
    );
  }
);

const ModalLayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  max-height: 70vh;
  overflow-y: auto;
  overflow-x: hidden;
`;

const ModalBodyText = styled(Typography)`
  font-family: "Spoqa Han Sans Neo";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  /* identical to box height */

  text-align: center;

  color: #3e3e3e;
`;
