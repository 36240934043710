import React, { useState } from "react";
import CreateHOC from "../../../SharedComponents/CreateHOC";
import UpdateHOC from "../../../SharedComponents/UpdateHOC";

import { ClientCreateShippingContainer } from "./SubComponents/ClientCreateShippingContainer";

export const ClientCreateShipping = (props) => {
  const [editMode, setEditMode] = useState(false);

  return React.createElement(editMode ? UpdateHOC : CreateHOC, {
    _resource: "RequestShipping",
    ...props,
    setEditMode,
    editMode,
    children: ClientCreateShippingContainer,
  });
};
