import * as React from "react";
import Popover from "@mui/material/Popover";
import { Button, Typography } from "@mui/material";
import { FilterList } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  btnStyle: {
    padding: "6.5px !important",
    backgroundColor: "#5B8FF7 !important",
  },
  btnStyleOutlined: {
    padding: "6.5px !important",
    border: "1px solid #5B8FF7 !important",
  },
  labelStyle: {
    fontFamily: "Spoqa Han Sans Neo",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: 12,
    lineHeight: 17,
    color: "white",
  },
  labelStyleBlue: {
    fontFamily: "Spoqa Han Sans Neo",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: 12,
    lineHeight: 17,
    color: "#5B8FF7",
  },
});

export default function CustomPopover({
  children: Children,
  label = "필터",
  variant = "outlined",
  fullWidth = true,
  outlined,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const classes = useStyles();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      <Button
        variant={variant}
        onClick={handleClick}
        fullWidth={fullWidth}
        disableElevation
        style={{ padding: "7px 14" }}
        sx={{borderRadius:"10px"}}
        startIcon={
          <FilterList sx={{ color: outlined ? "#5B8FF7" : "white" }} />
        }
        className={outlined ? classes.btnStyleOutlined : classes.btnStyle}
      >
        <Typography
          className={outlined ? classes.labelStyleBlue : classes.labelStyle}
        >
          {label}
        </Typography>
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Children close={handleClose} />
      </Popover>
    </div>
  );
}
