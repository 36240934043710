import { Grid, Stack, Box, Divider, Checkbox } from "@mui/material";
import React, { useState, useEffect, useCallback } from "react";
import ConfirmModal from "../../../modals/ConfirmModal";
import CustomButton from "../../../components/CustomButton";
import CustomCard from "../../../components/CustomCard";
import CustomPopover from "../../../components/CustomPopover";
import { Form, Formik } from "formik";
import Moment from "moment";
import { handleOtherSearch } from "../../../utils/utils";
import DateRangePicker from "../../../components/DateRangePicker";
import { onDropDownSearchChange } from "../../../utils/onDropDownSearchChange";
import DropdownAndSearchInput from "../../../components/DropdownAndSearchInput2";
import TableContainer from "../../../components/TableContainer";
import ListHOC from "../../../SharedComponents/ListHOC";
import CustomSelect from "../../../components/CustomSelect";
import WaybillModal from "../../../modals/WaybillModal";
import Toaster from "../../../utils/Toaster";
import { gql } from "@apollo/client/core";
import { APIClient } from "../../../utils/apiGraphql";
import { useDelete } from "../../../utils/utils";
import { generateSearchData } from "../../../utils/utils";

export const WaybillList = (props) => {
	return (
		<ListHOC
			_resource={"Waybill"}
			showSort={{ sortOrder: "desc", sortField: "id" }}
		>
			<WaybillListContainer />
		</ListHOC>
	);
};

const WaybillListContainer = ({
	page,
	countTotal,
	perPage,
	handleSetPerPage,
	handlePageChange,
	handleSearch,
	...hocProps
}) => {
	const [filterQuery, setFilterQuery] = useState(null);
	const [checkedList, setCheckedList] = useState([]);
	const [openDeleteConfirmModal, setOpenDeleteConfirmModal] = useState(null);
	const [openModal, setOpenModal] = useState(false);
	const [data, setData] = useState([]);
	const [filteredData, setFilteredData] = useState([]);
	const { isLoading, deleteOne } = useDelete();
	const [filterDate, setFilterDate] = useState(null);
	const [filterNew, setFilterNew] = useState(null);

	const doSearch = useCallback(() => {
		generateSearchData(handleSearch, filterQuery);
	}, [filterQuery]);

	useEffect(() => {
		doSearch();
	}, [filterQuery]);

	React.useEffect(() => {
		if (hocProps.data?.length !== 0) {
			setData(hocProps.data);
			setFilteredData(hocProps.data);
		}
	}, [hocProps.data]);

	const handleClose = () => {
		setOpenModal(false);
	};
	const handleClose2 = () => {
		setOpenDeleteConfirmModal(false);
	};

	const handleCheck = ({ target }) => {
		const { value, checked } = target;
		setCheckedList((prevState) => {
			const temp = [...prevState];
			if (checked) {
				temp.push(+value);
				return temp;
			} else {
				return temp.filter((res) => res !== +value);
			}
		});
	};

	const handleDeleteSuccess = () => {
		setCheckedList([]);
		setTimeout(() => {
			window.location.reload();
		}, 2000);
	};

	const handleDelete = async () => {
		if (checkedList.length === 0) {
			return Toaster(
				"error",
				"운송장을 선택해주세요."
			);
		}

		const deleteMutation = gql(`
    mutation deleteWaybill($id: WayBillDeleteInput!){
			deleteWaybill(id:$id)
			{
				id
			}
		}`);

		const deleteManyMutation = gql(`
		mutation
		deleteManyWaybill($ids: WayBillDeleteManyInput!){
			deleteManyWaybill(ids:$ids)
			{
				id
			}
		}
		`);

		try {
			if (checkedList.length === 1) {
				const res = await APIClient.mutate({
					mutation: deleteMutation,
					variables: {
						id: {
							id: checkedList[0],
						},
					},
				});
				Toaster("success", "삭제 완료되었습니다");
				handleDeleteSuccess();
			} else if (checkedList.length > 1) {
				const res = await APIClient.mutate({
					mutation: deleteManyMutation,
					variables: {
						ids: {
							ids: checkedList,
						},
					},
				});
				Toaster("success", "삭제 완료되었습니다");
				handleDeleteSuccess();
			}
		} catch (e) {
			Toaster("error", e.message);
		}
	};

	// useEffect(() => {
	// 	if (filterQuery) {
	// 		const filtered = data.filter((item) =>
	// 			item.trackingNumber.includes(filterQuery?.search)
	// 		);
	// 		setFilteredData(filtered);
	// 	}
	// }, [filterQuery]);

	useEffect(() => {
		if (filterDate) {
			const date = filterDate?.createdAt.split(" ");
			const filtered = data.filter((item) => {
				const formated = Moment(item.createdAt).format("YYYY-MM-DD");
				const formatedUpper = Moment(date[2]).format("YYYY-MM-DD");
				const formatedLower = Moment(date[0]).format("YYYY-MM-DD");
				if (Moment(formated).isBetween(formatedLower, formatedUpper)) {
					return item;
				}
			});
			setFilteredData(filtered);
		}
	}, [filterDate]);

	return (
		<CustomCard>
			<Stack spacing={"35px"}>
				<Box>
					<Grid
						container
						alignItems={"center"}
						justifyContent={"space-between"}
						spacing={2}
						pb="0"
						pt="20px"
					>
						<Grid item container xs={4} alignItems={"center"} spacing="10px">
							<Grid item xs={3}>
								<CustomPopover>
									{({ close }) => {
										return (
											<Formik
												enableReinitialize
												initialValues={
													filterQuery || {
														createdAt: `${Moment().format(
															"YYYY-MM-DD"
														)} - ${Moment().format("YYYY-MM-DD")}`,
													}
												}
												onSubmit={(values) => {
													handleOtherSearch(values, setFilterQuery);
													// setFilterDate(values);
												}}
											>
												{({
													values,
													handleSubmit,
													setFieldValue,
													resetForm,
												}) => {
													return (
														<Form onSubmit={handleSubmit}>
															<Stack spacing={"20px"} width={370} p="20px">
																<DateRangePicker
																	name={"createdAt"}
																	value={values?.["createdAt"]}
																	setFieldValue={setFieldValue}
																	labelWidth="20%"
																	labelWeight={700}
																	label="등록일"
																/>

																<Divider
																	style={{ margin: 0, marginBottom: "20px" }}
																/>

																<div
																	style={{
																		display: "flex",
																		justifyContent: "flex-end",
																		gap: "20px",
																		marginTop: 0,
																	}}
																>
																	<CustomButton
																		onClick={close}
																		styles={{
																			backgroundColor: "#F8F8FA",
																			padding: "5px",
																			color: "#000",
																			width: "auto",
																		}}
																	>
																		취소
																	</CustomButton>
																	<CustomButton
																		styles={{ width: "auto", padding: "5px" }}
																		variant={"outlined"}
																		onClick={() => {
																			setFilterQuery(null);
																		}}
																	>
																		필터 초기화
																	</CustomButton>
																	<CustomButton
																		type={"submit"}
																		onClick={(handleSubmit, close)}
																		styles={{ padding: "5px", width: "auto" }}
																	>
																		적용
																	</CustomButton>
																</div>
															</Stack>
														</Form>
													);
												}}
											</Formik>
										);
									}}
								</CustomPopover>
							</Grid>
							<CustomButton
								styles={{
									width: "100px",
									padding: "8px",
									margin: "10px 0 0 10px",
								}}
								bg="#5B8FF7"
								onClick={() => setOpenModal(true)}
							>
								사용현황
							</CustomButton>

							<CustomButton
								styles={{
									width: "100px",
									padding: "8px",
									margin: "10px 0 0 10px",
								}}
								bg={checkedList.length === 0 ? "#eeeeed" : "#F9574D"}
								onClick={() => setOpenDeleteConfirmModal(true)}
								disabled={checkedList.length === 0}
							>
								삭제
							</CustomButton>
						</Grid>

						<Grid
							item
							style={{
								justifyContent: "flex-end",
								display: "flex",
								gap: "10px",
							}}
							padding="0"
							xs={7}
						>
							<DropdownAndSearchInput
								onChange={(data) =>
									onDropDownSearchChange(data, setFilterQuery)
								}
								value={filterQuery}
								defaultValue={{ key: "trackingNumber", value: "" }}
								dropDownValues={[
									{ value: "trackingNumber", title: "운송장번호" },
								]}
								inputFieldPlaceHolder={"운송장번호를  입력해주세요."}
							/>
							<Grid item xs={4}>
								<CustomSelect
									value={String(perPage)}
									label=""
									onChange={({ target }) => {
										handleSetPerPage(target.value);
									}}
									options={["10", "20", "30", "40", "50", "100"]}
								></CustomSelect>
							</Grid>
						</Grid>
					</Grid>
				</Box>
				{/* {console.log(filteredData)} */}
				<div style={{ position: "relative" }}>
					<TableContainer
						{...hocProps}
						headers={[
							<Checkbox
								checked={filteredData.every((res, index) =>
									checkedList.includes(res.id)
								)}
								onChange={({ target }) => {
									if (target.checked) {
										setCheckedList(filteredData.map((res, index) => res.id));
									} else {
										setCheckedList([]);
									}
								}}

								// checked={rows.every((res, index) =>
								//   checkedList.includes(index)
								// )}
								// onChange={({ target }) => {
								//   if (target.checked) {
								//     setCheckedList(rows.map((res, index) => res.index));
								//   } else {
								//     setCheckedList([]);
								//   }
								// }}
							/>,
							"No.",
							"운송장번호",
							"등록날짜",
							"배송 서비스 타입",
							"완료여부",
						]}
						data={filteredData?.map((item, index) => {
							return [
								<Checkbox
									key={index}
									onChange={handleCheck}
									value={item.id}
									checked={checkedList.some((res) => res === item.id)}
								/>,
								countTotal - ((page - 1) * 10 + index),
								item.trackingNumber,
								Moment(item.createdAt).format("DD-MM-YYYY"),
								item.deliveryType?.shippingType,
								item.status === "pending" ? "사용전" : "사용완료",
							];
						})}
					/>
				</div>
			</Stack>
			{checkedList.length !== 0 && openDeleteConfirmModal && (
				<ConfirmModal
					// isLoading={isLoading}
					isOpen={openDeleteConfirmModal}
					onClose={handleClose2}
					// onConfirm={handleDelete}
					title={"운송장 삭제"}
					description={"해당 운송장을 삭제 하시겠습니까?"}
					onConfirm={handleDelete}
				/>
			)}
			{openModal && <WaybillModal isOpen={openModal} onClose={handleClose} />}
		</CustomCard>
	);
};
