import { DateRange } from "react-date-range";
import { useEffect, useState } from "react";
import CustomInput from "./CustomInput";
import { Popover } from "@mui/material";
import Moment from "moment";

function DateRangePicker({
	name,
	customLabel = false,
	value,
	showLabel = true,
	setFieldValue,
	iconStart = null,
	iconEnd = null,
	label = "",
	selectValue = "",
	dropDownList = [],
	handleChange,
	padding,
	DateSetter,
	...prop
}) {
	const [el, setEl] = useState(null);
	const [list, setList] = useState(dropDownList);
	const [state, setState] = useState([
		{
			startDate: new Date(),
			endDate: null,
			key: "selection",
		},
	]);

	const handleClick = ({ currentTarget }) => {
		setEl(currentTarget);
	};

	const handleClose = () => {
		setEl(null);
	};

	useEffect(() => {
		if (value) {
			const rangeValue = getValue(value);
			setState([rangeValue]);
		}
	}, [value]);

	const getValue = (value) => {
		const [startDate, endDate] = value.split(" - ");

		return {
			startDate: startDate ? Moment(startDate).startOf("day").toDate() : null,
			endDate: endDate ? Moment(endDate).startOf("day").toDate() : null,
			key: "selection",
		};
	};

	const getDateFormat = (date) => {
		return Moment(date).format("YYYY-MM-DD");
	};

	const handleValueChange = (value) => {
		const { startDate, endDate, key } = value;
		// DateSetter();/
		if (key === "selection") {
			if (setFieldValue) {
				setFieldValue(
					name,
					`${getDateFormat(startDate)} - ${getDateFormat(endDate)}`
				);
				// DateSetter(true);
			} else {
				if (handleChange) {
					handleChange({
						[name]: `${getDateFormat(startDate)} - ${getDateFormat(endDate)}`,
					});
				}
			}
		}
	};

	return (
		<>
			<CustomInput
				customLabel={customLabel}
				onClick={handleClick}
				label={showLabel ? (label ? label : "접수일") : ""}
				labelColor=""
				value={value}
				placeholder={"From (YYYY-MM-DD) - TO (YYYY-MM-DD)"}
				iconStart={iconStart}
				iconEnd={iconEnd}
				labelWeight={700}
				selectValue={selectValue}
				dropDownList={list}
				padding={padding}
			/>

			<Popover
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left",
				}}
				open={!!el}
				anchorEl={el}
				onClose={handleClose}
			>
				<DateRange
					editableDateInputs={true}
					onChange={(item) => {
						handleValueChange(item.selection);
					}}
					moveRangeOnFirstSelection={false}
					ranges={state}
				/>
			</Popover>
		</>
	);
}

export default DateRangePicker;
