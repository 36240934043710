import {
	Checkbox,
	Divider,
	Grid,
	Link,
	MenuItem,
	Pagination,
	Stack,
} from "@mui/material";
import React, { useState, useEffect, useCallback } from "react";
import Moment from "moment";
import { Form, Formik } from "formik";
import CustomButton from "../../components/CustomButton";
import CustomInput, {
	SelectBox,
	TextFieldBox,
} from "../../components/CustomInput";
import TrackingModal from "../../modals/trackingModal";
import CustomPopover from "../../components/CustomPopover";
import DropdownAndSearchInput from "../../components/DropdownAndSearchInput2";
import { onDropDownSearchChange } from "../../utils/onDropDownSearchChange";
import ShippingFilter from "../../components/shippingFilter";
import ListHOC from "../../SharedComponents/ListHOC";
import TableContainer from "../../components/TableContainer";
import ExportDeclarationModal from "../../modals/ExportDeclarationModal";
import ExportConfirmModal from "../../modals/ExportConfirmModal";
import ExportErrorModal from "../../modals/ExportErrorModal";
import { generateSearchData } from "../../utils/utils";
import CustomSelect from "../../components/CustomSelect";
import { gql } from "@apollo/client";
import { APIClient } from "../../utils/apiGraphql";
import Toaster from "../../utils/Toaster";

export function ExportDeclaration(props) {
	return (
		<ListHOC _resource={"RequestShipping"} {...props}>
			<ClientExportDeclaration />
		</ListHOC>
	);
}
export default function ClientExportDeclaration({
	data: _Data,
	page,
	count,
	countTotal,
	perPage,
	handleSetPerPage,
	handlePageChange,
	handleSearch,
	isLoading,
	// setActiveScreen,
	filter,
	screens,
	...hocProps
}) {
	const [sortingOrder, setSortingOrder] = useState("ascending");
	// const [page, setPage] = React.useState(2);
	const [rowsPerPage, setRowsPerPage] = React.useState(4);
	const [openDialog, setOpenDialog] = useState(false);
	const [openDialog2, setOpenDialog2] = useState(false);
	const [checkedList, setCheckedList] = useState([]);
	const [openExport, setOpenExport] = useState(false);
	const [activeScreen, setActiveScreen] = useState(false);
	const [filterQuery, setFilterQuery] = useState(null);
	const [declaration, setDeclaration] = useState(null);
	const [api, setApi] = useState(false);
	const [confirmModal, setConfirmModal] = useState(false);
	const [data, setData] = useState([]);
	const [errorModal, setErrorModal] = useState(false);
	const [row, setRow] = useState(null);

	const doSearch = useCallback(() => {
		if (filterQuery?.orderNumber) {
			const orderno1 = data.filter((item) => {
				if (
					item.productInformation?.orderNo1.includes(filterQuery["orderNumber"])
				) {
					return item.productInformation?.orderNo1;
				} else return "";
			});
			const orderno2 = data.filter((item) => {
				if (
					item.productInformation?.orderNo2.includes(filterQuery["orderNumber"])
				) {
					return item.productInformation?.orderNo2;
				} else return "";
			});
			console.log(filterQuery["orderNumber"], " ", orderno1, " ", orderno2);

			if (orderno1.length === 0 && orderno2.length === 0) {
				const filterData = {
					productInformation123orderNo1: filterQuery["orderNumber"],
					productInformation123orderNo2: filterQuery["orderNumber"],
				};
				generateSearchData(handleSearch, filterData);
			} else {
				const filterData = {
					productInformation123orderNo1:
						orderno1 && orderno1[0]?.productInformation?.orderNo1,
					productInformation123orderNo2:
						orderno2 && orderno2[0]?.productInformation?.orderNo2,
				};
				generateSearchData(handleSearch, filterData);
			}
		} else generateSearchData(handleSearch, filterQuery);
	}, [filterQuery]);

	useEffect(() => {
		doSearch();
	}, [filterQuery]);

	useEffect(() => {
		if (data) {
			setData(_Data);
		}
	}, [_Data]);

	const changeSorting = () => {
		if (sortingOrder === "ascending") {
			setData((prevData) => prevData.sort((a, b) => b[2] - a[2]));
			setSortingOrder("descending");
		} else {
			setData((prevData) => prevData.sort((a, b) => a[2] - b[2]));
			setSortingOrder("ascending");
		}
	};

	const handleCheck = ({ target }) => {
		const { value, checked } = target;
		setCheckedList((prevState) => {
			const temp = [...prevState];
			if (checked) {
				temp.push(+value);
				return temp;
			} else {
				return temp.filter((res) => res !== +value);
			}
		});
	};

	const handleConfirmClose = () => setConfirmModal(false);
	const handleActiveClose = () => setActiveScreen(false);
	const handleErrorClose = () => setErrorModal(false);
	const handleExportClose = () => setOpenExport(false);

	// console.log({ filterQuery });

	const handleExportCheck = async (id) => {
		// console.log("id", id);
		const checkExportDeclaration = gql(`mutation 
		requestExportDeclaration($requestShippingId:Float!	)
			{
			requestExportDeclaration(requestShippingId:$requestShippingId)
		}`);

		try {
			const res = await APIClient.mutate({
				mutation: checkExportDeclaration,
				variables: {
					requestShippingId: id,
				},
			});
			// console.log("res", res);

			if (res?.data?.requestExportDeclaration) {
				Toaster("sucess", "수출신고가 성공적으로 신청 완료되었습니다! ");
				setOpenExport(false);
			} else {
				setOpenExport(false);
				setErrorModal(true);
			}
		} catch (e) {
			setOpenExport(true);
			console.log(e);
		}
	};

	const handleExportRequest = (e) => {
		setFilterQuery({ ...filterQuery, [e.target.name]: e.target.value });
	};

	const handleRequestCheckExport = async (id) => {
		const checkExportDeclaration = gql(`query($requestShippingId:Float!){
			checkExportDeclaration(requestShippingId:$requestShippingId)
		}`);

		try {
			const res = await APIClient.mutate({
				mutation: checkExportDeclaration,
				variables: {
					requestShippingId: id,
				},
			});

			setConfirmModal(true);
			setDeclaration(res.data);
		} catch (e) {
			console.log(e);
		}
	};

	return (
		<Stack spacing={"35px"}>
			<Grid container justifyContent={"space-between"}>
				<Grid item xs={12}>
					<Grid
						container
						style={{
							display: "flex",
							justifyContent: "space-between",
						}}
					>
						<Grid item xs={10} style={{ margin: "auto 10px" }}>
							<Formik
								enableReinitialize
								initialValues={
									filterQuery || {
										createdAt: `${Moment().format(
											"YYYY-MM-DD"
										)} - ${Moment().format("YYYY-MM-DD")}`,
									}
								}
								onSubmit={(values) => {}}
							>
								{({ values, handleSubmit, setFieldValue, resetForm }) => {
									return (
										<Form onSubmit={handleSubmit}>
											<Grid container columnGap={2}>
												<Grid xs={1.8}>
													<SelectBox
														defaultValue={"default"}
														name={"exportDeclarationRequest"}
														onChange={handleExportRequest}
													>
														<MenuItem value="default" disabled hidden>
															수출신고 여부
														</MenuItem>
														<MenuItem value={false}>수출신고전</MenuItem>
														<MenuItem value={true}>신청완료</MenuItem>
													</SelectBox>
												</Grid>
												<Grid xs={8}>
													<DropdownAndSearchInput
														onChange={(data) =>
															onDropDownSearchChange(data, setFilterQuery)
														}
														value={filterQuery}
														defaultValue={{
															key: "선택",
															value: "",
														}}
														dropDownValues={[
															{
																value: "선택",
																title: "선택",
															},
															{
																value: "trackingNumber",
																title: "접수번호",
															},
															{
																value: "orderNumber",
																title: "주문번호",
															},
														]}
														inputFieldPlaceHolder={"검색어를 입력해주세요."}
													/>
												</Grid>
											</Grid>
										</Form>
									);
								}}
							</Formik>
						</Grid>
						<Grid item xs={1.5}>
							<CustomSelect
								value={String(perPage)}
								label=""
								onChange={({ target }) => {
									handleSetPerPage(target.value);
								}}
								options={["10", "20", "30", "40", "50", "100"]}
							></CustomSelect>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<div style={{ position: "relative" }}>
				<TableContainer
					{...{ isLoading: isLoading, requestCompleted: true }}
					flipped={"Container Flipped"}
					contentFlip={"Content"}
					headers={[
						"특이사항(메모)",
						<Checkbox
							checked={data.every((res) => checkedList.includes(res.id))}
							onChange={({ target }) => {
								if (target.checked) {
									setCheckedList(data.map((res) => res.id));
								} else {
									setCheckedList([]);
								}
							}}
						/>,

						"No.",
						<div style={{ width: "100px" }}>배송 상태</div>,
						"배송 추적",
						"수출신고",
						"운송장 출력",
						"팩킹리스트 출력",
						<div style={{ width: "100px" }}>타입</div>,
						<div style={{ width: "150px" }}>고객사 이름</div>,

						"배송국가",
						<div style={{ width: "120px" }}>배송종류</div>,
						<div style={{ width: "90px" }}>접수일</div>,
						<div style={{ width: "90px" }}>출고일</div>,
						"접수번호",
						"주문번호1",
						"주문번호2",
						"배송번호",
						"출발국가 택배번호",
						"배송비",
						"택배 추가요금",
						<div style={{ width: "100px" }}>화폐</div>,

						"가로(Cm)",
						"세로(Cm)",
						"높이(Cm)",
						"Box 수량",
						"실제무게(KG)",
						"부피무게(KG)",
						"적용무게(KG)",
						"주민번호/통관번호/CPF",
						<div style={{ width: "100px" }}>IOSS</div>,
						<div style={{ width: "100px" }}>EORI</div>,
						<div style={{ width: "100px" }}>VOEC</div>,
						"상품 바코드",
						<div style={{ width: "150px" }}>상품목록</div>,

						"상품수량",
						"총 상품금액",
						"화폐단위",
						<div style={{ width: "150px" }}>발송인 이름</div>,

						"발송인 전화번호",
						"발송인 주소",
						"수취인 이름",
						"수취인 이름 (YOMIGANA)",
						"수취인 휴대폰 번호",
						"수취인 전화번호",
						"수취인 이메일",
						"수취인 우편번호",
						<div style={{ width: "150px" }}>Colony</div>,
						<div style={{ width: "250px" }}>수취인 주소1</div>,
						<div style={{ width: "250px" }}>수취인 주소2</div>,

						"배송메세지",
						"HSCODE",
						"상품종류",
						"통관구분",
						"수출신고 유형",
						"판매 site",
						"사용자 데이터 1",
						"사용자 데이터 2",
						"사용자 데이터 3",
						// "행방조사",
						// "수정 / 삭제",
					]}
					data={data.map((item, index) => [
						item.miscellaneousInformation?.coupon &&
							`고객사 쿠폰 적용(${item.miscellaneousInformation.coupon})`,
						<Checkbox
							onChange={handleCheck}
							value={item.id}
							checked={checkedList.some((res) => res === item.id)}
						/>,
						countTotal - ((page - 1) * 10 + index),
						item.status === "waiting_warehousing"
							? "입고대기"
							: item.status === "warehousing_completed"
							? "입고완료"
							: item.status === "waiting_delivery"
							? "출고대기"
							: item.status === "started_delivery"
							? "출고완료"
							: item.status === "completed_delivery" && "배송완료",
						<CustomButton
							onClick={() => {
								setActiveScreen(true);
							}}
							styles={{
								backgroundColor: "rgb(91, 143, 247)",
								color: "#FFFFFF",
								borderRadius: 4,
								height: "40px",
								width: "80px",
							}}
						>
							배송추적
						</CustomButton>,
						item.exportDeclarationRequest ? (
							<CustomButton
								onClick={() => {
									// setDeclaration({ id: item?.id, state: true });
									// setConfirmModal(true);
									handleRequestCheckExport(item.id);
								}}
								styles={{
									backgroundColor: "#ef3939",
									color: "#FFFFFF",
									borderRadius: 4,
									height: "40px",
									width: "80px",
								}}
							>
								신청완료
							</CustomButton>
						) : (
							<CustomButton
								onClick={() => {
									setOpenExport(true);
									setApi(true);
									setRow(item);
								}}
								styles={{
									backgroundColor: "rgb(91, 143, 247)",
									color: "#FFFFFF",
									borderRadius: 4,
									height: "40px",
									width: "80px",
								}}
							>
								수출신고
							</CustomButton>
						),

						item.waybillPrintStatus ? "O" : "X",
						item.packingListPrintStatus ? "O" : "X",
						"일반",
						item.user?.company_name_kr,
						item.payeeInformation?.shippingCountry.code,
						item.shippingType?.shippingType,
						item.createdAt ? Moment(item.createdAt).format("YYYY-MM-DD") : "",
						item.processReleaseDate &&
							Moment(item.processReleaseDate).format("YYYY-MM-DD"),
						item.trackingNumber,
						item.productInformation?.orderNo1,
						item.productInformation?.orderNo2,
						item.shippingNumber,
						item.productInformation?.departureCountryCourierNumber,
						item.deliveryFee,
						item.additionalFee,
						"KRW",
						item.miscellaneousInformation?.Horizontal,
						item.miscellaneousInformation?.portrait,
						item.miscellaneousInformation?.height,
						item.miscellaneousInformation?.boxQuantity,
						item.miscellaneousInformation?.actualWeight,
						item.miscellaneousInformation?.bulkWeight,
						item.miscellaneousInformation?.applicableWeight,
						item.payeeInformation?.ssn,
						item.payeeInformation?.euioss,
						item.payeeInformation?.ukeori,
						item.payeeInformation?.ssn,
						item.productInformation?.productInformation?.map(
							(subItem, subIndex) => {
								return (
									<div>
										<p>
											{subItem.barcode}{" "}
											{subItem.barcode !== "" &&
												index <
													item.productInformation?.productInformation.length -
														1 &&
												","}
										</p>
									</div>
								);
							}
						),
						item.productInformation?.productInformation?.map(
							(subItem, subIndex) => {
								return (
									<div>
										<p>
											{subItem.productName}{" "}
											{subItem.productName !== "" &&
												subIndex <
													item.productInformation.productInformation.length -
														1 &&
												","}
										</p>
									</div>
								);
							}
						),
						item?.productInformation?.productInformation?.reduce(
							(previousValue, currentValue) =>
								previousValue + currentValue.quantity,
							0
						),
						item?.productInformation?.totalPriceAmount,
						item.payeeInformation?.shippingCountry.monetaryUnit,
						item.senderInformation?.name,
						item.senderInformation?.phoneNumber,
						item.senderInformation?.address,
						item.payeeInformation?.name,
						"X",
						item.payeeInformation?.phoneNumber1,
						item.payeeInformation?.phoneNumber2,
						item.payeeInformation?.email,
						item.payeeInformation?.zipCode,
						item.payeeInformation?.mexicoColony,
						item.payeeInformation?.streetAddress1,
						item.payeeInformation?.streetAddress2,
						item.miscellaneousInformation?.shippingMessage,
						item.productInformation?.productInformation?.map(
							(subItem, subIndex) => {
								return (
									<>
										{subItem.hscode}{" "}
										{subItem.hscode !== "" &&
											subIndex <
												item.productInformation?.productInformation?.length -
													1 &&
											","}
									</>
								);
							}
						),
						item.miscellaneousInformation?.productType,
						"1",
						item.miscellaneousInformation?.exportTypeDeclaration,
						item.productInformation?.productInformation?.map(
							(subItem, subIndex) => {
								return (
									<div>
										<p>
											{subItem.purchaseURL}{" "}
											{subItem.purchaseURL !== "" &&
												subIndex <
													item.productInformation?.productInformation?.length -
														1 &&
												","}
										</p>
									</div>
								);
							}
						),
						item.miscellaneousInformation?.userData,
						item.miscellaneousInformation?.userData2,
						item.miscellaneousInformation?.userData3,
					])}
				/>
			</div>
			<ExportDeclarationModal
				isOpen={openExport}
				api={api}
				onClose={handleExportClose}
				onConfirm={handleExportCheck}
				row={row}
			/>
			<ExportConfirmModal
				isOpen={confirmModal}
				onClose={handleConfirmClose}
				declaration={declaration}
			/>
			<ExportErrorModal isOpen={errorModal} onClose={handleErrorClose} />
			<TrackingModal isOpen={activeScreen} onClose={handleActiveClose} />
		</Stack>
	);
}
