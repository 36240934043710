import styled from "styled-components";
import CustomInput from "../CustomInput";
import { useAtom } from "jotai";
import { messageAtom } from "./atom";
import { Typography } from "@mui/material";

const CustomTypography = styled(Typography).withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) => {
    return !["color"].includes(prop) && defaultValidatorFn(prop);
  },
})`
  white-space: pre-line;
  margin-left: 0.5rem;
  color: ${({ color }) =>
    color === "error"
      ? "rgb(188, 70, 71)"
      : color === "success"
      ? "rgb(51,126,169)"
      : "rgb(0,0,0)"};
`;

// input={
//   !sampoongAmountCheck ? (
//     <CustomTypography ml={1} color={message.type}>
//       {message.text}
//     </CustomTypography>
//   ) : isSmall && sampoongAmountCheck && SRDetails ? (
//     <CustomTypography ml={1} color={"error"}>
//       상품 최대 금액을 초과하였습니다.
//     </CustomTypography>
//   ) : (
//     <CustomTypography ml={1} color={message.type}>
//       {message.text}
//     </CustomTypography>
//   )
// }
const ResultMessage = () => {
  const [message] = useAtom(messageAtom);
  return (
    <div
      style={{
        borderRadius: "10px",
        overflow: "hidden",
        border: "1px solid #E5E5E5",
      }}
    >
      <CustomInput
        label="접수 메시지"
        input={
          <CustomTypography ml={1} color={message.type}>
            {message.text}
          </CustomTypography>
        }
        labelWidth="22%"
        padding="14px 20px 10px 20px"
        style={{ margin: "10px", width: "100%" }}
      />
    </div>
  );
};

export default ResultMessage;
