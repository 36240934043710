import { Button } from "@mui/material";
import React from "react";

export default function CustomButton({
	children,
	onClick = () => {},
	styles = {},
	type = "button",
	variant = "contained",
	width,
	padding = "5px",
	bg,
	...rest
}) {
	return (
		<Button
			disableElevation
			style={{
				backgroundColor: bg ? bg : "primary",
				boxShadow: "0",
				width: width || "100%",
				padding: padding ? padding : "5px",
				borderRadius: 10,
				...styles,
			}}
			{...rest}
			type={type}
			variant={variant}
			onClick={onClick}
		>
			{children}
		</Button>
	);
}
