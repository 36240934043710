import {
	Checkbox,
	Divider,
	Grid,
	Link,
	MenuItem,
	Pagination,
	Stack,
} from "@mui/material";
import React, { useState } from "react";
import Moment from "moment";
import CustomButton from "../../components/CustomButton";
import CustomInput, {
	SelectBox,
	TextFieldBox,
} from "../../components/CustomInput";
import CustomPopover from "../../components/CustomPopover";
import TableContainer from "../../components/TableContainer";
import DateRangePicker from "../../components/DateRangePicker";
import CustomSelect from "../../components/CustomSelect";
import { handleOtherSearch } from "../../utils/utils";
import { Form, Formik } from "formik";
import ListHOC from "../../SharedComponents/ListHOC";
import { useEffect, useCallback } from "react";
import { generateSearchData } from "../../utils/utils";

export default function ClientHistoy() {
	return (
		<ListHOC _resource={"Transaction"}>
			<ClientHistoy1 />
		</ListHOC>
	);
}

function ClientHistoy1({
	countTotal,
	page,
	perPage,
	handleSetPerPage,
	handleSearch,
	...hocProps
}) {
	const [page2, setPage2] = React.useState(2);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);
	const [filterQuery, setFilterQuery] = useState(null);
	const [dateFilter, setDateFilter] = useState(null);
	const [data, setData] = useState([]);
	const [filteredData, setFilteredData] = useState([]);
	const [dropDownList, setDropDownList] = useState([]);
	const list = ["입금일"];

	useEffect(() => {
		if (hocProps.data?.length !== 0) {
			setData(hocProps.data);
			setFilteredData(hocProps.data);
		}
	}, [hocProps.data]);

	const handleChangePage = (event, newPage) => {
		setPage2(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage2(0);
	};

	// useEffect(() => {
	// 	if (dateFilter) {
	// 		const date = dateFilter?.createdAt.split(" ");
	// 		const filtered = data.filter((item) => {
	// 			const formated = Moment(item.createdAt).format("YYYY-MM-DD");
	// 			const formatedUpper = Moment(date[2]).format("YYYY-MM-DD");
	// 			const formatedLower = Moment(date[0]).format("YYYY-MM-DD");
	// 			if (Moment(formated).isBetween(formatedLower, formatedUpper)) {
	// 				return item;
	// 			}
	// 		});
	// 		setFilteredData(filtered);
	// 	}
	// }, [dateFilter]);

	// console.log(
	// 	"type",
	// 	filteredData.map((item) => item.type)
	// );

	const doSearch = useCallback(() => {
		generateSearchData(handleSearch, filterQuery);
	}, [filterQuery]);

	useEffect(() => {
		doSearch();
	}, [filterQuery]);

	// const rangeChange = (value) => {
	// 	setFilterQuery({
	// 		createdAt: value?.createdAt,
	// 	});
	// };
	// console.log("filter", filterQuery);
	return (
		<Stack spacing={"35px"}>
			<Grid container justifyContent={"space-between"} alignItems={"center"}>
				{/* <Grid xs={2}></Grid> */}
				<Grid item xs={12} justifyContent={"flex-end"}>
					<Grid
						item
						container
						columnGap={1}
						xs={12}
						alignItems={"center"}
						style={{ justifyContent: "flex-end" }}
					>
						<Grid
							item
							xs={12}
							container
							alignItems={"center"}
							style={{ justifyContent: "flex-end" }}
							columnGap={1}
						>
							<Grid padding="0">
								<Formik
									enableReinitialize
									initialValues={
										filterQuery || {
											createdAt: `${Moment().format(
												"YYYY-MM-DD"
											)} - ${Moment().format("YYYY-MM-DD")}`,
										}
									}
									onSubmit={(values) => {
										handleOtherSearch(values, setFilterQuery);
									}}
								>
									{({ values, handleSubmit, setFieldValue, resetForm }) => {
										return (
											<Form onSubmit={handleSubmit}>
												<Grid container alignItems={"center"} columnGap={1}>
													<Grid xs={3.5}>
														<SelectBox defaultValue={"createdAt"}>
															<MenuItem value="createdAt">입금처리일</MenuItem>
														</SelectBox>
													</Grid>
													<Stack
														spacing={"20px"}
														sx={{ marginBottom: "1px" }}
														width={230}
													>
														<div>
															<DateRangePicker
																name={"createdAt"}
																value={values?.["createdAt"]}
																setFieldValue={setFieldValue}
																showLabel={false}
															/>
														</div>
													</Stack>
													<Grid item xs={1}>
														<div>
															<CustomButton
																bg="#5B8FF7"
																padding="7px"
																type="submit"

																// onClick={doSearch}
															>
																적용
															</CustomButton>
														</div>
													</Grid>
												</Grid>
											</Form>
										);
									}}
								</Formik>
							</Grid>

							<Grid
								item
								xs={1.5}
								alignItems={"end"}
								style={{ marginLeft: "10px", maxWidth: "200px" }}
							>
								<CustomSelect
									value={String(perPage)}
									label=""
									onChange={({ target }) => {
										handleSetPerPage(target.value);
									}}
									options={["10", "20", "30", "40", "50", "100"]}
								></CustomSelect>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<div style={{ position: "relative" }}>
				<TableContainer
					// {...{ isLoading: hocProps, requestCompleted: true }}
					{...hocProps}
					headers={[
						"No.",
						"업체명",
						"입금액",
						"입금 처리 날짜",
						"입금 내용",
						"메모",
					]}
					data={filteredData.map((item, index) => [
						countTotal - ((page - 1) * 10 + index),
						item.user.company_name_kr,
						item.amount === "0" ? (
							parseInt(item.amount).toLocaleString("en-us") + "원"
						) : item.amount > "0" ? (
							<div style={{ color: "blue" }}>
								{parseInt(item.amount).toLocaleString("en-us") + "원"}
							</div>
						) : (
							item.amount < "0" && (
								<div style={{ color: "red" }}>
									{" "}
									{parseInt(item.amount).toLocaleString("en-us") + "원"}
								</div>
							)
						),

						`${Moment(item.deposit_processing_date).format(
							"DD-MM-YYYY"
						)} ${Moment(item.createdAt).format("h:mm:ss a")}`,

						item.type === "deposit" && item.reason === "customer-fees"
							? "고객 요금 입금"
							: item.type === "deposit" && item.reason === "reward-amount"
							? "보상 금액 입금"
							: item.type === "deposit" && item.reason === "other"
							? "기타"
							: item.type === "withdraw" && item.reason === "customer-fees"
							? "기타 서비스 사용 요금"
							: item.type === "withdraw" && item.reason === "reward-amount"
							? "착오로 인한 입금"
							: item.type === "deduction" &&
							  item.reason === "delivery-fee-deduction"
							? "배송접수건 출고"
							: item.type === "withdraw" && item.reason === "other" && "기타",

						item.type === "deduction" &&
						item.reason === "delivery-fee-deduction"
							? item.shippingFeeDeductionHistory?.trackingNumber
							: item.memo,
					])}
				/>
			</div>
			<div
				style={{
					display: "flex",
					justifyContent: "center",
					marginTop: 30,
				}}
			></div>
		</Stack>
	);
}
