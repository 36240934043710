import {
  Box,
  Modal,
  Stack,
  Typography,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Divider,
} from "@mui/material";
import CustomCard from "../components/CustomCard";
import CustomButton from "../components/CustomButton";
import React, { useEffect, useState } from "react";
import { Formik, Form, FieldArray } from "formik";
import CreateHOC from "../SharedComponents/CreateHOC";
import { TextFieldBox } from "../components/CustomInput";
import * as Yup from "yup";
import UpdateHOC from "../SharedComponents/UpdateHOC";

function ShippingTypeModal({
  isOpen,
  onClose,
  apiTypes = [],
  selected = null,
  onSuccess = (data, reason) => {},
  ...rest
}) {
  const [initialState, setInitialValue] = useState({
    deliveryType: "",
    details: [{ shippingType: "", apiType: "", shippingWeight: "" }],
  });

  useEffect(() => {
    if (selected) {
      let { id, deliveryType, details } = selected;
      details = details.map((res) => ({
        id: res.id,
        shippingType: res.shippingType,
        apiType: res.apiType,
        shippingWeight: res.shippingWeight,
      }));

      setInitialValue({ id, deliveryType, details });
    }
  }, [selected]);

  const validationSchema = () => {
    return Yup.object().shape({
      deliveryType: Yup.string()
        .matches(/([A-Z\d_ ]{3,})$/g, "Invalid Characters")
        .required(),
      details: Yup.array().of(
        Yup.object().shape({
          shippingType: Yup.string()
            .matches(/([A-Z\d_]+)$/g, "Invalid Characters")
            .required(),
          apiType: Yup.number().required(),
          shippingWeight: Yup.number().required(),
          ...(selected && { id: Yup.number() }),
        })
      ),
    });
  };

  return React.createElement(selected ? UpdateHOC : CreateHOC, {
    handleClose: onClose,
    ...rest,
    children: (hocProps) => {
      const handleSubmit = async (values, formikUtils) => {
        let input = {};
        if (selected) {
          const finalDetails = [];
          if (selected) {
            selected.details.forEach((value) => {
              const find = values.details.find((res) => res.id === value.id);
              if (find) {
                finalDetails.push(find);
              } else {
                finalDetails.push({
                  ...value,
                  deleted: true,
                });
              }
              values.details.forEach((res) => {
                if (res.new) {
                  delete res.new;
                  finalDetails.push(res);
                }
              });
            });
          }
          values.details = finalDetails;
          input = {
            updateInput: {
              ...values,
              isActive: true,
            },
          };
        } else {
          input = {
            createInput: {
              ...values,
              isActive: true,
            },
          };
        }

        const res = await hocProps.handleSubmit({ ...input }, formikUtils);
        if (res) {
          const { data, reason } = res;
          onSuccess(data, reason);
        }
      };
      return (
        <Modal
          open={isOpen}
          onClose={onClose}
          aria-labelledby="Shipping type Edit/Create"
          aria-describedby="This modal is used for edit and create shipping type"
        >
          <Box
            style={{
              padding: 20,
              overflowX: "hidden",
              minHeight: "100vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CustomCard>
              <Stack
                spacing={"20px"}
                minWidth={670}
                maxHeight={700}
                overflow={"scroll"}
                p="20px"
              >
                <Typography
                  fontSize={"1.5rem"}
                  varient={"h1"}
                  fontWeight={"bold"}
                >
                  배송 타입 추가
                </Typography>

                <Formik
                  initialValues={initialState}
                  onSubmit={handleSubmit}
                  enableReinitialize={selected}
                  validationSchema={validationSchema}
                  submitCount
                >
                  {({
                    values,
                    errors,
                    handleChange,
                    handleSubmit,
                    submitCount,
                    isSubmitting,
                    isValid,
                  }) => {
                    return (
                      <Form onSubmit={handleSubmit}>
                        <Box
                          display={"flex"}
                          flexDirection={"column"}
                          gap={2.5}
                        >
                          <Box
                            display={"flex"}
                            flexDirection={"column"}
                            gap={2}
                          >
                            <label>
                              <Typography fontWeight={"bold"}>
                                배송 타입
                              </Typography>
                            </label>
                            <TextFieldBox
                              sx={{ borderRadius: 8 }}
                              name={"deliveryType"}
                              placeholder={
                                "배송타입은 영문 대문자, 숫자, 언더스코어만 입력이 가능합니다."
                              }
                              value={values.deliveryType}
                              onChange={handleChange}
                              error={
                                submitCount > 0 &&
                                !isSubmitting &&
                                !isValid &&
                                !!errors.deliveryType
                              }
                            />
                          </Box>

                          <Box display={"flex"} flexDirection={"column"}>
                            <FieldArray
                              name={"details"}
                              render={({ push, remove }) => {
                                return values.details.map((res, index) => {
                                  return (
                                    <>
                                      {index === 0 && (
                                        <Box
                                          display={"flex"}
                                          justifyContent={"space-between"}
                                          alignItems={"center"}
                                        >
                                          <Typography
                                            my={2}
                                            fontWeight={"bold"}
                                          >
                                            배송 종류
                                          </Typography>

                                          <Box
                                            display={"flex"}
                                            alignItems={"center"}
                                            gap={1}
                                            justifyContent={"flex-end"}
                                          >
                                            <Button
                                              onClick={() => {
                                                push({
                                                  shippingType: "",
                                                  apiType: "",
                                                  shippingWeight: "",
                                                  ...(selected && {
                                                    new: true,
                                                  }),
                                                });
                                              }}
                                              variant={"outlined"}
                                              color={"primary"}
                                              sx={{ borderRadius: 2 }}
                                            >
                                              추가
                                            </Button>
                                          </Box>
                                        </Box>
                                      )}
                                      <Grid
                                        key={`fieldArray${index}`}
                                        container
                                        gap={1}
                                        my={1}
                                        flexWrap={"nowrap"}
                                      >
                                        <Grid
                                          item
                                          xs={index ? 5 : 4}
                                          flexShrink={1}
                                        >
                                          <TextFieldBox
                                            sx={{ borderRadius: 8 }}
                                            name={`details[${index}].shippingType`}
                                            placeholder={
                                              "배송 종류를 입력해주세요."
                                            }
                                            value={
                                              values.details[index].shippingType
                                            }
                                            onChange={handleChange}
                                            error={
                                              submitCount > 0 &&
                                              !isSubmitting &&
                                              !isValid &&
                                              !!errors?.details?.[index]
                                                ?.shippingType
                                            }
                                          />
                                        </Grid>
                                        <Grid
                                          item
                                          xs={index ? 5 : 4}
                                          flexShrink={1}
                                        >
                                          <FormControl
                                            size="small"
                                            sx={{
                                              width: "100%",
                                              m: 0,
                                            }}
                                            error={
                                              submitCount > 0 &&
                                              !isSubmitting &&
                                              !isValid &&
                                              !!errors?.details?.[index]
                                                ?.apiType
                                            }
                                          >
                                            <InputLabel
                                              style={{
                                                background: "#fff",
                                                padding: "0 5px",
                                              }}
                                              variant="outlined"
                                            >
                                              API 종류
                                            </InputLabel>
                                            <Select
                                              variant="outlined"
                                              sx={{ borderRadius: 8 }}
                                              name={`details[${index}].apiType`}
                                              value={
                                                values.details[index].apiType
                                              }
                                              onChange={handleChange}
                                              style={{
                                                borderRadius: "5px",
                                                boxShadow:
                                                  "0px 0px 5px rgba(31, 65, 115, 0.1)",
                                                border: "1px solid #EBEBEB",
                                                width: "100%",
                                              }}
                                            >
                                              {apiTypes.map((item, index) => (
                                                <MenuItem
                                                  value={item.id}
                                                  key={index}
                                                >
                                                  {item.name}
                                                </MenuItem>
                                              ))}
                                            </Select>
                                          </FormControl>
                                        </Grid>
                                        <Grid
                                          item
                                          xs={index ? 3 : 4}
                                          flexShrink={1}
                                        >
                                          <TextFieldBox
                                            type={"number"}
                                            min={0}
                                            sx={{ borderRadius: 8 }}
                                            name={`details[${index}].shippingWeight`}
                                            placeholder={"배송 무게(kg)"}
                                            value={
                                              values.details[index]
                                                .shippingWeight
                                            }
                                            onChange={handleChange}
                                            error={
                                              submitCount > 0 &&
                                              !isSubmitting &&
                                              !isValid &&
                                              !!errors?.details?.[index]
                                                ?.shippingWeight
                                            }
                                          />
                                        </Grid>
                                        {!!index && (
                                          <Grid>
                                            <Button
                                              variant={"outlined"}
                                              color={"error"}
                                              sx={{ borderRadius: 2 }}
                                              onClick={() => remove(index)}
                                            >
                                              삭제
                                            </Button>
                                          </Grid>
                                        )}
                                      </Grid>
                                    </>
                                  );
                                });
                              }}
                            ></FieldArray>
                          </Box>
                        </Box>
                        <Divider sx={{ marginBlock: "1rem" }} />
                        <Box
                          display={"flex"}
                          justifyContent={"flex-end"}
                          gap={2.5}
                        >
                          <CustomButton
                            onClick={onClose}
                            styles={{
                              background: "#F8F8FA",
                              color: "#000",
                              width: "auto",
                            }}
                          >
                            취소
                          </CustomButton>
                          <CustomButton
                            type={"submit"}
                            styles={{ width: "auto" }}
                          >
                            전송
                          </CustomButton>
                        </Box>
                      </Form>
                    );
                  }}
                </Formik>
              </Stack>
            </CustomCard>
          </Box>
        </Modal>
      );
    },
  });
}

export default React.memo(ShippingTypeModal);
