import { Checkbox, Divider, Grid, Stack } from "@mui/material";
import { CustomRightClickMenu } from "../../../components/CustomRightClickMenu";
import Button from "@mui/material/Button";
import React, { useCallback, useEffect, useState, useRef } from "react";
import CustomButton from "../../../components/CustomButton";
import CustomPopover from "../../../components/CustomPopover";
import TableContainer from "../../../components/TableContainer";
import MasterManagementModal from "../../../modals/MasterManagementModal";
import ListHOC from "../../../SharedComponents/ListHOC";
import ConfirmModal from "../../../modals/ConfirmModal";
import { useDataProvider } from "react-admin";
import Toaster from "../../../utils/Toaster";
import { onDropDownSearchChange } from "../../../utils/onDropDownSearchChange";
import DropdownAndSearchInput from "../../../components/DropdownAndSearchInput2";
import { Form, Formik } from "formik";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import Moment from "moment";
import {
	generateSearchData,
	handleOtherSearch,
	useDeleteMany,
} from "../../../utils/utils";
import DateRangePicker from "../../../components/DateRangePicker";
import { gql } from "@apollo/client/core";
import CustomSelect from "../../../components/CustomSelect";
import { MasterProcessReleaseMutation } from "../../../utils/constants";
import { APIClient } from "../../../utils/apiGraphql";
import { useNavigate } from "react-router-dom";
import { CustomMasterQuery } from "./queries";

export default function MasterManagement(props) {
	return (
		<ListHOC {...props} _resource={"MasterManagement"}>
			<MasterManagementContainer />
		</ListHOC>
	);
}

function MasterManagementContainer({
	deleteBag,
	data: _Data,
	handleSearch,
	page,
	perPage,
	handleSetPerPage,
	excelData,
	countTotal,
	...hocProps
}) {
	const [openModal, setOpenModal] = useState(false);
	const [data, setData] = useState([]);
	const [selected, setSelected] = useState(null);
	const [checkedList, setCheckedList] = useState([]);
	const [openConfirmModal, setOpenConfirmModal] = useState(false);
	const dataProvider = useDataProvider();
	const [filterQuery, setFilterQuery] = useState(null);
	const navigate = useNavigate();
	const { isLoading, deleteMany } = useDeleteMany();
	const [selectedDeleteId, setSelectedDeleteId] = useState(null);
	const [openDeleteConfirmModal, setOpenDeleteConfirmModal] = useState(null);
	const [allExcelData, setAllExcelData] = useState([]);
	// const [requestIds, setRequestIds] = useState([]);
	const [UBIsuccess, setUBIsuccess] = useState(false);
	const [customMaster, setCustomMaster] = useState(null);

	const handleCheck = ({ target }) => {
		const { value, checked } = target;
		setCheckedList((prevState) => {
			const temp = [...prevState];
			if (checked) {
				temp.push(+value);
				return temp;
			} else {
				return temp.filter((res) => res !== +value);
			}
		});
	};

	React.useEffect(() => {
		setData(_Data);
	}, [_Data]);

	const handleOpenModal = () => {
		setOpenModal(true);
	};

	const handleClose = (reason) => {
		if ((reason = "deleteConfirm")) {
			setOpenDeleteConfirmModal(false);
		}
		setOpenModal(false);
		setSelected(null);
	};

	const doSearch = useCallback(() => {
		generateSearchData(handleSearch, filterQuery);
	}, [filterQuery]);

	useEffect(() => {
		doSearch();
	}, [filterQuery]);

	useEffect(() => {
		setAllExcelData(excelData);
	}, [excelData]);

	const handleSuccess = (data, reason) => {
		if (reason === "update") {
			setData((prevState) => {
				const temp = [...prevState];
				const index = prevState.findIndex((res) => res.id === data.id);
				if (index > -1) {
					temp[index] = data;
				}
				return temp;
			});
		} else {
			setData((prevState) => {
				const temp = [...prevState];
				temp.push(data);
				return temp;
			});
		}
	};
	const handleSelect = (data) => {
		setSelected(data);
		setOpenModal(true);
	};

	const handleDeleteSuccess = () => {
		setCheckedList([]);
		setOpenDeleteConfirmModal(false);
		if (selectedDeleteId) setSelectedDeleteId(null);
	};

	const handleProcessUpdate = async (type) => {
		if (type === "delete") {
			return handleDelete();
		}
		try {
			const { data } = await APIClient.mutate({
				mutation: MasterProcessReleaseMutation,
				variables: {
					input: { ids: checkedList},
				},
			});

			const { masterManagementProcessUpdate } = data;
			masterManagementProcessUpdate.forEach((res) => {
				handleSuccess(res, "update");
			});
			Toaster("success", "선택하신 마스터가 출고 처리되었습니다.");
			setOpenConfirmModal(false);
			setCheckedList([]);
			generateSearchData(handleSearch, {});
		} catch (error) {
			Toaster("error", error.message);
		}
	};

	const handleRedirect = (url, id) => {
		// navigate(`${url}?master=${id}`, { state: { master_id: id } });
		window.open(`${url}?master=${id}`, { state: { master_id: id } });
	};

	const handleDelete = () => {
		if (!(selectedDeleteId || checkedList.length)) {
			return Toaster("error", "마스터를 선택해주세요.");
		}
		const deleteMutation = gql(`
    mutation deleteMasterMany($input: MasterManagementDeleteManyInput!){
    deleteMasterMany(deleteInput: $input){
        ids
      }
    }`);
		deleteMany({
			ids: selectedDeleteId ? [selectedDeleteId] : checkedList,
			setData,
			deleteMutation,
			onSuccess: handleDeleteSuccess,
			responseKey: "deleteMasterMany",
		});
	};

	const [position, setPosition] = useState(null);
	const handleRightClick = (e) => {
		e.preventDefault();
		const { pageX: left, pageY: top } = e;
		setPosition({ top, left });
	};

	const handleMenuClose = () => {
		setPosition(null);
	};

	const resetFilters = () => {
		setFilterQuery({ createdAt: "", DepartureTime: "" });
	};

	//Custom master for dropdown
	const getMaster = async () => {
		const master = await CustomMasterQuery();
		// console.log(master?.data, { checkedList });
		const filtered = master?.data?.items.filter((item) =>
			checkedList.includes(item.id)
		);
		setCustomMaster(filtered);
	};

	useEffect(() => {
		if (checkedList) {
			getMaster();
		}
	}, [checkedList]);
	// console.log({ customMaster });

	// console.log({ data });

	const getRequestList = (items) => {
		let ids = [];
		items.bags
			.filter(
				(bag) =>
					bag.deliveryService?.apiType_?.name.includes("UBI (API)") ||
					bag.deliveryService?.apiType_?.name.includes("UBI (Relabel)")
			)
			.forEach((bag) =>
				bag.receptionLists.forEach((request) => ids.push(request.id))
			);
		return ids;
	};

	const checkAPIType = (items) => {
		// console.log({ items });

		const ids = getRequestList(items);
		// console.log({ ids });

		const filtered = items.bags.filter(
			(bag) =>
				bag.deliveryService?.apiType_?.name.includes("UBI (API)") ||
				bag.deliveryService?.apiType_?.name.includes("UBI (Relabel)")
		);
		// console.log({ filtered });

		if (ids.length > 0 || filtered.length > 0) {
			// setRequestIds(ids);
			return true;
		} else return false;
	};

	const getUBILabel = async (item) => {
		const requestIds = getRequestList(item);
		const Query = gql(`mutation
				bagUBI($bagInput:UBIBagInput!, $masterId:Float!){
								bagUBI(bagInput:$bagInput , masterId:$masterId)
							}`);

		try {
			const res = await APIClient.mutate({
				mutation: Query,
				variables: {
					bagInput: {
						ids: requestIds,
					},
					masterId: item.id,
				},
			});
			setUBIsuccess(true);
			Toaster("success", "UBI API 호출이 완료되었습니다.");
			generateSearchData(handleSearch, {});
			// console.log({ res });
		} catch (e) {
			Toaster("error", e.message);
			console.log(e);
		}
	};

	return (
		<>
			<CustomRightClickMenu
				openPosition={position}
				handleMenuClose={handleMenuClose}
				checkedList={checkedList}
				masterData={customMaster}
			/>
			<Stack spacing={"35px"} style={{ marginTop: "0px" }}>
				<Grid container justifyContent={"space-between"}>
					<Grid item xs={8}>
						<Grid container spacing="10px">
							<Grid item xs={2}>
								<CustomPopover>
									{({ close }) => {
										return (
											<Formik
												enableReinitialize
												initialValues={
													filterQuery || {
														createdAt: "",
														DepartureTime: "",
													}
												}
												onSubmit={(values) => {
													handleOtherSearch(values, setFilterQuery);
												}}
											>
												{({
													values,
													handleSubmit,
													handleChange,
													setFieldValue,
													resetForm,
												}) => {
													return (
														<Form onSubmit={handleSubmit}>
															<Stack spacing={"10px"} width={412} p="20px">
																<div>
																	<DateRangePicker
																		name={"createdAt"}
																		value={values?.["createdAt"]}
																		setFieldValue={setFieldValue}
																		label="생성일"
																	/>
																</div>
																<div>
																	<DateRangePicker
																		name={"DepartureTime"}
																		value={values?.["DepartureTime"]}
																		setFieldValue={setFieldValue}
																		label="출발날짜"
																	/>
																</div>

																<Divider />

																<div
																	style={{
																		display: "flex",
																		justifyContent: "flex-end",
																		gap: "20px",
																	}}
																>
																	<CustomButton
																		onClick={close}
																		styles={{
																			backgroundColor: "#F8F8FA",
																			padding: "5px",
																			color: "#000",
																			width: "auto",
																		}}
																	>
																		취소
																	</CustomButton>
																	<CustomButton
																		variant={"outlined"}
																		onClick={resetFilters}
																		styles={{
																			width: "auto",
																			padding: "8px",
																		}}
																	>
																		필터 초기화
																	</CustomButton>
																	<CustomButton
																		type={"submit"}
																		onClick={handleSubmit}
																		styles={{ padding: "5px", width: "auto" }}
																	>
																		전송
																	</CustomButton>
																</div>
															</Stack>
														</Form>
													);
												}}
											</Formik>
										);
									}}
								</CustomPopover>
							</Grid>
							<Grid item style={{ alignSelf: "center" }} xs={10}>
								<DropdownAndSearchInput
									onChange={(data) =>
										onDropDownSearchChange(data, setFilterQuery)
									}
									value={filterQuery}
									defaultValue={{ key: "masterNumber", value: "" }}
									dropDownValues={[
										// { value: "company_name_en", title: "업체명" },
										{ value: "masterNumber", title: "마스터 번호" },
										{ value: "flightNumber", title: "항공편 번호" },
										{ value: "departureAirPort", title: "출발 공항" },
										{ value: "arrivalAirPort", title: "도착 공항" },
									]}
									justifyContent={"center"}
								/>
							</Grid>
						</Grid>
					</Grid>

					<Grid item xs={4}>
						<Grid container spacing="10px">
							<Grid item xs={4}>
								<CustomButton
									onClick={handleOpenModal}
									variant={"outlined"}
									styles={{
										color: "#000",
										border: "1px solid #C4C4C4",
										borderRadius: 4,
										height: "40px",
									}}
								>
									마스터 생성
								</CustomButton>
							</Grid>
							<Grid item xs={4}>
								<CustomButton
									disabled={!checkedList.length}
									onClick={() => setOpenConfirmModal(true)}
									variant={"outlined"}
									styles={{
										color: "#000",
										border: "1px solid #C4C4C4",
										borderRadius: 4,
										height: "40px",
									}}
								>
									출고 처리
								</CustomButton>
							</Grid>
							<Grid item xs={4} padding="0">
								<CustomSelect
									value={String(perPage)}
									label=""
									onChange={({ target }) => {
										handleSetPerPage(target.value);
									}}
									options={["10", "20", "30", "40", "50", "100"]}
								></CustomSelect>
							</Grid>
						</Grid>
					</Grid>
				</Grid>

				<div style={{ position: "relative" }} onContextMenu={handleRightClick}>
					<TableContainer
						{...hocProps}
						headers={[
							<Checkbox
								checked={data.every((res) => checkedList.includes(res.id))}
								onChange={({ target }) => {
									if (target.checked) {
										setCheckedList(data.map((res) => res.id));
									} else {
										setCheckedList([]);
									}
								}}
							/>,
							"No.",
							"마스터 번호",
							"항공편명",
							"출발공항",
							"도착공항",
							"출발날짜",
							"출발시간",
							"도착날짜",
							"도착시간",
							"출고상태",
							"BAG 갯수",
							"총 배송품 갯수",
							"BAG 리스트",
							"UBI",
							"삭제",
						]}
						data={data.map((item, index) => {
							return [
								<Checkbox
									onChange={handleCheck}
									value={item.id}
									checked={checkedList.some((res) => res === item.id)}
								/>,
								<div
									style={{ cursor: "pointer" }}
									onClick={() => handleSelect(item)}
								>
									{countTotal - ((page - 1) * perPage + index)}
								</div>,

								item.masterNumber,
								item.flightNumber,
								item.departureAirPort,
								item.arrivalAirPort,
								<div>
									{item.DepartureTime &&
										new Date(item.DepartureTime).toLocaleDateString()}
								</div>,
								<div style={{ whiteSpace: "nowrap" }}>
									{item.DepartureTime &&
										new Date(item.DepartureTime).toTimeString()}
								</div>,
								item.arrivalTime &&
									new Date(item.arrivalTime).toLocaleDateString(),
								<div style={{ whiteSpace: "nowrap" }}>
									{item.arrivalTime &&
										new Date(item.arrivalTime).toTimeString()}
								</div>,
								<div>
									{item.deliveryStatus == "waiting for shipment"
										? "출고대기"
										: item.deliveryStatus == "shipped"
										? "출고완료"
										: item.deliveryStatus}
								</div>,
								item.totalBags,
								item.totalRequests,
								<Button
									style={{
										backgroundColor: "#5B8FF7",
										color: "white",
										borderRadius: "10px",
										whiteSpace: "nowrap",
										padding: "8px",
									}}
									onClick={() => handleRedirect("/master-bag-list", item.id)}
								>
									BAG 리스트
								</Button>,
								<div>
									{!checkAPIType(item) ? (
										""
									) : item.deliveryStatus !== "shipped" ? (
										"출고 대기중"
									) : item.ubiBag ? (
										"API 완료 "
									) : (
										<Button
											style={{
												backgroundColor: "red",
												color: "white",
												borderRadius: "10px",
												whiteSpace: "nowrap",
												padding: "8px",
											}}
											onClick={() => getUBILabel(item)}
										>
											API 호출
										</Button>
									)}
								</div>,
								<DeleteOutlineOutlinedIcon
									style={{
										cursor: "pointer",
										color: "red",
									}}
									onClick={() => {
										setOpenDeleteConfirmModal(true);
										setSelectedDeleteId(item.id);
									}}
								/>,
							];
						})}
					/>
				</div>
			</Stack>

			{openDeleteConfirmModal && (
				<ConfirmModal
					isLoading={isLoading}
					isOpen={openDeleteConfirmModal}
					onClose={() => handleClose("deleteConfirm")}
					onConfirm={handleDelete}
					title={"삭제"}
					description={"해당 마스터를 삭제하시겠습니까?"}
				/>
			)}

			<ConfirmModal
				onConfirm={handleProcessUpdate}
				onClose={() => setOpenConfirmModal(false)}
				isOpen={openConfirmModal}
				title={"출고 처리"}
				description={"출고처리 하시겠습니까?"}
			/>

			<MasterManagementModal
				isOpen={openModal}
				onSuccess={handleSuccess}
				selected={selected}
				onClose={() => handleClose()}
				onOpen={() => handleOpenModal()}
				{...hocProps}
			/>
		</>
	);
}
