import * as React from "react";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import FilledInput from "@mui/material/FilledInput";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Typography } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";

import { Box, Grid } from "@mui/material";

export default function CustomPasswordInput({
  error,
  onChange,
  placeholder,
  inputValue,
  name,
  flex,
  ...props
}) {
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => {
    setShowPassword((prevState) => !prevState);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <div
      style={{
        display: "flex",
        flex: flex ? flex : 6,
        flexDirection: "column",
        alignItems: "stretch",
        width: "100%",
      }}
    >
      <OutlinedInput
        size="small"
        id="outlined-adornment-password"
        name={name}
        placeholder={placeholder}
        type={showPassword ? "text" : "password"}
        value={inputValue}
        sx={{ backgroundColor: "#ffffff" }}
        notched={false}
        onChange={onChange}
        endAdornment={
          <InputAdornment position="end" sx={{ backgroundColor: "#ffffff" }}>
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
        label="Password"
        {...props}
      />
      {error ? (
        <Box
          container
          sx={{ display: "flex", flexDirection: "row", pt: "12px" }}
        >
          <Box item>
            {/* <ErrorIcon sx={{ color: "red" }} /> */}
          </Box>
          <Box item>
            <Typography
              component="p"
              variant="p"
              color="error"
              sx={{
                font: "normal",
                fontSize: "14px",
                fontWeight: "400",
              }}
            >
              Incorrect Password!
            </Typography>
          </Box>
        </Box>
      ) : null}
    </div>
  );
}
