import { AltRoute, ConnectingAirportsOutlined } from "@mui/icons-material";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Font,
  Image,
} from "@react-pdf/renderer";

const BagLabel1 = (props) => {
  const {
    barcode,
    bagNo,
    countryCode,
    dispatchDate,
    destination,
    masterNumber,
    shippingType,
    bagName,
    bag,
  } = props;

  let weight = 0;
  let price = 0;
  let totalParcels = 0;
  if (bag) {
    weight = bag.receptionLists.reduce((a, b) => {
      return a + b.miscellaneousInformation.applicableWeight;
    }, 0);

    price = bag.receptionLists.reduce((a, b) => {
      return a + b.productInformation.totalPriceAmount;
    }, 0);
    totalParcels = bag.receptionLists.length;
  }
  return (
    <Document>
      <Page size={"A6"} style={styles.page}>
        {/* <Page size={{ width: "10cm", height: "15cm" }} style={styles.page}> */}
        <View style={styles.sec1}>
          <Text style={styles.text}>MAWB</Text>
          <Text style={styles.text}>DEST.</Text>
        </View>
        <View style={styles.sec2}>
          <Text style={styles.text}>{masterNumber || " "}</Text>
          <Text style={styles.text}>{destination || " "}</Text>
        </View>
        <View style={styles.line}></View>
        <View style={styles.line}></View>
        <View style={styles.sec3}>
          <Text>{bagName}</Text>
          <Image src={barcode} style={styles.barCode} />
        </View>
        <View style={styles.sec4}>
          <View style={styles.listItem}>
            <Text style={styles.listItemLabel}>Dispatch date:</Text>
            <Text style={styles.listItemValue}>{dispatchDate || " "}</Text>
          </View>
          <View style={styles.listItem}>
            <Text style={styles.listItemLabel}>Country:</Text>
            <Text style={styles.listItemValue}>
              {bag?.shippingCountry?.code || " "}
            </Text>
          </View>
          <View style={styles.row}>
            <View style={{ ...styles.listItem, width: "50%" }}>
              <Text style={styles.listItemLabel}>pcs:</Text>
              <Text style={styles.listItemValue}>{totalParcels}</Text>
            </View>
            <View style={{ ...styles.listItem, width: "50%" }}>
              <Text style={styles.listItemLabel}>Weight:</Text>
              <Text style={styles.listItemValue}>{weight}</Text>
            </View>
          </View>
        </View>
        <Text style={styles.sec5}>
          {bag?.deliveryService?.shippingType || " "}
        </Text>
        <View style={styles.line}></View>
        <View style={styles.line}></View>
        <View style={styles.center}>
          <Text>
            {[
              bag?.shippingCountry?.code,
              bag?.deliveryService?.shippingType,
              bagNo,
            ].join("-")}
          </Text>
        </View>
      </Page>
    </Document>
  );
};

export default BagLabel1;

Font.register({
  family: "SpoqaHanSans",
  src: "https://cdn.jsdelivr.net/gh/spoqa/spoqa-han-sans@01ff0283e4f36e159ffbf744b36e16ef742da6d8/Subset/SpoqaHanSans/SpoqaHanSansLight.ttf",
});

const fRow = {
  diaplay: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
};
const fCol = {
  diaplay: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
};

const styles = StyleSheet.create({
  page: {
    textTransform: "uppercase",
    border: "2px solid #000",
    fontFamily: "SpoqaHanSans",
  },
  barCode: {
    height: 40,
    width: "100%",
  },
  sec1: {
    ...fRow,
    backgroundColor: "black",
    padding: 5,
    color: "#fff",
  },
  text: {
    fontSize: 12,
  },
  sec2: {
    ...fRow,
    padding: "5px 15px",
  },
  sec3: {
    ...fCol,
    fontSize: 16,
    margin: "0 10px",
  },
  sec4: {
    ...fCol,
    padding: 10,
  },

  sec5: {
    textAlign: "center",
    fontSize: 12,
    marginBottom: 5,
  },
  listItem: {
    ...fRow,
    width: "100%",
    fontSize: 12,
    marginBottom: 10,
    gap: 20,
    justifyContent: "flex-start",
  },
  listItemLabel: {
    marginRight: 20,
  },
  listItemValue: {
    color: "grey",
  },
  line: {
    height: 1,
    backgroundColor: "black",
    margin: "1px 0",
  },
  body: {
    padding: 10,
  },
  row: {
    ...fRow,
  },
  center: {
    ...fCol,
    textTransform: "uppercase",
    marginTop: 25,
  },
});
