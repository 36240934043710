/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, MenuItem } from "@mui/material";
import React, { useState, useEffect } from "react";
import CustomButton from "../../../components/CustomButton";
import { SelectBox } from "../../../components/CustomInput";
import {
  createGeneralRateMgmt,
  getAllCountries,
  getRatesManagement,
  getShippingDetails,
  updateRateMgmt,
} from "./queries";
import UploadRateCardPopover from "./UploadRateCardPopover";
import Button from "@mui/material/Button";
import Table1 from "./Table1";
import Toaster from "../../../utils/Toaster";
import { processXlx, readXlx } from "./utils/readXlsx";
import { exportGeneralSample, exportXlsx } from "./utils/exportXlsx";

export const GeneralRates = () => {
  const [countries, setCountries] = useState([]);
  const [shiptypes, setShiptypes] = useState();
  const [selectedcountry, setSelectedcountry] = useState("");
  const [shipwithdetail, setShipwithdetail] = useState([]);
  const [loading, setLoading] = useState(false);
  const [finalData, setFinalData] = useState([]);
  const [postloading, setPostloading] = useState(false);
  const [update, setUpdate] = useState(false);
  const [updateid, setUpdateid] = useState("");
  const [fromsheet, setFromsheet] = useState(false);

  //Select a country
  const countrySelect = (e) => {
    setSelectedcountry(e.target.value.id);
    setShiptypes(e.target.value.shippingTypesDetailsIds);
  };

  useEffect(() => {
    getAllCountries().then((res) => {
      setCountries(res.data.allShippingCountries);
    });

    return () => {};
  }, []);

  const handleChange = (file) => {
    readXlx(file).then((res) => {
      processXlx(res, countries).then((response) => {
        setShipwithdetail(response.shipments);
        setFromsheet(response.shipments);
        setSelectedcountry(response.country.id);


        //Checking the selected country already has the data for rates
        let filter = {
          country_id: parseInt(response.country.id),
        };
        //Getting rates
        getRatesManagement(filter).then((res) => {
          if (res.data.allRatesManagements[0] !== undefined) {
            setUpdate(true);
            setUpdateid(res.data.allRatesManagements[0].id);
          } else {
            setUpdate(false);
          }
        });
      });
    });
  };

  //Get the ship details
  const getShipDetail = async (ships, rates) => {
    const result = await Promise.all(
      ships.map(async (prom) => {
        const contents = await getShippingDetails(prom);
        if (rates !== null) {
          return { ...rates, ...contents.data.allShippingTypeDetails[0] }; //returning by adding rates
        } else {
          if (contents.data.allShippingTypeDetails.length === 0) return [];
          return contents.data.allShippingTypeDetails[0];
        }
      })
    );
    return result;
  };

  useEffect(() => {
    if (shiptypes && selectedcountry) {
      setLoading(true);
      setFinalData([]);
      //Geting rates
      let filter = {
        country_id: parseInt(selectedcountry),
        default: true,
      };
      //Getting rates
      getRatesManagement(filter).then((res) => {
        const rates = res.data.allRatesManagements[0]
          ? res.data.allRatesManagements[0]
          : null;

        if (res.data.allRatesManagements[0] !== undefined) {
          setUpdate(true);
          setUpdateid(res.data.allRatesManagements[0].id);
        } else {
          setUpdate(false);
        }

        //Getting ship info
        getShipDetail(JSON.parse(shiptypes), rates).then((res) => {
          setShipwithdetail(res);
          setLoading(false);
        });
      });
    }
    return () => {};
  }, [shiptypes, selectedcountry]);

  //Making inital finalData when obtaned from server
  useEffect(() => {
    if (shipwithdetail.length !== 0) {
      const cloneShipDetail = [...shipwithdetail];
      let initData = [];
      cloneShipDetail.forEach((d, i) => {
        // if (Object.hasOwn(d, "ratesDetails")) {
        let obj = {};
        obj.id = d.id;
        obj.index = i;
        if (Object.hasOwn(d, "ratesDetails")) {
          const filterData = d.ratesDetails.filter(
            (el) => el.shippingTypeDetailId === d.id
          );
          obj.data = filterData;
        }
        initData.unshift(obj);
        // }
      });
      setFinalData(initData);
    }
    return () => {};
  }, [shipwithdetail]);

  //Get table 1 data
  const setData1 = (data) => {
    const cloneFinal = [...finalData];
    const index = cloneFinal.findIndex((el) => el.index === data.index);
    if (index !== -1) {
      cloneFinal[index].data = data.data;
      setFinalData(cloneFinal);
    } else {
      cloneFinal.push(data);
      setFinalData(cloneFinal);
    }
  };

  //Insert to db
  const insertRate = () => {
    //If the rate is not added and data is initial return
    if (finalData.length !== shipwithdetail.length)
      return Toaster("error", "요율을 입력해주세요.");

    let pack = {
      countryId: selectedcountry,
      default: true,
    };
    let rates = [];
    finalData.forEach((data) => {
      rates = [...rates, ...data.data];
    });
    pack.ratesDetails = rates;
    setPostloading(true);
    createGeneralRateMgmt(pack).then(
      (res) => {
        setPostloading(false);
        setUpdate(true);
        setUpdateid(res.data.createRatesManagement.id);
        Toaster("success", "요율이 성공적으로 업데이트 되었습니다.");
        console.log(res);
      },
      (err) => {
        setPostloading(false);
        Toaster("error", "에러가 발생하였습니다.");
      }
    );
  };

  //Update in db
  const updateRate = () => {
    let pack = {
      id: parseInt(updateid),
      countryId: selectedcountry,
      default: true,
    };
    let rates = [];
    finalData.forEach((data) => {
      const newRatObj = data.data.map(({ __typename, ...rest }) => rest);
      rates = [...rates, ...newRatObj];
    });
    pack.ratesDetails = rates;
    setPostloading(true);
    updateRateMgmt(pack).then(
      (res) => {
        setPostloading(false);
        Toaster("success", "요율 업데이트가 완료되었습니다.");
      },
      (err) => {
        setPostloading(false);
        Toaster("error", "요율 업데이트를 실패하였습니다.");
      }
    );
  };

  //Export XLX
  const exportXlx = () => {
    if (finalData.length === 0)
      return Toaster("error", "해당 국가에 해당하는 요율이 없습니다.");
    let country = countries.find((el) => el.id === selectedcountry);
    exportXlsx(finalData, country, shipwithdetail);
  };

  return (
    <Grid container spacing="20px" flexDirection={"column"}>
      <Grid
        item
        xs={12}
        container
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Grid item xs={5} sm={4} md={2}>
          {countries && (
            <SelectBox onChange={countrySelect}>
              {countries
                .filter((el) => el.isActive === true)
                .map((country) => (
                  <MenuItem key={country.id} value={country}>
                    {country.name}
                  </MenuItem>
                ))}
            </SelectBox>
          )}
          {fromsheet && (
            <div style={{ wordBreak: "keep-all" }}>
              <small>
                Country detected from Sheets :
                <b>{countries.find((el) => el.id === selectedcountry).name}</b>
              </small>
            </div>
          )}
        </Grid>

        <Grid
          item
          xs={5}
          sm={4}
          md={3}
          container
          spacing={1}
          justifyContent={"flex-end"}
        >
          {shipwithdetail.length > 0 ? (
            <Grid item xs={6}>
              <CustomButton
                variant={"outlined"}
                styles={{
                  color: "#000",
                  border: "1px solid #C4C4C4",
                  padding: "7px",
                  borderRadius: 5,
                }}
                onClick={exportXlx}
              >
                <img
                  style={{ width: "30px", paddingRight: "10px" }}
                  src="/assets/images/excel.png"
                  alt="excel"
                ></img>
                엑셀 다운로드
              </CustomButton>
            </Grid>
          ) : (
            <Grid item xs={6}>
              <CustomButton
                variant={"outlined"}
                styles={{
                  color: "#000",
                  border: "1px solid #C4C4C4",
                  padding: "7px",
                  borderRadius: 5,
                }}
                onClick={exportGeneralSample}
              >
                요율표 양식 다운로드
              </CustomButton>
            </Grid>
          )}
          <Grid item xs={6}>
            <UploadRateCardPopover sendFile={handleChange} />
          </Grid>
        </Grid>
      </Grid>

      {loading ? (
        <Grid
          item
          xs={12}
          container
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          Loading...
        </Grid>
      ) : (
        <Grid item container spacing={3} justifyContent={"space-between"}>
          {shipwithdetail.length > 0 &&
            shipwithdetail.map((ship, index) => (
              <Grid item xs={6} key={`rateTables${ship.id}`}>
                <Table1 getData={setData1} data={ship} index={index} />
              </Grid>
            ))}
        </Grid>
      )}

      {selectedcountry === "" && !fromsheet && (
        <Grid
          item
          xs={12}
          container
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          국가를 선택하세요.
        </Grid>
      )}

      {shipwithdetail.length === 0 && selectedcountry !== "" && !loading && (
        <Grid
          item
          xs={12}
          container
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          There is no Shipping for this country.
        </Grid>
      )}

      {shipwithdetail.length > 0 && (
        <Grid xs={12} mt={2} ml={2}>
          <Grid
            xs={12}
            container
            justifyContent={"flex-start"}
            alignItems={"center"}
          >
            {!postloading ? (
              <>
                {update ? (
                  <Button
                    variant="outlined"
                    disabled={
                      selectedcountry !== "" || fromsheet ? false : true
                    }
                    onClick={updateRate}
                  >
                    업데이트
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    disabled={
                      selectedcountry !== "" || fromsheet ? false : true
                    }
                    onClick={insertRate}
                  >
                    저장
                  </Button>
                )}
              </>
            ) : (
              <>{update ? "Updating..." : "Adding..."}</>
            )}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};
