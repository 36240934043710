import { Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ShipterLogo from "../../Common/assets/Images/ShipterLogo";
import CustomButton from "../../components/CustomButton";
import CustomCard from "../../components/CustomCard";
import TableContainer from "../../components/TableContainer";
import { Layout as LayoutBox, MenuItemLink, useLogout } from "react-admin";
import { useNavigate, useSearchParams } from "react-router-dom";
import { gql } from "@apollo/client/core";
import { APIClient } from "../../utils/apiGraphql";
import Toaster from "../../utils/Toaster";

// const DUMMY_DATA = [
// 	{ key: "Departure", value: "Korea" },
// 	{ key: "Destination", value: "United Kingdom" },
// 	{ key: "Registration number", value: "1-4000-897d-81ada7910b32" },
// 	{ key: "Order number", value: "619b2262-350" },
// 	{ key: "Destinaton Courier number", value: "563-588-5663" },
// 	{ key: "Courier", value: "Kunze Inc" },
// 	{ key: "Sender", value: "Alfredo Medhurst" },
// 	{ key: "Consignee", value: "Santos Trantow" },
// 	{ key: "Address", value: "59042 Abby Overpass" },
// 	{ key: "Contact number", value: "314-432-4472" },
// ];

export default function DeliveryTracking({ screens, setActiveScreen, noBack }) {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();

	const [ term, setTerm ] = useState(searchParams.get("no"));
	const [ DUMMY_DATA, setDUMMY_DATA ] = useState([
		{ key: "Departure", value: "" },
		{ key: "Destination", value: "" },
		{ key: "Registration number", value: "" },
		{ key: "Shipping number", value: "" },
		{ key: "Order number", value: "" },
		{ key: "Sender", value: "" },
		{ key: "Consignee", value: "" },
		{ key: "Address", value: "" },
		{ key: "Contact number", value: "" },
	]);
	const [ trackShipments, setTrackShipments ] = useState([]);

	useEffect(() => {
		if(searchParams.get("no")) {
			const termNo = searchParams.get("no");
			
			setTerm(termNo);
			console.log("Search params = ",termNo);

			const Query = gql(`
				query trackShipmentByTrackingOrShippingNumber($id: String!) {
					items: trackShipmentByTrackingOrShippingNumber(id: $id) {
						destination
						trackingNumber
						shippingNumber
						orderNumber
						sender
						consignee
						address
						contactNumber
						trackingInfoList{
							date
							location
							event
						}
					}
			  	}
			`)


			findTracking(Query, termNo);
		}
		
	}, [])

	const findTracking = async (Query, termNo) => {
		try{
			
			const {
				data: {items},
			} = await APIClient.query({
				query: Query,
				variables: {
					id: termNo,
				},
			});

			console.log("items",items);
			if(items) {
				setTrackShipments(items.trackingInfoList);
				setDUMMY_DATA([
					{ key: "Departure", value: "Korea" },
					{ key: "Destination", value: items.destination },
					{ key: "Registration number", value: items.trackingNumber },
					{ key: "Shipping number", value: items.shippingNumber },
					{ key: "Order number", value: items.orderNumber },
					{ key: "Sender", value: items.sender },
					{ key: "Consignee", value: items.consignee },
					{ key: "Address", value: items.address },
					{ key: "Contact number", value: items.contactNumber },
				])

			}
			// el/se {
				// Toaster("error", "No tracking available")
			// }

		}
		catch (e) {
			Toaster("error", e.message);
		}
	}

	return (
		<Grid xs={12} padding={"20px"}>
			<Grid
				container
				flexDirection={"column"}
				padding={"30px"}
				sx={{ height: "100%" }}
				paddin
			>
				<Grid
					alignSelf={"center"}
					style={{ marginTop: "50px", marginBottom: "30px" }}
					item
					xs={8}
				>
					<ShipterLogo />
				</Grid>
				{!noBack && (
					<Grid
						container
						alignItems={"center"}
						justifyContent="space-between"
						item
						xs={8}
					>
						<Grid item xs={4}>
							<CustomButton
								styles={{
									background: "white",
									color: "#000",
									width: 120,
									height: 44,
									border: "1px solid #E5E5E5",
									gap: "20px",
									filter: "drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.25))",
								}}
								onClick={() => navigate("/login")}
							>
								이전 페이지
							</CustomButton>
						</Grid>
					</Grid>
				)}
				<Grid container justifyContent={"space-between"} mt={3} xs={12}>
					<Grid item xs={6}>
						<TableContainer
							isLoading={false}
							requestCompleted
							headers={["날짜", "위치", "내용"]}
							data={trackShipments.map((item, index) => [
								trackShipments.date ? trackShipments.date : "no date available",
								trackShipments.location ? trackShipments.location : "no location",
								trackShipments.event ? trackShipments.event : "no event"
							])}
						/>
					</Grid>
					<Grid item xs={5}>
						<CustomCard border>
							{DUMMY_DATA.map(({ key, value }, idx) => (
								<CardRow hasBorder={idx < DUMMY_DATA.length - 1}>
									<CardLeft>{key}</CardLeft>
									<CardRight>{value}</CardRight>
								</CardRow>
							))}
						</CustomCard>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
}

const CardRow = styled.div`
	height: 45px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 25px;
	border-bottom: ${({ hasBorder }) =>
		hasBorder ? "1px solid #E5E5E5" : "0px"};
`;

const CardLeft = styled.span`
	font-family: "Spoqa Han Sans Neo";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 19px;
	/* identical to box height */

	color: #000000;
`;

const CardRight = styled.span`
	font-family: "Spoqa Han Sans Neo";
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
	line-height: 19px;
	/* identical to box height */

	color: #000000;
`;
