import * as React from "react";
import { Divider } from "@mui/material";
import Box from "@mui/material/Box";
import CustomButton from "../components/CustomButton";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ReactDOMServer from "react-dom/server";
import {
	MasterTable,
	ShippingRequestTable,
} from "../Common/settlement-excel-components";
import * as XLSX from "xlsx-js-style";
import Moment from "moment";
import { GET_SETTLEMENT_EXCEL_DATA } from "../utils/constants";
import { APIClient } from "../utils/apiGraphql";
import Toaster from "../utils/Toaster";
import {
	generateSameStyleInCount,
	masterStyles,
	shippingRequestStyles,
} from "../Common/settlement-excel-components/styles";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 420,
	bgcolor: "background.paper",
	boxShadow: "0px 0px 5px rgba(31, 65, 115, 0.1)",
	borderRadius: "10px",
	textAlign: "center",
	p: 4,
};

const iconStyle = {
	backgroundColor: "#fef5ed",
	width: "100px",
	height: "100px",
	marginLeft: "auto",
	marginRight: "auto",
	borderRadius: "100%",
	paddingTop: "21px",
};

export function ExcelModal({ open, ids, handleOpen, handleClose }) {
	const handleExcelFileGeneration = async (options) => {
		if (ids && ids.length) {
			const { data } = await APIClient.query({
				query: GET_SETTLEMENT_EXCEL_DATA,
				variables: {
					ids: { ids },
				},
			});
			generateMasterDetails(data.getSettlementExcelData);
		}
	};

  const generateMasterDetails = (data) => {
    try {
		const htmls = data.map((res) => {
			return {
				htmlData: ReactDOMServer.renderToString(<MasterTable {...res} />),
				data: res,
			};
		});
      	
		htmls.forEach(async ({ htmlData, data }) => {
			const wb = XLSX.utils.book_new();
			const div = document.createElement("div");
			div.innerHTML = htmlData;
			const ws = XLSX.utils.table_to_sheet(div, { cellStyles: true });
			ws['!cols'] = [{ width: 10 }, { width: 10 }, { width: 10 }, { width: 10 }, { width: 10 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }]; //set col. widths
			const masterStyle = masterStyles( data.masterManagement.length );

			Object.keys(masterStyle).forEach((key) => {
			ws[key].s = masterStyle[key];
			});

			XLSX.utils.book_append_sheet(wb, ws, "invoice");
			div.remove();

			data.masterManagement.forEach((master) => {
				const { bags } = master;
				
				const requestShippingTable = ReactDOMServer.renderToString(
					<ShippingRequestTable bags={bags} />
				);

				const div = document.createElement("div");
				div.innerHTML = requestShippingTable;
				const ws = XLSX.utils.table_to_sheet(div);
				ws['!cols'] = [{ width: 15 }, { width: 15 }, { width: 15 }, { width: 15 }, { width: 15 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }]; //set col. widths
				const totalRequests = bags.reduce((a, bag) => {
					return a + bag.receptionLists.length || 0;
				}, 0);

				const shippingRequestStyle = shippingRequestStyles(totalRequests + 2);
				console.log(shippingRequestStyle);
				Object.keys(shippingRequestStyle).forEach((key) => {
					ws[key].s = shippingRequestStyle[key];
				});
				XLSX.utils.book_append_sheet(wb, ws, master.masterNumber);
				div.remove();
        	});

			XLSX.writeFile(
				wb,
				data.company_name_kr +
					"정산내역" +
					` (${Moment().format("YYYY-MM-DD")})` +
					".xlsx",
				{
					bookType: "xlsx",
				}
			);
		});

		handleClose();
	} catch (e) {
		console.log(e);
		Toaster("error", "Something went wrong!");
	}
};
	
	return (
		<div>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={style}>
					<Typography
						sx={{ mt: 2 }}
						style={{ textAlign: "left", display: "flex" }}
						variant="string"
					>
						<InfoOutlinedIcon />
						<Typography variant="string" style={{ fontWeight: "bold", marginLeft: "5px" }}>
							엑셀 다운로드
						</Typography>
					</Typography>
					<Typography
						sx={{ mt: 2 }}
						style={{ textAlign: "left", lineHeight: "10px" }}
						variant="string"
					>
						정산 내역 액셀 파일을 다운로드 하시겠습니까?
					</Typography>
					{/* <Divider /> */}
					<Box
						style={{
							textAlign: "right",
							marginTop: "20px",
						}}
					>
						<CustomButton
							width="100px"
							style={{ borderRadius: "10px" }}
							onClick={handleExcelFileGeneration}
						>
							확인
						</CustomButton>
						<CustomButton
							width="100px"
							style={{
								borderRadius: "10px",
								marginLeft: "10px",
								backgroundColor: "#F8F8FA",
								color: "black",
							}}
							onClick={handleClose}
						>
							취소
						</CustomButton>
					</Box>
				</Box>
			</Modal>
		</div>
	);
}
