import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
// import TableContainer from "@mui/material/TableContainer";
import TableContainer from "./TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Checkbox, Grid, MenuItem, Pagination, Stack } from "@mui/material";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		fontWeight: "bold",
		backgroundColor: "#F8F8FA",
		color: "rgba(0, 0, 0, 0.6)",
	},
	[`&.${tableCellClasses.body}`]: {
		backgroundColor: "white",
		fontSize: 14,
	},
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
	"&:nth-of-type(odd)": {
		backgroundColor: theme.palette.action.hover,
	},
	// hide last border
	"&:last-child td, &:last-child th": {
		border: 0,
	},
}));

function createData(
	id,
	companyName,
	quantityReceived,
	incomingQuantity,
	unstockedQuantity
) {
	return {
		id,
		companyName,
		quantityReceived,
		incomingQuantity,
		unstockedQuantity,
	};
}

export const ReceptionHistoryTable = ({
	data,
	isLoading,
	setCheckedList,
	checkedList,
	sortingOrder,
}) => {
	// const rows = data.map((row) =>
	// 	createData(row[0], row[1], row[2], row[3], row[4])
	// );

	const handleCheck = ({ target }) => {
		const { value, checked } = target;
		setCheckedList((prevState) => {
			const temp = [...prevState];
			if (checked) {
				temp.push(+value);
				return temp;
			} else {
				return temp.filter((res) => res !== +value);
			}
		});
	};
	return (
		<TableContainer
			{...{ isLoading: isLoading, requestCompleted: true }}
			headers={[
				"No.",
				"업체명",
				`접수수량(총 ${data?.reduce(
					(partialSum, a) => partialSum + a.overall,
					0
				)} 개)`,
				`입고수량(총 ${data?.reduce(
					(partialSum, a) => partialSum + a.quantityReceived,
					0
				)} 개)`,
				`미입고수량(총 ${data?.reduce(
					(partialSum, a) => partialSum + a.unstacked,
					0
				)} 개)`,
			]}
			data={data?.map((item, index) => [
				sortingOrder === 'ascending' ? index + 1 : data.length - index,
				item.companyName,
				item.overall,
				item.quantityReceived,
				item.unstacked,
			])}
		/>
	);
};
