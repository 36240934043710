import {
	Checkbox,
	Divider,
	Grid,
	Link,
	MenuItem,
	Pagination,
	Stack,
} from "@mui/material";
import React, { useEffect, useState, useCallback } from "react";
import CustomButton from "../../components/CustomButton";
import CustomInput, {
	SelectBox,
	TextFieldBox,
} from "../../components/CustomInput";
import CustomPopover from "../../components/CustomPopover";
import TableContainer from "../../components/TableContainer";
import { Form, Formik, setIn } from "formik";
import Moment from "moment";
import { handleOtherSearch } from "../../utils/utils";
import DateRangePicker from "../../components/DateRangePicker";
import DropdownAndSearchInput from "../../components/DropdownAndSearchInput2";
import { onDropDownSearchChange } from "../../utils/onDropDownSearchChange";
import ListHOC from "../../SharedComponents/ListHOC";
import { generateSearchData } from "../../utils/utils";
import { ConnectingAirportsOutlined } from "@mui/icons-material";
import moment from "moment";
import CustomSelect from "../../components/CustomSelect";
import CalendarIcon from "../../Common/assets/Images/CalendarIcon";

export default function FeeDeduction() {
	return (
		<ListHOC
			_resource={"Transaction"}
			showFilter={{ reason: "delivery-fee-deduction" }}
		>
			<FeeDeductionList />
		</ListHOC>
	);
}

function FeeDeductionList({
	data: _Data,
	handleSearch,
	page,
	perPage,
	handleSort,
	countTotal,
	handleChangePage,
	handleSetPerPage,
	...hocProps
}) {
	const [filterQuery, setFilterQuery] = useState(null);
	const [filterQuery2, setFilterQuery2] = useState(null);
	const [filterNew, setFilterNew] = useState(null);
	const [deductionFilter, setDeductionFilter] = useState(null);
	const [data, setData] = useState([]);
	const [filteredData, setFilteredData] = useState([]);
	const [filterDate, setFilterDate] = useState(null);
	const [startDate, setStartDate] = useState("");
	const [endDate, setEndDate] = useState("");
	const [initialData, setInitialData] = useState([]);
	// console.log(filterDate);

	useEffect(() => {
		if (_Data) {
			setData(_Data);
			// setFilteredData(hocProps.data);
			// setInitialData(
			// 	hocProps.data
			// 		? hocProps.data.filter(
			// 				(row) => row.reason === "delivery-fee-deduction"
			// 		  )
			// 		: hocProps.data
			// );
		}
	}, [_Data]);

	const searchTrackingNumber = (obj) => {
		setFilterQuery(obj.newValue);
	};
	// console.log(filterQuery);

	const doSearch = useCallback(() => {
		// console.log({ filterDate });
		let splitted = [];
		splitted = filterDate?.createdAt?.split(" ");
		if (splitted) {
			if (splitted.length > 0) {
				// console.log({ splitted });
				if (splitted[0] !== splitted[2]) {
					// console.log("if");
					const filter = {
						createdAt: filterDate?.createdAt,
						reason: "delivery-fee-deduction",
					};
					generateSearchData(handleSearch, filter);
				} else {
					delete filterQuery?.createdAt;
					// console.log("else");
					generateSearchData(handleSearch, filterQuery);
				}
			}
		} else generateSearchData(handleSearch, filterQuery);
	}, [filterQuery, filterDate]);

	useEffect(() => {
		doSearch();
	}, [filterQuery, filterDate]);

	// const doSearch2 = useCallback(() => {
	// 	generateSearchData(handleSearch, filterDate);
	// }, [filterDate]);

	// useEffect(() => {
	// 	doSearch2();
	// }, [filterDate]);

	return (
		<Stack spacing={"35px"}>
			<Grid container justifyContent={"space-between"} alignItems={"center"}>
				<Grid xs={10} item alignItems={"center"} container>
					<Formik
						enableReinitialize
						initialValues={
							filterQuery || {
								createdAt: `${Moment().format(
									"YYYY-MM-DD"
								)} - ${Moment().format("YYYY-MM-DD")}`,
							}
						}
						onSubmit={(values) => {}}
					>
						{({ values, handleSubmit, setFieldValue, resetForm }) => {
							return (
								<Form onSubmit={handleSubmit} style={{ width: "50%" }}>
									<Grid
										container
										xs={12}
										item
										alignItems={"center"}
										columnGap={1}
									>
										<Grid
											item
											xs={3.5}
											alignItems={"end"}
											style={{ maxWidth: "200px" }}
										>
											<SelectBox defaultValue="차감일">
												<MenuItem value="차감일">차감일</MenuItem>
											</SelectBox>
										</Grid>
										<Grid padding="0" xs={7} item style={{ maxWidth: "250px" }}>
											<DateRangePicker
												name={"createdAt"}
												value={values?.["createdAt"]}
												setFieldValue={setFieldValue}
												showLabel={false}
												onChange={setFilterDate(values)}
												iconEnd={<CalendarIcon />}
											/>
										</Grid>
									</Grid>
								</Form>
							);
						}}
					</Formik>
					<Grid xs={5.5} item>
						<DropdownAndSearchInput
							onChange={searchTrackingNumber}
							value={filterQuery}
							defaultValue={{
								key: "trackingNumber",
								value: "",
							}}
							dropDownValues={[
								{
									value: "trackingNumber",
									title: "접수번호",
								},
							]}
							inputFieldPlaceHolder={"검색어를 입력해주세요."}
							justifyContent={"flex-end"}
						/>
					</Grid>
				</Grid>

				<Grid item xs={2} alignItems={"end"} style={{ maxWidth: "200px" }}>
					<CustomSelect
						value={String(perPage)}
						label=""
						onChange={({ target }) => {
							handleSetPerPage(target.value);
						}}
						options={["10", "20", "30", "40", "50", "100"]}
					></CustomSelect>
				</Grid>
			</Grid>
			<div style={{ position: "relative" }}>
				<TableContainer
					{...{ isLoading: false, requestCompleted: true }}
					headers={[
						"No.",
						"접수번호",
						"상품명",
						"차감내역",
						"배송국가",
						"무게",
						"차감액",
						"차감날짜",
					]}
					data={data.map((item, index) => [
						countTotal - ((page - 1) * perPage + index),
						item.shippingFeeDeductionHistory?.trackingNumber,
						item.shippingFeeDeductionHistory?.product_name,
						"배송비 차감",
						item.shippingFeeDeductionHistory?.shipping_country,
						`${item.shippingFeeDeductionHistory?.actual_weight}kg`,
						<div
							style={{ whiteSpace: "nowrap" }}
						>{`${item.shippingFeeDeductionHistory?.shipping_fee.toLocaleString()}원`}</div>,

						<div style={{ whiteSpace: "nowrap" }}>
							{item.shippingFeeDeductionHistory?.shipping_fee_deducted_date
								? moment(
										item.shippingFeeDeductionHistory?.shipping_fee_deducted_date
								  ).format("YYYY-MM-DD Thh-mm-ss")
								: null}
						</div>,
					])}
				/>
			</div>
		</Stack>
	);
}
