import React, { useCallback, useEffect, useState } from "react";
import { useDataProvider } from "react-admin";
import Toaster from "../../../utils/Toaster";
import { gql } from "@apollo/client/core";
import { APIClient } from "../../../utils/apiGraphql";
import CustomCard from "../../../components/CustomCard";
import { Button, Grid, Pagination, Stack } from "@mui/material";
import { TextFieldBox } from "../../../components/CustomInput";
import CustomButton from "../../../components/CustomButton";
import TableContainer from "../../../components/TableContainer";
import ConfirmModal from "../../../modals/ConfirmModal";
import ListHOC from "../../../SharedComponents/ListHOC";
import ShippingCountriesModal from "../../../modals/ShippingCountriesModal";
import CustomSelect from "../../../components/CustomSelect";
import { generateSearchData } from "../../../utils/utils";

const ShippingCountryContainer = ({
	data: _Data,
	page,
	perPage,
	countTotal,
	handleSetPerPage,
	count,
	handlePageChange,
	handleSearch,
	...hocProps
}) => {
	const [openDialog, setOpenDialog] = useState(false);
	const dataProvider = useDataProvider();
	const [data, setData] = useState([]);
	const [selected, setSelected] = useState(null);
	// eslint-disable-next-line no-unused-vars
	const [filterQuery, setFilterQuery] = useState(null);
	const [countries, setCountries] = useState([]);

	const [deliveryTypes, setDeliveryTypes] = useState([]);

	const [openModal, setOpenModal] = useState(false);

	const getShippingCountries = async () => {
		const Query = gql(`
		query{
			allShippingCountries{
				id
			}
		}
      `);

		try {
			const {
				data: { allShippingCountries: countries },
			} = await APIClient.query({
				query: Query,
			});
			setCountries(countries);
		} catch (e) {
			console.log(e);
		}
	};

	useEffect(() => {
		getShippingCountries();
	}, []);

	useEffect(() => {
		setData(_Data);
	}, [_Data]);

	const handleClose = () => {
		setOpenDialog(false);
		setSelected(null);
		setOpenModal(false);
	};

	const handleConfirm = async () => {
		const Mutation = gql(`
      mutation updateShippingCountries($updateInput: UpdateShippingCountryInput!) {
        updateShippingCountries(updateInput: $updateInput) {
          id
          isActive
        }
      }
    `);

		try {
			const {
				data: { updateShippingCountries: responseData },
			} = await APIClient.mutate({
				mutation: Mutation,
				variables: {
					updateInput: {
						id: selected.id,
						isActive: !selected.isActive,
					},
				},
			});
			setData((prevState) => {
				const tempData = [...prevState];
				const find = tempData.find((res) => res.id === responseData.id);
				if (find) {
					find.isActive = responseData.isActive;
				}
				return tempData;
			});
			Toaster("success", "업데이트가 완료되었습니다");
			handleClose();
		} catch (e) {
			console.log(e);
			Toaster("error", e.message);
		}
	};

	const handleActive = (data, type = "") => {
		if (type === "wholeUpdate") {
			setOpenModal(true);
		} else {
			setOpenDialog(true);
		}
		setSelected(data);
	};

	const doSearch = useCallback(() => {
		if (filterQuery) {
			// const query = {};
			// Object.keys(filterQuery).forEach((res) => {
			//   const value = filterQuery[res];
			//   if (value) {
			//     query[res] = value;
			//   }
			// });
			// handleSearch(query);
			generateSearchData(handleSearch, filterQuery);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filterQuery]);

	// useEffect(() => {
	// 	doSearch();
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [filterQuery]);

	const handleCreate = () => {
		setOpenModal(true);
	};

	useEffect(() => {
		dataProvider
			.getList("ShippingTypes", {})
			.then(({ data }) => {
				setDeliveryTypes(data);
			})
			.catch((e) => Toaster("error", e.message));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleSuccess = (data1, reason) => {
		if (reason === "update") {
			setData((prevState) => {
				const temp = [...prevState];
				const index = prevState.findIndex((res) => res.id === data1.id);
				if (index > -1) {
					temp[index] = data1;
				}
				return temp;
			});
		} else {
			let temp = [...data];
			temp = [data1, ...temp];
			setData(temp);
		}
	};

	return (
		<>
			<CustomCard>
				<Stack spacing={"35px"}>
					{/* <Grid container justifyContent={"space-between"}>
            <Grid item xs={5}>
              <Grid container spacing="10px">
                <DropdownAndSearchInput
                  dropDownValues={[{ value: "code", title: "국가 코드" }]}
                  value={filterQuery}
                  defaultValue={{
                    key: "code",
                    value: "",
                  }}
                  onChange={(value) =>
                    onDropDownSearchChange(value, setFilterQuery)
                  }
                />

                 <Grid item xs={3} padding="0">
                  <CustomSelect
                    value={String(perPage)}
                    label="개씩 보기 "
                    onChange={({ target }) => {
                      handleSetPerPage(target.value);
                    }}
                    options={["10", "20"]}
                  ></CustomSelect>
                </Grid>
              </Grid>

            </Grid>

            <Grid
              item
              xs={6}
              style={{
                justifyContent: "flex-end",
                display: "flex",
                gap: "20px",
              }}
             
            >
              <CustomButton
                onClick={handleCreate}
                styles={{ width: "auto",padding:'10px' }}
              >
                배송 국가 추가
              </CustomButton>
            </Grid>
          </Grid> */}

					<Grid xs={12} container justifyContent={"space-between"}>
						<Grid
							item
							xs={3}
							style={{
								justifyContent: "start",
								display: "flex",
								gap: "20px",
							}}
							// padding='8px'
						>
							<CustomButton
								onClick={handleCreate}
								styles={{ width: "auto", padding: "10px" }}
							>
								배송국가 추가
							</CustomButton>
						</Grid>
						<Grid item xs={9}>
							<Grid xs={12} container>
								<Grid sx={{ marginRight: "10px" }} xs={2}>
									<CustomButton
										variant={"outlined"}
										styles={{
											color: "black",
											fontWeigh: "light",
											border: "1px solid #C4C4C4",
											height: "40px",
											borderRadius: "10px",
										}}
									>
										국가 코드
									</CustomButton>
								</Grid>

								<Grid sx={{ marginRight: "10px" }} item xs={5}>
									<TextFieldBox
										placeholder="국가 코드를  입력해주세요."
										onChange={(event) =>
											// setData(
											// 	_Data.filter((row) =>
											// 		row.code
											// 			.toLowerCase()
											// 			.includes(event.target.value.toLowerCase())
											// 	)
											// )
											setFilterQuery({ code: event.target.value.toUpperCase() })
										}
									/>
								</Grid>

								<Grid item xs={2}>
									<CustomButton
										style={{ height: "38px", borderRadius: 10 }}
										onClick={doSearch}
									>
										검색
									</CustomButton>
								</Grid>

								<Grid item xs={2}>
									{/* <SelectBox onChange={handlePageChange} value={perPage}>
                  <MenuItem value="10">10개씩 보기</MenuItem>
                  <MenuItem value="20">20개씩 보기</MenuItem>
                  <MenuItem value="30">30개씩 보기</MenuItem>
                  <MenuItem value="40">40개씩 보기</MenuItem>
                  <MenuItem value="50">50개씩 보기</MenuItem>
                  <MenuItem value="100">100개씩 보기</MenuItem>
                </SelectBox> */}
									<CustomSelect
										value={String(perPage)}
										label=""
										onChange={({ target }) => {
											handleSetPerPage(target.value);
										}}
										options={["10", "20", "30", "40", "50", "100"]}
									></CustomSelect>
								</Grid>
							</Grid>
						</Grid>
					</Grid>

					<TableContainer
						{...hocProps}
						headers={["No.", "국가 이름", "국가 코드", "화폐 단위", "활성화"]}
						data={data.map((item, index) => [
							countTotal - ((page - 1) * perPage + index),
							<div
								style={{ cursor: "pointer" }}
								onClick={() => handleActive(item, "wholeUpdate")}
							>
								{item.name}
							</div>,
							item.code,
							item.monetaryUnit,
							<Button
								onClick={() => handleActive(item)}
								color={item.isActive ? "primary" : "error"}
								variant="contained"
							>
								{item.isActive ? "활성화중" : "비활성화"}
							</Button>,
						])}
					/>
				</Stack>
			</CustomCard>

			{openDialog && (
				<ConfirmModal
					title="활성화 현황"
					description={`해당 국가 비활성화 하시겠습니까?
          `}
					onClose={handleClose}
					isOpen={openDialog && selected}
					onConfirm={handleConfirm}
				/>
			)}

			{openModal && (
				<ShippingCountriesModal
					deliveryTypes={deliveryTypes}
					isOpen={openModal}
					selected={selected}
					onClose={handleClose}
					onSuccess={handleSuccess}
					{...hocProps}
				/>
			)}
		</>
	);
};

export const ShippingCountryList = React.memo((props) => {
	return (
		<ListHOC {...props}>
			<ShippingCountryContainer />
		</ListHOC>
	);
});
