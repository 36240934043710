import * as React from "react";
import Box from "@mui/material/Box";
import { Divider } from "@mui/material";
import CustomButton from "../components/CustomButton";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 420,
	bgcolor: "background.paper",
	boxShadow: "0px 0px 5px rgba(31, 65, 115, 0.1)",
	borderRadius: "10px",
	textAlign: "center",
	p: 4,
};

const iconStyle = {
	backgroundColor: "#fef5ed",
	width: "100px",
	height: "100px",
	marginLeft: "auto",
	marginRight: "auto",
	borderRadius: "100%",
	paddingTop: "21px",
};

export function ExcelDownloadModal({ open, handleOpen, handleClose }) {
	return (
		<div>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={style}>
					<Typography
						sx={{ mt: 2 }}
						style={{ textAlign: "left", display: "flex" }}
						variant="string"
					>
						<InfoOutlinedIcon />
						<Typography
							style={{
								fontWeight: "bold",
								marginLeft: "5px",
								textAlign: "left",
							}}
							variant="string"
						>
							데이터가 없습니다.
						</Typography>
					</Typography>
					{/* <Divider /> */}
					<Box
						style={{
							textAlign: "right",
							marginTop: "20px",
						}}
					>
						<CustomButton
							width="100px"
							style={{
								borderRadius: "10px",
								marginLeft: "10px",
								backgroundColor: "#F8F8FA",
								color: "black",
							}}
							onClick={handleClose}
						>
							닫기
						</CustomButton>
					</Box>
				</Box>
			</Modal>
		</div>
	);
}
