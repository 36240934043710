import { useState } from "react";
import { useEffect } from "react";
import { useQuery } from "@apollo/client";
import { ALL_SHIPPING_COUNTRIES } from "./queries";
import BagInfoBagCreationModal from "./BagInfoBagCreationModal";

const BagInfoBagCreation = ({ open, close }) => {
  const [bagCreationDetail, setBagCreationDetail] = useState({
    ShippingCountries: [],
  });
  const { data } = useQuery(ALL_SHIPPING_COUNTRIES, {
    fetchPolicy: "cache-and-network",
  });
  useEffect(() => {
    if (data) {
      setBagCreationDetail({ ShippingCountries: data.allShippingCountries });
    }
  }, [data]);

  return (
    <BagInfoBagCreationModal
      isOpen={open}
      onClose={close}
      additionalFormDetails={bagCreationDetail}
      //   onSuccess={(data) => onSuccess(data, setFieldValue)}
      _resource={"Bag"}
      componentFor={"shipping-processing"}
    />
  );
};

export default BagInfoBagCreation;
