import { Grid, IconButton } from "@mui/material";
import { TextFieldBox } from "./CustomInput";
import React, { useRef, useState, useEffect } from "react";
import SearchIcon from "@mui/icons-material/Search";

export const SearchBox = ({
  onChange,
  width,
  defaultValue = { key: "", value: "" },
  value = { key: "", value: "" },
  dropDownValues = [{ value: "", title: "" }],
  inputFieldPlaceHolder = "",
  showSearchIcon = true,
  setFilterQueryFunc = null,
  doSearch = null,
  ...props
}) => {
  const [filterQuery, setFilterQuery] = useState({
    key: "",
    value: "",
  });
  const prevValue = useRef();

  useEffect(() => {
    if (value || defaultValue) {
      if (value && prevValue) {
        if (prevValue.current) {
          const [key] = Object.keys(prevValue.current);
          if (key) {
            setFilterQuery({ key, value: value[key] });
          }
        }
      } else {
        setFilterQuery(defaultValue);
      }
    }
  }, [value, defaultValue]);

  const handleFilterChange = ({ target }) => {
    const { name: key, value } = target;
    setFilterQuery((prevState) => {
      return { ...prevState, [key]: value };
    });
  };

  const handleOnChange = () => {
    const finalObj = { [filterQuery.key]: filterQuery.value };
    onChange({ preValue: prevValue.current, newValue: finalObj });
    prevValue.current = finalObj;
  };
  return (
    <>
      <Grid container item alignItems={"center"}>
        <Grid item sx={{ display: "flex", width: "100%" }}>
          <TextFieldBox
            onChange={setFilterQueryFunc ? (e) => setFilterQueryFunc(e.target.value) : handleFilterChange}
            onKeyDown={(event) => {
              if (event.keyCode === 13) {
                if (setFilterQueryFunc) {
                  doSearch();
                }
                else {
                  handleOnChange();
                } 
              }
            }}
            name="value"
            value={setFilterQueryFunc ? null : filterQuery.value}
            placeholder={
              inputFieldPlaceHolder ||
              `${
                dropDownValues.find((res) => res.value === filterQuery.key)
                  ?.title || "업체명"
              } 입력해주세요`
            }
          />{" "}
          {showSearchIcon && (
            <IconButton
              variant={"contained"}
              onClick={handleOnChange}
              sx={{ ml: 1 }}
              style={{
                position: "relative",
                left: "-46px",
              }}
            >
              <SearchIcon />
            </IconButton>
          )}
        </Grid>
      </Grid>
    </>
  );
};
