import React, { createContext, useEffect, useState } from "react";
import { useUtils } from "../../utils/utils";

export const AuthContext = createContext({
  user: null,
  balance: null,
  setBalance: (balance) => {},
});

export const AuthContextProvider = ({ children: Children, props }) => {
  const [balance, setBalance] = useState(null);
  const { userData } = useUtils();

  useEffect(() =>{
    if (userData){
      const balance= userData.balance
      if (balance !== undefined){
        setBalance(balance)
      }
    }
  },[userData])


  return (
    <AuthContext.Provider
      value={{
        user: userData,
        balance,
        setBalance,
      }}
    >
      {React.cloneElement(Children, { ...props })}
    </AuthContext.Provider>
  );
};
