import * as React from "react";
import { Box, Grid } from "@mui/material";
import Stepper from "@mui/material/Stepper";
import { useState } from "react";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CustomInputLabel from "./CustomInputLabel";
import CustomPasswordInput from "./CustomPasswordInput";
import { Link } from "ra-ui-materialui";
import { Formik, Form, yupToFormErrors } from "formik";
import * as Yup from "yup";
import OutlinedInput from "@mui/material/OutlinedInput";
import CheckCircleSharpIcon from "@mui/icons-material/CheckCircleSharp";
import Images from "../Common/Images";
import OtpComp from "./OTP";
import { CircularProgress } from "@mui/material";
import CustomInput from "./CustomInput";
// import OtpInput from 'react-otp-input';
import OTPInput, { ResendOTP } from "otp-input-react";

const steps = ["1단계", "2단계", "3단계"];

export default function HorizontalNonLinearStepper({ changeForm, showForm }) {
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});
  const [loading, setLoading] = useState(false);
  const [otpError, setOtpError] = useState("");
  var otp = "";
  const handleChangeOTP = (value) => {
    console.log("value insdie handle change", value);
    if (value.length == 5) {
      console.log("inhere");
      otp = value;
    }
    // setOTP(value)
  };

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  const handleSubmit = async (values, form) => {
    console.log("Submit", values);
    // form.setTouched({ ...form.touched, [field.name]: true });
    console.log(form);

    try {
    } catch (e) {
      console.log(e);
    }
  };

  const [initialValues, setInitialValues] = useState({
    password: "",
    confirmPassword: "",
    email: "",
  });
  const validationSchema = () => {
    return Yup.object().shape({
      password: Yup.string().min(6).required(),
      confirmPassword: Yup.string()
        .min(6)
        .oneOf([Yup.ref("password"), null], "Password must match")
        .required(),

      email: Yup.string().email().required("이메일 주소를 입력해주세요."),
    });
  };

  const Step1 = ({ changeForm, handleNext, errors, handleChange, values }) => {
    const onChange = (value) => {
      // setFirstStepValue({
      //   ...firstStepValue,
      //   id: value,
      // });

      console.log("hiiii");
    };

    return (
      <Box>
        <Box>
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
              isValid,
            }) => {
              return (
                <Form>
                  <Box
                    display={"flex"}
                    flexDirection={"column"}
                    sx={{
                      marginTop: "60px",
                      justifyContent: "left",
                      marginLeft: 0,
                      paddingLeft: 0,

                      width: "100%",

                      overflow: "auto",
                    }}
                  >
                    <CustomInputLabel name="이메일 주소" />
                    <CustomInput
                      labelColor=""
                      name={"email"}
                      type={"email"}
                      required
                      onChange={handleChange}
                      onBlur={handleBlur}
                      values={values.email}
                      // error={errors.email}
                      placeholder="이메일 주소를 입력해주세요. "
                      sx={{ width: "850px" }}
                    />
                    <p style={{ fontSize: "12px", color: "red" }}>
                      {errors.email != undefined && "정확한 이메일 주소를 입력하세요."}
                    </p>
                  </Box>
                  <Box
                    xs={12}
                    md={6}
                    sm={12}
                    display="flex"
                    flexDirection="row"
                    sx={{ width: "100%" }}
                    justifyContent="center"
                  >
                    <Button
                      variant="contained"
                      // type={"submit"}
                      onClick={() => {
                        if (values.email != "" && errors.email == undefined) {
                          console.log("in here");
                          handleNext();
                        }
                      }}
                      sx={{
                        paddingTop: "12px",
                        paddingBottom: "12px",
                        marginTop: "51px",
                        backgroundColor: "#427DF3",
                        borderRadius: "8px",
                        width: "350px",
                        height: "60px",
                      }}
                    >
                      {loading ? (
                        <CircularProgress size={20} color="primary" />
                      ) : (
                        <Typography
                          component="p"
                          variant="p"
                          sx={{
                            fontSize: "16px",
                            fontWeight: "700",
                            lineHeight: "24px",
                            color: "#ffffff",
                          }}
                        >
                          다음
                        </Typography>
                      )}
                    </Button>
                  </Box>
                </Form>
              );
            }}
          </Formik>
        </Box>
        <Box sx={{}}>
          <Typography
            component="p"
            variant="button"
            onClick={() => {
              changeForm("LoginForm");
              console.info("I'm a button.");
              // changeForm("SignUpForm");
            }}
            sx={{
              fontSize: "16px",
              fontWeight: "600",
              lineHeight: "20px",
              color: "#427DF3",
              marginTop: "24px",
              textAlign: "center",
            }}
          >
            {"로그인 페이지로 돌아가기"}
          </Typography>
        </Box>
      </Box>
    );
  };

  const Step2 = ({ changeForm, handleNext, errors, values, handleChange }) => {
    return (
      <Box sx={{}}>
        <Box>
          {/* <Form onSubmit={handleSubmit}> */}
          <Box
            display={"flex"}
            flexDirection={"column"}
            sx={{
              marginTop: "30px",
              justifyContent: "left",
              marginLeft: 0,
              paddingLeft: 0,
              width: "100%",
              height: "350px",
              overflow: "auto",
            }}
          >
            <Typography
              component="p"
              variant="p"
              sx={{
                paddingTop: "40px",
                fontSize: "24px",
                fontWeight: "bold",
                lineHeight: "24px",
                color: "#00000",
                textAlign: "center",
              }}
            >
              이메일로 코드 전송
            </Typography>
            <Typography
              component="p"
              variant="p"
              sx={{
                paddingTop: "20px",
                fontSize: "16px",
                fontWeight: "bold",
                lineHeight: "24px",
                color: "#9EA3AE",
                textAlign: "center",
                marginBottom: "50px",
              }}
            >
              Joel_Baumbach45@hotmail.com로 전송된 5자리 코드를 입력하세요.
            </Typography>

            <OtpComp numInputs={5} handleChange={handleChangeOTP} />

            <div
              style={{
                marginTop: "50px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                component="p"
                variant="p"
                sx={{
                  fontSize: "14px",
                  color: "#999E9F",
                }}
              >
                코드를 받지 못하셨나요?
              </Typography>
              <button
                style={{
                  marginLeft: "15px",
                  background: "#F5F8FF",
                  fontSize: "12px",
                  border: "none",
                  borderRadius: "16px",
                  color: "#427DF3",
                  padding: "8px",
                }}
              >
                다시 보내기
              </button>
            </div>
          </Box>
          <Box
            xs={12}
            md={6}
            sm={12}
            display="flex"
            flexDirection="row"
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
            justifyContent="center"
          >
            <p style={{ fontSize: "12px", color: "red" }}>{otpError}</p>

            <Button
              variant="contained"
              onClick={() => {
                console.log(otp.length);
                if (otp.length == 5) {
                  handleNext();
                } else {
                  setOtpError("이메일로 전달받으신 5자리 코드를 입력해주세요.");
                }
              }}
              sx={{
                paddingTop: "12px",
                paddingBottom: "12px",
                marginTop: "51px",
                backgroundColor: "#427DF3",
                borderRadius: "8px",
                width: "350px",
                height: "60px",
              }}
            >
              {loading ? (
                <CircularProgress size={20} color="primary" />
              ) : (
                <Typography
                  component="p"
                  variant="p"
                  sx={{
                    fontSize: "16px",
                    fontWeight: "700",
                    lineHeight: "24px",
                    color: "#ffffff",
                  }}
                >
              다음
                </Typography>
              )}
            </Button>
          </Box>
          {/* </Form> */}
        </Box>
        <Box sx={{}}>
          <Typography
            component="p"
            variant="button"
            onClick={() => {
              changeForm("LoginForm");
              console.info("I'm a button.");
              // changeForm("SignUpForm");
            }}
            sx={{
              fontSize: "16px",
              fontWeight: "600",
              lineHeight: "20px",
              color: "#427DF3",
              marginTop: "24px",
              textAlign: "center",
            }}
          >
            {"이전 페이지로 돌아가기"}
          </Typography>
        </Box>
      </Box>
    );
  };

  const Step3 = ({ changeForm, handleNext, errors, values, handleChange }) => {
    return (
      <Box sx={{}}>
        <Box component="form">
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
              isValid,
            }) => {
              return (
                <Form>
                  <Box
                    display={"flex"}
                    flexDirection={"column"}
                    sx={{
                      marginTop: "30px",
                      justifyContent: "left",
                      marginLeft: 0,
                      paddingLeft: 0,
                      width: "100%",
                      height: "350px",
                      overflow: "auto",
                    }}
                  >
                    <Typography
                      component="p"
                      variant="p"
                      sx={{
                        paddingTop: "40px",
                        fontSize: "24px",
                        fontWeight: "bold",
                        lineHeight: "24px",
                        color: "#00000",
                        textAlign: "center",
                        marginBottom: "50px",
                      }}
                    >
                      새 비밀번호 설정{" "}
                    </Typography>
                    <CustomInputLabel name="비밀번호" />
                    <CustomPasswordInput
                      name="password"
                      // error={errors.confirmPassword}
                      placeholder="새 비밀번호를 입력하세요."
                      inputValue={values.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      flex={3}

                      // onChange={onChange}
                    />
                    <p style={{ fontSize: "12px", color: "red" }}>
                      {errors.password != undefined && "비밀번호는 최소 6자리 이상으로 설정해주세요."}
                    </p>
                    <CustomInputLabel name="비밀번호 확인" />
                    <CustomPasswordInput
                      // error={errors.confirmPassword}
                      name="confirmPassword"
                      placeholder="비밀번호를 다시 한번 입력해주세요."
                      inputValue={values.confirmPassword}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <p style={{ fontSize: "12px", color: "red" }}>

                      {errors.password == undefined && errors.confirmPassword !=undefined && "비밀번호가 일치하지 않습니다."}
                    </p>
                  </Box>
                  <Box
                    xs={12}
                    md={6}
                    sm={12}
                    display="flex"
                    flexDirection="row"
                    justifyContent="center"
                    sx={{ width: "100%" }}
                  >
                    <Button
                      variant="contained"
                      onClick={() => {
                        
                        if (
                          errors.password == undefined &&
                          errors.confirmPassword == undefined
                        ) {
                          console.log("in here");
                           changeForm("Completed");
                        }
                      }}
                      sx={{
                        paddingTop: "12px",
                        paddingBottom: "12px",
                        marginTop: "51px",
                        backgroundColor: "#427DF3",
                        borderRadius: "8px",
                        width: "350px",
                        height: "60px",
                      }}
                    >
                      {loading ? (
                        <CircularProgress size={20} color="primary" />
                      ) : (
                        <Typography
                          component="p"
                          variant="p"
                          sx={{
                            fontSize: "16px",
                            fontWeight: "700",
                            lineHeight: "24px",
                            color: "#ffffff",
                          }}
                        >
                         비밀번호 설정 완료
                        </Typography>
                      )}
                    </Button>
                  </Box>
                </Form>
              );
            }}
          </Formik>
        </Box>
        <Box sx={{}}>
          <Typography
            component="p"
            variant="button"
            onClick={() => {
              changeForm("LoginForm");
              console.log("I'm a button.");
              // changeForm("SignUpForm");
            }}
            sx={{
              fontSize: "16px",
              fontWeight: "600",
              lineHeight: "20px",
              color: "#427DF3",
              marginTop: "24px",
              textAlign: "center",
            }}
          >
            {"로그인 페이지로 이동"}
          </Typography>
        </Box>
      </Box>
    );
  };

  const Completed = ({ changeForm, handleNext }) => {
    return (
      <Box sx={{}}>
        <Box component="form" onSubmit={handleSubmit}>
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
          >
            {({ values, handleChange, errors, handleSubmit }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <Box
                    display={"flex"}
                    flexDirection={"column"}
                    sx={{
                      marginTop: "30px",
                      justifyContent: "left",
                      marginLeft: 0,
                      paddingLeft: 0,
                      width: "100%",
                      height: "350px",
                      overflow: "hidden",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "24px",
                        fontWeight: "700",
                        lineHeight: "32px",
                        color: "#427DF3",
                        marginTop: "24px",
                        textAlign: "center",
                      }}
                    >
                      가입하기
                    </Typography>
                    <img
                      src={Images.AlertSuccess}
                      style={{
                        maxWidth: "183px",
                        maxHeight: "183px",
                        alignSelf: "center",
                      }}
                    />
                    <Typography
                      component="p"
                      variant="p"
                      sx={{
                        fontSize: "24px",
                        fontWeight: "700",
                        lineHeight: "31px",
                        color: "#141416",
                        marginTop: "60px",
                        alignSelf: "center",
                      }}
                    >
                      계정이 성공적으로 생성되었습니다.
                    </Typography>
                  </Box>
                  <Box
                    xs={12}
                    md={6}
                    sm={12}
                    display="flex"
                    flexDirection="row"
                    justifyContent="center"
                    sx={{ width: "100%" }}
                  >
                    <Button
                      variant="contained"
                      onClick={() => {
                        changeForm("LoginForm");
                      }}
                      sx={{
                        paddingTop: "15px",
                        paddingBottom: "15px",
                        marginTop: "51px",
                        backgroundColor: "#427DF3",
                        borderRadius: "8px",
                        width: "400px",
                        height: "64px",
                      }}
                    >
                      {loading ? (
                        <CircularProgress size={20} color="primary" />
                      ) : (
                        <Typography
                          component="p"
                          variant="p"
                          sx={{
                            fontSize: "16px",
                            fontWeight: "700",
                            lineHeight: "24px",
                            color: "#ffffff",
                          }}
                        >
                          대시보드로 이동
                        </Typography>
                      )}
                    </Button>
                  </Box>
                </Form>
              );
            }}
          </Formik>
        </Box>
      </Box>
    );
  };

  const Connector = () => {
    return (
      <Typography sx={{ paddingBottom: "25px" }}>
        ...................
      </Typography>
    );
  };

  return (
    <box>
      <Box
        sx={{
          maxWidth: "478px",
          paddingRight: 12,
          paddingLeft: 12,
        }}
        component="form"
        noValidate
        onSubmit={handleSubmit}
      >
        <Typography
          sx={{
            fontSize: "24px",
            fontWeight: "700",
            lineHeight: "32px",
            color: "#427DF3",
            marginTop: "24px",
            textAlign: "center",
          }}
        >
          비밀번호 찾기
        </Typography>
        <Stepper
          activeStep={activeStep}
          connector={<Connector />}
          sx={{
            alignContent: "center",
            justifyContent: "center",
          }}
        >
          {steps.map((label, index) => (
            <Step key={label} completed={completed[index]}>
              <br />
              <StepButton
                color="inherit"
                onClick={handleStep(index)}
              ></StepButton>
              <Typography
                sx={{
                  fontSize: "12px",
                  fontWeight: "600",

                  textAlign: "center",
                  marginTop: "16px",
                }}
              >
                {label}
              </Typography>
            </Step>
          ))}
        </Stepper>
        <div>
          {allStepsCompleted() ? (
            <React.Fragment>
              <Typography sx={{ mt: 2, mb: 1 }}>
                All steps completed - you&apos;re finished
              </Typography>
              <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <Box sx={{ flex: "1 1 auto" }} />
                <Button onClick={handleReset}>Reset</Button>
              </Box>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
              >
                {({ values, handleChange, errors, handleSubmit, Formik }) => {
                  return (
                    <Box>
                      {activeStep === 0 ? (
                        <Step1
                          changeForm={changeForm}
                          handleNext={handleNext}
                          values={values}
                          errors={errors}
                        />
                      ) : activeStep === 1 ? (
                        <Step2
                          changeForm={changeForm}
                          handleNext={handleNext}
                          values={values}
                          errors={errors}
                        />
                      ) : activeStep === 2 ? (
                        <Step3
                          changeForm={changeForm}
                          handleNext={handleComplete}
                          values={values}
                          errors={errors}
                        />
                      ) : null}
                    </Box>
                  );
                }}
              </Formik>
            </React.Fragment>
          )}
        </div>
      </Box>
    </box>
  );
}
