// createMuiTheme

import { createTheme, ThemeProvider } from "@mui/material";
import * as React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import DeliveryTracking from "./pages/clients/tracking";
import Signup from "./pages/clients/Signup";
import ReactAdmin from "./resources/ReactAdmin";
import ErrorBoundary from "./Common/ErrorBoundries";
import { ApolloProvider } from '@apollo/client';
import {APIClient} from "./utils/apiGraphql";

export const themeOptions = createTheme({
  typography: {
    fontFamily: ["Spoqa Han Sans Neo, sans-serif"].join(","),
  },
  palette: {
    type: "light",
    primary: {
      main: "#5B8FF7",
    },
    red: {
      main: "#D5495A",
    },
    secondary: {
      main: "#FFD600",
    },
    background: {
      default: "#fff",
    },
  },
});

function App() {
  return (
      <ApolloProvider client={APIClient}>
    <ErrorBoundary>
      <BrowserRouter>
        <ThemeProvider theme={themeOptions}>
          <ToastContainer />
          <Routes>
            <Route
              path={"/tracking"}
              exact
              element={<DeliveryTracking />}
            ></Route>
            <Route path={"/signup"} exact element={<Signup />}></Route>
            <Route path={"/*"} element={<ReactAdmin />}></Route>
          </Routes>
        </ThemeProvider>
      </BrowserRouter>
    </ErrorBoundary>
      </ApolloProvider>
  );
}

export default App;
