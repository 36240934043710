import { Typography } from "@mui/material";
import React from "react";
import Button from "@mui/material/Button";
import AttachFileIcon from "@mui/icons-material/AttachFile";

export default function CustomFileUpload({
	children,
	label,
	fileName,
	placeholder,
	labelColor = "#F7F7F7",
	padding = "10px 20px",
	labelWidth = "150px",
	labelHeight = "auto",
	type = "text",
	defaultValue = "",
	iconStart = null,
	iconEnd = null,
	required,
	onChange,
	...props
}) {
	// console.log({ fileName });
	return (
		<div className={"custom-component-container"}>
			{label && (
				<div
					style={{
						height: labelHeight,
						width: labelWidth,
						background: labelColor,
						display: "flex",
						alignItems: "center",
						padding: padding,
						borderRight:
							"1px solid " + labelColor !== "" ? "#E5E5E5" : "transparent",
					}}
				>
					<Typography
						sx={{ padding: "1px" }}
						fontSize={"1rem"}
						component={"span"}
					>
						{label}
						{required && <span style={{ color: "red" }}>*</span>}
					</Typography>
				</div>
			)}
			<div style={{ width: "100%", display: "flex", alignItems: "center" }}>
				<div
					style={{
						minHeight: 60,
						display: "flex",
						alignItems: "center",
						width: "100%",
					}}
				>
					<Button
						variant="contained"
						component="label"
						style={{
							color: "#5B8FF7",
							backgroundColor: "white",
							boxShadow: "none",
							borderRadius: "10px",
							height: "40px",
							marginLeft: "5px",
							width: "110px",
							whiteSpace: "nowrap",
						}}
					>
						<AttachFileIcon />
						파일 선택
						<input type="file" hidden onChange={onChange} />
					</Button>
					{fileName && (
						<p
							style={{ color: "#8b93b5", fontSize: "12px", marginLeft: "10px" }}
						>
							{fileName}
						</p>
					)}
				</div>
			</div>
		</div>
	);
}
