import React from "react";
import { Page, View, Image } from "@react-pdf/renderer";
import PackageTable from "./PackageTable";

export default function ImagePrint({ src, ...props }) {
  const { selectedRequests } = props;
  let { tableData } = selectedRequests;
  return (
    <>
      {tableData && <PackageTable tableData={tableData} />}
      <Page>
        {/*<View>*/}
          <Image src={src} />
        {/*</View>*/}
      </Page>
    </>
  );
}
