import { pdf } from "@react-pdf/renderer";
import { useCallback } from "react";
import BagLabel1 from "../components/labels/BagLabel1";
import NLPostBag from "../components/labels/NLPostBag";

export const useBagLabel = () => {
  const generateNow = useCallback(async (props) => {
    const getLabel = () => {
      switch (props.countryCode) {
        case "NL": {
          return <NLPostBag {...props} />;
        }
        default: {
          return <BagLabel1 {...props} />;
        }
      }
    };
    const blob = await pdf(getLabel()).toBlob();

    var blobURL = window.URL.createObjectURL(blob);
    let iframe = document.createElement("iframe"); //load content in an iframe to print later
    document.body.appendChild(iframe);
    iframe.style.display = "none";
    iframe.src = blobURL;
    iframe.onload = function () {
      setTimeout(function () {
        iframe.focus();
        iframe.contentWindow.print();
      }, 1);
    };
  }, []);
  return generateNow;
};
