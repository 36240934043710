import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useGetList } from "../utils/utils";
import Toaster from "../utils/Toaster";
import { APIClient } from "../utils/apiGraphql";
import { STATS_BY_YEAR } from "../utils/constants";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontWeight: "bold",
    backgroundColor: "#F8F8FA",
    color: "rgba(0, 0, 0, 0.6)",
  },
  [`&.${tableCellClasses.body}`]: {
    backgroundColor: "white",
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export const MonthlyDataTable = () => {
  const [rows, setRows] = React.useState([]);

  React.useEffect(() => {
    (async () => {
      try {
        const { data } = await APIClient.query({
          query: STATS_BY_YEAR,
        });
        const { allStatsByYear } = data;
        const reversed = [...allStatsByYear.data].reverse();
        setRows(reversed);
      } catch (e) {
        console.error(e);
        Toaster("error", e.message);
      }
    })();
  }, []);
  return (
    <TableContainer component={Paper} style={{ borderRadius: "10px" }}>
      <Table
        sx={{ minWidth: 700, border: "1px solid rgba(224, 224, 224, 1)" }}
        aria-label="customized table"
      >
        <TableHead>
          <TableRow>
            <StyledTableCell align="center">날짜</StyledTableCell>
            <StyledTableCell align="center">데이터업로드</StyledTableCell>
            <StyledTableCell align="center">데이터 삭제 건</StyledTableCell>
            <StyledTableCell align="center">보류 건</StyledTableCell>
            <StyledTableCell align="center">입고 완료</StyledTableCell>
            <StyledTableCell align="center">출고 완료</StyledTableCell>
            <StyledTableCell align="center">수출신고건</StyledTableCell>
            <StyledTableCell align="center">배송 중</StyledTableCell>
            <StyledTableCell align="center">배송완료</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <StyledTableRow key={index}>
              <StyledTableCell align="center">{row.time}</StyledTableCell>
              <StyledTableCell align="center">
                {row.totalShipping}
              </StyledTableCell>
              <StyledTableCell align="center">
                {row.deletedData}
              </StyledTableCell>
              <StyledTableCell align="center">
                {row.onHoldCount}
              </StyledTableCell>
              <StyledTableCell align="center">
                {row.warehousingCompleted}
              </StyledTableCell>
              <StyledTableCell align="center">
                {row.startedDelivery}
              </StyledTableCell>
              <StyledTableCell align="center">
                {row.exportReport}
              </StyledTableCell>
              <StyledTableCell align="center">
                {row.startedDelivery}
              </StyledTableCell>
              <StyledTableCell align="center">
                {row.completedDelivery}
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
