import { Box, Divider, Modal, Stack, Typography } from "@mui/material";
import React from "react";
import CustomButton from "../components/CustomButton";
import CustomCard from "../components/CustomCard";
import Icons from "../utils/icon";
import DeliveryTracking from "../pages/admin/DeliveryRequest/DeliveryTracking";
export default function TrackingModal({
	isOpen,
	onClose,
	shipmentTrackingData,
	onConfirm,
	isLoading = false,
	title = "",
	description = "",
	buttonText = "",
	warningIcon,
}) {
	return (
		<Modal
			open={isOpen}
			onClose={onClose}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Box
				style={{
					padding: 20,
					overflowX: "hidden",
					minHeight: "90vh",
					width: "80%",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					background: "white",
					margin: "auto",
				}}
			>
				<DeliveryTracking noBack={true} shipmentTrackingData={shipmentTrackingData} />
			</Box>
		</Modal>
	);
}
