import { Close, MoreHoriz } from "@mui/icons-material";
import {
  Checkbox,
  Divider,
  Drawer,
  Grid,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Pagination,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomButton from "../../components/CustomButton";
import { SelectBox } from "../../components/CustomInput";
import CustomPopover from "../../components/CustomPopover";
import TableContainer from "../../components/TableContainer";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import { styled } from "@mui/system";
import ListHOC from "../../SharedComponents/ListHOC";
import { Form, Formik } from "formik";
import Moment from "moment";
import { generateSearchData, handleOtherSearch } from "../../utils/utils";
import DateRangePicker from "../../components/DateRangePicker";
import { useCallback } from "react";
import { onDropDownSearchChange } from "../../utils/onDropDownSearchChange";
import DropdownAndSearchInput from "../../components/DropdownAndSearchInput";
import ConfirmModal from "../../modals/ConfirmModal";
import Toaster from "../../utils/Toaster";
import { gql } from "@apollo/client";
import { APIClient } from "../../utils/apiGraphql";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

export default function BagDrawer(props) {
  const { selectedBag } = props;

  return (
    <ListHOC
      pagination={false}
      _resource={"RequestShipping"}
      showFilter={{ bag: selectedBag.id }}
      {...props}
    >
      <BagDrawerContainer />
    </ListHOC>
  );
}

function BagDrawerContainer({
  data: _Data,
  handleSetPerPage,
  perPage,
  page,
  count,
  handlePageChange,
  handleSearch,
  isOpen,
  onClose,
  selectedBag,
  isLoading,
  setData: _setData,
  ...hocProps
}) {
  const [data, setData] = useState([]);
  const [filterQuery, setFilterQuery] = useState(null);
  const [confirmModal, setConfirmModal] = useState(false);
  const [selectedDeleteId, setSelectedDeleteId] = useState(null);
  useEffect(() => {
    setData(_Data);
  }, [_Data]);

  const doSearch = useCallback(() => {
    generateSearchData(handleSearch, filterQuery);
  }, [filterQuery]);

  useEffect(() => {
    doSearch();
  }, [filterQuery]);

  const handleClose = () => {
    setConfirmModal(false);
    setSelectedDeleteId(null);
  };

  const handleDelete = async () => {
    const Mutation = gql(`
      mutation deleteFromBag($id: Float!) {
        deleteFromBag(id: $id) {
          id
          bag {
            id
          }
        }
      }
    `);

    try {
      const { data } = await APIClient.mutate({
        mutation: Mutation,
        variables: { id: selectedDeleteId },
      });
      const { id } = data.deleteFromBag;
      _setData((prevState) => {
        if (prevState) {
          return { data: prevState.data.filter((res) => res.id !== id) };
        } else {
          return prevState;
        }
      });
      setSelectedDeleteId(null);
      setConfirmModal(false);
    } catch (e) {
      Toaster("error", e.message);
    }
  };

  return (
    <Drawer elevation={0} anchor="right" open={isOpen}>
      <DrawerHeader>
        <Typography
          sx={{ flexGrow: 1, padding: "0 20px" }}
          variant="h6"
          noWrap
          component="div"
        >
          {selectedBag.name}
        </Typography>

        <IconButton onClick={onClose}>
          <Close />
        </IconButton>
      </DrawerHeader>

      <div style={{ width: 740, padding: 20 }}>
        <Stack spacing={"35px"}>
          <Grid container justifyContent={"space-between"}>
            <Grid item xs={7}>
              <Grid container alignItems={"center"} spacing="10px">
                <Grid item xs={3}>
                  <CustomPopover>
                    {({ close }) => {
                      return (
                        <Formik
                          enableReinitialize
                          initialValues={
                            filterQuery || {
                              createdAt: `${Moment().format(
                                "YYYY-MM-DD"
                              )} - ${Moment().format("YYYY-MM-DD")}`,
                            }
                          }
                          onSubmit={(values) => {
                            handleOtherSearch(values, setFilterQuery);
                          }}
                        >
                          {({
                            values,
                            handleSubmit,
                            setFieldValue,
                            resetForm,
                          }) => {
                            return (
                              <Form onSubmit={handleSubmit}>
                                <Stack spacing={"20px"} width={370} p="20px">
                                  <div>
                                    <DateRangePicker
                                      name={"createdAt"}
                                      value={values?.["createdAt"]}
                                      setFieldValue={setFieldValue}
                                    />
                                  </div>

                                  <Divider />

                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "flex-end",
                                      gap: "20px",
                                    }}
                                  >
                                    <CustomButton
                                      onClick={close}
                                      styles={{
                                        backgroundColor: "#F8F8FA",
                                        padding: "5px",
                                        color: "#000",
                                        width: "auto",
                                      }}
                                    >
                                      취소
                                    </CustomButton>
                                    <CustomButton
                                      variant={"outlined"}
                                      onClick={() => {
                                        resetForm();
                                      }}
                                      styles={{
                                        width: "auto",
                                        padding: "8px",
                                      }}
                                    >
                                      필터 초기화
                                    </CustomButton>
                                    <CustomButton
                                      type={"submit"}
                                      onClick={handleSubmit}
                                      styles={{ padding: "5px", width: "auto" }}
                                    >
                                      전송
                                    </CustomButton>
                                  </div>
                                </Stack>
                              </Form>
                            );
                          }}
                        </Formik>
                      );
                    }}
                  </CustomPopover>
                </Grid>
                <DropdownAndSearchInput
                  onChange={(data) =>
                    onDropDownSearchChange(data, setFilterQuery)
                  }
                  value={filterQuery}
                  defaultValue={{ key: "bag123name", value: "" }}
                  dropDownValues={[{ value: "bag123name", title: "BAG 이름" }]}
                  inputFieldPlaceHolder={"업체명을 입력해주세요."}
                />
              </Grid>
            </Grid>

            <Grid item xs={2}>
              <Grid container spacing="10px">
                <Grid item xs={12}>
                  <SelectBox onChange={handlePageChange} value={perPage}>
                    <MenuItem value="10">10개씩 보기</MenuItem>
                  </SelectBox>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <div style={{ position: "relative" }}>
            <TableContainer
              {...hocProps}
              headers={[
                <Checkbox />,
                "아니요",
                "유저아이디",
                "유저명",
                "업체명",
                "전화번호",
                "휴대폰 번호",
                "삭제",
                <IconButton variant="contained">
                  <MoreHoriz />
                </IconButton>,
              ]}
              data={data.map((item, index) => {
                const { user } = item;
                return [
                  <Checkbox />,
                  user.id,
                  <Link color="#000">{user.userID}</Link>,
                  user.username,
                  user.company_name_en,
                  user.phone_number,
                  user.mobile_number,
                  <Link
                    component={"button"}
                    onClick={() => {
                      setConfirmModal(true);
                      setSelectedDeleteId(item.id);
                    }}
                    color={"error"}
                  >
                    삭제
                  </Link>,
                  <PopupState variant="popover" popupId="demo-popup-menu">
                    {(popupState) => (
                      <React.Fragment>
                        <IconButton
                          variant="contained"
                          {...bindTrigger(popupState)}
                        >
                          <MoreHoriz />
                        </IconButton>
                        <Menu {...bindMenu(popupState)}>
                          <MenuItem onClick={popupState.close}>
                            마스터 패키징
                          </MenuItem>
                          <MenuItem onClick={popupState.close}>
                            마스터 생성 및 패키징
                          </MenuItem>
                          <MenuItem onClick={popupState.close}>
                            접수건 BAG에 담기
                          </MenuItem>
                        </Menu>
                      </React.Fragment>
                    )}
                  </PopupState>,
                ];
              })}
            />
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: 30,
            }}
          >
            <Pagination
              count={count}
              onChange={handlePageChange}
              page={page}
              color="primary"
              shape="rounded"
            />
          </div>
        </Stack>
      </div>

      {confirmModal && (
        <ConfirmModal
          isLoading={isLoading}
          isOpen={confirmModal}
          onClose={handleClose}
          onConfirm={handleDelete}
          title={"Are you sure?"}
          description={"This item is going to be deleted from the bag."}
        />
      )}
    </Drawer>
  );
}
