import pdfFonts from "pdfmake/build/vfs_fonts";
import pdfMake from "pdfmake";
import footerImage from "../../Common/assets/Images/overseasInfo.png";
import { blobToBase64 } from "../../utils/utils";
import { useState, useEffect } from "react";
// import imageToBase64 from "image-to-base64";

export function OverSeasDelivery(deliveryData, base64) {
	// console.log({ base64 });

	pdfMake.vfs = pdfFonts.pdfMake.vfs;

	pdfMake.fonts = {
		SpoqaHanSans: {
			normal:
				"https://cdn.jsdelivr.net/gh/spoqa/spoqa-han-sans@01ff0283e4f36e159ffbf744b36e16ef742da6d8/Subset/SpoqaHanSans/SpoqaHanSansLight.ttf",

			bold: "https://cdn.jsdelivr.net/gh/spoqa/spoqa-han-sans@01ff0283e4f36e159ffbf744b36e16ef742da6d8/Subset/SpoqaHanSans/SpoqaHanSansLight.ttf",

			italics:
				"https://cdn.jsdelivr.net/gh/spoqa/spoqa-han-sans@01ff0283e4f36e159ffbf744b36e16ef742da6d8/Subset/SpoqaHanSans/SpoqaHanSansLight.ttf",

			bolditalics:
				"https://cdn.jsdelivr.net/gh/spoqa/spoqa-han-sans@01ff0283e4f36e159ffbf744b36e16ef742da6d8/Subset/SpoqaHanSans/SpoqaHanSansLight.ttf",
		},
	};
	if (base64) {
		const OverSeasDeliveryLabel = {
			defaultStyle: {
				font: "SpoqaHanSans",
			},
			content: [
				{
					text: "해외배송 소포 수령증",
					style: "header",
					alignment: "center",
				},
				{
					text: "1. 제출업체 정보",
					style: "subheader",
				},
				{
					style: "tableExample",
					table: {
						widths: ["*", "*", "*", "*"],
						body: [
							[
								{ text: "사업자등록번호", style: "column" },
								{ text: deliveryData.businessLno, style: "column" },
								{ text: "법인명", style: "column" },
								{ text: deliveryData.companyName, style: "column" },
							],
							[
								{ text: "대표자 성명", style: "column" },
								{ text: deliveryData.representationName, style: "column" },
								{ text: "법인 주소", style: "column" },
								{ text: deliveryData.companyAddress, style: "column" },
							],
							[
								{ text: "조회기간", style: "column" },
								{ text: deliveryData.inquiryPeriod, style: "column" },
								{ text: "작성일자", style: "column" },
								{ text: deliveryData.date, style: "column" },
							],
						],
					},
					layout: {
						fillColor: function (rowIndex, node, columnIndex) {
							return columnIndex % 2 === 0 ? "#CCCCCC" : null;
						},
					},
				},
				{
					text: "2. 해외배송 소포 수령증",
					style: "subheader",
				},

				{
					style: "tableExample",
					table: {
						widths: ["*", "*", "*", "*", "*", "*"],
						body: [
							[
								{ text: "서비스", style: "column" },
								{ text: "배송업체", style: "column" },
								{ text: "배송국가", style: "column" },
								{ text: "접수번호", style: "column" },
								{ text: "수량", style: "column" },
								{ text: "비고", style: "column" },
							],
							[
								{ text: deliveryData.serviceName, style: "column" },
								{ text: deliveryData.carrier, style: "column" },
								{ text: deliveryData.deliveryCountry, style: "column" },
								{ text: deliveryData.receptionNo, style: "column" },
								{ text: deliveryData.quantity, style: "column" },
								{ text: deliveryData.remarks, style: "column" },
							],
							[
								{
									colSpan: 4,
									text: "조회기간 해외배송 합계",
									style: "column",
								},
								"",
								"",
								"",
								{
									text: deliveryData.totalDelivery,
									style: "column",
									fillColor: "#fff",
								},
								{
									text: "",
									style: "column",
									fillColor: "#fff",
								},
							],
						],
					},
					layout: {
						fillColor: function (rowIndex, node, columnIndex) {
							return rowIndex % 2 === 0 ? "#CCCCCC" : null;
						},
					},
				},
				{
					text: "3. 해외배송 내역서",
					style: "subheader",
				},

				{
					style: "tableExample",
					table: {
						widths: ["*", "*", "*", "*", "*", "*", "*"],
						body: [
							[
								{ text: "발행목적", style: "column" },
								{ colSpan: 6, text: "" },
								"",
								"",
								"",
								"",
								"",
							],
							[
								{ text: "서비스", style: "column" },
								{ text: "배송업체", style: "column" },
								{ text: "출발국가", style: "column" },
								{ text: "접수번호", style: "column" },
								{ text: "수량", style: "column" },
								{ text: "금액(KRW)", style: "column" },
								{ text: "비고", style: "column" },
							],
							[
								{ text: deliveryData.deliveryserviceName, style: "column" },
								{ text: deliveryData.deliverycarrier, style: "column" },
								{ text: deliveryData.deliverydepartCountry, style: "column" },
								{
									text: deliveryData.deliverydestinationCountry,
									style: "column",
								},
								{ text: deliveryData.deliveryreceptionNo, style: "column" },
								{ text: deliveryData.deliveryquantity, style: "column" },
								{ text: deliveryData.deliveryPrice, style: "column" },
							],
							[
								{
									colSpan: 4,
									text: "조회기간 해외배송 합계",
									style: "column",
								},
								"",
								"",
								"",
								{
									text: deliveryData.inquiryQuantity,
									style: "column",
									fillColor: "#fff",
								},
								{
									text: deliveryData.inquiryPrice,
									style: "column",
									fillColor: "#fff",
								},
								{ text: "", style: "column", fillColor: "#fff" },
							],
						],
					},
					layout: {
						fillColor: function (rowIndex, node, columnIndex) {
							return rowIndex % 2 !== 0 ? "#CCCCCC" : null;
						},
					},
				},

				{
					text: "상기 내역은 판매자가 H3 networks 를 이용하여 해외 배송한 내역임을 증명합니다",
					style: "CenterCol",
					background: "#CCCCCC",
					alignment: "center",
				},

				{
					style: "tableExample",
					table: {
						widths: ["*", "*", "*", "*", "*", "*"],
						body: [
							[
								{
									text: "상호(법인명)",
									style: "column",
									fillColor: "#CCCCCC",
								},
								{ text: deliveryData.companyName2, style: "column" },
								{
									text: "사업자등록번호",
									style: "column",
									fillColor: "#CCCCCC",
								},
								{ text: deliveryData.registrationName, style: "column" },
								{
									text: "성명(대표자)",
									style: "column",
									fillColor: "#CCCCCC",
								},
								{ text: deliveryData.representative, style: "column" },
							],
							[
								{
									text: "사업장 소재지",
									style: "column",
									fillColor: "#CCCCCC",
								},
								{ colSpan: 2, text: deliveryData.address, style: "column" },
								"",
								{ text: "업태(종목)", style: "column", fillColor: "#CCCCCC" },
								{
									colSpan: 2,
									text: deliveryData.businessCondition,
									style: "column",
								},
							],
							[
								{
									colSpan: 2,
									text: "비고",
									style: "column",
									fillColor: "#CCCCCC",
								},
								"",
								{ colSpan: 4, text: deliveryData.note, style: "column" },
								"",
								"",
							],
						],
					},
				},
				{
					image: base64,
					width: 200,
					style: "align",
				},
			],
			styles: {
				header: {
					fontSize: 18,
					bold: true,
					normal: true,
				},
				subheader: {
					marginTop: 16,
					fontSize: 12,
					bold: true,
				},
				quote: {
					italics: true,
				},
				small: {
					fontSize: 8,
				},
				tableExample: {
					margin: [10, 5, 0, 15],
					width: "100%",
				},
				tableHeader: {
					bold: true,
					fontSize: 10,
					color: "black",
				},
				column: {
					fontSize: 8,
					width: "25%",
				},
				CenterCol: {
					marginTop: 22,
					fontSize: 14,
					bold: true,
					padding: 50,
				},
				align: {
					margin: [330, 0],
				},
			},
		};

		return OverSeasDeliveryLabel;
	}
}
