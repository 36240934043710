import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Grid, MenuItem, Typography } from "@mui/material";
import CustomButton from "../../../../components/CustomButton";
import { SelectBox } from "../../../../components/CustomInput";
import {
  addCompanyToUserGroup,
  getAllCompanies,
  removeCompanyFromUserGroup,
} from "../queries";
import Toaster from "../../../../utils/Toaster";

function Companies({ data, usergroupId, getCompany }) {
  const [allcompanies, setAllcompanies] = useState([]);
  const [selectedcompany, setSelectedcompany] = useState({});
  const [groupcompanies, setGroupcompanies] = useState([]);

  //Set companies
  useEffect(() => {
    if (data) {
      setGroupcompanies(data);
    }

    return () => {};
  }, [data]);

  //Get companies
  useEffect(() => {
    getAllCompanies().then((res) => {
      setAllcompanies(res.data.allCompanyLists);
    });
    return () => {};
  }, []);

  //Select company
  const selectCompany = (e) => {
    setSelectedcompany(e.target.value);
  };

  //Add Company
  const addCompany = () => {
    //Checking if company is already existing
    if (groupcompanies.findIndex((el) => el.id === selectedcompany.id) !== -1)
      return Toaster("warning", "해당 업체는 그룹 내에 이미 존재합니다.");

    let pack = {
      userIds: [],
      groupId: parseInt(usergroupId),
    };
    const totalComps = [...groupcompanies, selectedcompany];
    //Making array of companyIds
    totalComps.forEach((el) => {
      pack.userIds.push(parseInt(el.id));
    });
    addCompanyToUserGroup(pack).then(
      (res) => {
        setGroupcompanies([...groupcompanies, selectedcompany]);
      },
      (err) => {
        console.log(err);
      }
    );
  };

  //Remove Company
  const removeCompany = (id) => {
    const cloneData = [...groupcompanies];
    const filteredData = cloneData.filter((el) => el.id !== id);
    removeCompanyFromUserGroup(id).then(
      (res) => {
        setGroupcompanies(filteredData);
      },
      (err) => {
        console.log(err);
      }
    );
  };

  //Send company count
  useEffect(() => {
    getCompany(groupcompanies.length);

    return () => {};
  }, [groupcompanies]);

  return (
    <>
      <Grid
        item
        xs={12}
        container
        justifyContent={"space-between"}
        alignItems={"center"}
        style={{ marginBottom: "50px" }}
      >
        <Grid item xs={10}>
          <div style={{ cursor: "pointer" }}>
            <SelectBox onChange={selectCompany}>
              {allcompanies.map((comp) => (
                <MenuItem value={comp} key={comp.id}>
                  {comp.company_name_en}
                </MenuItem>
              ))}
            </SelectBox>
          </div>
        </Grid>
        {/* </Grid> */}
        <Grid item xs={2}>
          <div
            style={{
              display: "flex",
              cursor: "pointer",
              justifyContent: "flex-end",
            }}
          >
            <CustomButton
              variant={"outlined"}
              styles={{
                width: "80%",
                color: "#427DF3",
                fontWeight: "bold",
                border: "1px solid #427DF3",
                // height: "40px",
                padding: "7px",
                borderRadius: 5,
              }}
              onClick={addCompany}
            >
              업체 추가하기
            </CustomButton>
          </div>
        </Grid>
      </Grid>

      <Grid
        item
        xs={12}
        container
        justifyContent={"space-between"}
        alignItems={"center"}
        style={{ marginBottom: "50px" }}
        spacing={4}
      >
        {groupcompanies.length > 0 &&
          groupcompanies.map((comp, index) => (
            <Grid item xs={6} key={`compId${comp.id}`}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  background: "#F5F8FE",
                  borderRadius: "10px",
                  padding: "15px",
                }}
              >
                <Typography>{index + 1}.</Typography>
                <Typography>{comp.company_name_en}</Typography>
                <StyledTypography>
                  <Typography
                    color={"#D5495A"}
                    onClick={() => removeCompany(comp.id)}
                  >
                    삭제
                  </Typography>
                </StyledTypography>
              </div>
            </Grid>
          ))}
      </Grid>
    </>
  );
}

export default Companies;

const StyledTypography = styled.div`
  cursor: pointer;
`;
