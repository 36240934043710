import writeXlsxFile from "write-excel-file";
import Moment from "moment";

//Export xlsx with multiple table in one sheet
export const exportXlx = async (row) => {
	let data = [];
	//Header
	let mainHead = [
		{
			value: "번호",
			backgroundColor: "#dbd6d6",
			align: "center",
		},
		{
			value: "업체명",
			backgroundColor: "#dbd6d6",
			align: "center",
		},
		{
			value: "입금액 (KRW)",
			backgroundColor: "#dbd6d6",
			align: "center",
		},
		{
			value: "출금액 (KRW)",
			backgroundColor: "#dbd6d6",
			align: "center",
		},
		{
			value: "잔액 (KRW)",
			backgroundColor: "#dbd6d6",
			align: "center",
		},
		{
			value: "메모",
			backgroundColor: "#dbd6d6",
			align: "center",
		},
		{
			value: "입출금처리날짜",
			backgroundColor: "#dbd6d6",
			align: "center",
		},
		{
			value: "배송번호",
			backgroundColor: "#dbd6d6",
			align: "center",
		},
		{
			value: "내용",
			backgroundColor: "#dbd6d6",
			align: "center",
		},
	];
	data.push(mainHead);

	//Processing data
	row.forEach((d, index) => {
		console.log({ d });
		let rw = [];
		let obj1 = {
			type: Number,
			value: index + 1,
		};
		rw.push(obj1);
		let obj2 = {
			type: String,
			value: d.user.company_name_kr,
		};
		rw.push(obj2);
		let obj3 = {
			type: Number,
		};
		if (d.type === "deposit") {
			obj3.value = parseInt(d.amount);
		} else {
			obj3.value = null;
		}
		rw.push(obj3);
		let obj4 = {
			type: Number,
		};
		if (d.type === "withdraw" || d.type === "deduction") {
			obj4.value = parseInt(d.amount);
		} else {
			obj4.value = null;
		}
		rw.push(obj4);
		let obj5 = {
			type: Number,
			value: parseInt(d.leftBalance),
		};
		rw.push(obj5);
		let obj6 = {
			type: String,
			value: d.memo,
		};
		rw.push(obj6);
		let obj7 = {
			type: String,
			value:
				d.deposit_processing_date &&
				Moment(d.deposit_processing_date).format("YYYY-MM-DD HH:mm"),
		};
		rw.push(obj7);
		let obj8 = {
			type: String,
			value: d.shippingFeeDeductionHistory?.shipping_number,
		};
		rw.push(obj8);
		//Reson converting to korean
		let obj9 = {
			type: String,
		};
		if (d.type === "deposit" && d.reason === "customer-fees") {
			obj9.value = "고객 요금 입금";
		} else if (d.type === "deposit" && d.reason === "reward-amount") {
			obj9.value = "보상 금액 입금";
		} else if (d.type === "deposit" && d.reason === "other") {
			obj9.value = "기타";
		} else if (d.type === "withdraw" && d.reason === "customer-fees") {
			obj9.value = "기타 서비스 사용 요금";
		} else if (d.type === "withdraw" && d.reason === "reward-amount") {
			obj9.value = "착오로 인한 입금";
		} else if (
			d.type === "deduction" &&
			d.reason === "delivery-fee-deduction"
		) {
			obj9.value = "배송접수건 출고";
		} else if (d.type === "withdraw" && d.reason === "other") {
			obj9.value = "기타";
		}
		rw.push(obj9);
		data.push(rw);
	});

	console.log({ data });
	//Columns width
	const columns = [];
	[...Array(7)].forEach((d) => {
		columns.push({
			width: 20,
		});
	});

	writeXlsxFile(data, {
		columns,
		fileName: `입출금내역 (${Moment().format("YYYY-MM-DD")}).xlsx`,
	});
};
