import PrintModal from "../../modals/PrintModal";
import { useState } from "react";
import { Button, Divider, Grid, Switch, Typography } from "@mui/material";
import CustomInput, { TextFieldBox } from "../../components/CustomInput";
import { BAG } from "./queries";
import BagInfoBagCreation from "./BagInfoBagCreation";
import {
  addBagAtom,
  apiWaybillAtom,
  priceCheckingAtom,
  nodeBagIdAtom,
  apiCallAtom,
  printAtom,
} from "./atom";
import { useAtom } from "jotai";
import BagInfoBagSelection from "./BagInfoBagSelection";
import { useQuery } from "@apollo/client";
import _ from "lodash";
import { getBagTotalWeight } from "./utils";

const BagInfo = () => {
  const [isBagCreationOpen, setIsBagCreationOpen] = useState(false);
  const bagCreationOpen = () => {
    setIsBagCreationOpen(true);
  };
  const bagCreationClose = () => {
    setIsBagCreationOpen(false);
  };
  const [isPrintOpen, setIsPrintOpen] = useState(false);
  const printOpen = () => {
    setIsPrintOpen(true);
  };
  const printClose = () => {
    setIsPrintOpen(false);
  };
  const [apiCall, setApiCall] = useAtom(apiCallAtom);
  const [print, setPrint] = useAtom(printAtom);

  const [priceCheck, setPriceCheck] = useAtom(priceCheckingAtom);
  const [addBag, setAddBag] = useAtom(addBagAtom);
  const [nodeBagId] = useAtom(nodeBagIdAtom);
  const { data } = useQuery(BAG, {
    fetchPolicy: "cache-and-network",
    variables: { id: { id: nodeBagId } },
  });

  const bag = data?.Bag || {};
  const bagTotalWeight = getBagTotalWeight({
    bagReceptionLists: bag?.receptionLists,
  });

  return (
    <>
      <Grid
        container
        style={{
          borderRadius: "10px",
          overflow: "hidden",
          border: "1px solid #E5E5E5",
        }}
      >
        <Grid item xs={12}>
          <BagInfoBagSelection />
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={6}>
          <CustomInput
            input={
              <Button
                // disabled={!(values && values?.bag && SRDetails)}
                variant="outlined"
                style={{ marginLeft: "10px" }}
                onClick={printOpen}
              >
                출력하기
              </Button>
            }
            label="BAG라벨 출력"
            padding="14px 20px 10px 20px"
            labelWidth="49%"
          />
        </Grid>
        <Grid item xs={6}>
          <CustomInput
            input={
              <Button
                onClick={bagCreationOpen}
                variant="outlined"
                style={{ marginLeft: "10px" }}
              >
                BAG 생성
              </Button>
            }
            label="BAG 생성"
            padding="14px 20px 10px 20px"
            labelWidth="55%"
          />
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={6}>
          <CustomInput
            input={
              <span style={{ marginLeft: 10 }}>
                {bag?.receptionLists?.length}
              </span>
            }
            label="현재 백에 담긴 총 접수건"
            labelWidth="49%"
            padding="13px 20px 10px 20px"
          />
        </Grid>
        <Grid item xs={6}>
          <CustomInput
            input={<span style={{ marginLeft: 10 }}>{bagTotalWeight}</span>}
            label="현재 백에 담긴 총 무게"
            labelWidth="55%"
            padding="13px 20px 10px 20px"
          />
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={12}>
          <CustomInput
            input={
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  marginLeft: "10px",
                }}
              >
                <Typography>API 요청</Typography>
                <Switch
                  onChange={({ target }) => {
                    setApiCall(target.checked);
                  }}
                  checked={apiCall}
                />
                <Typography>송장 인쇄</Typography>
                <Switch
                  onChange={({ target }) => {
                    setPrint(target.checked);
                  }}
                  checked={print}
                />

                <Typography>상품 금액 체크</Typography>
                <Switch
                  onChange={({ target }) =>
                    setPriceCheck({ ...priceCheck, isCheck: target.checked })
                  }
                  checked={priceCheck.isCheck}
                />

                <TextFieldBox
                  type="number"
                  step={"0.001"}
                  value={priceCheck.price}
                  onChange={({ target }) => {
                    setPriceCheck({
                      ...priceCheck,
                      price: target.valueAsNumber,
                    });
                  }}
                  style={{
                    width: "100px",
                  }}
                />

                <Typography>무게 변경 후 BAG 처리</Typography>
                <Switch
                  onChange={({ target }) => setAddBag(target.checked)}
                  checked={addBag}
                />
              </div>
            }
            label="설정"
            labelWidth="20%"
            padding="20px 20px 10px 20px"
          />
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>
      </Grid>
      {isBagCreationOpen ? (
        <BagInfoBagCreation open={bagCreationOpen} close={bagCreationClose} />
      ) : null}

      {isPrintOpen ? (
        <PrintModal
          isOpen={printOpen}
          onClose={printClose}
          details={{
            bagID: bag,
            bagNo: bag.bagNumber,
            bagName: bag.name,
            bag: bag,
            requestID: bag.requestID,
            countryCode: bag?.shippingCountry?.code,
          }}
          title={"BAG 라벨 출력"}
        />
      ) : null}
    </>
  );
};

export default BagInfo;
