import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer as TableContainerBox,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Loader from "./Loader";

import styled from "styled-components";
import BackupTableIcon from "@mui/icons-material/BackupTable";

export default function TableContainer({
  isLoading,
  requestCompleted,
  flipped,
  contentFlip,
  titlesShouldBeBold = false,
  data = [],
  headers = ["Hello", "World", "Welcome", "Here"],
  title = null,
}) {
  return !isLoading && requestCompleted ? (
    <TableContainerBox
      className={flipped}
      sx={{
        "&::-webkit-scrollbar": {
          width: 10,
        },
        "&::-webkit-scrollbar-track": {
          backgroundColor: "white",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#E0E0E0",
          borderRadius: 2,
        },
        // maxHeight: 700,
      }}
      style={{
        borderRadius: 10,
        overflow: "auto",
        border: "1px solid #E5E5E5",
        // overflowX: "scroll",
      }}
    >
      {title && (
        <div
          className={contentFlip}
          style={{
            textAlign: "center",
            padding: 10,
            backgroundColor: "#F8F8FA",
            borderBottom: "1px solid #E5E5E5",
          }}
        >
          <Typography variant="string" fontSize={"12px"} fontWeight={"700"}>
            {title}
          </Typography>
        </div>
      )}
      {/* exportButton={true} */}
      <Table className="Content" id="forExp">
        <TableHead
          style={{
            backgroundColor: "#F8F8FA",
            borderBottom: "1px solid #E5E5E5",
          }}
        >
          <TableRow>
            {headers.map((item, index) => (
              <TableCell
                align="center"
                style={{
                  whiteSpace: "nowrap",
                  width: (item === "No." || item === "날짜") && "120px",
                  fontWeight: titlesShouldBeBold ? "bold" : "normal",
                  borderRight:
                    index + 1 !== headers.length ? "1px solid #E5E5E5" : "none",
                }}
                key={"header" + index}
              >
                {item}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.length > 0 ? (
            data.map((row, index) => (
              <TableRow key={"row" + index}>
                {row.map((item, index) => (
                  <TableCell
                    key={"cell" + index}
                    align="center"
                    style={{
                      borderRight:
                        index + 1 !== row.length ? "1px solid #E5E5E5" : "none",
                    }}
                  >
                    {item}
                  </TableCell>
                ))}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={headers?.length < 13 ? headers?.length : 13}>
                <Empty>
                  <div>
                    <BackupTableIcon fontSize="large" />
                    <p>데이터 없음</p>
                  </div>
                </Empty>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainerBox>
  ) : (
    <Loader />
  );
}

const Empty = styled.div`
  height: 300px;
  display: grid;
  place-items: center;
  text-align: center;
  opacity: 0.6;
`;
