import {
	Divider,
	Grid,
	MenuItem,
	Pagination,
	Stack,
	Typography,
} from "@mui/material";
import { ShippingFeeHistoryTable } from "../../components/ShippingFeeHistoryTable";
import React, { useState, useEffect } from "react";

import CustomButton from "../../components/CustomButton";
import { Form, Formik } from "formik";
import { SearchBox } from "../../components/Searchbox";
import DateRangePicker from "../../components/DateRangePicker";
// import Moment from "moment";
import { handleOtherSearch } from "../../utils/utils";
import { SelectBox } from "../../components/CustomInput";
import CustomPopover from "../../components/CustomPopover";
import Loader from "../../components/Loader";
import DownloadExcelButton from "../../components/DownloadExcelButton";

// graphql
import { APIClient } from "../../utils/apiGraphql";
import { gql } from "@apollo/client/core";
import { getAllCountries } from "./RatesManagament/queries";
import Toaster from "../../utils/Toaster";
import styled from "styled-components";

// search param
import { useSearchParams } from "react-router-dom";
import moment from "moment";
import statusSwitch from "../../utils/statusSwitch";

export default function ShippingFees() {
	const [filterQuery, setFilterQuery] = useState();

	// search params
	const [searchParams] = useSearchParams();
	const [companyName, setCompanyName] = useState(
		searchParams.get("company_name_kr") || ""
	);
	const [sort, setSort] = useState({
		sortOrder: "desc",
		sortField: "createdAt",
	});

	// column name
	const column = {
		No: "id",
		업체명: "company_name",
		배송번호: "shipping_number",
		접수번호: "trackingNumber",
		배송비: "shipping_fee",
		"배송비 차감날짜": "shipping_fee_deducted_date",
		상품이름: "product_name",
		상품수량: "product_quantity",
		총상품금액: "total_product_price",
		적용무게: "applicable_weight",
		실제무게: "actual_weight",
		부피무게: "bulky_weight",
		주문번호: "orderNo1",
		배송국가: "shipping_country",
		배송종류: "delivery_type",
		배송상태: "delivery_status",
		출고날짜: "delivery_date",
	};

	// table data
	const [data, setData] = useState([]);

	// excel data
	const [excelData, setExcelData] = useState([]);

	const [dataLoad, setDataLoad] = useState(true);
	// page and pageSize
	const [page, setPage] = useState(0);
	const [pageSize, setPageSize] = useState(10);
	const [pageCount, setPageCount] = useState(1);

	// countries data
	const [countries, setCountries] = useState([]);

	// pagechange
	const handleChangePage = (event, value) => {
		setPage(value - 1);
	};

	// call for change in page and pageSize
	useEffect(() => {
		setDataLoad(true);
		// filtering query before sending data
		let filter = { ...filterQuery };

		if (filter?.createdAt) {
			let splitCreatedAt = filter.createdAt.split(" - ");
			let shipping_fee_deducted_date = {
				gte: splitCreatedAt[0],
				lte: splitCreatedAt[1],
			};
			filter.shipping_fee_deducted_date = shipping_fee_deducted_date;
		}

		if (companyName) {
			filter.company_name = companyName;
		}

		// delete useless object
		delete filter.createdAt;
		delete filter.sort;

		// delete all empty object
		Object.keys(filter).forEach((key) => {
			if (filter[key] === "") {
				delete filter[key];
			}
		});

		// with filter
		if (
			"requestShipping_deliveryStatus" in filter ||
			"requestShipping_payeeInformation_shippingCountryId" in filter ||
			"shipping_fee_deducted_date" in filter ||
			"company_name" in filter
		) {
			// for data
			getAllShippingFeesFilter(page, pageSize, sort, filter).then(
				(res) => {
					setData(res.data?.allShippingFeeHistory || []);
					setDataLoad(false);
				},
				(err) => {
					Toaster("error", err.message);
					setDataLoad(false);
				}
			);
			// for pagination
			getAllShippingFeesMetaFilter(filter).then(
				(res) => {
					// setData(res.data?.allShippingFeeHistory || []);
					setPageCount(res.data?._allShippingFeeHistoryMeta.count);
				},
				(err) => {
					Toaster("error", err.message);
				}
			);

			// for excel
			getExcelDataFilter(sort, filter).then(
				(res) => {
					let fn = res.data.allShippingFeeHistory.map((el) => ({
						...el,
						delivery_status: statusSwitch(el.delivery_status),
						orderNo1:
							el.requestShipping !== null
								? el.requestShipping.productInformation.orderNo1
								: "",
					}));
					// setExcelData(fn || []);
					makeExcelData(fn || []);
				},
				(err) => {
					Toaster("error", err.message);
				}
			);
		} else {
			// for data without filter
			getAllShippingFees(page, pageSize, sort).then(
				(res) => {
					setData(res.data?.allShippingFeeHistory || []);
					setPageCount(res.data?.total?.count);
					setDataLoad(false);
				},
				(err) => {
					Toaster("error", err.message);
					setDataLoad(false);
				}
			);

			// for pagination
			getAllShippingFeesMeta(page, pageSize).then(
				(res) => {
					// setData(res.data?.allShippingFeeHistory || []);
					setPageCount(res.data?._allShippingFeeHistoryMeta.count);
				},
				(err) => {
					Toaster("error", err.message);
				}
			);

			// for excel data
			getExcelData(sort).then(
				(res) => {
					//Korean word for status
					let fn = res.data.allShippingFeeHistory.map((el) => ({
						...el,
						delivery_status: statusSwitch(el.delivery_status),
						orderNo1:
							el.requestShipping !== null
								? el.requestShipping.productInformation.orderNo1
								: "",
					}));
					// setExcelData(fn || []);
					makeExcelData(fn || []);
					// setDataLoad(false);
				},
				(err) => {
					Toaster("error", err.message);
					// setDataLoad(false);
				}
			);
		}

		return () => {};
	}, [page, pageSize, filterQuery, sort, companyName]);

	// for once need to call

	useEffect(() => {
		getAllCountries().then((res) => {
			setCountries(res.data.allShippingCountries);
		});

		return () => {};
	}, []);

	const makeExcelData = (item1) => {
		// console.log({ item1 });
		let arr = [];
		item1.forEach((item, index) => {
			const data1 = {
				No: pageCount - (page * pageSize - index),
				업체명: item.company_name,
				배송번호: item.shipping_number,
				접수번호: item.trackingNumber,
				배송비: item.shipping_fee,
				"배송비 차감날짜": item.shipping_fee_deducted_date,
				상품이름: item.product_name,
				상품수량: item.product_quantity,
				총상품금액: item.total_product_price,
				적용무게: item.applicable_weight,
				실제무게: item.actual_weight,
				부피무게: item.bulky_weight,
				주문번호: item.orderNo1,
				배송국가: item.shipping_country,
				배송종류: item.delivery_type,
				배송상태: item.delivery_status,
				출고날짜: item.delivery_date,
			};
			arr.push(data1);
		});
		setExcelData(arr);
	};

	// form submit
	const onSubmit = (values) => {
		handleOtherSearch(values, setFilterQuery);
		// for sort value
		switch (values?.sort) {
			case 2:
				setSort({ sortOrder: "desc", sortField: "trackingNumber" });
				break;
			case 3:
				setSort({
					sortOrder: "asc",
					sortField: "requestShipping95productInformation95orderNo1",
				});
				break;
			case 4:
				setSort({
					sortOrder: "desc",
					sortField: "requestShipping95productInformation95orderNo1",
				});
				break;
			default:
				setSort({ sortOrder: "asc", sortField: "trackingNumber" });
			// code block
		}

		setPage(0);
	};

	// company serach submit
	const companySubmit = (e) => {
		e.preventDefault();
		setCompanyName(e.target.querySelector("input[type=text]").value);
		setPage(0);
	};

	let initialFil = {
		createdAt: "",
		sort: 1,
		requestShipping_status: "",
		requestShipping_payeeInformation_shippingCountryId: "",
	};

	// console.log({ excelData });
	return (
		<Stack spacing={"35px"}>
			<Grid container justifyContent={"space-between"}>
				<Grid item xs={6}>
					<Grid
						container
						style={{
							display: "flex",
						}}
					>
						<Grid item xs={2} style={{ margin: "auto 10px" }}>
							<CustomPopover>
								{({ close }) => {
									return (
										<Formik
											enableReinitialize
											initialValues={initialFil}
											onSubmit={(values) => {
												onSubmit(values);
												close();
											}}
										>
											{({
												values,
												handleSubmit,
												setFieldValue,
												resetForm,
												handleChange,
											}) => {
												return (
													<Form onSubmit={handleSubmit}>
														<Stack spacing={"20px"} width={370} p="20px">
															{/* date range */}
															<div className={"custom-component-container"}>
																<div
																	style={{
																		display: "flex",
																		alignItems: "center",
																		width: "140px",
																	}}
																>
																	<Typography
																		sx={{ padding: "1px" }}
																		fontSize={"1rem"}
																		component={"span"}
																	>
																		출고일
																	</Typography>
																</div>

																<div
																	style={{
																		width: "100%",
																		display: "flex",
																		alignItems: "center",
																	}}
																>
																	<div
																		style={{
																			minHeight: 60,
																			display: "flex",
																			alignItems: "center",
																			width: "100%",
																		}}
																	>
																		<DateRangePicker
																			// customLabel
																			style={{
																				width: "100%",
																			}}
																			showLabel={false}
																			name={"createdAt"}
																			value={values?.["createdAt"]}
																			setFieldValue={setFieldValue}
																		/>
																	</div>
																</div>
															</div>

															{/* country */}
															<div className={"custom-component-container"}>
																<div
																	style={{
																		display: "flex",
																		alignItems: "center",
																		width: "140px",
																	}}
																>
																	<Typography
																		sx={{ padding: "1px" }}
																		fontSize={"1rem"}
																		component={"span"}
																	>
																		배송국가
																	</Typography>
																</div>

																<div
																	style={{
																		width: "100%",
																		display: "flex",
																		alignItems: "center",
																	}}
																>
																	<div
																		style={{
																			minHeight: 60,
																			display: "flex",
																			alignItems: "center",
																			width: "100%",
																		}}
																	>
																		<SelectBox
																			value={
																				values.requestShipping_payeeInformation_shippingCountryId ||
																				"default"
																			}
																			onChange={handleChange}
																			name="requestShipping_payeeInformation_shippingCountryId"
																		>
																			<MenuItem value="" hidden disabled>
																				배송국가
																			</MenuItem>
																			{countries
																				.filter((el) => el.isActive)
																				.map((country) => (
																					<MenuItem
																						key={country.id}
																						value={country.id}
																					>
																						{country.name}
																					</MenuItem>
																				))}
																		</SelectBox>
																	</div>
																</div>
															</div>

															{/* delivery status */}
															<div className={"custom-component-container"}>
																<div
																	style={{
																		display: "flex",
																		alignItems: "center",
																		width: "140px",
																	}}
																>
																	<Typography
																		sx={{ padding: "1px" }}
																		fontSize={"1rem"}
																		component={"span"}
																	>
																		배송상태
																	</Typography>
																</div>

																<div
																	style={{
																		width: "100%",
																		display: "flex",
																		alignItems: "center",
																	}}
																>
																	<div
																		style={{
																			minHeight: 60,
																			// padding: "10px",
																			display: "flex",
																			alignItems: "center",
																			width: "100%",
																		}}
																	>
																		<SelectBox
																			value={values.requestShipping_status}
																			onChange={handleChange}
																			name="requestShipping_status"
																		>
																			<MenuItem value="" disabled>
																				배송상태
																			</MenuItem>
																			<MenuItem value="waiting_warehousing">
																				입고대기
																			</MenuItem>
																			<MenuItem value="warehousing_completed">
																				입고완료
																			</MenuItem>
																			<MenuItem value="waiting_delivery">
																				출고대기
																			</MenuItem>
																			<MenuItem value="started_delivery">
																				출고완료
																			</MenuItem>
																			<MenuItem value="completed_delivery">
																				배송완료
																			</MenuItem>
																		</SelectBox>
																	</div>
																</div>
															</div>

															{/* order */}
															<div className={"custom-component-container"}>
																<div
																	style={{
																		display: "flex",
																		alignItems: "center",
																		width: "140px",
																	}}
																>
																	<Typography
																		sx={{ padding: "1px" }}
																		fontSize={"1rem"}
																		component={"span"}
																	>
																		정렬
																	</Typography>
																</div>

																<div
																	style={{
																		width: "100%",
																		display: "flex",
																		alignItems: "center",
																	}}
																>
																	<div
																		style={{
																			minHeight: 60,
																			display: "flex",
																			alignItems: "center",
																			width: "100%",
																		}}
																	>
																		<SelectBox
																			name="sort"
																			value={values.sort}
																			onChange={handleChange}
																		>
																			<MenuItem value="" disabled>
																				정렬
																			</MenuItem>
																			<MenuItem value={1}>
																				접수번호 오름차순
																			</MenuItem>
																			<MenuItem value={2}>
																				접수번호 내림차순
																			</MenuItem>
																			<MenuItem value={3}>
																				주문번호 오름차순
																			</MenuItem>
																			<MenuItem value={4}>
																				주문번호 내림차순
																			</MenuItem>
																		</SelectBox>
																	</div>
																</div>
															</div>

															<Divider />

															<div
																style={{
																	display: "flex",
																	justifyContent: "flex-end",
																	gap: "20px",
																}}
															>
																<CustomButton
																	onClick={close}
																	styles={{
																		backgroundColor: "#F8F8FA",
																		padding: "5px",
																		color: "#000",
																		width: "auto",
																	}}
																>
																	취소
																</CustomButton>
																<CustomButton
																	variant={"outlined"}
																	onClick={() => {
																		resetForm(initialFil);
																		handleSubmit();
																	}}
																	styles={{
																		width: "auto",
																		padding: "8px",
																	}}
																>
																	필터 초기화
																</CustomButton>
																<CustomButton
																	type={"submit"}
																	onClick={handleSubmit}
																	styles={{ padding: "5px", width: "auto" }}
																>
																	전송
																</CustomButton>
															</div>
														</Stack>
													</Form>
												);
											}}
										</Formik>
									);
								}}
							</CustomPopover>
						</Grid>

						{!searchParams.get("company_name_kr") && (
							<Grid item xs={2} style={{ margin: "auto 10px" }}>
								<SelectBox value="업체명" defaultValue={"업체명"}>
									<MenuItem value="업체명">업체명</MenuItem>
								</SelectBox>
							</Grid>
						)}

						{!searchParams.get("company_name_kr") && (
							<Grid item xs={5} style={{ margin: "auto 10px" }}>
								<form
									style={{
										display: "flex",
										height: "100%",
										justifyContent: "flex-start",
									}}
									onSubmit={companySubmit}
								>
									<ActionLessIcon>
										<SearchBox
											showSearchIcon={false}
											inputFieldPlaceHolder="업체명을 입력해주세요."
											onChange={(data) => {}}
											defaultValue={{ key: "name", value: "업체명" }}
											dropDownValues={[{ value: "name", title: "가방 이름" }]}
										/>
									</ActionLessIcon>
									<CustomButton
										style={{
											height: "38px",
											borderRadius: 10,
											marginLeft: "10px",
										}}
										type="submit"
									>
										검색
									</CustomButton>
								</form>
							</Grid>
						)}
					</Grid>
				</Grid>
				<Grid
					item
					xs={6}
					style={{
						display: "flex",
						justifyContent: "end",
					}}
				>
					<Grid item xs={3} style={{ margin: "auto 10px" }}>
						{excelData.length > 0 && (
							<DownloadExcelButton
								excelData={excelData}
								columnsName={column}
								fileName={`배송비 차감 내역 ${moment().format("YYYY-MM-DD")}`}
							/>
						)}
					</Grid>
					<Grid item xs={3} style={{ margin: "auto 10px" }}>
						<SelectBox
							value={pageSize}
							defaultValue={"10"}
							onChange={(e) => {
								setPageSize(e.target.value);
								setPage(0);
							}}
						>
							<MenuItem value={10}>10개씩 보기</MenuItem>
							<MenuItem value={20}>20개씩 보기</MenuItem>
							<MenuItem value={30}>30개씩 보기</MenuItem>
							<MenuItem value={40}>40개씩 보기</MenuItem>
							<MenuItem value={50}>50개씩 보기</MenuItem>
							<MenuItem value={100}>100개씩 보기</MenuItem>
						</SelectBox>
					</Grid>
				</Grid>
			</Grid>

			{/* tables */}
			{dataLoad ? (
				<Loader />
			) : (
				<>
					{" "}
					<ShippingFeeHistoryTable
						data={data}
						count={
							pageSize > pageCount ? pageCount - page * pageSize : pageCount
						}
						page={page}
						pageSize={pageSize}
					/>
					<div
						style={{
							display: "flex",
							justifyContent: "center",
							marginTop: 30,
						}}
					>
						<Pagination
							count={Math.ceil(pageCount / pageSize) || 1}
							color="primary"
							shape="rounded"
							page={page + 1}
							onChange={handleChangePage}
						/>
					</div>
				</>
			)}
		</Stack>
	);
}

// data without filter
const getAllShippingFees = async (page, pageSize, sort) => {
	const queryAllShippingFeeHistory = gql(`query($page: Float!
    $perPage: Float!
    $sortOrder: SortOrder
    $sortField: SortField
  ) {
    allShippingFeeHistory(page: $page
      perPage : $perPage
      sortOrder: $sortOrder
      sortField: $sortField
      ){
      id
    order_number
    company_name
    shipping_number
    registration_number
    shipping_fee
    shipping_fee_deducted_date
    product_name
    total_product_price
    product_quantity
    actual_weight
    bulky_weight
    shipping_country
    delivery_type
    delivery_status
    delivery_date
    applicable_weight
    trackingNumber
    requestShipping{
      productInformation{
        orderNo1
        orderNo2
      }
    }
    }
		total: _allShippingFeeHistoryMeta(page: $page
      perPage : $perPage
      sortOrder: $sortOrder
      sortField: $sortField) {
			count
			__typename
		}
  }
    `);

	try {
		const res = await APIClient.query({
			query: queryAllShippingFeeHistory,
			variables: {
				page: page || 0,
				perPage: pageSize || 10,
				sortOrder: {
					sortOrder: sort.sortOrder,
				},
				sortField: {
					sortField: sort.sortField,
				},
			},
		});
		return res;
	} catch (e) {
		return e;
	}
};

// data withFilter
const getAllShippingFeesFilter = async (page, pageSize, sort, filter) => {
	const queryAllShippingFeeHistory = gql(`query($page: Float!
    $perPage: Float!
    $sortOrder: SortOrder!
    $sortField: SortField!
    $filter: FilterShippingFeeHistoryInput!
  ) {
    allShippingFeeHistory(page: $page
      perPage : $perPage
      sortOrder: $sortOrder
      sortField: $sortField
      filter: $filter
      ){
      id
    order_number
    company_name
    shipping_number
    registration_number
    shipping_fee
    shipping_fee_deducted_date
    product_name
    total_product_price
    product_quantity
    actual_weight
    bulky_weight
    shipping_country
    delivery_type
    delivery_status
    delivery_date
    applicable_weight
    trackingNumber
    requestShipping{
      productInformation{
        orderNo1
        orderNo2
      }
    }
    }
		total: _allShippingFeeHistoryMeta(page: $page
      perPage : $perPage
      sortOrder: $sortOrder
      sortField: $sortField) {
			count
			__typename
		}
  }
    `);

	try {
		const res = await APIClient.query({
			query: queryAllShippingFeeHistory,
			variables: {
				page: page || 0,
				perPage: pageSize || 10,
				sortOrder: {
					sortOrder: sort.sortOrder,
				},
				sortField: {
					sortField: sort.sortField,
				},
				filter: { ...filter },
			},
		});
		return res;
	} catch (e) {
		return e;
	}
};

// pagination without filter
const getAllShippingFeesMeta = async () => {
	const queryAllShippingFeeHistoryMeta = gql(`query {
    _allShippingFeeHistoryMeta{
      count
    }
  }
    `);

	try {
		const res = await APIClient.query({
			query: queryAllShippingFeeHistoryMeta,
		});
		return res;
	} catch (e) {
		return e;
	}
};

// pagination with filter
const getAllShippingFeesMetaFilter = async (filter) => {
	const queryAllShippingFeeHistoryMeta =
		gql(`query ($filter: FilterShippingFeeHistoryInput!){
    _allShippingFeeHistoryMeta(  filter: $filter){
      count
    }
  }
    `);

	try {
		const res = await APIClient.query({
			query: queryAllShippingFeeHistoryMeta,
			variables: {
				filter: { ...filter },
			},
		});
		return res;
	} catch (e) {
		return e;
	}
};

// excel data without filter

const getExcelData = async (sort) => {
	const queryAllShippingFeeHistory = gql(`query(
    $sortOrder: SortOrder
    $sortField: SortField
  ) {
    allShippingFeeHistory(
      sortOrder: $sortOrder
      sortField: $sortField
      ){
      id
    order_number
    company_name
    shipping_number
    registration_number
    shipping_fee
    shipping_fee_deducted_date
    product_name
    total_product_price
    product_quantity
    actual_weight
    bulky_weight
    shipping_country
    delivery_type
    delivery_status
    delivery_date
    trackingNumber
    applicable_weight
    requestShipping{
      productInformation{
        orderNo1
        orderNo2
      }
    }
    }
		total: _allShippingFeeHistoryMeta(
      sortOrder: $sortOrder
      sortField: $sortField
			) {
			count
			__typename
		}
  }
    `);

	try {
		const res = await APIClient.query({
			query: queryAllShippingFeeHistory,
			variables: {
				sortOrder: {
					sortOrder: sort.sortOrder,
				},
				sortField: {
					sortField: sort.sortField,
				},
			},
		});
		return res;
	} catch (e) {
		return e;
	}
};

// excel data with filter
const getExcelDataFilter = async (sort, filter) => {
	const queryAllShippingFeeHistory = gql(`query(
    $sortOrder: SortOrder!
    $sortField: SortField!
    $filter: FilterShippingFeeHistoryInput!
  ) {
    allShippingFeeHistory(
      sortOrder: $sortOrder
      sortField: $sortField
      filter: $filter
      ){
      id
    order_number
    company_name
    shipping_number
    registration_number
    shipping_fee
    shipping_fee_deducted_date
    product_name
    total_product_price
    product_quantity
    actual_weight
    bulky_weight
    shipping_country
    delivery_type
    delivery_status
    delivery_date
    trackingNumber
    applicable_weight
    requestShipping{
      productInformation{
        orderNo1
        orderNo2
      }
    }
    }
		total: _allShippingFeeHistoryMeta(
      sortOrder: $sortOrder
      sortField: $sortField,filter: $filter) {
			count
			__typename
		}
  }
    `);

	try {
		const res = await APIClient.query({
			query: queryAllShippingFeeHistory,
			variables: {
				sortOrder: {
					sortOrder: sort.sortOrder,
				},
				sortField: {
					sortField: sort.sortField,
				},
				filter: { ...filter },
			},
		});
		return res;
	} catch (e) {
		return e;
	}
};

const ActionLessIcon = styled.div`
	width: 210px;
	button {
		pointer-events: none;
	}
`;
