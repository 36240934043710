/* eslint-disable eqeqeq */
import { updateReqPacking, updateReqShipping } from "../query";

/**
 *
 * @param rows of selected data
 */
export const updateAllReqShipping = async (rows) => {
  let result = await Promise.all(
    rows.map(async (d) => {
      await updateReqShipping(d.id);
    })
  );
  return result;
};

/**
 *
 * @param rows of selected data
 */
export const updateAllReqPacking = async (rows) => {
  let result = await Promise.all(
    rows.map(async (d) => {
      await updateReqPacking(d.id);
    })
  );
  return result;
};

/**
 * Local Search
 */
export const localFilter = (datas, filter) => {
  let cloneData = [...datas];
  let res = [];

  if (filter.query[0] === '') {
    return datas;
  }

  switch (filter.field) {
    case "receipt_number":
      let filterRecp = cloneData.filter(
        (el) => filter.query.some((each) => each == el.trackingNumber)
      );
      res = filterRecp;
      break;

    case "order_number":
      let filterOrder = cloneData.filter(
        (el) => filter.query.some((each) => each == el.orderNumber)
      );
      res = filterOrder;
      break;

    case "departure_number":
      let filterDeparture = cloneData.filter(
        (el) =>
        filter.query.some((each) => each == el.productInformation.departureCountryCourierNumber)
      );
      res = filterDeparture;
      break;

    case "arival_number":
      let filterArrival = cloneData.filter(
        (el) => filter.query.some((each) => each == el.shippingNumber)
      );
      res = filterArrival;
      break;

    case "recipt_name":
      let filterPayee = cloneData.filter((el) =>
        filter.query.some((each) => el.payeeInformation.name.toUpperCase().includes(each.toUpperCase()))
      );
      res = filterPayee;
      break;

    case "recipient_address":
      let filterPayeeAddress = cloneData.filter(
        (el) =>
          filter.query.some((each) => 
          el.payeeInformation.cities.toUpperCase() === each.toUpperCase() ||
          el.payeeInformation.states.toUpperCase() === each.toUpperCase() ||
          el.payeeInformation.streetAddress1.toUpperCase() === each.toUpperCase() ||
          el.payeeInformation.streetAddress2.toUpperCase() === each.toUpperCase()
        )
      );
      res = filterPayeeAddress;
      break;

    case "recipient_phone":
      let filterPayeePhone = cloneData.filter(
        (el) =>
          filter.query.some((each) => 
            el.payeeInformation.phoneNumber1.includes(each) ||
            el.payeeInformation.phoneNumber2.includes(each)
          )
      );
      res = filterPayeePhone;
      break;

    default:
      res = datas;
      break;
  }

  return res;
};
