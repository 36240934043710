import { exportJsonToXlsx } from "../utils/utils";
import writeXlsxFile from "write-excel-file";
import React, { useState, useEffect } from "react";
import {
	Box,
	Checkbox,
	Divider,
	Grid,
	Link,
	MenuItem,
	Pagination,
	Stack,
} from "@mui/material";
import CustomButton from "../components/CustomButton";
import styled from "styled-components";

// eslint-disable-next-line import/no-anonymous-default-export
export default function DownloadExcelButton({
	fileName,
	columnsName,
	columnsData,
	excelData,
}) {
	//Processing the data for column conversion into korean

	const handleExportAll = () => {
		const processData = [];
		if (columnsData) {
			columnsData.forEach((d, i) => {
				let obj = {};
				//mapping data
				for (const [index, [key, value]] of Object.entries(Object.entries(d))) {
					//finding column
					let findColumn = Object.entries(columnsName).find(
						([key1, value1]) => value1 === key
					);

					//making an object
					if (findColumn) {
						obj[findColumn[0]] = value;
					}
				}

				processData.push(obj);
			});
			const data = processData;

			const columns = [];
			[...Array(3)].forEach((d) => {
				columns.push({
					width: 20,
				});
			});

			exportJsonToXlsx(data, fileName);
		} else if (excelData) {
			const data = excelData;

			exportJsonToXlsx(data, fileName);
		}
	};

	return (
		<ExcelDownLoad variant={"outlined"} onClick={handleExportAll}>
			<img
				style={{ paddingRight: "10px" }}
				src="/assets/images/excel.png"
				alt="excel"
			/>
			엑셀 다운로드
		</ExcelDownLoad>
	);
}

const ExcelDownLoad = styled(CustomButton)`
	color: black;
	font-weight: light;
	border: 1px solid #c4c4c4;
	border-radius: 4;
	height: 40px;
	border-radius: 10px;
	cursor: ${(props) => (props.pointer ? "pointer" : "unset")};
`;
