import React, { useState, useEffect } from "react";
import { Line, Circle, ProgressProps } from "rc-progress";

const Progressbar = ({ requestFilled }) => {
	const [percent, setPercent] = useState(0);

	function increase() {
		setPercent((prev) => prev + 1);
	}

	function restart() {
		setPercent(0);
		clearTimeout();
	}

	useEffect(() => {
		if (!requestFilled) {
			if (percent <= 100) {
				// console.log(percent);
				setInterval(() => {
					increase();
				}, 500);
			} else setPercent(100);
		}
	}, [percent]);
	useEffect(() => {
		if (requestFilled) {
			setPercent(100);
		}
	}, [requestFilled]);
	// console.log({ percent });

	return (
		<div
			style={{
				margin: 10,
				width: 200,
				backgroundColor: "transparent",
				position: "relative",
			}}
		>
			<Circle strokeWidth={6} percent={percent} />
			<p
				style={{
					position: "absolute",
					top: "36%",
					left: "42%",
					color: "#fff",
				}}
			>
				{percent}%
			</p>
			{/* <Line strokeWidth={4} percent={percent} /> */}
		</div>
	);
};

export default Progressbar;
