import readXlsxFile from "read-excel-file";
import { getShippingDetails } from "../queries/index";

//Reads sheet
export const readXlx = async (file) => {
	let res = await readXlsxFile(file);
	return res;
};

//Process data for regular tables
export const processXlx = async (rows, countries) => {
	//Number of tables
	const tables = rows[0].length / 2;
	/**
	 * Finding the country
	 */

	const countryCode = rows[0][0];
	const country = countries.find((el) => el.code.includes(countryCode));

	//Arranging rates
	const sheetRates = rows.slice(3);
	let finalRates = [];
	for (let j = 0; j < sheetRates.length; j++) {
		let arr = [];
		for (let i = 0; i <= tables; i += 2) {
			let obj = {};
			if (sheetRates[j][i] !== null && sheetRates[j][i + 1] !== null) {
				obj.weight = Number(sheetRates[j][i]) || 0;
				obj.deliveryFee = Number(sheetRates[j][i + 1]) || 0;
				arr.push(obj);
			}
		}
		finalRates.push(arr);
	}
	// console.log("first", { finalRates });
	/**
	 * Processing the shipment
	 */
	const getShipDetail = async (ships) => {
		const result = await Promise.all(
			ships.map(async (prom) => {
				const contents = await getShippingDetails(prom);
				return contents.data.allShippingTypeDetails[0];
			})
		);
		return result;
	};
	//Getting ship info
	let res = await getShipDetail(JSON.parse(country.shippingTypesDetailsIds));
	if (res) {
		let shipments = [];
		shipments = res.map((obj) => ({ ...obj, ratesDetails: [] }));
		finalRates.forEach((d, i) => {
			d.forEach((s, i) => {
				if (s.weight !== null || s.deliveryFee !== null)
					shipments[i]["ratesDetails"].push({
						...s,
						shippingTypeDetailId: shipments[i].id,
					});
			});
		});
		return { shipments, country };
	}
};

//Process data for gorup tables
export const processXlxGroup = async (rows, countries) => {
	//Number of tables
	const tables = rows[0].length / 2;
	/**
	 * Finding the country
	 */
	const countryCode = rows[0][0];
	const country = countries.find((el) => el.code.includes(countryCode));
	if (!country) return alert("!! Country not found !!");

	//Arranging rates
	const sheetRates = rows.slice(3);
	let finalRates = [];
	for (let j = 0; j < sheetRates.length; j++) {
		let arr = [];
		for (let i = 0; i <= tables; i += 2) {
			let obj = {};
			if (sheetRates[j][i] !== null && sheetRates[j][i + 1] !== null) {
				obj.weight = sheetRates[j][i];
				obj.deliveryFee = sheetRates[j][i + 1];
				arr.push(obj);
			}
		}
		finalRates.push(arr);
	}
	// console.log("second", { finalRates });
	/**
	 * Processing the shipment
	 */
	const getShipDetail = async (ships) => {
		const result = await Promise.all(
			ships.map(async (prom) => {
				const contents = await getShippingDetails(prom);
				return contents.data.allShippingTypeDetails[0];
			})
		);
		return result;
	};
	//Getting ship info
	let res = await getShipDetail(JSON.parse(country.shippingTypesDetailsIds));
	if (res) {
		let shipments = [];
		shipments = res.map((obj) => ({ ...obj, ratesDetails: [] }));
		finalRates.forEach((d, i) => {
			d.forEach((s, i) => {
				if (s.weight !== null || s.deliveryFee !== null)
					shipments[i]["ratesDetails"].push({
						...s,
						shippingTypeDetailId: shipments[i].id,
					});
			});
		});
		return { shipments, country };
	}
};
