import writeXlsxFile from "write-excel-file";

//Export xlsx with multiple table in one sheet
const exportPrimeXlsx = async (ids) => {
  //First fetch the desired data for the excel sheets using the ids
  let data = [];

  /**
   * Creating header
   */
  let header = [
    {
      value: "WAY BILL",
      backgroundColor: "#f1d8be",
      align: "center",
    },
    {
      value: "PACKAGE ID",
      backgroundColor: "#f1d8be",
      align: "center",
    },
    {
      value: "PARCEL ID",
      backgroundColor: "#f1d8be",
      align: "center",
    },
    {
      value: "CLIENT ID",
      backgroundColor: "#f1d8be",
      align: "center",
    },
    {
      value: "NAME",
      backgroundColor: "#f1d8be",
      align: "center",
    },
    {
      value: "ZIP",
      backgroundColor: "#f1d8be",
      align: "center",
    },
    {
      value: "REGION",
      backgroundColor: "#f1d8be",
      align: "center",
    },
    {
      value: "CITY",
      backgroundColor: "#f1d8be",
      align: "center",
    },
    {
      value: "ADDRESS",
      backgroundColor: "#f1d8be",
      align: "center",
    },
    {
      value: "PHONE NUMBER",
      backgroundColor: "#f1d8be",
      align: "center",
    },
    {
      value: "EMAIL",
      backgroundColor: "#f1d8be",
      align: "center",
    },
    {
      value: "COUNTRY",
      backgroundColor: "#f1d8be",
      align: "center",
    },
    {
      value: "SKU CODE",
      backgroundColor: "#f1d8be",
      align: "center",
    },
    {
      value: "DESCRIPTION OF CONTENT",
      backgroundColor: "#f1d8be",
      align: "center",
    },
    {
      value: "HS CODE",
      backgroundColor: "#f1d8be",
      align: "center",
    },
    {
      value: "QUANTITY",
      backgroundColor: "#f1d8be",
      align: "center",
    },
    {
      value: "WEIGHT PER ITEM,KG",
      backgroundColor: "#f1d8be",
      align: "center",
    },
    {
      value: "WEIGHT PER PARCEL,KG",
      backgroundColor: "#f1d8be",
      align: "center",
    },
    {
      value: "CURRENCY",
      backgroundColor: "#f1d8be",
      align: "center",
    },
    {
      value: "PRICE PER ITEM",
      backgroundColor: "#f1d8be",
      align: "center",
    },
    {
      value: "PRICE PER PARCEL",
      backgroundColor: "#f1d8be",
      align: "center",
    },
    {
      value: "TAX TYPE",
      backgroundColor: "#f1d8be",
      align: "center",
    },
    {
      value: "TAX IDENTIFICATION",
      backgroundColor: "#f1d8be",
      align: "center",
    },
  ];
  data.push(header);

  /**
   * Columns
   */
  const columns = [];
  [...Array(23)].forEach((d) => {
    columns.push({
      width: 20,
    });
  });

  writeXlsxFile(data, {
    columns,
    fileName: "hehe.xlsx",
  });
  console.log(data);
};

export default exportPrimeXlsx;
