import React from "react";
import {
	Page,
	Text,
	View,
	Document,
	StyleSheet,
	Image,
	Svg,
	Rect,
	Font,
} from "@react-pdf/renderer";

import LTPOSTAGEPAID from "../../Common/assets/Images/LT-POSTAGE-PAID-icon.JPG";
import Signature from "../../Common/assets/Images/signature-icon.JPG";
import PostNLPB1 from "../../Common/assets/Images/PostNL_PB1.jpg";
import phoneSymbol from "../../Common/assets/Images/phone-symbol.png";
import Scan from "../../Common/assets/Images/Scan.jpg";
import ReturnLogo from "../../Common/assets/Images/Return-Logo.jpg";
import UZEMSLogo from "../../Common/assets/Images/UZ-EMS-logo.jpg";
import moment from "moment";
import PackageTable from "./PackageTable";

export const UZPostEMS = (props) => {
	const { barcode, selectedRequests } = props;
	// console.log("barcode", barcode, "selectedRequests", selectedRequests);
	let { tableData, ...item1 } = selectedRequests;
	// console.log(props);

	const calculateTotal = (products) => {
		let total = 0;
		products.forEach((item) => {
			let product = parseInt(item.quantity) * parseInt(item.unitPrice);

			total = total + product;
		});

		return total;
	};
	return (
		<>
			{tableData && <PackageTable tableData={tableData} />}
			<Page size="A6" orientation="landscape" style={styles.page}>
				<View style={styles.rowized}>
					<View style={styles.rowized}>
						<Image style={styles.UZEMSImg} src={UZEMSLogo} />
						<Image style={styles.ReturnImg} src={ReturnLogo} />
						<View style={styles.marginT}>
							<Text>Return if undeliverable :</Text>
							<Text>PO Box 0371 TASHKENT-UZBEKISTAN</Text>
							<Text>The Item / Parcel maybe opened officially</Text>
						</View>
					</View>
					<View>
						<Image src={barcode} style={styles.barCode} />
					</View>
					<View style={styles.postagePaid}>
						<Text style={styles.packetCon}>POSTAGE PAID</Text>
						<Text style={styles.packetCon}>UZA</Text>
					</View>
				</View>
				<View style={styles.mainView}>
					<View style={styles.leftSec}>
						<View style={styles.from}>
							<View style={styles.fromLeft}>
								<Text>From</Text>
							</View>
							<View style={styles.fromRight}>
								<Text style={styles.bB}>{item1.senderInformation?.name}</Text>
								<Text style={styles.bB}>
									Address: {item1.senderInformation?.address}
								</Text>
								<Text style={styles.bB}>
									TEL: {item1.senderInformation?.phoneNumber}
								</Text>
								<View style={styles.rowized2}>
									<Text>PostCode:</Text>
									<Text style={styles.cityAdd}>City:</Text>
								</View>
							</View>
						</View>

						<View style={styles.to}>
							<View style={styles.toLeft}>
								<Text>To</Text>
							</View>
							<View style={styles.toRight}>
								<Text style={styles.bB}>
									Addressee: {item1.payeeInformation?.name}
								</Text>
								<Text style={styles.bB}>
									Address :{item1.payeeInformation?.streetAddress1} ,
									{item1.payeeInformation?.streetAddress2}
								</Text>
								<Text style={styles.bB}></Text>

								<View style={styles.rowized}>
									<View style={styles.bRMoskva}>
										<Text style={styles.bB}>
											{item1.payeeInformation?.cities} TEL:
											{item1.payeeInformation?.phoneNumber1}
										</Text>
										<View style={styles.pTB5}>
											<Text>Postcode: {item1.payeeInformation?.zipCode}</Text>
											<Text>City:{item1.payeeInformation?.cities}</Text>
										</View>
									</View>
									<View style={styles.justifyConCenter}>
										<Text style={styles.RUText}>
											{item1.shippingCountry?.code}
										</Text>
									</View>
								</View>
							</View>
						</View>

						<View style={styles.bottom}>
							<View style={styles.leftLeftBottom}>
								<Text>
									weight(Gr.)
									{item1.miscellaneousInformation?.actualWeight}
								</Text>
								<Text style={styles.marginT5}>
									Date: {moment().format("DD/MM/YYYY")}
								</Text>

								<View style={styles.timePostage}>
									<Text style={styles.marginT5}>Time:</Text>
									<Text style={styles.marginT5}>Postage:</Text>
								</View>
							</View>
							<View style={styles.leftRightBottom}>
								<Text style={styles.textCenter}>DELIVERY PARITICULARS</Text>
								<Text style={styles.textCenter}>Item was received</Text>

								<Text style={styles.marginT5}>Name:</Text>
								<Text style={styles.marginT5}>Date:</Text>
								<Text style={styles.marginT5}>Time:</Text>
								<Text style={styles.marginT5}>Signature:</Text>
							</View>
						</View>
					</View>
					<View style={styles.rightSec}>
						<View style={styles.rowized4}>
							<View style={styles.cDText}>
								<Text>CUSTOMS DECLARATION</Text>
								<Text>Importers telephone/fax/email (if known )</Text>
							</View>
							<View>
								<Text>Importer's reference (if any) ( tax code / VAT )</Text>
							</View>
						</View>

						<View style={styles.rowized5}>
							<View style={styles.bRwidthHundredPercent}>
								<Text>Detail description of contents</Text>
							</View>

							<View style={styles.bRwidthFiftyPercent}>
								<Text>Quantity</Text>
							</View>

							<View style={styles.bRwidthFiftyPercent}>
								<Text style={styles.breakSpaces}>Net</Text>
								<Text>Weight</Text>
								<Text>(kg)</Text>
							</View>

							<View style={styles.bRwidthFiftyPercent}>
								<Text style={styles.breakSpaces}>Unit</Text>
								<Text>value</Text>
								<Text>(USD)</Text>
							</View>

							<View style={styles.widthHundredPercent}>
								<View style={styles.bBcommercial}>
									<Text>For Commercial items only</Text>
								</View>
								<View style={styles.rowizedCustom}>
									<View style={styles.rowized50}>
										<Text>HS tariff </Text>
										<Text> no </Text>
									</View>
									<View style={styles.rowized50Col}>
										<Text>Country of </Text>
										<Text>origin </Text>
										<Text>of good</Text>
									</View>
								</View>
							</View>
						</View>
						{item1.productInformation?.productInformation
							?.slice(0, 3)
							.map((product, index) => {
								return (
									<View style={styles.rowized5}>
										<View style={styles.bRwidthHundredPercent}>
											<Text>
												{product.productName.length > 25
													? product.productName.slice(0, 25).concat("...")
													: product.productName}
											</Text>
										</View>

										<View style={styles.bRwidthFiftyPercent}>
											<Text>{product.quantity}</Text>
										</View>

										<View style={styles.bRwidthFiftyPercent}>
											<Text>
												{item1.miscellaneousInformation?.actualWeight}
											</Text>
										</View>

										<View style={styles.bRwidthFiftyPercent}>
											<Text>
												{product.unitPrice}{" "}
												{item1.productInformation?.currency === "USD"
													? "$"
													: "€"}
											</Text>
										</View>

										<View style={styles.widthHundredPercent}>
											<View style={styles.rowizedCustom}>
												<View style={styles.rowized50}>
													<Text>{product.hscode}</Text>
												</View>

												<View style={styles.rowized50}>
													<Text>{product.quantity}</Text>
												</View>
											</View>
										</View>
									</View>
								);
							})}

						<View style={styles.rowized}>
							<View style={styles.rightLeftSec}>
								<View style={styles.total}>
									<View style={styles.bR}>
										<Text>Total</Text>
										<Text>{item1.miscellaneousInformation?.actualWeight}</Text>
									</View>
									<View style={styles.mL5}>
										<Text>Total</Text>
										<Text>
											{calculateTotal(
												item1.productInformation.productInformation
											)}
											{item1.productInformation?.currency === "USD" ? "$" : "€"}
										</Text>
									</View>
								</View>

								<View style={styles.rowized}>
									<View>
										<Text style={styles.categoryOfItemText}>
											Category of item
										</Text>

										{/* Gift ---------------------------- */}
										{/* Use this if empty box */}

										{item1.miscellaneousInformation.productType !== "Gift" ? (
											<View style={styles.rowNoSpace}>
												<Svg viewBox="0 0 220 100">
													<Rect
														x="1"
														rx="2"
														ry="2"
														width="60"
														height="30"
														stroke="black"
													/>
												</Svg>
												<Text style={styles.ML47}>Gift</Text>
											</View>
										) : (
											<View style={styles.rowNoSpace}>
												<Svg viewBox="0 0 220 100">
													<Rect
														x="1"
														rx="2"
														ry="2"
														width="60"
														height="30"
														stroke="black"
													/>
												</Svg>

												<Text style={styles.crossInBoxCg}>X</Text>

												<Text style={styles.textAfterCrossCg}>Gift</Text>
											</View>
										)}
										{/* Commercial ---------------------------- */}
										{/* Use this if empty box */}
										{item1.miscellaneousInformation.productType !==
										"Documents" ? (
											<View style={styles.rowNoSpace1}>
												<Svg viewBox="0 0 220 100">
													<Rect
														x="1"
														rx="2"
														ry="2"
														width="60"
														height="30"
														stroke="black"
													/>
												</Svg>
												<Text style={styles.ML47}>Documents</Text>
											</View>
										) : (
											<View style={styles.rowNoSpace1}>
												<Svg viewBox="0 0 220 100">
													<Rect
														x="1"
														rx="2"
														ry="2"
														width="60"
														height="30"
														stroke="black"
													/>
												</Svg>

												<Text style={styles.crossInBoxCg}>X</Text>

												<Text style={styles.textAfterCrossCg}>Documents</Text>
											</View>
										)}
									</View>

									<View style={styles.mLCategoryOfGoods}>
										{/* Commercial ---------------------------- */}
										{/* Use this if empty box */}
										{item1.miscellaneousInformation.productType !==
										"Commercial" ? (
											<View style={styles.rowNoSpace}>
												<Svg viewBox="0 0 220 100">
													<Rect
														x="1"
														rx="2"
														ry="2"
														width="60"
														height="30"
														stroke="black"
													/>
												</Svg>
												<Text style={styles.ML47S}>Commercial</Text>
											</View>
										) : (
											<View style={styles.rowNoSpace}>
												<Svg viewBox="0 0 220 100">
													<Rect
														x="1"
														rx="2"
														ry="2"
														width="60"
														height="30"
														stroke="black"
													/>
												</Svg>

												<Text style={styles.crossInBoxCg}>X</Text>

												<Text style={styles.textAfterCross}>Commercial</Text>
											</View>
										)}

										{/* Return Goods ---------------------------- */}
										{/* Use this if empty box */}
										{item1.miscellaneousInformation.productType !==
										"ReturnedGood" ? (
											<View style={styles.rowNoSpace1}>
												<Svg viewBox="0 0 220 100">
													<Rect
														x="1"
														rx="2"
														ry="2"
														width="60"
														height="30"
														stroke="black"
														fillOpacity="0"
														fill="none"
													/>
												</Svg>
												<Text style={styles.ML47S}>Returned Goods</Text>
											</View>
										) : (
											<View style={styles.rowNoSpace1}>
												<Svg viewBox="0 0 220 100">
													<Rect
														x="1"
														rx="2"
														ry="2"
														width="60"
														height="30"
														stroke="black"
														fillOpacity="0"
														fill="none"
													/>
												</Svg>

												<Text style={styles.crossInBoxCg}>X</Text>

												<Text style={styles.textAfterCross}>
													Returned Goods
												</Text>
											</View>
										)}
										{/* OTHERS ---------------------------- */}
										{/* Use this if empty box */}

										{item1.miscellaneousInformation.productType !== "Others" ? (
											<View style={styles.rowNoSpace1}>
												<Svg viewBox="0 0 220 100">
													<Rect
														x="1"
														rx="2"
														ry="2"
														width="60"
														height="30"
														stroke="black"
													/>
												</Svg>
												<Text style={styles.ML47S}>Others</Text>
											</View>
										) : (
											<View style={styles.rowNoSpace1}>
												<Svg viewBox="0 0 220 100">
													<Rect
														x="1"
														rx="2"
														ry="2"
														width="60"
														height="30"
														stroke="black"
													/>
												</Svg>

												<Text style={styles.crossInBoxCg}>X</Text>

												<Text style={styles.textAfterCross}>Others</Text>
											</View>
										)}
									</View>
									<View style={styles.negML5}>
										<Text>Explanation: </Text>
									</View>
								</View>
								<View style={{ marginTop: "-15px", padding: 2 }}>
									<Text>
										Comments: (e.g.: good subject to quarantine sanitary /
										phytosanitary inspection or other restrictions).
									</Text>
								</View>
								<View style={styles.rowizedSa}>
									{/* License ---------------------------- */}
									{/* Use this if empty box */}
									<View style={styles.rowNoSpace}>
										<Svg viewBox="0 0 220 100">
											<Rect
												x="1"
												rx="2"
												ry="2"
												width="60"
												height="30"
												stroke="black"
											/>
										</Svg>
										<Text style={styles.ML47Sa}>License</Text>
									</View>

									{/* Use this if cross is required */}
									{/* <View style={styles.rowNoSpace}>
													<Svg viewBox="0 0 220 100">
														<Rect
															x="1"
															rx="2"
															ry="2"
															width="60"
															height="30"
															stroke="black"
														/>
													</Svg>
													<Text style={styles.crossOnLicense}>X</Text>
													<Text style={styles.ML47SaOnCross}>License</Text>
												</View> */}

									{/* Certificate ---------------------------- */}
									{/* Use this if empty box */}
									<View style={styles.rowNoSpace}>
										<Svg viewBox="0 0 220 100">
											<Rect
												x="1"
												rx="2"
												ry="2"
												width="60"
												height="30"
												stroke="black"
											/>
										</Svg>
										<Text style={styles.ML47Sa}>Certificate</Text>
									</View>

									{/* Use this if cross is required */}
									{/* <View style={styles.rowNoSpace}>
													<Svg viewBox="0 0 220 100">
														<Rect
															x="1"
															rx="2"
															ry="2"
															width="60"
															height="30"
															stroke="black"
														/>
													</Svg>
													<Text style={styles.crossOnLicense}>X</Text>
													<Text style={styles.ML47SaOnCross}>Certificate</Text>
												</View> */}

									{/* Invoice ---------------------------- */}
									{/* Use this if empty box */}
									<View style={styles.rowNoSpace}>
										<Svg viewBox="0 0 220 100">
											<Rect
												x="1"
												rx="2"
												ry="2"
												width="60"
												height="30"
												stroke="black"
											/>
										</Svg>
										<Text style={styles.ML47Sa}>Invoice</Text>
									</View>

									{/* Use this if cross is required */}
									{/* <View style={styles.rowNoSpace}>
													<Svg viewBox="0 0 220 100">
														<Rect
															x="1"
															rx="2"
															ry="2"
															width="60"
															height="30"
															stroke="black"
														/>
													</Svg>
													<Text style={styles.crossOnLicense}>X</Text>
													<Text style={styles.ML47SaOnCross}>Invoice</Text>
												</View> */}
								</View>
								<View style={styles.rowizedSb}>
									<Text>No. of liscence(s)</Text>
									<Text>No. of certificate(s)</Text>
									<Text>No. of invoice(s)</Text>
								</View>
							</View>
							<View style={styles.rightRightSec}>
								<View style={styles.justifyConCenter}>
									<View style={styles.justifyConCenter}>
										<Text style={styles.textCenter}>
											Office of origin / Date of posting
										</Text>
										<Text style={styles.textCenter}>SHIPTER Co.</Text>
									</View>

									<View>
										<Text style={styles.textCenter}>
											{moment().format("DD/MM/YY")}
										</Text>
										{/* <Text style={styles.textCenter}>DD / MM / YY</Text> */}
									</View>
								</View>
								<View>
									<Text style={styles.iCertify}>
										I certify that the particulars given in this customs
										decleration are correct and that this term does not contain
										any dangerous article or articles prohibited by regislation
									</Text>
									<Text style={styles.dateAndSenderSign}>
										Date and sender's signature
									</Text>
								</View>
							</View>
						</View>
					</View>
				</View>
			</Page>
		</>
	);
};

const fRow = {
	diaplay: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
};
const fCol = {
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	justifyContent: "center",
};

Font.register({
	family: "SpoqaHanSans",
	src: "https://cdn.jsdelivr.net/gh/spoqa/spoqa-han-sans@01ff0283e4f36e159ffbf744b36e16ef742da6d8/Subset/SpoqaHanSans/SpoqaHanSansLight.ttf",
});
const styles = StyleSheet.create({
	page: {
		fontSize: 5,
		padding: 10,
		border: "2px solid #000",
		fontFamily: "SpoqaHanSans",
	},
	sec1: {
		display: "flex",
		flexDirection: "row",
		width: "100%",
		color: "#fff",
	},
	sec11: {
		backgroundColor: "white",
		padding: 2,
		color: "#000",
		borderBottom: "2px solid #000",
		display: "flex",
		flexDirection: "row",
		justifyContent: "flex-start",
		alignItems: "flex-start",
	},
	sec12: {
		...fCol,
		backgroundColor: "white",
		width: "65%",
		padding: 15,
		color: "red",
		fontWeight: 700,
		border: "2px solid #000",
	},
	sec13: {
		width: "50%",
		borderRight: "1px solid #000",
	},
	sec14: {
		backgroundColor: "white",
		color: "#000",
		borderBottom: "2px solid #000",
		display: "flex",
		flexDirection: "row",
	},
	sec15: {
		width: "25%",
		borderRight: "1px solid #000",
	},
	sec16: {
		borderBottom: "1px solid #000",
		fontSize: 6,
	},
	leftSec: {
		width: "40%",
		borderRight: "1px solid black",
	},
	rightSec: {
		width: "60%",
		color: "#000",
		backgroundColor: "#fff",
	},
	from: {
		display: "flex",
		flexDirection: "row",
		borderBottom: "1px solid black",
		fontSize: 7,
	},
	fromLeft: {
		width: "15%",
		borderRight: "1px solid black",
		// backgroundColor: "#afdcec",
		// borderRadius: 5,
		// border: "0.5px solid blue",
		marginLeft: 5,
		padding: 0,
		fontSize: 9,
	},
	fromRight: {
		// ...fRow,
		width: "85%",
		// backgroundColor: "#afdcec",
		// borderRadius: 5,
		// border: "0.5px solid blue",
		// marginLeft: 5,
		// marginRight: 5,
		// marginBottom: 5
	},
	to: {
		display: "flex",
		flexDirection: "row",
		borderBottom: "1px solid black",
		fontSize: 7,
	},
	toLeft: {
		...fCol,
		width: "17%",
		borderRight: "1px solid black",
		fontSize: 12,
	},
	toRight: {
		width: "83%",
		// backgroundColor: "#ddead1",
		// borderRadius: 5,
		// border: "0.5px solid blue",
		// marginLeft: 5,
		// marginRight: 5,
		// marginBottom: 5,
		// padding: 5
	},
	Icon: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "center",
		borderBottom: "1px solid black",
	},
	mainView: {
		display: "flex",
		flexDirection: "row",
		width: "100%",
	},
	red: {
		color: "red",
	},
	sec2: {
		...fRow,
		color: "red",
		fontWeight: 700,
		padding: 10,
		alignItems: "center",
		justifyContent: "center",
		border: "2px solid #000",
	},
	sec3: {
		...fCol,
		alignItems: "flex-start",
		height: 170,
		paddingLeft: 15,
		paddingRight: 15,
		border: "2px solid #000",
	},
	sec3a: {
		...fRow,
	},
	sec4: {
		...fCol,
		height: 170,
		alignItems: "flex-start",
		paddingLeft: 15,
		paddingRight: 15,
		border: "2px solid #000",
	},
	sec5: {
		...fRow,
		width: "100%",
		paddingLeft: 15,
		paddingRight: 15,
	},
	sec6: {
		...fRow,
		width: "100%",
		paddingLeft: 15,
		paddingRight: 15,
		border: "2px solid #000",
	},
	sec6a: {
		...fCol,
		width: "100%",
		paddingLeft: 15,
		paddingRight: 15,
	},
	sec5a: {
		...fCol,
		justifyContent: "flex-start",
		alignItems: "flex-start",
		height: 200,
		border: "2px solid #000",
	},
	sec51: {
		...fCol,
		alignItems: "flex-start",
		justifyContent: "flex-start",
		width: "50%",
		height: 150,
		paddingLeft: 15,
		paddingRight: 15,
	},
	sec52: {
		...fCol,
		height: 150,
		width: "50%",
		justifyContent: "flex-start",
		alignItems: "flex-start",

		paddingLeft: 15,
		paddingRight: 15,
	},

	listItem: {
		...fRow,
		width: "100%",
		marginBottom: 10,
		gap: "20px",
		justifyContent: "flex-end",
		padding: "0 10px",
	},
	list: {
		...fRow,
		marginRight: 10,
	},
	listItemLabel: {},
	listItemValue: {
		color: "red",
	},
	line: {
		height: 1,
		backgroundColor: "black",
		marginBottom: 2,
	},
	body: {
		padding: 10,
	},
	row: {
		...fRow,
	},
	center: {
		...fCol,
		marginTop: 25,
	},
	image: {
		maxWidth: 130,
		height: "auto",
	},
	postCode: {
		fontSize: 16,
	},
	border: {
		border: "1px solid black",
	},
	borderBottom: {
		borderBottom: "1px solid black",
	},
	borderTop: {
		borderTop: "1px solid black",
	},
	bold: {
		fontWeight: "bold",
	},
	greenCon: {
		//     backgroundColor: "#ddead1",
		//     borderRadius: 5,
		//     border: "0.5px solid blue",
		marginLeft: 5,
		marginRight: 5,
		marginBottom: 5,
		padding: 5,
	},
	blueCon: {
		//     backgroundColor: "#afdcec",
		//     borderRadius: 5,
		//     border: "0.5px solid blue",
		marginLeft: 5,
		marginRight: 5,
		marginBottom: 5,
		padding: 5,
	},
	dateAndSender: {
		marginTop: 10,
		marginBottom: 10,
	},
	weightInKg: {
		...fCol,
		width: "25%",
		borderRight: "1px solid #000",
	},
	valueInUSD: {
		...fCol,
		width: "25%",
		borderRight: "1px solid #000",
	},

	col: {
		...fRow,
	},
	TrackImg: {
		// width: 50,
		// height: 50
	},
	PostNLImg: {
		// width: 150,
		// height: 70
	},
	phoneSym: {
		width: 8,
		height: 8,
	},
	scanImg: {
		width: 40,
		height: 40,
		// backgroundColor: "#afdcec",
		// borderRadius: 5,
		// border: "0.5px solid blue",
		// margin: 10,
		padding: 5,
	},
	returnLogoImg: {
		width: 15,
		height: 15,
	},
	blueConNoPadding: {
		//     backgroundColor: "#afdcec",
		//     border: "0.5px solid blue",
		marginLeft: 5,
		marginRight: 5,
		marginBottom: 5,
	},
	countryCode: {
		display: "flex",
		justifyContent: "flex-end",
		fontSize: 17,
	},
	fromAddress: {
		display: "flex",
		flexDirection: "row",
		fontSize: 5,
		marginRight: 12,
		marginBottom: 10,
	},
	fromRotate: {
		transform: "rotate(-90deg)",
		fontSize: 10,
		marginTop: 10,
	},
	standardText: {
		backgroundColor: "#000",
		color: "#fff",
		textAlign: "center",
		padding: 2,
	},
	packetCon: {
		textAlign: "center",
	},
	SignImg: {
		width: 30,
		height: 30,
		marginTop: 5,
	},
	rowized: {
		display: "flex",
		flexDirection: "row",
	},
	rowizedCustom: {
		display: "flex",
		flexDirection: "row",
		width: "100%",
	},
	rowized50: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "center",
		alignItems: "center",
		width: "50%",
		borderRight: "1px solid black",
	},
	rowized50Col: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		width: "50%",
		borderRight: "1px solid black",
	},
	postagePaid: {
		display: "flex",
		flexDirection: "col",
		justifyContent: "center",
		alignItems: "center",
		border: "1px solid black",
		padding: 2,
		margin: 10,
	},
	UZEMSImg: {
		width: 100,
		height: 40,
	},
	ReturnImg: {
		width: 20,
		height: 20,
		marginTop: 10,
	},
	marginT: {
		marginTop: 10,
	},
	rowized2: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		paddingTop: 5,
		paddingBottom: 5,
	},
	bottom: {
		display: "flex",
		flexDirection: "row",
		width: "100%",
		marginTop: 7,
	},
	leftLeftBottom: {
		paddingTop: 10,
		paddingBottom: 10,
		paddingLeft: 5,
		border: "1px solid black",
		width: "50%",
		marginRight: 5,
	},
	leftRightBottom: {
		paddingTop: 1,
		paddingLeft: 5,
		border: "1px solid black",
		width: "50%",
	},
	rowNoSpace: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "flex-start",
		alignItems: "flex-start",
		alignContent: "flex-start",
	},
	ML47: {
		marginLeft: -37,
		marginTop: 2,
		fontSize: 4,
	},
	bB: {
		borderBottom: "1px solid black",
		fontSize: "6px",
		padding: 2,
	},
	bRMoskva: {
		borderRight: "1px solid black",
		width: "75%",
	},
	RUText: {
		fontSize: 16,
	},
	pTB5: {
		paddingTop: 5,
		paddingBottom: 5,
	},
	textCenter: {
		textAlign: "center",
	},
	marginT5: {
		marginTop: 4,
	},
	cityAdd: {
		marginRight: 30,
	},
	cDText: {
		borderRight: "1px solid black",
	},
	rowized3: {
		display: "flex",
		flexDirection: "row",
		borderTop: "1px solid black",
	},
	rowized4: {
		display: "flex",
		flexDirection: "row",
		borderBottom: "1px solid black",
	},
	bR: {
		borderRight: "1px solid black",
	},
	rowized5: {
		display: "flex",
		flexDirection: "row",
		borderBottom: "1px solid black",
	},
	breakSpaces: {
		whiteSpace: "break-spaces",
	},
	bBcommercial: {
		borderBottom: "1px solid black",
		width: "100%",
		textAlign: "center",
	},
	widthHundredPercent: {
		width: "100%",
	},
	bRwidthHundredPercent: {
		borderRight: "1px solid black",
		width: "100%",
		textAlign: "center",
		padding: "1px 2px",
	},
	bRwidthFiftyPercent: {
		borderRight: "1px solid black",
		width: "50%",
		textAlign: "center",
		padding: 2,
	},
	total: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "flex-end",
	},
	rightLeftSec: {
		width: "60%",
		borderRight: "1px solid black",
		paddingRight: 10,
	},
	rightRightSec: {
		width: "40%",
	},
	mL5: {
		marginLeft: 5,
	},
	mL51: {
		marginLeft: -10,
	},
	rowNoSpace1: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "flex-start",
		alignItems: "flex-start",
		alignContent: "flex-start",
		marginTop: -19,
	},
	ML47S: {
		marginLeft: -41,
		marginTop: 2,
		fontSize: 4,
	},
	negML5: {
		marginLeft: -24,
		fontSize: 4,
	},
	ML47Sa: {
		marginLeft: -31,
		fontSize: 4,
	},
	rowizedSa: {
		display: "flex",
		flexDirection: "row",
		marginTop: 10,
	},
	rowizedSb: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		padding: 2,
		fontSize: 5,
	},
	dateAndSenderSign: {
		fontSize: 9,
		marginTop: 10,
		padding: 2,
	},
	justifyConCenter: {
		display: "flex",
		justifyContent: "center",
	},
	iCertify: {
		marginTop: 10,
		padding: 2,
	},
	barCode: {
		width: 115,
		height: 30,
	},
	categoryOfItemText: {
		marginBottom: 10,
	},
	mLCategoryOfGoods: {
		marginLeft: -10,
		marginTop: 15,
	},
	crossInBox: {
		marginLeft: -69,
		marginTop: 2,
		fontSize: 7,
	},
	textAfterCross: {
		fontSize: 4,
		marginLeft: 23,
		marginTop: 2,
	},
	crossInBoxCg: {
		marginLeft: -63,
		marginTop: 2,
		fontSize: 7,
	},
	textAfterCrossCg: {
		fontSize: 4,
		marginLeft: 21,
		marginTop: 2,
	},
	crossOnLicense: {
		marginLeft: -40,
	},
	ML47SaOnCross: {
		fontSize: 4,
		marginLeft: 5,
		marginTop: 1,
	},
});
