import React from "react";
import {
	Page,
	Text,
	View,
	Document,
	StyleSheet,
	Font,
	Image,
} from "@react-pdf/renderer";

import stamp from "../../Common/assets/Images/stampEmu.png";
import moment from "moment";
import PackageTable from "./PackageTable";

export const EMULabel = (props) => {
	const { barcode, selectedRequests } = props;
	let { tableData, ...item1 } = selectedRequests;
	// console.log(props);
	let totalSum = 0;

	// console.log("req", request);
	const calculateTotal = (products) => {
		const { unitPrice, quantity } = products;
		let total = 0;
		// products.forEach((item) => {
		// 	let product = parseInt(item.quantity) * parseInt(item.unitPrice);

		// 	total = total + product;
		// });
		total = parseInt(quantity) * parseInt(unitPrice);
		totalSum = totalSum + total;
		return total;
	};

	return (
		<>
			{/*<Document>*/}
			{/* {Object.keys(selectedRequests).map((item1, index2) => { */}
			{/*return (*/}
			{tableData && <PackageTable tableData={tableData} />}
			<Page size="A4" style={styles.page}>
				{/* First Section */}
				<View style={styles.row}>
					<View style={styles.colBorder}>
						<View style={styles.col}>
							<Text style={styles.textAlignCenter}>Receiver</Text>
						</View>
						<Text style={styles.textBold}>EXPRESS MAIL UNIVERSAL LLC</Text>
						<View style={styles.rowWrap}>
							{" "}
							<Text style={styles.textBold}>ADDRESS</Text>
							<Text style={styles.text}>
								35/1 Shota Rustaveli street Yakkasaray, District of Tashkent
								City
							</Text>
						</View>
						<View style={styles.rowStart}>
							<Text style={styles.textBold}>TEL:</Text>
							<Text style={styles.text}>+99895-198-96-69</Text>
						</View>
						<Text style={styles.blue}>e-mail: info@emu.uz</Text>
					</View>
					<View style={styles.colBorder}>
						<View style={styles.col}>
							<Text style={styles.textAlignCenter}>Sender</Text>
						</View>
						<Text style={styles.textBold}>H3 networks Co., Ltd</Text>
						<View style={styles.rowWrap}>
							{" "}
							<Text style={styles.textBold}>ADDRESS</Text>
							<Text style={styles.text}>
								{" "}
								B103 The Sky Valley 5th, Hwagok-ro 416, Gangseo-gu Seoul 07548
							</Text>
						</View>
						<View style={styles.rowStart}>
							<Text style={styles.textBold}>TEL:</Text>
							<Text style={styles.text}>+821023041331</Text>
						</View>
						<Text style={styles.blue}>e-mail: cs@shipter.kr</Text>
					</View>
				</View>
				{/* Second Section */}
				<View style={styles.col}>
					<View style={styles.rowStart}>
						<Text style={styles.textBold}>Invoice No:</Text>
						<Text>45 545 54 </Text>
					</View>
					<View style={styles.rowBarcode}>
						<View style={styles.rowStart}>
							<Text>{item1.trackingNumber}</Text>
							<Text>{item1.bag?.bagNumber}</Text>
						</View>
						<Image src={barcode} style={styles.barcodeStyle} />
					</View>
					<View style={styles.rowStart}>
						<Text>СЧЕТ-ФАКТУРА (ИНВОЙС) </Text>
					</View>
				</View>

				{/* Third Section */}

				<View style={styles.table}>
					<View style={styles.tableLeft}>
						<View style={styles.tableRow}>
							<Text>Sender/ Ф.И.О. отправителя:</Text>
						</View>
						<View style={styles.tableRow}>
							<Text>Receiver/Получатель:</Text>
						</View>
						<View style={styles.tableRow}>
							<Text>Receiver’s address/Адрес получателя:</Text>
						</View>
						<View style={styles.tableRow}>
							<Text>Passport number/Паспорт получателя</Text>
						</View>
						<View style={styles.tableRowCustom}>
							<Text>By order*/По поручению*</Text>
						</View>
						<View style={styles.tableRow}>
							<Text>Contract number*/Номер контракта* </Text>
						</View>
						<View style={styles.tableRow}>
							<Text>Waybill name/Номер накладной*</Text>
						</View>
						<View style={styles.tableRow}>
							<Text>
								Delivery terms (INCOTERMS)/Условия поставки (ИНКОТЕРМС)*
							</Text>
						</View>
					</View>
					<View style={styles.tableRight}>
						<View style={styles.tableRow}>
							<Text>Shipter</Text>
						</View>
						<View style={styles.tableRow}>
							<Text>{item1.payeeInformation?.name}</Text>
						</View>
						<View style={styles.tableRowCustom}>
							<Text>
								{`${item1.payeeInformation?.streetAddress1} ${item1.payeeInformation?.streetAddress2} ${item1.payeeInformation?.mexicoColony}, ${item1.payeeInformation?.cities} ${item1.payeeInformation?.states}, ${item1.payeeInformation?.zipCode}`}
							</Text>
						</View>
						<View style={styles.tableRowCustom}>
							<Text></Text>
						</View>
						<View style={styles.tableRow}>
							<Text>
								Необязательно для заполнения. Указывается полное наименование и
								реквизиты если имеется контрактодержатель{" "}
							</Text>
						</View>
						<View style={styles.tableRowCustom}>
							<Text>
								Необязательно для заполнения. Указывается номер и дата
								внешнеторгового контракта (если имеется)
							</Text>
						</View>
						<View style={styles.tableRow}>
							<Text>
								Необязательно для заполнения. Указывается номер транспортной
								накладной
							</Text>
						</View>
						<View style={styles.tableRowCustom}>
							<Text>
								Необязательно для заполнения. Указываются условия поставки в
								соответствии справилами ИНКОТЕРМС 2000 или 2010{" "}
							</Text>
						</View>
					</View>
				</View>

				{/* Fourth Section */}
				<View style={styles.Container}>
					<View style={styles.ContainerHead}>
						<Text>Name of goods/Перечень товаров</Text>
					</View>
					<View style={styles.ContainerBody}>
						<View style={styles.ContainerBodyTitle}>
							<Text style={styles.BodyTitle}>No</Text>
							<Text style={styles.BodyTitle}>Name/Наименование</Text>
							<Text style={styles.BodyTitle}>Quantity/ Количество</Text>
							<Text style={styles.BodyTitle}>Net weight/ Вес нетто</Text>
							<Text style={styles.BodyTitle}>Gross weight/Вес брутто</Text>
							<Text style={styles.BodyTitle}>Price/Цена за единицу</Text>
							<Text style={styles.BodyTitle}>Total price/Цена</Text>
						</View>
					</View>
					{item1?.productInformation?.productInformation
						?.slice(0, 5)
						.map((item2, index3) => {
							return (
								<View style={styles.ContainerBodyRows}>
									<Text style={styles.BodyRows}>{index3 + 1}</Text>
									<Text style={styles.BodyRows}>
										{item2.productName.length > 25
											? item2.productName.slice(0, 25).concat("...")
											: item2.productName}
									</Text>
									<Text style={styles.BodyRows}>{item2.quantity}</Text>
									<Text style={styles.BodyRows}>0.1kg</Text>
									<Text style={styles.BodyRows}>
										{(item2.quantity * 0.1).toFixed(2)}kg
									</Text>
									<Text style={styles.BodyRows}>{item2.unitPrice}</Text>
									<Text style={styles.BodyRows}>{calculateTotal(item2)}</Text>
								</View>
							);
						})}
					<View style={styles.ContainerBodyRows}>
						<Text style={styles.BodyRows1}>TOTAL/ИТОГО</Text>
						<Text style={styles.BodyRows}>
							{item1?.productInformation?.productInformation?.reduce(
								(previousValue, currentValue) =>
									previousValue + currentValue.quantity,
								0
							)}
						</Text>
						<Text style={styles.BodyRows}> </Text>
						<Text style={styles.BodyRows}>
							{" "}
							{(
								parseInt(
									item1?.productInformation?.productInformation?.reduce(
										(previousValue, currentValue) =>
											previousValue + currentValue.quantity,
										0
									)
								) * 0.1
							).toFixed(3) + "kg"}
						</Text>
						<Text style={styles.BodyRows}></Text>
						<Text style={styles.BodyRows}>
							{item1?.productInformation?.currency === "USD"
								? `$${totalSum}`
								: `€${totalSum}`}
						</Text>
					</View>
				</View>

				{/* Fifth Section */}
				<View style={styles.row}>
					<View style={styles.rowStart}>
						<Text style={styles.textBold}>Date: </Text>
						<Text>{moment().format("YYYY-MM-DD")}</Text>
					</View>
					<View>
						<Image src={stamp} style={styles.stampStyle} />
					</View>
				</View>
			</Page>
			{/*);*/}
			{/* })} */}
			{/*</Document>*/}
		</>
	);
};

const fRow = {
	diaplay: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
};
const fCol = {
	diaplay: "flex",
	flexDirection: "column",
	alignItems: "center",
	justifyContent: "center",
};

Font.register({
	// family: "SpoqaHanSans",
	family: "Roboto",
	// src: "https://cdn.jsdelivr.net/gh/spoqa/spoqa-han-sans@01ff0283e4f36e159ffbf744b36e16ef742da6d8/Subset/SpoqaHanSans/SpoqaHanSansLight.ttf",4
	src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf",
});
const styles = StyleSheet.create({
	page: {
		border: "2px solid #000",
		// fontFamily: "SpoqaHanSans",
		fontFamily: "Roboto",
		fontSize: 10,
	},
	row: {
		...fRow,
	},

	rowWrap: {
		...fRow,
		flexWrap: "wrap",
	},
	rowStart: {
		...fRow,
		justifyContent: "flex-start",
		alignItems: "center",
		margin: 3,
	},
	rowBarcode: {
		...fCol,
		justifyContent: "center",
		alignItems: "center",
		margin: 3,
		gap: 5,
	},

	col: {
		...fCol,
		width: "100%",
	},
	colMargin: {
		...fCol,
		width: "100%",
		margin: "15 0",
	},

	colStart: {
		...fCol,
		alignItems: "flex-start",
		justifyContent: "flex-start",
	},

	colBorder: {
		...fCol,
		border: "2px solid #000",
		alignItems: "flex-start",
		justifyContent: "flex-start",
		padding: 5,
		width: 250,
	},

	textAlignCenter: {
		textAlign: "center",
		padding: 2,
	},

	blue: {
		// color: "#369ed9",
		padding: 2,
	},
	text: {
		padding: 2,
	},

	textBold: {
		fontSize: 12,
		padding: 2,
	},

	table: {
		...fRow,
		width: "100%",
	},

	tableLeft: {
		...fCol,
		border: "2px solid #000",
		alignItems: "flex-start",
		justifyContent: "flex-start",
		width: "30%",
	},
	tableRight: {
		...fCol,
		border: "2px solid #000",
		alignItems: "flex-start",
		justifyContent: "flex-start",
		width: "70%",
	},

	tableRow: {
		...fRow,
		borderBottom: "1px solid #000",
		padding: "5px",
		width: "100%",
	},
	tableRowCustom: {
		...fRow,
		borderBottom: "1px solid #000",
		padding: "5px",
		width: "100%",
		height: "37px",
	},

	Container: {
		...fCol,
		width: "100%",
		margin: "15px 0",
	},

	ContainerHead: {
		...fCol,
		fontSize: 14,
		width: "100%",
		backgroundColor: "gray",
		padding: "3 0",
		border: "1px solid #000",
	},

	ContainerBody: {
		...fRow,
		border: "1px solid #000",
	},

	ContainerBodyTitle: {
		...fRow,

		width: "100%",
	},

	BodyTitle: {
		display: "flex",
		fontSize: 12,
		flex: 1,
		textAlign: "center",
		border: "0.5px solid #000",
		padding: 5,
	},
	ContainerBodyRows: {
		...fRow,

		width: "100%",
	},
	BodyRows: {
		display: "flex",
		flex: 1,
		textAlign: "center",
		alignItems: "center",
		border: "0.5px solid #000",
	},
	BodyRows1: {
		display: "flex",
		flex: 2,
		textAlign: "center",
		alignItems: "center",
		border: "0.5px solid #000",
	},
	barcodeStyle: {
		width: 200,
		height: 50,
	},

	stampStyle: {
		width: 100,
		height: 50,
		paddingRight: "20px",
	},
});
