/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback } from "react";
import axios from "axios";
import { pdf } from "@react-pdf/renderer";
import apiImage from "../../data/image.png";
import apiPdf from "../../data/waybill.pdf";
import { useBarCode } from "../../hooks/useBarCode";
import { NovaLabel } from "../../components/labels/NovaLabel";
import { LTPostR } from "../../components/labels/LTPostR";
import { LTPostL } from "../../components/labels/LTPostL";
import { PrintLabel } from "../../components/labels/PrintLabel";
import { UZPostEMS } from "../../components/labels/UZPostEMS";
import { UZPostR } from "../../components/labels/UZPostR";
import { NLPost } from "../../components/labels/NLPost";
import { GPSLabel } from "../../components/labels/GPSLabel";
import { LTPostPremium } from "../../components/labels/LTPostPremium";
import { LTPostPrime } from "../../components/labels/LTPostPrime";
import { LTPostRegistered } from "../../components/labels/LTPostRegistered";
import { EMULabel } from "../../components/labels/EMULabel";
import {
  blobToBase64,
  convertBase64ToUrl,
  convertPdfToImages,
  getWaybillBase64,
  getWaybillPack,
} from "../../utils/utils";
import MultipleLabels from "../../components/labels/MultipleLabels";
import ImagePrint from "../../components/labels/ImagePrint";

const usePrintWaybill = () => {
  const getBarCode = useBarCode();

  const handlePrint = (blobURL) => {
    let iframe = document.createElement("iframe"); //load content in an iframe to print later
    document.body.appendChild(iframe);
    iframe.style.display = "none";
    iframe.src = blobURL;
    iframe.onload = function () {
      setTimeout(function () {
        iframe.focus();
        iframe.contentWindow.print();
      }, 1);
    };
  };

  const generateNow = useCallback(async (props) => {
    const { selectedRequests } = props;
    // console.log({ selectedRequests });

    // for (var i = 0; i < selectedRequests.length; i++) {
    // const [request] = selectedRequests[0];
    // console.log(request);
    // console.log("req", selectedRequests);
    // debugger
    const type = selectedRequests.shippingType.apiType_.name;
    if (type === "BRAZIL POST" || type === "BPOST") {
      const base64 = await getWaybillBase64(selectedRequests.shippingType.id);
      if (base64) {
        return handlePrint(base64);
      }
    } else if (type === "UBI (Relabel)") {
      return <PrintLabel {...props} />;
    } else if (type === "PAACK") {
      const base64 = await getWaybillPack(selectedRequests.id);
      if (base64) {
        const pdfUrl = await convertBase64ToUrl(base64);
        const images = await convertPdfToImages(pdfUrl);
        if (images.length) {
          return images.map((img) => <ImagePrint src={img} {...props} />);
        }
      }
    } else if (
      type === "UBI (API)" ||
      type === "POSTPLUS (PRIME)" ||
      type === "POSTPLUS (EMS)" ||
      type === "UAEPOST (API)"
    ) {
      const base64 = await getWaybillBase64(selectedRequests.id, type);
      if (base64) {
        const pdfUrl = await convertBase64ToUrl(base64);
        const images = await convertPdfToImages(pdfUrl);
        if (images.length) {
          return images.map((img) => <ImagePrint src={img} {...props} />);
        }
      }
    }
    // else if (type === "UZ POST (EMS)") {
    //   const { data } = await axios.get(apiImage2, {
    //     responseType: "blob",
    //   });
    //   const blobURL = window.URL.createObjectURL(data);
    //   window.open(blobURL, "__blank");
    //   return;
    // }
    const getLabel = async () => {
      switch (type) {
        case "Nova postha (UA)": {
          return <NovaLabel {...props} />;
        }
        case "LT POST (R_CN22)": {
          return <LTPostR {...props} />;
        }

        case "LTPOST (L_CN22)": {
          return <LTPostL {...props} />;
        }

        case "UZ POST (R_CN22)": {
          return <UZPostR {...props} />;
        }

        case "LT POST (Prime)": {
          return <LTPostPrime {...props} />;
        }
        case "LT POST (Premium)": {
          return <LTPostPremium {...props} />;
        }

        case "LT POST (Registered)": {
          return <LTPostRegistered {...props} />;
        }

        case "NL POST (CN22)": {
          return <NLPost {...props} />;
        }
        case "UZ POST (EMS)": {
          return <UZPostEMS {...props} />;
        }
        case "GPS": {
          return <GPSLabel {...props} />;
        }
        case "EMU": {
          return <EMULabel {...props} />;
        }
        default: {
          return <PrintLabel {...props} />;
        }
      }
    };
    return await getLabel();
  }, []);

  const printRequestedLabels = async (rows, values, helpers) => {
    try {
      let finalGeneratedData = [];
      // selectedRequests?.forEach((item, index) =>
      // 	item.forEach(async (item1, index) => {

      // console.log("value", rows);

      for (let i = 0; i < rows.length; i++) {
        for (let j = 0; j < 1; j++) {
          if (rows[i][j].shippingType?.apiType_.name === "GPS") {
            const { url } = await getBarCode({
              text: rows[i][j].trackingNumber,
              bcid: "code128",
              height: 7,
              includetext: true,
            });

            const finalProps = {
              barcode: url,
              selectedRequests: rows[i][j],
            };

            finalGeneratedData.push(await generateNow(finalProps));
          } else {
            const { url } = await getBarCode({
              text: rows[i][j].shippingNumber
                ? rows[i][j].shippingNumber
                : rows[i][j].trackingNumber,
              bcid: "code128",
              height: 7,
              includetext: true,
            });
            // urlArr.push(url);
            // 	})
            // );
            const finalProps = {
              barcode: url,
              selectedRequests: rows[i][j],
              // ...values,
            };

            // console.log("finalProps", finalProps);
            finalGeneratedData.push(await generateNow(finalProps));
          }
        }
      }

      const blob = await pdf(
        await (<MultipleLabels data={finalGeneratedData.flat(1)} />)
      ).toBlob();
      const blobURL = window.URL.createObjectURL(blob);
      handlePrint(blobURL);

      // console.log({ urlArr });
      // debugger;

      // 	}
      // }
    } catch (err) {
      console.log(err);
    }
  };

  return { printRequestedLabels };
};

export default usePrintWaybill;
