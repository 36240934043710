import React from "react";

function CalendarIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="16"
      fill="none"
      viewBox="0 0 14 16"
    >
      <path
        stroke="#666"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M12.354 2.514H1.757A.757.757 0 001 3.27v10.597c0 .418.339.757.757.757h10.597a.757.757 0 00.757-.757V3.27a.757.757 0 00-.757-.757zM3.27 2.514V1m7.57 1.514V1M3.27 7.813h7.57M3.27 10.84h5.3"
      ></path>
    </svg>
  );
}

export default CalendarIcon;
