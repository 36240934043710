import {
  Box,
  Button,
  Divider,
  MenuItem,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import React, { useRef, useState } from "react";
import CustomButton from "../components/CustomButton";
import CustomCard from "../components/CustomCard";
import CustomInput, { SelectBox } from "../components/CustomInput";
import ConfirmModal from "./ConfirmModal";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import CreateHOC from "../SharedComponents/CreateHOC";
import UpdateHOC from "../SharedComponents/UpdateHOC";
import Moment from "moment";
// import {useMutation} from "react-query"
import { gql } from "@apollo/client/core";
import Toaster from "../utils/Toaster";
import { useMoveBags } from "../utils/utils";

function MasterManagementModal({ selected, onClose, ...props }) {
  return React.createElement(selected ? UpdateHOC : CreateHOC, {
    ...props,
    _resource: "MasterManagement",
    selected,
    handleClose: onClose,
    children: MasterManagementModalContainer,
  });
}

export default React.memo(MasterManagementModal);

const MasterManagementModalContainer = ({
  isOpen,
  handleClose,
  onOpen,
  selected,
  checkedList,
  onSuccess,
  ...hocProps
}) => {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const submitButton = useRef();
  const { updateBagsInMaster } = useMoveBags();
  const [initialValues, setInitialValues] = useState({
    masterNumber: "",
    flightNumber: "",
    date: Moment().format("YYYY-MM-DD"),
    departureAirPort: "",
    arrivalAirPort: "",
    DepartureTime: null,
    arrivalTime: null,
    deliveryStatus: "waiting for shipment",
    carrierAgentPhoneNo: "",
    memo: null,
  });

  React.useEffect(() => {
    if (selected) {
      const {
        id,
        bagIds: checkedList,
        masterNumber,
        flightNumber,
        date,
        departureAirPort,
        arrivalAirPort,
        DepartureTime,
        arrivalTime,
        deliveryStatus,
        carrierAgentPhoneNo,
        memo,
      } = selected;
      setInitialValues({
        id,
        bagIds: checkedList,
        masterNumber,
        flightNumber,
        date: Moment(date).format("YYYY-MM-DD"),
        departureAirPort,
        arrivalAirPort,
        DepartureTime,
        arrivalTime,
        deliveryStatus,
        carrierAgentPhoneNo,
        memo,
      });
    }
  }, [selected]);

  const handleMoveBagsToMasters = (values) => {
    if (!checkedList.length) {
      return Toaster(
        "error",
        "You have to select atleast one bag to perform this action."
      );
    }
    const updateBagsMutation = gql(`
  mutation assignBagsToNewMaster($input: MasterManagementCreateInputWithBags!){
    assignBagsToNewMaster(createInput: $input){
      id
    }
  }`);
    if (checkedList.length) {
      var array3 = [];
      array3.push({
        ...values,
        bagIds: checkedList,
      });

      updateBagsInMaster({
        id: checkedList,
        values,
        updateBagsMutation,
        responseKey: "updateBagsMutation",
        onSuccess: onSuccess,
      });
    }
  };

  const validationSchema = () => {
    return Yup.object().shape(
      {
        masterNumber: Yup.string()
          .max(190)
          .required("마스터번호는 필수 항목입니다."),
        flightNumber: Yup.string()
          .matches(
            new RegExp(/^[a-zA-Z0-9/*#\\]+$/),
            "Special symbols are not allowed except /, \\, * and #."
          )
          .required("항공편명은 필수 항목입니다."),
        date: Yup.date().required(),
        departureAirPort: Yup.string().required(),
        arrivalAirPort: Yup.string().required(),
        DepartureTime: Yup.date()
          .test(
            "DepartureTime",
            "Departure time must be less then arrival time.",
            function (departureTime) {
              const arrivalTime = this.parent.arrivalTime;
              if (arrivalTime)
                return Moment(departureTime).isBefore(
                  Moment(arrivalTime || new Date())
                );

              return true;
            }
          )
          .nullable(true),
        arrivalTime: Yup.date()
          .test(
            "arrivalTime",
            "Arrival time time must be greater then departure time.",
            function (arrivalTime) {
              const departureTime = this.parent.DepartureTime;
              if (departureTime) {
                return Moment(arrivalTime).isAfter(
                  Moment(departureTime || new Date())
                );
              }
              return true;
            }
          )
          .nullable(true),
        deliveryStatus: Yup.string().required(),
        memo: Yup.string().nullable(true),
        carrierAgentPhoneNo: Yup.string().nullable(true),
      },
      ["DepartureTime", "arrivalTime"]
    );
  };

  const handleSubmit = async (values, formikUtils) => {
    handleMoveBagsToMasters(values);
    // let input = {};
    // if (selected) {
    //   input = {
    //     updateInput: {
    //       ...values,
    //       date: Moment(values.date).startOf("day").format(),
    //     },
    //   };
    // } else {
    //   input = {
    //     createInput: {
    //       ...values,
    //       date: Moment(values.date).startOf("day").format(),
    //     },
    //   };
    // }

    // const res = await hocProps.handleSubmit({ ...input }, formikUtils);
    // if (res) {
    //   const { data, reason } = res;
    //   onSuccess(data, reason);
    //   setShowConfirmModal(false);
    // }
  };
  // const DeleteData = (id) =>  useMutation(deleteBag, {
  //   variables:  {id: id}, refetchQueries: ['deleteBag']
  // })
  return (
    <>
      <Modal
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          style={{
            padding: 20,
            overflowX: "hidden",
            minHeight: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            enableReinitialize
            validationSchema={validationSchema}
          >
            {({ errors, handleChange, touched, handleSubmit, values }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <CustomCard>
                    <Stack spacing={"20px"} width={500} p="20px">
                      <Typography fontSize={"20px"} fontWeight="700">
                        마스터 생성
                      </Typography>
                      <div>
                        <CustomInput
                          label={"마스터 번호"}
                          labelColor=""
                          name={"masterNumber"}
                          padding="10px 0"
                          value={values?.masterNumber}
                          onChange={handleChange}
                          error={!!errors.masterNumber}
                          helperText={
                            !!errors.masterNumber && errors.masterNumber
                          }
                          placeholder="마스터 번호를 입력해주세요."
                        />

                        <CustomInput
                          label={"항공편명"}
                          labelColor=""
                          name={"flightNumber"}
                          padding="10px 0"
                          value={values?.flightNumber}
                          onChange={handleChange}
                          placeholder={"항공편명을 입력해주세요."}
                          error={!!errors.flightNumber}
                          helperText={
                            !!errors.flightNumber && errors.flightNumber
                          }
                        />

                        <CustomInput
                          label={"날짜"}
                          labelColor=""
                          padding="10px 0"
                          name={"date"}
                          value={values.date}
                          inputProps={{
                            min: Moment().format("YYYY-MM-DD"),
                          }}
                          onChange={handleChange}
                          error={!!errors.date}
                          type="date"
                          placeholder={"출발 날짜를 입력해주세요."}
                        />

                        <CustomInput
                          label={"출발 공항"}
                          labelColor=""
                          name={"departureAirPort"}
                          value={values.departureAirPort}
                          error={!!errors.departureAirPort}
                          onChange={handleChange}
                          padding="10px 0"
                          placeholder={"출발 공항을 입력해주세요."}
                        />

                        <CustomInput
                          label={"도착 공항"}
                          name={"arrivalAirPort"}
                          value={values.arrivalAirPort}
                          onChange={handleChange}
                          error={!!errors.arrivalAirPort}
                          labelColor=""
                          padding="10px 0"
                          placeholder="도착 공항을 입력해주세요."
                        />

                        <CustomInput
                          label={"출발 시간"}
                          labelColor=""
                          name={"DepartureTime"}
                          value={values.DepartureTime}
                          onChange={handleChange}
                          inputProps={{
                            min: (() => {
                              return Moment().format("Y-MM-DDTHH:mm");
                            })(),
                          }}
                          helperText={
                            !!errors.DepartureTime && errors.DepartureTime
                          }
                          error={!!errors.DepartureTime}
                          padding="10px 0"
                          type="datetime-local"
                          placeholder={"출발 시간을 입력해주세요."}
                        />

                        <CustomInput
                          label={"도착 시간"}
                          labelColor=""
                          name={"arrivalTime"}
                          value={values.arrivalTime}
                          inputProps={{
                            min: Moment().format("Y-MM-DDTHH:mm"),
                          }}
                          helperText={
                            !!errors.arrivalTime && errors.arrivalTime
                          }
                          onChange={handleChange}
                          error={!!errors.arrivalTime}
                          padding="10px 0"
                          type="datetime-local"
                          placeholder={"도착 시간을 입력해주세요."}
                        />

                        <CustomInput
                          label={"Carrier agent"}
                          labelColor=""
                          name={"carrierAgentPhoneNo"}
                          value={values.carrierAgentPhoneNo}
                          helperText={
                            !!errors.carrierAgentPhoneNo &&
                            errors.carrierAgentPhoneNo
                          }
                          onChange={handleChange}
                          error={!!errors.carrierAgentPhoneNo}
                          padding="10px 0"
                          type="text"
                          placeholder={
                            "Carrier agent의 전화번호를 입력해주세요."
                          }
                        />

                        <CustomInput
                          label={"메모"}
                          labelColor=""
                          name={"memo"}
                          value={values.memo}
                          onChange={handleChange}
                          error={!!errors.memo}
                          padding="10px 0"
                          placeholder={"메모를 입력해주세요."}
                        />
                      </div>

                      <Divider />

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          gap: "20px",
                        }}
                      >
                        <CustomButton
                          styles={{
                            width: "auto",
                          }}
                          onClick={() => {
                            // handleClose();
                            setShowConfirmModal(true);
                          }}
                        >
                          전송
                        </CustomButton>
                        <CustomButton
                          onClick={handleClose}
                          styles={{
                            backgroundColor: "#F8F8FA",
                            color: "#000",
                            width: "auto",
                          }}
                        >
                          취소
                        </CustomButton>

                        <Button
                          ref={submitButton}
                          type={"submit"}
                          hidden={true}
                        ></Button>
                      </div>
                    </Stack>
                  </CustomCard>
                </Form>
              );
            }}
          </Formik>
        </Box>
      </Modal>
      <ConfirmModal
        isOpen={showConfirmModal}
        onClose={() => {
          setShowConfirmModal(false);
        }}
        onConfirm={() => {
          if (submitButton) {
            const { current } = submitButton;
            current.click();
          }
        }}
        title="마스터 생성"
        description={"마스터를 생성하시겠습니까?"}
      />
    </>
  );
};
