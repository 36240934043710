export default function NLPostMasterTable({ master }) {

  const CalculateCreationDateHK = () => {
    const creationDate = master?.createdAt.split("T")[0];
    const year = creationDate ? creationDate.split("-")[0] : "";
    const month = creationDate ? creationDate.split("-")[1] : "";
    const date = creationDate ? creationDate.split("-")[2] : "";

    return "HNKCOMBI"+date+month+year;
  }

  const CalculateCreationDate = () => {
    const creationDate = master?.createdAt.split("T")[0];
    const year = creationDate? creationDate.split("-")[0] : "";
    const month = creationDate ? creationDate.split("-")[1] : "";
    const date = creationDate ? creationDate.split("-")[2] : "";

    return date+"/"+month+"/"+year;
  }

  const CalculateReleaseDate = () => {
    const creationDate = master?.releaseDate.split("T")[0];
    const year = creationDate ? creationDate.split("-")[0] : "";
    const month = creationDate ? creationDate.split("-")[1] : "";
    const date = creationDate ? creationDate.split("-")[2] : "";

    return date+"/"+month+"/"+year;
  }

  const CalculateQty = () => {
      const bags = master?.bags;
      let tPrice = 0;
      let curr = "";
      let tWeight = 0;
      let tQty = 0;

      bags.forEach((bag) => {
        const receptionLists = bag?.receptionLists;

        receptionLists.forEach((receptionList) => {
          curr = receptionList.productInformation.currency;
          tPrice = tPrice + receptionList.productInformation.totalPriceAmount;
          tWeight = tWeight + bag.usedWeight;
          tQty = tQty + receptionList.productInformation.productInformation[0].quantity;
        })
      })

      return tQty;
  }

  const CalculateWeight = () => {
    const bags = master?.bags;
    let tPrice = 0;
    let curr = "";
    let tWeight = 0;
    let tQty = 0;

    bags.forEach((bag) => {
      const receptionLists = bag?.receptionLists;

      receptionLists.forEach((receptionList) => {
        curr = receptionList.productInformation.currency;
        tPrice = tPrice + receptionList.productInformation.totalPriceAmount;
        tWeight = tWeight + bag.usedWeight;
        tQty = tQty + receptionList.productInformation.productInformation[0].quantity;
      })
    })

    return "KG  "+tWeight;
  }

  const CalculatePrice = () => {
    const bags = master?.bags;
    let tPrice = 0;
    let curr = "";
    let tWeight = 0;
    let tQty = 0;

    bags.forEach((bag) => {
      const receptionLists = bag?.receptionLists;

      receptionLists.forEach((receptionList) => {
        curr = receptionList.productInformation.currency;
        tPrice = tPrice + receptionList.productInformation.totalPriceAmount;
        tWeight = tWeight + bag.usedWeight;
        tQty = tQty + receptionList.productInformation.productInformation[0].quantity;
      })
    })

    return curr+"  "+tPrice;
  }



  return (
    <table border={1}>
      <tr>
        <td colSpan="10" id="sjs-A1">
          Summary Manifest
        </td>
      </tr>  
      <tr>
        <td colSpan="10" id="sjs-A2">
          For custom purposes only
        </td>
      </tr> 

      <tr>
        <td colSpan="4" rowSpan="5" id="sjs-A3">
          From:<br/>
          H3 networks  co., ltd.<br/>
          #B103, Hwagok-ro 416 The Sky Valley 5th<br/>
          Gangseo-gu, Seoul . 07548<br/>
          Republic of Korea<br/>
          Phone: +82-10-2304-1331
        </td>
      </tr> 
      <tr>
        <td id="sjs-A4"></td>
      </tr>
      <tr>
        <td id="sjs-A5"></td>
      </tr>
      <tr>
        <td id="sjs-A6"></td>
      </tr>
      <tr>
        <td id="sjs-A7"></td>
      </tr>


      <tr>
        <td colSpan="4" rowSpan="2" id="sjs-A8">
          Org.nr:
        </td>
      </tr>

      <tr>
        <td id="sjs-A9"></td>
      </tr>

      <tr>
        <td colSpan="4" rowSpan="6" id="sjs-A10">
          Name and complete address shipper<br/>
          H3 networks  co., ltd.<br/>
          #B103, Hwagok-ro 416 The Sky Valley 5th<br/>
          Gangseo-gu, Seoul . 07548<br/>
          Republic of Korea<br/>
        </td>
        <td></td>
        <td>Name and complete address distributor</td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td>Final Addresses</td>
      </tr> 

      <tr>
        <td></td>
        <td>PostNL International Mail Unit</td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td>Multiple Addresses</td>
      </tr>
      <tr>
        <td></td>
        <td>Menzies Avaition Cargo AMS</td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td>in various countries</td>
      </tr>
      <tr>
        <td></td>
        <td>Anchoragelaan 50 / Dock 15</td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td>see for individual addresses</td>
      </tr>
      <tr>
        <td></td>
        <td>1118 LE Schiphol</td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td>enclosed manifest</td>
      </tr>

      <tr>
        <td></td>
        <td>The Netherlands</td>
      </tr>

      <tr></tr>

      <tr>
        <td colSpan="4"></td>
        <td></td>
        <td>Terms of Delivery:</td>
      </tr>

      <tr>
        <td colSpan="4"></td>
        <td></td>
        <td>Location and date:</td>
        <td>Republic of Korea</td>
        <td>date ship ex KR</td>
        <td><CalculateReleaseDate /></td>   {/* Release Date */}
      </tr>

      <tr>
        <td rowSpan="3">Manifest Number (Aut .no.):</td>
        <td><CalculateCreationDateHK/></td>   {/* HNKNCOMBI ddmmyy */}
      </tr>

      <tr></tr>
      <tr></tr>

      <tr>
        <td>Total number of packets:</td>
        <td><CalculateQty/></td>   {/* Total number of boxes */}
      </tr>


      <tr rowSpan="2">
        <td data-t="s">Goods Description</td>
        <td data-t="s">HS-Classification No.</td>
        <td data-t="s">Indication of Origin</td>
        <td data-t="s">Invoice Amount (USD)</td>
        <td data-t="s">Weight KG</td>
        <td data-t="s">Lines in shipment TOTAL</td>
      </tr>

      {
        master?.bags.map((bag) => (
          bag?.receptionLists.map((receptionList) => (
            <tr>
              <td data-t="s">{receptionList.productInformation.productInformation[0].productName}</td>
              <td data-t="s">{receptionList.productInformation.productInformation[0].hscode}</td>
              <td data-t="s">KR</td>
              <td data-t="s">{receptionList.productInformation.currency} {receptionList.productInformation.totalPriceAmount}</td>
              <td data-t="s">{bag.usedWeight}</td>
              <td data-t="s">{receptionList.productInformation.productInformation[0].quantity}</td>
            </tr>
          ))
        ))
      }

      <tr></tr>
      <tr></tr>
      <tr></tr>
      <tr></tr>
      <tr></tr>
      <tr></tr>
      <tr></tr>
      <tr>
        <td>Totals:</td>
        <td></td>
        <td></td>
        <td><CalculatePrice /></td>
        <td><CalculateWeight/></td>
        <td><CalculateQty/></td>
      </tr>
     
      <tr></tr>
      <tr></tr>
      <tr>
        <td></td>
        <td></td>
        <td>Republic of Korea</td>
        <td><CalculateReleaseDate /></td>
      </tr>

      <tr>
        <td></td>
        <td></td>
        <td>Location, Data and Signature</td>
      </tr>

      <tr></tr>
      <tr></tr>

      <tr>
        <td></td>
        <td></td>
        <td>Printed Name</td>
      </tr>
    </table>
  );
}
