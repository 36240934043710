import ListHOC from "../../SharedComponents/ListHOC";
import React from "react";
import StatsContainer from "../../components/Stats/Container";

function AdminStats(props) {
  return (
    <ListHOC {...props}>
      <StatsContainer isAdmin={true} />
    </ListHOC>
  );
}

export default AdminStats;
