import { APIClient, APIClientNoCache } from "../../../../utils/apiGraphql";
import { gql } from "@apollo/client/core";

//Gets all the shipping countries
export const getAllCountries = async () => {
	const queryAllCountries = gql(`query allShippingCountries{
    allShippingCountries{
      name
      id
      shippingTypesDetailsIds
      code
      isActive
      deliveryType{
        id
        deliveryType
        isActive
      }
    }
  }
    `);

	const res = await APIClientNoCache.query({
		query: queryAllCountries,
	});
	return res;
};

//Gets all the users group
export const getAlluserGroups = async () => {
	const queryAllUserGroups = gql(`query allUserGroups{
      allUserGroups{
        name
        id
        default
      }
    }
      `);

	const res = await APIClientNoCache.query({
		query: queryAllUserGroups,
		// variables: {
		//   page: 1,
		// },
	});
	return res;
};

//Get shipping details
export const getShippingDetails = async (sid) => {
	const query = gql(`
  query allShippingTypeDetails($filter: ShippingServicesFilterInput!){
    allShippingTypeDetails(filter: $filter){
      id
      memo
      shippingType
    }
  }
  `);

	const res = await APIClientNoCache.query({
		query: query,
		variables: {
			filter: { id: sid },
		},
	});
	return res;
};

//Create general rate management
export const createGeneralRateMgmt = async (data) => {
	const mutation =
		gql(`mutation createRatesManagement_V2($createInput: RatesManagementCreateInput_V2!){
        createRatesManagement_V2(createInput: $createInput){
          id
        }
      }
        `);

	const res = await APIClient.mutate({
		mutation: mutation,
		variables: {
			createInput: data,
		},
	});
	return res;
};

//Create rate management
export const createRateMgmt = async (data) => {
	const mutation =
		gql(`mutation createRatesManagement($createInput: RatesManagementCreateInput!){
        createRatesManagement(createInput: $createInput){
          id
        }
      }
        `);

	const res = await APIClient.mutate({
		mutation: mutation,
		variables: {
			createInput: data,
		},
	});
	return res;
};

//Update rate management
export const updateRateMgmt = async (data) => {
	const mutation =
		gql(`mutation updateRatesManagement($updateInput: RatesManagementUpdateInput!){
        updateRatesManagement(updateInput: $updateInput){
          id
        }
      }
        `);

	const res = await APIClient.mutate({
		mutation: mutation,
		variables: {
			updateInput: data,
		},
	});
	return res;
};

//Get the rates by group
export const getRatesByGroup = async () => {
	const mutation = gql(`
    query allUserGroups{
      allUserGroups{
        id
        name
        default
        memo
        users{
          customer_name
          company_name_en
          memo
        }
        rateManagement{
          id
        }
      }
    }
    `);

	const res = await APIClientNoCache.query({
		query: mutation,
	});
	return res;
};

//Get all rates management
export const getRatesManagement = async (filter) => {
	const query = gql(`
    query allRatesManagements($filter: RatesManagementFilterInput!){
      allRatesManagements(filter: $filter){
        id
        ratesDetails{
          id
          weight
          deliveryFee
          shippingTypeDetailId
        }
      }
    }
    `);

	const res = await APIClientNoCache.query({
		query: query,
		variables: {
			filter: filter,
		},
	});
	return res;
};

//Create userGroup
export const createUserGroup = async (data) => {
	const mutation = gql(`
  mutation createUserGroup($createInput: UserGroupCreateInput!){
      createUserGroup(createInput: $createInput){
        id
        name
        users{
          customer_name
          company_name_en
          memo
        }
        rateManagement{
          id
        }
      }
    }
    `);

	const res = await APIClient.mutate({
		mutation: mutation,
		variables: {
			createInput: data,
		},
	});
	return res;
};

//Delete userGroup
export const deleteuserGroup = async (gid) => {
	const mutation = gql(`
  mutation deleteUsersGroup($id: UserGroupDeleteInput!){
    deleteUsersGroup(id: $id){
        id
        name
        users{
          customer_name
          company_name_en
          memo
        }
        rateManagement{
          id
        }
      }
    }
    `);

	const res = await APIClient.mutate({
		mutation: mutation,
		variables: {
			id: { id: gid },
		},
	});
	return res;
};

//Get group by id
export const getUserGroup = async (id) => {
	const query = gql(`
  query getOneUserGroup($id: Float!){
    getOneUserGroup(id: $id){
      id
      name
      memo
      users{
        id
        company_name_en
        company_name_kr
      }
      rateManagement{
        countryId
        country{
          name
        }
        ratesDetails{
          weight
          deliveryFee
        }
      }
    }
  }
  `);

	try {
		const res = await APIClientNoCache.query({
			query: query,
			variables: {
				id: id,
			},
		});
		return res;
	} catch (e) {
		return e;
	}
};

//Update userGroup
export const updateUserGroup = async (data) => {
	const mutation = gql(`
  mutation updateUserGroup($updateInput: UserGroupUpdateInput!){
      updateUserGroup(updateInput: $updateInput){
        id
        name
    }
  }
    `);

	const res = await APIClient.mutate({
		mutation: mutation,
		variables: {
			updateInput: data,
		},
	});
	return res;
};

//Get all companies
export const getAllCompanies = async () => {
	const queryAllCompanies = gql(`query allCompanyLists{
    allCompanyLists{
      id
      company_name_en
    }
  }
    `);

	const res = await APIClientNoCache.query({
		query: queryAllCompanies,
	});
	return res;
};

//Add company to group
export const addCompanyToUserGroup = async (data) => {
	const mutation = gql(`
  mutation updateUsersGroup($updateInput: UpdateUsersGroupInput!){
      updateUsersGroup(updateInput: $updateInput){
        id
        company_name_en
        company_name_kr
      }
    }
    `);

	const res = await APIClient.mutate({
		mutation: mutation,
		variables: {
			updateInput: data,
		},
	});
	return res;
};

//Remove company from group
export const removeCompanyFromUserGroup = async (id) => {
	const mutation = gql(`
  mutation removeuser($id: Float!){
    removeUserGroup(id: $id){
      id
    }
  }
    `);

	const res = await APIClient.mutate({
		mutation: mutation,
		variables: {
			id: id,
		},
	});
	return res;
};

//Create or update memo inside of a user group
export const createMemousergroup = async (data) => {
	const mutation = gql(`
  mutation updateUserGroup($updateInput: UserGroupUpdateInput!){
    updateUserGroup(updateInput:$updateInput){
        id,
        name,
        memo
      }
    }
    `);

	const res = await APIClient.mutate({
		mutation: mutation,
		variables: {
			updateInput: data,
		},
	});
	return res;
};

//Update default group
export const updateDefaultUsergroup = async (id) => {
	const mutation = gql(`
  mutation setDefaultGroup($id: Float!){
    setDefaultGroup(id:$id){
        id,
        name,
        memo
      }
    }
    `);

	const res = await APIClient.mutate({
		mutation: mutation,
		variables: {
			id: id,
		},
	});
	return res;
};

//Delete rate
export const deleteRate = async (id) => {
	const mutation = gql(`
  mutation deleteRatesManagement($id: RateManagementDeleteInput!){
    deleteRatesManagement(id: $id){
        id
      }
    }
    `);

	const res = await APIClient.mutate({
		mutation: mutation,
		variables: {
			id: id,
		},
	});
	return res;
};

export const deleteRateDetail = async (id) => {
	const mutation = gql(`
    mutation deleteRatesDetails($id: RateManagementDeleteInput!){
      deleteRatesDetails(id: $id){
          id
        }
      }
      `);
	const res = await APIClient.mutate({
		mutation: mutation,
		variables: {
			id: id,
		},
	});
	return res;
};
