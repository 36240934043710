import { gql } from "@apollo/client/core";
const UPDATE_REQUEST_WEIGHT = gql`
  mutation updateWeight($updateInput: UpdateWeightInput!) {
    updateWeight(updateInput: $updateInput) {
      id
      actualWeight
      applicableWeight
      bulkWeight
      horizontal
      portrait
      height
    }
  }
`;

const UPDATE_REQUEST_SHIPPING = gql`
  mutation updateRequestShipping($updateInput: RequestShippingUpdateInput!) {
    data: updateRequestShipping(updateInput: $updateInput) {
      id
      bag {
        id
      }
    }
  }
`;

const UPDATE_API_SHIPPING_NUMBER = gql`
  mutation updateAPIShippingNumber($id: Float!) {
    updateAPIShippingNumber(id: $id) {
      apiStatus
      labelStatus
    }
  }
`;

export {
  UPDATE_REQUEST_WEIGHT,
  UPDATE_REQUEST_SHIPPING,
  UPDATE_API_SHIPPING_NUMBER,
};
