import React, { useCallback, useEffect, useState } from "react";
import { Grid, IconButton, MenuItem, Stack } from "@mui/material";
import CustomButton from "../../components/CustomButton";
import TableContainer from "../../components/TableContainer";
import { onDropDownSearchChange } from "../../utils/onDropDownSearchChange";
import { Form, Formik } from "formik";
import Moment from "moment";
import {
	generateSearchData,
	handleOtherSearch,
	useGetList,
} from "../../utils/utils";
import DateRangePicker from "../../components/DateRangePicker";
import { SelectBox, TextFieldBox } from "../CustomInput";
import CalendarIcon from "../../Common/assets/Images/CalendarIcon";
import SearchIcon from "../../Common/assets/Images/SearchIcon";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import DownloadExcelButton from "../DownloadExcelButton";

export default function StatsContainer({
	data: _Data,
	perPage,
	handlePageChange,
	handleSearch,
	isAdmin = false,
	excelData,
	...hocProps
}) {
	const [data, setData] = useState([]);
	const [companies, setCompanies] = useState([]);
	const [filterQuery, setFilterQuery] = useState(null);
	const [countryName, setCountryName] = useState("");
	const { getData } = useGetList();
	const [columnsName, setColumnsName] = useState(
		isAdmin
			? {
					국가: "countryName",
					"데이터 업로드": "uploadData",
					"데이터 삭제 건": "deletedData",
					"보류 건": "onHoldCount",
					"입고 완료": "completedStatus",
					"출고 완료": "deliveryStatus",
					수출신고건: "exportReport",
					배송중: "totalShipping",
					배송완료: "deliveryStatus",
					매출: "totalSales",
			  }
			: {
					국가: "countryName",
					"데이터 업로드": "uploadData",
					"데이터 삭제 건": "deletedData",
					"보류 건": "onHoldCount",
					"입고 완료": "completedStatus",
					"출고 완료": "deliveryStatus",
					수출신고건: "exportReport",
					배송중: "totalShipping",
					배송완료: "deliveryStatus",
			  }
	);

	const doSearch = useCallback(() => {
		generateSearchData(handleSearch, filterQuery);
	}, [filterQuery]);

	useEffect(() => {
		if (_Data.length > 0) {
			let dataSet = {
				uploadData: 0,
				deletedData: 0,
				onHoldCount: 0,
				completedStatus: 0,
				deliveryStatus: 0,
				exportReport: 0,
				totalShipping: 0,
				totalSales: 0,
			};

			Object.entries(dataSet).map(([key, value]) => {
				if (key !== "CountryName") {
					_Data.forEach((item) => {
						// console.log("items", key, value);
						// console.log("item", item);
						value = value + item[key];
					});
					return (dataSet[key] = value);
				}
			});

			const newData = {
				countryName: "Total",
				uploadData: dataSet.uploadData,
				deletedData: dataSet.deletedData,
				onHoldCount: dataSet.onHoldCount,
				completedStatus: dataSet.completedStatus,
				deliveryStatus: dataSet.deliveryStatus,
				exportReport: dataSet.exportReport,
				totalShipping: dataSet.totalShipping,
				totalSales: dataSet.totalSales,
			};
			_Data.unshift(newData);
			setData(_Data);
		}
	}, [_Data]);

	useEffect(() => {
		doSearch();
	}, [filterQuery]);

	useEffect(() => {
		(async () => {
			const data = await getData("CompanyList");
			setCompanies(data);
		})();
	}, []);

	return (
		<>
			<Stack spacing={"35px"}>
				<Grid container justifyContent={"space-between"}>
					<Grid container alignItems={"center"} spacing="10px">
						<Grid item xs={2.6}>
							<DateRangePicker
								showLabel={false}
								name={"createdAt"}
								value={
									filterQuery?.["createdAt"] ||
									`${Moment().format("YYYY-MM-DD")} - ${Moment().format(
										"YYYY-MM-DD"
									)}`
								}
								handleChange={(value) => {
									handleOtherSearch(value, setFilterQuery);
								}}
								iconEnd={<CalendarIcon />}
							/>
						</Grid>

						<Grid item xs={1}>
							{isAdmin && (
								<SelectBox
									name={"companyId"}
									onChange={({ target }) => {
										const { name, value } = target;
										handleOtherSearch({ [name]: value }, setFilterQuery);
									}}
									defaultValue={"all"}
									value={filterQuery?.companyId}
								>
									<MenuItem value={"all"}>전체</MenuItem>
									{companies.map(({ company_name_en, id }, index) => {
										return (
											<MenuItem key={index + company_name_en} value={id}>
												{company_name_en}
											</MenuItem>
										);
									})}
								</SelectBox>
							)}
						</Grid>

						{/* <Grid item xs={4} flexGrow={1} sx={{ display: "flex", gap: "5px" }}>
							{isAdmin && (
								<>
									<TextFieldBox
										onChange={(e) => setCountryName(e.target.value)}
										onKeyDown={(event) => {
											if (event.keyCode === 13) {
												// handleOnChange();
											}
										}}
										name="value"
										value={filterQuery?.value}
										placeholder={"고객사명을 입력해주세요."}
										style={{ height: "43px" }}
									/>
									<CustomButton
										style={{
											height: "43px",
											borderRadius: "10px",
											backgroundColor: "#5B8FF7",
											width: "71px",
										}}
										onClick={() =>
											handleOtherSearch(
												{ companyId: countryName },
												setFilterQuery
											)
										}
									>
										검색
									</CustomButton>
								</>
							)} */}

						{/* </Grid> */}
						<Grid item xs={6.9} />
						<Grid xs={1.5} item>
							{excelData.length > 0 && (
								<DownloadExcelButton
									columnsData={excelData}
									columnsName={columnsName}
									fileName={"통계"}
								/>
							)}
						</Grid>
					</Grid>
				</Grid>

				<div style={{ position: "relative" }}>
					<TableContainer
						{...hocProps}
						headers={Object.keys(columnsName).map((item) => item)}
						data={data.map((item, index) => {
							if (isAdmin) {
								return [
									item.countryName,
									item.uploadData,
									item.deletedData,
									item.onHoldCount,
									item.completedStatus,
									item.deliveryStatus,
									item.exportReport,
									item.totalShipping,
									<></>,
									item.totalSales,
								];
							} else {
								return [
									item.countryName,
									item.uploadData,
									item.deletedData,
									item.onHoldCount,
									item.completedStatus,
									item.deliveryStatus,
									item.exportReport,
									item.totalShipping,
									<></>,
								];
							}
						})}
					/>
				</div>
			</Stack>
		</>
	);
}
