import React, { useRef } from "react";
import * as XLSX from "xlsx";
import pdfMake from "pdfmake";
import domtoimage from "dom-to-image-more";
import html2canvas from "html2canvas";
import ImagePrint from "../../../components/labels/ImagePrint";
import { blobToBase64, DeliveryParcel } from "../../../utils/utils";
import { OverSeasDelivery } from "../../../components/labels/OverseasDeliveryLabel";
import { pdf } from "@react-pdf/renderer";
import { handlePrint } from "./print";
import MultipleLabels from "../../../components/labels/MultipleLabels";
// import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import overseasImage from "../../../Common/assets/Images/overseasInfo.png";

export default function useExcelToPdf() {
	/* the workbook object is the state */
	const [workbook, setWorkbook] = React.useState(XLSX.utils.book_new());
	const [base64String, setBase64String] = React.useState(false);
	pdfMake.vfs = pdfFonts.pdfMake.vfs;

	pdfMake.fonts = {
		SpoqaHanSans: {
			normal:
				"https://cdn.jsdelivr.net/gh/spoqa/spoqa-han-sans@01ff0283e4f36e159ffbf744b36e16ef742da6d8/Subset/SpoqaHanSans/SpoqaHanSansLight.ttf",

			bold: "https://cdn.jsdelivr.net/gh/spoqa/spoqa-han-sans@01ff0283e4f36e159ffbf744b36e16ef742da6d8/Subset/SpoqaHanSans/SpoqaHanSansLight.ttf",

			italics:
				"https://cdn.jsdelivr.net/gh/spoqa/spoqa-han-sans@01ff0283e4f36e159ffbf744b36e16ef742da6d8/Subset/SpoqaHanSans/SpoqaHanSansLight.ttf",

			bolditalics:
				"https://cdn.jsdelivr.net/gh/spoqa/spoqa-han-sans@01ff0283e4f36e159ffbf744b36e16ef742da6d8/Subset/SpoqaHanSans/SpoqaHanSansLight.ttf",
		},
	};

	function base64(props, image) {
		props.SheetNames.map((name, key) => {
			const jsonData = XLSX.utils.sheet_to_json(props.Sheets[name]);
			// console.log({ jsonData });
			const deliveryData = DeliveryParcel(jsonData);
			const docDefinition = OverSeasDelivery(deliveryData, image);

			pdfMake.createPdf(docDefinition).open();
		});
	}

	function excelToPdf(prop, ImageBlob) {
		if (prop) {
			(async () => {
				try {
					const res = await fetch(prop).catch((err) => {
						console.error(err, "err");
					});

					const wb = XLSX.read(await res.arrayBuffer());
					// console.log({ wb });
					base64(wb, ImageBlob);
				} catch (error) {
					console.error(error);
				}
			})();
		}
	}
	return [excelToPdf];
}
