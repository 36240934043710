import {
	Box,
	Divider,
	MenuItem,
	Modal,
	Stack,
	Typography,
} from "@mui/material";
import CustomCard from "../components/CustomCard";
import { useGetList, useCreate } from "react-admin";
import { useState, useEffect } from "react";
import CustomButton from "../components/CustomButton";
import React from "react";
import CustomInput, { SelectBox } from "../components/CustomInput";
import { gql } from "@apollo/client/core";
import { APIClient } from "../utils/apiGraphql";
import Toaster from "../utils/Toaster";

export default function WaybillModal({ isOpen, onClose, isLoading = false }) {
	const { data, total, error } = useGetList("ShippingTypes", {
		sort: { field: "published_at", order: "DESC" },
		pagination: { page: 1, perPage: {} },
	});
	const [deliveryType, setDeliveryType] = useState(null);
	const [deliveryName, setDeliveryName] = useState(null);
	const [shippingData, setShippingData] = useState(null);

	useEffect(() => {
		async function GetRates() {
			if (deliveryType) {
				const getShippingData = gql(`
					query getWaybillStats($deliveryServiceId:Float!){
						getWaybillStats(deliveryServiceId:$deliveryServiceId){
							totalNumber,
							used,
							remaining
					}
				}
				`);
				try {
					const res = await APIClient.query({
						query: getShippingData,
						variables: {
							deliveryServiceId: deliveryType,
						},
					});
					setShippingData(res.data.getWaybillStats);
				} catch (e) {
					Toaster("error", e.message);
				}
			}
		}
		GetRates();
	}, [deliveryType]);

	useEffect(() => {
		if (deliveryType) {
			data.forEach((item) => {
				item?.details.forEach((item2) => {
					if (item2.id === deliveryType) {
						setDeliveryName(item2.shippingType);
					}
				});
			});
		}
	}, [deliveryType]);
	return (
		<>
			<Modal
				open={isOpen}
				onClose={onClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
				sx={{ overflow: "scroll" }}
			>
				<Box
					style={{
						padding: 20,
						overflowX: "hidden",
						minHeight: "100vh",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					<CustomCard>
						<Box display={"flex"} flexDirection={"column"} gap={2} p={3}>
							<Stack spacing={"20px"} width={350} p={1}>
								<Box display={"flex"} flexDirection={"column"} gap={2}>
									<Typography fontWeight={"bold"} variant={"h5"}>
										운송장 사용 현황
									</Typography>

									<SelectBox
										defaultValue={"배송 타입"}
										value={deliveryType}
										onChange={(e) => {
											setDeliveryType(e.target.value);
										}}
									>
										<MenuItem value="배송 타입" disabled hidden>
											배송 타입
										</MenuItem>
										{data &&
											data.map((item, index) => {
												return item.details.map((item2, index) => (
													<MenuItem value={item2.id} key={index}>
														{item2.shippingType}
													</MenuItem>
												));
											})}
									</SelectBox>

									<Box
										display={"flex"}
										flexDirection={"column"}
										className={"waybill-container"}
									>
										<CustomInput
											input={
												<Typography style={{ padding: 10 }}>
													{deliveryName}
												</Typography>
											}
											label="배송 타입"
										/>
										<CustomInput
											input={
												<Typography style={{ padding: 10 }}>
													{shippingData?.totalNumber}
												</Typography>
											}
											label="전체 갯수"
										/>
										<CustomInput
											input={
												<Typography style={{ padding: 10 }}>
													{shippingData?.remaining}
												</Typography>
											}
											label="남은 갯수"
										/>
										<CustomInput
											input={
												<Typography style={{ padding: 10 }}>
													{shippingData?.used}
												</Typography>
											}
											label="사용 갯수"
										/>
									</Box>
								</Box>
							</Stack>
							<Divider />
							<Box textAlign={"right"}>
								<CustomButton
									onClick={onClose}
									styles={{
										background: "#F8F8FA",
										color: "#000",
										width: "auto",
									}}
								>
									닫기
								</CustomButton>
							</Box>
						</Box>
					</CustomCard>
				</Box>
			</Modal>
		</>
	);
}
