import { Grid, Link, Divider } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import styled from "styled-components";

import useWebSocket from "react-use-websocket";

import CustomInput from "../CustomInput";
import { useState } from "react";
import { FIND_REQUEST_SHIPPING } from "./queries";
import { useQuery } from "@apollo/client";
import { useAtom } from "jotai";
import { shippingProcessingNumberAtom } from "./atom";

const RequestInfo = () => {
  const [shippingProcessingNumber] = useAtom(shippingProcessingNumberAtom);
  const { data } = useQuery(FIND_REQUEST_SHIPPING, {
    variables: { term: shippingProcessingNumber },
    fetchPolicy: "cache-only",
  });
  const { findRequestShipping: detail } = data || {};

  return (
    <Grid
      container
      style={{
        borderRadius: "10px",
        overflow: "hidden",
        border: "1px solid #E5E5E5",
      }}
    >
      <Grid item xs={6}>
        <CustomInput
          input={<Span>{detail?.shippingCountry?.name}</Span>}
          label="배송국가"
          padding="14px 20px 10px 20px"
          labelWidth="55%"
        />
      </Grid>

      <Grid item xs={6}>
        <CustomInput
          input={<Span>{detail?.shippingType?.shippingType}</Span>}
          label="배송 타입"
          labelWidth="55%"
          padding="14px 20px 10px 20px"
        />
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={6}>
        <CustomInput
          name={"shippingNumber"}
          input={<Span>{detail?.trackingNumber}</Span>}
          placeholder="배송번호, 주문번호, 접수번호, 출발국가택배번호"
          label="접수번호"
          padding="14px 20px 10px 20px"
          labelWidth="55%"
          style={{ margin: "10px", width: "100%" }}
        />
      </Grid>

      <Grid item xs={6}>
        <CustomInput
          input={<Span>{detail?.productInformation?.orderNo1}</Span>}
          label="주문번호1"
          padding="14px 20px 10px 20px"
          labelWidth="55%"
        />
      </Grid>

      <Grid item xs={12}>
        <Divider />
      </Grid>

      <Grid item xs={6}>
        <CustomInput
          input={<Span>{detail?.totalSKU}</Span>}
          label="SKU"
          labelWidth="55%"
          padding="14px 20px 10px 20px"
        />
      </Grid>

      <Grid item xs={6}>
        <CustomInput
          input={
            <Span>
              {detail?.productInformation?.departureCountryCourierNumber}
            </Span>
          }
          label="출발국가택배번호"
          padding="14px 20px 10px 20px"
          labelWidth="55%"
        />
      </Grid>

      <Grid item xs={12}>
        <Divider />
      </Grid>

      <Grid item xs={6}>
        <CustomInput
          input={<Span>{detail?.shippingNumber}</Span>}
          label="도착국가 택배번호"
          labelWidth="55%"
          padding="14px 20px 10px 20px"
        />
      </Grid>
      <Grid item xs={6}>
        <CustomInput
          input={<Span>{detail?.productInformation?.totalPriceAmount}</Span>}
          label="총 상품 금액"
          labelWidth="55%"
          padding="14px 20px 10px 20px"
        />
      </Grid>

      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={6}>
        <CustomInput
          input={
            <Span>{detail?.miscellaneousInformation?.applicableWeight}</Span>
          }
          label="적용 무게"
          labelWidth="55%"
          padding="14px 20px 10px 20px"
        />
      </Grid>
    </Grid>
  );
};

export default RequestInfo;

const Span = styled.span`
  padding: 0 10px;
`;
