import {
  Box,
  Button,
  Divider,
  MenuItem,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import CustomButton from "../components/CustomButton";
import CustomCard from "../components/CustomCard";
import CustomInput, { SelectBox } from "../components/CustomInput";
import ConfirmModal from "./ConfirmModal";
import CreateHOC from "../SharedComponents/CreateHOC";
import UpdateHOC from "../SharedComponents/UpdateHOC";
import { Formik, Form } from "formik";
import Moment from "moment";
import * as Yup from "yup";

export default function DepositProcessingModal({ onClose, selected, ...rest }) {
  return React.createElement(selected ? UpdateHOC : CreateHOC, {
    selected,
    handleClose: onClose,
    ...rest,
    _resource: "Transaction",
    children: DepositProcessingModalContainer,
  });
}

const DepositProcessingModalContainer = ({
  isOpen,
  handleClose: onClose,
  onSuccess,
  onOpen,
  selected,
  selectedUserId,
  ...hocProps
}) => {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const submitButton = useRef();
  var current = null;

  const [initialValues, setInitialValues] = useState({
    reason: "customer-fees",
    type: "deposit",
    amount: 0,
    date: Moment().format("YYYY-MM-DD"),
    time: Moment().format("HH:mm"),
    memo: "",
  });
  useEffect(() => {
    current = submitButton.current;
  }, []);
  const validationSchema = () => {
    return Yup.object().shape({
      reason: Yup.string().required(),
      type: Yup.string().required(),
      date: Yup.string().required(),
      time: Yup.string().required(),
      amount: Yup.number().required(),
      memo: Yup.string(),
    });
  };

  const handleSubmit = async (values, formikUtils) => {
    if (selectedUserId) {
      let input = {};
      const { date, time, ...rest } = values;
      rest.deposit_processing_date = Moment(
        date + " " + time,
        "YYYY-MM-DD HH:mm"
      ).format();
      if (selected) {
        input = {
          updateInput: {
            ...rest,
            userId: selectedUserId,
          },
        };
      } else {
        input = {
          createInput: {
            ...rest,
            userId: selectedUserId,
          },
        };
      }

      const res = await hocProps.handleSubmit({ ...input }, formikUtils);
      if (res) {
        const { data, reason } = res;
        if (onSuccess) {
          onSuccess(data, reason);
        }
        setShowConfirmModal(false);
      }
    }
  };

  return (
    <>
      <Modal
        open={isOpen}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          style={{
            padding: 20,
            overflowX: "hidden",
            minHeight: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
          >
            {({ values, handleSubmit, errors, handleChange }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <CustomCard>
                    <Stack spacing={"20px"} width={500} p="20px">
                      <Typography fontSize={"20px"} fontWeight="700">
                        입출금 처리
                      </Typography>
                      <div>
                        <CustomInput
                          label={"입출금"}
                          labelColor=""
                          padding="10px 0"
                          input={
                            <SelectBox
                              name={"type"}
                              onChange={handleChange}
                              defaultValue={values.type}
                            >
                              <MenuItem selected value="deposit">
                                입금
                              </MenuItem>
                              <MenuItem value="withdraw">출금</MenuItem>
                            </SelectBox>
                          }
                        />

                        {/* {values.type == "입금" && ( */}
                        <CustomInput
                          label={"분류"}
                          labelColor=""
                          padding="10px 0"
                          input={
                            <SelectBox
                              name={"reason"}
                              onChange={handleChange}
                              defaultValue={values.reason}
                            >
                              {/* <MenuItem value="deposit-by-mistake">
                                    입금
                                  </MenuItem>
                                  <MenuItem value="refund">출금</MenuItem> */}
                              {values.type == "deposit" && (
                                <MenuItem value={"customer-fees"}>
                                  고객 요금 입금
                                </MenuItem>
                              )}
                              {values.type == "deposit" && (
                                <MenuItem value={"reward-amount"}>
                                  보상 금액 입금
                                </MenuItem>
                              )}
                              {values.type == "deposit" && (
                                <MenuItem value={"other"}>기타</MenuItem>
                              )}
                              {values.type == "withdraw" && (
                                <MenuItem value={"customer-fees"}>
                                  기타 서비스 사용 요금
                                </MenuItem>
                              )}
                              {values.type == "withdraw" && (
                                <MenuItem value={"reward-amount"}>
                                  착오로 인한 입금
                                </MenuItem>
                              )}
                              {values.type == "withdraw" && (
                                <MenuItem value={"other"}>기타</MenuItem>
                              )}
                            </SelectBox>
                          }
                        />
                        {/* )} */}

                        {/* {values.type == "출금" && ( */}

                        {/* )} */}

                        <CustomInput
                          label={"금액"}
                          sx={{}}
                          labelColor=""
                          name={"amount"}
                          type={"number"}
                          onChange={(e) => {
                            const { value } = e.target;
                            if (+value >= 0) handleChange(e);
                          }}
                          value={values.amount}
                          error={!!errors.amount}
                          padding="10px 0"
                          placeholder="금액을 입력해주세요."
                        />

                        <CustomInput
                          label={"날짜"}
                          labelColor=""
                          type={"date"}
                          name={"date"}
                          value={Moment(values.date, "YYYY-MM-DD").format(
                            "YYYY-MM-DD"
                          )}
                          onChange={handleChange}
                          error={!!errors.date}
                          padding="10px 0"
                          placeholder={"날짜를 입력해주세요."}
                        />

                        <CustomInput
                          label={"시간"}
                          labelColor=""
                          type={"time"}
                          name="time"
                          value={Moment(values.time, "HH:mm").format("HH:mm")}
                          onChange={handleChange}
                          error={!!errors.time}
                          padding="10px 0"
                          placeholder={"시간을 입력해주세요."}
                        />

                        <CustomInput
                          label={"메모"}
                          labelColor=""
                          name={"memo"}
                          onChange={handleChange}
                          value={values.memo}
                          error={!!errors.memo}
                          padding="10px 0"
                          placeholder={"메모를 입력해주세요."}
                        />
                      </div>

                      <Divider />

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          gap: "20px",
                        }}
                      >
                        <CustomButton
                          styles={{
                            width: "auto",
                          }}
                          onClick={() => {
                            // onClose();
                            setShowConfirmModal(true);
                          }}
                        >
                          전송
                        </CustomButton>
                        <CustomButton
                          onClick={onClose}
                          styles={{
                            backgroundColor: "#F8F8FA",
                            color: "#000",
                            width: "auto",
                          }}
                        >
                          취소
                        </CustomButton>

                        {/* <Button
                          ref={submitButton}
                          type={"submit"}
                          hidden={true}
                        ></Button> */}
                      </div>
                    </Stack>
                  </CustomCard>
                  <ConfirmModal
                    isOpen={showConfirmModal}
                    onClose={() => {
                      onOpen();
                      setShowConfirmModal(false);
                    }}
                    onConfirm={() => {
                      handleSubmit();
                      // if (submitButton) {

                      //   console.log(current)
                      //   current.click();
                      // }
                    }}
                    title="입금 처리"
                    description={
                      <>
                        <p>
                          입출금: {values.type === "withdraw" ? "출금" : "입금"}
                        </p>
                        <p>
                          분류:{" "}
                          {values.type === "deposit" &&
                            values.reason === "customer-fees" &&
                            "고객 요금 입금"}
                          {values.type === "deposit" &&
                            values.reason === "reward-amount" &&
                            "보상 금액 입금"}
                          {values.type === "deposit" &&
                            values.reason === "other" &&
                            "기타"}
                          {values.type === "withdraw" &&
                            values.reason === "customer-fees" &&
                            "기타 서비스 사용 요금"}
                          {values.type === "withdraw" &&
                            values.reason === "reward-amount" &&
                            "착오로 인한 입금"}
                          {values.type === "withdraw" &&
                            values.reason === "other" &&
                            "기타"}
                        </p>

                        <p>금액: {values.amount}원</p>
                        <p>
                          날짜:{" "}
                          {Moment(
                            values.date + " " + values.time,
                            "YYYY-MM-DD HH:mm"
                          ).format("YYYY-MM-DD HH:mm")}
                        </p>
                        <p>메모: {values.memo}</p>
                        <br />
                        <p> 위와 같이 입금 처리 내역을 전송하시겠습니까?</p>
                      </>
                    }
                  />
                </Form>
              );
            }}
          </Formik>
        </Box>
      </Modal>
    </>
  );
};
