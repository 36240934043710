import React from "react";

function SentIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="125"
      height="125"
      fill="none"
      viewBox="0 0 125 125"
    >
      <g filter="url(#filter0_f_759_2273)" opacity="0.1">
        <circle cx="62.5" cy="62.5" r="51.729" fill="#81A9F9"></circle>
      </g>
      <g filter="url(#filter1_f_759_2273)" opacity="0.15">
        <circle cx="62.5" cy="62.5" r="40.071" fill="#A3C0FA"></circle>
      </g>
      <circle cx="62.5" cy="62.5" r="25.5" fill="#427DF3"></circle>
      <path
        fill="#fff"
        d="M57.361 73.1l.225-.25.2-.125.225.125c.325-.25.6-.525.825-.8.225-.275.65-1 1.325-2.125l1.55-2.625c.825-1.4 5.15-7.275 6.125-8.375 2.25-2.575 3-3.35 3.3-3.75.275-.35.45-.625.45-.825 0-.125-.075-.325-.2-.325s-.325.1-.575.275l.2-.45-.85.5.05-.35c-.05.15-.45.55-.55.55-.075 0-.1-.075-.1-.2v-.1l-.7.35c-.05 0-.1-.025-.1-.1 0-.075.05-.25.1-.5-.35.375-.85.8-1.5 1.25-.875.6-4.275 4.875-5.65 7.1-.925 1.475-1.3 2.35-1.45 2.35-.05 0-.075-.1-.1-.3-.45 1.025-.85 1.875-1.25 2.5s-.775.95-1.05.95c-.325 0-.6-.625-.9-1.9-.125-.55-.4-.9-.8-1.1l-.075-.025c-.075 0-.175.05-.3.125s-.2.15-.25.15h-.025l-.45-.15-.4.6c-.05.075-.15.1-.225.1-.125 0-.225-.025-.275-.1-.1.35-.225.525-.325.525-.075 0-.225-.05-.425-.175.175 1.325.325 2.2.475 2.625.3.825.975 2.7 1.625 3.95.075.15.175.325.25.475.25-.15.325-.175.5-.175l.2.025c.125.05.2.125.2.25l.575-.275c.1 0 .125.1.125.325z"
      ></path>
      <defs>
        <filter
          id="filter0_f_759_2273"
          width="123.457"
          height="123.457"
          x="0.771"
          y="0.771"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          ></feBlend>
          <feGaussianBlur
            result="effect1_foregroundBlur_759_2273"
            stdDeviation="5"
          ></feGaussianBlur>
        </filter>
        <filter
          id="filter1_f_759_2273"
          width="100.143"
          height="100.143"
          x="12.429"
          y="12.429"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          ></feBlend>
          <feGaussianBlur
            result="effect1_foregroundBlur_759_2273"
            stdDeviation="5"
          ></feGaussianBlur>
        </filter>
      </defs>
    </svg>
  );
}

export default SentIcon;
