import { gql } from "@apollo/client/core";


export const DOWNLOAD_EXCEL_OVERSEA_DELIVERY_PARCEL = gql(`
 mutation createOverseaReceipt(
    $filterType: String!,
    $startDate: DateTime!,
    $endDate: DateTime!
){
    createOverseaReceipt(filterType: $filterType, startDate: $startDate, endDate: $endDate)
}
`);