import { Box, Modal } from "@mui/material";
import React from "react";
import styled from "styled-components";
import CloseIcon from "@mui/icons-material/Close";
import ModalButton from "./ModalButton";
import CustomButton from "./CustomButton";

const CustomModal = ({
  isOpen,
  handleClose,
  title = "",
  children,
  size = "small",
  hasFooter = false,
  onSuccess,
  buttonText,
  updateBag,
  updateBagStatus
}) => {
  return (
    <>
      <Modal
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          overflowY: "scroll",
          overflowX: "hidden",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          outline: "none",
        }}
      >
        <Layout sx={{ position: "relative" }} size={size} outline="none">
          <Header>
            <ModalTitle
              style={
                size != "small"
                  ? { paddingLeft: "30px" }
                  : { paddingLeft: "unset" }
              }
            >
              {title}
            </ModalTitle>
            <CloseIcon onClick={handleClose} sx={{ cursor: "pointer" }} />
          </Header>
          <Body>{children}</Body>
          <div style={{
            display: "flex",
            justifyContent: "flex-end",
            gap: "20px",
            marginBottom:"20px"
          }}>
            {!updateBagStatus ?
             <CustomButton
             onClick={handleClose}
             styles={{
               backgroundColor: "#F8F8FA",
               color: "#000",
               width: "auto",
             }}
           >
             취소
           </CustomButton>
           :
           <CustomButton
           onClick={handleClose}
           styles={{
             backgroundColor: "#F8F8FA",
             color: "#000",
             width: "auto",
             marginRight:"20px"
           }}
         >
           닫기
         </CustomButton>
            }
           
            {!updateBagStatus && 
             <CustomButton
             styles={{
               width: "auto",
               marginRight:"20px"
             }}
            onClick={e=>{updateBag()}}
           >
             전송
           </CustomButton>
            }
           
          </div>
        </Layout>
      </Modal>
    </>
  );
};

export default React.memo(CustomModal);

const Layout = styled(Box)`
  height: auto;
  width: ${({ size }) => (size === "small" ? "350px" : "550px")};
  background-color: white;
  border-radius: 26px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  outline: none;
`;

const Header = styled.div`
  width: 90%;
  margin: 20px;
  display: flex;
  justify-content: space-between;
  position: relative;
  top: 0;
`;

const ModalTitle = styled.p`
  font-family: "Spoqa Han Sans Neo";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 29px;
  /* identical to box height */

  color: #3e3e3e;
`;

const Body = styled.div`
  width: 90%;
  margin: 20px;
  margin-top: 0px;
  margin-bottom: 40px;
`;

const Footer = styled.div`
  display: flex;
  gap: 20px;
  justify-content: flex-end;
  margin: 20px;
`;
