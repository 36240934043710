import { Grid, Link, OutlinedInput, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import CustomButton from "../../../components/CustomButton";
import { useParams } from "react-router-dom";
import { createMemousergroup, getUserGroup } from "./queries";
import EditTitle from "./singlepage/EditTitle";
import Companies from "./singlepage/Companies";
import Rates from "./singlepage/Rates";
import Toaster from "../../../utils/Toaster";

export function RateByGroup() {
  const [groupdata, setGroupdata] = useState();
  const [memo, setMemo] = useState("");
  const [companycount, setCompanycount] = useState(0);
  const params = useParams();

  //Get data from api about group user
  useEffect(() => {
    if (params.id)
      getUserGroup(parseInt(params.id)).then((res) => {
        setGroupdata(res.data.getOneUserGroup);

        if (Object.hasOwn(res.data.getOneUserGroup, "memo")) {
          setMemo(res.data.getOneUserGroup.memo);
        }
      });

    return () => {};
  }, [params.id]);

  //Save memo
  const saveMemo = () => {
    let pack = {
      id: parseInt(params.id),
      name: groupdata.name,
      memo: memo,
    };
    createMemousergroup(pack).then((res) => {
      console.log(res.data);
      Toaster("success", "메모가 업데이트 되었습니다.");
    });
  };

  //Get company count
  const getCompCount = (c) => {
    setCompanycount(c);
  };

  return (
    <Grid container spacing="20px" flexDirection={"column"}>
      <Grid
        item
        xs={12}
        container
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Grid item xs={12} container spacing={1} alignItems={"center"}>
          <Grid item>
            <CustomButton
              variant={"outlined"}
              styles={{
                color: "#000",
                border: "1px solid #C4C4C4",
                // height: "40px",
                padding: "7px",
                borderRadius: 5,
              }}
              onClick={(e) => (window.location.href = "/rates-by-company")}
            >
              <img
                style={{ paddingRight: "10px" }}
                src="/assets/images/arrow-down.png"
                alt=""
              ></img>
              이전 페이지
            </CustomButton>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        item
        xs={12}
        container
        justifyContent={"space-between"}
        alignItems={"center"}
        style={{ marginBottom: "30px", marginTop: "30px" }}
      >
        {/* <Grid item xs={4} sm={4} md={3} container spacing={1}> */}
        <Grid item xs={6}>
          {groupdata?.name && <EditTitle data={groupdata} />}
        </Grid>
        <Grid item xs={6}>
          <div style={{ textAlign: "end", cursor: "pointer" }}>
            <Link style={{ textDecoration: "none" }}>
              그룹 내 총 업체갯수 {companycount}
            </Link>
          </div>
        </Grid>
        {/* </Grid> */}
      </Grid>

      <Grid
        item
        xs={12}
        container
        justifyContent={"space-between"}
        alignItems={"center"}
        style={{ marginBottom: "50px" }}
      >
        {/* <Grid item xs={4} sm={4} md={3} container spacing={1}> */}
        <Grid item xs={2}>
          <div style={{ display: "flex", cursor: "pointer" }}>
            <Typography
              style={{ fontSize: "16px", fontWeight: "bold" }}
              variant="h6"
            >
              메모
            </Typography>
          </div>
        </Grid>
        <Grid item xs={8}>
          <div style={{ cursor: "pointer" }}>
            <OutlinedInput
              size="small"
              placeholder="메모를 입력해주세요."
              id="outlined-adornment-username"
              notched={false}
              name="memo"
              value={memo}
              onChange={(e) => {
                setMemo(e.target.value);
              }}
              color="primary"
              sx={{
                width: "100%",
                // mb: "24px",
                backgroundColor: "white",
                borderRadius: "10px",
                borderColor: "red",
              }}
            />
          </div>
        </Grid>
        {/* </Grid> */}
        <Grid item xs={2}>
          <div
            style={{
              display: "flex",
              cursor: "pointer",
              justifyContent: "flex-end",
            }}
          >
            <CustomButton
              variant={"outlined"}
              styles={{
                width: "50%",
                color: "#000",
                border: "1px solid #C4C4C4",
                // height: "40px",
                padding: "7px",
                borderRadius: 5,
              }}
              onClick={saveMemo}
            >
              저장
            </CustomButton>
          </div>
        </Grid>
      </Grid>

      <Companies
        usergroupId={params.id}
        data={groupdata?.users}
        getCompany={getCompCount}
      />

      <Rates usergroupId={params.id} />
    </Grid>
  );
}
