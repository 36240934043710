import React, { useCallback, useEffect, useState } from "react";
import { generateSearchData } from "../../../utils/utils";
import {
	Grid,
	Link,
	MenuItem,
	Pagination,
	Stack,
	Typography,
} from "@mui/material";
import DropdownAndSearchInput from "../../../components/DropdownAndSearchInput2";
import { onDropDownSearchChange } from "../../../utils/onDropDownSearchChange";
import { SelectBox, TextFieldBox } from "../../../components/CustomInput";
import TableContainer from "../../../components/TableContainer";
import ListHOC from "../../../SharedComponents/ListHOC";
import { useDeliveryRequest } from "../../../Context/DeliveryRequestContext";
import SearchIcon from "../../../Common/assets/Images/SearchIcon";
import { Form, Formik } from "formik";
import Moment from "moment";
import DateRangePicker from "../../../components/DateRangePicker";
import CalendarIcon from "../../../Common/assets/Images/CalendarIcon";
import { gql } from "@apollo/client/core";
import { APIClient } from "../../../utils/apiGraphql";
import CustomSelect from "../../../components/CustomSelect";
import styled from "styled-components";

export default function DeliveryRequestUserDetails({
	setRequestShippingFilter,
	requestShippingFilter,
	dateFilter,
	setDateFilter,
}) {
	const [data, setData] = useState([]);
	const [filterQuery, setFilterQuery] = useState(null);
	const [filterQuery2, setFilterQuery2] = useState(null);
	const { setUserId } = useDeliveryRequest();
	const [isLoading, setIsLoading] = useState("");
	const [perPage, setPerPage] = useState(10);
	const [countriesName, setCountriesName] = useState([]);
	const [dateType, setDateType] = useState("processReleaseDate");

	const getShippingStats = async (filter) => {
		setIsLoading(true);
		const Query = gql(`
        query($filter:shippingSummaryFilter) {
					allShippingSummary(filter:$filter){
						summary{
							id
							unstacked
							quantityReceived
							companyName
							overall
							companyCode
						}
					}
        }
      `);

		try {
			const res = await APIClient.query({
				query: Query,
				variables: {
					filter: filter,
				},
			});
			setData(res.data?.allShippingSummary?.summary);
			setIsLoading(false);
		} catch (e) {
			console.log(e);
			setIsLoading(false);
		}
	};

	useEffect(() => {
		getShippingStats();
	}, []);

	const handleSearch = (filterParams) => {
		getShippingStats(JSON.parse(JSON.stringify(filterParams)));
	};

	const doSearch = useCallback(() => {
		// generateSearchData(handleSearch, filterQuery);
		handleSearch(filterQuery);
	}, [filterQuery]);

	useEffect(() => doSearch(), [filterQuery]);

	const doSearch2 = useCallback(() => {
		// generateSearchData(handleSearch, filterQuery);
		handleSearch(filterQuery2);
	}, [filterQuery2]);
	useEffect(() => doSearch2(), [filterQuery2]);

	const handleSetPerPage = (value) => {
		setPerPage(+value);
	};

	const getActiveCountryList = async (filter) => {
		const Query = gql(`
		query{
			allShippingCountries{
				id
				name
				isActive
			}
		}
      `);

		try {
			const res = await APIClient.query({
				query: Query,
			});
			setCountriesName(res.data?.allShippingCountries);
		} catch (e) {
			console.log(e);
		}
	};

	useEffect(() => {
		getActiveCountryList();
	}, []);

	const rangeChange = (value) => {
		const splitted = value[dateType]?.split(" ");

		const data = {
			gte: splitted[0],
			lte: splitted[2],
		};

		setFilterQuery2({
			[dateType]: data,
		});
		setDateFilter({
			[dateType]: data,
		});
	};

	// console.log("filter1", filterQuery);
	// console.log("filter2", filterQuery2);
	const handleCountryChange = (value) => {
		if (value === "all") {
			handleSearch({});
		} else {
			const countryName = {
				country_name: value,
			};
			handleSearch(countryName);
		}
	};

	return (
		<Stack spacing={"35px"}>
			<Stack spacing="20px">
				<Grid xs={12} container columnGap={1} style={{ justifyContent: "end" }}>
					<Grid xs={3}>
						<SelectBox
							defaultValue={dateType}
							onChange={(e) => {
								setDateType(e.target.value);
								setFilterQuery2(null);
							}}
						>
							<MenuItem value="processReleaseDate">출고일</MenuItem>
							<MenuItem value="createdAt">접수일</MenuItem>
						</SelectBox>
					</Grid>
					<Grid xs={5}>
						<Formik
							enableReinitialize
							initialValues={
								filterQuery2 || {
									[dateType]: {
										gte: `${Moment().format("YYYY-MM-DD")}`,

										lte: `${Moment().format("YYYY-MM-DD")}`,
									},
								}
							}
							onSubmit={(values) => {}}
						>
							{({ values, handleSubmit, setFieldValue, resetForm }) => {
								return (
									<Form onSubmit={handleSubmit}>
										<Grid
											container
											alignItems={"center"}
											style={{ flexWrap: "nowrap" }}
										>
											<Stack spacing={"20px"} width={340}>
												<div>
													<DateRangePicker
														name={dateType}
														value={`${values?.[dateType]?.gte} - ${values?.[dateType]?.lte}`}
														showLabel={false}
														// setFieldValue={setFieldValue}
														handleChange={rangeChange}
													/>
												</div>
											</Stack>
										</Grid>
									</Form>
								);
							}}
						</Formik>
					</Grid>

					<Grid xs={3}>
						<SelectBox
							defaultValue={"배송국가"}
							onChange={(e) => handleCountryChange(e.target.value)}
						>
							<MenuItem value="배송국가" disabled hidden>
								배송국가
							</MenuItem>
							<MenuItem value="all">전체보기</MenuItem>
							{countriesName &&
								countriesName.map((item, index) => (
									<MenuItem value={item.name} key={index}>
										{item.name}
									</MenuItem>
								))}
						</SelectBox>
					</Grid>
				</Grid>
				{/* <Grid container alignItems={"center"} style={{ marginTop: 10 }}> */}
				<Grid
					container
					alignItem={"center"}
					justifyContent={"flex-end"}
					style={{ marginTop: 10 }}
					item
					gap={1}
					xs={12}
				>
					<Grid>
						<DropdownAndSearchInput
							onChange={(data) => {
								const { newValue: value } = data;
								value[Object.keys(value)[0]] = value[Object.keys(value)[0]]
									.split(",")
									.map((each) => each.trim());
								data["newValue"] = value;
								onDropDownSearchChange(data, setFilterQuery);
							}}
							xs={12}
							value={filterQuery}
							defaultValue={{ key: "companyNames", value: "" }}
							dropDownValues={[{ value: "companyNames", title: "업체명" }]}
							inputFieldPlaceHolder={"검색어를 입력해주세요."}
							justifyContent={"flex-end"}
						/>
					</Grid>
					<Grid>
						<CustomSelect
							value={String(perPage)}
							label=""
							onChange={({ target }) => {
								handleSetPerPage(target.value);
							}}
							options={["10", "20", "30", "40", "50", "100"]}
						></CustomSelect>
					</Grid>
				</Grid>
				{/* </Grid> */}
				{/* <Grid
					xs={12}
					container
					columnGap={1}
					style={{ justifyContent: "end" }}
				></Grid> */}
			</Stack>

			<TableContainer
				{...{ isLoading: isLoading, requestCompleted: true }}
				headers={[
					"고객사코드",
					"업체명",
					`접수수량(총 ${data?.reduce(
						(partialSum, a) => partialSum + a.overall,
						0
					)} 개)`,
					`입고수량(총 ${data?.reduce(
						(partialSum, a) => partialSum + a.quantityReceived,
						0
					)} 개)`,
					`미입고수량(총 ${data?.reduce(
						(partialSum, a) => partialSum + a.unstacked,
						0
					)} 개)`,
				]}
				data={data.map((item, index) => [
					<Columns
						onClick={(e) =>
							setRequestShippingFilter({
								name: item?.companyName,
								id: item?.companyCode,
							})
						}
					>
						{item.companyCode}
					</Columns>,
					<Columns
						onClick={(e) =>
							setRequestShippingFilter({
								name: item?.companyName,
								id: item.companyCode,
							})
						}
						active={item.companyCode === requestShippingFilter.id}
					>
						{item.companyName}
					</Columns>,
					<Columns
						onClick={(e) =>
							setRequestShippingFilter({
								name: item?.companyName,
								id: item.companyCode,
							})
						}
					>
						{item.overall}
					</Columns>,
					<Columns
						onClick={(e) =>
							setRequestShippingFilter({
								name: item?.companyName,
								id: item.companyCode,
							})
						}
					>
						{item.quantityReceived}
					</Columns>,
					<Columns
						onClick={(e) =>
							setRequestShippingFilter({
								name: item?.companyName,
								id: item.companyCode,
							})
						}
					>
						{item.unstacked}
					</Columns>,
				])}
			/>

			<div
				style={{
					display: "flex",
					justifyContent: "center",
					marginTop: 30,
				}}
			>
				<Pagination
					count={Math.ceil(data.length / perPage)}
					// page={page}
					// onChange={handlePerSetChange}
					color="primary"
					shape="rounded"
				/>
			</div>
		</Stack>
	);
}

const Columns = styled.div`
	cursor: pointer;
	font-weight: ${(props) => (props.active ? "800" : "500")};
	color: ${(props) => (props.active ? "cornflowerblue" : "black")};
`;
