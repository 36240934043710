import writeXlsxFile from "write-excel-file";
import { gql } from "@apollo/client";
import { APIClient } from "../../../../../utils/apiGraphql";
import { Html } from "@mui/icons-material";
import Toaster from "../../../../../utils/Toaster";
import ReactDOMServer from "react-dom/server";
import * as XLSX from "xlsx-js-style";
import Moment from "moment";
import NLPostMasterTable from "./NLPostMasterTable";
import { NLPostMasterStyles } from "../../../../../Common/settlement-excel-components/styles";
import { ShippingRequestTable } from "../../../../../Common/settlement-excel-components";
import { NLPostShippingRequestStyles, NLPostPackingListStyles } from "../../../../../Common/settlement-excel-components/styles";
import NLPostShippingRequestTable from "./NLPostShippingRequestTable";
import NLPostPackingList from "./NLPostPackingList";

const getlength = (number) => number?.toString()?.length;

const generateMasterDetails = (data) => {
    try {
        console.log("generateMasterDetails", data);
		const htmls = data.map((res) => {
			return {
				htmlData: ReactDOMServer.renderToString(<NLPostMasterTable master={res} />),
				data: res,
			};
		});
      	
		htmls.forEach(async ({ htmlData, data1 }) => {
			const wb = XLSX.utils.book_new();
			const div = document.createElement("div");
			div.innerHTML = htmlData;
			const ws = XLSX.utils.table_to_sheet(div, { cellStyles: true });
			const masterStyle = NLPostMasterStyles( data1?.masterManagement?.length );

            if(ws) {
                Object.keys(masterStyle).forEach((key) => {
                    if(ws[key]) {
                        ws[key].s = masterStyle[key];
                    }
                });
            }	

			XLSX.utils.book_append_sheet(wb, ws, "Summary manifest");
			div.remove();

            const requestShippingTable = ReactDOMServer.renderToString(
				<NLPostShippingRequestTable data={data[0]} />
			);

            const div1 = document.createElement("div");
			div1.innerHTML = requestShippingTable;
			const ws1 = XLSX.utils.table_to_sheet(div1);
            const shippingRequestStyle = NLPostShippingRequestStyles(2);
            
            Object.keys(shippingRequestStyle).forEach((key) => {
				if(ws1[key]) {
                    ws1[key].s = shippingRequestStyle[key];
                }
			});

            XLSX.utils.book_append_sheet(wb, ws1, "Manifest");
			div1.remove();

            //-----------------------------------------------------------------------


            const requestShippingTable1 = ReactDOMServer.renderToString(
				<NLPostPackingList data={data[0]} />
			);

            const div2 = document.createElement("div");
			div2.innerHTML = requestShippingTable1;
			const ws2 = XLSX.utils.table_to_sheet(div2, { cellStyles: true });
            const shippingRequestStyle1 = NLPostPackingListStyles(2);
            
            Object.keys(shippingRequestStyle1).forEach((key) => {
				if(ws2[key]) {
                    ws2[key].s = shippingRequestStyle1[key];
                }
			});

            XLSX.utils.book_append_sheet(wb, ws2, "Packing List");
			div2.remove();


            //-----------------------------------------------------------------------


			// data?.masterManagement?.forEach((master) => {
				// const { bags } = master;
                // const { bags } = [];

				
				// const requestShippingTable = ReactDOMServer.renderToString(
				// 	<NLPostShippingRequestTable bags={bags} />
				// );

				// const div1 = document.createElement("div");
				// div1.innerHTML = requestShippingTable;
				// const ws1 = XLSX.utils.table_to_sheet(div1);
				// const totalRequests = bags?.reduce((a, bag) => {
				// 	return a + bag?.receptionLists?.length || 0;
				// }, 0);

				// const shippingRequestStyle = shippingRequestStyles(totalRequests + 2);
				// console.log(shippingRequestStyle);
				// Object.keys(shippingRequestStyle).forEach((key) => {
				// 	ws[key].s = shippingRequestStyle[key];
				// });
				// XLSX.utils.book_append_sheet(wb, ws1, master.masterNumber);
                // XLSX.utils.book_append_sheet(wb, ws1, "hello");
				// div1.remove();
        	// });

			XLSX.writeFile(
				wb,
				"[NL Spring 업로드 양식] BR Service 엑셀 데이터 다운로드.xlsx",
				{
					bookType: "xlsx",
				}
			);
		});

	} catch (e) {
		console.log(e);
		Toaster("error", "Something went wrong!");
	}
};


//Export xlsx with multiple table in one sheet
const exportBRNLPostServiceXlsx = async (ids, masterData) => {
    //First fetch the desired data for the excel sheets using the ids
    let data = [];

//     data.push([
//         {
//             value: "Summary Manifest",
//             align: "center",
//             width: "800",
//             fontWeight: "bold",
//             span: 10
//         }
//     ]);

//     data.push([
//         {
//             value: "For custom purposes only",
//             align: "center",
//             fontWeight: "bold",
//             span: 10
//         }
//     ]);

//     data.push([
//         {
//             value: `From:
// H3 networks co., ltd.
// #B103, Hwagok-ro 416 The Sky Valley 5th
// Gangseo-gu, Seoul . 07548
// Republic of Korea
// Phone: +82-10-2304-1331`,
//             align: "left",
//             fontWeight: "bold",
//             wrap: true,
//             height: 6
//         }
//     ]);

//     /**
//     * Creating header
//     */
//     let header = [
//         {
//             value: "배송번호",
//             backgroundColor: "#f1d8be",
//             align: "center",
//         },
//         {
//             value: "Sender type",
//             backgroundColor: "#f1d8be",
//             align: "center",
//         },
//         {
//             value: "Sender full name",
//             backgroundColor: "#f1d8be",
//             align: "center",
//         },
//         {
//             value: "Sender contact name",
//             backgroundColor: "#f1d8be",
//             align: "center",
//         },
//         {
//             value: "Sender phones",
//             backgroundColor: "#f1d8be",
//             align: "center",
//         },
//         {
//             value: "Sender email",
//             backgroundColor: "#f1d8be",
//             align: "center",
//         },
//         {
//             value: "Sender postcode",
//             backgroundColor: "#f1d8be",
//             align: "center",
//         },
//         {
//             value: "Sender country",
//             backgroundColor: "#f1d8be",
//             align: "center",
//         },
//         {
//             value: "Sender region",
//             backgroundColor: "#f1d8be",
//             align: "center",
//         },
//         {
//             value: "Sender city",
//             backgroundColor: "#f1d8be",
//             align: "center",
//         },
//         {
//             value: "Sender address",
//             backgroundColor: "#f1d8be",
//             align: "center",
//         },
//         {
//             value: "Sender warehouse owner",
//             backgroundColor: "#f1d8be",
//             align: "center",
//         },
//         {
//             value: "Sender warehouse",
//             backgroundColor: "#f1d8be",
//             align: "center",
//         },
//         {
//             value: "Receiver type",
//             backgroundColor: "#f1d8be",
//             align: "center",
//         },
//         {
//             value: "Receiver full name",
//             backgroundColor: "#f1d8be",
//             align: "center",
//         },
//         {
//             value: "Receiver contact name",
//             backgroundColor: "#f1d8be",
//             align: "center",
//         },
//         {
//             value: "Receiver phones",
//             backgroundColor: "#f1d8be",
//             align: "center",
//         },
//         {
//             value: "Receiver email",
//             backgroundColor: "#f1d8be",
//             align: "center",
//         },
//         {
//             value: "Receiver postcode",
//             backgroundColor: "#f1d8be",
//             align: "center",
//         },
//         {
//             value: "Receiver country",
//             backgroundColor: "#f1d8be",
//             align: "center",
//         },
//         {
//             value: "Receiver region",
//             backgroundColor: "#f1d8be",
//             align: "center",
//         },
//         {
//             value: "Receiver city",
//             backgroundColor: "#f1d8be",
//             align: "center",
//         },
//         {
//             value: "Receiver address",
//             backgroundColor: "#f1d8be",
//             align: "center",
//         },
//         {
//             value: "Receiver warehouse owner",
//             backgroundColor: "#f1d8be",
//             align: "center",
//         },
//         {
//             value: "Receiver warehouse",
//             backgroundColor: "#f1d8be",
//             align: "center",
//         },
//         {
//             value: "Invoice description",
//             backgroundColor: "#f1d8be",
//             align: "center",
//         },
//         {
//             value: "Full description",
//             backgroundColor: "#f1d8be",
//             align: "center",
//         },
//         {
//             value: "Place description",
//             backgroundColor: "#f1d8be",
//             align: "center",
//         },
//         {
//             value: "Actual weight, kg",
//             backgroundColor: "#f1d8be",
//             align: "center",
//         },
//         {
//             value: "Related order",
//             backgroundColor: "#f1d8be",
//             align: "center",
//         },
//         {
//             value: "Receiver postcode",
//             backgroundColor: "#f1d8be",
//             align: "center",
//         },
//         {
//             value: "Receiver country",
//             backgroundColor: "#f1d8be",
//             align: "center",
//         },
//         {
//             value: "Receiver region",
//             backgroundColor: "#f1d8be",
//             align: "center",
//         },
//         {
//             value: "Receiver city",
//             backgroundColor: "#f1d8be",
//             align: "center",
//         },
//         {
//             value: "Receiver address",
//             backgroundColor: "#f1d8be",
//             align: "center",
//         }
//     ];
    
//     data.push(header);

    const res = masterData;
    console.log("response",res);

    if(!res || res?.length === 0) {
        Toaster("error", "Cannot get data at the moment");
        return;
    }

    generateMasterDetails(res);

    // if(res.data && res.data.items) {
        // if(res.data.items.length == 1) {
            // const value = res[0];
            // let masterNumber = "";
            // let masterCreatedAt = "";
            // let uploadQuantity = 0;

            // for(let i=0; i < value.bags.length; i++) {
            //     const bag = value.bags[i];
                
            //     const totalWeight = bag.usedWeight;
            //     masterNumber = value.masterNumber;
            //     masterCreatedAt = value.createdAt;
                

            //     const receptionLists = bag.receptionLists;
                
            //     receptionLists.forEach((receptionList) => {
                    
            //         uploadQuantity++;
            //         let data1 = [];

            //         data1.push({value: receptionList.shippingNumber, align: "center"}); //Parcel ID === Shipping number (A)
            //         data1.push({value: "1", align: "center"}); //Sender Type (B)
            //         data1.push({value: receptionList.payeeInformation.name, align: "center"}); //Sender full name (C)
            //         data1.push({value: receptionList.payeeInformation.name, align: "center"}); //Sender contact name (D)
            //         data1.push({value: receptionList.payeeInformation.phoneNumber1+","+receptionList.payeeInformation.phoneNumber2, align: "center"}); //Sender phones (E)
            //         data1.push({value: receptionList.payeeInformation.email, align: "center"}); //Sender email (F)
            //         data1.push({value: receptionList.payeeInformation.zipCode, align: "center"}); //Sender postal (G)
            //         data1.push({value: receptionList.payeeInformation.shippingCountry.code, align: "center"}); //Sender country (H)
            //         data1.push({value: receptionList.payeeInformation.states, align: "center"}); //Sender region (I)
            //         data1.push({value: receptionList.payeeInformation.cities, align: "center"}); //Sender cities (J)
            //         data1.push({value: receptionList.payeeInformation.streetAddress1+","+receptionList.payeeInformation.streetAddress2, align: "center"}); //Sender full address (K)
            //         data1.push({value: "", align: "center"}); //Sender warehouse ownser (L)
            //         data1.push({value: "", align: "center"}); //Sender warehouse (M)
            //         data1.push({value: "1", align: "center"}); //Reciever Type (N)

            //         data1.push({value: receptionList.user.customer_name, align: "center"}); //Reciever full name (O)
            //         data1.push({value: receptionList.user.customer_name, align: "center"}); //Reciever contactl name (P)
            //         data1.push({value: receptionList.user.phone_number, align: "center"}); //Reciever phones (Q)
            //         data1.push({value: receptionList.user.email, align: "center"}); //Reciever email (R)
            //         data1.push({value: receptionList.user.code, align: "center"}); //Reciever post code (S)
            //         data1.push({value: "", align: "center"}); //Reciever country (T)
            //         data1.push({value: receptionList.user.english_address_state, align: "center"}); //Reciever region (U)
            //         data1.push({value: receptionList.user.english_address_city, align: "center"}); //Reciever city (V)
            //         data1.push({value: receptionList.user.address_1+","+receptionList.user.address_2, align: "center"}); //Reciever address (W)
            //         data1.push({value: "", align: "center"}); //Reciever warehouse owner (X)
            //         data1.push({value: "", align: "center"}); //Reciever warehouse (Y)

            //         let invoiceDescription = "";
            //         let fullDescription = "";

            //         if(receptionList.productInformation && receptionList.productInformation.productInformation) {
            //           const productInfos = receptionList.productInformation.productInformation;
            //           const currency = receptionList.productInformation.currency;
                      

            //           productInfos.map((pInfo) => {
            //             invoiceDescription = invoiceDescription === "" ? "" : " , "+(pInfo.productName+" "+pInfo.quantity+" "+currency+pInfo.unitPrice);
            //             fullDescription = fullDescription === "" ? "" : " , "+(pInfo.productName+" "+pInfo.quantity);
            //           })
            //         }

            //         data1.push({value: invoiceDescription, align: "center"}); //Invoice description (Z)
            //         data1.push({value: fullDescription, align: "center"}); //Full description (AA)

            //         let actualWeight = totalWeight.toFixed(2);

            //         data1.push({value: "10x10x10 "+actualWeight+"kg", align: "center"}); //Place description (AB)

            //         actualWeight = actualWeight.toString();
            //         actualWeight = actualWeight.replace(".",",");

            //         data1.push({value: actualWeight, align: "center"}); //Actual Weight (AC)
            //         data1.push({ value: receptionList.shippingNumber, align: "center"}); //Related order (AD)
            //         data1.push({value: receptionList.user.code, align: "center"}); //Reciever post code (AE)
            //         data1.push({value: "", align: "center"}); //Reciever country (AF)
            //         data1.push({value: receptionList.user.english_address_state, align: "center"}); //Reciever region (AG)
            //         data1.push({value: receptionList.user.english_address_city, align: "center"}); //Reciever city (AH)
            //         data1.push({value: receptionList.user.address_1+","+receptionList.user.address_2, align: "center"}); //Reciever address (AI)

            //         data.push(data1);
            //     })
            // }

            // /**
            //     * Columns
            // */
            // const columns = [];
            // [...Array(23)].forEach((d) => {
            //     columns.push({
            //         width: 20,
            //     });
            // });

            // let uploadQuantityString = "";
            // if(getlength(uploadQuantity) === 1) {
            //     uploadQuantityString = "00"+(uploadQuantity.toString());
            // }
            // else if(getlength(uploadQuantity) === 2) {
            //     uploadQuantityString = "0"+(uploadQuantity.toString());
            // }
            // else {
            //     uploadQuantityString = (uploadQuantity.toString());
            // }

            // writeXlsxFile(data, {
            //     columns,
            //     fileName: `H3_BR_NLPost_SERVICE_Match_${uploadQuantityString}_${masterNumber}_${masterCreatedAt}.xlsx`,
            // });
            // console.log(data);
        // }
    // }


};

export default exportBRNLPostServiceXlsx;