import writeXlsxFile from "write-excel-file";

//Export xlsx with multiple table in one sheet
export const exportXlsx = async (files, country, shipDetail) => {
	let data = [];

	//Processing country code header
	let countryHead = [];
	let cObj = {
		value: country.code,
		span: 2 * files.length,
		align: "center",
	};
	countryHead.push(cObj);
	data.push(countryHead);

	//Processing the ship header
	let shipHead = [];
	[...files]
		.sort((a, b) => a.index - b.index)
		.forEach((d, i) => {
			const sObj = {};
			sObj.value = shipDetail.find(
				(el) => el.id === d.data[0].shippingTypeDetailId
			).shippingType;
			sObj.span = 2;
			sObj.align = "center";
			shipHead.push(sObj);
			if (shipHead.length % 2 !== 0) {
				//Appending null
				shipHead.push({ value: null });
			}
		});
	data.push(shipHead);

	//Processing the main header
	let mainHead = [];
	let head = [
		{
			value: "배송 무게(Kg)",
			fontWeight: "bold",
			align: "center",
		},
		{
			value: "배송비",
			fontWeight: "bold",
			align: "center",
		},
	];
	files.forEach((d) => {
		mainHead = [...mainHead, ...head];
	});
	data.push(mainHead);

	//Progessing data
	let dataRow = [];
	files.forEach((d, boxindex) => {
		let temp = [];
		//Pushing the data
		d.data.forEach((s, i) => {
			//For weight
			let wobj = {};
			wobj["type"] = Number;
			wobj["value"] = s.weight;
			wobj["align"] = "center";
			//For fee
			let robj = {};
			robj["type"] = Number;
			robj["value"] = s.deliveryFee;
			robj["align"] = "center";
			temp.push([wobj, robj]);
		});
		dataRow.unshift(temp);
	});

	let finalData = [];
	dataRow.forEach((d, i) => {
		if (i === 0) {
			d.forEach((s, i) => {
				finalData[i] = [...s];
			});
		} else {
			d.forEach((s, i) => {
				finalData[i] = [...finalData[i], ...s];
			});
		}
	});
	data.push(...finalData);

	//Columns width
	const columns = [];
	[...Array(files.length * 2)].forEach((d) => {
		columns.push({
			width: 20,
		});
	});

	writeXlsxFile(data, {
		columns,
		fileName: country.name + ".xlsx",
	});
};

//Export xlsx with multiple table in multiple sheets
export const exportMultipleSeets = async (files, country, shipDetail) => {
	const schema = [
		{
			column: "배송 무게(Kg)",
			type: Number,
			value: (d) => d.weight,
		},
		{
			column: "배송비",
			type: Number,
			value: (d) => d.deliveryFee,
		},
	];

	let dataArr = [];
	files.forEach((d) => {
		let arr = [];
		d.data.forEach((s) => {
			arr.push(s);
		});
		dataArr.push(arr);
	});

	let schemaArr = [];
	files.forEach((d) => {
		schemaArr.push(schema);
	});

	let sheetsArr = [];
	files.forEach((d, i) => {
		sheetsArr.unshift(
			shipDetail.find((el) => el.id === d.data[0].shippingTypeDetailId)
				.shippingType
		);
	});

	writeXlsxFile(dataArr, {
		schema: schemaArr,
		sheets: sheetsArr,
		fileName: country.name + ".xlsx",
	});
};

//Sample for groupRate
export const exportGroupSample = async () => {
	let dataArr = [
		[
			{
				value:
					"해당 국가의 배송타입이 1개 이상일 경우,우측에 배송 타입과 배송 무게, 배송비란을 추가해서 기입해주세요.",
				span: 2,
				fontWeight: "bold",
				align: "center",
				backgroundColor: "#CEE3F6",
			},
		],
		[
			{
				value: "국가코드 입력 (예시: FR)",
				span: 2,
				fontWeight: "bold",
				align: "center",
				backgroundColor: "#CEE3F6",
			},
		],
		[
			{
				value: "배송타입 입력 (예시: SHIPTER_FR)",
				span: 2,
				fontWeight: "bold",
				align: "center",
				backgroundColor: "#CEE3F6",
			},
		],
		[
			{
				value: " 배송 무게(Kg)",
				fontWeight: "bold",
				align: "center",
				backgroundColor: "#CEE3F6",
			},
			{
				value: "배송비",
				fontWeight: "bold",
				align: "center",
				backgroundColor: "#CEE3F6",
			},
		],
		[
			{
				value: 1,
				align: "center",
			},
			{
				value: 1000,
				align: "center",
			},
		],
	];

	//Columns width
	const columns = [];
	[...Array(2)].forEach((d) => {
		columns.push({
			width: 40,
		});
	});

	writeXlsxFile(dataArr, {
		columns,
		fileName: "groupSample.xlsx",
	});
};

//Sample for groupRate
export const exportGeneralSample = async () => {
	let dataArr = [
		[
			{
				value: "국가코드입력(예시: FR)",
				span: 4,
				fontWeight: "bold",
				align: "center",
				backgroundColor: "#CEE3F6",
			},
		],
		[
			{
				value: "배송타입1  입력(예시: SHIPTER_FR)",
				span: 2,
				fontWeight: "bold",
				align: "center",
				backgroundColor: "#CEE3F6",
			},
			{
				value: null,
			},
			{
				value: "배송타입입력2 (예시: SHIPTER_FR2)",
				span: 2,
				fontWeight: "bold",
				align: "center",
				backgroundColor: "#CEE3F6",
			},
		],
		[
			{
				value: " 배송 무게(Kg)",
				fontWeight: "bold",
				align: "center",
				backgroundColor: "#CEE3F6",
			},
			{
				value: "배송비",
				fontWeight: "bold",
				align: "center",
				backgroundColor: "#CEE3F6",
			},
			{
				value: " 배송 무게(Kg)",
				fontWeight: "bold",
				align: "center",
				backgroundColor: "#CEE3F6",
			},
			{
				value: "배송비",
				fontWeight: "bold",
				align: "center",
				backgroundColor: "#CEE3F6",
			},
		],
		[
			{
				value: 1,
				align: "center",
			},
			{
				value: 1000,
				align: "center",
			},
			{
				value: 1,
				align: "center",
			},
			{
				value: 1000,
				align: "center",
			},
		],
	];

	//Columns width
	const columns = [];
	[...Array(4)].forEach((d) => {
		columns.push({
			width: 40,
		});
	});

	writeXlsxFile(dataArr, {
		columns,
		fileName: "generalSample.xlsx",
	});
};
