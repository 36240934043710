import CustomCard from "../../../components/CustomCard";
import {
  Box,
  Button,
  Grid,
  InputAdornment,
  Stack,
  Typography,
} from "@mui/material";
import CustomSelect from "../../../components/CustomSelect";
import CustomInput from "../../../components/CustomInput";
import CustomButton from "../../../components/CustomButton";
import React from "react";

import CreateHOC from "../../../SharedComponents/CreateHOC";

export const WaybillAdd = () => {
  return (
    <CreateHOC>
      <WaybillAddContainer />
    </CreateHOC>
  );
};

const WaybillAddContainer = () => {
  return (
    <CustomCard>
      <Stack spacing={"35px"}>
        <Box>
          <Grid
            container
            alignItems={"center"}
            spacing={2}
            pb="0"
            pt="20px"
            px="20px"
          >
            <Grid item xs={2} padding="0">
              <Typography
                style={{
                  fontSize: 12,
                  fontWeight: "600",
                  padding: "0 20px",
                }}
                variant="p"
              >
                운송장 업로드
              </Typography>
            </Grid>
            <Grid item xs={3} padding="0">
              <CustomSelect label="Israel"></CustomSelect>
            </Grid>

            <Grid item xs={3} padding="0">
              <CustomSelect label="IL_DOOR"></CustomSelect>
            </Grid>

            <Grid item xs={3} padding="0">
              <div style={{ display: "flex" }}>
                <CustomInput
                  endAdornment={
                    <InputAdornment style={{ color: "#1F4173" }} position="end">
                      kg
                    </InputAdornment>
                  }
                />
                <Button style={{ width: "45%" }} variant="contained">
                  엑셀 업로드
                </Button>
              </div>
            </Grid>
          </Grid>
        </Box>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingBottom: "20px",
          }}
        >
          <CustomButton background="#84B4FA" width="123px">
            운송장 등록
          </CustomButton>
        </div>
      </Stack>
    </CustomCard>
  );
};
