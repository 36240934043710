import {
	Grid,
	Pagination,
	Stack,
	Typography,
	Checkbox,
	Modal,
	MenuItem,
	Box,
} from "@mui/material";
import { pdf } from "@react-pdf/renderer";
import { SelectBox, TextFieldBox } from "../../../components/CustomInput";
import TableContainer from "../../../components/TableContainer";
import React, { useState, useEffect, useCallback } from "react";
import { generateSearchData, getQueryParams } from "../../../utils/utils";
import ListHOC from "../../../SharedComponents/ListHOC";
import Moment from "moment";
import CustomButton from "../../../components/CustomButton";
import { onDropDownSearchChange } from "../../../utils/onDropDownSearchChange";
import { useDeliveryRequest } from "../../../Context/DeliveryRequestContext";
import SearchIcon from "../../../Common/assets/Images/SearchIcon";
import DocumentIcon from "../../../Common/assets/Images/DocIcon";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import DropdownAndSearchInput from "../../../components/DropdownAndSearchInput2";
import DownloadExcelButton from "../../../components/DownloadExcelButton";
import CustomSelect from "../../../components/CustomSelect";
import DateRangePicker from "../../../components/DateRangePicker";
import { useGetList, useCreate } from "react-admin";
import { Form, Formik } from "formik";
import { gql } from "@apollo/client";
import { APIClient } from "../../../utils/apiGraphql";
import { PrintImage, PrintLabel } from "../../../components/labels/PrintLabel";
import { UZPostEMS } from "../../../components/labels/UZPostEMS";
import { LTPostPremium } from "../../../components/labels/LTPostPremium";
import { LTPostPrime } from "../../../components/labels/LTPostPrime";
import { LTPostRegistered } from "../../../components/labels/LTPostRegistered";
import { NovaLabel } from "../../../components/labels/NovaLabel";
import { LTPostR } from "../../../components/labels/LTPostR";
import { LTPostL } from "../../../components/labels/LTPostL";
import ReactPDF from "@react-pdf/renderer";
import { PDFViewer } from "@react-pdf/renderer";
import Icons from "../../../utils/icon";
import Toaster from "../../../utils/Toaster";
import { useBarCode } from "../../../hooks/useBarCode";
import { Link } from "react-router-dom";
import { UZPostR } from "../../../components/labels/UZPostR";
import { NLPost } from "../../../components/labels/NLPost";
import { GPSLabel } from "../../../components/labels/GPSLabel";
import apiImage from "../../../data/image.png";
import apiImage2 from "../../../data/UZ_POST_EMS.jpg";
import usePrintWaybill from "../../../resources/WaybillPrint";
import apiPdf from "../../../data/waybill.pdf";
import UpdateWayBillStat from "../../../modals/printWaybillModal";
import { updateAllReqShipping } from "./utils/updateReqShipping";

import axios from "axios";
import { DeliveryRequestTable } from "../../../tables/DeliveryRequestTable";

export default function DeliveryRequestDetails({ ...props }) {
	return (
		<ListHOC
			// loader={false}
			_resource={"RequestShipping"}
			showFilter={props.filter}
		>
			<DeliveryRequestDetailsDetails {...props} />
		</ListHOC>
	);
}

function DeliveryRequestDetailsDetails({
	data: _Data,
	page,
	count,
	countTotal,
	perPage,
	handleSetPerPage,
	handlePageChange,
	handleSearch,
	isLoading,
	setActiveScreen,
	filter,
	screens,
	excelData,
	...hocProps
}) {
	const [filterQuery, setFilterQuery] = useState(null);
	const [data, setData] = useState([]);
	const [countriesName, setCountriesName] = useState([]);
	const { userId } = useDeliveryRequest();
	const [productQuantity, setProductQuantity] = useState(null);
	const [checkedList, setCheckedList] = useState([]);
	const { state, search } = useLocation();
	const [excelData1, setExcelData1] = useState([]);
	const [labelPrint, setLabelPrint] = useState(false);
	const [dateType, setDateType] = useState("processReleaseDate");
	const [dateFilter, setDateFilter] = useState(null);
	const [selectedRequests, setSelectedRequests] = useState([]);
	const { printRequestedLabels } = usePrintWaybill(selectedRequests); //Print waybill

	const [shippingRequestsCount, setShippingRequestsCount] = useState(0);
	const [shippingRequests, setShippingRequests] = useState([]);

	const shippingTypes = useGetList("ShippingTypes", {
		sort: { field: "published_at", order: "DESC" },
		pagination: { page: 1, perPage: {} },
	});

	// console.log({ excelData });
	const getBarCode = useBarCode();
	// const List = useGetList("RequestShipping", {
	// 	pagination: { page: 1, perPage: {} },
	// });

	const [columns, setColumns] = useState({
		"특이사항(메모)": "",
		번호: "",
		"운송장 출력": "",
		타입: "",
		"고객사 이름": "",
		배송국가: "",
		배송종류: "",
		관세지불방식: "",
		상태: "",
		접수일: "",
		출고일: "",
		접수번호: "",
		배송번호: "",
		"적용무게(KG)": "",
		"Master No": "",
		"SUB MASTER": "",
		주문번호1: "",
		주문번호2: "",
		배송회사: "",
		"CUSTOM NUMBER": "",
		"출발국가 택배번호": "",
		"배송비 ": "",
		"택배 추가요금": "",
		"화폐 ": "",
		"가로(Cm)": "",
		"세로(Cm)": "",
		"높이(Cm)": "",
		"Box 수량": "",
		"실제무게(KG)": "",
		"부피무게(KG)": "",
		"적용무게(KG) ": "",
		"주민번호/통관번호/CPF": "",
		IOSS: "",
		EORI: "",
		VOEC: "",
		"상품 바코드": "",
		상품목록: "",
		상품수량: "",
		"총 상품금액": "",
		화폐단위: "",
		"발송인 이름": "",
		"발송인 전화번호": "",
		"발송인 주소": "",
		"수취인 이름": "",
		"수취인 이름 (YOMIGANA)": "",
		"수취인 휴대폰 번호": "",
		"수취인 전화번호": "",
		"수취인 이메일": "",
		"수취인 우편번호": "",
		Colony: "",
		주: "",
		도시: "",
		"수취인 주소1": "",
		"수취인 주소2": "",
		배송메세지: "",
		HSCODE: "",
		"상품종류(1:Commercial,2:Gift,3:ReturnedGood,4:Documents,5:Other)": "",
		통관구분: "",
		"수출신고 유형": "",
		"판매 site": "",
		"사용자 데이터 1": "",
		"사용자 데이터 2": "",
		"사용자 데이터 3": "",
		배송추적: "",
		수정: "",
	});

	const doSearch = useCallback(() => {
		let additionalValues = {};

		if (state) {
			const { user123company_name_en } = state;
			const queryParams = getQueryParams(search);
			const company_name = queryParams.get("company_name");
			additionalValues = {
				...additionalValues,
				...(company_name == user123company_name_en && {
					user123company_name_en,
				}),
			};
		}

		generateSearchData(handleSearch, filterQuery, additionalValues);
	}, [filterQuery]);

	// const doSearch = useCallback(() => {
	// 	generateSearchData(handleSearch, filterQuery);
	// }, [filterQuery]);

	useEffect(() => {
		doSearch();
	}, [filterQuery]);

	useEffect(() => {
		// excelData
		// 		.filter((item2) => {
		// 			if (filter?.user123company_name_kr) {
		// 				return item2.user?.company_name_kr.includes(
		// 					filter.user123company_name_kr
		// 				);
		// 			} else return item2;
		// 		})
		// 		.forEach((item, index) => {
		// console.log("selectedRequests", checkedList);
		// console.log("excelData", excelData);
		let exportData = [];

		if (perPage === checkedList.length) {
			excelData = shippingRequests;
		}

		if (checkedList.length && excelData) {
			excelData.forEach((item, index) => {
				if (
					checkedList.indexOf(item.id) != -1 ||
					perPage === checkedList.length
				) {
					var productNames = [];
					var productQuantity = [];
					var productHscode = [];
					var productPurchaseUrl = [];
					var productBarcode = [];
					var totalQuantity = 0;
					item.productInformation.productInformation.forEach((subItem) => {
						totalQuantity = totalQuantity + parseInt(subItem.quantity);
						productNames.push(`${subItem.productName}`);
						productHscode.push(`${subItem.hscode}`);
						productQuantity.push(`${subItem.quantity}`);
						productPurchaseUrl.push(`${subItem.purchaseURL}`);
						productBarcode.push(`${subItem.barcode}`);
					});

					const Data = {
						"특이사항(메모)":
							item.miscellaneousInformation?.coupon &&
							`고객사 쿠폰 적용(${item.miscellaneousInformation.coupon})`,
						번호: index + 1,
						"운송장 출력": item.waybillPrintStatus ? "O" : "X",
						타입: "일반",
						"고객사 이름": item.user?.company_name_kr,
						배송국가: item.payeeInformation?.shippingCountry.code,
						배송종류: item.shippingType?.shippingType,
						관세지불방식: item.bag?.howToPayCustomDuties,
						상태:
							item.status === "waiting_warehousing"
								? "입고대기"
								: item.status === "warehousing_completed"
								? "입고완료"
								: item.status === "waiting_delivery"
								? "출고대기"
								: item.status === "started_delivery"
								? "출고완료"
								: item.status === "completed_delivery" && "배송완료",
						접수일: item.createdAt
							? Moment(item.createdAt).format("YYYY-MM-DD")
							: "",
						출고일: item.processReleaseDate
							? Moment(item.processReleaseDate).format("YYYY-MM-DD")
							: "",
						접수번호: item.trackingNumber,
						배송번호: item.shippingNumber,
						"적용무게(KG)": item.miscellaneousInformation?.applicableWeight,
						"Master No": item.masterNo,
						"SUB MASTER": "",
						주문번호1: item.productInformation?.orderNo1,
						주문번호2: item.productInformation?.orderNo2,
						배송회사: "",
						"CUSTOM NUMBER": "",
						"출발국가 택배번호":
							item.productInformation?.departureCountryCourierNumber,
						"배송비 ": item.deliveryFee,
						"택배 추가요금": item.additionalFee,
						"화폐 ": "KRW",
						"가로(Cm)": item.miscellaneousInformation?.horizontal,
						"세로(Cm)": item.miscellaneousInformation?.portrait,
						"높이(Cm)": item.miscellaneousInformation?.height,
						"Box 수량": item.miscellaneousInformation?.boxQuantity,
						"실제무게(KG)": item.miscellaneousInformation?.actualWeight,
						"부피무게(KG)": item.miscellaneousInformation?.bulkWeight,
						"적용무게(KG) ": item.miscellaneousInformation?.applicableWeight,
						"주민번호/통관번호/CPF": item.payeeInformation?.ssn,
						IOSS: item.payeeInformation?.euioss,
						EORI: item.payeeInformation?.ukeori,
						VOEC: "",
						"상품 바코드": productBarcode,
						상품목록: productNames,
						상품수량: productQuantity,
						"총 상품금액": calculateTotal(
							item.productInformation?.productInformation
						),
						화폐단위: item.payeeInformation?.shippingCountry.monetaryUnit,
						"발송인 이름": item.senderInformation?.name,
						"발송인 전화번호": item.senderInformation?.phoneNumber,
						"발송인 주소": item.senderInformation?.address,
						"수취인 이름": item.payeeInformation?.name,
						"수취인 이름 (YOMIGANA)": "",
						"수취인 전화번호": item.payeeInformation?.phoneNumber2,
						"수취인 휴대폰 번호": item.payeeInformation?.phoneNumber1,
						"수취인 이메일": item.payeeInformation?.email,
						"수취인 우편번호": item.payeeInformation?.zipCode,
						Colony: item.payeeInformation?.mexicoColony,
						주: item.payeeInformation?.states,
						도시: item.payeeInformation?.cities,
						"수취인 주소1": item.payeeInformation?.streetAddress1,
						"수취인 주소2": item.payeeInformation?.streetAddress2,
						배송메세지: item.miscellaneousInformation?.shippingMessage,
						HSCODE: productHscode,
						"상품종류(1:Commercial,2:Gift,3:ReturnedGood,4:Documents,5:Other)":
							item.miscellaneousInformation?.productType,
						통관구분: "",
						"수출신고 유형":
							item.miscellaneousInformation?.exportTypeDeclaration,
						"판매 site": productPurchaseUrl,
						"사용자 데이터 1": item.miscellaneousInformation?.userData,
						"사용자 데이터 2": item.miscellaneousInformation?.userData2,
						"사용자 데이터 3": item.miscellaneousInformation?.userData3,
					};
					exportData.push(Data);
				}
			});

			setExcelData1(exportData);
		}
	}, [checkedList]);

	const getUser = useCallback(() => {
		if (userId) {
			setFilterQuery({ userId });
		}
	}, [userId]);

	useEffect(() => {
		getUser();
	}, [userId]);

	useEffect(() => {
		setData(_Data);
		// const initialValue = 0;
		// const quantity = _Data.productInformation?.productInformation?.reduce(
		// 	(previousValue, currentValue) =>
		// 		previousValue.quantity + currentValue.quantity,
		// 	initialValue
		// );
		// setProductQuantity(quantity);
	}, [_Data]);

	const getComaSeparated = (productDetails, key) => {
		return productDetails && key
			? productDetails.map((res) => res[key]).join(", ")
			: "";
	};

	const handleCheck = ({ target }) => {
		const { value, checked } = target;
		setCheckedList((prevState) => {
			const temp = [...prevState];
			if (checked) {
				temp.push(+value);
				return temp;
			} else {
				return temp.filter((res) => res !== +value);
			}
		});
	};

	//Waybill print confirm function
	const wayBillPrint = (d) => {
		// if (d) {
		// 	handleExport(checkedList);
		// }
		updateAllReqShipping(selectedRequests.flat()).then(
			(res) => {
				printRequestedLabels(selectedRequests, null);
				//Updating table row state data
				let cloneData = [...data];
				const ids = selectedRequests.flat().map((re) => re.id);
				ids.forEach((id) => {
					let index = cloneData.findIndex((el) => el.id === id);
					if (index !== -1) {
						cloneData[index].printWaybillAfterWeightChange = true;
					}
				});
				setData(cloneData);
			},
			(err) => {
				Toaster("error", "에러가 발생하였습니다.");
			}
		);

		setLabelPrint(false);
	};

	const getShippingRequests = async () => {
		const Query = gql(`
		query($page:Float, $perPage:Float){
			allRequestShippings(page:$page, perPage:$perPage){
				id
				waybillPrintStatus
				packingListPrintStatus
				masterNo
				totalSKU
				bag {
					id
					name
					bagNumber
					shippingCountry{
						id
						__typename
					}
					deliveryType{
						id
						__typename
					}
					deliveryService{
						id
						__typename
					}
			
					howToPayCustomDuties
					dgPackagingManagementCode
					userProfile{
						id
						__typename
					}
					userId
					numberOfUses
					weight
					usedWeight
					__typename
				}
				senderInformation {
					id
					name
					phoneNumber
					address
					requestShippingId
					deleted
					createdAt
					updatedAt
					__typename
				}
				whereAbout{
					id
					status
					requestShippingId
					requestShipping{
						id
						__typename
					}
					memo
					__typename
				}
				payeeInformation {
					id
					name
					shippingCountryId
					shippingCountry{
						id
						name
						code
						monetaryUnit
						country_name_en
						__typename
					}
					phoneNumber1
					phoneNumber2
					zipCode
					ssn
					ukeori
					euioss
					states
					cities
					streetAddress1
					streetAddress2
					email
					requestShippingId
					mexicoColony
					deleted
					createdAt
					updatedAt
					__typename
				}
				miscellaneousInformation {
					id
					shippingMessage
					horizontal
					portrait
					height
					actualWeight
					applicableWeight
					bulkWeight
					userData
					userDate2
					userData3
					boxQuantity
					productType
					exportDeclarationNumber
					requestShippingId
					deleted
					coupon
					createdAt
					updatedAt
					__typename
				}
				productInformation {
					id
					orderNo1
					orderNo2
					departureCountryCourierNumber
					currency
					productInformation {
						id
						barcode
						productName
						quantity
						unitPrice
						brandName
						sku
						purchaseURL
						hscode
						deleted
						createdAt
						updatedAt
						__typename
					}
					totalPriceAmount
					requestShippingId
					deleted
					createdAt
					updatedAt
					__typename
				}
			
				user {
					id
					username
					userID
					company_registration_number
					company_name_kr
					company_name_en
					customer_name
					totalDeposit
					email
					memo
					request_issuance_of_tax_invoice
					e_tax_invoice_email
					balance
					business_condition
					phone_number
					mobile_number
					mallId
					sellerPartyId
					apiKey
					__typename
				}
				shippingType{
					id
					shippingType
					apiType
					apiType_{
						name
						id
					}
					shippingWeight
					memo
					shippingTypeId
					createdAt
					__typename
				}
				shippingCountry{
					id
					name
					code
					monetaryUnit
					country_name_en
					shippingTypesDetailsIds
					isActive
					createdAt
					__typename
				}
				userId
				deleted
				deliveryFee
				createdAt
				updatedAt
				status
				trackingNumber
				shippingNumber
				additionalFee
				orderNumber
				weightValidation
				printWaybillAfterWeightChange
				sampoongAmountCheck
				treatmentAfterWeightChange
				processReleaseDate
				exportDeclarationRequest
				__typename
			}
		}
	    `);

		try {
			const {
				data: { allRequestShippings },
			} = await APIClient.query({
				query: Query,
				variables: {
					page: 0,
					perPage: shippingRequestsCount,
				},
			});
			setShippingRequests(allRequestShippings);
		} catch (e) {
			console.log(e);
		}
	};

	const getShippingRequestsCount = async () => {
		const Query = gql(`
		query{
			_allRequestShippingsMeta{
				count
			}
		}
      `);

		try {
			const {
				data: {
					_allRequestShippingsMeta: { count },
				},
			} = await APIClient.query({
				query: Query,
			});
			setShippingRequestsCount(count);
		} catch (e) {
			console.log(e);
		}
	};

	useEffect(() => {
		getShippingRequestsCount();
	}, []);

	useEffect(() => {
		getShippingRequests();
	}, [shippingRequestsCount]);

	useEffect(() => {
		let exportData = [];

		if (excelData) {
			excelData
				.filter((item2) => {
					if (filter?.user123company_name_kr) {
						return item2.user?.company_name_kr.includes(
							filter.user123company_name_kr
						);
					} else return item2;
				})
				.forEach((item, index) => {
					var productNames = [];
					var productQuantity = [];
					var productHscode = [];
					var productPurchaseUrl = [];
					var productBarcode = [];
					var totalQuantity = 0;
					item.productInformation.productInformation.forEach((subItem) => {
						totalQuantity = totalQuantity + parseInt(subItem.quantity);
						productNames.push(`${subItem.productName}`);
						productHscode.push(`${subItem.hscode}`);
						productQuantity.push(`${subItem.quantity}`);
						productPurchaseUrl.push(`${subItem.purchaseURL}`);
						productBarcode.push(`${subItem.barcode}`);
					});

					const Data = {
						"특이사항(메모)":
							item.miscellaneousInformation?.coupon &&
							`고객사 쿠폰 적용(${item.miscellaneousInformation.coupon})`,
						번호: index + 1,
						"운송장 출력": item.waybillPrintStatus ? "O" : "X",
						타입: "일반",
						"고객사 이름": item.user?.company_name_kr,
						배송국가: item.payeeInformation?.shippingCountry.code,
						배송종류: item.shippingType?.shippingType,
						관세지불방식: item.bag?.howToPayCustomDuties,
						상태:
							item.status === "waiting_warehousing"
								? "입고대기"
								: item.status === "warehousing_completed"
								? "입고완료"
								: item.status === "waiting_delivery"
								? "출고대기"
								: item.status === "started_delivery"
								? "출고완료"
								: item.status === "completed_delivery" && "배송완료",
						접수일: item.createdAt
							? Moment(item.createdAt).format("YYYY-MM-DD")
							: "",
						출고일: item.processReleaseDate
							? Moment(item.processReleaseDate).format("YYYY-MM-DD")
							: "",
						접수번호: item.trackingNumber,
						배송번호: item.shippingNumber,
						"적용무게(KG)": item.miscellaneousInformation?.applicableWeight,
						"Master No": item.masterNo,
						"SUB MASTER": "",
						주문번호1: item.productInformation?.orderNo1,
						주문번호2: item.productInformation?.orderNo2,
						배송회사: "",
						"CUSTOM NUMBER": "",
						"출발국가 택배번호":
							item.productInformation?.departureCountryCourierNumber,
						"배송비 ": item.deliveryFee,
						"택배 추가요금": item.additionalFee,
						"화폐 ": "KRW",
						"가로(Cm)": item.miscellaneousInformation?.horizontal,
						"세로(Cm)": item.miscellaneousInformation?.portrait,
						"높이(Cm)": item.miscellaneousInformation?.height,
						"Box 수량": item.miscellaneousInformation?.boxQuantity,
						"실제무게(KG)": item.miscellaneousInformation?.actualWeight,
						"부피무게(KG)": item.miscellaneousInformation?.bulkWeight,
						"적용무게(KG) ": item.miscellaneousInformation?.applicableWeight,
						"주민번호/통관번호/CPF": item.payeeInformation?.ssn,
						IOSS: item.payeeInformation?.euioss,
						EORI: item.payeeInformation?.ukeori,
						VOEC: "",
						"상품 바코드": productBarcode,
						상품목록: productNames,
						상품수량: productQuantity,
						"총 상품금액": calculateTotal(
							item.productInformation?.productInformation
						),
						화폐단위: item.payeeInformation?.shippingCountry.monetaryUnit,
						"발송인 이름": item.senderInformation?.name,
						"발송인 전화번호": item.senderInformation?.phoneNumber,
						"발송인 주소": item.senderInformation?.address,
						"수취인 이름": item.payeeInformation?.name,
						"수취인 이름 (YOMIGANA)": "",
						"수취인 전화번호": item.payeeInformation?.phoneNumber2,
						"수취인 휴대폰 번호": item.payeeInformation?.phoneNumber1,
						"수취인 이메일": item.payeeInformation?.email,
						"수취인 우편번호": item.payeeInformation?.zipCode,
						Colony: item.payeeInformation?.mexicoColony,
						주: item.payeeInformation?.states,
						도시: item.payeeInformation?.cities,
						"수취인 주소1": item.payeeInformation?.streetAddress1,
						"수취인 주소2": item.payeeInformation?.streetAddress2,
						배송메세지: item.miscellaneousInformation?.shippingMessage,
						HSCODE: productHscode,
						"상품종류(1:Commercial,2:Gift,3:ReturnedGood,4:Documents,5:Other)":
							item.miscellaneousInformation?.productType,
						통관구분: "",
						"수출신고 유형":
							item.miscellaneousInformation?.exportTypeDeclaration,
						"판매 site": productPurchaseUrl,
						"사용자 데이터 1": item.miscellaneousInformation?.userData,
						"사용자 데이터 2": item.miscellaneousInformation?.userData2,
						"사용자 데이터 3": item.miscellaneousInformation?.userData3,
					};
					exportData.push(Data);
				});
			setExcelData1(exportData);
		}
	}, [excelData, filter]);

	const doSearch2 = useCallback(() => {
		// generateSearchData(handleSearch, filterQuery);
		handleSearch(dateFilter);
	}, [dateFilter]);

	useEffect(() => doSearch2(), [dateFilter]);

	const rangeChange = (value) => {
		const splitted = value[dateType]?.split(" ");

		const data = {
			gte: splitted[0],
			lte: splitted[2],
		};

		setDateFilter({
			[dateType]: data,
		});
	};

	const fetchUser = async () => {
		const Query = gql(`
      query{
        User{
          id
        }
      }
      `);
		try {
			const res = await APIClient.query({
				query: Query,
			});
			// console.log(res);
		} catch (e) {
			console.log(e);
		}
	};

	useEffect(() => {
		fetchUser();
	}, []);

	const getActiveCountryList = async (filter) => {
		const Query = gql(`
		query{
			allShippingCountries{
				id
				name
				isActive
			}
		}
      `);

		try {
			const res = await APIClient.query({
				query: Query,
			});
			setCountriesName(res.data?.allShippingCountries);
		} catch (e) {
			console.log(e);
		}
	};

	useEffect(() => {
		getActiveCountryList();
	}, []);

	function onClose() {
		setLabelPrint((prev) => !prev);
	}

	useEffect(() => {
		if (checkedList) {
			let arr = [];
			checkedList.forEach((item) =>
				getSelectedShippingRequests(item).then((res) => {
					if (selectedRequests.filter((item2) => item2.id !== res.id))
						setSelectedRequests((old) => [...old, res]);
				})
			);

			setSelectedRequests(arr);
			Toaster("success", "데이터 불러오기 성공!");
		}
	}, [checkedList]);

	const calculateTotal = (products) => {
		let total = 0;
		products.forEach((item) => {
			let product = parseInt(item.quantity) * parseInt(item.unitPrice);
			total = total + product;
		});
		return total;
	};

	// console.log("filterDate", dateFilter);
	// console.log("filter", filterQuery);
	return (
		<Stack spacing={"35px"}>
			<Stack spacing="20px">
				<Grid xs={12}>
					<Grid
						xs={12}
						container
						columnGap={1}
						style={{ justifyContent: "end" }}
					>
						<Grid xs={2} style={{ maxWidth: "200px" }}>
							<WayBillPrint
								// onClick={PrintRequestedLabels}
								// onClick={(e) => printRequestedLabels(selectedRequests, e)}
								onClick={() => setLabelPrint(true)}
								disabled={checkedList.length <= 0}
								// disabled={selectedRequests.length <= 0}
							>
								운송장 출력
							</WayBillPrint>
						</Grid>
						<Grid xs={3} style={{ maxWidth: "200px" }}>
							<DownloadExcelButton
								excelData={excelData1}
								fileName={"배송신청리스트" + Moment().format("YYYY-MM-DD")}
							/>
						</Grid>
						<Grid xs={3} style={{ maxWidth: "200px" }}>
							<CustomSelect
								value={String(perPage)}
								label=""
								onChange={({ target }) => {
									handleSetPerPage(target.value);
								}}
								options={["10", "20", "30", "40", "50", "100"]}
							></CustomSelect>
						</Grid>
					</Grid>

					<Grid
						xs={12}
						container
						columnGap={1}
						style={{ justifyContent: "end", marginTop: "10px" }}
					>
						<Grid xs={1.5} style={{ maxWidth: "180px" }}>
							<SelectBox
								defaultValue={dateType}
								onChange={(e) => {
									setDateType(e.target.value);
									setDateFilter(null);
								}}
							>
								<MenuItem value="processReleaseDate">출고일</MenuItem>
								<MenuItem value="createdAt">접수일</MenuItem>
							</SelectBox>
						</Grid>
						<Grid xs={2.8} style={{ maxWidth: "250px" }}>
							<Formik
								enableReinitialize
								initialValues={
									dateFilter || {
										[dateType]: {
											gte: `${Moment().format("YYYY-MM-DD")}`,

											lte: `${Moment().format("YYYY-MM-DD")}`,
										},
									}
								}
								onSubmit={(values) => {}}
							>
								{({ values, handleSubmit, setFieldValue, resetForm }) => {
									return (
										<Form onSubmit={handleSubmit}>
											<Grid
												container
												alignItems={"center"}
												style={{ flexWrap: "nowrap" }}
											>
												<Stack spacing={"20px"} width={340}>
													<div>
														<DateRangePicker
															name={dateType}
															value={`${values?.[dateType]?.gte} - ${values?.[dateType]?.lte}`}
															showLabel={false}
															handleChange={rangeChange}
														/>
													</div>
												</Stack>
											</Grid>
										</Form>
									);
								}}
							</Formik>
						</Grid>
						<Grid xs={2} style={{ maxWidth: "200px" }}>
							<SelectBox
								defaultValue={"default"}
								onChange={(e) => setFilterQuery({ status: e.target.value })}
							>
								<MenuItem value="default" disabled hidden>
									필터 옵션 선택
								</MenuItem>
								<MenuItem value="waiting_warehousing">입고대기</MenuItem>
								<MenuItem value="warehousing_completed">입고완료</MenuItem>
								<MenuItem value="waiting_delivery">출고대기</MenuItem>
								<MenuItem value="started_delivery">출고완료</MenuItem>
								<MenuItem value="completed_delivery">배송완료</MenuItem>
							</SelectBox>
						</Grid>
						<Grid xs={2} style={{ maxWidth: "200px" }}>
							<SelectBox
								defaultValue={"배송국가"}
								onChange={(e) => {
									const { target } = e;
									if (target.value === "all") {
										setFilterQuery({});
									} else
										setFilterQuery({
											payeeInformation123shippingCountryId: e.target.value,
										});
								}}
							>
								<MenuItem value="배송국가" disabled hidden>
									배송국가
								</MenuItem>
								<MenuItem value="all">전체보기</MenuItem>
								{countriesName &&
									countriesName.map((item, index) => (
										<MenuItem value={item.id} key={index}>
											{item.name}
										</MenuItem>
									))}
							</SelectBox>
						</Grid>
						<Grid xs={2} style={{ maxWidth: "200px" }}>
							<SelectBox
								defaultValue={"배송타입 선택"}
								onChange={(e) => {
									const { target } = e;
									if (target.value === "all") {
										setFilterQuery({});
									} else
										setFilterQuery({
											payeeInformation123shippingTypeId: e.target.value,
										});
								}}
							>
								<MenuItem value="배송타입 선택" disabled hidden>
									배송타입 선택
								</MenuItem>
								<MenuItem value="all">전체보기</MenuItem>
								{shippingTypes.data &&
									shippingTypes.data?.map((item, index) => {
										return item.details.map((item2, index) => (
											<MenuItem value={item2.id} key={index}>
												{item2.shippingType}
											</MenuItem>
										));
									})}
							</SelectBox>
						</Grid>
					</Grid>
					<Grid style={{ justifyContent: "flex-end", marginTop: "10px" }}>
						<Grid style={{ justifyContent: "flex-end" }}>
							<DropdownAndSearchInput
								onChange={(data) =>
									onDropDownSearchChange(data, setFilterQuery)
								}
								value={filterQuery}
								xs={12}
								defaultValue={{ key: "trackingNumber", value: "" }}
								dropDownValues={[
									{ value: "trackingNumber", title: "접수번호" },
									{ value: "shippingNumber", title: "배송번호" },
									{
										value: "productInformation123orderNo1",
										title: "주문번호1",
									},
									{
										value: "productInformation123orderNo2",
										title: "주문번호2",
									},
									{
										value: "productInformation123departureCountryCourierNumber",
										title: "출발국가 택배번호",
									},
								]}
								inputFieldPlaceHolder={"검색어를 입력해주세요."}
								justifyContent={"flex-end"}
							/>
						</Grid>
					</Grid>
				</Grid>
			</Stack>
			<DeliveryRequestTable
				data={data}
				header={[
					<Checkbox
						checked={data.every((res, index) => checkedList.includes(res.id))}
						onChange={({ target }) => {
							if (target.checked) {
								setCheckedList(data.map((res, index) => res.id));
							} else {
								setCheckedList([]);
							}
						}}
					/>,
				].concat(
					Object.keys(columns).map((item, index) => (
						<CustomHeading key={index}>{item}</CustomHeading>
					))
				)}
				handleCheck={handleCheck}
				checkedList={checkedList}
				countTotal={countTotal}
				page={page}
				calculateTotal={calculateTotal}
				setCheckedList={setCheckedList}
				{...hocProps}
			/>

			<UpdateWayBillStat
				title="운송장 출력"
				warningIcon={false}
				buttonText={"닫기"}
				onClose={onClose}
				isOpen={labelPrint}
				onConfirm={wayBillPrint}
				showPackage={false}
			/>
		</Stack>
	);
}

const CustomHeading = styled.div`
	padding: ${(props) => (props.padding ? props.padding : "0 30px")};
`;

const WayBillPrint = styled(CustomButton)`
	padding: 7px !important;
	background: transparent;
	color: #000;
	border: 1px solid #c4c4c4;

	&:hover {
		background-color: transparent;
		border: 1px solid #000;
	}
`;

const getSelectedShippingRequests = async (filter) => {
	const Query = gql(`
	query($filter:RequestShippingFilterInput)
  {
  allRequestShippings(filter:$filter){
		id
		bag {
			id
		
		}
		senderInformation {
			id
			name
			phoneNumber
			address
			requestShippingId
			deleted
			createdAt
			updatedAt
		}
		whereAbout{
			id
			status
			requestShippingId
			requestShipping{
				id
			
			}
			memo
	
		}
		payeeInformation {
			id
			name
			shippingCountryId
			shippingCountry{
				id
				name
				code
				country_name_en
				monetaryUnit
			}
			phoneNumber1
			phoneNumber2
			zipCode
			ssn
			ukeori
			euioss
			states
			cities
			streetAddress1
			streetAddress2
			email
			requestShippingId
			mexicoColony
			deleted
			createdAt
			updatedAt
			stateCode
		}
		miscellaneousInformation {
			id
			shippingMessage
			horizontal
			portrait
			height
			actualWeight
			applicableWeight
			bulkWeight
			userData
			userDate2
			userData3
			boxQuantity
			productType
			exportDeclarationNumber
			requestShippingId
			deleted
			createdAt
			updatedAt
			
		}
		productInformation {
			id
			orderNo1
			orderNo2
			departureCountryCourierNumber
			currency
			productInformation {
				id
				barcode
				productName
				quantity
				unitPrice
				brandName
				sku
				purchaseURL
				hscode
				deleted
				createdAt
				updatedAt
	
			}
			totalPriceAmount
			requestShippingId
			deleted
			createdAt
			updatedAt
		
		}
	
		user {
			id
			username
			userID
			company_registration_number
			company_name_kr
			company_name_en
			customer_name
			totalDeposit
			email
			memo
			request_issuance_of_tax_invoice
			e_tax_invoice_email
			balance
			business_condition
			phone_number
			mobile_number
			
		}
		shippingType{
			id
			shippingType
			apiType
			apiType_{
			id
			name
			}
			shippingWeight
			memo
			shippingTypeId
			createdAt
		}
		shippingCountry{
			id
			name
			code
			monetaryUnit
			country_name_en
			shippingTypesDetailsIds
			isActive
		}
		userId
		deleted
		createdAt
		updatedAt
		status
		trackingNumber
		shippingNumber
		additionalFee
		orderNumber
		weightValidation
		printWaybillAfterWeightChange
		sampoongAmountCheck
		treatmentAfterWeightChange
		processReleaseDate
	}
}
		`);

	try {
		const res = await APIClient.query({
			query: Query,
			variables: {
				filter: {
					id: filter,
				},
			},
		});
		return res.data?.allRequestShippings;
	} catch (e) {
		console.log(e);
	}
};
