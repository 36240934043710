import { ApolloClient, InMemoryCache } from "@apollo/client/core";
import { setContext } from "@apollo/client/link/context";
import { graphqlUrl, wsUrl } from "./constants";
import { split } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { createUploadLink } from "apollo-upload-client";
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';

const httpLink = createUploadLink({
  uri: graphqlUrl,
});

const wsLink = new GraphQLWsLink(createClient({
  url: wsUrl,
  // url: 'ws://localhost:3300/graphql',
  connectionParams:{
  ...(() =>{
    const token = localStorage.getItem("credentials");
    return {
    ...(token && { authorization: `Bearer ${token}` }),
    }
  })()
  }
}));

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem("credentials");
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      ...(token && { authorization: `Bearer ${token}` }),
    },
  };
});

const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
          definition.kind === 'OperationDefinition' &&
          definition.operation === 'subscription'
      );
    },
    wsLink,
    authLink.concat(httpLink),
);

export const APIClient = new ApolloClient({
  link: splitLink,
  cache: new InMemoryCache(),
});

// For no cache
const defaultOptions = {
  watchQuery: {
    fetchPolicy: "no-cache",
    errorPolicy: "ignore",
  },
  query: {
    fetchPolicy: "no-cache",
    errorPolicy: "all",
  },
};

export const APIClientNoCache = new ApolloClient({
  link: splitLink,
  cache: new InMemoryCache(),
  defaultOptions: defaultOptions,
});
