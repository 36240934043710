import React from "react";
import {
	Page,
	Text,
	View,
	Document,
	StyleSheet,
	Image,
	Font,
} from "@react-pdf/renderer";

import shipter from "../../Common/assets/Images/shipterLogoHi.jpg";
import PackageTable from "./PackageTable";

export const PrintLabel = (props) => {
	const { barcode, selectedRequests } = props;
	let { tableData, ...item1 } = selectedRequests;
	// console.log(props);

	const calculateTotal = (products) => {
		let total = 0;
		products.forEach((item) => {
			let product = parseInt(item.quantity) * parseInt(item.unitPrice);

			total = total + product;
		});

		return total;
	};

	return (
		<>
			{tableData && <PackageTable tableData={tableData} />}
			<Page size="A4" style={styles.page}>
				<View style={styles.sec1}>
					<View style={styles.sec11}>
						<Image src={shipter} style={styles.image} />
					</View>
					<View style={styles.sec12}>
						<Text>{item1?.shippingCountry?.code}</Text>
					</View>
				</View>
				<View style={styles.sec2}>
					<Text>{item1?.shippingType?.shippingType}</Text>
				</View>

				<View style={styles.sec3}>
					<Text>FROM:</Text>
					<Text style={styles.red}>{item1?.senderInformation?.name}</Text>
					<Text style={styles.red}>{item1?.senderInformation?.address}</Text>
					<Text style={styles.red}>
						{item1?.senderInformation?.addressLine2}
					</Text>

					<Text style={styles.red}>
						{item1?.shippingCountry.country_name_en}
					</Text>
					<Text style={styles.red}>
						{item1?.senderInformation?.phoneNumber}
					</Text>
				</View>
				<View style={styles.sec4}>
					<Text>TO:</Text>
					<Text style={styles.red}>{item1?.payeeInformation?.name}</Text>
					<Text style={styles.red}>
						{item1?.payeeInformation?.streetAddress1}
					</Text>
					<Text style={styles.red}>
						{item1?.payeeInformation?.streetAddress2}
					</Text>
					<View style={styles.sec3a}>
						<Text style={styles.red}>{item1?.payeeInformation?.zipCode},</Text>
						<Text style={styles.red}>{item1?.payeeInformation?.states},</Text>
						<Text style={styles.red}>{item1?.payeeInformation?.cities},</Text>
						<Text style={styles.red}>
							{item1?.payeeInformation?.mexicoColony},
						</Text>
					</View>
					<Text style={styles.red}>
						{item1?.payeeInformation?.shippingCountry?.country_name_en}
					</Text>
					<Text style={styles.red}>
						{item1?.payeeInformation?.phoneNumber1}
					</Text>
				</View>
				<View style={styles.sec5a}>
					<View style={styles.sec5}>
						<View style={styles.sec51}>
							<View></View>
							<Text>
								Order No:
								<Text style={styles.red}>
									{item1.productInformation?.orderNo1}
								</Text>
							</Text>
							<View style={styles.colItem}>
								{item1.productInformation?.productInformation.map(
									(item2, index) => {
										return (
											<View style={styles.sec51a}>
												<Text style={styles.bold}>item {index + 1}:</Text>
												<Text style={styles.red}>
													{item2.productName.length > 25
														? item2.productName.slice(0, 25).concat("...")
														: item2.productName}
													/{item2.quantity}pc /{item2.unitPrice}
													{item1?.productInformation?.currency}
												</Text>
											</View>
										);
									}
								)}
							</View>
						</View>
						<View style={styles.sec52}>
							<Text>
								Shipter No:{" "}
								<Text style={styles.red}>{item1.trackingNumber}</Text>
							</Text>
						</View>
					</View>

					<View style={styles.listItem}>
						<View style={styles.list}>
							<Text style={styles.listItemLabel}>Pcs:</Text>
							<Text style={styles.listItemValue}>
								{item1?.productInformation?.productInformation?.reduce(
									(previousValue, currentValue) =>
										previousValue + currentValue.quantity,
									0
								)}
							</Text>
						</View>
						<View style={styles.list}>
							<Text style={styles.listItemLabel}>Value:</Text>
							<Text style={styles.listItemValue}>
								{calculateTotal(item1.productInformation?.productInformation)}
							</Text>
							<Text style={styles.listItemValue}>
								{item1?.productInformation?.currency}
							</Text>
						</View>
					</View>
				</View>

				<View style={styles.sec6}>
					<View style={styles.sec6a}>
						<Image src={barcode} width={"100%"} height={50} />
						{/* <Text style={styles.red}>{item1.trackingNumber}</Text> */}
					</View>
				</View>
			</Page>
			{/* });
			})} */}
		</>
	);
};

const fRow = {
	diaplay: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
};
const fCol = {
	diaplay: "flex",
	flexDirection: "column",
	alignItems: "center",
	justifyContent: "center",
};

Font.register({
	family: "SpoqaHanSans",
	src: "https://cdn.jsdelivr.net/gh/spoqa/spoqa-han-sans@01ff0283e4f36e159ffbf744b36e16ef742da6d8/Subset/SpoqaHanSans/SpoqaHanSansLight.ttf",
});
const styles = StyleSheet.create({
	page: {
		border: "2px solid #000",
		fontFamily: "SpoqaHanSans",
	},
	sec1: {
		display: "flex",
		flexDirection: "row",
		width: "100%",
		color: "#fff",
	},
	sec11: {
		...fCol,
		backgroundColor: "white",
		padding: 15,
		color: "#fff",
		width: "35%",
		border: "2px solid #000",
	},
	sec12: {
		...fCol,
		backgroundColor: "white",
		width: "65%",
		padding: 15,
		color: "black",
		fontSize: 30,
		fontWeight: 700,
		border: "2px solid #000",
	},
	red: {
		color: "black",
		fontSize: 16,
	},
	bold: {
		color: "black",
		fontSize: 18,
		fontWeight: "bold",
	},
	col: {
		...fCol,
	},
	colItem: {
		...fCol,
		alignItems: "flex-start",
	},
	sec2: {
		...fRow,
		color: "black",
		fontSize: 30,
		fontWeight: 700,
		padding: 10,
		alignItems: "center",
		justifyContent: "center",
		border: "2px solid #000",
	},
	sec3: {
		...fCol,
		alignItems: "flex-start",
		justifyContent: "flex-start",
		minHeight: 150,
		paddingLeft: 15,
		paddingRight: 15,
		border: "2px solid #000",
	},
	sec3a: {
		...fRow,
	},
	sec4: {
		...fCol,
		minHeight: 170,
		alignItems: "flex-start",
		justifyContent: "flex-start",
		paddingLeft: 15,
		paddingRight: 15,
		border: "2px solid #000",
	},
	sec5: {
		...fRow,
		width: "100%",
	},
	sec6: {
		...fRow,
		width: "100%",
		paddingLeft: 15,
		paddingRight: 15,
		border: "2px solid #000",
	},
	sec6a: {
		...fCol,
		width: "100%",
		paddingLeft: 15,
		paddingRight: 15,
	},
	sec5a: {
		...fCol,
		justifyContent: "flex-start",
		alignItems: "flex-start",
		minHeight: 200,
		border: "2px solid #000",
	},
	sec51: {
		...fCol,
		alignItems: "flex-start",
		justifyContent: "flex-start",
		width: "50%",
		height: 180,
		paddingLeft: 15,
		paddingRight: 15,
	},
	sec51a: {
		...fRow,
		marginTop: 10,
		marginBottom: 10,
		justifyContent: "flex-start",
	},
	sec52: {
		...fCol,
		minHeight: 180,
		width: "50%",
		justifyContent: "flex-start",
		alignItems: "flex-start",

		paddingLeft: 15,
		paddingRight: 15,
	},

	listItem: {
		...fRow,
		width: "100%",
		fontSize: 16,
		marginBottom: 10,
		gap: "20px",
		justifyContent: "flex-end",
		padding: "0 10px",
	},
	list: {
		...fRow,
		marginRight: 10,
		fontSize: 16,
		alignItems: "center",
	},
	listItemLabel: {
		color: "black",
		fontSize: 18,
		fontWeight: "bold",
	},
	listItemValue: {
		color: "black",
		fontSize: 16,
	},
	line: {
		height: 1,
		backgroundColor: "black",
		marginBottom: 2,
	},
	body: {
		padding: 10,
	},
	row: {
		...fRow,
	},
	center: {
		...fCol,
		marginTop: 25,
	},
	image: {
		maxWidth: 140,
		height: 120,
	},
});

export const PrintImage = (src) => {
	return (
		<Document>
			<Page size="A4" style={styles.page}>
				<view>
					<Image src={src} />
				</view>
			</Page>
		</Document>
	);
};
