import React, { useState } from "react";
import { TextFieldBox } from "../../../../components/CustomInput";
import Button from "@mui/material/Button";
import { Typography } from "@mui/material";
import styled from "styled-components";
import { updateUserGroup } from "../queries";

function EditTitle({ data }) {
  const [title, setTitle] = useState(data.name);
  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(false);

  //Save to api
  const saveName = () => {
    setLoading(true);
    let pack = {
      name: title,
      id: data.id,
    };
    updateUserGroup(pack).then(
      (res) => {
        setLoading(false);
      },
      (err) => {
        setLoading(false);
        console.log(err);
      }
    );
  };

  return (
    <div style={{ display: "flex", cursor: "pointer" }}>
      {editMode ? (
        <StyledTextField>
          <TextFieldBox
            type="text"
            value={title}
            onChange={(event) => setTitle(event.target.value)}
          />
          {loading ? (
            "Updating..."
          ) : (
            <Button
              onClick={() => {
                setEditMode(false);
                saveName();
              }}
            >
              Submit
            </Button>
          )}
        </StyledTextField>
      ) : (
        <Typography
          style={{ fontSize: "16px", fontWeight: "bold" }}
          variant="h6"
        >
          {title}
        </Typography>
      )}
      {!editMode && (
        <img
          style={{ paddingLeft: "10px" }}
          src="/assets/images/Edit.svg"
          alt="img"
          onClick={() => setEditMode(true)}
        ></img>
      )}
    </div>
  );
}

export default EditTitle;

const StyledTextField = styled.div`
  display: flex;
`;
