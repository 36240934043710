import {
	Box,
	Divider,
	Modal,
	Stack,
	TextareaAutosize,
	Typography,
} from "@mui/material";
import React from "react";
import CustomButton from "../components/CustomButton";
import CustomCard from "../components/CustomCard";
import Icons from "../utils/icon";

export default function RequestModal({
	isOpen,
	onClose,
	onConfirm,
	isLoading = false,
	title = "",
	description = "",
	buttonText = "",
	setRequestDescription,
	warningIcon,
}) {
	return (
		<Modal
			open={isOpen}
			onClose={onClose}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Box
				style={{
					padding: 20,
					overflowX: "hidden",
					minHeight: "100vh",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				<CustomCard>
					<Stack spacing={"20px"} width={450} p="20px">
						<Stack direction="row" alignItems={"center"} spacing="10px">
							<div>{!warningIcon ? "" : <Icons.Warning />}</div>

							<Typography fontSize={"20px"} fontWeight="700">
								{/* {title ? title : '고객 문의사항'} */}
								{title}
							</Typography>
						</Stack>

						<div>
							<Typography
								// lineHeight={"0.5"}
								fontSize={"14px"}
								color="rgba(0,0,0,0.6)"
								component={"div"}
							>
								{description
									? description
									: `고객의 문의사항이 해당 팝업에 표시될 예정입니다. 고객의
                문의사항이 해당 팝업에 표시될 예정입니다. 고객의 문의사항이 해당
                팝업에 표시될 예정입니다. 고객의 문의사항이 해당 팝업에 표시될
                예정입니다. 고객의 문의사항이 해당 팝업에 표시될 예정입니다.
                고객의 문의사항이 해당 팝업에 표시될 예정입니다. 고객의
                문의사항이 해당 팝업에 표시될 예정입니다. 고객의 문의사항이 해당
                팝업에 표시될 예정입니다.`}
							</Typography>
						</div>

						<TextareaAutosize
							placeholder="메모 및 문의사항을 작성해주세요."
							minRows={10}
							onChange={(e) => setRequestDescription(e.target.value)}
						/>

						<Divider />

						<div
							style={{
								display: "flex",
								justifyContent: "flex-end",
								gap: "20px",
							}}
						>
							<CustomButton
								onClick={onClose}
								styles={{
									background: "#F8F8FA",
									color: "#000",
									width: "auto",
								}}
							>
								{buttonText ? buttonText : "취소"}
							</CustomButton>
							<CustomButton
								disabled={isLoading}
								styles={{ width: "auto" }}
								onClick={onConfirm}
							>
								요청
							</CustomButton>
						</div>
					</Stack>
				</CustomCard>
			</Box>
		</Modal>
	);
}
