import * as React from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import CustomButton from "../../../components/CustomButton";
import Typography from "@mui/material/Typography";
import { Grid, Divider } from "@mui/material";
import Modal from "@mui/material/Modal";
import { TextFieldBox } from "../../../components/CustomInput";
import { createUserGroup } from "./queries";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 420,
  bgcolor: "background.paper",
  boxShadow: "0px 0px 5px rgba(31, 65, 115, 0.1)",
  borderRadius: "10px",
  textAlign: "center",
  p: 4,
};

export function CreateGroup({ open, handleClose, newGroup }) {
  const [groupname, setGroupname] = useState("");
  const [loading, setLoading] = useState(false);

  //Saving to backend
  const saveGroup = () => {
    setLoading(true);
    let pack = {
      name: groupname,
    };
    createUserGroup(pack).then(
      (res) => {
        setLoading(false);
        newGroup(res.data.createUserGroup);
      },
      (err) => {
        setLoading(false);
        console.log(err);
      }
    );
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            sx={{ mb: 2 }}
            style={{
              textAlign: "center",
              fontWeight: "bold",
              fontSize: "20px",
            }}
          >
            새 그룹 생성하기
          </Typography>
          <TextFieldBox
            type="text"
            placeholder="새 그룹명을 입력하세요"
            value={groupname}
            onChange={(e) => setGroupname(e.target.value)}
          />
          <Divider sx={{ mt: 8 }} />
          <Grid container sx={{ justifyContent: "right" }}>
            <CustomButton
              width="100px"
              onClick={handleClose}
              style={{
                marginTop: "30px",
                borderRadius: "10px",
                backgroundColor: "#F8F8FA",
                color: "#000000",
              }}
            >
              취소
            </CustomButton>
            <CustomButton
              width="100px"
              style={{
                marginTop: "30px",
                borderRadius: "10px",
                marginLeft: "10px",
                backgroundColor: "#5B8FF7",
              }}
              onClick={saveGroup}
              disabled={loading}
            >
              {loading ? "Adding..." : "추가"}
            </CustomButton>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}
