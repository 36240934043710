import * as React from "react";
import moment from "moment";
// import currency from 'currency-converter-module'


export default function NLPostShippingRequestTable({ data }) {

  const Counting = ({bags, index, i}) => {
    let count = 0;

    if(index !== 0) {
      for(let i=index-1; i >= 0; i--) {
        let bag = bags[i];
        count = count + bag.receptionLists.length;
      }
    }

    count = count + (i+1);

    return count;
  }

  const HSCodes = () => {
    console.log("DATAAA",data);
    let currentHsCode = "";
    let hsCodes = [];
    let HscodeData = [];
    const bags = data?.bags;
    let tempObj = {};

    {bags?.forEach((bag) => {

        const receptionLists = bag?.receptionLists;
        
        receptionLists?.forEach((receptionList) => {     
            const productInfos = receptionList?.productInformation?.productInformation;

            productInfos?.forEach((productInfo) => {
                if((hsCodes.indexOf(productInfo.hscode) === -1) || currentHsCode === productInfo.hscode) {
           
                    if(currentHsCode !== productInfo.hscode) {
                        currentHsCode = productInfo.hscode;
                        hsCodes.push(currentHsCode);
                        HscodeData.push(tempObj);
                        tempObj = {};
                        tempObj.hscode = productInfo.hscode;
                        tempObj.totalValue = productInfo.unitPrice * productInfo.quantity;
                        tempObj.totalLine = 1;
                        tempObj.totalWeight = bag.weight;
                    }
                    else if(currentHsCode === productInfo.hscode) {
                        tempObj.totalValue = tempObj.totalValue + (productInfo.unitPrice * productInfo.quantity);
                        tempObj.totalLine = tempObj.totalLine + 1;
                    }      
                }
            })
            hsCodes.push(currentHsCode);
            HscodeData.push(tempObj);
        })
    })}

    console.log("hs codes", HscodeData);

    return(
        <>  
            {
                HscodeData?.map((dat) => (
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>{dat.hscode}</td>
                        <td>{dat.totalValue}</td>
                        <td>{dat.totalLine}</td>
                        <td>{dat.totalWeight}</td>
                    </tr>
                ))
            }
            
        </>
    );   
}

const ManifestTable = () => {
    let dataRow = [];
    
    const bags = data?.bags;
    let creationDate = (data?.createdAt?.split("T"))[0];
    const creationdate = creationDate.split("-")[2];
    const creationmonth = creationDate.split("-")[1];
    const creationyear = creationDate.split("-")[0];
    const combi = "HNKCOMBI"+creationdate+creationmonth+creationyear;


    {bags?.forEach((bag) => {

        const receptionLists = bag?.receptionLists;
        
        receptionLists?.forEach((receptionList) => {
            let dataCol = {};
            dataCol.delivery = (combi);
            dataCol.reference = (receptionList?.productInformation?.orderNo1);
            dataCol.aName = (receptionList?.payeeInformation?.name);
            dataCol.aAddress = (receptionList?.payeeInformation?.streetAddress1+","+receptionList?.payeeInformation?.streetAddress2);
            dataCol.aZip = (receptionList?.payeeInformation?.zipCode);
            dataCol.aCity = (receptionList?.payeeInformation?.cities);
            dataCol.aState = (receptionList?.payeeInformation?.states);
            dataCol.aCountry = (receptionList?.payeeInformation?.shippingCountry?.country_name_en);
            dataCol.aCountryCode = (receptionList?.payeeInformation?.shippingCountry?.code);
            dataCol.weight = (bag?.usedWeight)*1000;
            dataCol.totalPrice = (receptionList?.productInformation?.totalPriceAmount);
            
            // var convertedValue = await currency.convertCurrencyByCode({value: receptionList?.productInformation?.totalPriceAmount, code: 'USD'},{code: 'EUR'})
            // dataCol.totalPriceEur = (convertedValue);
            dataCol.tariff = (receptionList?.productInformation?.productInformation[0]?.hscode);
            dataCol.productName = (receptionList?.productInformation?.productInformation[0]?.productName);
            dataCol.qty = (receptionList?.productInformation?.productInformation[0]?.quantity);
            dataCol.shippingNumber = (receptionList?.shippingNumber);
            dataCol.return = ("Y");
            dataCol.bagNumber = (bag.bagNumber);
            dataCol.shippingNumber = (receptionList?.shippingNumber);
            dataCol.dGoods = "N";
            dataCol.product = "MBX";
            dataCol.aPhone = (receptionList?.payeeInformation?.phoneNumber1);
            dataCol.email = (receptionList?.payeeInformation?.email);
            dataCol.format = "E";
            dataCol.rType = "BG";
            dataCol.idRef = (receptionList?.payeeInformation?.ssn);
            dataCol.countryOfManufacture = "KR";
            dataCol.itemUrl = (receptionList?.productInformation?.productInformation[0]?.purchaseURL);
            dataCol.declarationType = "SalesOfGoods";
            dataCol.totalWeight = ((bag?.weight)*1000);
            dataCol.currency = (receptionList?.productInformation?.currency);
            dataCol.transportValue = "";
            dataCol.insuranceValue = "";
            dataCol.senderCompany = receptionList?.user?.company_name_kr;
            dataCol.senderName = receptionList?.user?.customer_name;
            dataCol.senderAddress = receptionList?.user?.address_1+", "+receptionList?.user?.address_2;
            dataCol.senderZip = receptionList?.user?.english_address_zip_code;
            dataCol.senderCity = receptionList?.user?.english_address_city;
            dataCol.senderState = receptionList?.user?.english_address_state;
            dataCol.senderCountry = bag?.shippingCountry?.name;
            dataCol.senderCountryCode = bag?.shippingCountry?.code;
            dataCol.senderEmail = receptionList?.user?.email;
            dataCol.senderPhone = receptionList?.user?.phone_number;
            dataCol.senderRefId = receptionList?.user?.id;
            dataCol.groupItems = "";
            dataCol.AdditionalBarcode = "";


            dataRow.push(dataCol);
        })
    })}

    console.log("dataRow",dataRow);

    return(
        <>
        {
            dataRow.map((row1, i) => (
                <tr >
                    <td>{row1.delivery}</td>
                    <td>{row1.reference}</td>
                    <td>{row1.aName}</td>
                    <td>{row1.aAddress}</td>
                    <td>{row1.aZip}</td>
                    <td>{row1.aCity}</td>
                    <td>{row1.aState}</td>
                    <td>{row1.aCountry}</td>
                    <td>{row1.aCountryCode}</td>
                    <td>{row1.weight}</td>
                    <td>{row1.totalPrice}</td>
                    <td></td> {/* Convert to Euro */}
                    <td>{row1.tariff}</td>
                    <td>{row1.productName}</td>
                    <td>{row1.qty}</td>
                    <td>{row1.shippingNumber}</td>
                    <td>{row1.return}</td>
                    <td>{", "+row1.bagNumber}</td>
                    <td>{row1.shippingNumber}</td>
                    <td>{row1.dGoods}</td>
                    <td>{row1.product}</td>
                    <td>{row1.aPhone}</td>
                    <td>{row1.email}</td>
                    <td>{row1.format}</td>
                    <td>{row1.rType}</td>
                    <td>{row1.idRef}</td>
                    <td>{row1.countryOfManufacture}</td>
                    <td>{row1.itemUrl}</td>
                    <td>{row1.declarationType}</td>
                    <td>{row1.totalWeight}</td>
                    <td>{row1.currency}</td>
                    <td>{row1.transportValue}</td>
                    <td>{row1.insuranceValue}</td>
                    <td>{row1.senderCompany}</td>
                    <td>{row1.senderName}</td>
                    <td>{row1.senderAddress}</td>
                    <td>{row1.senderZip}</td>
                    <td>{row1.senderCity}</td>
                    <td>{row1.senderState}</td>
                    <td>{row1.senderCountry}</td>
                    <td>{row1.senderCountryCode}</td>
                    <td>{row1.senderEmail}</td>
                    <td>{row1.senderPhone}</td>
                    <td>{row1.senderRefId}</td>
                    <td>{row1.groupItems}</td>
                    <td>{row1.AdditionalBarcode}</td>
                    <td>yo</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
            ))
        }

        </>
    )

}

  return (
    <table>
        <tr>
            <td colSpan="10" id="sjs-A1">
                Manifest
            </td>
        </tr>  
        <tr>
            <td colSpan="10" id="sjs-A2">
                FOR CUSTOM PURPOSE ONLY
            </td>
        </tr>

        <tr>
            <td>MANIFEST</td>
        </tr>
        <tr>
            <td>All Countries</td>
        </tr>

        <tr></tr>

        <tr>
            <td>To:</td>
            <td>Several Individual Private Addresses</td>
            <td></td>
            <td></td>
            <td>From:</td>
            <td>H3 networks co., ltd.</td>
        </tr>

        <tr>
            <td></td>
            <td>c/o as distributor: PostNL Internation Mail Unit</td>
            <td></td>
            <td></td>
            <td></td>
            <td>#B103, Hwagok-ro 416 The Sky Valley 5th</td>
        </tr>

        <tr>
            <td></td>
            <td>PostNL International Mail Unit</td>
            <td></td>
            <td></td>
            <td></td>
            <td>Gangseo-gu, Seoul . 07548</td>
        </tr>

        <tr>
            <td></td>
            <td>Menzies Aviation Cargo AMS</td>
            <td></td>
            <td></td>
            <td></td>
            <td>Republic of Korea</td>
        </tr>

        <tr>
            <td></td>
            <td>Anchoragelaan 50/ Dock 15</td>
        </tr>

        <tr>
            <td></td>
            <td>1118 LE Schiphol</td>
        </tr>

        <tr>
            <td></td>
            <td>The Netherlands</td>
        </tr>

        <tr></tr>
        <tr></tr>
        <tr>
            <td>Totals</td>
            <td></td>
            <td></td>
            <td></td>
            <td>No. of Despatches</td>
            <td>Wgt (kg)</td>
            <td>Curr</td>
            <td>Value (EUR)</td>
        </tr>

        <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>{data?.bags[0]?.receptionLists[0]?.productInformation?.currency}</td>
        </tr>

        <tr></tr>
        <tr></tr>
        <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>Tariff</td>
            <td>Total value per HS code</td>
            <td>Total line per HS code</td>
            <td>Total wght (g) per HS code</td>
        </tr>
        <HSCodes/>

        <tr></tr>
        <tr></tr>
        <tr></tr>
        <tr></tr>
        <tr></tr>
        <tr></tr>
        <tr></tr>
        <tr></tr>
        <tr></tr>
        <tr></tr>
        <tr></tr>
        <tr></tr>

        <tr>
            <td>Delivery</td>
            <td>Customer Reference</td>
            <td>Addressee Name</td>
            <td>Addressee Address</td>
            <td>Addressee Postal Code</td>
            <td>Addressee City</td>
            <td>Addressee State</td>
            <td>Addressee Country</td>
            <td>Addressee Country code</td>
            <td>Gross wght(g)</td>
            <td>Total goods value</td>
            <td>Total goods value(EUR)</td>
            <td>Tariff</td>
            <td>Goods Description</td>
            <td>Qty</td>
            <td>Item barcode</td>
            <td>Return</td>
            <td>PostNL mailbagID</td>
            <td>Customer Item Id</td>
            <td>Dangerous Goods</td>
            <td>Product</td>
            <td>Addressee Phone</td>
            <td>Addressee Email</td>
            <td>Format</td>
            <td>Receptacle Type</td>
            <td>Addressee ID Ref</td>
            <td>Country of Manufacture</td>
            <td>Item Url</td>
            <td>Declaration Type</td>
            <td>Total Weight (g)</td>
            <td>Currency</td>
            <td>Transport Value</td>
            <td>Insurance value</td>
            <td>Sender Company</td>
            <td>Sender Name</td>
            <td>Sender Address</td>
            <td>Sender Postal Code</td>
            <td>Sender city</td>
            <td>Sender state</td>
            <td>Sender country</td>
            <td>Sender country code</td>
            <td>Sender email</td>
            <td>Sender phone</td>
            <td>Sender Id ref</td>
            <td>Group Items</td>
            <td>Additional barcode</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
        </tr>
        {/* <tr>
            <td>TEST</td>
        </tr> */}
        <ManifestTable/>
   
    </table>
  );
}
