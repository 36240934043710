import { adminLinks, clientLinks } from "./links";
import writeXlsxFile from "write-excel-file";
import { useDataProvider } from "react-admin";
import Moment from "moment";
import Toaster from "./Toaster";
import { useEffect, useState } from "react";
import * as PDFJS from "pdfjs-dist/webpack";

import {
  REMOTE_FILES_BASE_URL,
  graphqlUrl,
  TRACK_SHIPMENT,
  PACKK_LABEL,
  UBI_REQUEST_SHIPPING_LABEL,
  POSTPLUS_REQUEST_SHIPPING_LABEL,
} from "./constants";
import { APIClient } from "./apiGraphql";
import { ConstructionOutlined } from "@mui/icons-material";
import moment from "moment";

// const PdfjsWorker = require("worker-loader!./build/pdf.worker.js");
export const CREDENTIALS_LOCAL_STORAGE_ITEM = "credentials";

export const USER_DATA_LOCAL_STORAGE_ITEM = "userData";

export const url = typeof window && window.location.hash;

// role of dashboard
export const role = "client";

export const MenuLinks = role === "client" ? clientLinks : adminLinks;

export const countriesName = [
  "AD( 안도라 )",
  "AE( 아랍에미리트 )",
  "AF( 아프가니스탄 )",
];

export const monetaryUnit = ["EUR", "AED", "AFN"];

export const useUtils = (props) => {
  const data = localStorage.getItem("userData");

  let login;
  if (data) {
    const { login: loginData } = JSON.parse(data);
    login = loginData;
  }

  return {
    graphqlUrl,
    CREDENTIALS_LOCAL_STORAGE_ITEM,
    USER_DATA_LOCAL_STORAGE_ITEM,
    url,
    role: login?.role,
    userData: login,
    MenuLinks: login?.role !== "admin" ? clientLinks : adminLinks,
  };
};

export const handleChange = ({ target }, setFieldValue, isId = false) => {
  const { value, name } = target;
  setFieldValue(name, isId ? +value : value);
};

export const handleOtherSearch = (values = {}, setFilterQuery) => {
  // console.log("values", values);
  setFilterQuery((preValue) => {
    if (preValue) {
      return { ...preValue, ...values };
    } else {
      return { ...values };
    }
  });
};

export const useGetList = (props) => {
  const dataProvider = useDataProvider();
  const [isLoading, setIsLoading] = useState(false);

  const getData = (resource, params = {}) => {
    return new Promise((resolve, reject) => {
      setIsLoading(true);
      dataProvider
        .getList(resource, params)
        .then(({ data }) => {
          setIsLoading(false);
          resolve(data);
        })
        .catch((error) => {
          setIsLoading(false);
          reject(error);
        });
    });
  };

  return {
    getData,
    isLoading,
  };
};

export const useGeneralData = (props) => {
  const dataProvider = useDataProvider();
  const [isLoading, setIsLoading] = useState(false);

  const getData = (resource) => {
    return new Promise((resolve, reject) => {
      setIsLoading(true);
      dataProvider
        .getDepositHistory(resource, {})
        .then(({ data }) => {
          setIsLoading(false);
          resolve(data);
        })
        .catch((error) => {
          setIsLoading(false);
          reject(error);
        });
    });
  };

  return {
    getData,
    isLoading,
  };
};

export const useGetById = (props) => {
  const dataProvider = useDataProvider();
  const [isLoading, setIsLoading] = useState(false);

  const getData = (resource, id) => {
    return new Promise((resolve, reject) => {
      setIsLoading(true);
      dataProvider
        .getOne(resource, { id })
        .then(({ data }) => {
          setIsLoading(false);
          resolve(data);
        })
        .catch((error) => {
          setIsLoading(false);
          reject(error);
        });
    });
  };

  return {
    getData,
    isLoading,
  };
};

export const useUpdate = (props) => {
  const dataProvider = useDataProvider();
  const [isLoading, setIsLoading] = useState(false);
  const update = (resource, data, setData, handleClose, id) => {
    setIsLoading(true);
    dataProvider
      .update(resource, {
        data: {
          updateInput: {
            ...data,
          },
        },
      })
      .then(({ data: responseData }) => {
        setIsLoading(false);
        setData((prevState) => {
          const tempData = [...prevState];
          const index = tempData.findIndex((res) => res.id === responseData.id);
          if (index > -1) {
            tempData[index] = responseData;
          }
          return tempData;
        });
        Toaster("success", "업데이트가 완료되었습니다.");
        handleClose();
      })
      .catch((err) => {
        setIsLoading(false);
        Toaster("error", err.message);
        handleClose();
      });
  };

  return {
    update,
    isLoading,
  };
};

export const useUpdateBagName = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const updateName = ({
    id,
    bagName,
    updateNameMutation,
    setData,
    responseKey,
    onSuccess = () => {
      console.log("i am here");
    },
  }) => {
    if (!(updateNameMutation || props.mutation)) {
      return Toaster("error", "에러가 발생했습니다");
    }
    setIsLoading(true);
    const UPDATE_MUTATION = updateNameMutation || props.mutation;
    APIClient.mutate({
      mutation: UPDATE_MUTATION,
      variables: {
        input: {
          id: id[0],
          name: bagName,
        },
      },
    })
      .then(({ data }) => {
        onSuccess();
        Toaster("success", "BAG 이름이 성공적으로 변경되었습니다.");
      })
      .catch((err) => {
        console.log(err);
        Toaster("error", "에러가 발생했습니다");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  return {
    updateName,
    isLoading,
  };
};

export const useMoveBags = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const updateBagsInMaster = ({
    id,
    values,
    updateBagsMutation,
    setData,
    responseKey,
    onSuccess = () => {
      console.log("i am here");
    },
  }) => {
    if (!(updateBagsMutation || props.mutation)) {
      return Toaster("error", "에러가 발생했습니다");
    }
    setIsLoading(true);
    const UPDATE_MUTATION = updateBagsMutation || props.mutation;
    APIClient.mutate({
      mutation: UPDATE_MUTATION,
      variables: {
        input: {
          bagIds: id,
          ...values,
        },
      },
    })
      .then(({ data }) => {
        onSuccess();
        Toaster("success", "마스터 생성 및 패키징이 완료되었습니다.");
      })
      .catch((err) => {
        console.log(err);
        Toaster("error", "에러가 발생했습니다");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  return {
    updateBagsInMaster,
    isLoading,
  };
};

export const useSetRequestShippingOnHold = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const holdMany = ({
    ids = [],
    holdMutation,
    setData,
    responseKey,
    onSuccess = () => {},
  }) => {
    if (!(holdMutation || props.mutation)) {
      return Toaster("error", "에러가 발생했습니다");
    }
    setIsLoading(true);
    const HOLD_MUTATION = holdMutation || props.mutation;
    APIClient.mutate({
      mutation: HOLD_MUTATION,
      variables: {
        ids: {
          ids,
        },
      },
    })
      .then(({ data }) => {
        const ids = data[responseKey];
        setData((prevState) => {
          const temp = [...prevState];
          return temp.filter((res) => {
            return ids.findIndex((id) => id.id === res.id) === -1;
            // const index = ids.findIndex((res) =>
            // {
            //   // res.id === ids.id}
            //   console.log(res.id)
            //   console.log(res)
            //   console.log(ids)
            //   console.log(ids.id)
            // }
            // );
            // if (index > -1) {
            //   // tempData[index] = responseData;
            //   tempData.splice(index, 1);
            // }
            // return tempData;
          });
        });

        onSuccess();
        Toaster("success", "선택하신 접수건이 보류 처리되었습니다.");
      })
      .catch((err) => {
        Toaster("error", "에러가 발생했습니다");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  return {
    holdMany,
    isLoading,
  };
};

export const useMovemany = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const moveMany = ({
    ids = [],
    bagId = "",
    moveMutation,
    setData,
    responseKey,
    onSuccess = () => {
      console.log("i am here");
    },
  }) => {
    if (!(moveMutation || props.mutation)) {
      return Toaster("error", "에러가 발생했습니다");
    }
    setIsLoading(true);
    const MOVE_MUTATION = moveMutation || props.mutation;
    APIClient.mutate({
      mutation: MOVE_MUTATION,
      variables: {
        input: {
          requests: ids,
          bagId,
        },
      },
    })
      .then(({ data }) => {
        const { ids } = data[responseKey];
        setData((prevState) => {
          // debugger;
          const temp = [...prevState.data];
          return temp.filter((res) => {
            return ids.findIndex((id) => id === res.id) === -1;
          });
        });
        onSuccess();
        Toaster("success", "선택하신 접수건을 BAG 으로 이동하였습니다.");
        // setTimeout(()=>{
        // 	window.location.reload()
        // },[300])
      })
      .catch((err) => {
        console.log(err);
        Toaster(
          "error",
          "선택하신 배송접수건의 배송 타입이 이동을 원하시는 BAG의 배송타입과 일치하지 않습니다.",
          { autoClose: false }
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  return {
    moveMany,
    isLoading,
  };
};

export const useDeleteMany = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const deleteMany = ({
    ids = [],
    deleteMutation,
    setData,
    responseKey,
    onSuccess = () => {},
  }) => {
    if (!(deleteMutation || props.mutation)) {
      return Toaster("error", "에러가 발생했습니다");
    }
    setIsLoading(true);
    const DELETE_MUTATION = deleteMutation || props.mutation;
    APIClient.mutate({
      mutation: DELETE_MUTATION,
      variables: {
        input: {
          ids,
        },
      },
    })
      .then(({ data }) => {
        const { ids } = data[responseKey];
        // debugger;
        setData((prevState) => {
          const temp = [...prevState];
          return temp.filter((res) => {
            return ids.findIndex((id) => id === res.id) === -1;
          });
        });
        onSuccess();
        Toaster("success", "선택하신 항목이 삭제 완료되었습니다.");
      })
      .catch((err) => {
        Toaster("error", err.message);
        // "에러가 발생했습니다"
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  return {
    deleteMany,
    isLoading,
  };
};

export const useDeleteManyFromMaster = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const deleteMany = ({
    ids = [],
    deleteMutation,
    setData,
    responseKey,
    onSuccess = () => {},
  }) => {
    if (!(deleteMutation || props.mutation)) {
      return Toaster("error", "에러가 발생했습니다");
    }
    setIsLoading(true);
    const DELETE_MUTATION = deleteMutation || props.mutation;
    APIClient.mutate({
      mutation: DELETE_MUTATION,
      variables: {
        input: {
          ids,
        },
      },
    })
      .then(({ data }) => {
        const { ids } = data[responseKey];
        // debugger;
        setData((prevState) => {
          const temp = [...prevState];
          return temp.filter((res) => {
            return ids.findIndex((id) => id === res.id) === -1;
          });
        });
        onSuccess();
        Toaster("success", "선택하신 항목이 삭제 완료되었습니다.");
      })
      .catch((err) => {
        Toaster("error", err.message);
        // "에러가 발생했습니다"
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  return {
    deleteMany,
    isLoading,
  };
};

export const useDeleteManyWithIds = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const deleteMany = ({
    ids = [],
    deleteMutation,
    setData,
    responseKey,
    onSuccess = () => {},
  }) => {
    if (!(deleteMutation || props.mutation)) {
      return Toaster("error", "에러가 발생했습니다");
    }
    setIsLoading(true);
    const DELETE_MUTATION = deleteMutation || props.mutation;
    APIClient.mutate({
      mutation: DELETE_MUTATION,
      variables: {
        ids: {
          ids,
        },
      },
    })
      .then(({ data }) => {
        const ids = data[responseKey];
        // debugger;
        setData((prevState) => {
          const temp = [...prevState];
          return temp.filter((res) => {
            return ids.findIndex((id) => id === res.id) === -1;
          });
        });
        onSuccess();
        Toaster("success", "선택하신 항목이 삭제 완료되었습니다.");
      })
      .catch((err) => {
        Toaster("error", "에러가 발생했습니다", { autoClose: false });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  return {
    deleteMany,
    isLoading,
  };
};

export const useDeleteManyBagsWithIds = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const deleteMany = ({
    ids = [],
    deleteMutation,
    setData,
    responseKey,
    onSuccess = () => {},
  }) => {
    if (!(deleteMutation || props.mutation)) {
      return Toaster("error", "에러가 발생했습니다");
    }
    setIsLoading(true);
    const DELETE_MUTATION = deleteMutation || props.mutation;
    APIClient.mutate({
      mutation: DELETE_MUTATION,
      variables: {
        ids: {
          ids,
        },
      },
    })
      .then(({ data }) => {
        // const ids = data[responseKey];
        // // debugger;
        // setData((prevState) => {
        // 	const temp = [...prevState];
        // 	return temp.filter((res) => {
        // 		return ids.findIndex((id) => id === res.id) === -1;
        // 	});
        // });
        onSuccess();
        Toaster("success", "선택하신 접수건을 해당 BAG에서 삭제하였습니다.");
      })
      .catch((err) => {
        Toaster(
          "error",
          "선택하신 배송접수건은 이미 출고되어, 해당 접수건을 BAG에서 삭제할 수 없습니다.",
          { autoClose: false }
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  return {
    deleteMany,
    isLoading,
  };
};

export const useDelete = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const deleteOne = ({
    id = [],
    deleteMutation,
    setData,
    responseKey,
    onSuccess = () => {},
  }) => {
    if (!(deleteMutation || props.mutation)) {
      return Toaster("error", "에러가 발생했습니다");
    }
    setIsLoading(true);
    const DELETE_MUTATION = deleteMutation || props.mutation;
    APIClient.mutate({
      mutation: DELETE_MUTATION,
      variables: {
        input: {
          id,
        },
      },
    })
      .then(({ data }) => {
        const { id } = data[responseKey];
        setData((prevState) => {
          const tempData = [...prevState];
          const index = tempData.findIndex((res) => res.id === data.id);
          if (index > -1) {
            tempData[index] = data;
          }
          return tempData;
        });
        onSuccess();
        Toaster("success", "선택하신 항목이 삭제 완료되었습니다.");
      })
      .catch((err) => {
        Toaster("error", "에러가 발생했습니다");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  return {
    deleteOne,
    isLoading,
  };
};

export const generateSearchData = (
  handleSearch,
  filterQuery,
  additionalValues = {}
) => {
  // console.log({ additionalValues });
  // console.log({ filterQuery });
  if (filterQuery) {
    const query = { ...(additionalValues && additionalValues) };
    Object.keys(filterQuery).forEach((res) => {
      const value = filterQuery[res];
      if (value) {
        if (res === "createdAt" || res === "DepartureTime") {
          const [startDate, endDate] = value.split(" - ");
          query[res] = {
            gte: Moment(startDate).utc(true).toDate(),
            lte: Moment(endDate).utc(true).toDate(),
          };
        } else {
          query[res] = value;
        }
      }
    });
    // console.log("query", query);
    handleSearch(query);
  } else if (additionalValues) {
    handleSearch(additionalValues);
  }
};

export const renameResource = (name = "") => {
  const [firstPart, secondPart] = name.split("-");
  if (secondPart) {
    return madeThisUpperCase(firstPart) + madeThisUpperCase(secondPart);
  }
  return madeThisUpperCase(firstPart);
};

export const madeThisUpperCase = (value) => {
  return value[0].toUpperCase() + value.slice(1);
};

export const getQueryParams = (search) => {
  return new URLSearchParams(search);
};

export const getRemoteFileURL = (fileName) => {
  return `${REMOTE_FILES_BASE_URL}/${fileName}`;
};

export const exportJsonToXlsx = (data, fileName) => {
  // console.log({ data });
  const columns = Object.keys(data[0]).map((each) => ({
    value: each,
    fontWeight: "bold",
  }));
  const result = [];
  result.push(columns);
  data.forEach((row) => {
    result.push(
      Object.values(row).map((each) => ({
        value: each,
      }))
    );
  });
  writeXlsxFile(result, {
    fileName: `${fileName}.xlsx`,
  });
};

export const getWaybillBase64 = async (requestId, type) => {
  if (type === "UBI (API)") {
    console.log(requestId);
    const { data } = await APIClient.mutate({
      mutation: UBI_REQUEST_SHIPPING_LABEL,
      variables: {
        id: requestId,
      },
    });
    return data.ubiRequestShippingLabel.base64;
  } else if (["POSTPLUS (PRIME)", "POSTPLUS (EMS)"].includes(type)) {
    const { data } = await APIClient.mutate({
      mutation: POSTPLUS_REQUEST_SHIPPING_LABEL,
      variables: {
        id: requestId,
      },
    });
    return data.postplusRequestShippingLabel.base64;
  }
  return null;
};

export const getWaybillPack = async (requestId) => {
  const { data } = await APIClient.mutate({
    mutation: PACKK_LABEL,
    variables: {
      id: requestId,
    },
  });
  return data.paackRequestShippingLabel.base64;
};

export function blobToBase64(blob) {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
}

export const convertPdfToImages = async (data) => {
  // pdfjs.GlobalWorkerOptions.workerPort = new PdfjsWorker();
  const images = [];
  const pdf = await PDFJS.getDocument(data).promise;
  const canvas = document.createElement("canvas");
  for (let i = 0; i < pdf.numPages; i++) {
    const page = await pdf.getPage(i + 1);
    const viewport = page.getViewport({ scale: 1 });
    const context = canvas.getContext("2d");
    canvas.height = viewport.height;
    canvas.width = viewport.width;
    await page.render({ canvasContext: context, viewport: viewport }).promise;
    images.push(canvas.toDataURL());
  }
  canvas.remove();
  return images;
};

export const convertBase64ToUrl = (base64) => {
  return new Promise((resolve, reject) => {
    fetch(base64)
      .then((res) => res.blob())
      .then((blob) => {
        try {
          resolve(URL.createObjectURL(blob));
        } catch (e) {
          throw e;
        }
      })
      .catch((e) => reject(e));
  });
};

export const DeliveryParcel = (jsonData) => {
  const desiredData = {
    businessLno: jsonData[1].__EMPTY,
    companyName: jsonData[1].__EMPTY_4,
    representationName: jsonData[2].__EMPTY,
    companyAddress: jsonData[2].__EMPTY_4,
    inquiryPeriod: jsonData[3].__EMPTY,
    date: moment().format("YYYY-MM-DD"),
    serviceName: "",
    carrier: jsonData[6].__EMPTY,
    deliveryCountry: jsonData[6].__EMPTY_1,
    receptionNo: jsonData[6].__EMPTY_2,
    quantity: jsonData[6].__EMPTY_4,
    remarks: jsonData[6].__EMPTY_5,
    deliveryserviceName: "",
    deliverycarrier: jsonData[11].__EMPTY,
    deliverydepartCountry: jsonData[11].__EMPTY_1,
    deliverydestinationCountry: jsonData[11].__EMPTY_2,
    deliveryreceptionNo: jsonData[11].__EMPTY_4,
    deliveryquantity: jsonData[11].__EMPTY_6,
    deliveryremarks: jsonData[11].__EMPTY_7,
    deliveryPrice: jsonData[11].__EMPTY_8,
    totalDelivery: jsonData[7].__EMPTY_4,
    purpose: "",
    inquiryQuantity: jsonData[12].__EMPTY_6,
    inquiryPrice: jsonData[12].__EMPTY_7,
    companyName2: jsonData[14].__EMPTY,
    registrationName: jsonData[14].__EMPTY_2,
    representative: jsonData[14].__EMPTY_7,
    address: jsonData[15].__EMPTY,
    businessCondition: jsonData[15].__EMPTY_3,
    note: jsonData[16].__EMPTY,
  };
  return desiredData;
};

export const downloadFile = async ({ name, url: downloadUrl }) => {
  fetch(downloadUrl)
    .then((res) => res.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(blob);
      var link = document.createElement("a");
      link.href = url;
      link.download = name;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
};
