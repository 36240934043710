import { gql } from "@apollo/client/core";

const FIND_REQUEST_SHIPPING = gql`
  query findRequestShipping($term: String!) {
    findRequestShipping(term: $term) {
      id
      totalSKU
      bag {
        id
        name
        bagNumber
      }
      senderInformation {
        id
        name
        phoneNumber
        address
        requestShippingId
        deleted
        createdAt
        updatedAt
        __typename
      }
      shippingType {
        id
        shippingType
        apiType_ {
          id
          name
        }
      }
      payeeInformation {
        id
        name
        shippingCountryId
        shippingCountry {
          name
          __typename
        }
        phoneNumber1
        phoneNumber2
        zipCode
        ssn
        ukeori
        euioss
        states
        cities
        streetAddress1
        streetAddress2
        email
        requestShippingId
        deleted
        createdAt
        updatedAt
        __typename
      }
      trackingNumber
      weightValidation
      printWaybillAfterWeightChange
      sampoongAmountCheck
      treatmentAfterWeightChange
      status
      weightValidation
      shippingNumber
      isDraft
      miscellaneousInformation {
        id
        shippingMessage
        horizontal
        portrait
        height
        actualWeight
        applicableWeight
        bulkWeight
        userData
        userDate2
        userData3
        boxQuantity
        productType
        exportDeclarationNumber
        requestShippingId
        deleted
        createdAt
        updatedAt
        __typename
      }
      productInformation {
        id
        orderNo1
        orderNo2
        departureCountryCourierNumber
        currency
        productInformation {
          id
          barcode
          productName
          quantity
          unitPrice
          brandName
          sku
          purchaseURL
          hscode
          deleted
          createdAt
          updatedAt
          __typename
        }
        totalPriceAmount
        requestShippingId
        deleted
        createdAt
        updatedAt
        __typename
      }
      bag {
        id
        name
        receptionLists {
          miscellaneousInformation {
            applicableWeight
          }
        }
        weight
        __typename
      }
      user {
        id
        __typename
      }
      userId
      deleted
      createdAt
      updatedAt
      shippingCountry {
        id
        name
        code
        __typename
      }

      __typename
    }
  }
`;
const ALL_BAG = gql`
  query allBags(
    $page: Float
    $perPage: Float
    $filter: BagManagementFilterInput
    $sortOrder: SortOrder
    $sortField: SortField
  ) {
    items: allBags(
      page: $page
      perPage: $perPage
      filter: $filter
      sortOrder: $sortOrder
      sortField: $sortField
    ) {
      id
      name
      bagNumber
      shippingCountry {
        id
        name
        code
        __typename
      }
      deliveryType {
        id
        deliveryType

        __typename
      }
      deliveryService {
        id
        shippingType
        apiType
        shippingWeight
        shippingTypeId
        createdAt
        updatedAt
        __typename
      }
      howToPayCustomDuties
      dgPackagingManagementCode
      userProfile {
        id
        userID
        username
        company_name_en
        company_name_kr
        phone_number
        balance
        company_registration_number
        __typename
      }
      userId
      numberOfUses
      receptionLists {
        id
        productInformation {
          productInformation {
            quantity
          }
          totalPriceAmount
          currency
          __typename
        }
        miscellaneousInformation {
          actualWeight
          bulkWeight
          applicableWeight
        }
        status
        __typename
      }
      weight
      usedWeight
      totalRequests
      updatedAt
      createdAt
      __typename
    }
    total: _allBagsMeta(
      page: $page
      perPage: $perPage
      filter: $filter
      sortOrder: $sortOrder
      sortField: $sortField
    ) {
      count
      __typename
    }
  }
`;

const ALL_SHIPPING_COUNTRIES = gql`
  query allShippingCountries {
    allShippingCountries {
      name
      id
      shippingTypesDetailsIds
      code
    }
  }
`;
const BAG = gql`
  query Bag($id: FindById!) {
    Bag(id: $id) {
      id
      name
      bagNumber
      shippingCountry {
        id
        name
        code
        __typename
      }
      deliveryType {
        id
        deliveryType

        __typename
      }
      deliveryService {
        id
        shippingType
        apiType
        shippingWeight
        shippingTypeId
        createdAt
        updatedAt
        __typename
      }
      howToPayCustomDuties
      dgPackagingManagementCode
      userProfile {
        id
        userID
        username
        company_name_en
        company_name_kr
        phone_number
        balance
        company_registration_number
        __typename
      }
      userId
      numberOfUses
      receptionLists {
        id
        productInformation {
          productInformation {
            quantity
          }
          totalPriceAmount
          currency
          __typename
        }
        miscellaneousInformation {
          actualWeight
          bulkWeight
          applicableWeight
        }
        status
        __typename
      }
      weight
      usedWeight
      totalRequests
      updatedAt
      createdAt
      __typename
    }
  }
`;

export { FIND_REQUEST_SHIPPING, ALL_BAG, ALL_SHIPPING_COUNTRIES, BAG };
