import { Divider, Grid, MenuItem, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import CustomInput from "../../../../components/CustomInput";

export const CreateStepTwo = ({
	handleChange,
	shippingTypes,
	handleBlur,
	values,
	errors,
	shippingCountries,
	editMode,
}) => {
	const [renderNow, setRenderNow] = useState(false);

	// useEffect(() => {
	// 	if (!renderNow) {
	// 		{
	// 			errors?.payeeInformation?.name && (errors.payeeInformation.name = "");
	// 		}
	// 		{
	// 			errors?.payeeInformation?.shippingCountry &&
	// 				(errors.payeeInformation.shippingCountry = "");
	// 		}
	// 		{
	// 			errors?.payeeInformation?.deliveryType &&
	// 				(errors.payeeInformation.deliveryType = "");
	// 		}
	// 		{
	// 			errors.payeeInformation?.phoneNumber1 &&
	// 				(errors.payeeInformation.phoneNumber1 = "");
	// 		}
	// 		{
	// 			errors.payeeInformation?.zipCode &&
	// 				(errors.payeeInformation.zipCode = "");
	// 		}
	// 		{
	// 			errors.payeeInformation?.states &&
	// 				(errors.payeeInformation.states = "");
	// 		}
	// 		{
	// 			errors.payeeInformation?.streetAddress2 &&
	// 				(errors.payeeInformation.streetAddress2 = "");
	// 		}

	// 		{
	// 			errors.payeeInformation?.cities &&
	// 				(errors.payeeInformation.cities = "");
	// 		}
	// 		{
	// 			errors.payeeInformation?.streetAddress1 &&
	// 				(errors.payeeInformation.streetAddress1 = "");
	// 		}

	// 		{
	// 			errors.payeeInformation?.phoneNumber2 &&
	// 				(errors.payeeInformation.phoneNumber2 = "");
	// 		}
	// 		setRenderNow(true);
	// 	}
	// });
	return (
		<div
			style={{
				borderRadius: 10,
				overflow: "hidden",
				border: "1px solid #E5E5E5",
				overflowX: "auto",
			}}
		>
			<Grid container>
				<Grid item xs={6}>
					{/* name */}
					<div>
						<CustomInput
							placeholder="이름을 입력해주세요."
							label="이름"
							stepper={true}
							name="payeeInformation.name"
							value={values.payeeInformation.name}
							onChange={handleChange}
							required={true}
							onBlur={handleBlur}
							labelWidth={"250px"}
						/>
						{/* {errors.payeeInformation?.name && (
								<Typography fontSize={"14px"} margin="10px " color="#ff0000">
									{"이름을 입력해주세요."}
								</Typography>
							)} */}
					</div>
				</Grid>

				<Grid item xs={6}>
					<div>
						<CustomInput
							select
							label="배송국가"
							required={true}
							stepper={true}
							name="payeeInformation.shippingCountry"
							onChange={handleChange}
							onBlur={handleBlur}
							disabled={editMode}
							defaultValue={values.payeeInformation.shippingCountry}
							labelWidth={"250px"}
						>
							{shippingCountries.map((item, index) => {
								return (
									<MenuItem
										key={index + item.id}
										value={`${item.id}-${item.code}`}
									>
										{item.code}
									</MenuItem>
								);
							})}
						</CustomInput>
						{/* {errors?.payeeInformation?.shippingCountry && (
								<Typography fontSize={"14px"} margin="10px " color="#ff0000">
									{errors.payeeInformation?.shippingCountry}
								</Typography>
							)} */}
					</div>
				</Grid>

				<Grid item xs={12}>
					<Divider />
				</Grid>

				<Grid item xs={6}>
					<div>
						<CustomInput
							placeholder="이메일을 입력해주세요."
							label="이메일"
							onBlur={handleBlur}
							name="payeeInformation.email"
							onChange={handleChange}
							value={values.payeeInformation.email}
							labelWidth={"250px"}
							// required={true}
							stepper={true}
						/>
						{/* {errors?.payeeInformation?.email && (
								<Typography fontSize={"14px"} margin="10px " color="#ff0000">
									{"이메일을 입력해주세요"}
								</Typography>
							)} */}
					</div>
				</Grid>

				<Grid item xs={6}>
					<div>
						<CustomInput
							select
							label="배송종류"
							required={true}
							name="payeeInformation.shippingTypeId"
							defaultValue={values.payeeInformation.shippingTypeId}
							onChange={handleChange}
							onBlur={handleBlur}
							labelWidth={"250px"}
							disabled={editMode}
							stepper={true}
						>
							{values.payeeInformation.shippingCountry &&
								shippingTypes
									.filter((res) => {
										if (
											typeof values.payeeInformation.shippingCountry != "object"
										) {
											const [id] =
												values.payeeInformation.shippingCountry.split("-");
											const find = shippingCountries.find(
												(res) => res.id === +id
											);
											if (find) {
												return res.id === find?.deliveryType?.id;
											} else {
												return false;
											}
										} else {
											return false;
										}
									})
									.map((item, parentIndex) => {
										return item.details
											.filter((detailId) => {
												const [id] =
													values.payeeInformation.shippingCountry.split("-");
												const find = shippingCountries.find(
													(res) => res.id === +id
												);
												if (find) {
													const detailsID = JSON.parse(
														find.shippingTypesDetailsIds
													);
													return detailsID.some((res) => {
														return res === detailId.id;
													});
												} else {
													return false;
												}
											})
											.map((detail, index) => {
												return (
													<MenuItem
														key={`${index}-${parentIndex}`}
														value={detail.id}
													>
														{detail.shippingType}
													</MenuItem>
												);
											});
									})}
						</CustomInput>

						{/* {errors?.miscellaneousInformation?.shippingTypeId && (
								<Typography fontSize={"14px"} margin="10px " color="#ff0000">
									{errors?.miscellaneousInformation?.shippingTypeId}
								</Typography>
							)} */}
					</div>
				</Grid>

				<Grid item xs={12}>
					<Divider />
				</Grid>

				<Grid item xs={6}>
					<div>
						<CustomInput
							placeholder="전화번호 1을 입력해주세요."
							label="전화번호1"
							onBlur={handleBlur}
							required={true}
							stepper={true}
							type={"text"}
							name="payeeInformation.phoneNumber1"
							onChange={handleChange}
							value={values.payeeInformation.phoneNumber1}
							labelWidth={"250px"}
						/>
						{/* {errors?.payeeInformation?.deliveryType && (
								<Typography fontSize={"14px"} margin="10px " color="#ff0000">
									{"전화번호 1을 입력해주세요."}
								</Typography>
							)} */}
					</div>
				</Grid>

				<Grid item xs={6}>
					<div>
						<CustomInput
							placeholder="전화번호 2를 입력해주세요."
							label="전화번호2"
							onBlur={handleBlur}
							stepper={true}
							type={"tel"}
							name="payeeInformation.phoneNumber2"
							onChange={handleChange}
							value={values.payeeInformation.phoneNumber2}
							labelWidth={"250px"}
						/>
					</div>
				</Grid>

				<Grid item xs={12}>
					<Divider />
				</Grid>

				<Grid item xs={6}>
					<div>
						<CustomInput
							placeholder="영국 EORI를 입력해주세요."
							label="영국 EORI"
							onBlur={handleBlur}
							stepper={true}
							name="payeeInformation.ukeori"
							onChange={handleChange}
							value={values.payeeInformation.ukeori}
							labelWidth={"250px"}
						/>
					</div>
				</Grid>
				<Grid item xs={6}>
					<div>
						<CustomInput
							placeholder="EU IOSS를 입력해주세요."
							label="EU IOSS"
							stepper={true}
							onBlur={handleBlur}
							name="payeeInformation.euioss"
							onChange={handleChange}
							value={values.payeeInformation.euioss}
							labelWidth={"250px"}
						/>
					</div>
				</Grid>

				<Grid item xs={12}>
					<Divider />
				</Grid>

				<Grid item xs={6}>
					<div>
						<CustomInput
							placeholder="멕시코 Colony를 입력해주세요."
							label="멕시코 Colony"
							onBlur={handleBlur}
							stepper={true}
							name="payeeInformation.mexicoColony"
							onChange={handleChange}
							value={values.payeeInformation.mexicoColony}
							labelWidth={"250px"}
						/>
					</div>
				</Grid>

				<Grid item xs={6}>
					<div>
						<CustomInput
							placeholder="주를 입력해주세요."
							label="주 States"
							required={true}
							stepper={true}
							onBlur={handleBlur}
							name="payeeInformation.states"
							onChange={handleChange}
							value={values.payeeInformation.states}
							labelWidth={"250px"}
						/>
						{/* {errors?.payeeInformation?.states && (
								<Typography fontSize={"14px"} margin="10px " color="#ff0000">
									{"주를 입력해주세요."}
								</Typography>
							)} */}
					</div>
				</Grid>

				<Grid item xs={12}>
					<Divider />
				</Grid>
				<Grid item xs={6}>
					<div>
						<CustomInput
							placeholder="도시를 입력해주세요."
							label="도시 Cities"
							required={true}
							stepper={true}
							onBlur={handleBlur}
							name="payeeInformation.cities"
							onChange={handleChange}
							value={values.payeeInformation.cities}
							labelWidth={"250px"}
						/>
						{/* {errors?.payeeInformation?.cities && (
								<Typography fontSize={"14px"} margin="10px " color="#ff0000">
									{"도시를 입력해주세요."}
								</Typography>
							)} */}
					</div>
				</Grid>
				<Grid item xs={6}>
					<div>
						<CustomInput
							placeholder="상세주소 1을 입력해주세요."
							label="상세 주소1"
							required={true}
							stepper={true}
							onBlur={handleBlur}
							name="payeeInformation.streetAddress1"
							onChange={handleChange}
							value={values.payeeInformation.streetAddress1}
							labelWidth={"250px"}
						/>
						{/* {errors?.payeeInformation?.streetAddress1 && (
								<Typography fontSize={"14px"} margin="10px " color="#ff0000">
									{"상세주소를 입력해주세요."}
								</Typography>
							)} */}
					</div>
				</Grid>

				<Grid item xs={12}>
					<Divider />
				</Grid>

				<Grid item xs={6}>
					<div>
						<CustomInput
							placeholder="상세주소 2를 입력해주세요."
							label="상세 주소2"
							onBlur={handleBlur}
							stepper={true}
							name="payeeInformation.streetAddress2"
							onChange={handleChange}
							value={values.payeeInformation.streetAddress2}
							labelWidth={"250px"}
						/>
						{/* {errors?.payeeInformation?.streetAddress2 && (
								<Typography fontSize={"14px"} margin="10px " color="#ff0000">
									{"상세주소를 입력해주세요"}
								</Typography>
							)} */}
					</div>
				</Grid>

				<Grid item xs={6}>
					<div>
						<CustomInput
							placeholder="우편번호를 입력해주세요."
							label="우편번호"
							onBlur={handleBlur}
							stepper={true}
							name="payeeInformation.zipCode"
							onChange={handleChange}
							value={values.payeeInformation.zipCode}
							labelWidth={"250px"}
							required={true}
						/>
						{/* {errors?.payeeInformation?.email && (
								<Typography fontSize={"14px"} margin="10px " color="#ff0000">
									{"이메일을 입력해주세요"}
								</Typography>
							)} */}
					</div>
				</Grid>
				<Grid item xs={12}>
					<Divider />
				</Grid>

				<Grid item xs={12}>
					<div>
						<CustomInput
							placeholder="주민/통관/CPF 번호를 입력해주세요."
							label="주민/통관/CPF 번호"
							onBlur={handleBlur}
							stepper={true}
							name="payeeInformation.ssn"
							onChange={handleChange}
							value={values.payeeInformation.ssn}
							labelWidth={"212px"}
						/>
					</div>
				</Grid>
			</Grid>
		</div>
	);
};
