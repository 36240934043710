import * as React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Box";
import CustomButton from "../components/CustomButton";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import Modal from "@mui/material/Modal";
import InfoIcon from "@mui/icons-material/Info";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 420,
	bgcolor: "background.paper",
	boxShadow: "0px 0px 5px rgba(31, 65, 115, 0.1)",
	borderRadius: "10px",
	textAlign: "center",
	p: 4,
};

const iconStyle = {
	backgroundColor: "#fef5ed",
	width: "100px",
	height: "100px",
	marginLeft: "auto",
	marginRight: "auto",
	borderRadius: "100%",
	paddingTop: "21px",
};

export function PopUpModal({ open, handleOpen, handleClose }) {
	const navigate = useNavigate();

	return (
		<div>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={style}>
					<Stack sx={iconStyle}>
						<InfoIcon sx={{ color: "#F2994A", fontSize: "60px" }} />
					</Stack>
					<Typography sx={{ mt: 2 }} style={{ textAlign: "center" }}>
						현재 예치금이 부족하여 배송 신청이 불가합니다.
					</Typography>
					<Typography sx={{ ml: 2 }} style={{ textAlign: "center" }}>
						{`배송 신청을 하시려면 예치금을 충전해주세요.`}
					</Typography>
					<CustomButton
						width="100px"
						onClick={() => navigate("/")}
						style={{ marginTop: "30px", borderRadius: "10px" }}
					>
						확인
					</CustomButton>
				</Box>
			</Modal>
		</div>
	);
}
