import { Grid, IconButton, MenuItem } from "@mui/material";
import { SelectBox, TextFieldBox } from "./CustomInput";
import React, { useRef, useState, useEffect } from "react";
import SearchIcon from "@mui/icons-material/Search";
import CustomButton from "./CustomButton";

function DropdownAndSearchInput({
	onChange,
	xs = 11,
	justifyContent = "space-between",
	defaultValue = { key: "", value: "" },
	value = { key: "", value: "" },
	dropDownValues = [{ value: "", title: "" }],
	inputFieldPlaceHolder = "",
	...props
}) {
	const [filterQuery, setFilterQuery] = useState({
		key: "",
		value: "",
	});
	const prevValue = useRef();

	// console.log({ defaultValue }, { value });

	useEffect(() => {
		if (value || defaultValue) {
			if (value && prevValue) {
				if (prevValue.current) {
					const [key] = Object.keys(prevValue.current);
					if (key) {
						setFilterQuery({ key, value: value[key] });
					}
				}
			} else {
				setFilterQuery(defaultValue);
			}
		}
	}, [value, defaultValue]);

	const handleFilterChange = ({ target }) => {
		const { name: key, value } = target;
		setFilterQuery((prevState) => {
			return { ...prevState, [key]: value };
		});
	};

	const handleOnChange = () => {
		const finalObj = { [filterQuery.key]: filterQuery.value };
		onChange({ preValue: prevValue.current, newValue: finalObj });
		prevValue.current = finalObj;
	};

	return (
		<>
			<Grid
				container
				item
				xs={xs}
				gap={1}
				justifyContent={justifyContent}
				style={{ flexWrap: "nowrap" }}
			>
				<Grid item xs={3} style={{ maxWidth: "220px" }}>
					<SelectBox
						name={"key"}
						onChange={handleFilterChange}
						defaultValue={defaultValue.key}
						value={filterQuery.key}
					>
						{dropDownValues.map(({ value, title }, index) => {
							return (
								<MenuItem key={index} value={value}>
									{title}
								</MenuItem>
							);
						})}
					</SelectBox>
				</Grid>

				<Grid item xs={6.5} style={{ maxWidth: "300px" }}>
					<TextFieldBox
						onChange={handleFilterChange}
						onKeyDown={(event) => {
							if (event.keyCode === 13) {
								handleOnChange();
							}
						}}
						name="value"
						value={filterQuery.value}
						placeholder={
							inputFieldPlaceHolder ||
							`${
								dropDownValues.find((res) => res.value === filterQuery.key)
									?.title || "업체명"
							} 입력해주세요`
						}
					/>
				</Grid>
				<Grid>
					<CustomButton
						onClick={handleOnChange}
						style={{
							height: "38px",
							borderRadius: 10,
							backgroundColor: "#5B8FF7",
						}}
					>
						검색
					</CustomButton>
				</Grid>
			</Grid>

			{/* <Grid container item xs={6} spacing={0.5} alignItems={"center"}>
        <Grid item flexGrow={1} sx={{ display: "flex" }}>
          <TextFieldBox
            onChange={handleFilterChange}
            onKeyDown={(event) => {
              if (event.keyCode === 13) {
                handleOnChange();
              }
            }}
            name="value"
            value={filterQuery.value}
            placeholder={
              inputFieldPlaceHolder ||
              `${
                dropDownValues.find((res) => res.value === filterQuery.key)
                  ?.title || "업체명"
              } 입력해주세요`
            }
          />
          <IconButton
            variant={"contained"}
            color={"primary"}
            onClick={handleOnChange}
            sx={{ml:1}}
          >
            <SearchIcon />
          </IconButton>
        </Grid>
      </Grid> */}
		</>
	);
}

export default DropdownAndSearchInput;
