import {
	Checkbox,
	Divider,
	Grid,
	Link,
	MenuItem,
	Pagination,
	Stack,
} from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import CustomButton from "../../../components/CustomButton";
import CustomInput, {
	SelectBox,
	TextFieldBox,
} from "../../../components/CustomInput";
import CustomPopover from "../../../components/CustomPopover";
import TableContainer from "../../../components/TableContainer";
import ListHOC from "../../../SharedComponents/ListHOC";
import { gql } from "@apollo/client/core";
import { APIClient } from "../../../utils/apiGraphql";
import styled from "styled-components";
import moment from "moment";
import Toaster from "../../../utils/Toaster";
import { useDelete } from "../../../utils/utils";
import CustomSelect from "../../../components/CustomSelect";

export default function AdminShippingSuspend() {
	return (
		<ListHOC _resource={"RequestShipping"} showFilter={{ isDraft: true }}>
			<AdminShippingHalt />
		</ListHOC>
	);
}

function AdminShippingHalt({
	page,
	perPage,
	handlePageChange,
	handleSearch,
	handleSetPerPage,
	excelData,
	countTotal,
	...hocProps
}) {
	const [data, setData] = useState([]);
	useEffect(() => {
		if (hocProps) {
			setData(hocProps.data);
		}
	}, [hocProps]);

	return (
		<Stack spacing={"35px"}>
			<Grid container justifyContent={"space-between"} alignItems={"center"}>
				<Grid xs={12} alignItems={"center"} justifyContent={"flex-end"}>
					<Grid
						container
						columnGap={1}
						xs={12}
						alignItems={"center"}
						justifyContent={"flex-end"}
						sx={{ mr: "280px", ml: "auto" }}
					>
						<Grid item xs={2}>
							<CustomSelect
								value={String(perPage)}
								label=""
								onChange={({ target }) => {
									handleSetPerPage(target.value);
								}}
								options={["10", "20", "30", "40", "50", "100"]}
							></CustomSelect>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<div style={{ position: "relative" }}>
				<TableContainer
					// {...{ isLoading: false, requestCompleted: true }}
					{...hocProps}
					headers={[
						"No.",
						"업로드 날짜",
						"고객사명",
						"배송번호",
						"접수번호",
						"주문번호",
						"수취인 이름",
						"수취인 전화번호",
						"수취인 주소",
					]}
					data={data.map((item, index) => [
						countTotal - ((page - 1) * perPage + index),
						item.createdAt ? moment(item.createdAt).format("YYYY-MM-DD") : "",
						item.user?.company_name_kr,
						item.shippingNumber,
						item.trackingNumber,
						item.orderNumber,
						item.payeeInformation?.name,
						item.payeeInformation?.phoneNumber1,
						`${item.payeeInformation?.streetAddress1}, ${item.payeeInformation?.streetAddress2}, ${item.payeeInformation?.cities}, ${item.payeeInformation?.states}, ${item.payeeInformation?.shippingCountry?.name}`,
					])}
				/>
			</div>
		</Stack>
	);
}
