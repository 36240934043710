import * as React from "react";


export default function NLPostPackingList({ data }) {
  return (
    <table>
        <tr>
            <td colSpan="10" id="sjs-A2"></td>
        </tr>
        <tr>
            <td colSpan="10" id="sjs-A2">
                Invoice
            </td>
        </tr>  
        <tr>
            <td>From:</td>
            <td>H3 networks co., ltd.</td>
        </tr>

        <tr rowSpan="3">
            <td>Address:</td>
            <td>
                #B103, Hwagok-ro 416 The Sky Valley 5th<br/>
                Gangseo-gu, Seoul . 07548<br/>
                Republic of Korea<br/>
            </td>
        </tr>

        <tr></tr>
        <tr></tr>

        <tr>
            <td>Phone:</td>
        </tr>
        <tr>
            <td>VAT reg.no:</td>
        </tr>
        <tr>
            <td>Org.nr:</td>
        </tr>

        <tr></tr>
        <tr></tr>

        <tr>
            <td>Name and complete address seller</td>
            <td></td>
            <td>Name and complete address distributor</td>
            <td></td>
            <td></td>
            <td></td>
            <td>Final Addressee</td>
        </tr>

        <tr>
            <td>H3 networks co., ltd.</td>
            <td></td>
            <td>PostNL International Mail Unit</td>
            <td></td>
            <td></td>
            <td></td>
            <td>Multiple Addresses</td>
        </tr>

        <tr>
            <td>#B103, Hwagok-ro 416 The Sky Valley 5th</td>
            <td></td>
            <td>Menzies Aviation Cargo AMS</td>
            <td></td>
            <td></td>
            <td></td>
            <td>In various countries</td>
        </tr>

        <tr>
            <td>Gangseo-gu, Seoul . 07548</td>
            <td></td>
            <td>Anchoragelaan 50/ Dock 15</td>
            <td></td>
            <td></td>
            <td></td>
            <td>see for individual addresses</td>
        </tr>

        <tr>
            <td>Republic of Korea</td>
            <td></td>
            <td>1118 LE Schiphol</td>
        </tr>

        <tr>
            <td>VAT NO:</td>
            <td></td>
            <td>The Netherlands</td>
        </tr>

        <tr>
            <td></td>
            <td></td>
            <td>Terms of Delivery</td>
            <td>25/08/2022</td>
        </tr>

        <tr>
            <td>Invoice Number (Aut.no.):</td>
            <td></td>
            <td>Location and date:</td>
        </tr>

        <tr></tr>
        <tr></tr>
        <tr></tr>

        <tr>
            <td>Total number of pallets</td>
            <td>1</td>
        </tr>

        <tr></tr>

        <tr>
            <td>件 數</td>
            <td>包 裝</td>
            <td>貨 物 名 稱 及 其 數 量</td>
            <td>箱 嘜</td>
            <td>尺 碼</td>
            <td>重 量</td>
        </tr>

        <tr>
            <td>No. Of</td>
            <td>Method Of</td>
            <td>Nature And Quantity Of Goods</td>
            <td>Marks &</td>
            <td>Dimensions</td>
            <td>Weight Specify</td>
        </tr>

        <tr>
            <td>Packages</td>
            <td>Packing</td>
            <td></td>
            <td>Numbers</td>
            <td></td>
            <td>LBS .  Or KGS .</td>
        </tr>
    </table>
  );
}
