import {
	Grid,
	Stack,
	Box,
	Divider,
	Checkbox,
	MenuItem,
	Pagination,
} from "@mui/material";

import React from "react";
import { DepositHistoryTable } from "../../../components/DepositHistoryTable";
import CustomButton from "../../../components/CustomButton";
import { SelectBox, TextFieldBox } from "../../../components/CustomInput";
import { SearchBox } from "../../../components/Searchbox";
import styled from "styled-components";
import ListHOC from "../../../SharedComponents/ListHOC";
import TableContainer from "../../../components/TableContainer";
import { useState } from "react";
import { useEffect, useCallback } from "react";
import Moment from "moment";
import DateRangePicker from "../../../components/DateRangePicker";
import CustomSelect from "../../../components/CustomSelect";
import { Form, Formik } from "formik";
import { onDropDownSearchChange } from "../../../utils/onDropDownSearchChange";
import {
	generateSearchData,
	getQueryParams,
	handleChange,
	handleOtherSearch,
	useGetList,
	useDeleteManyWithIds,
} from "../../../utils/utils";
import { useLocation } from "react-router-dom";
import DropdownAndSearchInput from "../../../components/DropdownAndSearchInput2";
import DownloadExcelButton from "../../../components/DownloadExcelButton";
import { getFilteredTransaction } from "./queries";
import { exportXlx } from "./utils/exportXlx";
import Loader from "../../../components/Loader";

import { useSearchParams } from "react-router-dom";

export default function ReceptionHistory() {
	return (
		<ListHOC _resource={"Transaction"}>
			<ReceptionHistoryAdmin />
		</ListHOC>
	);
}

const ReceptionHistoryAdmin = ({
	data: _Data,
	handleSearch,
	page,
	perPage,
	handleSetPerPage,
	handleSort,
	countTotal,
	excelData,
	...hocProps
}) => {
	const [transactionData, setTransactionData] = useState(null);
	const [filterQuery, setFilterQuery] = useState(null);
	const [filteredData, setFilteredData] = useState(null);
	const [filterDate, setFilterDate] = useState(null);
	const [checkedList, setCheckedList] = useState([]);
	const [data, setData] = useState([]);
	const [sort, setSort] = useState("");
	const { state, search } = useLocation();
	const [exportdata, setExportdata] = useState([]);

	const [searchParams] = useSearchParams();

	// loader for company name in advance
	const [countryLoader, setcountryLoader] = useState(
		searchParams.get("company_name_kr") ? true : false
	);
	const [hide, setHide] = useState(
		searchParams.get("company_name_kr") ? true : false
	);
	useEffect(() => {
		setData(_Data);
	}, [_Data]);

	//Fetching export data
	useEffect(() => {
		getFilteredTransaction(null, null).then((res) => {
			setExportdata(res.data.allTransactions);
		});
	}, []);

	const handleCheck = ({ target }) => {
		const { value, checked } = target;
		setCheckedList((prevState) => {
			const temp = [...prevState];
			if (checked) {
				temp.push(+value);
				return temp;
			} else {
				return temp.filter((res) => res !== +value);
			}
		});
	};

	const doSearch = useCallback(() => {
		let additionalValues = {};

		if (state) {
			const { company_id } = state;
			const queryParams = getQueryParams(search);
			const company_name_kr = queryParams.get("company_name_kr");

			additionalValues = {
				...additionalValues,
				...(company_name_kr == company_id && { company_name_kr }),
			};
		}

		generateSearchData(handleSearch, filterQuery, additionalValues);
		getFilteredTransaction(filterQuery, additionalValues).then((res) => {
			setExportdata(res.data.allTransactions);
		});
	}, [filterQuery]);

	useEffect(() => {
		// setTimeout(() => {
		doSearch();
		// }, 3000);
	}, [filterQuery]);

	useEffect(() => {
		if (data.length > 0 && countryLoader) {
			setFilterQuery({
				...filterQuery,
				company_name_kr: searchParams.get("company_name_kr"),
			});
			setTimeout(() => {
				setcountryLoader(false);
			}, 1000);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);

	const rangeChange = (value) => {
		setFilterQuery({
			...filterQuery,
			createdAt: value.createdAt,
		});
	};

	function SortFilter(value) {
		if (value === "1") {
			setSort(value);
			let sortData = {
				sortOrder: { sortOrder: "asc" },
				sortField: { sortField: "deposit_processing_date" },
			};
			handleSort(sortData);
		} else if (value === "2") {
			// setSort({ sortOrder: "desc", sortField: "deposit_processing_date" });
			setSort(value);
			let sortData = {
				sortOrder: { sortOrder: "desc" },
				sortField: { sortField: "deposit_processing_date" },
			};
			handleSort(sortData);
		} else if (value === "3") {
			// setSort({ sortOrder: "asc", sortField: "company_name_en" });
			setSort(value);
			let sortData = {
				sortOrder: { sortOrder: "asc" },
				sortField: { sortField: "user95company_name_kr" },
			};
			handleSort(sortData);
		} else if (value === "4") {
			// setSort({ sortOrder: "desc", sortField: "company_name_en" });
			setSort(value);
			let sortData = {
				sortOrder: { sortOrder: "desc" },
				sortField: { sortField: "user95company_name_kr" },
			};
			handleSort(sortData);
		} else setSort("");
	}

	//Export xlsx
	const exportXlxFile = () => {
		exportXlx(exportdata);
	};

	return !countryLoader ? (
		<Stack spacing={"35px"}>
			<Stack justifyContent={"space-between"} spacing={"10px"}>
				<Grid
					container
					style={{
						display: "flex",
						justifyContent: "end",
					}}
				>
					<Grid item xs={2}>
						{exportdata.length > 0 && (
							<CustomButton
								styles={{
									width: "100%",
									whiteSpace: "nowrap",
									padding: "10px",
									color: "black",
									backgroundColor: "transparent",
									border: "1px solid #cec7c7",
									marginBottom: "16px",
								}}
								onClick={exportXlxFile}
							>
								<img
									style={{ width: "30px", paddingRight: "10px" }}
									src="/assets/images/excel.png"
									alt="excel"
								></img>
								엑셀 다운로드
							</CustomButton>
						)}
					</Grid>
				</Grid>
				<Grid item xs={12}>
					<Grid
						container
						style={{
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
						}}
					>
						<Grid item xs={4} style={{ margin: "auto 10px" }}>
							<Formik
								enableReinitialize
								initialValues={
									filterQuery || {
										createdAt: `${Moment().format(
											"YYYY-MM-DD"
										)} - ${Moment().format("YYYY-MM-DD")}`,
									}
								}
								onSubmit={(values) => {
									handleOtherSearch(values, setFilterQuery);
									setFilterDate(values);
								}}
							>
								{({ values, handleSubmit, setFieldValue, resetForm }) => {
									return (
										<Form onSubmit={handleSubmit}>
											<Grid
												container
												alignItems={"center"}
												style={{ flexWrap: "nowrap" }}
											>
												<Grid item xs={4} style={{ margin: "auto 10px" }}>
													<SelectBox defaultValue="처리일" value="처리일">
														<MenuItem value="처리일">처리일</MenuItem>
													</SelectBox>
												</Grid>
												<Stack spacing={"20px"} width={340}>
													<div>
														<DateRangePicker
															name={"createdAt"}
															value={values?.["createdAt"]}
															showLabel={false}
															handleChange={rangeChange}
														/>
													</div>
												</Stack>
											</Grid>
										</Form>
									);
								}}
							</Formik>
						</Grid>

						{!hide ? (
							<>
								<Grid item xs={1.5} style={{ margin: "auto 10px" }}>
									<SelectBox
										defaultValue="default"
										onChange={(e) => SortFilter(e.target.value)}
									>
										<MenuItem value="default" disabled hidden>
											정렬 선택
										</MenuItem>
										<MenuItem value="1">처리일 오름차순</MenuItem>
										<MenuItem value="2">처리일 내림차순</MenuItem>
										<MenuItem value="3">업체명 오름차순 </MenuItem>
										<MenuItem value="4">업체명 내림차순 </MenuItem>
									</SelectBox>
								</Grid>

								<Grid container xs={4.5}>
									<Grid xs={11}>
										<DropdownAndSearchInput
											onChange={(data) =>
												onDropDownSearchChange(data, setFilterQuery)
											}
											value={filterQuery}
											defaultValue={{ key: "company_name_kr", value: "" }}
											dropDownValues={[
												{ value: "company_name_kr", title: "업체명" },
											]}
											inputFieldPlaceHolder={"검색어를 입력해주세요"}
										/>
									</Grid>
								</Grid>
							</>
						) : null}
						<Grid item xs={1.5}>
							<CustomSelect
								value={String(perPage)}
								label=""
								onChange={({ target }) => {
									handleSetPerPage(target.value);
								}}
								options={["10", "20", "30", "40", "50", "100"]}
							></CustomSelect>
						</Grid>
					</Grid>
				</Grid>
			</Stack>
			<div style={{ position: "relative" }}>
				<TableContainer
					{...hocProps}
					headers={[
						<HeaderCol1>No.</HeaderCol1>,
						"업체명",
						"입금액",
						"출금액",
						"잔액",
						"메모",
						"입출금처리날짜",
						"배송번호",
						"내용",
					]}
					data={data?.map((item, index) => {
						return [
							countTotal - ((page - 1) * perPage + index),
							item?.user?.company_name_kr,
							<div style={{ color: "blue" }}>
								{item.type === "deposit"
									? parseInt(item.amount).toLocaleString()
									: ""}
							</div>,
							<div style={{ color: "red" }}>
								{item.type === "withdraw" || item.type === "deduction"
									? parseInt(item.amount).toLocaleString()
									: ""}
							</div>,
							parseInt(item.leftBalance).toLocaleString(),
							item.memo,
							item.deposit_processing_date &&
								Moment(item.deposit_processing_date).format("YYYY-MM-DD HH:mm"),
							item.shippingFeeDeductionHistory?.shipping_number,
							item.type === "deposit" && item.reason === "customer-fees"
								? "고객 요금 입금"
								: item.type === "deposit" && item.reason === "reward-amount"
								? "보상 금액 입금"
								: item.type === "deposit" && item.reason === "other"
								? "기타"
								: item.type === "withdraw" && item.reason === "customer-fees"
								? "기타 서비스 사용 요금"
								: item.type === "withdraw" && item.reason === "reward-amount"
								? "착오로 인한 입금"
								: item.type === "deduction" &&
								  item.reason === "delivery-fee-deduction"
								? "배송접수건 출고"
								: item.type === "withdraw" && item.reason === "other" && "기타",
						];
					})}
				/>
			</div>
		</Stack>
	) : (
		<div style={{ height: "90vh" }}>
			<Loader />
		</div>
	);
};

const ExcelButton = styled(CustomButton)`
	color: black;
	font-weight: light;
	border: 1px solid #c4c4c4;
	height: 40px;
	border-radius: 10px;
	margin-bottom: 10px;
`;
const HeaderCol1 = styled.div`
	padding: 0 10px;
`;
