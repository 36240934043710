import React from "react";
import {
	Page,
	Text,
	View,
	Document,
	StyleSheet,
	Image,
	Font,
} from "@react-pdf/renderer";

import LTPostPremiumImg from "../../Common/assets/Images/LTPostPremium.png";
import SIGNATURE from "../../Common/assets/Images/SIGNATURE.JPG";
import PostNLPB1 from "../../Common/assets/Images/PostNL_PB1.jpg";
import phoneSymbol from "../../Common/assets/Images/phone-symbol.png";
import Scan from "../../Common/assets/Images/Scan.jpg";
import ReturnLogo from "../../Common/assets/Images/Return-Logo.jpg";
import LtPostWeight from "../../Common/assets/Images/shipterweight.png";
import moment from "moment";
import PackageTable from "./PackageTable";

export const LTPostPremium = (props) => {
	const { barcode, selectedRequests } = props;
	// console.log("barcode", barcode, "selectedRequests", selectedRequests);
	let { tableData, ...item1 } = selectedRequests;
	// console.log(props);
	return (
		<>
			{/* {selectedRequests?.map((item, index1) => {
				return item.map((item1, index2) => {
					return ( */}
			{tableData && <PackageTable tableData={tableData} />}
			<Page size="A6" orientation="landscape" style={styles.page}>
				<View style={styles.mainView}>
					<View style={styles.rightSec}>
						<View style={styles.from}>
							<View style={styles.fromLeft}>
								<View style={styles.fromAddress}>
									<Text style={styles.fromRotate}>FROM</Text>
									<View>
										<Text>Sender</Text>
										<Text>O.B. Post + Vilniaus logistikos centras</Text>
										<Text>P.d. 5045, Metalo g.5</Text>
										<Text>02190 Vilnius</Text>
										<Text>LITHUANIA</Text>
									</View>
								</View>
							</View>

							<View style={styles.fromRight}>
								<View style={styles.row}>
									<Image style={styles.TrackImg} src={LTPostPremiumImg} />
								</View>
								{/* <View>
                                                <Text style={styles.packetCon}>Packet Tracked</Text>
                                                <Text style={styles.standardText}>STANDARD</Text>                
                                            </View> */}
							</View>
						</View>
						<View style={styles.to}>
							<View style={styles.toLeft}>
								<Text>To</Text>
							</View>

							<View style={styles.toRight}>
								<View style={styles.row}>
									<View>
										<Text>{item1.payeeInformation?.name}</Text>
										<Text>{item1.payeeInformation?.streetAddress1}</Text>
									</View>

									<View style={styles.row}>
										<Image style={styles.phoneSym} src={phoneSymbol} />
										<Text>{item1.payeeInformation.phoneNumber1}</Text>
									</View>
								</View>

								<Text>{item1.payeeInformation?.streetAddress2}</Text>
								<Text>ZipCode: {item1.payeeInformation?.zipCode}</Text>
								<Text>{item1.shippingCountry?.country_name_en}</Text>
							</View>
							<View style={styles.countryCode}>
								<Text>{item1.shippingCountry?.code}</Text>
							</View>
						</View>
						<View style={styles.IconDiv}>
							<View style={styles.Icon}>
								<Image style={styles.scanImg} src={Scan} />
								<Image style={styles.NoSignImg} src={SIGNATURE} />
								<View style={styles.WeightContainer}>
									<Image style={styles.lock} src={LtPostWeight} />
									<Text style={styles.WeightText}>
										{item1.miscellaneousInformation.applicableWeight}kg
									</Text>
								</View>
							</View>

							<View style={styles.width100}>
								<Text>No. {item1?.trackingNumber} </Text>
								<Text>Order No: {item1?.productInformation?.orderNo1} </Text>
								{item1.productInformation.productInformation
									.slice(0, 3)
									.map((product) => {
										return (
											<View style={styles.row}>
												{" "}
												<Text>
													{product.productName.length > 25
														? product.productName.slice(0, 25).concat("...")
														: product.productName}
												</Text>
												<Text>* {product.quantity}ea</Text>
											</View>
										);
									})}
							</View>
						</View>

						<View style={styles.TrackingDiv}>
							<View style={styles.TrackingText}>
								<Text>{item1.senderInformation?.name}</Text>
								<Text>{moment().format("YYYY-MM-DD")}</Text>
							</View>
							<View style={styles.rowNoSpace}>
								<Text style={styles.shippingInfo}>R</Text>
								<View style={styles.col}>
									<Image src={barcode} width="100%" height={50} />
									{/* <Text style={styles.shippingInfo2}>
										{item1.shippingNumber}
									</Text> */}
								</View>
							</View>
						</View>
					</View>
				</View>
			</Page>
			{/* );
				});
			})} */}
		</>
	);
};

const fRow = {
	diaplay: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
};
const fCol = {
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	justifyContent: "center",
};
Font.register({
	family: "SpoqaHanSans",
	src: "https://cdn.jsdelivr.net/gh/spoqa/spoqa-han-sans@01ff0283e4f36e159ffbf744b36e16ef742da6d8/Subset/SpoqaHanSans/SpoqaHanSansLight.ttf",
});
const styles = StyleSheet.create({
	page: {
		fontSize: 5,
		padding: 10,

		fontFamily: "SpoqaHanSans",
	},
	sec1: {
		display: "flex",
		flexDirection: "row",
		width: "100%",
		color: "#fff",
	},
	sec11: {
		backgroundColor: "white",
		padding: 2,
		color: "#000",
		borderBottom: "2px solid #000",
		display: "flex",
		flexDirection: "row",
		justifyContent: "flex-start",
		alignItems: "flex-start",
	},
	sec12: {
		...fCol,
		backgroundColor: "white",
		width: "65%",
		padding: 15,
		color: "red",
		fontWeight: 700,
		border: "2px solid #000",
	},
	sec13: {
		width: "50%",
		borderRight: "1px solid #000",
	},
	sec14: {
		backgroundColor: "white",
		color: "#000",
		borderBottom: "2px solid #000",
		display: "flex",
		flexDirection: "row",
	},
	sec15: {
		width: "25%",
		borderRight: "1px solid #000",
	},
	sec16: {
		borderBottom: "1px solid #000",
		fontSize: 6,
	},
	leftSec: {
		width: "40%",
		borderRight: "1px solid black",
	},
	rightSec: {
		width: "100%",
		color: "#000",
		backgroundColor: "#fff",
	},
	from: {
		display: "flex",
		flexDirection: "row",
		borderBottom: "1px solid black",
	},
	fromLeft: {
		width: "55%",
		borderRight: "1px solid black",
		// backgroundColor: "#afdcec",
		// borderRadius: 5,
		// border: "0.5px solid blue",
		marginLeft: 5,
		marginRight: 5,
		// marginBottom: 5,
		padding: 0,
	},
	fromRight: {
		// ...fRow,
		width: "45%",
		// backgroundColor: "#afdcec",
		// borderRadius: 5,
		// border: "0.5px solid blue",
		marginLeft: 5,
		// marginRight: 5,
		// marginBottom: 5,
	},
	to: {
		display: "flex",
		flexDirection: "row",
		borderBottom: "1px solid black",
	},
	toLeft: {
		...fCol,
		width: "15%",
		borderRight: "1px solid black",
		fontSize: 12,
	},
	toRight: {
		width: "85%",
		// backgroundColor: "#ddead1",
		// borderRadius: 5,
		// border: "0.5px solid blue",
		marginLeft: 5,
		marginRight: 5,
		marginBottom: 5,
		padding: 5,
	},
	Icon: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "flex-start",
		alignItems: "center",
		marginRight: "20px",
		// borderBottom: "1px solid black"
	},
	mainView: {
		display: "flex",
		flexDirection: "row",
		width: "100%",
	},
	red: {
		color: "red",
	},
	sec2: {
		...fRow,
		color: "red",
		fontWeight: 700,
		padding: 10,
		alignItems: "center",
		justifyContent: "center",
		border: "2px solid #000",
	},
	sec3: {
		...fCol,
		alignItems: "flex-start",
		height: 170,
		paddingLeft: 15,
		paddingRight: 15,
		border: "2px solid #000",
	},
	sec3a: {
		...fRow,
	},
	sec4: {
		...fCol,
		height: 170,
		alignItems: "flex-start",
		paddingLeft: 15,
		paddingRight: 15,
		border: "2px solid #000",
	},
	sec5: {
		...fRow,
		width: "100%",
		paddingLeft: 15,
		paddingRight: 15,
	},
	sec6: {
		...fRow,
		width: "100%",
		paddingLeft: 15,
		paddingRight: 15,
		border: "2px solid #000",
	},
	sec6a: {
		...fCol,
		width: "100%",
		paddingLeft: 15,
		paddingRight: 15,
	},
	sec5a: {
		...fCol,
		justifyContent: "flex-start",
		alignItems: "flex-start",
		height: 200,
		border: "2px solid #000",
	},
	sec51: {
		...fCol,
		alignItems: "flex-start",
		justifyContent: "flex-start",
		width: "50%",
		height: 150,
		paddingLeft: 15,
		paddingRight: 15,
	},
	sec52: {
		...fCol,
		height: 150,
		width: "50%",
		justifyContent: "flex-start",
		alignItems: "flex-start",

		paddingLeft: 15,
		paddingRight: 15,
	},

	listItem: {
		...fRow,
		width: "100%",
		marginBottom: 10,
		gap: "20px",
		justifyContent: "flex-end",
		padding: "0 10px",
	},
	list: {
		...fRow,
		marginRight: 10,
	},
	listItemLabel: {},
	listItemValue: {
		color: "red",
	},
	line: {
		height: 1,
		backgroundColor: "black",
		marginBottom: 2,
	},
	body: {
		padding: 10,
	},
	row: {
		...fRow,
	},
	center: {
		...fCol,
		marginTop: 25,
	},
	image: {
		maxWidth: 130,
		height: "auto",
	},
	postCode: {
		fontSize: 16,
	},
	border: {
		border: "1px solid black",
	},
	borderBottom: {
		borderBottom: "1px solid black",
	},
	borderTop: {
		borderTop: "1px solid black",
	},
	bold: {
		fontWeight: "bold",
	},
	greenCon: {
		//     backgroundColor: "#ddead1",
		//     borderRadius: 5,
		//     border: "0.5px solid blue",
		marginLeft: 5,
		marginRight: 5,
		marginBottom: 5,
		padding: 5,
	},
	blueCon: {
		//     backgroundColor: "#afdcec",
		//     borderRadius: 5,
		//     border: "0.5px solid blue",
		marginLeft: 5,
		marginRight: 5,
		marginBottom: 5,
		padding: 5,
	},
	dateAndSender: {
		marginTop: 10,
		marginBottom: 10,
	},
	weightInKg: {
		...fCol,
		width: "25%",
		borderRight: "1px solid #000",
	},
	valueInUSD: {
		...fCol,
		width: "25%",
		borderRight: "1px solid #000",
	},

	col: {
		...fCol,
	},
	TrackImg: {
		// width: 50,
		// height: 50
	},
	PostNLImg: {
		// width: 150,
		// height: 70
	},
	phoneSym: {
		width: 8,
		height: 8,
	},
	WeightContainer: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		position: "relative",
	},
	WeightText: {
		position: "absolute",
		top: "55%",
	},
	scanImg: {
		width: 60,
		height: 40,
		// backgroundColor: "#afdcec",
		// borderRadius: 5,
		// border: "0.5px solid blue",
		padding: 5,
		marginTop: 5,
	},
	returnLogoImg: {
		width: 15,
		height: 15,
	},
	blueConNoPadding: {
		//     backgroundColor: "#afdcec",
		//     border: "0.5px solid blue",
		marginLeft: 5,
		marginRight: 5,
		marginBottom: 5,
	},
	countryCode: {
		display: "flex",
		justifyContent: "flex-end",
		fontSize: 17,
	},
	fromAddress: {
		display: "flex",
		flexDirection: "row",
		fontSize: 8,
		marginRight: 12,
		marginBottom: 10,
	},
	fromRotate: {
		transform: "rotate(-90deg)",
		fontSize: 10,
		marginTop: 10,
	},
	standardText: {
		backgroundColor: "#000",
		color: "#fff",
		textAlign: "center",
		padding: 2,
	},
	packetCon: {
		textAlign: "center",
	},
	NoSignImg: {
		width: 40,
		height: 40,
		marginTop: 6,
	},
	IconDiv: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "flex-start",
		// borderBottom: "1px solid black",
	},

	TrackingDiv: { marginTop: "10px", borderTop: "1px solid #000" },
	TrackingText: { fontSize: "8px" },

	rowLT: {
		display: "flex",
		justifyContent: "space-between",
		flexDirection: "row",
		width: "100%",
	},
	width100: {
		width: "100%",
	},
	shippingInfo: {
		fontSize: 24,
		textAlign: "center",
		marginLeft: 10,
		marginRight: 10,
	},
	shippingInfo2: {
		fontSize: 16,
		textAlign: "center",
	},
	rowNoSpace: {
		display: "flex",
		justifyContent: "flex-start",
		flexDirection: "row",
		marginTop: 8,
		marginBottom: 8,
	},
	lock: {
		width: 30,
		height: 30,
		marginTop: 6,
	},
});
