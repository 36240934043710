import { Box, Divider, Modal, Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import CustomButton from "../components/CustomButton";
import CustomCard from "../components/CustomCard";
import Icons from "../utils/icon";

export default function ConfirmModal({
	isOpen,
	onClose,
	onConfirm,
	isLoading = false,
	title = "",
	description = "",
	lastBtn,
	// handleDelete,
	...props
}) {
	return (
		<Modal
			open={isOpen}
			onClose={onClose}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Box
				style={{
					padding: 20,
					overflowX: "hidden",
					minHeight: "100vh",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				<CustomCard>
					<Stack spacing={"20px"} width={430} p="20px">
						<Stack direction="row" alignItems={"center"} spacing="10px">
							<div>
								<Icons.Warning />
							</div>

							<Typography fontSize={"20px"} fontWeight="700">
								{title}
							</Typography>
						</Stack>

						<div>
							<Typography
								lineHeight={"0.5"}
								fontSize={"14px"}
								color="rgba(0,0,0,0.6)"
								component={"div"}
							>
								{description}
							</Typography>
						</div>

						<Divider />

						<div
							style={{
								display: "flex",
								justifyContent: "flex-end",
								gap: "20px",
							}}
						>
							<CustomButton
								onClick={onClose}
								styles={{
									background: "#F8F8FA",
									color: "#000",
									width: "auto",
								}}
							>
								취소
							</CustomButton>
							<CustomButton
								disabled={isLoading}
								styles={{ width: "auto" }}
								onClick={onConfirm}
							>
								확인
							</CustomButton>
						</div>
					</Stack>
				</CustomCard>
			</Box>
		</Modal>
	);
}
