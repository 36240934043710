export const onDropDownSearchChange = (data, setFilterQuery) => {
	const { preValue, newValue } = data;
	setFilterQuery((preStateValue) => {
		if (!preStateValue) {
			return { ...newValue };
		} else {
			const temp = { ...preStateValue };
			if (preValue) {
				const [key] = Object.keys(preValue);
				delete temp[key];
			}
			return { ...temp, ...newValue };
		}
	});
};
