import React from "react";
import styled from "styled-components";

export default function CustomInputField({
  label,
  placeholder,
  direction = "column",
  value,
  type="text"
}) {
  return (
    <Layout direction={direction}>
      <Label>{label}</Label>
      <InputField type={type} style={{paddingLeft:"10px",paddingRight:"10px"}} value={value} placeholder={placeholder} />
    </Layout>
  );
}

const Layout = styled.div`
  display: flex;
  flex-direction: ${({ direction }) => direction};
  align-items: ${({ direction }) =>
    direction === "row" ? "center" : "flex-start"};
  gap: ${({ direction }) => (direction === "row" ? "30px" : "8px")};
  justify-content: ${({ direction }) =>
    direction === "row" ? "space-between" : "center"};
`;

const Label = styled.span`
  font-family: "Spoqa Han Sans Neo" !important;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #000000;

`;

const InputField = styled.input`
  width: 300px;
  height: 44px;
  border-radius: 10px;
  border: 1px solid #c1c1c1;

  ::placeholder {
    font-family: "Spoqa Han Sans Neo" !important;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    color: #c1c1c1;
  }
`;
