import {
  Checkbox,
  Grid,
  Link,
  MenuItem,
  Pagination,
  Stack,
} from "@mui/material";
import React, { useState } from "react";
import { SelectBox, TextFieldBox } from "../../components/CustomInput";
import TableContainer from "../../components/TableContainer";
import DepositProcessingModal from "../../modals/DepositProcessingModal";
import VendorDetailModal from "../../modals/VendorDetailModal";

export default function Vendors() {
  const options3 = [
    "10개씩 보기",
    "20개씩 보기",
    "30개씩 보기",
    "40개씩 보기",
    "50개씩 보기",
    "100개씩 보기",
  ];

  const [showModal, setShowModal] = useState(false);
  const [showModal2, setShowModal2] = useState(false);

  return (
    <Stack spacing={"35px"}>
      <Grid container justifyContent={"space-between"}>
        <Grid item xs={6}>
          <Grid container spacing="10px">
            <Grid item xs={4}>
              <SelectBox value="업체명">
                <MenuItem value="업체명">업체명</MenuItem>
              </SelectBox>
            </Grid>

            <Grid item xs={6}>
              <TextFieldBox placeholder="업체명을 입력해주세요." />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={4}>
          <Grid container spacing="10px">
            <Grid item xs={6}>
              <SelectBox value="엑셀 다운로드">
                <MenuItem value="엑셀 다운로드">엑셀 다운로드</MenuItem>
                <MenuItem value="예치금 내역 다운로드">
                  예치금 내역 다운로드
                </MenuItem>
              </SelectBox>
            </Grid>

            <Grid item xs={6}>
              <SelectBox value="업체명">
                {options3.map((item, index) => (
                  <MenuItem key={index} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </SelectBox>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <TableContainer
        headers={[
          <Checkbox />,
          "아니요",
          "유저아이디",
          "유저명",
          "업체명",
          "전화번호",
          "휴대폰 번호",
          "사업자 등록 번호",
          "입금처리",
          "예치금",
          "접수내역",
          "입금내역",
          "배송비 내역",
        ]}
        data={[1, 1, 1, 1, 1, 1, 1, 1].map((item, index) => [
          <Checkbox />,
          "100",
          <Link color="#000" onClick={() => setShowModal2(true)}>
            admin1
          </Link>,
          "홍길동",
          "엠비케이 엠비케이투",
          "010-1234-5678",
          "010-1234-5678",
          "264-81-01766	",
          <Link onClick={() => setShowModal(true)}>입금</Link>,
          "0원",
          <Link style={{ width: "200px" }}>접수내역</Link>,
          <Link>입금내역</Link>,
          <Link>배송비 내역</Link>,
        ])}
      />

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: 30,
        }}
      >
        <Pagination count={10} color="primary" shape="rounded" />
      </div>

      <DepositProcessingModal
        isOpen={showModal}
        onOpen={() => setShowModal(true)}
        onClose={() => setShowModal(false)}
      />

      <VendorDetailModal
        isOpen={showModal2}
        onOpen={() => setShowModal2(true)}
        onClose={() => setShowModal2(false)}
      />
    </Stack>
  );
}
