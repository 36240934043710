import { Grid, Stack, Typography } from "@mui/material";
import React, { useState, useEffect} from "react";
import styled from "styled-components";
import ShipterLogo from "../../../Common/assets/Images/ShipterLogo";
import CustomButton from "../../../components/CustomButton";
import CustomCard from "../../../components/CustomCard";
import TableContainer from "../../../components/TableContainer";
import { useNavigate } from "react-router-dom";
import { gql } from "@apollo/client/core";
import { APIClient } from "../../../utils/apiGraphql";
import Toaster from "../../../utils/Toaster";

export default function DeliveryTracking({ screens, setActiveScreen, noBack, shipmentTrackingData }) {
	const [ DUMMY_DATA, setDUMMY_DATA ] = useState([
		{ key: "Departure", value: "" },
		{ key: "Destination", value: "" },
		{ key: "Registration number", value: "" },
		{ key: "Shipping number", value: "" },
		{ key: "Order number", value: "" },
		{ key: "Sender", value: "" },
		{ key: "Consignee", value: "" },
		{ key: "Address", value: "" },
		{ key: "Contact number", value: "" },
	]);
	const [ trackShipments, setTrackShipments ] = useState([]);
	const navigate = useNavigate();

	useEffect(() => {
		if(shipmentTrackingData) {
			setDUMMY_DATA([
				{ key: "Departure", value: "Korea" },
				{ key: "Destination", value: shipmentTrackingData.shippingCountry.country_name_en },
				{ key: "Registration number", value: shipmentTrackingData.trackingNumber },
				{ key: "Shipping number", value: shipmentTrackingData.shippingNumber },
				{ key: "Order number", value: shipmentTrackingData.productInformation.orderNo1 },
				{ key: "Sender", value: shipmentTrackingData.senderInformation.name },
				{ key: "Consignee", value: shipmentTrackingData.payeeInformation.name },
				{ key: "Address", value: shipmentTrackingData.payeeInformation.streetAddress1+", "+shipmentTrackingData.payeeInformation.streetAddress2},
				{ key: "Contact number", value: shipmentTrackingData.payeeInformation.phoneNumber1 },
			])

			const Query = gql(`
				query trackShipment($requestShippmentId: Float!) {
					trackShipment(requestShippmentId:$requestShippmentId){
						date
					location
					event
					}
				}
			`)

			getTrackShipment(Query, shipmentTrackingData.payeeInformation.requestShippingId);
		}
	}, [shipmentTrackingData])

	const getTrackShipment = async (Query, shipmentId) => {
		try {
			const {
				data: { trackShipment },
			} = await APIClient.query({
				query: Query,
				variables: {
					requestShippmentId: shipmentId
				},
			});

			console.log("data", trackShipment);
			
			if(trackShipment.length) {
				setTrackShipments(trackShipment);
			}
			else {
				Toaster("error", "No tracking available")
			}

		}
		catch (e) {
			console.log(e);
			Toaster("error", "Couldn't get shipments");

		}
	}

	return (
		<Stack>
			<Grid
				container
				flexDirection={"column"}
				spacing="20px"
				sx={{ height: "100%" }}
			>
				<Grid alignSelf={"center"} marginBottom={"20px"} item xs={8}>
					<ShipterLogo />
				</Grid>
				{!noBack && (
					<Grid
						container
						alignItems={"center"}
						justifyContent="space-between"
						item
						xs={8}
					>
						<Grid item xs={4}>
							<CustomButton
								styles={{
									background: "white",
									color: "#000",
									width: 120,
									height: 44,
									border: "1px solid #E5E5E5",
									gap: "20px",
									filter: "drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.25))",
								}}
								onClick={() => navigate(-1)}
							>
								이전 페이지
							</CustomButton>
						</Grid>
					</Grid>
				)}
				<Grid container justifyContent={"space-between"} mt={3} xs={12}>
					<Grid item xs={6}>
						<TableContainer
							isLoading={false}
							requestCompleted
							headers={["날짜", "위치", "내용"]}
							data={trackShipments.map((item, index) => [
								item.date ? item.date : "no date available",
								item.location ? item.location : "no location",
								item.event ? item.event : "no event"
							])}
						/>
					</Grid>
					<Grid item xs={5} style={{width: "1000px"}}>
						<CustomCard border>
							{DUMMY_DATA.map(({ key, value }, idx) => (
								<CardRow hasBorder={idx < DUMMY_DATA.length - 1}>
									<CardLeft>{key}</CardLeft>
									<CardRight>{value}</CardRight>
								</CardRow>
							))}
						</CustomCard>
					</Grid>
				</Grid>
			</Grid>
		</Stack>
	);
}

const CardRow = styled.div`
	width: 100%;
	height: 45px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 25px;
	border-bottom: ${({ hasBorder }) =>
		hasBorder ? "1px solid #E5E5E5" : "0px"};
`;

const CardLeft = styled.span`
	font-family: "Spoqa Han Sans Neo";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 19px;
	/* identical to box height */

	color: #000000;
`;

const CardRight = styled.span`
	font-family: "Spoqa Han Sans Neo";
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
	line-height: 19px;
	/* identical to box height */

	color: #000000;
`;
