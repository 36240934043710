import { Box, Divider, Modal, Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import React, { useEffect } from "react";
import CustomButton from "../components/CustomButton";
import CustomCard from "../components/CustomCard";
// import TableContainer from "../components/TableContainer";
import * as XLSX from 'xlsx-js-style'
import Icons from "../utils/icon";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		fontWeight: "bold",
		backgroundColor: "#F8F8FA",
		color: "rgba(0, 0, 0, 0.6)",
		whiteSpace: "nowrap",
	},
	[`&.${tableCellClasses.body}`]: {
		// backgroundColor: "white",
		fontSize: 14,
		whiteSpace: "nowrap",
		border: "1px solid #E5E5E5",
		color: "inherit",
	},
}));

const StyledTableRow = styled(TableRow)`
	// "&:nth-of-type(odd)": {
	// 	backgroundColor: theme.palette.action.hover,
	// },
	background-color: ${(props) => props.bg && props.bg};
	color: ${(props) =>
		props.bg
			? "rgba(255, 255, 255, 0.9) !important"
			: "rgba(0, 0, 0, 0.87) !important"};
	// hide last border
	// "&:last-child td, &:last-child th": {
	// 	border: 0,
	// },
`;

export default function BulkShippingModal({
	isOpen,
	onClose,
	onConfirm,
	isLoading = false,
	title = "",
	description = "",
	lastBtn,
	bulkShippingFailureReason,
	// handleDelete,
	...props
}) {
	const header = ["엑셀 행", "주문번호", "실패사유"];

	const handleExcelDownload= () =>{
		if (bulkShippingFailureReason && bulkShippingFailureReason.length){
			const wb= XLSX.utils.book_new()
			const ws= XLSX.utils.json_to_sheet(bulkShippingFailureReason)
			XLSX.utils.book_append_sheet(wb, ws, 'Report')
			XLSX.writeFile(wb,'report.xlsx');
		}
	}

	return (
		<Modal
			open={isOpen}
			onClose={onClose}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Box
				style={{
					padding: 20,
					overflowX: "hidden",
					minHeight: "100vh",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				<CustomCard>
					<Stack spacing={"20px"} width={780} p="20px">
						<Stack direction="row" alignItems={"center"} spacing="10px">
							<Typography fontSize={"20px"} fontWeight="700">
								{title}
							</Typography>
						</Stack>

						<div>
							<Typography
								lineHeight={"0.5"}
								fontSize={"14px"}
								color="rgba(0,0,0,0.6)"
								component={"div"}
							>
								{description}
							</Typography>
						</div>

						{/* <Divider /> */}
						<div style={{ position: "relative" }}>
							{/* <TableContainer
								{...{ isLoading: false, requestCompleted: true }}
								headers={header}
								data={
									bulkShippingFailureReason &&
									bulkShippingFailureReason?.map((item, index) => {
										return [
											index + 1,
											item.orderNo,
											<div style={{ color: "red" }}>{item.reason}</div>,
										];
									})
								}
							/> */}

							<TableContainer component={Paper} sx={{ maxHeight: 500 }}>
								<Table
									sx={{
										minWidth: 700,
										border: "1px solid rgba(224, 224, 224, 1)",
									}}
									aria-label="customized table"
									stickyHeader
								>
									<TableHead>
										<TableRow>
											{header?.map((item, index) => (
												<StyledTableCell align="center">{item}</StyledTableCell>
											))}
										</TableRow>
									</TableHead>

									{bulkShippingFailureReason && (
										<TableBody>
											{bulkShippingFailureReason?.map((item, index) => (
												<StyledTableRow
													key={index}
													bg={item.status === "started_delivery" && "#5b8ff7"}
													// "#8ebedc"
												>
													<StyledTableCell align="center">
														{item.line}
													</StyledTableCell>
													<StyledTableCell align="center">
														{item.orderNumber}
													</StyledTableCell>

													<StyledTableCell align="center">
														<div style={{ color: "red" }}>{item.reason}</div>
													</StyledTableCell>
												</StyledTableRow>
											))}
										</TableBody>
									)}
								</Table>
							</TableContainer>
						</div>

						<div
							style={{
								display: "flex",
								justifyContent: "flex-end",
								gap: "20px",
							}}
						>
							<CustomButton
								onClick={onClose}
								styles={{
									background: "#F8F8FA",
									color: "#000",
									width: "auto",
								}}
							>
								닫기
							</CustomButton>
							<CustomButton
								styles={{ width: "auto" }}
								onClick={handleExcelDownload}
							>
								엑셀파일 출력
							</CustomButton>
						</div>
					</Stack>
				</CustomCard>
			</Box>
		</Modal>
	);
}
