import React from "react";
import { Typography } from "@mui/material";

export default function CustomInputLabel({ name,required }) {
  return (
    <Typography
      component="h6"
      variant="h6"
      sx={{
        fontSize: "16px",
        fontWeight: "600",
        lineHeight: "21px",
        color: "2e2e2e",
        mb: "8px",
      }}
    >
      {name } {required ? <span style={{color:'red'}}>*</span> :""}
    </Typography>
  );
}
