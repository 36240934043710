import * as React from "react";

import { useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";

import * as Yup from "yup";
import Link from "@mui/material/Link";
import { CircularProgress } from "@mui/material";
import { Button } from "ra-ui-materialui";
import ErrorIcon from "@mui/icons-material/Error";
import { Formik, Form } from "formik";
import OutlinedInput from "@mui/material/OutlinedInput";

import { Box, Grid, Card, CardContent, CardHeader } from "@mui/material";

import { useNavigate } from "react-router-dom";
import { useLogin, useNotify, Notification } from "react-admin";
import CustomPasswordInput from "./CustomPasswordInput";
import CustomInputLabel from "./CustomInputLabel";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  console.log({ props });

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 6 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function LoginPageTabs({ theme, changeForm }) {
  const [value, setValue] = React.useState(0);
  const [loading, setLoading] = useState(true);
  const login = useLogin();
  const navigate = useNavigate();
  const notify = useNotify();
  const [trackingNumber, setTrackingNumber] = useState("");
  const [trackingError, setTrackingError] = useState(true);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [initialValues, setInitialValues] = useState({
    password: "",
    username: "",
  });
  const validationSchema = () => {
    return Yup.object().shape({
      userID: Yup.string().required(),
      password: Yup.string().required(),
      email: Yup.string().email().required(),
      username: Yup.string().required(),
      customer_name: Yup.string().required(),
      company_registration_number: Yup.string().required(),
      company_name_en: Yup.string().required(),
      company_name_kr: Yup.string().required(),
      phone_number: Yup.string().required(),
    });
  };

  const loginValidationSchema = () => {
    return Yup.object().shape({
      password: Yup.string().required(),
      username: Yup.string().required(),
    });
  };

  const handleLoginSubmit = (values) => {
    login(values)
      .then(() => {
        navigate(0);
      })
      .catch((error) => {
        console.log(error);
        notify("아이디 패스워드가 일치하지 않거나 가입 심사 대기중입니다.");
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("track", trackingNumber);
  };

  const handleSubmit2 = (e) => {
    e.preventDefault();

    navigate(`/tracking?no=${trackingNumber}`, { replace: true });
  };

  return (
    <Box
      sx={{
        width: "478px",
        paddingLeft: 12,
        paddingRight: 12,
        // maxHeight: "558px",
      }}
      xs={12}
      sm={12}
    >
      <Box sx={{}}>
        <Tabs
          variant="fullWidth"
          value={value}
          onChange={handleChange}
          aria-label="basic tabs "
          indicatorColor="#ECF2FE"
          sx={{ paddingLeft: "60px", paddingRight: "60px" }}
        >
          <Tab
            label="로그인"
            sx={{
              fontSize: "24px",
              fontWeight: "700",
              lineHeight: "33px",
              Align: "left",
              borderRadius: "8px",
            }}
            {...a11yProps(0)}
          />
          <Tab
            label="배송 조회"
            {...a11yProps(1)}
            sx={{
              fontSize: "24px",
              fontWeight: "700",
              lineHeight: "33px",
              Align: "left",
              borderRadius: "8px",
            }}
          />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <Typography
          component="p"
          variant="p"
          sx={{
            fontSize: "16px",
            fontWeight: "400",
            lineHeight: "21px",
            color: "#5D6D73",
            mt: "0px",
          }}
        >
          로그인 정보를 입력해주세요.
        </Typography>
        <Box sx={{ mt: 0 }}>
          <Formik
            initialValues={initialValues}
            onSubmit={handleLoginSubmit}
            validationSchema={loginValidationSchema}
          >
            {({ values, handleChange, errors, handleSubmit, submitCount }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <Box
                    display={"flex"}
                    flexDirection={"column"}
                    sx={{
                      marginTop: "30px",
                      justifyContent: "left",
                      marginLeft: 0,
                      paddingLeft: 0,
                    }}
                  >
                    <CustomInputLabel name="아이디" />
                    <OutlinedInput
                      error={!!submitCount && !!errors.username}
                      size="small"
                      placeholder="아이디를 입력해주세요.."
                      id="outlined-adornment-username"
                      notched={false}
                      value={values.username}
                      onChange={handleChange}
                      name={"username"}
                      color="primary"
                      sx={{
                        mb: "24px",
                        backgroundColor: "white",
                      }}
                    />

                    <CustomInputLabel name="비밀번호" />
                    <CustomPasswordInput
                      error={!!submitCount && !!errors.password}
                      placeholder="비밀번호를 입력해주세요."
                      inputValue={values.password}
                      onChange={handleChange}
                      name={"password"}
                    />

                    <Box
                      xs={12}
                      md={6}
                      sm={12}
                      display="flex"
                      flexDirection="row-reverse"
                    >
                      {/* <Link href="" variant="body2"> */}
                      <Typography
                        component="p"
                        variant="p"
                        onClick={() => {
                          console.info("I'm a button.");
                          changeForm("ForgetForm");
                        }}
                        sx={{
                          fontSize: "16px",
                          fontWeight: "600",
                          lineHeight: "20px",
                          color: "427df3",
                          mt: "24px",
                          color: "#427DF3",
                          cursor: "pointer",
                        }}
                      >
                        비밀번호를 잃어버리셨나요?
                      </Typography>
                      {errors.name && errors.name}
                      {/* </Link> */}
                    </Box>
                    <Link to={"/delivery-tracking"}>
                      <Button
                        variant="contained"
                        type={"submit"}
                        sx={{
                          paddingTop: "15px",
                          paddingBottom: "15px",
                          marginTop: "58px",
                          backgroundColor: "#427DF3",
                          borderRadius: "8px",
                          width: "382px",
                          marginLeft: "auto",
                          marginRight: "auto",
                        }}
                      >
                        {!loading ? (
                          <CircularProgress size={20} color="primary" />
                        ) : (
                          <Typography
                            component="p"
                            variant="p"
                            sx={{
                              fontSize: "16px",
                              fontWeight: "700",
                              lineHeight: "24px",
                              color: "#ffffff",
                            }}
                          >
                            로그인
                          </Typography>
                        )}
                      </Button>
                    </Link>
                  </Box>
                </Form>
              );
            }}
          </Formik>
        </Box>

        <Box sx={{}}>
          <Typography
            component="p"
            variant="button"
            onClick={() => {
              changeForm("TermsAndConditions");
            }}
            sx={{
              fontSize: "16px",
              fontWeight: "600",
              lineHeight: "20px",
              color: "#427DF3",
              marginTop: "24px",
              textAlign: "center",
              cursor: "pointer",
            }}
          >
            회원가입
          </Typography>
        </Box>
      </TabPanel>

      <TabPanel value={value} index={1}>
        <Grid container>
          <Grid item xs md={12}>
            <Box sx={{ mt: 0 }}>
              <form onSubmit={handleSubmit2}>
                <Box
                  md={12}
                  display={"flex"}
                  flexDirection={"column"}
                  sx={{ width: "350px" }}
                >
                  <Typography
                    component="p"
                    variant="p"
                    sx={{
                      fontSize: "16px",
                      fontWeight: "400",
                      lineHeight: "21px",
                      color: "#5D6D73",
                      mt: "0px",
                    }}
                  >
                    {/* 계속하려면 로그인하세요. */}
                  </Typography>
                  <CustomInputLabel name="배송 조회" />
                  <OutlinedInput
                    size="small"
                    id="outlined-adornment-username"
                    placeholder={"배송번호 또는 접수번호를 입력해주세요"}
                    type={"text"}
                    value={trackingNumber}
                    notched={false}
                    onChange={(e) => setTrackingNumber(e.target.value)}
                    OutlinedInput={false}
                    required
                    color="primary"
                    sx={{
                      mb: "24px",
                      backgroundColor: "white",
                    }}
                  />
                  <Button
                    variant="contained"
                    type={"submit"}
                    sx={{
                      paddingTop: "15px",
                      paddingBottom: "15px",
                      marginTop: "58px",
                      backgroundColor: "#427DF3",
                      borderRadius: "8px",
                    }}
                  >
                    {!loading ? (
                      <CircularProgress size={20} color="primary" />
                    ) : (
                      <Typography
                        component="p"
                        variant="p"
                        sx={{
                          fontSize: "16px",
                          fontWeight: "700",
                          lineHeight: "24px",
                          color: "ffffff",
                        }}
                      >
                        배송 추적하기
                      </Typography>
                    )}
                  </Button>
                </Box>
              </form>
            </Box>
          </Grid>
        </Grid>
      </TabPanel>
      {/* <Notification /> */}
    </Box>
  );
}
