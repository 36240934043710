import React from "react";
import {
	Page,
	Text,
	View,
	Document,
	StyleSheet,
	Font,
	Image,
} from "@react-pdf/renderer";

import shipter from "../../Common/assets/Images/shipterLogoHi.jpg";
import moment from "moment";
import PackageTable from "./PackageTable";

export const GPSLabel = (props) => {
	const { barcode, selectedRequests } = props;
	let { tableData, ...item1 } = selectedRequests;
	console.log("GPS Label",props);

	const calculateTotal = (products) => {
		let total = 0;
		products.forEach((item) => {
			let product = parseInt(item.quantity) * parseInt(item.unitPrice);

			total = total + product;
		});

		return total;
	};

	return (
		<>
			{/*<Document>*/}
			{/* {selectedRequests?.map((item, index1) => {
				return item.map((item1, index2) => {
					return ( */}
			{tableData && <PackageTable tableData={tableData} />}
			<Page size="A4" style={styles.page}>
				<View style={styles.sec1}>
					<View style={styles.sec11}>
						<Text>Packing Slip</Text>
					</View>
					<View style={styles.sec12}>
						<Text>{item1?.shippingCountry?.code}</Text>
					</View>
				</View>
				<View style={styles.sec2}>
					<Text style={styles.pad}>ID: {item1?.user?.company_name_en}</Text>
					<Text style={styles.pad}>Date: {moment().format("YYYY.MM.DD")}</Text>
					<Text style={styles.pad}>
						SKU: {item1?.productInformation?.orderNo1}
					</Text>
				</View>

				<View style={styles.sec3}>
					<View style={styles.colItem}>
						{item1.productInformation?.productInformation
							.slice(0, 5)
							.map((item2, index) => {
								return (
									<View style={styles.sec5a}>
										<View style={styles.sec51a}>
											<Text style={styles.bold}>item {index + 1}:</Text>
											<Text style={styles.red}>
												{" "}
												{item2.productName.length > 25
													? item2.productName.slice(0, 25).concat("...")
													: item2.productName}
											</Text>
										</View>
										<Text style={styles.red}>{item2.quantity}pc</Text>
									</View>
								);
							})}
					</View>
				</View>

				<View>
					<Text style={styles.red2}>{item1?.payeeInformation?.stateCode === "CA" ? "CA@" : item1?.payeeInformation?.stateCode}</Text>
				</View>
				<View style={styles.ImageRow}>
					<View style={styles.ImageCol}>
						
						<Image src={barcode} width={"100%"} height={"100px"} />	
					</View>
				</View>
			</Page>
			{/* );
				});
			})} */}
			{/*</Document>*/}
		</>
	);
};

const fRow = {
	diaplay: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
};
const fCol = {
	diaplay: "flex",
	flexDirection: "column",
	alignItems: "center",
	justifyContent: "center",
};

Font.register({
	family: "SpoqaHanSans",
	src: "https://cdn.jsdelivr.net/gh/spoqa/spoqa-han-sans@01ff0283e4f36e159ffbf744b36e16ef742da6d8/Subset/SpoqaHanSans/SpoqaHanSansLight.ttf",
});
const styles = StyleSheet.create({
	page: {
		border: "2px solid #000",
		fontFamily: "SpoqaHanSans",
	},
	sec1: {
		display: "flex",
		flexDirection: "row",
		width: "100%",
	},
	sec11: {
		...fCol,
		backgroundColor: "white",
		padding: 15,
		fontSize: 30,
		color: "black",
		width: "50%",
		borderBottom: "2px solid #000",
		borderRight: "2px solid #000",
	},
	sec12: {
		...fCol,
		backgroundColor: "white",
		width: "50%",
		padding: 15,
		color: "black",
		fontSize: 30,
		fontWeight: 700,
		borderBottom: "2px solid #000",
		borderLeft: "2px solid #000",
	},
	red: {
		color: "black",
		fontSize: 16,
		paddingLeft: 3,
	},
	red2: {
		color: "black",
		fontSize: 40,
		padding: 30,
		fontWeight: 800,
		textAlign: "center"
	},
	bold: {
		color: "black",
		fontSize: 18,
		fontWeight: "bold",
	},
	col: {
		...fCol,
	},
	colItem: {
		...fCol,
		width: "100%",
		// minHeight: 200,
	},
	sec2: {
		...fCol,
		color: "black",
		fontSize: 20,
		fontWeight: 700,
		padding: 20,
		minHeight: 130,
		alignItems: "flex-start",
		justifyContent: "flex-start",
		borderBottom: "2px solid #000",
	},

	sec3: {
		...fCol,
		alignItems: "flex-start",
		justifyContent: "flex-start",
		minHeight: 270,
		padding: 15,
		width: "100%",
		borderBottom: "2px solid #000",
	},

	sec5a: {
		...fRow,
		justifyContent: "space-between",
		alignItems: "flex-start",
		padding: 8,
		width: "100%",
	},

	sec51a: {
		...fRow,
		alignItems: "center",
	},

	sec3a: {
		...fRow,
	},
	sec4: {
		...fCol,
		minHeight: 170,
		alignItems: "flex-start",
		justifyContent: "flex-start",
		paddingLeft: 15,
		paddingRight: 15,
		borderBottom: "2px solid #000",
	},
	sec5: {
		...fRow,
		width: "100%",
	},

	ImageRow: {
		...fRow,
		padding: 10,
		// paddingTop: 150,
	},
	ImageCol: {
		...fCol,
	},
	pad: {
		paddingTop: 5,
	},
});
