import { useDataProvider, useResourceContext } from "react-admin";
import Toaster from "../utils/Toaster";
import React from "react";

function UpdateHOC({
  children: Children,
  _resource,
  handleClose = () => {},
  ...rest
}) {
  const dataProvider = useDataProvider();
  const resource = useResourceContext();
  const handleSubmit = async (values, formikUtils, showToast = true) => {
    try {
      return await new Promise((resolve, reject) => {
        dataProvider
          .update(_resource || resource, {
            ...(values?.updateInput?.id && { id: values.updateInput.id }),
            data: values,
          })
          .then(({ data }) => {
            // debugger;
            if (showToast) {
              Toaster("Success", "업데이트가 성공적으로 완료되었습니다");
            }
            if (formikUtils) {
             const {resetForm}= formikUtils
              resetForm();
            }

            handleClose();
            resolve({ data, reason: "update" });
          })
          .catch((e) => reject(e));
      });
    } catch (e) {
      Toaster("error", e.message);
      throw e;
    }
  };
  return <Children handleSubmit={handleSubmit} {...{ handleClose, ...rest }} />;
}

export default UpdateHOC;
