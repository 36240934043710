import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import styled from "styled-components";
import DeliveryRequestDetails from "./DeliveryRequestDetails";
import DeliveryRequestUserDetails from "./DeliveryRequestUserDetails";
import DeliveryTracking from "./DeliveryTracking";
import { DeliveryRequestContextProvider } from "../../../Context/DeliveryRequestContext";

const SCREEN_NAMES = {
	SHIPMENT_REQUEST: "SHIPMENT_REQUEST",
	DELIVERY_TRACKING: "DELIVERY_TRACKING",
};

export function DeliveryRequest() {
	const [activeScreen, setActiveScreen] = React.useState(
		SCREEN_NAMES.SHIPMENT_REQUEST
	);
	const [requestShippingFilter, setRequestShippingFilter] = React.useState({});
	const [dateFilter, setDateFilter] = React.useState({});
	const [requestFilter, setRequestFilter] = React.useState({ isDraft: false });

	React.useEffect(() => {
		return () => {
			activeScreen !== SCREEN_NAMES.SHIPMENT_REQUEST &&
				setActiveScreen(SCREEN_NAMES.SHIPMENT_REQUEST);
		};
	}, []);

	useEffect(() => {
		if (requestShippingFilter.name && dateFilter.createdAt) {
			setRequestFilter({
				user123company_name_kr: requestShippingFilter.name,
				isDraft: false,
				createdAt: dateFilter.createdAt,
			});
		} else if (requestShippingFilter.name && dateFilter.processReleaseDate) {
			setRequestFilter({
				user123company_name_kr: requestShippingFilter.name,
				isDraft: false,
				processReleaseDate: dateFilter.processReleaseDate,
			});
		} else if (requestShippingFilter.name) {
			setRequestFilter({
				user123company_name_kr: requestShippingFilter.name,
				isDraft: false,
			});
		}
	}, [
		requestShippingFilter.name,
		dateFilter.createdAt,
		dateFilter.processReleaseDate,
	]);

	return (
		<DeliveryRequestContextProvider>
			{activeScreen === SCREEN_NAMES.SHIPMENT_REQUEST ? (
				<Grid container spacing="20px" sx={{ height: "100%" }}>
					<Grid item xs={3.2}>
						<DeliveryRequestUserDetails
							setRequestShippingFilter={setRequestShippingFilter}
							requestShippingFilter={requestShippingFilter}
							setDateFilter={setDateFilter}
							dateFilter={dateFilter}
						/>
					</Grid>
					<Grid item xs={0.5} container sx={{ justifyContent: "center" }}>
						<Divider />
					</Grid>
					<Grid item xs={8.3}>
						<DeliveryRequestDetails
							screens={SCREEN_NAMES}
							setActiveScreen={setActiveScreen}
							filter={requestFilter}
						/>
					</Grid>
				</Grid>
			) : (
				<DeliveryTracking
					setActiveScreen={setActiveScreen}
					screens={SCREEN_NAMES}
				/>
			)}
		</DeliveryRequestContextProvider>
	);
}

const Divider = styled.div`
	border-left: 1px solid #00000022;
	padding: 0;
`;
