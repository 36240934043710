import writeXlsxFile from "write-excel-file";
import { gql } from "@apollo/client";
import { APIClient } from "../../../../utils/apiGraphql";

const getlength = (number) => number.toString().length;

const getData =
    gql(`
        query allMasterManagements(
        $page: Float
        $perPage: Float
        $filter: MasterManagementFilterInput
        ) {
        items: allMasterManagements(
            page: $page
            perPage: $perPage
            filter: $filter
        ) {
            id
            masterNumber

            bags {
            id
            name
            shippingCountry {
                id
                __typename
            }
            deliveryType {
                id
                __typename
            }
            deliveryService {
                id
                shippingType
                apiType
                shippingWeight
                shippingTypeId
                createdAt
                updatedAt
                __typename
            }
            masters {
                id
                __typename
            }
            howToPayCustomDuties
            dgPackagingManagementCode
            numberOfUses
            receptionLists {
                id
                shippingNumber
                trackingNumber
                user {
                    id
                    userID
                    customer_name
                    phone_number
                    address_1
                    address_2
                    code
                    english_address_state
                    english_address_city
                    english_address_street
                    english_address_detail
                    english_address_full_address
                    english_address_zip_code
                    email
                }
                productInformation {
                    productInformation {
                        id
                        productName
                        quantity
                        unitPrice
                        sku
                        hscode
                    }
                    currency
                    totalPriceAmount
                }
                payeeInformation {
                    euioss
                    shippingCountry {
                        id
                        name
                        code
                    }
                }
                __typename
            }
            weight
            usedWeight
            updatedAt
            createdAt
            __typename
            }
            totalBags
            totalRequests
            deleted
            createdAt
            updatedAt
            __typename
        }
        total: _allMasterManagementsMeta(
            page: $page
            perPage: $perPage
            filter: $filter
        ) {
            count
            __typename
        }
        }`
    );

//Export xlsx with multiple table in one sheet
const exportLTPostXlsx = async (ids) => {
    //First fetch the desired data for the excel sheets using the ids
    let data = [];

  /**
   * Creating header
   */
  let header = [
    {
      value: "WAY BILL",
      backgroundColor: "#f1d8be",
      align: "center",
    },
    {
      value: "PACKAGE ID",
      backgroundColor: "#f1d8be",
      align: "center",
    },
    {
      value: "PARCEL ID",
      backgroundColor: "#f1d8be",
      align: "center",
    },
    {
      value: "CLIENT ID",
      backgroundColor: "#f1d8be",
      align: "center",
    },
    {
      value: "NAME",
      backgroundColor: "#f1d8be",
      align: "center",
    },
    {
      value: "ZIP",
      backgroundColor: "#f1d8be",
      align: "center",
    },
    {
      value: "REGION",
      backgroundColor: "#f1d8be",
      align: "center",
    },
    {
      value: "CITY",
      backgroundColor: "#f1d8be",
      align: "center",
    },
    {
      value: "ADDRESS",
      backgroundColor: "#f1d8be",
      align: "center",
    },
    {
      value: "PHONE NUMBER",
      backgroundColor: "#f1d8be",
      align: "center",
    },
    {
      value: "EMAIL",
      backgroundColor: "#f1d8be",
      align: "center",
    },
    {
      value: "COUNTRY",
      backgroundColor: "#f1d8be",
      align: "center",
    },
    {
      value: "SKU CODE",
      backgroundColor: "#f1d8be",
      align: "center",
    },
    {
      value: "DESCRIPTION OF CONTENT",
      backgroundColor: "#f1d8be",
      align: "center",
    },
    {
      value: "HS CODE",
      backgroundColor: "#f1d8be",
      align: "center",
    },
    {
      value: "QUANTITY",
      backgroundColor: "#f1d8be",
      align: "center",
    },
    {
      value: "WEIGHT PER ITEM,KG",
      backgroundColor: "#f1d8be",
      align: "center",
    },
    {
      value: "WEIGHT PER PARCEL,KG",
      backgroundColor: "#f1d8be",
      align: "center",
    },
    {
      value: "CURRENCY",
      backgroundColor: "#f1d8be",
      align: "center",
    },
    {
      value: "PRICE PER ITEM",
      backgroundColor: "#f1d8be",
      align: "center",
    },
    {
      value: "PRICE PER PARCEL",
      backgroundColor: "#f1d8be",
      align: "center",
    },
    {
      value: "TAX TYPE",
      backgroundColor: "#f1d8be",
      align: "center",
    },
    {
      value: "TAX IDENTIFICATION",
      backgroundColor: "#f1d8be",
      align: "center",
    },
    {
        value: "MAIL TYPE",
        backgroundColor: "#f1d8be",
        align: "center",
      }
  ];
  data.push(header);

    const res = await APIClient.query({
        query: getData,
        variables: {
            filter: {
                id: ids[0]
            }
        },
    });

    console.log("response",res);
    var mailType = "";
    

    if(res.data && res.data.items) {
        // if(res.data.items.length == 1) {
            const value = res.data.items[0];
            let masterNumber = "";
            let masterCreatedAt = "";
            let uploadQuantity = 0;

            for(let i=0; i < value.bags.length; i++) {
                const bag = value.bags[i];
                
                const totalWeight = bag.usedWeight;
                masterNumber = value.masterNumber;
                masterCreatedAt = value.createdAt;
                

                const receptionLists = bag.receptionLists;
                
                receptionLists.forEach((receptionList) => {
                                       
                    uploadQuantity++;
                    let data1 = [];
                    data1.push({value: value.masterNumber, align: "center"}) //Way bill == Master Number
                    data1.push({value: bag.name, align: "center"})  //Package ID === bag number
                    data1.push({value: receptionList.shippingNumber, align: "center"}); //Parcel ID === Shipping number 

                    if((receptionList.shippingNumber).indexOf("LB") !== 1) {
                        mailType = "Prime";
                    }
                    else if((receptionList.shippingNumber).indexOf("RF") !== 1) {
                        mailType = "Registered";
                    }
                    else if((receptionList.shippingNumber).indexOf("RV") !== 1) {
                        mailType = "Registered";
                    }
                    else if((receptionList.shippingNumber).indexOf("CE") !== 1) {
                        mailType = "Premium";
                    }
                    else if((receptionList.shippingNumber).indexOf("EV") !== 1) {
                        mailType = "EMS";
                    }

                    data1.push({value: receptionList.trackingNumber, align: "center"}); //Client Id === Tracking Number
                    data1.push({value: receptionList.user.customer_name, align: "center"}); //Client Id === Tracking Number
                    data1.push({value: receptionList.user.english_address_zip_code, align: "center"}); //ZIP === postal code 
                    data1.push({value: receptionList.user.english_address_state, align: "center"}); //Region === state
                    data1.push({value: receptionList.user.english_address_city, align: "center"}); //City
                    data1.push({value: receptionList.user.address_1+receptionList.user.address_2, align: "center"}); //address
                    data1.push({value: receptionList.user.phone_number, align: "center"}); //phone number
                    data1.push({value: receptionList.user.email, align: "center"}); //email
                    const countryCode = receptionList.payeeInformation.shippingCountry.code;
                    data1.push({value: countryCode, align: "center"}); //country code

                    if(receptionList.productInformation && receptionList.productInformation.productInformation) {
                        const rProductInfos = receptionList.productInformation;
                        const productInfos = rProductInfos.productInformation;

                        // productInfos.forEach((productInfo) => {
                            if(productInfos.length) {
                                const productInfo = productInfos[0];
                                data1.push({value: productInfo.sku, align: "center"}); //SKU 
                                data1.push({value: productInfo.productName, align: "center"}); //Description of content === item name
                                data1.push({value: productInfo.hscode, align: "center"}); //hs code
                                data1.push({value: productInfo.quantity, align: "center"}); //Quantity
                                data1.push({value: (totalWeight/productInfo.quantity).toFixed(2), align: "center"}); //Weight per item (Q)
                                data1.push({value: totalWeight, align: "center"}); //Weight per parcel (R)
                                data1.push({value: rProductInfos.currency, align: "center"}); //Currency (S)
                                data1.push({value: productInfo.unitPrice, align: "center"}); //price per item (T)
                                data1.push({value: rProductInfos.totalPriceAmount, align: "center"}); //total price (U)
                                let euioss = receptionList.payeeInformation.euioss;
                                let isEUIOUSS = false;

                                if(euioss && euioss !== "") {
                                    if(countryCode === "AT" || countryCode === "BE" || countryCode === "DE" || 
                                    countryCode === "ES" || countryCode === "FR" || countryCode === "FI" || 
                                    countryCode === "GR" || countryCode === "IT" || countryCode === "LU" || 
                                    countryCode === "LV" || countryCode === "NL" || countryCode === "PL" || 
                                    countryCode === "PT" || countryCode === "SE" || countryCode === "SI" || 
                                    countryCode === "SK") {
                                        isEUIOUSS = true;
                                    }
                                }

                                if(isEUIOUSS) {
                                    data1.push({value: "IOSS", align: "center"});
                                    data1.push({value: euioss, align: "center"});
                                }
                                else {
                                    data1.push({value: "DDU", align: "center"});
                                }

                                data1.push({value: mailType, align: "center"});
                            }       
                        // })
                    }
                    data.push(data1);
                })
            }

            /**
                * Columns
            */
            const columns = [];
            [...Array(23)].forEach((d) => {
                columns.push({
                width: 20,
                });
            });

            let uploadQuantityString = "";
            if(getlength(uploadQuantity) === 1) {
                uploadQuantityString = "00"+(uploadQuantity.toString());
            }
            else if(getlength(uploadQuantity) === 2) {
                uploadQuantityString = "0"+(uploadQuantity.toString());
            }
            else {
                uploadQuantityString = (uploadQuantity.toString());
            }

            writeXlsxFile(data, {
                columns,
                fileName: `H3_POST_Match_${uploadQuantityString}_${masterNumber}_${masterCreatedAt}.xlsx`,
            });
            console.log(data);
        // }
        
    }


};

export default exportLTPostXlsx;