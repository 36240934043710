import {
	Page,
	Text,
	View,
	Document,
	StyleSheet,
	Image,
	Font,
} from "@react-pdf/renderer";

export default function NLPostLabel(props) {
	return (
		<Document>
			<Page style={styles.body} size={"A6"}>
				<View style={styles.mainView}>
					<View style={styles.sec1}>
						<Text style={styles.firstFixedText}>hnk</Text>
						<Text style={styles.secondFixedText}>BR (barazil)</Text>
					</View>
					<View style={styles.barCodeSec}>
						<Image src={props.barcode} />
					</View>
					<View>
						<Text style={styles.bottomText}>{`NLHNK${props.bagNo}`}</Text>
					</View>
				</View>
			</Page>
		</Document>
	);
}

const styles = StyleSheet.create({
	body: {
		paddingTop: 35,
		paddingBottom: 65,
		paddingHorizontal: 35,
	},

	mainView: {
		border: "2px solid #C6CFDF",
		borderRadius: 24,
		padding: 5,
		textTransform: "uppercase",
	},
	sec1: {
		position: "relative",
		minHeight: "15%",
	},
	firstFixedText: {
		position: "absolute",
		left: 10,
		top: 10,
		fontSize: 12,
	},
	secondFixedText: {
		position: "absolute",
		left: "50%",
		transform: "translateX(-50%)",
		fontSize: 16,
		top: 15,
	},
	bottomText: {
		textAlign: "center",
		fontSize: 12,
	},
	barCodeSec: {
		margin: "0 15 10 15",
	},
});
