import CreateHOC from "../../SharedComponents/CreateHOC";
import { Stack } from "@mui/material";
import FileUpload from "../../components/FileUpload";
import CustomInput from "../../components/CustomInput";
import React, { useEffect, useState } from "react";
import CustomButton from "../../components/CustomButton";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useRedirect } from "react-admin";
import { useGetById } from "../../utils/utils";
import { useLocation } from "react-router-dom";
import UpdateHOC from "../../SharedComponents/UpdateHOC";
import { useNavigate } from "react-router-dom";
import FileUploadMultiple from "../../components/FileUploadMultiple";

function CreateNotice(props) {
	const [editMode, setEditMode] = useState(false);
	return React.createElement(editMode ? UpdateHOC : CreateHOC, {
		...props,
		setEditMode,
		editMode,
		children: CreateNoticeContainer,
	});
}

export default CreateNotice;

const CreateNoticeContainer = ({ editMode, setEditMode, ...hocProps }) => {
	const navigate = useNavigate();
	const [initialValues, setInitialValues] = useState({
		title: "",
		description: "",
		attachment: null,
	});
	const [file, setFile] = useState(null);
	const redirect = useRedirect();
	const { search } = useLocation();

	const { getData } = useGetById();

	useEffect(() => {
		(async () => {
			if (search) {
				const params = new URLSearchParams(search);
				const id = params.get("id");
				if (id) {
					const data = await getData("Notice", id);
					if (data) {
						setEditMode(true);
						handleInitialiseValues(data);
					}
				}
			}
		})();
	}, [search]);

	const handleInitialiseValues = (data) => {
		setInitialValues((prevState) => {
			const obj = {};
			Object.keys(prevState).forEach((res) => {
				obj[res] = data[res];
			});
			return { ...obj, ...(editMode && { id: data.id }) };
		});
	};

	const validationSchema = () => {
		return Yup.object().shape({
			...(editMode && { id: Yup.number().required() }),
			title: Yup.string().max(190).required(),
			description: Yup.string().max(65535).required(),
			attachment: Yup.mixed().optional(),
		});
	};
	const handleSubmit = async (values, formikUtils) => {
		const formikValues = { ...values };
		let input = {};
		// const fileListAsArray = Array.from(fileList)
		const attachment = Array.from(formikValues?.attachment);

		delete formikValues?.attachment;
		if (editMode) {
			input = {
				updateInput: {
					...formikValues,
				},
				attachment: attachment && attachment?.length ? attachment : null,
			};
		} else {
			input = {
				createInput: {
					...formikValues,
					date: new Date(),
				},
				attachment,
			};
		}
		const { data, reason } = await hocProps.handleSubmit(
			{ ...input },
			formikUtils
		);
		if (data) {
			redirect(reason === "update" ? "/notice" : `/notice/${data.id}/show`);
		}
	};
	return (
		<Formik
			initialValues={initialValues}
			onSubmit={handleSubmit}
			validationSchema={validationSchema}
			enableReinitialize
		>
			{({
				values,
				errors,
				handleSubmit,
				handleChange,
				handleReset,
				setFieldValue,
				touched,
			}) => {
				// console.log(values);
				// if (values?.attachment) {
				// 	console.log(Array.from(values?.attachment));
				// }
				// attachdata = JSON.parse(values.attachment);
				return (
					<Form onSubmit={handleSubmit}>
						<Stack container maxWidth="1150px">
							<CustomInput
								name={"title"}
								onChange={handleChange}
								value={values.title}
								label={"제목"}
								placeholder="내용을 입력하세요."
								fullWidth
								helperText={!!errors.title && touched.title && errors.title}
								error={!!errors.title && touched.title}
							/>

							<CustomInput
								name={"description"}
								label={"내용"}
								value={values.description}
								onChange={handleChange}
								placeholder="내용을 입력하세요."
								multi
								minRows={12}
								maxRows={16}
								helperText={
									!!errors.description &&
									touched.description &&
									errors.description
								}
								error={!!errors.description && touched.description}
							/>

							<CustomInput
								label="첨부파일"
								input={
									<FileUploadMultiple
										label="파일 선택"
										helperText={
											!!errors.attachment &&
											touched.attachment &&
											errors.attachment
										}
										error={!!errors.attachment && touched.attachment}
										onChange={(file) => setFieldValue("attachment", file)}
										file={values["attachment"]}
										// setFile
									/>
								}
							/>

							<div
								style={{
									display: "flex",
									justifyContent: "flex-end",
									gap: "20px",
								}}
							>
								<CustomButton
									styles={{
										backgroundColor: "#F8F8FA",
										color: "#000",
										width: "auto",
										padding: "8px",
									}}
									// onClick={() => {
									//   // backShow();
									//   // redirect(-1);
									//   // redirect('/notice');
									//   showBack()
									// }}
									onClick={() => navigate(-1)}
								>
									취소
								</CustomButton>
								<CustomButton
									type={"submit"}
									styles={{ width: "auto", padding: "10px" }}
								>
									{!editMode ? "등록" : "업데이트"}
								</CustomButton>
							</div>
						</Stack>
					</Form>
				);
			}}
		</Formik>
	);
};
