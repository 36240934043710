import { gql } from "@apollo/client";
import { buildQuery } from "ra-data-graphql-simple";

export const customBuildQuery =
	(introspection) => (fetchType, resource, params) => {
		const builtQuery = buildQuery(introspection)(fetchType, resource, params);

		if (resource === "Notice") {
			switch (fetchType) {
				default: {
					return builtQuery;
				}
			}
		}

		if (resource === "GeneralData") {
			switch (fetchType) {
				case "GET_DEPOSIT_HISTORY": {
					return {
						...builtQuery,
						query: gql(`
              query ShippingCountries($id: Int!) {
                data: ShippingCountries(id: $id) {
                  id
                  name
                  code
                  monetaryUnit
                  deliveryType {
                    id
                    deliveryType
                    details {
                      id
                      shippingType
                      apiType
                      shippingWeight
                      shippingType
                    }
                    __typename
                  }
                  shippingTypesDetailsIds
                  isActive
                  createdAt
                  updatedAt
                  __typename
                }
              }
            `),
					};
				}
				default: {
					return builtQuery;
				}
			}
		}

		if (resource === "ShippingCountries") {
			switch (fetchType) {
				case "GET_ONE": {
					return {
						...builtQuery,
						query: gql(`
              query ShippingCountries($id: Int!) {
                data: ShippingCountries(id: $id) {
                  id
                  name
                  code
                  monetaryUnit
                  deliveryType {
                    id
                    deliveryType
                    details {
                      id
                      shippingType
                      apiType
                      shippingWeight
                      shippingType
                    }
                    __typename
                  }
                  shippingTypesDetailsIds
                  isActive
                  createdAt
                  updatedAt
                  __typename
                }
              }
            `),
					};
				}
				default: {
					return builtQuery;
				}
			}
		}

		if (resource === "ShippingTypes") {
			switch (fetchType) {
				case "GET_LIST": {
					return {
						...builtQuery,
						query:
							gql(`query allShippingTypes($page: Float, $perPage: Float, $filter: ShippingTypeFilter) {
                items: allShippingTypes(page: $page, perPage: $perPage, filter: $filter) {
                  id
                  deliveryType
                  details {
                    id
                    shippingType
                    apiType
                    shippingWeight
                    __typename
                  }
                  shippingCountries {
                    id
                    name
                    __typename
                  }
                  isActive
                  createdAt
                  updatedAt
                  __typename
                }
                total: _allShippingTypesMeta(page: $page, perPage: $perPage, filter: $filter) {
                  count
                  __typename
                }
              }`),
					};
				}

				case "CREATE": {
					return {
						...builtQuery,
						query:
							gql(`mutation createShippingTypes($createInput: ShippingTypeCreateInput!) {
                data: createShippingTypes(createInput: $createInput) {
                  id
                  deliveryType
                  details {
                    id
                    shippingType
                    apiType
                    shippingWeight
                    __typename
                    }
                  shippingCountries {
                   id
                   name
                   __typename
                    }
                  isActive
                  createdAt
                  updatedAt
                  __typename
                }
              }`),
					};
				}

				case "UPDATE": {
					return {
						...builtQuery,
						query:
							gql(`mutation updateShippingTypes($updateInput: ShippingTypeUpdateInput!) {
                data: updateShippingTypes(updateInput: $updateInput) {
                  id
                  deliveryType
                  details {
                    id
                    shippingType
                    apiType
                    shippingWeight
                    __typename
                    }
                  shippingCountries {
                   id
                   name
                   __typename
                    }
                  isActive
                  createdAt
                  updatedAt
                  __typename
                }
              }`),
					};
				}
				default: {
					return builtQuery;
				}
			}
		}

		if (resource === "ApiType") {
			switch (fetchType) {
				default: {
					return builtQuery;
				}
			}
		}

		if (resource === "StatsByCountries") {
			switch (fetchType) {
				default: {
					return builtQuery;
				}
			}
		}

		if (resource === "MasterManagement") {
			switch (fetchType) {
				case "GET_LIST": {
					return {
						// Use the default query variables and parseResponse
						...builtQuery,
						// Override the query
						query: gql(`
              query allMasterManagements(
                $page: Float
                $perPage: Float
                $filter: MasterManagementFilterInput
              ) {
                items: allMasterManagements(
                  page: $page
                  perPage: $perPage
                  filter: $filter
                ) {
                  id
                  masterNumber
                  flightNumber
                  departureAirPort
                  arrivalAirPort
                  DepartureTime
                  arrivalTime
                  deliveryStatus
                  ubiBag
                  userId
                  userProfile {
                    id
                    userID
                    username
                    company_name_en
                    company_name_kr
                    phone_number
                    company_registration_number
                    balance
                    __typename
                  }
                  bags {
                    id
                    name
                    shippingCountry {
                      id
                      __typename
                    }
                    deliveryType {
                      id
                      __typename
                    }
                    deliveryService {
                      id
                      shippingType
                      apiType
                      apiType_ {
                        id
                        name
                        waybillCheck
                        createdAt
                        __typename
                    }
                      shippingWeight
                      shippingTypeId
                      createdAt
                      updatedAt
                      __typename
                    }
                  
                    howToPayCustomDuties
                    dgPackagingManagementCode
                    numberOfUses
                    receptionLists {
                      id
                      shippingType{
                        id
                        shippingType
                        apiType
                        apiType_{
                            id
                            name
                            waybillCheck
                            createdAt
                            __typename
                        }
                        shippingWeight
                        memo
                        shippingTypeId
                        createdAt
                        __typename
                      }
                      __typename
                    }
                    weight
                    usedWeight
                    updatedAt
                    createdAt
                    __typename
                  }
                  totalBags
                  totalRequests
                  deleted
                  createdAt
                  updatedAt
                  __typename
                }
                total: _allMasterManagementsMeta(
                  page: $page
                  perPage: $perPage
                  filter: $filter
                ) {
                  count
                  __typename
                }
              }
            `),
					};
				}

				case "UPDATE": {
					return {
						// Use the default query variables and parseResponse
						...builtQuery,
						// Override the query
						query: gql(`
              mutation updateMasterManagement(
                $id: Int!
                $updateInput: MasterManagementUpdateInput!
              ) {
                data: updateMasterManagement(
                  id: $id
                  updateInput: $updateInput
                ) {
                  id
                  masterNumber
                  flightNumber
                  departureAirPort
                  arrivalAirPort
                  DepartureTime
                  arrivalTime
                  deliveryStatus
                  userId
                  userProfile {
                    id
                    userID
                    username
                    company_name_en
                    company_name_kr
                    phone_number
                    company_registration_number
                    __typename
                  }
                  bags {
                    id
                    name
                    shippingCountry {
                      id
                      __typename
                    }
                    deliveryType {
                      id
                      __typename
                    }
                    deliveryService {
                      id
                      __typename
                    }
                   
                    howToPayCustomDuties
                    dgPackagingManagementCode
                    numberOfUses
                    receptionLists {
                      id
                      __typename
                    }
                    weight
                    usedWeight
                    updatedAt
                    createdAt
                    __typename
                  }
                  deleted
                  createdAt
                  updatedAt
                  __typename
                }
              }
            `),
					};
				}

				case "CREATE": {
					return {
						...builtQuery,
						query:
							gql(`mutation createMasterManagement($createInput: MasterManagementCreateInput!) {
              data: createMasterManagement(createInput: $createInput) {
                id
                masterNumber
                flightNumber
                departureAirPort
                arrivalAirPort
                DepartureTime
                date
                memo
                arrivalTime
                deliveryStatus
                userId
                userProfile {
                    id
                    userID
                    username
                    company_name_en
                    company_name_kr
                    phone_number
                    company_registration_number
                  __typename
                }
                bags {
                  id
                  __typename
                }
                deleted
                createdAt
                updatedAt
                __typename
              }
            }`),
					};
				}
				default: {
					return builtQuery;
				}
			}
		}

		if (resource === "Bag") {
			switch (fetchType) {
				case "GET_LIST": {
					return {
						// Use the default query variables and parseResponse
						...builtQuery,
						// Override the query
						query: gql(`
              query allBags(
                $page: Float
                $perPage: Float
                $filter: BagManagementFilterInput
                $sortOrder: SortOrder
                $sortField: SortField
              ) {
                items: allBags(
                  page: $page
                  perPage: $perPage
                  filter: $filter
                  sortOrder: $sortOrder
                  sortField: $sortField
                ) {
                  id
                  name
                  bagNumber
                  shippingCountry {
                    id
                    name
                    code
                    __typename
                  }
                  deliveryType {
                    id
                    deliveryType
                    
                    __typename
                  }
                  deliveryService {
                    id
                    shippingType
                    apiType
                    shippingWeight
                    shippingTypeId
                    createdAt
                    updatedAt
                    __typename
                  }
                 
                  howToPayCustomDuties
                  dgPackagingManagementCode
                  userProfile {
                    id
                    userID
                    username
                    company_name_en
                    company_name_kr
                    phone_number
                    balance
                    company_registration_number
                    __typename
                  }
                  userId
                  receptionLists {
                    id
                    productInformation{
                    productInformation{
                      quantity
                    }
                    totalPriceAmount
                    currency
                    __typename
                    }
                    miscellaneousInformation{
                    actualWeight
                    bulkWeight
                    applicableWeight
                    }                  
                    status
                    __typename
                  }
                  weight
                  usedWeight
                  totalRequests
                  updatedAt
                  createdAt
                  __typename
                }
                total: _allBagsMeta(
                  page: $page
                  perPage: $perPage
                  filter: $filter
                  sortOrder: $sortOrder
                  sortField: $sortField
                ) {
                  count
                  __typename
                }
              }
            `),
					};
				}
				case "CREATE": {
					return {
						...builtQuery,
						query: gql(`
              mutation createBag($createInput: BagManagementCreateInput!) {
                data: createBag(createInput: $createInput) {
                  id
                  name
                  shippingCountry {
                    id
                    __typename
                  }
                  deliveryType {
                    id
                    __typename
                  }
                  deliveryService {
                    id
                    shippingType
                    apiType
                    shippingWeight
                    shippingTypeId
                    createdAt
                    updatedAt
                    __typename
                  }
                
                  howToPayCustomDuties
                  dgPackagingManagementCode
                  userProfile {
                    id
                    userID
                    username
                    company_name_en
                    company_name_kr
                    phone_number
                    balance
                    company_registration_number
                    __typename
                  }
                  userId
                  numberOfUses
                  receptionLists {
                    id
                    __typename
                  }
                  weight
                  usedWeight
                  updatedAt
                  createdAt
                  __typename
                }
              }
            `),
					};
				}

				case "UPDATE": {
					return {
						...builtQuery,
						query: gql(`
              mutation updateBag($updateInput: BagManagementUpdateInput!) {
                data: updateBag(updateInput: $updateInput) {
                  id
                  name
                  shippingCountry {
                    id
                    __typename
                  }
                  deliveryType {
                    id
                    __typename
                  }
                  deliveryService {
                    id
                    shippingType
                    apiType
                    shippingWeight
                    shippingTypeId
                    createdAt
                    updatedAt
                    __typename
                  }
               
                  howToPayCustomDuties
                  dgPackagingManagementCode
                  userProfile {
                    id
                    userID
                    username
                    company_name_en
                    company_name_kr
                    phone_number
                    balance
                    company_registration_number
                    __typename
                  }
                  userId
                  numberOfUses
                  receptionLists {
                    id
                    __typename
                  }
                  weight
                  usedWeight
                  updatedAt
                  createdAt
                  __typename
                }
              }
            `),
					};
				}
				default: {
					return builtQuery;
				}
			}
		}

		if (resource === "master-bag-list") {
			switch (fetchType) {
				case "GET_LIST": {
					return {
						// Use the default query variables and parseResponse
						...builtQuery,
						// Override the query
						query: gql(`
              query allMasterBags(
                $page: Float
                $perPage: Float
                $filter: MasterBagFilterInput
                $sortOrder: SortOrder
                $sortField: SortField
              ) {
                items: allMasterBags(
                  page: $page
                  perPage: $perPage
                  filter: $filter
                  sortOrder: $sortOrder
                  sortField: $sortField
                ) {
                  id
                  name
                  bagNumber
                  shippingCountry {
                    id
                    name
                    code
                    __typename
                  }
                  deliveryType {
                    id
                    deliveryType
                    
                    __typename
                  }
                  deliveryService {
                    id
                    shippingType
                    apiType
                    shippingWeight
                    shippingTypeId
                    createdAt
                    updatedAt
                    __typename
                  }
                 
                  howToPayCustomDuties
                  dgPackagingManagementCode
                  userProfile {
                    id
                    userID
                    username
                    company_name_en
                    company_name_kr
                    phone_number
                    balance
                    company_registration_number
                    __typename
                  }
                  userId
                  receptionLists {
                    id
                    productInformation{
                    productInformation{
                      quantity
                    }
                    totalPriceAmount
                    currency
                    __typename
                    }
                    miscellaneousInformation{
                    actualWeight
                    bulkWeight
                    applicableWeight
                    }                  
                    status
                    __typename
                  }
                  weight
                  usedWeight
                  totalRequests
                  updatedAt
                  createdAt
                  __typename
                }
                total: _allMasterBagsMeta(
                  page: $page
                  perPage: $perPage
                  filter: $filter
                  sortOrder: $sortOrder
                  sortField: $sortField
                ) {
                  count
                  __typename
                }
              }
            `),
					};
				}
				case "CREATE": {
					return {
						...builtQuery,
						query: gql(`
              mutation createBag($createInput: BagManagementCreateInput!) {
                data: createBag(createInput: $createInput) {
                  id
                  name
                  shippingCountry {
                    id
                    __typename
                  }
                  deliveryType {
                    id
                    __typename
                  }
                  deliveryService {
                    id
                    shippingType
                    apiType
                    shippingWeight
                    shippingTypeId
                    createdAt
                    updatedAt
                    __typename
                  }
                
                  howToPayCustomDuties
                  dgPackagingManagementCode
                  userProfile {
                    id
                    userID
                    username
                    company_name_en
                    company_name_kr
                    phone_number
                    balance
                    company_registration_number
                    __typename
                  }
                  userId
                  numberOfUses
                  receptionLists {
                    id
                    __typename
                  }
                  weight
                  usedWeight
                  updatedAt
                  createdAt
                  __typename
                }
              }
            `),
					};
				}

				case "UPDATE": {
					return {
						...builtQuery,
						query: gql(`
              mutation updateBag($updateInput: BagManagementUpdateInput!) {
                data: updateBag(updateInput: $updateInput) {
                  id
                  name
                  shippingCountry {
                    id
                    __typename
                  }
                  deliveryType {
                    id
                    __typename
                  }
                  deliveryService {
                    id
                    shippingType
                    apiType
                    shippingWeight
                    shippingTypeId
                    createdAt
                    updatedAt
                    __typename
                  }
                
                  howToPayCustomDuties
                  dgPackagingManagementCode
                  userProfile {
                    id
                    userID
                    username
                    company_name_en
                    company_name_kr
                    phone_number
                    balance
                    company_registration_number
                    __typename
                  }
                  userId
                  numberOfUses
                  receptionLists {
                    id
                    __typename
                  }
                  weight
                  usedWeight
                  updatedAt
                  createdAt
                  __typename
                }
              }
            `),
					};
				}
				default: {
					return builtQuery;
				}
			}
		}

		if (resource === "CompanyList") {
			switch (fetchType) {
				case "GET_LIST": {
					return {
						...builtQuery,
					};
				}
				default: {
					return builtQuery;
				}
			}
		}

		if (resource === "RequestShipping") {
			switch (fetchType) {
				case "GET_LIST": {
					return {
						...builtQuery,
						query:
							gql(`query allRequestShippings($page: Float, $perPage: Float, $sortOrder:SortOrder, $sortField:SortField, $filter: RequestShippingFilterInput) {
                      items: allRequestShippings(page: $page, perPage: $perPage, sortOrder:$sortOrder,sortField:$sortField, filter: $filter) {
                        id
                        waybillPrintStatus
                        packingListPrintStatus
                        masterNo
                        totalSKU
                        bag {
                          id
                          name
                          bagNumber
                          shippingCountry{
                            id
                            __typename
                          }
                          deliveryType{
                            id
                            __typename
                          }
                          deliveryService{
                            id
                            __typename
                          }
                         

                          howToPayCustomDuties
                          dgPackagingManagementCode
                          userProfile{
                            id
                            __typename
                          }
                          userId
                          numberOfUses
                          weight
                          usedWeight
                          __typename
                        }
                        senderInformation {
                          id
                          name
                          phoneNumber
                          address
                          requestShippingId
                          deleted
                          createdAt
                          updatedAt
                          __typename
                        }
                        whereAbout{
                          id
                          status
                          requestShippingId
                          requestShipping{
                            id
                            __typename
                          }
                          memo
                          __typename
                        }
                        payeeInformation {
                          id
                          name
                          shippingCountryId
                          shippingCountry{
                            id
                            name
                            code
                            monetaryUnit
                            country_name_en
                            __typename
                          }
                          phoneNumber1
                          phoneNumber2
                          zipCode
                          ssn
                          ukeori
                          euioss
                          states
                          cities
                          streetAddress1
                          streetAddress2
                          email
                          requestShippingId
                          mexicoColony
                          deleted
                          createdAt
                          updatedAt
                          __typename
                        }
                        miscellaneousInformation {
                          id
                          shippingMessage
                          horizontal
                          portrait
                          height
                          actualWeight
                          applicableWeight
                          bulkWeight
                          userData
                          userDate2
                          userData3
                          boxQuantity
                          productType
                          exportDeclarationNumber
                          requestShippingId
                          deleted
                          coupon
                          createdAt
                          updatedAt
                          __typename
                        }
                        productInformation {
                          id
                          orderNo1
                          orderNo2
                          departureCountryCourierNumber
                          currency
                          productInformation {
                            id
                            barcode
                            productName
                            quantity
                            unitPrice
                            brandName
                            sku
                            purchaseURL
                            hscode
                            deleted
                            createdAt
                            updatedAt
                            __typename
                          }
                          totalPriceAmount
                          requestShippingId
                          deleted
                          createdAt
                          updatedAt
                          __typename
                        }
                      
                        user {
                          id
                          username
                          userID
                          company_registration_number
                          company_name_kr
                          company_name_en
                          customer_name
                          totalDeposit
                          email
                          memo
                          request_issuance_of_tax_invoice
                          e_tax_invoice_email
                          balance
                          business_condition
                          phone_number
                          mobile_number
                          mallId
                          sellerPartyId
                          apiKey
                          __typename
                        }
                        shippingType{
                          id
                          shippingType
                          apiType
                          apiType_{
                            name
                            id
                          }
                          shippingWeight
                          memo
                         
                          createdAt
                          __typename
                        }
                        shippingCountry{
                          id
                          name
                          code
                          monetaryUnit
                          country_name_en
                          shippingTypesDetailsIds
                          isActive
                          createdAt
                          __typename
                        }
                        userId
                        deleted
                        deliveryFee
                        createdAt
                        updatedAt
                        status
                        trackingNumber
                        shippingNumber
                        additionalFee
                        orderNumber
                        weightValidation
                        printWaybillAfterWeightChange
                        sampoongAmountCheck
                        treatmentAfterWeightChange
                        processReleaseDate
                        exportDeclarationRequest
                        __typename
                      }
                      total: _allRequestShippingsMeta(page: $page, perPage: $perPage, sortOrder:$sortOrder,sortField:$sortField, filter: $filter) {
                        count
                        __typename
                      }
                    }
                    `),
					};
				}
				// case "CREATE": {
				//   return {
				//     ...builtQuery,
				//     query: gql(`mutation createRequestShipping($createInput: RequestShippingCreateInput!) {
				//            createRequestShipping(createInput: $createInput) {
				//             id
				//             senderInformation {
				//               id
				//               name
				//               phoneNumber
				//               address
				//               requestShippingId
				//               deleted
				//               createdAt
				//               updatedAt
				//               __typename
				//             }
				//             payeeInformation {
				//               id
				//               name
				//               shippingCountryId
				//               shippingCountry{
				//                 id
				//                 name
				//                 code
				//                 monetaryUnit
				//               }
				//               phoneNumber1
				//               phoneNumber2
				//               zipCode
				//               ssn
				//               ukeori
				//               euioss
				//               states
				//               cities
				//               streetAddress1
				//               streetAddress2
				//               email
				//               requestShippingId
				//               deleted
				//               createdAt
				//               updatedAt
				//               __typename
				//             }
				//             miscellaneousInformation {
				//               id
				//               shippingMessage
				//               horizontal
				//               portrait
				//               height
				//               actualWeight
				//               applicableWeight
				//               bulkWeight
				//               shippingTypeId
				//               userData
				//               userDate2
				//               userData3
				//               boxQuantity
				//               productType
				//               exportDeclarationNumber
				//               requestShippingId
				//               deleted
				//               createdAt
				//               updatedAt
				//               __typename
				//             }
				//             productInformation {
				//               id
				//               orderNo1
				//               orderNo2
				//               departureCountryCourierNumber
				//               currency
				//               productInformation {
				//                 id
				//                 barcode
				//                 productName
				//                 quantity
				//                 unitPrice
				//                 brandName
				//                 sku
				//                 purchaseURL
				//                 hscode
				//                 deleted
				//                 createdAt
				//                 updatedAt
				//                 __typename
				//               }
				//               totalPriceAmount
				//               requestShippingId
				//               deleted
				//               createdAt
				//               updatedAt
				//               __typename
				//             }
				//             bag {
				//               id
				//               __typename
				//             }
				//             user {
				//               id
				//               username
				//               userID
				//               company_registration_number
				//               company_name_en
				//               phone_number
				//               mobile_number
				//               __typename
				//             }
				//             userId
				//             deleted
				//             createdAt
				//             updatedAt

				//             __typename
				//           }

				//               }
				//               `),
				//   };
				// }
				case "GET_ONE": {
					return {
						...builtQuery,
						query: gql(`
              query RequestShipping($id: Float!) {
                data: RequestShipping(id: $id) {
                  id
                  totalSKU
                  senderInformation {
                    id
                    name
                    phoneNumber
                    address
                    requestShippingId
                    deleted
                    createdAt
                    updatedAt
                    __typename
                  }
                  shippingType{
                    id
                    shippingType
                  }
                  payeeInformation {
                    id
                    name
                    shippingTypeId
                    shippingCountryId
                    shippingCountry {
                      name
                      __typename
                    }
                    phoneNumber1
                    phoneNumber2
                    zipCode
                    ssn
                    ukeori
                    euioss
                    states
                    cities
                    streetAddress1
                    streetAddress2
                    email
                    requestShippingId
                    deleted
                    createdAt
                    updatedAt
                    __typename
                  }
                  trackingNumber
                  weightValidation
                  printWaybillAfterWeightChange
                  sampoongAmountCheck
                  treatmentAfterWeightChange
                  status
                  weightValidation
                  shippingNumber
                  miscellaneousInformation {
                    id
                    shippingMessage
                    horizontal
                    portrait
                    height
                    actualWeight
                    applicableWeight
                    bulkWeight
                    userData
                    userDate2
                    userData3
                    boxQuantity
                    productType
                    exportDeclarationNumber
                    requestShippingId
                    deleted
                    createdAt
                    updatedAt
                    __typename
                  }
                  productInformation {
                    id
                    orderNo1
                    orderNo2
                    departureCountryCourierNumber
                    currency
                    productInformation {
                      id
                      barcode
                      productName
                      quantity
                      unitPrice
                      brandName
                      sku
                      purchaseURL
                      hscode
                      deleted
                      createdAt
                      updatedAt
                      __typename
                    }
                    totalPriceAmount
                    requestShippingId
                    deleted
                    createdAt
                    updatedAt
                    __typename
                  }
                  bag {
                    id
                    weight
                    __typename
                  }
                  user {
                    id
                    __typename
                  }
                  userId
                  deleted
                  createdAt
                  updatedAt
                  shippingCountry {
                    id
                    name
                    code
                    monetaryUnit
                    country_name_en
                    __typename
                  }
                  
                  __typename
                }
              }
            `),
					};
				}

				case "UPDATE": {
					return {
						...builtQuery,
						query: gql(`
              mutation updateRequestShipping(
                $updateInput: RequestShippingUpdateInput!
              ) {
                data: updateRequestShipping(updateInput: $updateInput) {
                  id
                  bag {
                    id
                  }
                  senderInformation {
                    id
                    name
                    phoneNumber
                    address
                    requestShippingId
                    deleted
                    createdAt
                    updatedAt
                  }
                  whereAbout {
                    id
                    status
                    requestShippingId
                    requestShipping {
                      id
                    }
                    memo
                  }
                  payeeInformation {
                    id
                    name
                    shippingCountryId
                    shippingCountry {
                      id
                      name
                      code
                      monetaryUnit
                    }
                    phoneNumber1
                    phoneNumber2
                    zipCode
                    ssn
                    ukeori
                    euioss
                    states
                    cities
                    streetAddress1
                    streetAddress2
                    email
                    requestShippingId
                    mexicoColony
                    deleted
                    createdAt
                    updatedAt
                  }
                  miscellaneousInformation {
                    id
                    shippingMessage
                    horizontal
                    portrait
                    height
                    actualWeight
                    applicableWeight
                    bulkWeight
                    userData
                    userDate2
                    userData3
                    boxQuantity
                    productType
                    exportDeclarationNumber
                    requestShippingId
                    deleted
                    createdAt
                    updatedAt
                  }
                  productInformation {
                    id
                    orderNo1
                    orderNo2
                    departureCountryCourierNumber
                    currency
                    productInformation {
                      id
                      barcode
                      productName
                      quantity
                      unitPrice
                      brandName
                      sku
                      purchaseURL
                      hscode
                      deleted
                      createdAt
                      updatedAt
                    }
                    totalPriceAmount
                    requestShippingId
                    deleted
                    createdAt
                    updatedAt
                  }

                  user {
                    id
                    username
                    userID
                    company_registration_number
                    company_name_kr
                    company_name_en
                    customer_name
                    totalDeposit
                    email
                    memo
                    request_issuance_of_tax_invoice
                    e_tax_invoice_email
                    balance
                    business_condition
                    phone_number
                    mobile_number
                  }
                  shippingType {
                    id
                    shippingType
                    apiType
                    apiType_ {
                      id
                      name
                    }
                    shippingWeight
                    memo
                    shippingTypeId
                    createdAt
                  }
                  shippingCountry {
                    id
                    name
                    code
                    monetaryUnit
                    shippingTypesDetailsIds
                    isActive
                  }
                  userId
                  deleted
                  createdAt
                  updatedAt
                  status
                  trackingNumber
                  shippingNumber
                  additionalFee
                  orderNumber
                  weightValidation
                  totalSKU
                  printWaybillAfterWeightChange
                  sampoongAmountCheck
                  treatmentAfterWeightChange
                  processReleaseDate
                }
              }
            `),
					};
				}

				default: {
					return builtQuery;
				}
			}
		}

		if (resource === "Transaction") {
			switch (fetchType) {
				case "GET_LIST": {
					return {
						...builtQuery,
						query:
							gql(`query allTransactions($page: Int, $perPage: Int, $sortOrder:SortOrder, $sortField:SortField, $filter: TransactionsFilterInput) {
                      items: allTransactions(page: $page, perPage: $perPage, sortOrder:$sortOrder, sortField:$sortField, filter: $filter) {
                        id
                        userId
                        user
                        {
                         id
                        userID
                        customer_name
                        representative_name
                        business_condition
                        business_item
                        contact_name
                        totalDeposit
                        company_registration_number
                        company_name_kr
                        company_name_en
                        business_registration
                        website_address
                        createdAt
                        updatedAt
                        aproval
                        receptionMethodCheck
                        e_tax_invoice_email
                        request_issuance_of_tax_invoice
                        memo
                        branchEmail
                        branchName
                        branchAddress
                        branchPhoneNumber
                        __typename
                        }
                        shippingFeeDeductionHistory{
                          id
                          user{
                            id
                            __typename
                          }
                          requestShippingId
                          requestShipping{
                            id
                            __typename
                          }
                          company_name
                          shipping_number
                          order_number
                          registration_number
                          customs_number
                          shipping_fee
                          shipping_fee_deducted_date
                          courier_additional_charge
                          other_costs
                          product_name
                          trackingNumber
                          product_quantity
                          total_product_price
                          currency
                          applicable_weight
                          actual_weight
                          bulky_weight
                          delivery_status
                          delivery_date
                          delivery_type
                          shipping_country
                          __typename
                         }
                        reason,
                        type,
                        amount,
                        leftBalance,
                        shipping_number,
                        details,
                        deposit_processing_date,
                        memo,
                        createdAt,
                        updatedAt,
                        __typename
                      }
                      total: _allTransactionsMeta(page: $page, perPage: $perPage,sortOrder:$sortOrder, sortField:$sortField, filter: $filter) {
                        count
                        __typename
                      }
                    }
                    `),
					};
				}

				default: {
					return builtQuery;
				}
			}
		}

		if (resource === "Waybill") {
			switch (fetchType) {
				case "GET_LIST": {
					return {
						...builtQuery,
						query:
							gql(`query allWaybills($page: Float, $perPage: Float, $filter: WaybillFilterInput) {
		                  items: allWaybills(page: $page, perPage: $perPage, filter: $filter) {
		                    id
		                    trackingNumber
		                    shippingRequestId
		                    shippingRequest{
                          id
                          __typename
                        }
		                    deliveryTypeId
		                    deliveryType{
		                      id
		                      shippingType
		                      apiType
		                      shippingWeight
		                      memo
		                      shippingTypeId
		                      createdAt
		                      __typename
		                    }
		                    status
		                    deleted
		                    createdAt
		                    updatedAt
		                    __typename
		                  }
		                  total: _allWaybillsMeta(page: $page, perPage: $perPage, filter: $filter) {
		                    count
		                    __typename
		                  }
		                }
		                `),
					};
				}

				default: {
					return builtQuery;
				}
			}
		}

		if (resource === "WhereAbout") {
			switch (fetchType) {
				case "GET_LIST": {
					return {
						...builtQuery,
						query:
							gql(`query allWhereAbouts($page: Float, $perPage: Float, $filter: WhereAboutFilterInput, $sortOrder:SortOrder , $sortField:SortField ) {
		                  items: allWhereAbouts(page: $page, perPage: $perPage, sortOrder:$sortOrder, sortField:$sortField, filter: $filter) {
                        id
                        requestShippingId
                        requestShipping{
                          id
                          deliveryFee
                          bag {
                            id
                            __typename
                          }
                          senderInformation {
                            id
                            name
                            phoneNumber
                            address
                            requestShippingId
                            deleted
                            createdAt
                            updatedAt
                            __typename
                          }
                          whereAbout{
                            id
                            __typename
                          }
                        payeeInformation {
                          id
                          name
                          shippingCountryId
                          shippingCountry{
                            id
                            name
                            code
                            monetaryUnit
                            __typename
                          }
                          phoneNumber1
                          phoneNumber2
                          zipCode
                          ssn
                          ukeori
                          euioss
                          states
                          cities
                          streetAddress1
                          streetAddress2
                          email
                          requestShippingId
                          mexicoColony
                          deleted
                          createdAt
                          updatedAt
                          __typename
                        }
                        miscellaneousInformation {
                          id
                          shippingMessage
                          horizontal
                          portrait
                          height
                          actualWeight
                          applicableWeight
                          bulkWeight
                          userData
                          userDate2
                          userData3
                          boxQuantity
                          productType
                          exportDeclarationNumber
                          requestShippingId
                          deleted
                          createdAt
                          updatedAt
                          __typename
                        }
                        productInformation {
                          id
                          orderNo1
                          orderNo2
                          departureCountryCourierNumber
                          currency
                          productInformation {
                            id
                            barcode
                            productName
                            quantity
                            unitPrice
                            brandName
                            sku
                            purchaseURL
                            hscode
                            deleted
                            createdAt
                            updatedAt
                            __typename
                          }
                          totalPriceAmount
                          requestShippingId
                          deleted
                          createdAt
                          updatedAt
                          __typename
                        }
                      
                        user {
                          id
                          username
                          userID
                          company_registration_number
                          company_name_kr
                          customer_name
                          totalDeposit
                          email
                          memo
                          request_issuance_of_tax_invoice
                          e_tax_invoice_email
                          balance
                          business_condition
                          phone_number
                          mobile_number
                          __typename
                        }
                        shippingType{
                          id
                          shippingType
                          apiType
                          shippingWeight
                          memo
                          shippingTypeId
                          createdAt
                          __typename
                        }
                        userId
                        deleted
                        createdAt
                        updatedAt
                        status
                        trackingNumber
                        shippingNumber
                        additionalFee
                        orderNumber
                        weightValidation
                        printWaybillAfterWeightChange
                        sampoongAmountCheck
                        treatmentAfterWeightChange
                        processReleaseDate
                        __typename
                      }
                        status
                        memo
		                    __typename
		                  }
		                  total: _allWhereAboutsMeta(page: $page, perPage: $perPage,sortOrder:$sortOrder, sortField:$sortField, filter: $filter) {
		                    count
		                    __typename
		                  }
		                }
		                `),
					};
				}

				default: {
					return builtQuery;
				}
			}
		}

		if (resource === "ShippingSummaryStats") {
			switch (fetchType) {
				case "GET_LIST": {
					return {
						...builtQuery,
						query:
							gql(`query allShippingSummary($page: Float, $perPage: Float, $filter: shippingSummaryFilter, $sortOrder:SortOrder , $sortField:SortField ) {
		                  items: allShippingSummary(page: $page, perPage: $perPage, sortOrder:$sortOrder, sortField:$sortField, filter: $filter) {
                        summary{
                          id
                          companyName
                          unstacked
                          quantityReceived
                          overall
                          companyCode
                          __typename
                        }
		                    __typename
		                  }
		                  total: _allShippingSummaryMeta(page: $page, perPage: $perPage,sortOrder:$sortOrder, sortField:$sortField, filter: $filter) {
		                    count
		                    __typename
		                  }
		                }
		                `),
					};
				}

				default: {
					return builtQuery;
				}
			}
		}

		if (resource === "SettlementHistoryStats") {
			switch (fetchType) {
				case "GET_LIST": {
					return {
						...builtQuery,
						query:
							gql(`query allSettlementHistoryStats($page: Int, $perPage: Int, $sortOrder:String , $sortField:String , $filter: SettlementHistoryStatsFilter) {
		                  items: allSettlementHistoryStats(page: $page, perPage: $perPage, sortOrder:$sortOrder, sortField:$sortField, filter: $filter) {
                        history{
                          shippingStarted
                          companyName
                          id
                          deliveryCompleted
                          overall
                          __typename
                        }
		                    __typename
		                  }
		                  total: _allSettlementHistoryStatsMeta(page: $page, perPage: $perPage,sortOrder:$sortOrder, sortField:$sortField, filter: $filter) {
		                    count
		                    __typename
		                  }
		                }
		                `),
					};
				}

				default: {
					return builtQuery;
				}
			}
		}

		if (resource === "ShippingFeeHistory") {
			switch (fetchType) {
				case "GET_LIST": {
					return {
						...builtQuery,
						query:
							gql(`query allShippingFeeHistory($page: Float, $perPage: Float, $sortOrder:SortOrder , $sortField:SortOrder , $filter: FilterShippingFeeHistoryInput) {
		                  items: allShippingFeeHistory(page: $page, perPage: $perPage, sortOrder:$sortOrder, sortField:$sortField, filter: $filter) {
                        id
                        user{
                          id
                          __typename
                        }
                        userId
                        requestShippingId
                        requestShipping{
                          id
                          __typename
                        }
                        transaction{
                          id
                          __typename
                        }
                        transactionId
                        company_name
                        shipping_number
                        order_number
                        registration_number
                        customs_number
                        shipping_fee
                        shipping_fee_deducted_date
                        courier_additional_charge
                        other_costs
                        product_name
                        product_quantity
                        total_product_price
                        currency
                        memo
                        release
                        applicable_weight
                        actual_weight
                        bulky_weight
                        shipping_country
                        delivery_type
                        api_type
                        delivery_status
                        delivery_date
		                    __typename
		                  }
		                  total: _allShippingFeeHistoryMeta(page: $page, perPage: $perPage,sortOrder:$sortOrder, sortField:$sortField, filter: $filter) {
		                    count
		                    __typename
		                  }
		                }
		                `),
					};
				}

				default: {
					return builtQuery;
				}
			}
		}

		if (resource === "MasterBag") {
			switch (fetchType) {
				case "GET_LIST": {
					return {
						// Use the default query variables and parseResponse
						...builtQuery,
						// Override the query
						query: gql(`
              query allMasterBags(
                $page: Float
                $perPage: Float
                $filter: MasterBagFilterInput
                $sortOrder: SortOrder
                $sortField: SortField
              ) {
                items: allMasterBags(
                  page: $page
                  perPage: $perPage
                  filter: $filter
                  sortOrder: $sortOrder
                  sortField: $sortField
                ) {
                  id
                  name
                  bagNumber
                  shippingCountry {
                    id
                    name
                    code
                    __typename
                  }
                  deliveryType {
                    id
                    deliveryType
                    
                    __typename
                  }
                  deliveryService {
                    id
                    shippingType
                    apiType
                    shippingWeight
                    shippingTypeId
                    createdAt
                    updatedAt
                    __typename
                  }
                 
                  howToPayCustomDuties
                  dgPackagingManagementCode
                  userProfile {
                    id
                    userID
                    username
                    company_name_en
                    company_name_kr
                    phone_number
                    balance
                    company_registration_number
                    __typename
                  }
                  userId
                  receptionLists {
                    id
                    productInformation{
                    productInformation{
                      quantity
                    }
                    totalPriceAmount
                    currency
                    __typename
                    }
                    miscellaneousInformation{
                    actualWeight
                    bulkWeight
                    applicableWeight
                    }                  
                    status
                    __typename
                  }
                  master{
                    id
                    masterNumber
                    flightNumber
                    carrierAgentPhoneNo
                    departureAirPort
                    arrivalAirPort
                    DepartureTime
                    date
                    memo
                    arrivalTime
                    deliveryStatus
                    userId
                    __typename
                  }
                  weight
                  usedWeight
                  totalRequests
                  updatedAt
                  createdAt
                  __typename
                }
                total: _allMasterBagsMeta(
                  page: $page
                  perPage: $perPage
                  filter: $filter
                  sortOrder: $sortOrder
                  sortField: $sortField
                ) {
                  count
                  __typename
                }
              }
            `),
					};
				}
				default: {
					return builtQuery;
				}
			}
		}

		return builtQuery;
	};

// export default buildGraphQLProvider({
//   client: apolloClient,
//   buildQuery: myBuildQuery,
// });
