import React from "react";
import Popover from "@mui/material/Popover";
import { MenuItem, MenuList } from "@mui/material";
import Toaster from "../utils/Toaster";
import exportPrimeXlsx from "../pages/admin/mastermanagement/utils/exportPrimeExcel";
import exportLTPostPrimeXlsx from "../pages/admin/mastermanagement/utils/exportLTPostPrime";
import exportLTPostRegisteredXlsx from "../pages/admin/mastermanagement/utils/exportLTPostReg";
import exportLTPostPremiumXlsx from "../pages/admin/mastermanagement/utils/exportLTPostPremium";
import exportLTPostEMSXlsx from "../pages/admin/mastermanagement/utils/exportLTPostEMS";
import exportLTPostXlsx from "../pages/admin/mastermanagement/utils/exportLTPost";
import exportEDI from "../pages/admin/mastermanagement/utils/exportEDI";
import DetailedExport from "../pages/admin/mastermanagement/utils/detailedExport";
import exportUAServiceXlsx from "../pages/admin/mastermanagement/utils/exportUAService";
import exportBRNLPostService from "../pages/admin/mastermanagement/utils/NLPost/exportBRNLPostService";

export function CustomRightClickMenu({
	handleMenuClose,
	openPosition,
	checkedList,
	masterData,
}) {
	const checkSelectedRow = (fn, data = []) => {

		if (checkedList.length > 1) {
			Toaster("error", "마스터 1개만 선택해주세요.");
		} else if (checkedList.length === 0) {
			Toaster("error", "마스터를 선택해주세요.");
		} else {
			fn(checkedList, data);
		}
	};

	return (
		<Popover
			open={!!openPosition}
			anchorPosition={openPosition}
			onClose={handleMenuClose}
			anchorReference="anchorPosition"
		>
			<MenuList className={"px1"}>
				<MenuItem onClick={() => checkSelectedRow(exportEDI, masterData)}>유한 EDI 엑셀 다운로드</MenuItem>
				<MenuItem onClick={() => checkSelectedRow(exportLTPostXlsx)}>리투아니아 POST (P+) 엑셀 데이터 다운로드</MenuItem>
				<MenuItem onClick={() => checkSelectedRow(exportLTPostPrimeXlsx)}>리투아니아 POST (P+) 엑셀 Prime 데이터 다운로드</MenuItem>
				<MenuItem onClick={() => checkSelectedRow(exportLTPostRegisteredXlsx)}>리투아니아 POST (P+) 엑셀 Registered 데이터 다운로드</MenuItem>
				<MenuItem onClick={() => checkSelectedRow(exportLTPostPremiumXlsx)}>리투아니아 POST (P+) 엑셀 Premium 데이터 다운로드</MenuItem>
				<MenuItem onClick={() => checkSelectedRow(exportLTPostEMSXlsx)}>리투아니아 POST (P+) 엑셀 EMS 데이터 다운로드</MenuItem>
				<MenuItem onClick={() => checkSelectedRow(exportUAServiceXlsx)}>UA (NPG) Service 엑셀 데이터 다운로드</MenuItem>
				<MenuItem onClick={() => checkSelectedRow(exportBRNLPostService, masterData)}>BR (NL post) Service 엑셀 데이터 다운로드</MenuItem>
				<MenuItem onClick={() => checkSelectedRow(DetailedExport, masterData)}>
					상세내역
				</MenuItem>
				<MenuItem>수출신고양식 데이터 다운로드</MenuItem>
			</MenuList>
		</Popover>
	);
}

export function BagManagementCustomRightClickMenu({
	handleMenuClose,
	openPosition,
	setIsMasterPackagingModalOpen,
	setIsMasterCreationModalOpen,
	setIsAddBagModalOpen,
	setOpenBagError,
	setShippedBagsError,
	shippedLength,
	length,
}) {
	return (
		<Popover
			open={!!openPosition}
			anchorPosition={openPosition}
			onClose={handleMenuClose}
			anchorReference="anchorPosition"
		>
			<MenuList className={"px-1"}>
				<MenuItem
					onClick={(e) => {
						if (!shippedLength) {
							if (length) setIsMasterPackagingModalOpen(true);
							else {
								Toaster("error", "BAG을 선택해주세요.");
							}
						} else setShippedBagsError(true);
					}}
				>
					마스터 패키징
				</MenuItem>
				<MenuItem
					onClick={(e) => {
						if (!shippedLength) {
							if (length) {
								setIsMasterCreationModalOpen(true);
							} else {
								Toaster("error", "BAG을 선택해주세요.");
							}
						} else setShippedBagsError(true);
					}}
				>
					마스터 생성 및 패키징
				</MenuItem>
				<MenuItem
					onClick={(e) => {
						if (length > 1) {
							setOpenBagError(true);
						} else if (length === 1) {
							setIsAddBagModalOpen(true);
						} else {
							Toaster("error", "BAG을 선택해주세요.");
						}
					}}
				>
					BAG 이름변경
				</MenuItem>
				{/* <MenuItem disabled={!length}>마스터 생성 및 패키징</MenuItem>
        <MenuItem disabled={!length || length > 1}>BAG 이름변경</MenuItem> */}
			</MenuList>
		</Popover>
	);
}
