import { Box, Divider, Modal, Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import CustomButton from "../components/CustomButton";
import CustomCard from "../components/CustomCard";
import Icons from "../utils/icon";
import successIcon from "../Common/assets/Images/successIcon.svg";
import { useNavigate } from "react-router-dom";

export default function BulkSuccessModal({
	isOpen,
	onClose,
	onConfirm,
	isLoading = false,
	title = "",
	description = "",
	lastBtn,
	count,
	// handleDelete,
	...props
}) {
	const navigate = useNavigate();
	return (
		<Modal
			open={isOpen}
			onClose={onClose}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Box
				style={{
					padding: 20,
					overflowX: "hidden",
					minHeight: "100vh",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				<CustomCard>
					<Stack spacing={"20px"} width={350} p="20px">
						<Stack direction="row" alignItems={"center"} spacing="10px">
							<div
								style={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									height: "180px",
									width: "100%",
								}}
							>
								<img src={successIcon} alt="Icon" />
							</div>
						</Stack>
						<div style={{textAlign: "center"}}>
							<Typography
								lineHeight={"0.5"}
								fontSize={"14px"}
								color="rgba(0,0,0,0.6)"
								component={"div"}
							>
								배송대량신청 페이지를 통해 접수하신 배송신청 (
								<Typography
									fontSize={"14px"}
									color="#427DF3"
									component={"span"}
									fontWeight={"bold"}
								>
									{count}
								</Typography>
								)건 중, <br/> (
								<Typography
									fontSize={"14px"}
									color="#427DF3"
									component={"span"}
									fontWeight={"bold"}
								>
									{count}
								</Typography>
								)건이 성공적으로 접수되었습니다.
							</Typography>
						</div>

						{/* <Divider /> */}

						<div
							style={{
								display: "flex",
								justifyContent: "center",
								gap: "20px",
							}}
						>
							<CustomButton
								onClick={onClose}
								styles={{
									background: "#F8F8FA",
									color: "#000",
									width: "auto",
								}}
							>
								닫기
							</CustomButton>
							<CustomButton
								disabled={isLoading}
								styles={{ width: "auto", paddingLeft: "10px", paddingRight: "10px" }}
								onClick={() => navigate("/shipping-request/list")}
							>
								배송신청리스트 페이지로 이동
							</CustomButton>
						</div>
					</Stack>
				</CustomCard>
			</Box>
		</Modal>
	);
}
