import { CircularProgress, Box } from "@mui/material";

export default function Loader(props) {
  return (
    <Box
      display={"flex"}
      height={"100%"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <CircularProgress {...{ size: "4rem", ...props }} />
    </Box>
  );
}
