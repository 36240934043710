import CustomButton from "../CustomButton";
import { Grid } from "@mui/material";

import CustomInput from "../CustomInput";
import styled from "styled-components";
import { useAtom } from "jotai";
import {
  addBagAtom,
  apiCallAtom,
  printAtom,
  getValidWeightAtom,
  messageAtom,
  nodeBagIdAtom,
  priceCheckingAtom,
  resetWeightAtom,
  shippingProcessingNumberAtom,
} from "./atom";
import {
  useApolloClient,
  useLazyQuery,
  useMutation,
  useQuery,
} from "@apollo/client";
import { BAG, FIND_REQUEST_SHIPPING } from "./queries";
import Toaster from "../../utils/Toaster";
import { useState } from "react";
import {
  UPDATE_API_SHIPPING_NUMBER,
  UPDATE_REQUEST_SHIPPING,
  UPDATE_REQUEST_WEIGHT,
} from "./mutations";
import _ from "lodash";
import { getBagTotalWeight } from "./utils";
import usePrintWaybill from "../../resources/WaybillPrint";

const availWeightUpdate = ({ status }) => {
  switch (status) {
    case "waiting_warehousing":
      return true;
    case "warehousing_completed":
      return true;
    case "waiting_delivery":
      return false;
    case "started_delivery":
      return false;
    case "completed_delivery":
      return false;
    default:
      return false;
  }
};

const NumberSubmit = ({ inputRef }) => {
  const [value, setValue] = useState("");
  const [, setShippingProcessingNumber] = useAtom(shippingProcessingNumberAtom);
  const [, setMessage] = useAtom(messageAtom);
  const [nodeBagId] = useAtom(nodeBagIdAtom);
  const [addBag] = useAtom(addBagAtom);
  const [print] = useAtom(printAtom);
  const [apiCall] = useAtom(apiCallAtom);

  const [validWeight] = useAtom(getValidWeightAtom);
  const { data: nodeBagData, refetch: refetchBag } = useQuery(BAG, {
    fetchPolicy: "cache-and-network",
    variables: { id: { id: nodeBagId } },
  });
  const client = useApolloClient();

  const [findRequestShipping, { refetch: refetchRequestShipping }] =
    useLazyQuery(FIND_REQUEST_SHIPPING, {
      fetchPolicy: "cache-and-network",
    });

  const [updateWeight] = useMutation(UPDATE_REQUEST_WEIGHT);
  const [updateRequestShipping] = useMutation(UPDATE_REQUEST_SHIPPING);
  const [updateApiShippingNumber] = useMutation(UPDATE_API_SHIPPING_NUMBER);
  const [priceCheck] = useAtom(priceCheckingAtom);
  const [, resetWeight] = useAtom(resetWeightAtom);
  const { printRequestedLabels } = usePrintWaybill(null);
  const inputConfirm = async () => {
    let message = ``;
    const response = await findRequestShipping({
      variables: { term: value },
    });
    setValue("");
    if (response.error) {
      Toaster("error", response.error.message);
      setMessage({
        text: `${value} 를 조회할 수 없습니다.`,
        type: "error",
      });
      return;
    }
    setShippingProcessingNumber(value);
    const shipping = response.data?.findRequestShipping || {};
    const apiTypeName = shipping.shippingType.apiType_.name;
    if (!value) return;
    message += `배송번호: ${shipping.trackingNumber}\n`;

    if (shipping.isDraft) {
      message += `배송 보류건입니다`;
      setMessage({ text: message, type: "error" });
      return;
    }

    if (
      !!!availWeightUpdate({
        status: shipping.status,
      })
    ) {
      message += `이미 출고되어서 무게 업데이트가 불가합니다.`;
      setMessage({ text: message, type: "error" });
      return;
    }

    const existBag = shipping.bag;
    let finalWeight = shipping.miscellaneousInformation.applicableWeight;
    if (validWeight.applicableWeight) {
      await updateWeight({
        variables: {
          updateInput: {
            id: shipping.miscellaneousInformation.id,
            ...validWeight,
          },
        },
      });
      refetchRequestShipping();
      message += `무게 업데이트 (${validWeight.applicableWeight})\n`;
      finalWeight = validWeight.applicableWeight;
      client.refetchQueries({ include: [BAG] });
    } else {
      message += `업데이트된 무게가 없습니다.\n`;
    }
    resetWeight();

    if (existBag) {
      message += `백 (${existBag.name})\n`;
      const limitWeight = existBag.weight;
      const totalWeight = getBagTotalWeight({
        bagReceptionLists: existBag.bagReceptionLists,
      });
      const updatedTotalWeight = totalWeight + finalWeight;
      if (updatedTotalWeight > limitWeight) {
        message += `백 무게를 초과했습니다.(${updatedTotalWeight}/${limitWeight}) 백(${existBag.name})을 확인 해주세요.`;
        setMessage({ text: message, type: "error" });
        client.refetchQueries({ include: [BAG] });
        alert("백 무게 초과 오류 발생 백을 다시 확인해주세요");
        return;
      }
    }

    if (
      priceCheck.isCheck &&
      shipping.productInformation.totalPriceAmount > priceCheck.price
    ) {
      message += `상품 금액이 초과했습니다.(${shipping.productInformation.totalPriceAmount}/${priceCheck.price})`;
      setMessage({ text: message, type: "error" });
      return;
    }

    if (addBag) {
      const nodeBag = nodeBagData?.Bag;
      if (!!!finalWeight) {
        console.log(finalWeight);
        message += `기록된 무게가 없어 백에 담을 수 없습니다.`;
        setMessage({ text: message, type: "error" });
        return;
      }
      if (existBag) {
        message += `이미 백에 담겨있는 요청입니다.(${existBag.name})`;
        setMessage({ text: message, type: "error" });
        return;
      }

      if (!!!nodeBag) {
        message += `선택한 백이 없습니다.`;
        setMessage({ text: message, type: "error" });
        return;
      }
      if (
        nodeBag.deliveryService.shippingType !==
        shipping.shippingType.shippingType
      ) {
        message += `백 담기 실패 | 배송 타입 불일치(백: ${nodeBag.deliveryService.shippingType} / ${shipping.shippingType.shippingType})`;
        setMessage({ text: message, type: "error" });
        return;
      }

      const nodeBagTotalWeight = getBagTotalWeight({
        bagReceptionLists: nodeBag?.bagReceptionLists,
      });
      const nodeBagLimitWeight = nodeBag?.weight;
      const nodeBagUpdatedWeight = nodeBagTotalWeight + finalWeight;
      if (nodeBagUpdatedWeight > nodeBagLimitWeight) {
        message += `백 담기 실패 | 무게 초과(${nodeBagUpdatedWeight}/${nodeBagLimitWeight})`;
        setMessage({ text: message, type: "error" });
        return;
      }
      // put bag action
      await updateRequestShipping({
        variables: {
          updateInput: {
            id: shipping.id,
            bag: nodeBag.id,
          },
        },
      });
      message += `백 담기 성공(${nodeBag.name})`;
      client.refetchQueries({ include: [BAG] });
    }
    if (apiCall) {
      let skip = false;
      if (apiTypeName === "GPS" && shipping.shippingNumber) {
        skip = !window.confirm(
          "이미 생성한 GPS 요청입니다 다시 생성하시겠습니까?"
        );
      }
      if (!!!finalWeight) {
        message += `기록된 무게가 없어서 API 요청을 할 수 없습니다.`;
        setMessage({ text: message, type: "error" });
        return;
      }
      if (!skip) {
        try {
          const apiShippingNumberResponse = await updateApiShippingNumber({
            variables: { id: shipping.id },
          });
          const callStatus =
            apiShippingNumberResponse.data.updateAPIShippingNumber;
          if (callStatus.apiStatus === "API_CALLED_ALREADY") {
            message += `이미 API 요청을 했습니다.\n`;
          } else if (callStatus.apiStatus === "API_ORDER_CREATED") {
            message += `API 생성 요청 성공\n`;
          } else if (callStatus.apiStatus === "API_ORDER_UPDATED") {
            message += `API 업데이트 요청 성공\n`;
          }
        } catch (e) {
          message += `API 이슈 발생으로 호출 실패`;
          console.log(e);
          setMessage({ text: message, type: "error" });
          return;
        }
      } else {
        message += `API 호출 취소`;
        setMessage({ text: message, type: "error" });
        return;
      }
    }
    if (print) {
      try {
        printRequestedLabels([[shipping]], null);
        message += `프린트 성공\n`;
      } catch {
        message += `프린트 실패\n`;
      }
    }
    client.refetchQueries({ include: [FIND_REQUEST_SHIPPING] });

    const type = addBag ? "success" : "info";
    setMessage({ text: message, type: type });
  };

  return (
    <Grid
      container
      style={{
        borderRadius: "10px",
        overflow: "hidden",
        border: "1px solid #E5E5E5",
      }}
    >
      <Grid
        item
        xs={12}
        style={{
          width: "70%",
        }}
      >
        <CustomInput
          input={
            <Grid
              container
              xs={12}
              style={{
                margin: 10,
              }}
              columnGap={1}
            >
              <div style={{ width: "auto" }}>
                <Input
                  ref={inputRef}
                  name="id"
                  type="text"
                  value={value}
                  onChange={(e) => {
                    setValue(e.target.value);
                  }}
                  onKeyPress={(event) => {
                    if (event?.key === "Enter" || event?.keyCode === 13) {
                      event.preventDefault();
                      inputConfirm();
                    }
                  }}
                  placeholder="배송번호, 주문번호, 접수번호, 출발국가택배번호"
                />
              </div>
              <CustomButton
                variant="outlined"
                width={80}
                onClick={inputConfirm}
              >
                확인
              </CustomButton>
            </Grid>
          }
          name="id"
          label="배송번호 조회 및 BAG 담기"
          padding="14px 20px 10px 20px"
          labelWidth="22%"
          style={{ margin: "10px" }}
        />
      </Grid>
    </Grid>
  );
};

export default NumberSubmit;

const Input = styled(CustomInput)`
  width: 400px !important;
`;
