import {
	Box,
	Divider,
	Modal,
	Stack,
	TextareaAutosize,
	Typography,
} from "@mui/material";
import React from "react";
import CustomButton from "../components/CustomButton";
import CustomCard from "../components/CustomCard";
import Icons from "../utils/icon";
import { useNavigate } from "react-router-dom";
import ExclamationIcon from "../Common/assets/Images/exclamationIcon";
import ConfirmIcon from "../Common/assets/Images/confirmIcon";

export default function ExportErrorModal({
	isOpen,
	onClose,
	onConfirm,
	isLoading = false,
	title = "",
	description = "",
	buttonText = "",
	setRequestDescription,
	warningIcon,
	update,
	api,
}) {
	const navigate = useNavigate();
	return (
		<Modal
			open={isOpen}
			onClose={onClose}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Box
				style={{
					padding: 20,
					overflowX: "hidden",
					minHeight: "100vh",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				<CustomCard>
					<Stack
						spacing={"20px"}
						width={370}
						height={400}
						p="20px"
						justifyContent={"space-evenly"}
					>
						<Stack
							direction="row"
							alignItems={"center"}
							spacing="10px"
							justifyContent={"center"}
						>
							<ExclamationIcon />
						</Stack>

						<Stack
							direction="column"
							alignItems={"center"}
							spacing="10px"
							justifyContent={"center"}
						>
							<Typography
								fontSize={"14px"}
								color="rgba(0,0,0,0.6)"
								component={"div"}
							>
								수출신고 요청이 실패했습니다.
							</Typography>
							<Typography
								fontSize={"14px"}
								color="rgba(0,0,0,0.6)"
								component={"div"}
							>
								관리자에게 문의해주세요.
							</Typography>

							<Typography
								fontSize={"14px"}
								color="rgba(0,0,0,0.2)"
								component={"div"}
							>
								(cs@shipter.kr)
							</Typography>
						</Stack>

						<div
							style={{
								display: "flex",
								justifyContent: "center",
								gap: "20px",
							}}
						>
							<CustomButton
								onClick={onClose}
								styles={{
									width: "auto",
									padding: 10,
									color: "#000",
								}}
								bg="#F8F8FA"
							>
								닫기
							</CustomButton>
						</div>
					</Stack>
				</CustomCard>
			</Box>
		</Modal>
	);
}
