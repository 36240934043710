/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Grid, MenuItem } from "@mui/material";
import { SelectBox } from "../../../../components/CustomInput";
import Button from "@mui/material/Button";
import TextSnippetOutlinedIcon from "@mui/icons-material/TextSnippetOutlined";
import CustomButton from "../../../../components/CustomButton";
import {
  getAllCountries,
  getShippingDetails,
  createRateMgmt,
  getRatesManagement,
  updateRateMgmt,
} from "../queries";
import Table2 from "../Table2";
import Toaster from "../../../../utils/Toaster";
import { exportXlsx, exportGroupSample } from "../utils/exportXlsx";
import { processXlxGroup, readXlx } from "../utils/readXlsx";
import UploadRateCardPopover from "../UploadRateCardPopover";

function Rates({ usergroupId }) {
  const [loading, setLoading] = useState(false);
  const [countries, setCountries] = useState([]);
  const [selectedcountry, setSelectedcountry] = useState("");
  const [shiptypes, setShiptypes] = useState();
  const [shipwithdetail, setShipwithdetail] = useState([]);
  const [finalData, setFinalData] = useState([]);
  const [postloading, setPostloading] = useState(false);
  const [update, setUpdate] = useState(false); //Flag to check if its gonna be update or add rates
  const [updateid, setUpdateid] = useState("");
  const [sheet, setSheet] = useState(null);
  const [fromsheet, setFromsheet] = useState(false);

  //Get countries
  useEffect(() => {
    getAllCountries().then((res) => {
      setCountries(res.data.allShippingCountries);
    });

    return () => {};
  }, []);

  //Change country
  const countrySelect = (e) => {
    setSelectedcountry(e.target.value.id);
    setShiptypes(e.target.value.shippingTypesDetailsIds);
  };

  //Get the ship details
  const getShipDetail = async (ships, rates) => {
    const result = await Promise.all(
      ships.map(async (prom) => {
        const contents = await getShippingDetails(prom);
        if (rates !== null) {
          return { ...rates, ...contents.data.allShippingTypeDetails[0] }; //returning by adding rates
        } else {
          if (contents.data.allShippingTypeDetails.length === 0) return [];
          return contents.data.allShippingTypeDetails[0];
        }
      })
    );
    return result;
  };

  //Gets rates
  useEffect(() => {
    if (usergroupId && selectedcountry && shiptypes) {
      setLoading(true);
      let filter = {
        userGroup_id: parseInt(usergroupId),
        country_id: parseInt(selectedcountry),
        default: false,
      };
      getRatesManagement(filter).then((res) => {
        const rates = res.data.allRatesManagements[0]
          ? res.data.allRatesManagements[0]
          : null;

        if (res.data.allRatesManagements[0] !== undefined) {
          setUpdate(true);
          setUpdateid(res.data.allRatesManagements[0].id);
        } else {
          setUpdate(false);
        }

        getShipDetail(JSON.parse(shiptypes), rates).then((res) => {
          setShipwithdetail(res);
          setLoading(false);
        });
      });
    }
    return () => {};
  }, [usergroupId, selectedcountry, shiptypes]);

  //Insert to db
  const insertRate = () => {
    let pack = {
      countryId: selectedcountry,
      userGroupId: parseInt(usergroupId),
      default: false,
    };
    let rates = [];
    finalData.forEach((data) => {
      rates = [...rates, ...data.data];
    });
    pack.ratesDetails = rates.map((res) => ({
      ...res,
      weight: Number(res.weight),
      deliveryFee: Number(res.deliveryFee),
    }));
    setPostloading(true);
    createRateMgmt(pack).then(
      (res) => {
        setPostloading(false);
        setUpdate(true);
        setUpdateid(res.data.createRatesManagement.id);
        Toaster("success", "요율이 성공적으로 업데이트 되었습니다.");
      },
      (err) => {
        setPostloading(false);
        Toaster("error", "에러가 발생하였습니다.");
      }
    );
  };

  //Update in db
  const updateRate = () => {
    let pack = {
      id: parseInt(updateid),
      countryId: selectedcountry,
      userGroupId: parseInt(usergroupId),
      default: false,
    };
    let rates = [];
    finalData.forEach((data) => {
      const newRatObj = data.data.map(({ __typename, ...rest }) => rest);
      rates = [...rates, ...newRatObj];
    });
    pack.ratesDetails = rates.map((res) => ({
      ...res,
      weight: Number(res.weight),
      deliveryFee: Number(res.deliveryFee),
    }));
    setPostloading(true);
    updateRateMgmt(pack).then(
      (res) => {
        setPostloading(false);
        Toaster("success", "요율 업데이트가 완료되었습니다.");
      },
      (err) => {
        setPostloading(false);
        Toaster("error", "요율 업데이트를 실패하였습니다.");
      }
    );
  };

  //Export XLX
  const exportXlx = () => {
    if (finalData.length === 0)
      return Toaster("error", "해당 국가에 해당하는 요율이 없습니다.");
    let country = countries.find((el) => el.id === selectedcountry);
    exportXlsx(finalData, country, shipwithdetail);
  };

  //Making inital finalData when obtaned from server
  useEffect(() => {
    if (shipwithdetail.length !== 0) {
      const cloneShipDetail = [...shipwithdetail];
      let initData = [];
      cloneShipDetail.forEach((d, i) => {
        // if (Object.hasOwn(d, "ratesDetails")) {
        let obj = {};
        obj.index = i;

        if (Object.hasOwn(d, "ratesDetails")) {
          const filterData = d.ratesDetails.filter(
            (el) => el.shippingTypeDetailId === d.id
          );
          obj.data = filterData;
        }

        initData.unshift(obj);
        // }
      });
      setFinalData(initData);
    }
    return () => {};
  }, [shipwithdetail]);

  //Get table 1 data
  const setData1 = (data) => {
    const cloneFinal = [...finalData];
    const index = cloneFinal.findIndex((el) => el.index === data.index);
    if (index !== -1) {
      cloneFinal[index].data = data.data;
      setFinalData(cloneFinal);
    } else {
      cloneFinal.push(data);
      setFinalData(cloneFinal);
    }
  };

  //Set file sheet
  const setFile = (e) => {
    setSheet(e.target.files[0]);
  };

  const handleChange = (file) => {
    readXlx(file).then((res) => {
      console.log("readXlx", res);
      processXlxGroup(res, countries).then(
        (response) => {
          console.log("processXlxgroup", response);
          setShipwithdetail(response.shipments);
          setFromsheet(response.shipments);
          setSelectedcountry(response.country.id);
          console.log("insheetRates", res);

          //Checking the selected country already has the data for rates
          let filter = {
            userGroup_id: parseInt(usergroupId),
            country_id: parseInt(response.country.id),
          };
          //Getting rates
          getRatesManagement(filter).then((res) => {
            console.log("get rates management", res);
            if (res.data.allRatesManagements[0] !== undefined) {
              setUpdate(true);
              setUpdateid(res.data.allRatesManagements[0].id);
            } else {
              setUpdate(false);
            }
          });
        },
        (err) => {
          console.log(err);
        }
      );
    });
  };

  return (
    <Grid
      // item
      container
      spacing={3}
      justifyContent={"space-between"}
      alignItems={"center"}
    >
      <Grid xs={4} item>
        {countries && (
          <SelectBox onChange={countrySelect}>
            {countries
              .filter((el) => el.isActive === true)
              .map((country) => (
                <MenuItem key={`countruID${country.id}`} value={country}>
                  {country.name}
                </MenuItem>
              ))}
          </SelectBox>
        )}
        {fromsheet && (
          <div style={{ wordBreak: "keep-all" }}>
            <small>
              Country detected from Sheets :
              <b>{countries.find((el) => el.id === selectedcountry).name}</b>
            </small>
          </div>
        )}
      </Grid>

      <Grid xs={6} item container gap={3} justifyContent={"flex-end"}>
        <Grid xs={4} item>
          {shipwithdetail.length > 0 ? (
            <div
              style={{
                display: "flex",
                cursor: "pointer",
                justifyContent: "flex-end",
              }}
            >
              <CustomButton
                styles={{
                  width: "90%",
                  whiteSpace: "nowrap",
                  color: "black",
                  backgroundColor: "transparent",
                  border: "1px solid #EEEEEE",
                }}
                onClick={exportXlx}
              >
                <img
                  style={{ width: "30px", paddingRight: "10px" }}
                  src="/assets/images/excel.png"
                  alt="excel"
                ></img>
                엑셀 다운로드
              </CustomButton>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                cursor: "pointer",
                justifyContent: "flex-end",
              }}
            >
              <CustomButton
                styles={{
                  width: "90%",
                  whiteSpace: "nowrap",
                  color: "black",
                  backgroundColor: "transparent",
                  border: "1px solid #EEEEEE",
                }}
                onClick={exportGroupSample}
              >
                <img
                  style={{ width: "30px", paddingRight: "10px" }}
                  src="/assets/images/excel.png"
                  alt="excel"
                ></img>
                요율표 양식 다운로드
              </CustomButton>
            </div>
          )}
        </Grid>

        {/* <Grid xs={4} item>
				<Button
					variant="contained"
					component="label"
					style={{
						backgroundColor: "white",
						boxShadow: "none",
						borderRadius: "10px",
						height: "40px",
						marginLeft: "5px",
						whiteSpace: "nowrap",
						display: "flex",
						justifyContent: "space-between",
						border: "1px solid rgb(238, 238, 238)",
						color: "#718096",
					}}
				>
					{sheet !== null ? sheet.name : " 요율표 업로드"}
					<input type="file" hidden onChange={setFile} />
					<TextSnippetOutlinedIcon />
				</Button>
			</Grid>

			<Grid xs={2} item>
				<div
					style={{
						display: "flex",
						cursor: "pointer",
						justifyContent: "flex-end",
					}}
				>
					<CustomButton
						variant={"outlined"}
						styles={{
							width: "80%",
							color: "#427DF3",
							fontWeight: "bold",
							border: "1px solid #427DF3",
							// height: "40px",
							padding: "7px",
							borderRadius: 5,
						}}
						onClick={handleChange}
					>
						등록
					</CustomButton>
				</div>
			</Grid> */}
        <Grid item xs={4}>
          <UploadRateCardPopover sendFile={handleChange} />
        </Grid>
      </Grid>

      {loading ? (
        <Grid xs={12} container justifyContent={"center"} alignItems={"center"}>
          Loading...
        </Grid>
      ) : (
        shipwithdetail.length > 0 &&
        shipwithdetail.map((ship, index) => (
          <Grid item xs={6} key={`rateTables${ship.id}`}>
            <Table2 getData={setData1} data={ship} index={index} />
          </Grid>
        ))
      )}

      {selectedcountry === "" && !fromsheet && (
        <Grid
          item
          xs={12}
          container
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          국가를 선택하세요.
        </Grid>
      )}

      {shipwithdetail.length > 0 && (
        <Grid xs={12} item>
          <Grid
            xs={12}
            container
            justifyContent={"flex-start"}
            alignItems={"center"}
            mt={4}
            item
          >
            {!postloading ? (
              <>
                {update ? (
                  <Button
                    variant="outlined"
                    disabled={
                      selectedcountry !== "" || fromsheet ? false : true
                    }
                    onClick={updateRate}
                  >
                    업데이트
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    disabled={
                      selectedcountry !== "" || fromsheet ? false : true
                    }
                    onClick={insertRate}
                  >
                    저장
                  </Button>
                )}
              </>
            ) : (
              <>{update ? "Updating..." : "Adding..."}</>
            )}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}

export default Rates;
