import {
  Box,
  Grid,
  Card,
  CardContent,
  CardHeader,
  Typography,
} from "@mui/material";
import { Formik, Form } from "formik";
import { useState } from "react";
import CustomInput from "../../components/CustomInput";
import CustomButton from "../../components/CustomButton";
import * as Yup from "yup";
import { APIClient } from "../../utils/apiGraphql";
import { gql } from "@apollo/client/core";
import Toaster from "../../utils/Toaster";
import { useNavigate, Navigate } from "react-router-dom";
import { useUtils } from "../../utils/utils";

function Signup() {
  const { role } = useUtils();
  const navigate = useNavigate();

  const [initialValues, setInitialValues] = useState({
    userID: "",
    password: "",
    email: "",
    username: "",
    customer_name: "",
    company_registration_number: "",
    company_name_en: "",
    company_name_kr: "",
    phone_number: "",
  });

  const validationSchema = () => {
    return Yup.object().shape({
      userID: Yup.string().required(),
      password: Yup.string().required(),
      email: Yup.string().email().required(),
      username: Yup.string().required(),
      customer_name: Yup.string().required(),
      company_registration_number: Yup.string().required(),
      company_name_en: Yup.string().required(),
      company_name_kr: Yup.string().required(),
      phone_number: Yup.string().required(),
    });
  };

  const handleSubmit = async (values) => {
    const SIGNUP = gql(`
      mutation signUp($input: UserCreateInput!) {
        signUp(signup: $input) {
          id
          userID
          username
          email
          customer_name
          company_registration_number
          company_name_en
          company_name_kr
          phone_number
          mobile_number
          fax_number
        }
      }
    `);
    try {
      const userData = await APIClient.mutate({
        mutation: SIGNUP,
        variables: {
          input: {
            ...values,
            role: "client",
          },
        },
      });

      navigate("/login", { replace: true });
    } catch (e) {
      Toaster("error", e.message);
    }
  };

  if (role) {
    return <Navigate to="/" />;
  }

  return (
    <Box height={"100vh"}>
      <Grid
        container
        sx={{ height: "100%" }}
        alignItems="center"
        justifyContent="center"
      >
        <Grid item xs={11} md={4} sm={6}>
          <Card sx={{ width: "100%" }}>
            <CardHeader
              title={
                <Typography align={"center"} variant={"h4"}>
                  Signup
                </Typography>
              }
            />
            <CardContent>
              <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
              >
                {({ values, handleChange, errors, handleSubmit }) => {
                  return (
                    <Form onSubmit={handleSubmit}>
                      <Box display={"flex"} flexDirection={"column"} gap={1}>
                        <CustomInput
                          label={"User ID"}
                          labelColor=""
                          name={"userID"}
                          onChange={handleChange}
                          values={values.userID}
                          error={!!errors.userID}
                          padding="10px 0"
                          placeholder="User ID"
                        />

                        <CustomInput
                          label={"Email"}
                          labelColor=""
                          name={"email"}
                          onChange={handleChange}
                          values={values.email}
                          error={!!errors.email}
                          padding="10px 0"
                          placeholder="Email"
                        />

                        <CustomInput
                          label={"Username"}
                          labelColor=""
                          name={"username"}
                          onChange={handleChange}
                          values={values.username}
                          error={!!errors.username}
                          padding="10px 0"
                          placeholder="Username"
                        />

                        <CustomInput
                          label={"Customer name"}
                          labelColor=""
                          name={"customer_name"}
                          onChange={handleChange}
                          values={values.customer_name}
                          error={!!errors.customer_name}
                          padding="10px 0"
                          placeholder="Customer name"
                        />

                        <CustomInput
                          label={"Company registration number"}
                          labelColor=""
                          name={"company_registration_number"}
                          onChange={handleChange}
                          values={values.company_registration_number}
                          error={!!errors.company_registration_number}
                          padding="10px 0"
                          placeholder="Company registration number"
                        />

                        <CustomInput
                          label={"Company name (English)"}
                          labelColor=""
                          name={"company_name_en"}
                          onChange={handleChange}
                          values={values.company_name_en}
                          error={!!errors.company_name_en}
                          padding="10px 0"
                          placeholder="Company name (English)"
                        />

                        <CustomInput
                          label={"Company name (Korean)"}
                          labelColor=""
                          name={"company_name_kr"}
                          onChange={handleChange}
                          values={values.company_name_kr}
                          error={!!errors.company_name_kr}
                          padding="10px 0"
                          placeholder="Company name (Korean)"
                        />

                        <CustomInput
                          label={"Phone number"}
                          labelColor=""
                          name={"phone_number"}
                          onChange={handleChange}
                          values={values.phone_number}
                          error={!!errors.phone_number}
                          padding="10px 0"
                          placeholder="phone number"
                        />

                        <CustomInput
                          label={"Password"}
                          labelColor=""
                          name={"password"}
                          type={"password"}
                          onChange={handleChange}
                          values={values.password}
                          error={!!errors.password}
                          padding="10px 0"
                          placeholder="Password"
                        />

                        <CustomButton
                          styles={{
                            width: "auto",
                          }}
                          type={"submit"}
                        >
                          Sign up
                        </CustomButton>
                      </Box>
                    </Form>
                  );
                }}
              </Formik>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Signup;
