import { Button, Divider, Grid, Stack, Typography } from "@mui/material";
import CustomInput, { TextFieldBox } from "../../../components/CustomInput";
import CustomButton from "../../../components/CustomButton";
import { gql } from "@apollo/client/core";
import { APIClient } from "../../../utils/apiGraphql";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import React, { useState, useEffect } from "react";
import UpdateHOC from "../../../SharedComponents/UpdateHOC";
import Toaster from "../../../utils/Toaster";

export function ClientInfoSettings({ ...props }) {
	return React.createElement(UpdateHOC, {
		children: ClientInfoSettingsContainer,
		_resource: "User",
		...props,
	});
}

function ClientInfoSettingsContainer({ ...hocProps }) {
	const [initialValues, setInitialValues] = useState({
		id: 0,
		userID: "",
		customer_name: "",
		username: "",
		phone_number: "",
		email: "",
		business_item: "",
		business_condition: "",
		contact_name: "",
		company_registration_number: "",
		address_1: "",
		address_2: "",
		website_address: "",
		company_name_en: "",
		business_registration: "",
		english_address_city: "",
		english_address_street: "",
		english_address_state: "",
		english_address_full_address: "",
		english_address_zip_code: "",
		code: "",
	});

	const [businessRegistration, setBusinessRegistration] = useState("");

	const validationSchema = () => {
		return Yup.object().shape({
			id: Yup.number().required(),
			username: Yup.string().required(),
			// business_condition: Yup.string(),
			email: Yup.string().email().required(),
			customer_name: Yup.string().required(),
			company_registration_number: Yup.string(),
			phone_number: Yup.string().required(),
			business_condition: Yup.string().required().max(15),
			business_item: Yup.string().required(),
			contact_name: Yup.string().required(),
			address_1: Yup.string().required(),
			address_2: Yup.string().required(),
			website_address: Yup.string(),
			company_name_en: Yup.string().required(),
			english_address_city: Yup.string().required(),
			english_address_street: Yup.string().required(),
			english_address_state: Yup.string().required(),
			english_address_full_address: Yup.string().required(),
			english_address_zip_code: Yup.number().required(),
			code: Yup.string(),
			business_registration: Yup.string(),
		});
	};

	const fetchUser = async () => {
		const Query = gql(`query User{
      User{
        username
        email
        role
        customer_name
        id
        business_registration
        company_registration_number
        phone_number
        business_item
        contact_name
        address_1
        address_2
        company_name_en
        website_address
        english_address_city
        english_address_street
        english_address_state
        english_address_full_address
        english_address_zip_code
        code
        userID,
        business_condition
      }
    }`);

		try {
			const {
				data: {
					User: {
						username,
						email,
						role,
						customer_name,
						id,
						business_registration,
						company_registration_number,
						phone_number,
						business_condition,
						business_item,
						contact_name,
						address_1,
						address_2,
						company_name_en,
						website_address,
						english_address_city,
						english_address_street,
						english_address_state,
						english_address_full_address,
						english_address_zip_code,
						userID,
					},
				},
			} = await APIClient.query({
				query: Query,
			});
			setInitialValues({
				username,
				email,
				role,
				customer_name,
				id,
				company_registration_number,
				phone_number,
				business_condition,
				business_item,
				contact_name,
				address_1,
				address_2,
				company_name_en,
				website_address,
				english_address_city,
				english_address_street,
				english_address_state,
				english_address_full_address,
				english_address_zip_code,
				code: String(userID),
			});
			setBusinessRegistration(business_registration);
		} catch (e) {
			console.log(e);
		}
	};

	useEffect(() => {
		fetchUser();
	}, []);

	const updateUser = async (values) => {
		const Mutation = gql(`
      mutation updateUser($updateInput: UpdateUserInput!) {
        updateUser(updateInput: $updateInput) {
          id
          customer_name
          username
          phone_number
          email
          business_item
          contact_name
          company_registration_number
          address_1
          address_2
          website_address
          company_name_en
          english_address_city
          english_address_street
          english_address_state
          english_address_full_address
          english_address_zip_code
          business_condition
          code
        }
      }
    `);

		try {
			await APIClient.mutate({
				mutation: Mutation,
				variables: {
					updateInput: {
						...values,
					},
				},
			});
			Toaster("success", "고객사 정보가 성공적으로 업데이트 되었습니다.");
		} catch (e) {
			Toaster("error", e.message);
		}
	};

	const handleSubmit = async (values, formikUtils) => {
		console.log("working");
		if (!businessRegistration) {
			Toaster("error", "Business registration cannot be empty.");
		} else {
			values = {
				...values,
				business_registration: businessRegistration,
			};
			updateUser(values);
		}
	};

	return (
		<Formik
			initialValues={initialValues}
			onSubmit={handleSubmit}
			validationSchema={validationSchema}
			enableReinitialize
		>
			{({
				values,
				errors,
				handleChange,
				handleSubmit,
				submitCount,
				isSubmitting,
				isValid,
			}) => {
				return (
					<Form onSubmit={handleSubmit}>
						<Stack spacing="40px">
							<div
								style={{
									borderRadius: "10px",
									overflow: "hidden",
									border: "1px solid #E5E5E5",
								}}
							>
								<Grid container>
									<Grid item xs={6}>
										<CustomInput
											value={values.code}
											name={"code"}
											// onChange={handleChange}
											// error={
											// 	submitCount > 0 &&
											// 	!isSubmitting &&
											// 	!isValid &&
											// 	!!errors.code
											// }
											label="코드"
											padding="22px 20px"
											style={{ padding: "10px", width: "100%" }}
											readOnly={true}
										/>
									</Grid>

									<Grid item xs={6}>
										<CustomInput
											value={values.company_registration_number}
											// error={
											// 	submitCount > 0 &&
											// 	!isSubmitting &&
											// 	!isValid &&
											// 	!!errors.company_registration_number
											// }
											// onChange={handleChange}
											name={"company_registration_number"}
											label="사업자 등록번호*"
											labelWidth="180px"
											style={{ padding: "10px", width: "100%" }}
											readOnly={true}
										/>
									</Grid>

									<Grid item xs={12}>
										<Divider />
									</Grid>

									<Grid item xs={6}>
										<CustomInput
											value={values.customer_name}
											name={"customer_name"}
											// error={
											// 	submitCount > 0 &&
											// 	!isSubmitting &&
											// 	!isValid &&
											// 	!!errors.customer_name
											// }
											// onChange={handleChange}
											label="고객사 이름*"
											style={{ padding: "10px", width: "100%" }}
											readOnly={true}
										/>
									</Grid>

									<Grid item xs={6}>
										<CustomInput
											value={values.business_condition}
											name={"business_condition"}
											// onChange={handleChange}
											// error={
											// 	submitCount > 0 &&
											// 	!isSubmitting &&
											// 	!isValid &&
											// 	!!errors.business_condition
											// }
											label="사업 업태*"
											padding="22px 20px"
											style={{ padding: "10px", width: "100%" }}
											labelWidth="180px"
											readOnly={true}
										/>
									</Grid>

									<Grid item xs={12}>
										<Divider />
									</Grid>

									<Grid item xs={6}>
										<CustomInput
											value={values.username}
											name={"username"}
											onChange={handleChange}
											error={
												submitCount > 0 &&
												!isSubmitting &&
												!isValid &&
												!!errors.username
											}
											label="대표자 이름*"
											style={{ padding: "10px", width: "100%" }}
										/>
									</Grid>

									<Grid item xs={6}>
										<CustomInput
											value={values.business_item}
											name={"business_item"}
											// onChange={handleChange}
											// error={
											// 	submitCount > 0 &&
											// 	!isSubmitting &&
											// 	!isValid &&
											// 	!!errors.business_item
											// }
											label="사업 종목*"
											padding="22px 20px"
											style={{ padding: "10px", width: "100%" }}
											labelWidth="180px"
											readOnly={true}
										/>
									</Grid>

									<Grid item xs={12}>
										<Divider />
									</Grid>

									<Grid item xs={6}>
										<CustomInput
											value={values.phone_number}
											name={"phone_number"}
											onChange={handleChange}
											error={
												submitCount > 0 &&
												!isSubmitting &&
												!isValid &&
												!!errors.phone_number
											}
											label="전화번호*"
											padding="22px 20px"
											style={{ padding: "10px", width: "100%" }}
										/>
									</Grid>

									<Grid item xs={6}>
										<CustomInput
											value={values.contact_name}
											name={"contact_name"}
											onChange={handleChange}
											error={
												submitCount > 0 &&
												!isSubmitting &&
												!isValid &&
												!!errors.contact_name
											}
											label="담당자 이름*"
											style={{ padding: "10px", width: "100%" }}
											labelWidth="180px"
										/>
									</Grid>

									<Grid item xs={12}>
										<Divider />
									</Grid>

									<Grid item xs={6}>
										<CustomInput
											value={values.email}
											name={"email"}
											onChange={handleChange}
											error={
												submitCount > 0 &&
												!isSubmitting &&
												!isValid &&
												!!errors.email
											}
											label="이메일*"
											padding="22px 20px"
											style={{ padding: "10px", width: "100%" }}
										/>
									</Grid>

									<Grid item xs={6}>
										<CustomInput
											value={values.phone_number}
											name={"phone_number"}
											onChange={handleChange}
											error={
												submitCount > 0 &&
												!isSubmitting &&
												!isValid &&
												!!errors.phone_number
											}
											label="핸드폰 번호*"
											style={{ padding: "10px", width: "100%" }}
											labelWidth="180px"
										/>
									</Grid>

									<Grid item xs={12}>
										<Divider />
									</Grid>

									<Grid item xs={6}>
										<CustomInput
											label="주소1*"
											input={
												<Grid
													spacing="10px"
													container
													sx={{
														display: "flex",
														alignItems: "center",
														justifyContent: "center",
														p: 1,
													}}
												>
													<Grid item xs={9}>
														<TextFieldBox
															value={values.address_1}
															name={"address_1"}
															onChange={handleChange}
															error={
																submitCount > 0 &&
																!isSubmitting &&
																!isValid &&
																!!errors.address_1
															}
															placeholder="서울 강서구 양천로 36-4"
														/>
													</Grid>
													<Grid item xs={3}>
														<Button
															fullWidth
															style={{ padding: "6px 0" }}
															variant="outlined"
														>
															찾기
														</Button>
													</Grid>
												</Grid>
											}
										/>
									</Grid>

									<Grid item xs={6}>
										<CustomInput
											value={values.address_2}
											name={"address_2"}
											onChange={handleChange}
											error={
												submitCount > 0 &&
												!isSubmitting &&
												!isValid &&
												errors.address_2
											}
											label="주소2*"
											style={{ padding: "10px", width: "100%" }}
											labelWidth="180px"
										/>
									</Grid>

									<Grid item xs={12}>
										<Divider />
									</Grid>

									<Grid item xs={6}>
										<CustomInput
											value={values.website_address}
											name={"website_address"}
											onChange={handleChange}
											error={
												submitCount > 0 &&
												!isSubmitting &&
												!isValid &&
												!!errors.website_address
											}
											label="홈페이지 주소"
											style={{ padding: "10px", width: "100%" }}
										/>
									</Grid>

									<Grid item xs={6}>
										<CustomInput
											label="사업자 등록증*"
											input={
												<Grid
													spacing="10px"
													alignItems={"center"}
													container
													sx={{ p: 2 }}
												>
													<Grid item xs={9}>
														<a
															href={businessRegistration}
															target="_blank"
															rel="noreferrer"
														>
															{
																businessRegistration.split("/")[
																	businessRegistration.split("/").length - 1
																]
															}
														</a>
													</Grid>
													{/* <Grid item xs={3}>
														<Button
															variant="outlined"
															component="label"
															fullWidth
															style={{ padding: "6px 0" }}
														>
															변경
															<input
																type="file" */}
													{/* // 			hidden
													// 			onChange={(event) => { */}
													{/* // 				setBusinessRegistration(
													// 					event.target.files[0].name
													// 				);
													// 			}}
													// 		/>
													// 	</Button> */}
													{/* // </Grid> */}
												</Grid>
											}
											labelWidth="180px"
										/>
									</Grid>
								</Grid>
							</div>

							<Stack spacing={"20px"}>
								<Typography>발송인 정보 수정</Typography>

								<Grid
									container
									style={{
										borderRadius: "10px",
										overflow: "hidden",
										border: "1px solid #E5E5E5",
									}}
								>
									<Grid item xs={6}>
										<CustomInput
											value={values.company_name_en}
											name={"company_name_en"}
											onChange={handleChange}
											error={
												submitCount > 0 &&
												!isSubmitting &&
												!isValid &&
												!!errors.company_name_en
											}
											label="Name*"
											style={{ padding: "10px", width: "100%" }}
										/>
									</Grid>

									<Grid item xs={6}>
										<CustomInput
											value={values.english_address_street}
											name={"english_address_street"}
											onChange={handleChange}
											error={
												submitCount > 0 &&
												!isSubmitting &&
												!isValid &&
												!!errors.english_address_street
											}
											label="Street*"
											style={{ padding: "10px", width: "100%" }}
										/>
									</Grid>

									<Grid item xs={12}>
										<Divider />
									</Grid>

									<Grid item xs={6}>
										<CustomInput
											value={values.english_address_city}
											name={"english_address_city"}
											onChange={handleChange}
											error={
												submitCount > 0 &&
												!isSubmitting &&
												!isValid &&
												!!errors.english_address_city
											}
											label="City*"
											style={{ padding: "10px", width: "100%" }}
										/>
									</Grid>

									<Grid item xs={6}>
										<CustomInput
											value={values.english_address_state}
											name={"english_address_state"}
											onChange={handleChange}
											error={
												submitCount > 0 &&
												!isSubmitting &&
												!isValid &&
												!!errors.english_address_state
											}
											label="State*"
											style={{ padding: "10px", width: "100%" }}
										/>
									</Grid>

									<Grid item xs={12}>
										<Divider />
									</Grid>

									<Grid item xs={6}>
										<CustomInput
											value={values.english_address_zip_code}
											name={"english_address_zip_code"}
											onChange={handleChange}
											error={
												submitCount > 0 &&
												!isSubmitting &&
												!isValid &&
												!!errors.english_address_zip_code
											}
											label="Zipcode*"
											padding="22px 20px"
											style={{ padding: "10px", width: "100%" }}
										/>
									</Grid>

									<Grid item xs={6}>
										<CustomInput
											name={"english_address_full_address"}
											value={values.english_address_full_address}
											onChange={handleChange}
											error={
												submitCount > 0 &&
												!isSubmitting &&
												!isValid &&
												!!errors.english_address_full_address
											}
											label="Full Address*"
											style={{ padding: "10px", width: "100%" }}
										/>
									</Grid>
								</Grid>
							</Stack>

							<div
								style={{
									display: "flex",
									justifyContent: "flex-end",
									gap: "20px",
								}}
							>
								<CustomButton type={"submit"} width="100px">
									수정
								</CustomButton>
							</div>
						</Stack>
					</Form>
				);
			}}
		</Formik>
	);
}
