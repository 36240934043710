import React, { useEffect, useState, useMemo, useRef } from "react";
import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import { useRedirect } from "react-admin";
import { DeliveryTimeGrid } from "../../components/DeliveryTimeGrid";
import Moment from "moment";
import { gql } from "@apollo/client/core";
import PublicIcon from "@mui/icons-material/Public";
import PersonIcon from "@mui/icons-material/Person";
import { useGetList } from "../../utils/utils";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import DownloadIcon from "@mui/icons-material/Download";
import SavingsIcon from "@mui/icons-material/Savings";
import OutputIcon from "@mui/icons-material/Output";
import AirplanemodeActiveIcon from "@mui/icons-material/AirplanemodeActive";
import Loader from "../../components/Loader";
import InputIcon from "@mui/icons-material/Input";
import { useUtils } from "../../utils/utils";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import { APIClient } from "../../utils/apiGraphql";
import {
  allSettlementHistoryStats,
  allShippingSummary,
  DASHBOARD_SHIPPING_STATS,
} from "../../utils/constants";

export default function ClientDashboard() {
  const [notices, setNotices] = useState([]);
  const [add1, setAdd1] = useState(null);
  const [add2, setAdd2] = useState(null);
  const [add3, setAdd3] = useState(null);
  const [rate1, setRate1] = useState(null);
  const [rate2, setRate2] = useState(null);
  const [balance, setBalance] = React.useState(null);
  const [dashboardStats, setDashboardStats] = useState(null);
  const { userData } = useUtils();
  const { getData, isLoading } = useGetList();
  const redirect = useRedirect();

  const userDetails = useMemo(() => {
    return userData;
  }, [userData]);

  useEffect(() => {
    (async () => {
      const noticesResponseData = await getData("Notice");
      if (noticesResponseData) {
        setNotices(noticesResponseData);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchAddData("Add1");
    fetchAddData("Add2");
    fetchAddData("Add3");
    fetchRateData("Rate1");
    fetchRateData("Rate2");
    getDashboardShippingDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchUser = async () => {
    const Query = gql(`query User{
      User{
        company_name_kr
        balance
      }
    }`);

    try {
      const {
        data: {
          User: { company_name_kr, balance },
        },
      } = await APIClient.query({
        query: Query,
      });
      setBalance(balance);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchUser();
  }, []);

  const compareDates = (date) => {
    const presentDate = Moment();
    let diffDays = presentDate.diff(date, "days");

    if (diffDays <= 7) {
      return true;
    } else {
      return false;
    }
  };

  const getDashboardShippingDetails = async () => {
    try {
      const {
        data :{clientDashboardShippingStats}
      } = await APIClient.query({
        query: DASHBOARD_SHIPPING_STATS,
      });
      setDashboardStats(clientDashboardShippingStats);
    } catch (e) {
      console.error(e.message);
    }
  };

  const fetchAddData = async (type) => {
    const Query = gql(`
      query getGeneralData($type: String!) {
        getGeneralData(type: $type) {
          id
          type
					data
					isAttachment
					createdAt
        }
      }
    `);

    try {
      const {
        data: { getGeneralData },
      } = await APIClient.query({
        query: Query,
        variables: { type },
      });
      if (type === "Add1") {
        setAdd1(handleAttachmentData(getGeneralData));
      } else if (type === "Add2") {
        setAdd2(handleAttachmentData(getGeneralData));
      } else if (type === "Add3") {
        setAdd3(handleAttachmentData(getGeneralData));
      }
    } catch (e) {
      console.log(e);
    }
  };

  const fetchRateData = async (type) => {
    const Query = gql(`
      query getGeneralData($type: String!) {
        getGeneralData(type: $type) {
          id
          type
					data
					isAttachment
					createdAt
        }
      }
    `);

    try {
      const {
        data: { getGeneralData },
      } = await APIClient.query({
        query: Query,
        variables: { type },
      });
      if (type === "Rate1") {
        setRate1(handleAttachmentData(getGeneralData));
      } else if (type === "Rate2") {
        setRate2(handleAttachmentData(getGeneralData));
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleAttachmentData = (data) => {
    if (data && data.isAttachment) {
      return { ...JSON.parse(data.data), createdAt: data.createdAt };
    }
    return { url: data.data, name: null, createdAt: data.createdAt };
  };

  const downloadFile = async ({ name, url: downloadUrl }) => {
    fetch(downloadUrl)
      .then((res) => res.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        var link = document.createElement("a");
        link.href = url;
        link.download = name;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
  };

  return (
    <Stack spacing={"20px"} style={{ maxWidth: "1150px" }}>
      <Grid container alignItems={"flex-start"} spacing={"20px"}>
        <Grid item xs={12} padding="0" marginTop={2}>
          <Box
            style={{
              padding: "20px",
              border: "1px solid #C6C6C6",
              borderRadius: 50,
            }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography
                variant="h6"
                style={{
                  marginLeft: "20px",
                  fontWeight: "bold",
                  whiteSpace: "nowrap",
                  width: "10%",
                }}
              >
                공지사항
              </Typography>
              <Grid sx={{ width: "90%" }}>
                {!isLoading ? (
                  notices
                    .sort((a, b) => b.id - a.id)
                    .slice(0, 1)
                    .map((item, index) => (
                      <Grid
                        item
                        xs={12}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          margin: "auto 2%",
                          paddingTop: "6px",
                        }}
                      >
                        <div
                          onClick={() => redirect(`/notice/${item.id}/show`)}
                          style={{
                            cursor: "pointer",
                            display: "flex",
                            width: "100%",
                          }}
                        >
                          <Typography
                            overflow={"hidden"}
                            textOverflow={"ellipsis"}
                            fontSize={"14px"}
                            sx={{ ml: 1, width: "90%" }}
                          >
                            {item.title}
                          </Typography>
                          <Typography
                            color="#C1C1C1"
                            fontSize={"14px"}
                            sx={{ width: "10%" }}
                          >
                            {Moment(item.date).format("YYYY-MM-DD")}
                          </Typography>
                        </div>
                      </Grid>
                    ))
                ) : (
                  <Loader />
                )}
              </Grid>
            </div>
          </Box>
        </Grid>
        <Grid container sx={{ marginTop: "50px", paddingLeft: "20px" }}>
          <Box
            xs={5}
            sx={{
              border: "1px solid rgb(198, 198, 198)",
              width: "49%",
              margin: "auto",
              borderRadius: "30px",
            }}
          >
            <Grid
              container
              sx={{
                justifyContent: "space-between",
                paddingLeft: "30px",
                paddingRight: "30px",
                paddingTop: "18px",
                paddingBottom: "10px",
                borderBottom: "1px solid rgb(198, 198, 198)",
              }}
            >
              <Grid>
                <Typography>
                  <LocalShippingIcon
                    sx={{ color: "#4EADFB", verticalAlign: "top" }}
                  />
                  <Typography
                    sx={{
                      display: "inline-block",
                      color: "#4EADFB",
                      fontWeight: "bold",
                      marginLeft: "8px",
                    }}
                  >
                    입고
                  </Typography>
                </Typography>
                <Typography sx={{ color: "#718096" }}>최근 7일 기준</Typography>
              </Grid>
              <Grid>
                <Typography sx={{ fontWeight: "bold", textAlign: "right" }}>
                  입고 월간수량
                </Typography>
                <Typography sx={{ color: "#718096" }}>
                  당월 기준 {Moment().startOf("month").format("YYYY-MM-DD")}~
                  {Moment().endOf("month").format("MM-DD")}
                </Typography>
              </Grid>
            </Grid>
            <Grid container sx={{ padding: "30px" }}>
              {dashboardStats && (
                <>
                  <Grid sx={{ width: "45%", padding: "10px" }}>
                    <Grid container>
                      <Grid
                        sx={{
                          backgroundColor: "#4EADFB",
                          borderRadius: "100%",
                          width: "80px",
                          height: "80px",
                          paddingTop: "20px",
                          textAlign: "center",
                        }}
                      >
                        <InputIcon
                          sx={{ color: "white", width: "40px", height: "40px" }}
                        />
                      </Grid>
                      <Grid sx={{ marginTop: "8px", marginLeft: "10px" }}>
                        <Typography
                          sx={{ color: "#787878", fontWeight: "bold" }}
                        >
                          입고 완료
                        </Typography>
                        <Typography
                          sx={{ fontSize: "30px", fontWeight: "bold" }}
                        >
                          {dashboardStats?.weeklyData?.quantityReceived}
                          <Typography
                            sx={{
                              display: "inline",
                              fontSize: "16px",
                              fontWeight: "normal",
                              marginLeft: "5px",
                              color: "#787878",
                            }}
                          >
                            건
                          </Typography>
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    sx={{
                      width: "24.5%",
                      backgroundColor: "#F8FAFF",
                      borderRadius: "8px",
                      height: "100px",
                      marginLeft: "3%",
                      padding: "20px",
                    }}
                  >
                    <Typography sx={{ color: "#787878" }}>접수완료</Typography>
                    <Typography
                      sx={{
                        textAlign: "right",
                        fontWeight: "bold",
                        fontSize: "22px",
                        marginTop: "7px",
                      }}
                    >
                      {dashboardStats?.monthlyData?.unstacked}
                    </Typography>
                  </Grid>
                  <Grid
                    sx={{
                      width: "24.5%",
                      backgroundColor: "#F8FAFF",
                      borderRadius: "8px",
                      height: "100px",
                      marginLeft: "3%",
                      padding: "20px",
                    }}
                  >
                    <Typography sx={{ color: "#787878" }}>입고완료</Typography>
                    <Typography
                      sx={{
                        textAlign: "right",
                        fontWeight: "bold",
                        fontSize: "22px",
                        marginTop: "7px",
                      }}
                    >
                      {dashboardStats?.monthlyData?.quantityReceived}
                    </Typography>
                  </Grid>
                </>
              )}
            </Grid>
          </Box>
          <Box
            xs={5}
            sx={{
              border: "1px solid rgb(198, 198, 198)",
              width: "49%",
              margin: "auto",
              marginLeft: "2%",
              borderRadius: "30px",
            }}
          >
            <Grid
              container
              sx={{
                justifyContent: "space-between",
                paddingLeft: "30px",
                paddingRight: "30px",
                paddingTop: "18px",
                paddingBottom: "10px",
                borderBottom: "1px solid rgb(198, 198, 198)",
              }}
            >
              <Grid>
                <Typography>
                  <AirplanemodeActiveIcon
                    sx={{ color: "#F16F17", verticalAlign: "top" }}
                  />
                  <Typography
                    sx={{
                      display: "inline-block",
                      color: "#F16F17",
                      fontWeight: "bold",
                      marginLeft: "8px",
                    }}
                  >
                    출고
                  </Typography>
                </Typography>
                <Typography sx={{ color: "#718096" }}>최근 7일 기준</Typography>
              </Grid>
              <Grid>
                <Typography sx={{ fontWeight: "bold", textAlign: "right" }}>
                  출고 월간수량
                </Typography>
                <Typography sx={{ color: "#718096" }}>
                  당월 기준 {Moment().startOf("month").format("YYYY-MM-DD")}~
                  {Moment().endOf("month").format("MM-DD")}
                </Typography>
              </Grid>
            </Grid>
            <Grid container sx={{ padding: "30px" }}>
              {dashboardStats && (
                <>
                  <Grid sx={{ width: "45%", padding: "10px" }}>
                    <Grid container>
                      <Grid
                        sx={{
                          backgroundColor: "#F16F17",
                          borderRadius: "100%",
                          width: "80px",
                          height: "80px",
                          paddingTop: "20px",
                          textAlign: "center",
                        }}
                      >
                        <OutputIcon
                          sx={{ color: "white", width: "40px", height: "40px" }}
                        />
                      </Grid>
                      <Grid sx={{ marginTop: "8px", marginLeft: "10px" }}>
                        <Typography
                          sx={{ color: "#787878", fontWeight: "bold" }}
                        >
                          출고 완료
                        </Typography>
                        <Typography
                          sx={{ fontSize: "30px", fontWeight: "bold" }}
                        >
                          {dashboardStats?.weeklyData?.overallShipped}
                          <Typography
                            sx={{
                              display: "inline",
                              fontSize: "16px",
                              fontWeight: "normal",
                              marginLeft: "5px",
                              color: "#787878",
                            }}
                          >
                            건
                          </Typography>
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    sx={{
                      width: "24.5%",
                      backgroundColor: "#FFF6F0",
                      borderRadius: "8px",
                      height: "100px",
                      marginLeft: "3%",
                      padding: "20px",
                    }}
                  >
                    <Typography sx={{ color: "#787878" }}>출고예정</Typography>
                    <Typography
                      sx={{
                        textAlign: "right",
                        fontWeight: "bold",
                        fontSize: "22px",
                        marginTop: "7px",
                      }}
                    >
                      {/* {shippingStarted} */}
                      {dashboardStats?.monthlyData?.quantityReceived}
                    </Typography>
                  </Grid>
                  <Grid
                    sx={{
                      width: "24.5%",
                      backgroundColor: "#FFF6F0",
                      borderRadius: "8px",
                      height: "100px",
                      marginLeft: "3%",
                      padding: "20px",
                    }}
                  >
                    <Typography sx={{ color: "#787878" }}>출고완료</Typography>
                    <Typography
                      sx={{
                        textAlign: "right",
                        fontWeight: "bold",
                        fontSize: "22px",
                        marginTop: "7px",
                      }}
                    >
                      {dashboardStats?.monthlyData?.shippingStarted}
                      {/* {deliveryCompleted} */}
                    </Typography>
                  </Grid>
                </>
              )}
            </Grid>
          </Box>
        </Grid>
        <Grid container sx={{ marginTop: "50px", paddingLeft: "20px" }}>
          <Box
            xs={5}
            sx={{
              border: "1px solid rgb(198, 198, 198)",
              width: "49%",
              margin: "auto",
              borderRadius: "30px",
              padding: "25px",
            }}
          >
            <Grid
              sx={{
                backgroundColor: "#F8FAFF",
                width: "100%",
                padding: "20px",
                borderRadius: "10px",
              }}
            >
              <Typography>
                <SavingsIcon sx={{ color: "#ffb3b3", verticalAlign: "top" }} />
                <Typography
                  sx={{
                    display: "inline-block",
                    fontWeight: "bold",
                    marginLeft: "8px",
                    marginBottom: "7px",
                  }}
                >
                  예치금 충전 계좌
                </Typography>
              </Typography>
              <Typography sx={{ fontWeight: "bold" }}>
                ㈜에이치3네트웍스
              </Typography>
              <Typography sx={{ color: "#2D3748" }}>
                하나은행 198-910021-07304
              </Typography>
            </Grid>
            <Grid
              sx={{
                backgroundColor: "#FFF6F0",
                width: "100%",
                padding: "20px",
                height: "112px",
                borderRadius: "10px",
                marginTop: "20px",
              }}
            >
              <Grid container sx={{ justifyContent: "space-between" }}>
                <Typography>
                  <PersonIcon sx={{ color: "#4EADFB", verticalAlign: "top" }} />
                  <Typography
                    sx={{
                      display: "inline-block",
                      fontWeight: "bold",
                      marginLeft: "8px",
                    }}
                  >
                    입금자명
                  </Typography>
                </Typography>
                <Typography>{userData?.customer_name}</Typography>
              </Grid>
              <Grid
                container
                sx={{ justifyContent: "space-between", marginTop: "25px" }}
              >
                <Typography>
                  <MonetizationOnIcon
                    sx={{ color: "#FFC451", verticalAlign: "top" }}
                  />
                  <Typography
                    sx={{
                      display: "inline-block",
                      fontWeight: "bold",
                      marginLeft: "8px",
                    }}
                  >
                    예치금 현황
                  </Typography>
                </Typography>
                <Typography>{balance?.toLocaleString()} 원</Typography>
              </Grid>
            </Grid>
          </Box>
          <Box
            xs={5}
            sx={{
              border: "1px solid rgb(198, 198, 198)",
              width: "49%",
              margin: "auto",
              height: "296px",
              borderRadius: "30px",
              padding: "25px",
              marginLeft: "2%",
              textAlign: "center",
            }}
          >
            {rate1 && (
              <>
                <Button
                  onClick={() => downloadFile(rate1)}
                  sx={{
                    width: "40%",
                    height: "30px",
                    border: "1px solid #4EADFB",
                    backgroundColor: "#F8FAFF",
                    padding: "27px",
                    borderRadius: "30px",
                    fontWeight: "bold",
                    fontSize: "16px",
                    marginTop: "15px",
                    color: "#4EADFB",
                    "&:hover": {
                      backgroundColor: "#F8FAFF",
                    },
                  }}
                >
                  <DownloadIcon sx={{ color: "#4EADFB" }} />
                  최신 요율표
                </Button>
                {compareDates(rate1.createdAt) && (
                  <Typography
                    sx={{
                      position: "relative",
                      top: "-26%",
                      left: "62%",
                      backgroundColor: "#FFFFFF",
                      width: "45px",
                      fontSize: "12px",
                      border: "1px solid #EB0000",
                      color: "#EB0000",
                      borderRadius: "8px",
                    }}
                  >
                    New
                  </Typography>
                )}
                <Typography>파일명 : {rate1.name}</Typography>
              </>
            )}
            {rate2 && (
              <>
                <Button
                  onClick={() => downloadFile(rate2)}
                  sx={{
                    width: "40%",
                    height: "30px",
                    border: "1px solid #F16F17",
                    backgroundColor: "#FFF6F0",
                    padding: "27px",
                    borderRadius: "30px",
                    fontWeight: "bold",
                    fontSize: "16px",
                    marginTop: "20px",
                    color: "#F16F17",
                    "&:hover": {
                      backgroundColor: "#FFF6F0",
                    },
                  }}
                >
                  <DownloadIcon sx={{ color: "#F16F17" }} />
                  쉽터 이용 가이드
                </Button>
                {(compareDates(rate2.createdAt)) && <Typography
                  sx={{
                    position: "relative",
                    top: "-26%",
                    left: "62%",
                    backgroundColor: "#FFFFFF",
                    width: "45px",
                    fontSize: "12px",
                    border: "1px solid #EB0000",
                    color: "#EB0000",
                    borderRadius: "8px",
                    visibility: "hidden",
                  }}
                >
                  New
                </Typography>}
                <Typography>파일명 : {rate2.name}</Typography>
              </>
            )}
          </Box>
        </Grid>
        <Grid
          xs={12}
          style={{
            marginLeft: "20px",
          }}
        >
          <Typography
            style={{
              margin: "30px auto",
              marginBottom: "20px",
            }}
          >
            <PublicIcon sx={{ color: "#4EADFB" }} />
            <Typography
              sx={{
                display: "inline-block",
                fontWeight: "bold",
                marginLeft: "8px",
                verticalAlign: "top",
                marginTop: "1px",
              }}
            >
              국가별 평균 배송 기간
            </Typography>
          </Typography>
          <DeliveryTimeGrid />
        </Grid>
        {add1 && add2 && add3 && (
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              paddingLeft: "20px",
              marginTop: "10px",
            }}
          >
            <Box
              sx={{
                backgroundImage: `url(${add1.url})`,
                borderRadius: "20px",
                backgroundPosition: "center",
                backgroundSize: "cover",
                width: "32.5%",
                height: "250px",
              }}
            />
            <Box
              sx={{
                backgroundImage: `url(${add2.url})`,
                borderRadius: "20px",
                backgroundPosition: "center",
                backgroundSize: "cover",
                width: "32.5%",
                height: "250px",
                margin: "auto 20px",
              }}
            />
            <Box
              sx={{
                backgroundImage: `url(${add3.url})`,
                borderRadius: "20px",
                backgroundPosition: "center",
                backgroundSize: "cover",
                width: "32.5%",
                height: "250px",
              }}
            />
          </Grid>
        )}
      </Grid>
    </Stack>
  );
}
