import CatchAll from "./CatchAll";
import Layout from "../layout";
import authProvider from "../utils/authProvider";
import { useUtils } from "../utils/utils";
import ClientDashboard from "../pages/clients/Dashboard";
import AdminDashboard from "../pages/admin/Dashboard";
import { Admin, Resource } from "react-admin";
import MasterManagement from "../pages/admin/mastermanagement/MasterManagement";
import Bag from "../pages/admin/Bag";
import MasterBagList from "../pages/admin/masterBagList";
import ReceptionLists from "../pages/admin/receptionList";
import { ShippingCountryList } from "./Shipping/ShippingCountry";
import { ShippingTypeList } from "./Shipping/ShippingTypes";
import { DeliveryRequest } from "../pages/admin/DeliveryRequest";
import { GeneralRates, RateByCompany } from "../pages/admin/RatesManagament";
import ShippingProcessing from "../pages/admin/ShippingProcessing";
import { UploadWaybill, WaybillList } from "../pages/admin/WayBill";
import CompanyList from "../pages/admin/CompanyList";
import Vendors from "../pages/admin/Vendors";
import ShippingSummary from "../pages/admin/ShippingSummary";
import ShippingFees from "../pages/admin/Shipping";
import SettleMentHistory from "../pages/admin/settlement/Settlement-new";
import WhereAbout from "../pages/admin/WhereAbout";
import {
	ClientBranchSettings,
	ClientInfoSettings,
	ClientPasswordSettings,
	ClientShippingRateSettings,
	ClientApiSettings,
} from "../pages/clients/ClientSettings";
import ClientShippingRequest from "../pages/clients/RequestShipping/ShippingRequest";
import DeliveryParcels from "../pages/clients/DeliveryParcel";
import ClientShippingSuspend from "../pages/clients/ShippingSuspend";
import AdminShippingSuspend from "../pages/admin/suspendShipping";
import ClientDeliveryRequests from "../pages/clients/Delivery";
import { ClientCreateShipping } from "../pages/clients/CreateShipping";
import DepositHistory from "../pages/admin/transactions/Deposits";
import ClientDepositHistory from "../pages/clients/ClientDeposit";
import MyLoginPage from "../pages/admin/MyLoginPage";
import { NoticeList, NoticeView } from "./Notice";
import CreateNotice from "./Notice/CreateNotice";
import React, { useEffect, useState } from "react";
import buildGraphQLProvider from "ra-data-graphql-simple";
import { APIClient } from "../utils/apiGraphql";
import { customBuildQuery } from "../utils/dataProvider";
import AdminStats from "../pages/admin/Stats";
import ClientStats from "../pages/clients/Stats";
import { RateByGroup } from "../pages/admin/RatesManagament/RateByGroup";
import { RateByCompanyNew } from "../pages/admin/RatesManagament/RateByCompanyNew";
import { ExportDeclaration } from "../pages/clients/ExportDeclaration";
import FeeDeduction from "../pages/clients/FeeDeduction";
import ClientHistoy from "../pages/clients/history";
import { BulkShipping } from "../pages/clients/BulkShipping";
import DeliveryRequestDetails from "../pages/admin/DeliveryRequest/DeliveryRequestDetails";
import DeliveryRequestDetails2 from "../pages/admin/DeliveryRequestDetailsFilter";
import DeliveryTracking from "../pages/admin/DeliveryRequest/DeliveryTracking";
import Relabeling from "../pages/admin/Relabelling/Relabeling";

export default function ReactAdmin() {
	const { role } = useUtils();
	const [dataProvider, setDataProvider] = useState(null);

	useEffect(() => {
		// buildGraphQLProvider
		//   .then((provider) => {
		//     setDataProvider(() => provider);
		//   })
		//   .catch((error) => {
		//     console.log(error);
		//   });

		buildGraphQLProvider({
			client: APIClient,
			buildQuery: customBuildQuery,
		}).then((graphQlDataProvider) =>
			setDataProvider(() => graphQlDataProvider)
		);
	}, []);

	if (!dataProvider) {
		return <div>Loading..</div>;
	}

	return (
		<Admin
			catchAll={CatchAll}
			layout={Layout}
			authProvider={authProvider}
			dataProvider={dataProvider}
			loginPage={MyLoginPage}
			dashboard={role === "client" ? ClientDashboard : AdminDashboard}
		>
			{(permissions) => {
				return (
					<>
						{permissions === "admin" ? (
							<>
								<Resource name="MasterManagement" list={MasterManagement} />
								<Resource name="Bag" list={Bag} />
								<Resource
									name={"ShippingCountries"}
									list={ShippingCountryList}
								/>
								<Resource name={"ShippingTypes"} list={ShippingTypeList} />
								<Resource name="request-shipping" list={DeliveryRequest} />
								<Resource
									name="request-shipping-details"
									list={DeliveryRequestDetails2}
								/>
								<Resource name="general-rates" list={GeneralRates} />
								<Resource name="rates-by-company" list={RateByCompanyNew} />
								{/* <Resource name="rates-by-company-new" list={RateByCompanyNew} /> */}
								<Resource
									name="shipping-processing"
									list={ShippingProcessing}
								/>
								<Resource name="suspend-shipping" list={AdminShippingSuspend} />
								<Resource name="relabeling" list={Relabeling} />
								<Resource name="upload-waybill" list={UploadWaybill} />
								<Resource name="waybill-list" list={WaybillList} />
								<Resource name="CompanyList" list={CompanyList} />
								<Resource name="vendors" list={Vendors} />
								<Resource name="shipping-summary" list={ShippingSummary} />
								{/*<Resource name="deposit-history" list={DepositHistory} />*/}
								<Resource name="shipping-fees" list={ShippingFees} />
								<Resource name="settlement-history" list={SettleMentHistory} />
								<Resource name="whereabout" list={WhereAbout} />
								<Resource name="delivery-tracking" list={DeliveryTracking} />
							</>
						) : (
							<>
								<Resource
									name="settings-branchinfo"
									list={ClientBranchSettings}
								/>
								<Resource
									name="settings-shippingrate"
									list={ClientShippingRateSettings}
								/>
								<Resource
									name="settings-customerinfo"
									list={ClientInfoSettings}
								/>
								<Resource name="delivery-parcels" list={DeliveryParcels} />
								<Resource name="export-declaration" list={ExportDeclaration} />
								<Resource
									name="settings-password"
									list={ClientPasswordSettings}
								/>
								<Resource
									name="shipping-request/list"
									list={ClientShippingRequest}
								/>
								<Resource
									name="shipping-suspend"
									list={ClientShippingSuspend}
								/>
								<Resource name="delivery" list={ClientDeliveryRequests} />
								<Resource name="RequestShippingBulk" list={BulkShipping} />
								<Resource name="deposit-history" list={ClientHistoy} />
								<Resource name="fee-deduction" list={FeeDeduction} />
								<Resource name="settings-api" list={ClientApiSettings} />
							</>
						)}
						<Resource name="RequestShipping" list={ClientCreateShipping} />
						<Resource
							name="Transaction"
							list={
								permissions === "admin" ? DepositHistory : ClientDepositHistory
							}
							show={DepositHistory}
						/>
						<Resource
							name="StatsByCountries"
							list={permissions === "admin" ? AdminStats : ClientStats}
							show={AdminStats}
						/>
						<Resource
							name="Notice"
							list={NoticeList}
							show={NoticeView}
							create={CreateNotice}
						/>
						<Resource name="rates-by-company/:id" list={RateByGroup} />
						<Resource name="master-bag-list" list={MasterBagList} />
						<Resource name="reception-list" list={ReceptionLists} />
						<Resource name="reception-list-master" list={ReceptionLists} />
						<Resource name="delivery-tracking" list={DeliveryTracking} />
					</>
				);
			}}
		</Admin>
	);
}
