import React, { useState, useEffect } from "react";
import { Divider, Grid, Stack, Typography } from "@mui/material";
import CustomButton from "../../../../components/CustomButton";
import { CreateStepOne } from "./CreateStepOne";
import { CreateStepTwo } from "./CreateStepTwo";
import { CreateStepThree } from "./CreateStepThree";
import { PopUpModal } from "../../../../components/PopUpModal";
import { CreateStepFour } from "./CreateStepFour";
import Toaster from "../../../../utils/Toaster";
import { useGetList, useUtils } from "../../../../utils/utils";
import { Formik } from "formik";
import BackendErrorModal from "../../../../modals/BackendErrorModal";
import AlertWayBill from "../../../../modals/AlertWayBill";
import { useQuery } from "@apollo/client";
import { getUserInfo } from "../../../../utils/constants";
// import { useUtils } from "../../utils/utils";

export function CreateShippingForm({
	values,
	errors,
	backendErrors,
	errorMessage,
	setBackendErrors,
	handleChange,
	handleBlur,
	handleSubmit,
	initialValues,
	validation,
	isSubmitting,
	handleReset,
	editMode,
	setFieldValue,

	/* and other goodies */
}) {
	const [currentStep, setCurrentStep] = useState(1);
	const [shippingTypes, setShippingTypes] = useState([]);
	const [shippingCountries, setShippingCountries] = useState([]);
	const [alertModal, setAlertModal] = useState(false);
	const [update, setUpdate] = useState(true);
	const { userData } = useUtils();
	const { getData } = useGetList();
	const [open, setOpen] = useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);
	const handleOpen2 = () => setAlertModal(true);
	const handleClose2 = () => setAlertModal(false);
	const handleUpdate = () => setUpdate(false);
	const handleBackendError = () => setBackendErrors(false);
	const { loading, error, data } = useQuery(getUserInfo);

	useEffect(() => {
		(async () => {
			try {
				const data = await getData("ShippingTypes");
				setShippingTypes(data);
			} catch (e) {
				Toaster("error", e.message);
			}
		})();

		(async () => {
			try {
				const data = await getData("ShippingCountries", {
					filter: { isActive: true },
				});
				setShippingCountries(data);
			} catch (e) {
				Toaster("error", e.message);
			}
		})();
	}, []);

	useEffect(() => {
		if (userData) {
			if (data) {
				if (data.User?.balance < 0 && data.User?.receptionMethodCheck) {
					// console.log(data.User.receptionMethodCheck);
					setOpen(true);
				}
			}
		}
	}, [userData, data]);

	const handleSubmitForm = (event) => {
		event.preventDefault();

		setCurrentStep(currentStep + 1);
	};

	const setError = (index) => {
		const steps = [
			"senderInformation",
			"payeeInformation",
			"productInformation",
			"miscellaneousInformation",
		];

		if (errors) {
			return Object.keys(errors).some((res) =>
				res.split(".").includes(steps[index])
			);
		}
		return false;
	};

	return (
		<Stack spacing={"50px"}>
			<div
				style={{
					display: "flex",
					alignItems: "flex-end",
					justifyContent: "space-between",
				}}
			>
				<Stack>
					<Typography fontSize={"18px"} fontWeight="700">
						배송 정보를 입력해주세요.
					</Typography>
					<Typography fontSize={"14px"} color="rgba(0,0,0,0.6)">
						배송 정보를 정확히 입력하셔야 배송처리가 신속히 이루어집니다.
					</Typography>
				</Stack>
			</div>

			<Divider />

			<Stack spacing="10px" direction={"row"}>
				<Grid
					container
					flexDirection="row"
					xs={12}
					alignItems="center"
					justifyContent="center"
					gap={1}
				>
					{[1, 2, 3, 4].map((step, index) => (
						<div
							style={{
								cursor: "pointer",
								padding: "7px 13px",
								borderRadius: "10px",
								background: currentStep === step ? "#5B8FF7" : "#F8F8FA",
								color: setError(index)
									? "red"
									: currentStep === step
									? "#fff"
									: "#C1C1C1",
							}}
							onClick={() => {
								setCurrentStep(step);
							}}
						>
							{step}
						</div>
					))}
				</Grid>
			</Stack>

			<Stack spacing={"20px"}>
				<div style={{ maxWidth: "1200px", textAlign: "start" }}></div>
				<div>
					{currentStep === 1 && (
						<div style={{ maxWidth: "1200px", margin: "auto" }}>
							<Typography fontWeight={"700"}>발송인 정보 입력</Typography>
							<form style={{ paddingTop: "20px" }} onSubmit={handleSubmitForm}>
								<CreateStepOne
									handleChange={handleChange}
									handleBlur={handleBlur}
									values={values}
									errors={errors}
									handleSubmit={handleSubmitForm}
								/>
								<div style={{ textAlign: "end", marginTop: "40px" }}>
									<CustomButton bg="primary" width="100px" type="submit">
										다음
									</CustomButton>
								</div>
							</form>
						</div>
					)}
					{currentStep === 2 && (
						<div style={{ maxWidth: "1200px", margin: "auto" }}>
							<Typography fontWeight={"700"}>수취인 정보 입력</Typography>
							<form style={{ paddingTop: "20px" }} onSubmit={handleSubmitForm}>
								<CreateStepTwo
									shippingTypes={shippingTypes}
									shippingCountries={shippingCountries}
									handleChange={handleChange}
									handleBlur={handleBlur}
									values={values}
									errors={errors}
									editMode={editMode}
								/>
								<div style={{ textAlign: "end", marginTop: "40px" }}>
									<CustomButton
										onClick={() => {
											if (currentStep !== 1) {
												setCurrentStep(currentStep - 1);
											}
										}}
										// bg="#F8F8FA"
										// color="#000"
										style={{
											width: "100px",
											backgroundColor: "#F8F8FA",
											color: "#000",
											minWidth: "100px",
											padding: "10px 12px",
											borderRadius: "10px",
										}}
										width="100px"
									>
										이전
									</CustomButton>
									<CustomButton
										style={{
											marginLeft: "20px",
											minWidth: "100px",
											padding: "10px 12px",
											borderRadius: "10px",
										}}
										bg="primary"
										width="100px"
										type="submit"
									>
										다음
									</CustomButton>
								</div>
							</form>
						</div>
					)}
					{currentStep === 3 && (
						<div style={{ maxWidth: "1200px", margin: "auto" }}>
							<Typography fontWeight={"700"}>상품 정보</Typography>
							<form style={{ paddingTop: "20px" }} onSubmit={handleSubmitForm}>
								<CreateStepThree
									handleChange={handleChange}
									handleBlur={handleBlur}
									values={values}
									errors={errors}
									setFieldValue={setFieldValue}
								/>
								<div style={{ textAlign: "end", marginTop: "40px" }}>
									<CustomButton
										onClick={() => {
											if (currentStep !== 1) {
												setCurrentStep(currentStep - 1);
											}
										}}
										// bg="#F8F8FA"
										// color="#000"
										style={{
											width: "100px",
											backgroundColor: "#F8F8FA",
											color: "#000",
											padding: "10px 12px",
											borderRadius: "10px",
										}}
										width="100px"
									>
										이전
									</CustomButton>
									<CustomButton
										style={{
											marginLeft: "20px",
											minWidth: "100px",
											padding: "10px 12px",
											borderRadius: "10px",
										}}
										bg="primary"
										width="100px"
										type="submit"
									>
										다음
									</CustomButton>
								</div>
							</form>
						</div>
					)}

					{currentStep === 4 && (
						<div style={{ maxWidth: "1200px", margin: "auto" }}>
							<Typography fontWeight={"700"}>기타 정보</Typography>
							<form style={{ paddingTop: "20px" }} onSubmit={handleSubmit}>
								<CreateStepFour
									shippingTypes={shippingTypes}
									handleChange={handleChange}
									handleBlur={handleBlur}
									values={values}
									errors={errors}
								/>

								<div style={{ display: "flex", justifyContent: "flex-end" }}>
									<Typography
										sx={{
											width: "50%",
											color: "red",
											textAlign: "end",
											marginTop: "20px",
										}}
									>
										접수 건에 대해 수정을 하신 경우에는 반드시 송장을 재출력하여 
										부착하시기 바랍니다.
										<br />
										입고완료/출고완료가 된 경우에는 수정이 불가하오니
										문의주시기 바랍니다.
									</Typography>
								</div>

								<div style={{ textAlign: "end", marginTop: "40px" }}>
									<CustomButton
										onClick={() => {
											if (currentStep !== 1) {
												setCurrentStep(currentStep - 1);
											}
										}}
										// bg="#F8F8FA"
										// color="#000"
										style={{
											width: "100px",
											backgroundColor: "#F8F8FA",
											color: "#000",
											padding: "10px 12px",
											borderRadius: "10px",
										}}
										width="100px"
									>
										이전
									</CustomButton>
									{editMode && update ? (
										<CustomButton
											style={{
												marginLeft: "20px",
												minWidth: "100px",
												padding: "10px 12px",
												borderRadius: "10px",
											}}
											bg="primary"
											width="100px"
											onClick={handleOpen2}
										>
											저장
										</CustomButton>
									) : (
										<CustomButton
											style={{
												marginLeft: "20px",
												minWidth: "100px",
												padding: "10px 12px",
												borderRadius: "10px",
											}}
											bg="primary"
											width="100px"
											type="submit"
										>
											저장
										</CustomButton>
									)}
								</div>
							</form>
						</div>
					)}
				</div>

				<div
					style={{
						display: "flex",
						justifyContent: "flex-end",
						gap: "20px",
						marginTop: "20px",
						textAlign: "end",
					}}
				>
					{/* {currentStep !== 1 && (
            <CustomButton
              onClick={() => {
                if (currentStep !== 1) {
                  setCurrentStep(currentStep - 1);
                }
              }}
              // bg="#F8F8FA"
              // color="#000"
              style={{
                width: "100px",
                backgroundColor: "#F8F8FA",
                color: "#000",
              }}
              width="100px"
            >
              이전
            </CustomButton>
          )} */}
					{/* <CustomButton
            onClick={(e) => {
              if (currentStep !== 4) {
                handleSubmitForm(e);
                // setCurrentStep(currentStep + 1);
                // handleOpen();
              }
            }}
            bg="primary"
            width="100px"
            type={currentStep === 4 ? "submit" : "button"}
            // disabled={isSubmitting}
          >
            {isSubmitting ? "..." : currentStep == 4 ? "저장" : "다음"}
          </CustomButton> */}
				</div>
			</Stack>

			<PopUpModal
				open={open}
				handleOpen={handleOpen}
				// handleClose={handleClose}
			/>
			<BackendErrorModal
				isOpen={backendErrors}
				onClose={handleBackendError}
				errorMessage={errorMessage}
			/>
			<AlertWayBill
				isOpen={alertModal}
				onClose={handleClose2}
				update={handleUpdate}
			/>
		</Stack>
	);
}
