import { Box, Divider, Modal, Stack, Typography } from "@mui/material";
import React, { useCallback } from "react";
import CustomButton from "../components/CustomButton";
import CustomCard from "../components/CustomCard";
import CustomInput from "../components/CustomInput";
import styled from "styled-components";
import { Formik, Form } from "formik";
import { useBarCode } from "../hooks/useBarCode";
import { useBagLabel } from "../hooks/useBagLabel";
import Moment from "moment";

export default function ConfirmModal({
  isOpen,
  onClose,
  onConfirm,
  isLoading = false,
  title = "",
  description = "",
  lastBtn,
  details,
  // handleDelete,
  ...props
}) {
  const { bagID, bagNo, requestID, bagName } = details;
  console.log(details, "details");
  const getBarCode = useBarCode();
  const printLabel = useBagLabel();
  const handleSubmit = useCallback(
    async (values, helpers) => {
      try {
        const { url } = await getBarCode({
          text: String(
            details.countryCode === "NL"
              ? `NLHNK${bagNo}`
              : bagName.split("_").join("").toUpperCase()
          ),
          bcid: "code128",
          height: 7,
          includetext: details.countryCode !== "NL",
        });
        const finalProps = { barcode: url, ...details, ...values };
        // console.log(finalProps, "finalProps");
        await printLabel(finalProps);
      } catch (err) {
        console.log(err);
      }
    },
    [getBarCode, printLabel, bagNo, details, bagName]
  );
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        style={{
          padding: 20,
          overflowX: "hidden",
          minHeight: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Formik
          initialValues={{
            masterNumber: "",
            dispatchDate: Moment().format("YYYY-MM-DD"),
            destination: "",
            requestId: requestID,
            bagId: bagID,
          }}
          onSubmit={handleSubmit}
        >
          {({ handleChange, handleSubmit, values }) => {
            return (
              <Form onSubmit={handleSubmit}>
                <CustomCard>
                  <Stack spacing={"20px"} width={450} p="20px">
                    <Stack direction="row" alignItems={"center"} spacing="10px">
                      <Typography fontSize={"25px"} fontWeight="700">
                        {title}
                      </Typography>
                    </Stack>

                    <div>
                      <Typography
                        lineHeight={"0.5"}
                        fontSize={"14px"}
                        color="rgba(0,0,0,0.6)"
                        component={"div"}
                      >
                        {description}
                      </Typography>
                    </div>

                    <FlexColumn>
                      <CustomInput
                        placeholder={"마스터번호를 입력하세요."}
                        label="마스터번호"
                        name={"masterNumber"}
                        labelColor={"transparent"}
                        padding="5px"
                        onChange={handleChange}
                        labelWidth="160px"
                        value={values.masterNumber}
                      />

                      <CustomInput
                        placeholder={"Date"}
                        label="Dispatch Date"
                        name={"dispatchDate"}
                        labelColor={"transparent"}
                        value={values.dispatchDate}
                        padding="5px"
                        type="date"
                        onChange={handleChange}
                        labelWidth="160px"
                      />

                      <CustomInput
                        placeholder={"도착국가를 입력하세요."}
                        label="Dest."
                        name={"destination"}
                        labelColor={"transparent"}
                        padding="5px"
                        value={values.destination}
                        onChange={handleChange}
                        labelWidth="160px"
                      />
                    </FlexColumn>

                    <Divider />

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        gap: "20px",
                      }}
                    >
                      <CustomButton
                        disabled={isLoading}
                        styles={{ width: "auto" }}
                        onClick={handleSubmit}
                      >
                        확인
                      </CustomButton>
                      <CustomButton
                        onClick={onClose}
                        styles={{
                          background: "#F8F8FA",
                          color: "#000",
                          width: "auto",
                        }}
                      >
                        취소
                      </CustomButton>
                    </div>
                  </Stack>
                </CustomCard>
              </Form>
            );
          }}
        </Formik>
      </Box>
    </Modal>
  );
}

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;
