// in src/MyLoginPage.js
import * as React from "react";
import { useState } from "react";
import { Box, Grid, Card, CardContent } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import Images from "./../../Common/Images";
import LoginPageTabs from "../../components/LoginPageTabs";
import SocialLinks from "./../../components/SocialLinks";
import { useWindowSize } from "../../hooks";
import SignUpForm from "./../../components/SignUpForm";
import ForgetForm from "./../../components/ForgetForm";
import CompleteScreen from "../../components/CompleteScreen";
import TermsAndConditions from "../../components/TermsAndConditions";

const MyLoginPage = ({ theme }) => {
  const size = useWindowSize();
  const [showForm, setForm] = useState("LoginForm");

  const LeftSection = () => {
    return (
      <Grid
        item
        md={5}
        xs={false}
        sm={false}
        sx={{ position: "relative", width: "100%", height: "100%" }}
      >
        <Box
          sx={{
            backgroundImage: `url(${Images.loginPageLeftImage})`,
            backgroundPosition: "center",
            borderTopLeftRadius: 16,
            borderBottomLeftRadius: 16,
            width: "100%",
            height: "790px",
            backgroundSize: "cover",
          }}
          xs={false}
          sm={false}
        ></Box>
        <Box
          xs={false}
          sm={false}
          sx={{
            position: "absolute",
            bottom: "48px",
            left: "43px",
          }}
        >
          <Card
            xs={false}
            sm={false}
            sx={{ backgroundColor: "transparent", borderBottom: "0px" }}
          >
            <CardContent>
              <Avatar
                alt="shipter"
                src={Images.shipter}
                variant="square"
                sx={{ width: 78 }}
              />
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{
                  fontSize: "24px",
                  fontWeight: "700",
                  lineHeight: "33px",
                  color: "#ffffff",
                }}
              >
                해외배송 <br></br> 이젠 SHIPTER 입니다. <br></br> 대한민국 No.1
                글로벌 이커머스 전문 해외 배송서비스
              </Typography>
            </CardContent>
          </Card>
        </Box>
      </Grid>
    );
  };

  return (
    <Grid
      md={12}
      xs={12}
      container
      component="main"
      sx={{
        backgroundColor: "#E5E5E5",
        paddingLeft: "78px",
        paddingRight: "78px",
        paddingTop: "72px",
        paddingBottom: "73px",
        Width: "1283px",
        // height: "100vh",
      }}
    >
      {size.width < 900 ? null : <LeftSection />}

      <Grid
        item
        xs={12}
        sm={12}
        md={7}
        borderRadius={"0px 16px 16px 0px"}
        sx={{
          backgroundColor: "#ffffff",
          width: "664px",
          height: "790px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: "60px",
          }}
          xs={12}
          sm={12}
        >
          <Box
            md={6}
            xs={12}
            sm={12}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {showForm === "LoginForm" ? (
              <>
                <SocialLinks />
                <LoginPageTabs changeForm={setForm} />
              </>
            ) : showForm === "SignUpForm" ? (
              <SignUpForm changeForm={setForm} showForm={showForm} />
            ) : showForm === "ForgetForm" ? (
              // <SignUpForm changeForm={setForm} showForm={showForm} />
              <ForgetForm changeForm={setForm} />
            ) : showForm === "Completed" ? (
              // <SignUpForm changeForm={setForm} showForm={showForm} />
              <CompleteScreen />
            ) 
            : showForm === "TermsAndConditions" ? (
              <TermsAndConditions changeForm={setForm} />
            ) : showForm === "CompletedSignUp" ? (
                // <SignUpForm changeForm={setForm} showForm={showForm} />
                <CompleteScreen heading={"회원가입이 완료되었습니다."} buttonText={"로그인 페이지로 이동"} />)
              : (
              <SignUpForm changeForm={setForm} showForm={showForm} />
            )}
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default MyLoginPage;
