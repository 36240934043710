import moment from "moment";

export default function MasterTable({ masterManagement, ...userProfile }) {
  return (
    <table border={1}>
      <tr>
        <td rowSpan="4" colSpan="7">
          INVOICE
        </td>
        <td colSpan="6" id="sjs-H1">
          H3 networks Co., Ltd (SHIPTER)
        </td>
        <td id="sjs-N1"></td>
      </tr>
      <tr>
        <td
          colSpan="6"
          data-v="ADDRESS: 서울시 강서구 화곡로 416 더스카이벨리5차 지하1층 103호 SHIPTER집하센터"
          id="sjs-H2"
        >
          ADDRESS: 서울시 강서구 화곡로 416 더스카이벨리5차 지하1층 103호
          SHIPTER집하센터
        </td>
        <td id="sjs-N2"></td>
      </tr>
      <tr>
        <td colSpan="6" data-t="s" id="sjs-H3">
          TEL: {userProfile?.phone_number || ""} / FAX:{" "}
          {userProfile.fax_number || ""}
        </td>
        <td id="sjs-N3"></td>
      </tr>
      <tr>
        <td colSpan="6" id="sjs-H4">
          EMAIL: cs@shipter.kr
        </td>
        <td id="sjs-N4"></td>
      </tr>
      <tr>
        <td id="sjs-A5"></td>
        <td id="sjs-B5"></td>
        <td id="sjs-C5"></td>
        <td id="sjs-D5"></td>
        <td id="sjs-E5"></td>
        <td id="sjs-F5"></td>
        <td id="sjs-G5"></td>
        <td id="sjs-H5"></td>
        <td id="sjs-I5"></td>
        <td id="sjs-J5"></td>
        <td id="sjs-K5"></td>
        <td id="sjs-L5"></td>
        <td id="sjs-M5"></td>
        <td id="sjs-N5"></td>
      </tr>
      <tr>
        <td data-t="s" data-v="BILL TO:" id="sjs-A6">
          BILL TO:
        </td>
        <td id="sjs-B6" data-v="" data-t="s"></td>
        <td id="sjs-C6" data-v="" data-t="s"></td>
        <td id="sjs-D6" data-v="" data-t="s"></td>
        <td id="sjs-E6" data-v="" data-t="s"></td>
        <td id="sjs-F6" data-v="" data-t="s"></td>
        <td id="sjs-G6" data-v="" data-t="s"></td>
        <td id="sjs-H6" data-v="" data-t="s"></td>
        <td id="sjs-I6" data-v="" data-t="s"></td>
        <td id="sjs-J6" data-v="" data-t="s"></td>
        <td data-t="s" data-v="Invoice No" id="sjs-K6">
          Invoice No
        </td>
        <td id="sjs-L6" data-t="s"></td>
        <td id="sjs-M6" data-t="s"></td>
        <td id="sjs-N6" data-t="s"></td>
      </tr>
      <tr>
        <td data-t="s" data-v="Company" id="sjs-A7">
          Company
        </td>
        <td colSpan="6" data-t="s" id="sjs-B7">
          {userProfile?.company_name_kr}
        </td>
        <td id="sjs-H7" data-t="s"></td>
        <td id="sjs-I7" data-t="s"></td>
        <td id="sjs-J7" data-t="s"></td>
        <td data-t="s" data-v="Invoice Date" id="sjs-K7">
          Invoice Date
        </td>
        <td colSpan="2" data-t="s" id="sjs-L7">
          {moment().format("YYYY.MM.DD")}
        </td>
        <td id="sjs-N7" data-t="s"></td>
      </tr>
      <tr>
        <td data-t="s" id="sjs-A8">
          Address
        </td>
        <td
          rowSpan="2"
          colSpan="6"
          data-t="s"
          id="sjs-B8"
        >
          {userProfile?.english_address_full_address}
        </td>
        <td id="sjs-H8" data-t="s"></td>
        <td id="sjs-I8" data-t="s"></td>
        <td id="sjs-J8" data-t="s"></td>
        <td data-t="s" data-v="Ref. No" id="sjs-K8">
          Ref. No
        </td>
        <td colSpan="2" id="sjs-L8"></td>
        <td id="sjs-N8" data-t="s"></td>
      </tr>
      <tr>
        <td id="sjs-A9" data-t="s"></td>
        <td id="sjs-H9" data-t="s"></td>
        <td id="sjs-I9" data-t="s"></td>
        <td id="sjs-J9" data-t="s"></td>
        <td id="sjs-K9" data-t="s"></td>
        <td id="sjs-L9" data-t="s"></td>
        <td id="sjs-M9" data-t="s"></td>
        <td id="sjs-N9" data-t="s"></td>
      </tr>
      <tr>
        <td data-t="s" data-v="Tel" id="sjs-A10">
          Tel
        </td>
        <td data-t="s" id="sjs-B10">
          {userProfile?.phone_number}
        </td>
        <td data-t="s"></td>
        <td data-t="s"></td>
        <td data-t="s"></td>
        <td data-t="s"></td>
        <td data-t="s"></td>
        <td data-t="s"></td>
        <td data-t="s"></td>
        <td data-t="s"></td>
        <td data-t="s" data-v="Please Pay This Amount:">
          Please Pay This Amount:
        </td>
        <td colSpan="2" data-t="s" id="sjs-L10">
          {masterManagement.reduce((a, masterDetails) => {
            return (
              a +
              masterDetails.bags.reduce((c, d) => {
                return (
                  c +
                  d.receptionLists.reduce((e, f) => {
                    return e + f.deliveryFee || 0;
                  }, 0)
                );
              }, 0)
            );
          }, 0)}
        </td>
        <td id="sjs-N10"></td>
      </tr>
      <tr>
        <td id="sjs-A11"></td>
        <td id="sjs-B11"></td>
        <td id="sjs-C11"></td>
        <td id="sjs-D11"></td>
        <td id="sjs-E11"></td>
        <td id="sjs-F11"></td>
        <td id="sjs-G11"></td>
        <td id="sjs-H11"></td>
        <td id="sjs-I11"></td>
        <td id="sjs-J11"></td>
        <td id="sjs-K11"></td>
        <td id="sjs-L11"></td>
        <td id="sjs-M11"></td>
        <td id="sjs-N11"></td>
      </tr>
      <tr>
        <td data-t="s" data-v="출고일" id="sjs-A12">
          출고일
        </td>
        <td data-t="s" data-v="MASTER No" id="sjs-B12">
          MASTER No
        </td>
        <td data-t="s" data-v="항공편명" id="sjs-C12">
          항공편명
        </td>
        <td data-t="s" data-v="출발공항" id="sjs-D12">
          출발공항
        </td>
        <td data-t="s" data-v="도착공항" id="sjs-E12">
          도착공항
        </td>
        <td data-t="s" id="sjs-F12">
          출고된
          <br />
          주문갯수
        </td>
        <td data-t="s" id="sjs-G12">
          적용무게
          <br />
          (KG)
        </td>
        <td data-t="s" id="sjs-H12">
          실제무게
          <br />
          (KG)
        </td>
        <td data-t="s" id="sjs-I12">
          배송비
          <br />
          (KRW)
        </td>
        <td data-t="s" id="sjs-J12">
          총 상품금액
          <br />
          (USD)
        </td>
        <td
          data-t="s"
          data-v="택배 추가요금
  (KRW)"
          id="sjs-K12"
        >
          택배 추가요금
          <br />
          (KRW)
        </td>
        <td
          data-t="s"
          data-v="기타비용
  (KRW)"
          id="sjs-L12"
        >
          기타비용
          <br />
          (KRW)
        </td>
        <td
          data-t="s"
          data-v="총금액
  (KRW)"
          id="sjs-M12"
        >
          총금액
          <br />
          (KRW)
        </td>
        <td id="sjs-N12"></td>
      </tr>
      {masterManagement.map((masterDetails, index) => {
        return (
          <tr key={index}>
            <td id="sjs-A13">
              {masterDetails.releaseDate
                ? moment(masterDetails.releaseDate).format("YYYY-MM-DD")
                : ""}
            </td>
            <td id="sjs-B13">{masterDetails?.masterNumber}</td>
            <td id="sjs-C13">{masterDetails?.flightNumber}</td>
            <td id="sjs-D13">{masterDetails?.departureAirPort}</td>
            <td id="sjs-E13">{masterDetails?.arrivalAirPort}</td>
            <td id="sjs-F13">
              {masterDetails?.bags?.reduce((a, b) => {
                return a + b.receptionLists.length;
              }, 0)}
            </td>
            <td id="sjs-G13">
              {masterDetails?.bags?.reduce((a, b) => {
                return (
                  a +
                  b.receptionLists.reduce((c, d) => {
                    return c + d.miscellaneousInformation.applicableWeight || 0;
                  }, 0)
                );
              }, 0)  || ""}
            </td>
            <td id="sjs-H13">
              {masterDetails?.bags?.reduce((a, b) => {
                return (
                  a +
                  b.receptionLists.reduce((c, d) => {
                    return c + d.miscellaneousInformation.actualWeight || 0;
                  }, 0)
                );
              }, 0) || ""}
            </td>
            <td id="sjs-I13">
              {masterDetails?.bags?.reduce((a, b) => {
                return (
                  a +
                  b.receptionLists.reduce((c, d) => {
                    return c + d.deliveryFee || 0;
                  }, 0)
                );
              }, 0) || 0}
            </td>
            <td id="sjs-J13">
              {masterDetails?.bags?.reduce((a, b) => {
                return (
                  a +
                  b.receptionLists.reduce((c, d) => {
                    if (d.productInformation.currency === "usd") {
                      return c + d.productInformation.totalPriceAmount;
                    }
                    return c + d.productInformation.totalPriceAmount;
                  }, 0)
                );
              }, 0)}
            </td>
            <td data-t="n" id="sjs-K13">
              0
            </td>
            <td data-t="n" id="sjs-L13">
              0
            </td>
            <td data-t="n" id="sjs-M13">
              {masterDetails?.bags?.reduce((a, b) => {
                return (
                  a +
                  b.receptionLists.reduce((c, d) => {
                    return c + d.deliveryFee || 0;
                  }, 0)
                );
              }, 0) || 0}
            </td>
            <td id="sjs-N13"></td>
          </tr>
        );
      })}

      <tr>
        <td colSpan="5" data-t="s" data-v="SUM" id="sjs-A28">
          SUM
        </td>
        <td data-t="n" id="sjs-F28">
          {masterManagement.reduce((a, masterDetails) => {
            console.log(masterDetails);
            return (
              a +
              masterDetails.bags.reduce((c, d) => {
                return c + d.receptionLists.length;
              }, 0)
            );
          }, 0)}
        </td>
        <td data-t="n" id="sjs-G28">
          {masterManagement.reduce((a, masterDetails) => {
            return (
              a +
              masterDetails.bags.reduce((c, d) => {
                return (
                  c +
                  d.receptionLists.reduce((e, f) => {
                    return e + f.miscellaneousInformation.applicableWeight;
                  }, 0)
                );
              }, 0)
            );
          }, 0) || ""}
        </td>
        <td data-t="n" id="sjs-H28">
          {masterManagement.reduce((a, masterDetails) => {
            return (
              a +
              masterDetails.bags.reduce((c, d) => {
                return (
                  c +
                  d.receptionLists.reduce((e, f) => {
                    return e + f.miscellaneousInformation.actualWeight;
                  }, 0)
                );
              }, 0)
            );
          }, 0) || ""}
        </td>
        <td data-t="n" id="sjs-I28">
          {masterManagement.reduce((a, masterDetails) => {
            return (
              a +
              masterDetails.bags.reduce((c, d) => {
                return (
                  c +
                  d.receptionLists.reduce((e, f) => {
                    return e + f.deliveryFee || 0;
                  }, 0)
                );
              }, 0)
            );
          }, 0)}
        </td>
        <td data-t="n" id="sjs-J28">
          {masterManagement.reduce((a, masterDetails) => {
            return (
              a +
              masterDetails.bags.reduce((c, d) => {
                return (
                  c +
                  d.receptionLists.reduce((e, f) => {
                    return e + f.productInformation.totalPriceAmount;
                  }, 0)
                );
              }, 0)
            );
          }, 0)}
        </td>
        <td data-t="n" id="sjs-K28">
          0
        </td>
        <td data-t="n" id="sjs-L28">
          0
        </td>
        <td data-t="n" id="sjs-M28">
          {/*{masterManagement.reduce((a, masterDetails) => {*/}
          {/*    return (*/}
          {/*        a +*/}
          {/*        masterDetails.bags.reduce((c, d) => {*/}
          {/*            return (*/}
          {/*                c +*/}
          {/*                d.receptionLists.reduce((e, f) => {*/}
          {/*                    return e + f.productInformation.totalPriceAmount * 1398;*/}
          {/*                }, 0)*/}
          {/*            );*/}
          {/*        }, 0)*/}
          {/*    );*/}
          {/*}, 0)}*/}

          {masterManagement.reduce((a, masterDetails) => {
            return (
              a +
              masterDetails.bags.reduce((c, d) => {
                return (
                  c +
                  d.receptionLists.reduce((e, f) => {
                    return e + f.deliveryFee || 0;
                  }, 0)
                );
              }, 0)
            );
          }, 0)}
        </td>
        <td id="sjs-N28"></td>
      </tr>
      <tr>
        <td id="sjs-A29"></td>
        <td id="sjs-B29"></td>
        <td id="sjs-C29"></td>
        <td id="sjs-D29"></td>
        <td id="sjs-E29"></td>
        <td id="sjs-F29"></td>
        <td id="sjs-G29"></td>
        <td id="sjs-H29"></td>
        <td id="sjs-I29"></td>
        <td id="sjs-J29"></td>
        <td id="sjs-K29"></td>
        <td id="sjs-L29"></td>
        <td id="sjs-M29"></td>
        <td id="sjs-N29"></td>
      </tr>
      <tr>
        <td data-t="s" data-v="REMARK:" id="sjs-A30">
          REMARK:
        </td>
        <td
          colSpan="5"
          data-t="s"
          data-v="SHIPTER 서비스 사용내역"
          id="sjs-B30"
        >
          SHIPTER 서비스 사용내역
        </td>
        <td id="sjs-G30"></td>
        <td id="sjs-H30"></td>
        <td id="sjs-I30"></td>
        <td id="sjs-J30"></td>
        <td id="sjs-K30"></td>
        <td id="sjs-L30"></td>
        <td id="sjs-M30"></td>
        <td id="sjs-N30"></td>
      </tr>
      <tr>
        <td id="sjs-A31"></td>
        <td
          colSpan="7"
          data-t="s"
          data-v="[하나은행 198-910021-07304 ㈜에이치3네트웍스]"
          id="sjs-B31"
        >
          [하나은행 198-910021-07304 ㈜에이치3네트웍스]
        </td>
        <td id="sjs-I31"></td>
        <td id="sjs-J31"></td>
        <td id="sjs-K31"></td>
        <td id="sjs-L31"></td>
        <td id="sjs-M31"></td>
        <td id="sjs-N31"></td>
      </tr>
      <tr>
        <td id="sjs-A32"></td>
        <td id="sjs-B32"></td>
        <td id="sjs-C32"></td>
        <td id="sjs-D32"></td>
        <td id="sjs-E32"></td>
        <td id="sjs-F32"></td>
        <td id="sjs-G32"></td>
        <td id="sjs-H32"></td>
        <td id="sjs-I32"></td>
        <td id="sjs-J32"></td>
        <td id="sjs-K32"></td>
        <td id="sjs-L32"></td>
        <td id="sjs-M32"></td>
        <td id="sjs-N32"></td>
      </tr>
      <tr>
        <td id="sjs-A33"></td>
        <td id="sjs-B33"></td>
        <td id="sjs-C33"></td>
        <td id="sjs-D33"></td>
        <td id="sjs-E33"></td>
        <td id="sjs-F33"></td>
        <td id="sjs-G33"></td>
        <td id="sjs-H33"></td>
        <td id="sjs-I33"></td>
        <td id="sjs-J33"></td>
        <td id="sjs-K33"></td>
        <td id="sjs-L33"></td>
        <td id="sjs-M33"></td>
        <td id="sjs-N33"></td>
      </tr>
      <tr>
        <td id="sjs-A34"></td>
        <td id="sjs-B34"></td>
        <td id="sjs-C34"></td>
        <td id="sjs-D34"></td>
        <td id="sjs-E34"></td>
        <td id="sjs-F34"></td>
        <td id="sjs-G34"></td>
        <td id="sjs-H34"></td>
        <td id="sjs-I34"></td>
        <td id="sjs-J34"></td>
        <td id="sjs-K34"></td>
        <td id="sjs-L34"></td>
        <td id="sjs-M34"></td>
        <td id="sjs-N34"></td>
      </tr>
      <tr>
        <td colSpan="13" data-t="s" data-v="THANKS FOR USING OUR SERVICE!!">
          THANKS FOR USING OUR SERVICE!!
        </td>
        <td data-t="s" data-v=""></td>
        <td data-t="s" data-v=""></td>
        <td data-t="s" data-v=""></td>
        <td data-t="s" data-v=""></td>
        <td data-t="s" data-v=""></td>
        <td data-t="s" data-v=""></td>
        <td data-t="s" data-v=""></td>
        <td data-t="s" data-v=""></td>
        <td data-t="s" data-v=""></td>
        <td data-t="s" data-v=""></td>
        <td data-t="s" data-v=""></td>
        <td data-t="s" data-v=""></td>
        <td data-t="s" data-v=""></td>
        <td data-t="s" data-v=""></td>
        <td data-t="s" data-v=""></td>
        <td data-t="s" data-v=""></td>
      </tr>
      <tr>
        <td id="sjs-A36"></td>
        <td id="sjs-B36"></td>
        <td id="sjs-C36"></td>
        <td id="sjs-D36"></td>
        <td id="sjs-E36"></td>
        <td id="sjs-F36"></td>
        <td id="sjs-G36"></td>
        <td id="sjs-H36"></td>
        <td id="sjs-I36"></td>
        <td id="sjs-J36"></td>
        <td id="sjs-K36"></td>
        <td id="sjs-L36"></td>
        <td id="sjs-M36"></td>
        <td id="sjs-N36"></td>
      </tr>
    </table>
  );
}
