import ListHOC from "../../SharedComponents/ListHOC";
import React, { useCallback, useEffect, useState } from "react";
import { Checkbox, Divider, Grid, Link, MenuItem, Stack } from "@mui/material";
import CustomPopover from "../../components/CustomPopover";
import CustomInput, { SelectBox } from "../../components/CustomInput";
import CustomButton from "../../components/CustomButton";
import TableContainer from "../../components/TableContainer";
import ConfirmModal from "../../modals/ConfirmModal";
import DepositProcessingModal from "../../modals/DepositProcessingModal";
import VendorDetailModal from "../../modals/VendorDetailModal";
import { useRedirect } from "react-admin";
import { onDropDownSearchChange } from "../../utils/onDropDownSearchChange";
import DropdownAndSearchInput from "../../components/DropdownAndSearchInput2";
import { Form, Formik } from "formik";
import Toggle from "react-toggle";

import {
	generateSearchData,
	useUpdate,
	exportJsonToXlsx,
} from "../../utils/utils";
import CustomSelect from "../../components/CustomSelect";
import { ConstructionOutlined } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useDataProvider } from "react-admin";
import Toaster from "../../utils/Toaster";
import moment from "moment";

function CompanyList(props) {
	return (
		<ListHOC {...props}>
			<CompanyListContainer />
		</ListHOC>
	);
}

const CompanyListContainer = ({
	data: _Data,
	perPage,
	page,
	handlePageChange,
	handleSearch,
	handleSetPerPage,
	handleSort,
	excelData,
	countTotal,
	...hocProps
}) => {
	const [data, setData] = useState([]);
	let currentSort = "deposit-history-ascending";
	const [sortingOrder, setSortingOrder] = useState(currentSort);
	const [openDepositModal, setOpenDepositModal] = useState(false);
	const [selectedUserId, setSelectedUserId] = useState(null);
	const [openVendorDetailsModal, setOpenVendorDetailsModal] = useState(false);
	const [checkedList, setCheckedList] = useState([]);
	const [selected, setSelected] = useState(null);
	const [filterQuery, setFilterQuery] = useState(null);
	const [selectedData, setSelectedData] = useState(null);
	const [openDialog, setOpenDialog] = useState(false);
	const [approval, setApproval] = useState(false);
	const [lastBtnClicked, setLastBtnClicked] = useState(false);
	const [receptionMethodCheck, setReceptionMethodCheck] = useState(false);
	const [allTransactionHistpry, setAllTransaction] = useState(false);
	const [allExcelData, setAllExcelData] = useState([]);
	const [sort, setSort] = useState("default");
	const [sortFilter, setSortFilter] = useState(null);
	const navigate = useNavigate();
	const dataProvider = useDataProvider();

	const { update } = useUpdate();

	const redirect = useRedirect();

	const handleCheck = ({ target }) => {
		const { value, checked } = target;
		setCheckedList((prevState) => {
			const temp = [...prevState];
			if (checked) {
				temp.push(+value);
				return temp;
			} else {
				return temp.filter((res) => res !== +value);
			}
		});
	};

	const doSearch = useCallback(() => {
		generateSearchData(handleSearch, filterQuery);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filterQuery]);

	useEffect(() => {
		doSearch();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filterQuery]);

	const handleSelect = (item) => {
		if (item) {
			setSelected(item);
			setOpenVendorDetailsModal(true);
		}
	};

	const handleRedirect = (url, id) => {
		window.open(`${url}?company_name_kr=${id}`, {
			state: { company_id: id },
		});
	};

	const handleRedirectTrans = (url, id) => {
		window.open(`${url}?company_name_kr=${id}`, { state: { company_id: id } });
	};
	const handleRedirectShippingFee = (url, id) => {
		window.open(`${url}?company_name_kr=${id}`, { state: { company_id: id } });
	};

	const handleVendorDetailsModalClose = () => {
		setOpenVendorDetailsModal(false);
		setSelected(false);
	};

	const handleUpdate = (data) => {
		setOpenDialog(true);
		setSelectedData(data);
	};

	const handleCloseConfirmDialog = () => {
		setOpenDialog(false);
		setSelectedData(null);
		generateSearchData(handleSearch, {});
	};

	const handleOpenModal = (id) => {
		if (id) {
			setSelectedUserId(id);
		}
		setOpenDepositModal(true);
	};

	const handleClose = () => {
		setOpenDepositModal(false);
		setSelectedUserId(null);
	};

	const handleConfirm = () => {
		update("User", selectedData, setData, handleCloseConfirmDialog);
	};

	const handleSuccess = (data, reason) => {
		if (reason === "update") {
			setData((prevState) => {
				const temp = [...prevState];
				const index = prevState.findIndex((res) => res.id === data.id);
				if (index > -1) {
					temp[index] = data;
				}
				return temp;
			});
		} else {
			setData((prevState) => {
				const temp = [...prevState];
				temp.push(data);
				return temp;
			});
		}
	};

	useEffect(() => {
		setData(_Data);
	}, [_Data]);
	useEffect(() => {
		setAllExcelData(excelData);
	}, [excelData]);
	// eslint-disable-next-line no-unused-vars
	const [initialValues, setInitialValues] = useState({
		예치금: "ascending",
		가입일: "가입일 오름차순",
	});

	useEffect(() => {
		dataProvider
			.getList("Transaction", {})
			.then(({ data }) => {
				setAllTransaction(data);
			})
			.catch((error) => {
				Toaster("error", error.message);
			});
	}, []);

	const handleExport = (exportData) => {
		if (exportData.length === 0)
			return Toaster(
				"warning",
				"예치금내역을 다운받으실 업체를 선택해주세요. "
			);
		var filtered_array = [];
		data.filter((res) => {
			exportData.map((item, index) => {
				if (item == res.id) {
					filtered_array.push(res);
				}
			});
		});
		var count = 0;
		filtered_array.map((item, index) => {
			var getSpecific = [];

			allTransactionHistpry.forEach((transaction, index) => {
				if (transaction.user.company_name_en === item.company_name_en) {
					count = count + 1;
					getSpecific.push({
						항목:
							transaction.type === "deposit" &&
							transaction.reason === "customer-fees"
								? "고객 요금 입금"
								: transaction.type === "deposit" &&
								  transaction.reason === "reward-amount"
								? "보상 금액 입금"
								: transaction.type === "deposit" &&
								  transaction.reason === "other"
								? "기타"
								: transaction.type === "withdraw" &&
								  transaction.reason === "customer-fees"
								? "기타 서비스 사용 요금"
								: transaction.type === "withdraw" &&
								  transaction.reason === "reward-amount"
								? "착오로 인한 입금"
								: transaction.type === "deduction" &&
								  transaction.reason === "delivery-fee-deduction"
								? "배송접수건 출고"
								: transaction.type === "withdraw" &&
								  transaction.reason === "other" &&
								  "기타",
						"입금액(KRW)":
							transaction.type === "deposit"
								? transaction?.amount?.toLocaleString("en-US")
								: 0,
						"출금액(KRW)":
							transaction.type === "withdraw"
								? transaction?.amount?.toLocaleString("en-US")
								: 0,
						"잔액(KRW)": transaction?.leftBalance?.toLocaleString("en-US"),
						날짜: moment(transaction.createdAt).format("YYYY-MM-DD"),
						시간: moment(transaction.createdAt).format("LTS"),
						메모: transaction.memo,
						// 번호: count,
						// 고객사: transaction.user.company_name_kr,
						// "입금액(KRW)":
						// 	transaction.type == "deposit" ? transaction.amount : 0,
						// "출금액(KRW)":
						// 	transaction.type == "withdraw" ? transaction.amount : 0,
						// "잔액(KRW)": transaction.leftBalance,
						// 메모: transaction.memo,
					});
				}
			});

			const data = getSpecific;
			const fileName =
				item.company_name_kr +
				" 예치금내역 " +
				`${moment().format("YYYY-MM-DD")}`;

			exportJsonToXlsx(data, fileName);
		});
	};

	const handleExportAll = (exportData) => {
		var cleanJson = [];
		// console.log({ allExcelData });
		if (checkedList.length === 0) {
			return Toaster("warning", "업체를 선택해주세요.");
		} else {
			allExcelData
				.filter((item) => checkedList.includes(item.id))
				.forEach((item, index) => {
					cleanJson.push({
						"고객사 코드": item.userID,
						"고객사 이름 ": item.company_name_kr,
						아이디: item.username,
						"대표자 이름": item.customer_name,
						주소: item.address_1 + " " + item.address_2,
						"사업자 등록번호": item.company_registration_number,
						"사업 업태": item.business_condition,
						"사업 종목": item.business_item,
						"담당자 이름": item.contact_name,
						전화번호: item.phone_number,
						"이메일 주소": item.email,
						등록날짜: new Date(item.createdAt).toLocaleString(),
						"예치금(KRW)": item.balance,
						고객정산코드: "",
						"전자계산서 Email": item.e_tax_invoice_email,
						메모: item.memo,
					});
				});
			// console.log({ cleanJson });

			if (cleanJson.length) {
				const data = cleanJson;
				const fileName = `업체리스트내역 (${moment().format("YYYY-MM-DD")})`;

				exportJsonToXlsx(data, fileName);
			}
		}
	};

	const handleApproval = (value, item) => {
		if (value) {
			handleUpdate({ id: item.id, aproval: true });
			setApproval(true);
			setLastBtnClicked(false);
		} else {
			handleUpdate({ id: item.id, aproval: false });
			setApproval(false);
			setLastBtnClicked(false);
		}
	};

	const handleReception = (value, item) => {
		if (value) {
			handleUpdate({
				id: item.id,
				receptionMethodCheck: true,
			});
			setLastBtnClicked(true);
			setReceptionMethodCheck(true);
		} else {
			handleUpdate({
				id: item.id,
				receptionMethodCheck: false,
			});
			setLastBtnClicked(true);
			setReceptionMethodCheck(false);
		}
	};
	const sorting = (value) => {
		// console.log("value", value);
		// setSort({ ...sort, sortOrder: e.target.value });
		if (value === "1") {
			setSort(value);
			let sortData = {
				sortOrder: { sortOrder: "asc" },
				sortField: { sortField: "balance" },
			};
			setSortFilter(sortData);
		} else if (value === "2") {
			setSort(value);
			let sortData = {
				sortOrder: { sortOrder: "desc" },
				sortField: { sortField: "balance" },
			};
			setSortFilter(sortData);
		} else if (value === "3") {
			setSort(value);
			let sortData = {
				sortOrder: { sortOrder: "asc" },
				sortField: { sortField: "createdAt" },
			};
			setSortFilter(sortData);
		} else if (value === "4") {
			setSort(value);
			let sortData = {
				sortOrder: { sortOrder: "desc" },
				sortField: { sortField: "createdAt" },
			};
			setSortFilter(sortData);
		} else {
			setSort("");
			setSortFilter({});
		}
	};

	const handleSorting = () => {
		handleSort(sortFilter);
	};
	const Reset = () => {
		// handleSort(sortFilter);
		// generateSearchData(handleSearch, {});
		handleSort({});
	};

	return (
		<>
			<Stack spacing={"35px"}>
				<Grid container justifyContent={"space-between"}>
					<Grid item xs={7}>
						<Grid item xs={12} container alignItems={"center"} spacing="10px">
							<Grid item xs={3} style={{ maxWidth: "200px" }}>
								<CustomPopover>
									{({ close }) => {
										return (
											<Formik initialValues={initialValues}>
												{({
													values,
													handleSubmit,
													handleChange,
													setFieldValue,
													resetForm,
												}) => {
													return (
														<Form onSubmit={handleSubmit}>
															<Stack spacing={"20px"} width={370} p="20px">
																<div>
																	<CustomInput
																		label={"정렬"}
																		labelColor=""
																		padding="10px 0"
																		input={
																			<SelectBox
																				name={"예치금"}
																				// onChange={(e) => {
																				// 	currentSort = e.target.value;
																				// }}
																				onChange={(e) =>
																					sorting(e.target.value)
																				}
																				defaultValue={"default"}
																				value={sort}
																			>
																				<MenuItem
																					value={"default"}
																					disabled
																					hidden
																				>
																					정렬 선택
																				</MenuItem>
																				<MenuItem value={"1"}>
																					예치금 오름차순
																				</MenuItem>
																				<MenuItem value={"2"}>
																					예치금 내림차순
																				</MenuItem>
																				<MenuItem value={"3"}>
																					가입일 오름차순
																				</MenuItem>
																				<MenuItem value={"4"}>
																					가입일 내림차순
																				</MenuItem>
																			</SelectBox>
																		}
																	/>
																</div>

																<Divider />

																<div
																	style={{
																		display: "flex",
																		justifyContent: "flex-end",
																		gap: "20px",
																	}}
																>
																	<CustomButton
																		onClick={close}
																		styles={{
																			backgroundColor: "#F8F8FA",
																			padding: "5px",
																			color: "#000",
																			width: "auto",
																		}}
																	>
																		취소
																	</CustomButton>
																	<CustomButton
																		onClick={() => {
																			// setSortingOrder(
																			// 	"deposit-history-ascending"
																			// );
																			// close();
																			Reset();
																			close();
																		}}
																		styles={{
																			backgroundColor: "#F8F8FA",
																			padding: "5px",
																			color: "#000",
																			width: "auto",
																		}}
																	>
																		필터 초기화
																	</CustomButton>
																	<CustomButton
																		onClick={handleSorting}
																		styles={{ padding: "5px", width: "auto" }}
																	>
																		적용
																	</CustomButton>
																</div>
															</Stack>
														</Form>
													);
												}}
											</Formik>
										);
									}}
								</CustomPopover>
							</Grid>

							<Grid item xs={8}>
								<DropdownAndSearchInput
									onChange={(data) => {
										const { newValue: value } = data;
										value[Object.keys(value)[0]] = value[Object.keys(value)[0]]
											.split(",")
											.map((each) => each.trim());
										data["newValue"] = value;
										onDropDownSearchChange(data, setFilterQuery);
									}}
									inputFieldPlaceHolder={"검색어를 입력해주세요."}
									value={filterQuery}
									justifyContent={"flex-start"}
									defaultValue={{ key: "company_name_kr", value: "" }}
									dropDownValues={[
										{ value: "company_name_kr", title: "업체명" },
										{ value: "username", title: "유저아이디" },
										{ value: "customer_name", title: "유저명" },
										{ value: "phone_number", title: "전화번호" },
										{
											value: "company_registration_number",
											title: "사업자등록번호 ",
										},
									]}
								/>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={5}>
						<Grid
							container
							item
							xs={12}
							spacing="10px"
							justifyContent={"flex-end"}
							alignItems={"center"}
						>
							<Grid item xs={4} style={{ maxWidth: "200px" }}>
								<CustomButton
									variant={"outlined"}
									onClick={(e) => handleExportAll(data)}
									styles={{
										color: "black",
										fontWeigh: "light",
										border: "1px solid #C4C4C4",
										height: "40px",
										borderRadius: "10px",
									}}
								>
									<img
										style={{ paddingRight: "10px" }}
										src="/assets/images/excel.png"
										alt="excel"
									/>
									엑셀 다운로드
								</CustomButton>
							</Grid>
							<Grid item xs={4} style={{ maxWidth: "200px" }}>
								<CustomButton
									variant={"outlined"}
									onClick={(e) => handleExport(checkedList)}
									styles={{
										color: "black",
										fontWeigh: "light",
										border: "1px solid #C4C4C4",
										height: "40px",
										borderRadius: "10px",
									}}
								>
									예치금내역 다운로드
								</CustomButton>
							</Grid>
							<Grid item xs={4} style={{ maxWidth: "200px" }}>
								<CustomSelect
									value={String(perPage)}
									label=""
									onChange={({ target }) => {
										handleSetPerPage(target.value);
									}}
									options={["10", "20", "30", "40", "50", "100"]}
								></CustomSelect>
							</Grid>
						</Grid>
					</Grid>
				</Grid>

				<div style={{ position: "relative" }}>
					<TableContainer
						{...hocProps}
						headers={[
							<Checkbox
								checked={data.every((res) => checkedList.includes(res.id))}
								onChange={({ target }) => {
									if (target.checked) {
										setCheckedList(data.map((res) => res.id));
									} else {
										setCheckedList([]);
									}
								}}
							/>,
							"No.",
							"유저아이디",
							"유저명",
							"업체명",
							"전화번호",
							"휴대폰 번호",
							"사업자 등록 번호",
							"입출금처리",
							"예치금",
							"접수내역",
							"입출금내역",
							"배송비 내역",
							"승인여부",
							"접수방식",
						]}
						data={data
							// .sort((a, b) => {
							// 	if (sortingOrder === "deposit-history-ascending") {
							// 		return a.balance - b.balance;
							// 	} else if (sortingOrder === "deposit-history-descending") {
							// 		return b.balance - a.balance;
							// 	} else if (sortingOrder === "member-since-ascending") {
							// 		return new Date(a.createdAt) - new Date(b.createdAt);
							// 	}
							// 	return new Date(b.createdAt) - new Date(a.createdAt);
							// })
							.map((item, index) => {
								return [
									<Checkbox
										onChange={handleCheck}
										value={item.id}
										checked={checkedList.some((res) => res === item.id)}
									/>,
									<div style={{ cursor: "pointer" }}>
										{countTotal - ((page - 1) * perPage + index)}
									</div>,
									item.username,
									<div
										style={{ cursor: "pointer", zIndex: "1000" }}
										onClick={() => handleSelect(item)}
									>
										{item.customer_name}
									</div>,
									<div
										style={{ cursor: "pointer", zIndex: "1000" }}
										onClick={() => handleSelect(item)}
									>
										{item.company_name_kr}
									</div>,
									<div
										style={{ cursor: "pointer", zIndex: "1000" }}
										onClick={() => handleSelect(item)}
									>
										{item.phone_number}
									</div>,
									<div
										style={{ cursor: "pointer", zIndex: "1000" }}
										onClick={() => handleSelect(item)}
									>
										{item.mobile_number}
									</div>,
									<div
										style={{ cursor: "pointer", zIndex: "1000" }}
										onClick={() => handleSelect(item)}
									>
										{item.company_registration_number}
									</div>,
									<Link
										onClick={() => handleOpenModal(item.id)}
										component={"button"}
									>
										입출금
									</Link>,
									`${item.balance.toLocaleString("en-us")} 원`,
									<Link
										onClick={() =>
											handleRedirect(
												"/request-shipping-details",
												item.company_name_kr
											)
										}
										component={"button"}
									>
										접수내역
									</Link>,
									<Link
										component={"button"}
										onClick={() =>
											handleRedirectTrans("/Transaction", item.company_name_kr)
										}

										// onClick={() =>
										//   redirect(`/Transaction/${item.company_name_en}/show`)
										// }
									>
										입출금내역
									</Link>,
									<Link
										onClick={() =>
											handleRedirectShippingFee(
												"/shipping-fees",
												item.company_name_kr
											)
										}
										component={"button"}
									>
										배송비 내역
									</Link>,
									// !item.aproval ? (
									// 	<Link
									// 		onClick={(e) => {
									// 			handleUpdate({ id: item.id, aproval: true });
									// 			setApproval(true);
									// 			setLastBtnClicked(false);
									// 		}}
									// 		component={"button"}
									// 	>
									// 		승인
									// 	</Link>
									// ) : (
									// 	<Link
									// 		onClick={(e) => {
									// 			handleUpdate({ id: item.id, aproval: false });
									// 			setApproval(false);
									// 			setLastBtnClicked(false);
									// 		}}
									// 		component={"button"}
									// 		color={"error"}
									// 	>
									// 		승인취소
									// 	</Link>
									// ),
									<Toggle
										id="approval"
										defaultChecked={item.aproval}
										onChange={(e) => handleApproval(e.target.checked, item)}
									/>,
									<Toggle
										id="receptionMethodCheck"
										defaultChecked={item.receptionMethodCheck}
										onChange={(e) => handleReception(e.target.checked, item)}
									/>,

									// !item.receptionMethodCheck ? (
									// 	<Link
									// 		onClick={(e) => {
									// 			handleUpdate({
									// 				id: item.id,
									// 				receptionMethodCheck: true,
									// 			});
									// 			setLastBtnClicked(true);
									// 			setReceptionMethodCheck(true);
									// 		}}
									// 		component={"button"}
									// 	>
									// 		체크
									// 	</Link>
									// ) : (
									// 	<Link
									// 		onClick={(e) => {
									// 			handleUpdate({
									// 				id: item.id,
									// 				receptionMethodCheck: false,
									// 			});
									// 			setLastBtnClicked(true);
									// 			setReceptionMethodCheck(false);
									// 		}}
									// 		component={"button"}
									// 		color={"error"}
									// 	>
									// 		미체크
									// 	</Link>
									// ),
								];
							})}
					/>
				</div>

				<VendorDetailModal
					onClose={handleVendorDetailsModalClose}
					isOpen={openVendorDetailsModal}
					onOpen={handleSelect}
					selected={selected}
					onSuccess={handleSuccess}
					{...hocProps}
				/>

				{openDialog && (
					<ConfirmModal
						title={lastBtnClicked ? "접수방식" : "승인 여부"}
						description={
							!lastBtnClicked
								? approval
									? `해당 업체를 승인 하시겠습니까?`
									: "해당 업체를 승인 취소 하시겠습니까?"
								: receptionMethodCheck
								? `해당 업체의 접수를 체크 하시겠습니까?
            `
								: "해당 업체의 접수를 미체크 하시겠습니까?"
						}
						onClose={handleCloseConfirmDialog}
						isOpen={openDialog && selectedData}
						onConfirm={handleConfirm}
					/>
				)}

				<DepositProcessingModal
					selectedUserId={selectedUserId}
					isOpen={openDepositModal}
					onClose={handleClose}
					onOpen={handleOpenModal}
				/>
			</Stack>
		</>
	);
};

export default CompanyList;
