import {
	Box,
	Button,
	Divider,
	Grid,
	Link,
	MenuItem,
	Modal,
	Stack,
	Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { gql } from "@apollo/client/core";
import { APIClient } from "../utils/apiGraphql";
import CustomButton from "../components/CustomButton";
import CustomCard from "../components/CustomCard";
import ConfirmModal from "./ConfirmModal";
import UpdateHOC from "../SharedComponents/UpdateHOC";
import Moment from "moment";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import CustomInput from "../components/CustomInput";
import { CHANGE_USER_PASSWORD_BY_ADMIN } from "../utils/constants";
import Toaster from "../utils/Toaster";

export default function VendorDetailModal({ onClose, ...rest }) {
	return React.createElement(UpdateHOC, {
		handleClose: onClose,
		...rest,
		_resource: "User",
		children: VendorDetailModalContainer,
	});
}

function VendorDetailModalContainer({
	isOpen,
	handleClose: onClose,
	onSuccess,
	onOpen,
	selected,
	...hocProps
}) {
	const [showConfirmModal, setShowConfirmModal] = useState(false);
	const [businessRegistration, setBusinessRegistration] = useState(null);
	const [passwordChangeConfirmation, setPasswordChangeConfirmation] =
		useState(false);
	const submitButton = useRef();
	const passwordRef = useRef();
	const [balance, setBalance] = useState("");
	const [initialValues, setInitialValues] = useState({
		id: "",
		// userID: "",
		customer_name: "",
		company_name_kr: "",
		company_name_en: "",
		fax_number: "",
		username: "",
		balance: "",
		phone_number: "",
		mobile_number: "",
		email: "",
		company_registration_number: "",
		address_1: "",
		address_2: "",
		e_tax_invoice_email: "",
		business_address_en: "",
		english_address_full_address: "",
		return_address: "",
		memo: "",
	});

	const mobileNumberFormatting = (value) => {
		if (!value) return value;

		const phoneNumber = value.replace(/[^\d]/g, "");

		const phoneNumberLength = phoneNumber.length;

		if (phoneNumberLength < 4) return phoneNumber;

		if (phoneNumberLength < 7) {

			return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3)}`;
		}

		return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(
			3,
			7
		)}-${phoneNumber.slice(7, 11)}`;
	};

	const handleMobileNumber = (value, values) => {
		// console.log({ value });
		const formatted = mobileNumberFormatting(value);
		// console.log({ formatted });
		let prev = {...values};
		prev.mobile_number = formatted
		setInitialValues(prev);
	};

	const handlePhoneNumber = (value, values) => {
		// console.log({ value });
		const formatted = mobileNumberFormatting(value);
		// console.log({ formatted });
		let prev = {...values};
		prev.phone_number = formatted
		setInitialValues(prev);
	};

	const handlePasswordUpdate = async (userId, newPassword, setFieldValue) => {
		try {
			if (newPassword.length < 3) {
				throw "password is too short";
			}
			const { data } = await APIClient.mutate({
				mutation: CHANGE_USER_PASSWORD_BY_ADMIN,
				variables: {
					userId,
					newPassword,
				},
			});
			if (setFieldValue) {
				setFieldValue("password", "");
			}
			setPasswordChangeConfirmation(false);
			Toaster("success", "Password updated");
		} catch (e) {
			Toaster("error", e.message);
		}
	};

	const validationSchema = () => {
		return Yup.object().shape({
			id: Yup.number().required(),
			// userID: Yup.string().required(),
			username: Yup.string().required(),
			email: Yup.string().required(),
			customer_name: Yup.string().required(),
			company_registration_number: Yup.string().required(),
			company_name_en: Yup.string().required(),
			company_name_kr: Yup.string().required(),
		});
	};

	useEffect(() => {
		if (selected) {
			const obj = {};
			Object.keys(initialValues).forEach((key) => {
				if (key !== "password") {
					obj[key] = selected[key];
				}
			});
			fetchUser(selected.id);
			setInitialValues(obj);
			setBalance(obj.balance);
		}
	}, [selected]);

	const handleSubmit = async (values, formikUtils) => {
		let input = {};
		delete values.password;
		delete values.createdAt;
		delete values.balance;
		if (selected) {
			input = {
				updateInput: {
					...values,
				},
			};
		} else {
			// input = {
			//   createInput: {
			//     ...values,
			//   },
			// };
		}

		// try {
		// 	if (newPassword) {
		// 		try {
		// 			const passRes = await APIClient.mutate({
		// 				mutation: changeUserPasswordFromAdmin,
		// 				variables: {
		// 					userId: userId,
		// 					newPassword: newPassword,
		// 				},
		// 			});
		// 			console.log({ passRes });
		// 			Toaster("success", "Password Successfully Changed");
		// 		} catch (error) {
		// 			Toaster("error", error);
		// 			console.log(error);
		// 		}
		// 	}

		const res = await hocProps.handleSubmit({ ...input }, formikUtils);

		if (res) {
			const { data, reason } = res;
			// console.log({ passRes });
			if (onSuccess) {
				onSuccess(data, reason);
			}
			setShowConfirmModal(false);
		}
		// } catch (error) {
		// 	console.log(error);
		// }
	};

	const fetchUser = async (key) => {
		const Query = gql(`query User($id:Int){
      User(id:$id){
        business_registration
      }
    }`);

		try {
			const {
				data: {
					User: { business_registration, id },
				},
			} = await APIClient.query({
				query: Query,
				variables: {
					id: key,
				},
			});

			setBusinessRegistration(business_registration);
		} catch (e) {
			console.log(e);
		}
	};

	const downloadFile = (url) => {
		window.open(url, "_blank").focus();
		// var FileSaver = require("file-saver");
		// FileSaver.saveAs(url, url.split("/")[url.split("/").length - 1]);
	};

	return (
		<>
			<Modal
				open={isOpen}
				onClose={onClose}
				aria-labelledby="simple-modal-title"
				aria-describedby="simple-modal-description"
				style={{ overflow: "scroll" }}
			>
				<Box
					style={{
						padding: 20,
						minHeight: "100vh",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					<div style={{ minWidth: "1110px" }}>
						<Formik
							initialValues={initialValues}
							enableReinitialize
							onSubmit={handleSubmit}
							validationSchema={validationSchema}
						>
							{({
								values,
								errors,
								handleSubmit,
								handleChange,
								setFieldValue,
							}) => {
								return (
									<Form onSubmit={handleSubmit}>
										<CustomCard>
											<Stack spacing={"20px"} p="20px">
												<Typography fontSize={"20px"} fontWeight="700">
													업체 상세 정보
												</Typography>

												<Typography fontSize={14} fontWeight={700}>
													업체 기본 정보
												</Typography>
												<div
													style={{
														borderRadius: "10px",
														overflow: "hidden",
														border: "1px solid #E5E5E5",
													}}
													spacing="20px"
												>
													<Grid container>
														<Grid item xs={4}>
															<CustomInput
																labelWidth="280px"
																name={"company_name_kr"}
																onChange={handleChange}
																error={!!errors.company_name_kr}
																value={values.company_name_kr}
																label="업체명"
																stepper
															/>
														</Grid>

														<Grid item xs={4}>
															<CustomInput
																labelWidth="280px"
																name={"company_name_en"}
																value={values.company_name_en}
																onChange={handleChange}
																error={!!errors.company_name_en}
																label="업체 영문이름"
																stepper
															/>
														</Grid>

														<Grid item xs={4}>
															<CustomInput
																labelWidth="280px"
																name={"fax_number"}
																value={values.fax_number}
																onChange={handleChange}
																error={!!errors.fax_number}
																label="팩스번호"
																stepper
															/>
														</Grid>

														<Grid item xs={12}>
															<Divider />
														</Grid>

														<Grid item xs={4}>
															<CustomInput
																labelWidth="280px"
																name={"customer_name"}
																onChange={handleChange}
																error={!!errors.customer_name}
																value={values.customer_name}
																label="유저명"
																stepper
															/>
														</Grid>
														<Grid item xs={4}>
															<CustomInput
																labelWidth="280px"
																name={"username"}
																onChange={handleChange}
																error={!!errors.username}
																value={values.username}
																label="유저 아이디"
																stepper
															/>
														</Grid>

														{/* <Grid item xs={4}>
                              <CustomInput
                                 labelWidth="280px"

                                name={"userID"}
                                value={values.userID}
                                onChange={handleChange}
                                error={errors.userID}
                                label="유저 아이디"
                              />
                            </Grid> */}

														<Grid item xs={4}>
															<CustomInput
																labelWidth="280px"
																inputProps={{
																	"data-userid": values.id,
																}}
																value={values.password}
																ref={passwordRef}
																label="유저 비밀번호"
																name={"password"}
																type={"password"}
																onChange={handleChange}
																onKeyDown={(e) => {
																	if (e.key === "Enter") {
																		e.preventDefault();
																		if (e.target.value.length) {
																			e.target.onBlur = null;
																			setPasswordChangeConfirmation(true);
																		}
																	}
																}}
																onBlur={({ target: { value } }) => {
																	if (value.length)
																		setPasswordChangeConfirmation(true);
																}}
																stepper
															/>
														</Grid>

														<Grid item xs={12}>
															<Divider />
														</Grid>

														<Grid item xs={4}>
															<CustomInput
																labelWidth="280px"
																name={"phone_number"}
																onChange={(event) => {
																	handlePhoneNumber(event.target.value, values)
																}}
																error={!!errors.phone_number}
																value={values.phone_number}
																label="전화 번호"
																stepper
															/>
														</Grid>

														<Grid item xs={4}>
															<CustomInput
																labelWidth="280px"
																name={"mobile_number"}
																value={values.mobile_number}
																onChange={(event) => {
																	handleMobileNumber(event.target.value, values)
																}}
																error={!!errors.mobile_number}
																label="휴대폰 번호"
																stepper
															/>
														</Grid>

														<Grid item xs={4}>
															<CustomInput
																labelWidth="280px"
																name={"email"}
																value={values.email}
																onChange={handleChange}
																error={!!errors.email}
																label="이메일"
																stepper
															/>
														</Grid>

														<Grid item xs={12}>
															<Divider />
														</Grid>

														<Grid item xs={4}>
															<CustomInput
																labelWidth="280px"
																name={"company_registration_number"}
																value={values.company_registration_number}
																onChange={handleChange}
																error={!!errors.company_registration_number}
																label="사업자 등록번호"
																stepper
															/>
														</Grid>

														<Grid item xs={4}>
															<CustomInput
																labelWidth="280px"
																label="사업자 등록증"
																input={
																	<Link
																		style={{ padding: "6px 10px" }}
																		variant="outlined"
																		onClick={() =>
																			downloadFile(businessRegistration)
																		}
																	>
																		다운로드
																	</Link>
																}
																stepper
															/>
														</Grid>

														<Grid item xs={4}>
															<CustomInput
																labelWidth="280px"
																readOnly
																name={"createdAt"}
																value={Moment(values.CreatedAt).format(
																	"YYYY-MM-DD HH:mm"
																)}
																label="가입날짜"
																stepper
															/>
														</Grid>

														<Grid item xs={12}>
															<Divider />
														</Grid>

														<Grid item xs={4}>
															<CustomInput
																labelWidth="280px"
																value={balance.toLocaleString("en-us")}
																label="예치금"
																name={"balance"}
																readOnly
																stepper
																// onChange={handleChange}
															/>
														</Grid>

														<Grid item xs={4}>
															<CustomInput
																labelWidth="280px"
																label="전자세금계산서 이메일"
																value={values.e_tax_invoice_email}
																name={"e_tax_invoice_email"}
																onChange={handleChange}
																error={!!errors.e_tax_invoice_email}
																stepper
															/>
														</Grid>

														<Grid item xs={12}>
															<Divider />
														</Grid>

														<Grid item xs={4}>
															<CustomInput
																labelWidth="280px"
																name={"request_issuance_of_tax_invoice"}
																onChange={handleChange}
																error={!!errors.request_issuance_of_tax_invoice}
																value={values.request_issuance_of_tax_invoice}
																label="메모"
																stepper
															/>
														</Grid>
													</Grid>
												</div>

												<Stack spacing={"20px"}>
													<Typography fontSize={14} fontWeight={700}>
														발송인 정보 수정
													</Typography>

													<Grid
														container
														style={{
															borderRadius: "10px",
															overflow: "hidden",
															border: "1px solid #E5E5E5",
														}}
													>
														<Grid item xs={6}>
															<CustomInput
																labelWidth="280px"
																name={"english_address_full_address"}
																onChange={handleChange}
																error={!!errors.english_address_full_address}
																value={values.english_address_full_address}
																label="업체 주소"
																stepper
															/>
														</Grid>

														<Grid item xs={6}>
															<CustomInput
																labelWidth="280px"
																name={"address_2"}
																onChange={handleChange}
																error={!!errors.address_2}
																value={values.address_2}
																label="업체 주소2"
																stepper
															/>
														</Grid>

														<Grid item xs={12}>
															<Divider />
														</Grid>

														<Grid item xs={6}>
															<CustomInput
																labelWidth="280px"
																name={"english_address_full_address"}
																value={values.english_address_full_address}
																error={!!errors.english_address_full_address}
																onChange={handleChange}
																label="업체 영문주소"
																stepper
															/>
														</Grid>

														<Grid item xs={6}>
															<CustomInput
																labelWidth="280px"
																name={"return_address"}
																value={values.return_address}
																onChange={handleChange}
																error={!!errors.return_address}
																label="반송 주소"
																stepper
															/>
														</Grid>

														<Grid item xs={12}>
															<Divider />
														</Grid>

														<Grid item xs={6}>
															<CustomInput
																labelWidth="280px"
																name={"memo"}
																value={values.memo}
																error={!!errors.memo}
																onChange={handleChange}
																label="메모"
																stepper
															/>
														</Grid>
													</Grid>
												</Stack>

												<div
													style={{
														display: "flex",
														justifyContent: "flex-end",
														gap: "20px",
													}}
												>
													<CustomButton
														onClick={onClose}
														styles={{
															backgroundColor: "#F8F8FA",
															color: "#000",
															width: "auto",
															padding: "10px 20px 10px 20px",
														}}
													>
														취소
													</CustomButton>
													<CustomButton
														styles={{
															width: "auto",
															padding: "10px 20px 10px 20px",
														}}
														type="submit"
														// onClick={() => {
														//   // onClose();
														//   setShowConfirmModal(true);
														// }}
													>
														변경
													</CustomButton>

													{/* <Button
                            ref={submitButton}
                            type={"submit"}
                            hidden={true}
                          ></Button> */}
												</div>
											</Stack>
										</CustomCard>
										<ConfirmModal
											isOpen={passwordChangeConfirmation}
											onClose={() => {
												setPasswordChangeConfirmation(false);
											}}
											onConfirm={() => {
												handlePasswordUpdate(
													values.id,
													values.password,
													setFieldValue
												);
											}}
											title="사용자의 비밀번호를 변경하시겠습니까?"
											description={
												"확인을 누르시면 사용자의 비밀번호가 변경됩니다."
											}
										/>
									</Form>
								);
							}}
						</Formik>
					</div>
				</Box>
			</Modal>

			<ConfirmModal
				isOpen={showConfirmModal}
				onClose={() => {
					onOpen();
					setShowConfirmModal(false);
				}}
				onConfirm={() => {
					if (submitButton) {
						const { current } = submitButton;
						current.click();
					}
				}}
				title="입금 처리"
				description={"해당 업체를 승인 취소 하시겠습니까?"}
			/>
		</>
	);
}
