import writeXlsxFile from "write-excel-file";
import React, { useState } from "react";
import { CustomMasterQuery } from "../queries";
import moment from "moment";

const ExportEDI = async (ids, masterData) => {
	const master = masterData[0];
	// console.log({ master });
	let allReceptions = [];

	master.bags?.forEach((bag, index) =>
		bag.receptionLists?.forEach((reception) => allReceptions.push(reception))
	);
	// console.log({ allReceptions });

	const masterNumber = masterData[0].masterNumber;
	let data = [];

	//Date
	const Date = [
		{
			value: "Date",
			align: "center",
		},
		{
			value: master.releaseDate,
			align: "center",
		},
	];
	data.push(Date);
	//Date
	const Flight = [
		{
			value: "FLT NO.",
			align: "center",
		},
		{
			value: master.flightNumber,
			align: "center",
		},
	];
	data.push(Flight);
	//Date
	const DepartureAirport = [
		{
			value: "출발공항",
			align: "center",
		},
		{
			value: master.departureAirPort,
			align: "center",
		},
	];
	data.push(DepartureAirport);
	//Date
	const ArrivalAirport = [
		{
			value: "도착공항",
			align: "center",
		},
		{
			value: master.arrivalAirPort,
			align: "center",
		},
	];
	data.push(ArrivalAirport);
	//Date
	const MasterNumber = [
		{
			value: "M NO",
			align: "center",
		},
		{
			value: master.masterNumber,
			align: "center",
		},
		{},
		{},
		{},
		{},
		{},
		{},
		{},
		{},
		{},
		{},
		{},
		{},
		{},
		{},
		{
			value: "화주실적 및 세관등록용도",
			align: "center",
			span: 2,
			backgroundColor: "#32fa32",
		},
		{},
		{
			value: "미주등AMS신고화물용도",
			align: "center",
			span: 5,
			backgroundColor: "#ffa600",
		},
	];
	data.push(MasterNumber);

	let header = [
		{
			value: "No.",
			backgroundColor: "#ffff00",
			align: "center",
		},
		{
			value: "HAWB NO",
			backgroundColor: "#ffff00",
			align: "center",
		},
		{
			value: "CT",
			backgroundColor: "#ffff00",
			align: "center",
		},
		{
			value: "WT",
			backgroundColor: "#ffff00",
			align: "center",
		},
		{
			value: "신고금액(USD)",
			backgroundColor: "#ffff00",
			align: "center",
		},
		{
			value: "아이템",
			backgroundColor: "#ffff00",
			align: "center",
		},
		{
			value: "보내는화주명",
			backgroundColor: "#ffff00",
			align: "center",
		},
		{
			value: "보내는화주주소",
			backgroundColor: "#ffff00",
			align: "center",
		},
		{
			value: "받는화주명",
			backgroundColor: "#ffff00",
			align: "center",
		},
		{
			value: "받는화주주소",
			backgroundColor: "#ffff00",
			align: "center",
		},
		{
			value: "수출신고번호",
			backgroundColor: "#ffff00",
			align: "center",
		},
		{
			value: "수출신고 C/T",
			backgroundColor: "#ffff00",
			align: "center",
		},
		{
			value: "수출신고 W/T",
			backgroundColor: "#ffff00",
			align: "center",
		},
		{
			value: "분할선적(Y/N)",
			backgroundColor: "#ffff00",
			align: "center",
		},
		{
			value: "분할선적차수",
			backgroundColor: "#ffff00",
			align: "center",
		},
		{
			value: "거래코드 A:전자상거래 B:특송",
			backgroundColor: "#ffff00",
			align: "center",
		},
		{
			value: "사업자등록번호",
			backgroundColor: "#32fa32",
			align: "center",
		},
		{
			value: "세번부호",
			backgroundColor: "#32fa32",
			align: "center",
		},
		{
			value: "Agent Code",
			backgroundColor: "#ffffff",
			align: "center",
		},
		{
			value: "보내는화주연락처",
			backgroundColor: "#ffa600",
			align: "center",
		},
		{
			value: "받는 화주 우편번호",
			backgroundColor: "#ffffff",
			align: "center",
		},
		{
			value: "받는화주연락처",
			backgroundColor: "#ffa600",
			align: "center",
		},
		{
			value: "받는사람명",
			backgroundColor: "#ffa600",
			align: "center",
		},
		{
			value: "받는 사업자 번호",
			backgroundColor: "#ffa600",
			align: "center",
		},
		{
			value: "보내는 화주 담당자",
			backgroundColor: "#ffa600",
			align: "center",
		},
	];
	data.push(header);

	const columns = [];
	[...Array(25)].forEach((d) => {
		columns.push({
			width: 30,
		});
	});

	//columnData
	let receptionList = [];
	allReceptions.forEach((item, index) => {
		let reception = [];
		reception.push({ value: index + 1, align: "center" });
		reception.push({ value: item.shippingNumber, align: "center" });
		reception.push({
			value: item.productInformation?.productInformation?.length,
			align: "center",
		});
		reception.push({
			value: item.miscellaneousInformation.actualWeight,
			align: "center",
		});
		reception.push({
			value: item.productInformation?.totalPriceAmount,
			align: "center",
		});
		reception.push({
			value: item.productInformation?.productInformation?.map(
				(item) => item.productName
			),
			align: "center",
		});
		reception.push({ value: item.senderInformation?.name, align: "center" });
		reception.push({ value: item.senderInformation?.address, align: "center" });
		reception.push({ value: item.payeeInformation?.name, align: "center" });
		reception.push({
			value: item.payeeInformation?.streetAddress1,
			align: "center",
		});
		reception.push({
			value: item.miscellaneousInformation?.exportDeclarationNumber,
			align: "center",
		});
		reception.push({});
		reception.push({});
		reception.push({});
		reception.push({});
		reception.push({ value: "A", align: "center" });
		reception.push({
			value: item.userProfile?.business_registration,
			align: "center",
		});
		// console.log({ reception });
		receptionList.push(reception);
		data.push(reception);
	});
	// console.log({ receptionList });

	writeXlsxFile(data, {
		columns,
		fileName: masterNumber + "_유한테크노스EDI.xlsx",
	});
};
export default ExportEDI;
