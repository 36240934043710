/* eslint-disable no-restricted-globals */
import { Grid, Link, Paper } from "@mui/material";
import React, { useState, useEffect } from "react";
import CustomButton from "../../../components/CustomButton";
import TableContainer from "../../../components/TableContainerNew";
import { SetDefaultModal } from "./SetDefault";

import { deleteuserGroup, getRatesByGroup } from "./queries";
import { CreateGroup } from "./CreateGroup";

export function RateByCompanyNew() {
  const [loading, setLoading] = useState(false);
  const [usergroups, setUsergroups] = useState([]);
  const [fordefault, setFordefault] = useState();

  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleOpen2 = () => setOpen2(true);
  const handleClose2 = () => setOpen2(false);
  const [deleting, setDeleting] = useState(false);

  //Get the data from backend
  useEffect(() => {
    setLoading(true);
    getRatesByGroup().then((res) => {
      setLoading(false);
      setUsergroups(res.data.allUserGroups);
    });

    return () => {};
  }, []);

  //Delete group
  const deleteThisGroup = (id) => {
    if (confirm("해당 그룹을 삭제하시겠습니까?")) {
      setDeleting(true);
      deleteuserGroup(id).then(
        (res) => {
          setDeleting(false);
          //Remove deleted user
          const cloneData = [...usergroups];
          const filteredData = cloneData.filter((el) => el.id !== id);
          setUsergroups(filteredData);
        },
        (err) => {
          setDeleting(false);
          console.log(err);
        }
      );
    }
  };

  //Get new gorup to add to state
  const setNewgroup = (data) => {
    setUsergroups([...usergroups, data]);
    setOpen2(false);
  };

  return (
    <Paper
      sx={{
        maxWidth: "1150px",
        flexGrow: 1,
        boxShadow: "unset",
      }}
    >
      <Grid container flexDirection={"column"}>
        <Grid
          item
          xs={12}
          container
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Grid item xs={6} container spacing={1} alignItems={"center"}></Grid>

          <Grid
            item
            xs={5}
            sm={4}
            md={3}
            container
            spacing={1}
            style={{ display: "flex", justifyContent: "end" }}
          >
            <Grid item xs={6}>
              {/* <UploadRateCardPopover onChange={handleChange} /> */}
            </Grid>
            <Grid
              item
              xs={10}
              style={{ display: "flex", justifyContent: "end" }}
            >
              {/* <CustomButton
                variant={"outlined"}
                onClick={handleOpen}
                styles={{
                  color: "#000",
                  whiteSpace: "nowrap",
                  border: "1px solid #C4C4C4",
                  padding: "7px 30px",
                  borderRadius: 5,
                }}
                disabled={fordefault === undefined ? true : false}
              >
                기본값 설정
              </CustomButton> */}

              <CustomButton
                variant={"outlined"}
                onClick={handleOpen2}
                styles={{
                  color: "#000",
                  whiteSpace: "nowrap",
                  marginLeft: "10px",
                  border: "1px solid #C4C4C4",
                  padding: "7px 30px",
                  borderRadius: 5,
                }}
              >
                그룹 추가
              </CustomButton>
            </Grid>
          </Grid>
        </Grid>

        <Grid item container justifyContent={"space-between"} mt={3}>
          <Grid item xs={12}>
            {loading && <>Loading...</>}
            {usergroups.length > 0 && (
              <TableContainer
                {...{ isLoading: loading, requestCompleted: true }}
                headers={[<>그룹명</>, "업체수", "메모", "삭제"]}
                data={usergroups.map((item, index) => [
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <input
                      type="radio"
                      name="defaultSelect"
                      style={{ accentColor: "blue", marginRight: "10px" }}
                      selected={item.default ? true : false}
                      onChange={() =>
                        setFordefault({
                          name: item.name,
                          default: true,
                          id: item.id,
                        })
                      }
                    />
                    <Link href={`/rates-by-company/${item.id}`}>
                      {item.name}
                    </Link>
                  </div>,

                  <p>{item.users.length}</p>,
                  <p>{item.memo ? item.memo : "NA"}</p>,
                  <>
                    {deleting ? (
                      "Deleting..."
                    ) : (
                      <span onClick={() => deleteThisGroup(item.id)}>
                        <img
                          style={{ width: "15px" }}
                          src="/assets/images/Delete.png"
                          alt="img"
                        ></img>
                      </span>
                    )}
                  </>,
                ])}
              />
            )}
          </Grid>
        </Grid>

        <SetDefaultModal
          open={open}
          handleOpen={handleOpen}
          handleClose={handleClose}
          data={fordefault}
        />

        <CreateGroup
          open={open2}
          handleClose={handleClose2}
          newGroup={setNewgroup}
        />
      </Grid>
    </Paper>
  );
}
