import React from "react";
import styled from "styled-components";

export default function ModalButton({ children, primary = false, onClick }) {
  return (
    <Button onClick={onClick} primary={primary}>
      <Label primary={primary}>{children}</Label>
    </Button>
  );
}

const Button = styled.button`
  background-color: ${({ primary }) =>
    primary ? "RGB(91, 143, 247)" : "RGB(248, 248, 250)"};
  height: 44px;
  width: 76px;
  border-radius: 10px;
  outline: none;
  border: none;
  cursor: pointer;
`;

const Label = styled.div`
  font-family: "Spoqa Han Sans Neo";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 17px;
  color: ${({ primary }) => (primary ? "#ffffff" : "rgba(0, 0, 0, 0.6);")};
`;
