import {
	Box,
	Divider,
	Modal,
	Stack,
	TextareaAutosize,
	Typography,
} from "@mui/material";
import React from "react";
import CustomButton from "../components/CustomButton";
import CustomCard from "../components/CustomCard";
import Icons from "../utils/icon";
import { useNavigate } from "react-router-dom";

export default function AlertWayBill({
	isOpen,
	onClose,
	onConfirm,
	isLoading = false,
	title = "",
	description = "",
	buttonText = "",
	setRequestDescription,
	warningIcon,
	update,
}) {
	const navigate = useNavigate();
	return (
		<Modal
			open={isOpen}
			onClose={onClose}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Box
				style={{
					padding: 20,
					overflowX: "hidden",
					minHeight: "100vh",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				<CustomCard>
					<Stack spacing={"20px"} width={450} p="20px">
						<Stack direction="row" alignItems={"center"} spacing="10px">
							{/* <div>{!warningIcon ? "" : <Icons.Warning />}</div> */}

							<Typography fontSize={"20px"} fontWeight="700">
								{/* {title ? title : '고객 문의사항'} */}
								세부사항 변경 시 주의사항
							</Typography>
						</Stack>

						<div>
							<Typography
								// lineHeight={"0.5"}
								fontSize={"14px"}
								color="rgba(0,0,0,0.6)"
								component={"div"}
							>
								이미 송장이 부착된 화물의 경우 변경된 정보가 누락되어 있으니
								세부사항을 변경한 경우 송장을 재출력하여 화물에 부착하셔야
								합니다.
							</Typography>
						</div>

						<Divider />

						<div
							style={{
								display: "flex",
								justifyContent: "flex-end",
								gap: "20px",
							}}
						>
							<CustomButton
								onClick={() => {
									update();
									onClose();
								}}
								styles={{
									background: "#F8F8FA",
									color: "#000",
									width: "auto",
								}}
							>
								닫기
							</CustomButton>
						</div>
					</Stack>
				</CustomCard>
			</Box>
		</Modal>
	);
}
