import { useState } from "react";
import { Divider, Stack } from "@mui/material";
import styled from "styled-components";
import CustomFileUpload from "../../components/CustomFileUpload";
import CustomButton from "../../components/CustomButton";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Toaster from "../../utils/Toaster";
import { APIClient } from "../../utils/apiGraphql";
import { gql } from "@apollo/client/core";
import { useDataProvider, useLogout, useRedirect } from "react-admin";
import { PopUpModal } from "../../components/PopUpModal";
import ExcelDownload from "../../Common/download/배송 대량 업로드 양식.xlsx";
import { useUtils } from "../../utils/utils";
import { useEffect } from "react";
import ProgressModal from "../../modals/progressModal";
import BulkShippingModal from "../../modals/bulkShippingModal";
import BulkSuccessModal from "../../modals/BulkSuccessModal";
import { useQuery } from "@apollo/client";
import { getUserInfo } from "../../utils/constants";
import { BULK_CREATE_PROCESS_DETAILS } from "../../utils/constants";
import { useSubscription } from "@apollo/client";

var xlsx = require("xlsx");
const excelToJson = require("convert-excel-to-json");
// const fs = require('fs');

const StyledLink = styled.a`
	text-decoration: none;
`;

const getProductType = (value) => {
	const typeNumber = parseInt(value);
	switch (typeNumber) {
		case 1:
			return "Commercial";
		case 2:
			return "Gift";
		case 3:
			return "ReturnedGood";
		case 4:
			return "Documents";
		case 5:
			return "Others";
		default:
			return "";
	}
};

export function BulkShipping(props) {
	const [open, setOpen] = useState(false);
	const [file, setFile] = useState("");
	const [fileName, setFileName] = useState(null);
	const [fileData, setFileData] = useState(null);
	const [progressStart, setProgressStart] = useState(false);
	const [bulkCompletion, setBulkCompletion] = useState(false);
	const [userId, setUserId] = useState(null);
	const [bulkShippingFailure, setBulkShippingFailure] = useState([]);
	const [bulkShippingSuccess, setBulkShippingSuccess] = useState(false);
	const [bulkShippingSuccessCount, setBulkShippingSuccessCount] = useState([]);
	const [requestFilled, setRequestFilled] = useState(false);
	const dataProvider = useDataProvider();
	const { userData } = useUtils();
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);
	const redirectTo = useRedirect();
	const [subscriptionObserver, setSubscriptionObserver] = useState(null);
	// const result = useSubscription(BULK_CREATE_PROCESS_DETAILS, {
	// 	variables: {
	// 		subscriptionNumber: subscriptionNumber || 0,
	// 	},
	// });
	//
	// console.log(result)

	const generateUniqueNumber = () => {
		return Math.round(Math.random() * 9000000 + 1000000);
	};

	const handleUnsubscribe = () => {
		if (subscriptionObserver) {
			subscriptionObserver.unsubscribe();
		}
	};

	const handleSubscription = async (subscriptionNumber) => {
		return new Promise(async (resolve, reject) => {
			handleUnsubscribe();
			try {
				const subscriptionObserver = await APIClient.subscribe({
					query: BULK_CREATE_PROCESS_DETAILS,
					variables: {
						subscriptionNumber,
					},
				}).subscribe(
					(data) => {
						handleManageSubscriptionData(data);
					},
					(e) => {
						reject(e);
					},
					() => {
						console.log(`${subscriptionNumber} completed`);
					}
				);
				setSubscriptionObserver(subscriptionObserver);
				setBulkCompletion(true);
				setTimeout(() => {
					return resolve();
				}, 2000);
			} catch (e) {
				return reject(e);
			}
		});
	};

	const handleManageSubscriptionData = ({
		data: { bulkCreateProcessDetails: data },
	}) => {
		delete data.__typename
		setBulkShippingFailure((prevState) => [...prevState, data]);
	};

	useEffect(() => {
		return handleUnsubscribe;
	}, []);

	const { loading, error, data } = useQuery(getUserInfo);

	// console.log({ data });

	const logout = useLogout();
	const endProgress = () => {
		setProgressStart(false);
	};
	const closeSuccessfulModal = () => {
		setBulkShippingSuccess(false);
	};

	const apiData = {
		// first step

		senderInformation: {
			name: "",
			phoneNumber: "",
			address: "",
		},
		payeeInformation: {
			name: "",
			shippingCountry: "",
			phoneNumber1: "",
			phoneNumber2: "",
			zipCode: "",
			ssn: "",
			ukeori: "",
			euioss: "",
			states: "",
			cities: "",
			streetAddress1: "",
			streetAddress2: "",
			email: "",
			shippingTypeId: "",
		},
		productInformation: {
			orderNo1: "",
			orderNo2: "",
			departureCountryCourierNumber: "",
			currency: "",
			productInformation: [
				{
					barcode: "",
					productName: "",
					quantity: "",
					unitPrice: "",
					brandName: "",
					sku: "",
					purchaseURL: "",
					hscode: "",
				},
			],
		},
		miscellaneousInformation: {
			shippingMessage: "",
			actualWeight: null,
			userData: "",
			userDate2: "",
			userData3: "",
			boxQuantity: null,
			productType: "",
			exportDeclarationNumber: "",
		},
	};

	useEffect(() => {
		// console.log({ data });

		if (userData) {
			if (data) {
				if (data.User?.balance < 0 && data.User?.receptionMethodCheck) {
					// console.log(data.User.receptionMethodCheck);
					setOpen(true);
				}
			}
			// console.log(userData);
			setUserId(userData.userID);
		}
	}, [userData, data]);

	const [initialValues, setInitialValues] = useState(apiData);
	// const [initialValues, setInitialValues] = useState({
	// 	verifyPassword: "",
	// 	confirmPassword: "",
	// 	newPassword: "",
	// });
	const validationSchema = () => {
		return Yup.object().shape({
			verifyPassword: Yup.string().required(),
			confirmPassword: Yup.string()
				.oneOf([Yup.ref("newPassword")], "Password must be same")
				.required(),
			newPassword: Yup.string().required(),
		});
	};

	const handleBulkClose = () => {
		setBulkCompletion(false);
		setBulkShippingFailure([]);
	};

	const handleSubmit = async (values, { resetForm }) => {
		delete values.confirmPassword;
		const ChangePasswordMutation = gql(`
      mutation changeUserPassword($input: ChangePasswordInput!) {
        changeUserPassword(updateInput: $input) {
          status
        }
      }
    `);
		try {
			const res = await APIClient.mutate({
				mutation: ChangePasswordMutation,
				variables: {
					input: {
						...values,
					},
				},
			});
			Toaster("success", "Updated");
			// resetForm();
			logout();
		} catch (e) {
			resetForm();
			Toaster("error", e.message);
		}
	};

	const changeFile = async (file) => {
		// console.log(file);
		setFile(file);
		excelToJson.parseExcel(file);
	};

	const createReceptionList = async () => {};

	const readUploadFile = (e) => {
		e.preventDefault();
		if (e.target.files) {
			const fileSelect = e.target.files;
			setFileName(fileSelect[0].name);
			// set
			const reader = new FileReader();
			reader.onload = (e) => {
				const data = e.target.result;
				const workbook = xlsx.read(data, { type: "array" });
				const sheetName = workbook.SheetNames[0];
				const worksheet = workbook.Sheets[sheetName];
				const json = xlsx.utils.sheet_to_json(worksheet);

				var dataTosend = [];
				// console.log({ json });
				const newData = json.map((item, index) => {
					const key = Object.entries(item).map(([key, value], index1) => {
						let trim = key.split(/\r?\n/).at(0);
						trim = trim.trim();
						return { [trim]: value };
					});
					const newObj = Object.assign(...key);

					return newObj;
				});

				// console.log({ newData });

				newData.forEach((item) => {
					console.log(item);
					dataTosend.push({
						senderInformation_name:
							item["발송인 이름"] != undefined
								? item["발송인 이름"].toString()
								: "",
						senderInformation_phoneNumber:
							item["발송인 전화번호"] != undefined
								? item["발송인 전화번호"].toString()
								: "",
						senderInformation_address:item["발송인 주소"] != undefined?  item["발송인 주소"].toString(): '',
						payeeInformation_name:
							item["수취인 이름"] != undefined
								? item["수취인 이름"].toString()
								: "",
						payeeInformation_shippingCountry:
							item["도착국가 코드"] != undefined
								? item["도착국가 코드"].toString()
								: "",
						payeeInformation_shippingTypeId:
							item["배송타입"] != undefined ? item["배송타입"] : "",
						payeeInformation_phoneNumber1:
							item["수취인 전화번호1"] != undefined
								? String(item["수취인 전화번호1"]).toString()
								: "",
						payeeInformation_phoneNumber2:
							item["수취인 전화번호2"] != undefined
								? String(item["수취인 전화번호2"]).toString()
								: "",
						payeeInformation_zipCode:
							item["수취인 우편번호"] != undefined
								? String(item["수취인 우편번호"]).toString()
								: "",
						payeeInformation_ssn:
							item["CPF/VOEC"] != undefined
								? String(item["CPF/VOEC"]).toString()
								: "",
						payeeInformation_ukeori:
							item["EORI"] != undefined ? item["EORI"].toString() : "",
						payeeInformation_euioss:
							item["IOSS"] != undefined ? item["IOSS"].toString() : "",
						payeeInformation_email:
							item["고객 e-mail"] != undefined
								? String(item["고객 e-mail"]).toString()
								: "",
						payeeInformation_states:
							item["수취인 주소-지역"] != undefined
								? String(item["수취인 주소-지역"]).toString()
								: "",
						payeeInformation_cities:
							item["수취인 주소-도시"] != undefined
								? item["수취인 주소-도시"].toString()
								: "",
						payeeInformation_mexicoColony:
							item["Colony(멕시코)"] != undefined
								? item["Colony(멕시코)"].toString()
								: "",
						payeeInformation_streetAddress1:
							item["수취인 주소-나머지 주소"] != undefined
								? String(item["수취인 주소-나머지 주소"]).toString()
								: "",
						payeeInformation_streetAddress2:
							item["수취인 주소2-나머지 주소"] != undefined
								? item["수취인 주소2-나머지 주소"].toString()
								: "",
						productInformation_orderNo1:
							item["주문번호"] != undefined
								? String(item["주문번호"]).toString()
								: "",
						productInformation_orderNo2:
							item["주문번호2"] != undefined
								? String(item["주문번호2"]).toString()
								: "",

						productInformation_currency:
							item["통화단위"] != undefined ? item["통화단위"].toString() : "",
						productInformation_productInformation_barcode:
							item["상품 바코드"] != undefined
								? String(item["상품 바코드"]).toString()
								: "",
						productInformation_productInformation_productName:
							item["상품명"] != undefined ? item["상품명"].toString() : "",
						productInformation_departureCountryCourierNumber:
							item["출발국가 택배번호"] != undefined
								? String(item["출발국가 택배번호"]).toString()
								: "",

						productInformation_productInformation_quantity:
							item["수량"] != undefined ? parseInt(item["수량"]) : null,
						productInformation_productInformation_unitPrice:
							item["단가"] != undefined ? parseInt(item["단가"]) : null,
						productInformation_productInformation_brandName:
							item["브랜드명"] != undefined ? item["브랜드명"].toString() : "",

						productInformation_productInformation_sku:
							item["SKU"] != undefined ? String(item["SKU"]).toString() : "",
						productInformation_productInformation_purchaseURL:
							item["제품 URL"] != undefined ? item["제품 URL"].toString() : "",
						productInformation_productInformation_hscode:
							item["HS CODE"] != undefined
								? String(item["HS CODE"]).toString()
								: "",
						productInformation_totalPriceAmount:
							item["전체 가격"] != undefined
								? parseInt(item["전체 가격"])
								: null,
						miscellaneousInformation_shippingMessage:
							item["배송메세지"] != undefined
								? String(item["배송메세지"]).toString()
								: "",
						miscellaneousInformation_height: Number(
							item["높이(Cm)"] != undefined ? item["높이(Cm)"] : 0
						),
						miscellaneousInformation_horizontal: Number(
							item["가로(Cm)"] != undefined ? item["가로(Cm)"] : 0
						),
						miscellaneousInformation_portrait: Number(
							item["세로(Cm)"] != undefined ? item["세로(Cm)"] : 0
						),
						miscellaneousInformation_actualWeight:
							item["무게(Kg)"] != undefined ? Number(item["무게(Kg)"]) : null,
						miscellaneousInformation_productType: getProductType(
							item[
								"상품종류(1:Commercial,2:Gift,3:ReturnedGood,4:Documents,5:Other)"
							]
						),

						miscellaneousInformation_userData:
							item["사용자 데이터1"] != undefined
								? String(item["사용자 데이터1"]).toString()
								: "",
						miscellaneousInformation_userDate2:
							item["사용자 데이터2"] != undefined
								? String(item["사용자 데이터2"]).toString()
								: "",
						miscellaneousInformation_userData3:
							item["사용자 데이터3"] != undefined
								? String(item["사용자 데이터3"]).toString()
								: "",
						miscellaneousInformation_exportDeclarationNumber:
							item["수출신고번호"] != undefined
								? String(item["수출신고번호"]).toString()
								: "",
						// "miscellaneousInformation_applicableWeight": 20,
						// "miscellaneousInformation_applicableWeight": 30,
						// "miscellaneousInformation_bulkWeight": 40,
						miscellaneousInformation_boxQuantity:
							item["박스갯수"] != undefined ? Number(item["박스갯수"]) : null,
					});
				});
				setFileData(dataTosend);
			};
			reader.readAsArrayBuffer(e.target.files[0]);
		}
	};

	const handleSubmitData = async () => {
		if (!fileData) return
		// setProgressStart(true);
		const addBulkMutation = gql(`
    mutation createRequestShippingBulk($input: RequestShippingCreateBulkInput!, $subscriptionNumber: Float) {
      createRequestShippingBulk(createInput: $input, subscriptionNumber: $subscriptionNumber) {
        succeeded{
        orderNo
        }
        failed{
        line
        orderNo
        reason
        }
      }
    }
  `);
		try {
			const number = generateUniqueNumber();
			await handleSubscription(number);
			const res = await APIClient.mutate({
				mutation: addBulkMutation,
				variables: {
					input: {
						data: fileData,
					},
					subscriptionNumber: number,
				},
			});
			// debugger;data

			setRequestFilled(true);
			if (res.data?.createRequestShippingBulk?.failed.length === 0) {
				setBulkCompletion(false);
				setBulkShippingSuccess(true);
				setBulkShippingSuccessCount(
					res.data?.createRequestShippingBulk?.succeeded.length
				);
				Toaster("success", "배송 대량 신청건이 성공적으로 접수되었습니다.");
			}
			else{
				console.log("response",res);
				Toaster("error", res?.data?.createRequestShippingBulk?.failed[0]?.reason);
			}
		} catch (e) {
			Toaster("error", e.message);
			setProgressStart(false);
		} finally {
			handleUnsubscribe();
		}
	};

	return (
		<>
			<Formik
				initialValues={initialValues}
				enableReinitialize
				onSubmit={handleSubmit}
				validationSchema={validationSchema}
			>
				{({ handleSubmit, values, errors, handleChange }) => {
					return (
						<Form onSubmit={handleSubmit}>
							<Stack spacing={"50px"}>
								<div
									style={{
										borderRadius: "10px",
										overflow: "hidden",
										border: "1px solid #E5E5E5",
									}}
								>
									<CustomFileUpload
										// name={"verifyPassword"}
										value={file}
										onChange={(e) => readUploadFile(e)}
										helperText={
											!!errors.verifyPassword && errors.verifyPassword
										}
										// type={"password"}
										label="엑셀 업로드"
										fileName={fileName}
									/>

									<Divider />
								</div>

								<div
									style={{
										display: "flex",
										justifyContent: "flex-end",
										gap: "20px",
									}}
								>
									<a
										href={ExcelDownload}
										download={"배송 대량 신청 양식.xlsx"}
										style={{ textDecoration: "none" }}
									>
										<CustomButton
											width="100px"
											styles={{
												backgroundColor: "white",
												color: "rgba(0, 0, 0, 0.6)",
												whiteSpace: "nowrap",
												border: "1px solid #E5E5E5",
												width: "170px",
												padding: "10px",
											}}
										>
											<img
												src="./assets/images/excel.png"
												alt="img"
												style={{ marginRight: "5px" }}
											/>
											엑셀 양식 다운로드
										</CustomButton>
									</a>
									<CustomButton
										onClick={(e) => handleSubmitData()}
										width="100px"
									>
										등록
									</CustomButton>
									{/* deactivated pop for some time onClick={handleOpen}  */}
								</div>
							</Stack>
						</Form>
					);
				}}
			</Formik>
			<ProgressModal
				isOpen={progressStart}
				handleClose={endProgress}
				requestFilled
			/>
			<PopUpModal
				open={open}
				handleOpen={handleOpen}
				// handleClose={handleClose}
			/>
			<BulkShippingModal
				title={"배송 대량 신청 결과 안내"}
				description={
					"배송 대량 신청건 중, 접수에 실패한 배송 신청건을 안내드립니다."
				}
				isOpen={bulkCompletion}
				onClose={handleBulkClose}
				bulkShippingFailureReason={bulkShippingFailure}
			/>
			<BulkSuccessModal
				isOpen={bulkShippingSuccess}
				onClose={closeSuccessfulModal}
				count={bulkShippingSuccessCount}
			/>
		</>
	);
}
