/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import * as React from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { useGetList } from "../utils/utils";
import { gql } from "@apollo/client/core";
import { APIClient } from "../utils/apiGraphql";
import { useEffect, useState } from "react";
import Toaster from "../utils/Toaster";

export const DeliveryTimeGrid = ({ isEditable = false }) => {
  const { getData } = useGetList();
  const [data, setData] = useState([]);
  const [shouldRefetchData, setShouldRefetchData] = useState(true);
  const [deliveryTimelines, setDeliveryTimelines] = useState([]);

  const createDeliveryTimeline = async (duration, country, idx) => {
    if (idx.field[idx.field.length - 1] === "4") {
      let cloneData = [...deliveryTimelines];
      cloneData.push({
        id: deliveryTimelines.length + 1,
        country1: "",
        duration1: "",
        country2: "",
        duration2: "",
        country3: "",
        duration3: "",
      });
      setDeliveryTimelines(cloneData);
    }
    const Mutation = gql(`
      mutation createDeliveryTimeline($createInput: DeliveryTimelineCreateInput!) {
        createDeliveryTimeline(createInput: $createInput) {
          id
          country
          duration
        }
      }
    `);

    try {
      await APIClient.mutate({
        mutation: Mutation,
        variables: {
          createInput: {
            duration,
            country,
          },
        },
      });
      Toaster("success", `Created.`);
      setShouldRefetchData(!shouldRefetchData);
    } catch (e) {
      console.log(e);
      Toaster("error", e.message);
    }
  };

  const updateDeliveryTimeline = async (duration, country, id) => {
    const Mutation = gql(`
      mutation updateDeliveryTimeline($updateInput: DeliveryTimelineUpdateInput!) {
        updateDeliveryTimeline(updateInput: $updateInput) {
          id
          duration
          country
        }
      }
    `);

    try {
      await APIClient.mutate({
        mutation: Mutation,
        variables: {
          updateInput: {
            duration,
            country,
            id,
          },
        },
      });
      setShouldRefetchData(!shouldRefetchData);
      Toaster("success", "Updated");
    } catch (e) {
      Toaster("error", e.message);
    }
  };

  const handleCellEditCommit = (cell) => {
    const currentCountry =
      cell.field.substr(0, cell.field.length - 1) === "country"
        ? cell.value
        : cell.row[`country${cell.field[cell.field.length - 1]}`];
    const currentValue =
      cell.field.substr(0, cell.field.length - 1) === "country"
        ? cell.row[`duration${cell.field[cell.field.length - 1]}`]
        : cell.value;

    if (currentCountry && currentValue) {
      if (cell.formattedValue === "") {
        createDeliveryTimeline(currentValue, currentCountry, cell);
      } else {
        updateDeliveryTimeline(
          currentValue,
          currentCountry,
          data.find(
            (el) =>
              el.country === cell.formattedValue ||
              el.duration === cell.formattedValue
          ).id
        );
      }
    }
  };

  useEffect(() => {
    Promise.all([
      getData("DeliveryTimeline"),
      getData("ShippingCountries"),
    ]).then(([deliveryData, countriesData]) => {
      setData(deliveryData);

      let finalData = [];
      let pack = {};
      let count = 1;
      for (let i = 0; i < deliveryData.length; i++) {
        if ((i + 1) % 4 === 0) {
          let obj = {};
          obj.id = finalData.length;
          obj.country4 = deliveryData[i].country;
          obj.duration4 = deliveryData[i].duration;
          pack = { ...pack, ...obj };
          finalData.push(pack);
          count = 1;
          pack = {};
        } else {
          let obj = {};
          obj.id = finalData.length;
          obj[`country${count}`] = deliveryData[i].country;
          obj[`duration${count}`] = deliveryData[i].duration;
          pack = { ...pack, ...obj };
          count++;
        }
      }
      let leftObj =
        deliveryData.length - Math.floor(deliveryData.length / 4) * 4;

      //For non 4 divisble
      let lPack = {};
      lPack.id = Math.floor(deliveryData.length / 4);
      for (let i = 1; i <= leftObj; i++) {
        lPack[`country${i}`] =
          deliveryData[Math.floor(deliveryData.length / 4) * 4 + i - 1].country;
        lPack[`duration${i}`] =
          deliveryData[
            Math.floor(deliveryData.length / 4) * 4 + i - 1
          ].duration;
      }

      //Adding empty field
      for (let i = leftObj + 1; i < 5; i++) {
        lPack[`country${i}`] = "";
        lPack[`duration${i}`] = "";
      }
      finalData.push(lPack);
      setDeliveryTimelines(finalData);
    });
  }, [shouldRefetchData]);

  // function CustomEditComponent(props) {
  //   const { id, value, field } = props;
  //   const apiRef = useGridApiContext();

  //   const handleValueChange = (event, value) => {
  //     const newValue = value.name;
  //     apiRef.current.setEditCellValue({ id, field, value: newValue });
  //   };

  //   return (
  //     <Autocomplete
  //       fullWidth
  //       renderInput={(props) => {
  //         return <TextField {...props} fullWidth />;
  //       }}
  //       getOptionLabel={(option) => {
  //         return option.name;
  //       }}
  //       onChange={handleValueChange}
  //       options={countries}
  //     />
  //   );
  // }

  const columns = [
    {
      field: "country1",
      headerName: "국가",
      cellClassName: !isEditable && "coloredCountry",
      width: 134,
      sortable: false,
      editable: isEditable,
      // renderEditCell: (params) => {
      //   return <CustomEditComponent {...params} />;
      // },
    },
    {
      field: "duration1",
      cellClassName: !isEditable && "colored",
      headerName: "소요일",
      editable: isEditable,
      width: 134,
      sortable: false,
    },
    {
      field: "country2",
      cellClassName: !isEditable && "coloredCountry",
      headerName: "국가",
      sortable: false,
      width: 134,
      editable: isEditable,
      // renderEditCell: (params) => {
      //   return <CustomEditComponent {...params} />;
      // },
    },
    {
      field: "duration2",
      cellClassName: !isEditable && "colored",
      headerName: "소요일",
      editable: isEditable,
      width: 134,
      sortable: false,
    },
    {
      field: "country3",
      cellClassName: !isEditable && "coloredCountry",
      headerName: "국가",
      width: 134,
      sortable: false,
      editable: isEditable,
      // renderEditCell: (params) => {
      //   return <CustomEditComponent {...params} />;
      // },
    },
    {
      field: "duration3",
      cellClassName: !isEditable && "colored",
      headerName: "소요일",
      editable: isEditable,
      width: 134,
      sortable: false,
    },
    {
      field: "country4",
      cellClassName: !isEditable && "coloredCountry",
      headerName: "국가",
      width: 134,
      sortable: false,
      editable: isEditable,
      // renderEditCell: (params) => {
      //   return <CustomEditComponent {...params} />;
      // },
    },
    {
      field: "duration4",
      cellClassName: !isEditable && "colored",
      headerName: "소요일",
      editable: isEditable,
      width: 134,
      sortable: false,
    },
  ];

  return (
    <Box
      sx={{
        height: 475,
        maxWidth: "1150px",
        "& .coloredCountry": {
          "&:hover": {
            backgroundColor: "#F8FAFF",
          },
        },
        "& .colored": {
          color: "#4EADFB",
          "&:hover": {
            backgroundColor: "#F8FAFF",
          },
        },
      }}
    >
      <DataGrid
        rows={deliveryTimelines}
        columns={columns}
        hideFooter={true}
        style={{ borderRadius: "10px" }}
        disableColumnMenu
        onCellEditCommit={handleCellEditCommit}
      />
    </Box>
  );
};
