import { Checkbox, Grid, MenuItem, Pagination, Stack } from "@mui/material";
import { Form, Formik } from "formik";
import CalendarIcon from "../../../Common/assets/Images/CalendarIcon";
import DateRangePicker from "../../../components/DateRangePicker";
import React, { useEffect, useState, useCallback } from "react";
import CustomButton from "../../../components/CustomButton";
import Moment from "moment";
import { ExcelModal } from "../../../components/ExcelModal";
import { ExcelDownloadModal } from "../../../components/ExcelDownloadModal";
import { ExcelDownloadCancelModal } from "../../../components/ExcelDownloadCancelModal";
import { SelectBox, TextFieldBox } from "../../../components/CustomInput";
import TableContainer from "../../../components/TableContainer";
import { generateSearchData, handleOtherSearch } from "../../../utils/utils";
import { gql } from "@apollo/client";
import { APIClient } from "../../../utils/apiGraphql";
import DropdownAndSearchInput from "../../../components/DropdownAndSearchInput2";
import { onDropDownSearchChange } from "../../../utils/onDropDownSearchChange";
import Toaster from "../../../utils/Toaster";
import { ConstructionOutlined } from "@mui/icons-material";

export default function SettleMentHistory() {
	const [filterQuery, setFilterQuery] = useState(null);
	const [searchQuery, setSearchQuery] = useState({});
	const [page, setPage] = useState(1);
	const [perPage, setPerPage] = useState(10);
	const [item, setItem] = useState("releaseDate");
	const [data, setData] = useState(null);
	const [count, setCount] = useState(null);
	const [isLoading, setIsLoading] = useState(false);

	const [openExcelModal, setOpenExcelModal] = useState(false);
	const handleExcelModalOpen = () => setOpenExcelModal(true);
	const handleExcelModalClose = () => setOpenExcelModal(false);

	const [openExcelDownloadModal, setOpenExcelDownloadModal] = useState(false);
	const handleExcelDownloadModalOpen = () => setOpenExcelDownloadModal(true);
	const handleExcelDownloadModalClose = () => setOpenExcelDownloadModal(false);
	const [checkedList, setCheckedList] = useState([]);

	const [openExcelDownloadCancelModal, setOpenExcelDownloadCancelModal] =
		useState(false);
	const handleExcelDownloadCancelModalOpen = () =>
		setOpenExcelDownloadCancelModal(true);
	const handleExcelDownloadCancelModalClose = () =>
		setOpenExcelDownloadCancelModal(false);

	const getSettlememtHistory = async (filter) => {
		setIsLoading(true);
		const Query = gql(`
        query($filter:SettlementHistoryStatsFilter) {
          allSettlementHistoryStats(filter:$filter){
            history{
              shippingStarted
              companyName
              id
              deliveryCompleted
              overall
            }
          }
        }
      `);

		try {
			const res = await APIClient.query({
				query: Query,
				variables: {
					filter: filter,
					page: page,
					perPage: perPage,
				},
			});

			setData(res.data?.allSettlementHistoryStats?.history);
			setIsLoading(false);
		} catch (e) {
			console.log(e);
			Toaster("error", e);
			setIsLoading(false);
		}
	};

	useEffect(() => {
		getSettlememtHistory();
	}, []);

	const handleSearch = (filterParams) => {
		getSettlememtHistory(JSON.parse(JSON.stringify(filterParams)));
	};

	const doSearch = useCallback(() => {
		handleSearch(filterQuery);
		// getSettlememtHistory(filterQuery);
	}, [filterQuery]);

	useEffect(() => {
		doSearch();
	}, [filterQuery]);

	const handleCheck = ({ target }) => {
		const { value, checked } = target;
		setCheckedList((prevState) => {
			const temp = [...prevState];
			if (checked) {
				temp.push(+value);
				return temp;
			} else {
				return temp.filter((res) => res !== +value);
			}
		});
	};

	function handleChange(value) {
		if (Object.keys(value).length > 0) {
			const splitted = value?.createdAt?.split(" ");
			const data1 = {
				[item]: {
					gte: splitted[0],
					lte: splitted[2],
				},
			};

			if (splitted[0] != splitted[2]) {
				setTimeout(() => handleSearch(data1), 1000);
			}
		}
	}

	const handlePageChange = (e, number) => {
		setPage(number);
	};

	return (
		<Stack spacing={"35px"}>
			<Grid container justifyContent={"space-between"} alignItems={"center"}>
				<Grid item xs={3} alignItems={"center"}>
					<CustomButton
						onClick={
							checkedList.length
								? handleExcelModalOpen
								: handleExcelDownloadCancelModalOpen
						}
						styles={{
							width: "70%",
							whiteSpace: "nowrap",
							color: "black",
							backgroundColor: "transparent",
							border: "1px solid #EEEEEE",
						}}
					>
						<img
							style={{ width: "30px", paddingRight: "10px" }}
							src="/assets/images/excel.png"
							alt="excel"
						></img>
						엑셀 다운로드
					</CustomButton>
				</Grid>
				<Grid item xs={9}>
					<Grid
						item
						container
						columnGap={1}
						xs={12}
						alignItems={"center"}
						style={{ marginLeft: "4%" }}
					>
						<Grid item padding="0" xs={1.5}>
							<SelectBox
								defaultValue={item}
								// value={item}
								onChange={(event) => setItem(event.target.value)}
								styles={{ borderRadius: "10px" }}
							>
								<MenuItem value={"releaseDate"}>출고일</MenuItem>
								<MenuItem value={"receiptDate"}>접수일</MenuItem>
							</SelectBox>
						</Grid>
						<Grid item xs={3.5} style={{ margin: "auto 10px" }}>
							<Formik
								enableReinitialize
								initialValues={
									searchQuery || {
										createdAt: `${Moment().format(
											"YYYY-MM-DD"
										)} - ${Moment().format("YYYY-MM-DD")}`,
									}
								}
								// onSubmit={(values) => {console.log("sunetij"); handleChange(values)}}
								onSubmit={(values) => {}}
							>
								{({ values, handleSubmit, setFieldValue, resetForm }) => {
									return (
										<Form onSubmit={handleSubmit}>
											<div>
												<DateRangePicker
													showLabel={false}
													name={"createdAt"}
													value={values?.["createdAt"]}
													setFieldValue={setFieldValue}
													iconEnd={<CalendarIcon />}
													onChange={handleChange(values)}
												/>
											</div>
										</Form>
									);
								}}
							</Formik>
						</Grid>

						<Grid item padding="0" xs={6}>
							<DropdownAndSearchInput
								onChange={(data) =>
									onDropDownSearchChange(data, setFilterQuery)
								}
								value={filterQuery}
								defaultValue={{ key: "customer_name", value: "" }}
								dropDownValues={[{ value: "customer_name", title: "업체명" }]}
								inputFieldPlaceHolder={"업체명을 입력해주세요."}
							/>
							{/* <CustomButton
								variant={"outlined"}
								styles={{
									color: "#000",
									border: "1px solid #C4C4C4",
									borderRadius: 5,
									alignSelf: "center",
								}}
							>
								업체명
							</CustomButton> */}
						</Grid>

						{/* <Grid item xs={3.5} style={{ marginLeft: "10px" }}>
							<TextFieldBox
								placeholder="업체명을 입력해주세요."
								onChange={({ value }) => {
									handleOtherSearch(value, setFilterQuery);
								}}
							/>
						</Grid> */}

						{/* <Grid padding="0" xs={1} style={{ marginLeft: "10px" }}>
							<CustomButton
								type={"submit"}
								styles={{
									borderRadius: 5,
									alignSelf: "center",
									backgroundColor: "#5B8FF7",
									color: "white",
								}}
								onClick={doSearch}
							>
								검색
							</CustomButton>
						</Grid> */}
					</Grid>
				</Grid>
			</Grid>
			<div style={{ position: "relative" }}>
				<TableContainer
					{...{ isLoading: isLoading, requestCompleted: true }}
					headers={[
						<Checkbox
							checked={data?.every((res, index) =>
								checkedList.includes(res.id)
							)}
							onChange={({ target }) => {
								if (target.checked) {
									setCheckedList(data?.map((res, index) => res.id));
								} else {
									setCheckedList([]);
								}
							}}
						/>,
						"No.",
						"업체명",
						"출고수량",
						"배송시작",
						"배송완료",
					]}
					data={data?.map((item, index) => [
						<Checkbox
							key={index}
							onChange={handleCheck}
							value={item.id}
							checked={checkedList.some((res) => res === item.id)}
						/>,
						data.length - ((page - 1) * perPage + index),
						item.companyName,
						item.overall,
						<div style={{ color: "blue" }}>{item.shippingStarted}</div>,
						<div style={{ color: "red" }}>{item.deliveryCompleted}</div>,
						// <Link>배송비 내역</Link>,
					])}
				/>
			</div>
			<div
				style={{
					display: "flex",
					justifyContent: "center",
					marginTop: 30,
				}}
			>
				<Pagination
					count={
						data?.length && perPage ? Math.ceil(data?.length / perPage) : 0
					}
					page={page}
					onChange={handlePageChange}
					color="primary"
					shape="rounded"
				/>
			</div>
			<ExcelModal
				open={openExcelModal}
				ids={checkedList}
				handleOpen={handleExcelModalOpen}
				handleClose={handleExcelModalClose}
			/>
			<ExcelDownloadModal
				open={openExcelDownloadModal}
				handleOpen={handleExcelDownloadModalOpen}
				handleClose={handleExcelDownloadModalClose}
			/>
			<ExcelDownloadCancelModal
				open={openExcelDownloadCancelModal}
				handleOpen={handleExcelDownloadCancelModalOpen}
				handleClose={handleExcelDownloadCancelModalClose}
			/>
		</Stack>
	);
}
