import { Box, Divider, Modal, Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import CustomButton from "../components/CustomButton";
import CustomCard from "../components/CustomCard";
import Progressbar from "../components/progressbar";
import Icons from "../utils/icon";

export default function ProgressModal({
	isOpen,
	onClose,
	onConfirm,
	isLoading = false,
	title = "",
	description = "",
	lastBtn,
	progressFilled,
	// handleDelete,
	...props
}) {
	return (
		<Modal
			open={isOpen}
			onClose={onClose}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Box
				style={{
					padding: 20,
					overflowX: "hidden",
					minHeight: "100vh",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					backgroundColor: "transparent !important",
				}}
			>
				<CustomCard backgroundColor={"transparent"}>
					<Progressbar progressFilled />
				</CustomCard>
			</Box>
		</Modal>
	);
}
