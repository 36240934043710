import { APIClient } from "../../../../utils/apiGraphql";
import { gql } from "@apollo/client/core";

//Update waybill status
export const updateReqShipping = async (id) => {
	const mutation = gql(`
  mutation updateWaybillPrintStatus($shippingRequestId: Float!){
    updateWaybillPrintStatus(shippingRequestId: $shippingRequestId){
        id
      }
    }
    `);

	const res = await APIClient.mutate({
		mutation: mutation,
		variables: {
			shippingRequestId: id,
		},
	});
	return res;
};
