import { Box, Checkbox, Grid, MenuItem, Stack } from "@mui/material";
import React from "react";
import CustomButton from "../CustomButton";
import { SelectBox } from "../CustomInput";
import CustomPopover from "../CustomPopover";
import CustomSelect from "../CustomSelect";
import DropdownAndSearchInput from "../DropdownAndSearchInput2";
import TableContainer from "../TableContainer";
import { onDropDownSearchChange } from "../../utils/onDropDownSearchChange";
import moment from "moment";

export default function BagItems({
	filterQuery,
	setFilterQuery,
	handleCheck,
	handleSelect,
	checkedList,
	setCheckedList,
	setSelectedDeleteId,
	setOpenDeleteConfirmModal,
	hocProps,
	data = [],
	handleSetPerPage,
	perPage,
	setIsMasterPackagingModalOpen,
}) {
	// console.log("filter", filterQuery);
	return (
		<Stack spacing={"35px"}>
			<Grid container justifyContent={"space-between"}>
				<Grid item xs={2}>
					<Grid container spacing="10px" alignItems={"center"}>
						<Grid item xs={6}>
							<CustomButton
								variant={"outlined"}
								onClick={(e) => {
									setIsMasterPackagingModalOpen(true);
								}}
								styles={{
									color: "#0055FF",
									border: "1px solid #C4C4C4",
									borderRadius: 4,
									height: "40px",
								}}
							>
								BAG 이동
							</CustomButton>
						</Grid>
						<Grid item xs={6}>
							<CustomButton
								onClick={(e) => setOpenDeleteConfirmModal(true)}
								variant={"outlined"}
								styles={{
									color: "#FF000F",
									border: "1px solid #C4C4C4",
									borderRadius: 4,
									height: "40px",
								}}
							>
								백에서 삭제
							</CustomButton>
						</Grid>
					</Grid>
				</Grid>

				<Grid item xs={9}>
					<Grid
						container
						alignItems={"center"}
						spacing="10px"
						style={{ justifyContent: "space-between" }}
					>
						{/* <Grid item xs={1.5}>
							<CustomPopover>
								{({ close }) => {
									return <></>;
								}}
							</CustomPopover>
						</Grid> */}
						<Grid item xs={9}>
							<DropdownAndSearchInput
								onChange={(data) =>
									onDropDownSearchChange(data, setFilterQuery)
								}
								value={filterQuery}
								defaultValue={{ key: "default", value: "" }}
								inputFieldPlaceHolder={"검색어를 입력하세요."}
								dropDownValues={[
									{ value: "default", title: "검색필터 선택" },
									{ value: "trackingNumber", title: "접수번호" },
									{ value: "shippingNumber", title: "배송번호" },
								]}
							/>
						</Grid>
						<Grid item xs={1.5}>
							<CustomSelect
								value={String(perPage)}
								label=""
								onChange={({ target }) => {
									handleSetPerPage(target.value);
								}}
								options={["10", "20", "30", "40", "50", "100"]}
							></CustomSelect>
						</Grid>
					</Grid>
				</Grid>
			</Grid>

			<div style={{ position: "relative" }}>
				<TableContainer
					{...hocProps}
					headers={[
						<Checkbox
							checked={data.every((res) => checkedList.includes(res.id))}
							onChange={({ target }) => {
								if (target.checked) {
									setCheckedList(data.map((res) => res.id));
								} else {
									setCheckedList([]);
								}
							}}
						/>,
						"No.",
						"접수번호",
						"업체명",
						"상품 바코드",
						"상품이름",
						"상품수량",
						"총상품금액",
						"실제무게",
						"부피무게",
						"적용무게",
						"배송번호",
						"배송국가",
						"배송종류",
						"배송상태",
						"배송비",
						"접수날짜",
						"출고날짜",
						// "삭제",
					]}
					data={data.map((item, index) => {
						return [
							<Checkbox
								onChange={(e) => {
									handleCheck(e);
									handleSelect(item);
								}}
								value={item.id}
								checked={checkedList.some((res) => res === item.id)}
							/>,
							data?.length - index,
							item.trackingNumber,
							<div style={{ width: "150px" }}>
								{item.user?.company_name_kr}
							</div>,
							<Box sx={{ width: 230 }}>
								{item.productInformation.productInformation.map(
									(subItem, index) => {
										return (
											<div>
												<p>
													{subItem.barcode}{" "}
													{index <
														item.productInformation.productInformation.length -
															1 && ","}
												</p>
											</div>
										);
									}
								)}
							</Box>,
							<Box>
								{item.productInformation.productInformation.map(
									(subItem, index) => {
										return (
											<div>
												<p>
													{subItem.productName.length > 20 &&
														subItem.productName.slice(0, 20).concat("...")}{" "}
													{index <
														item.productInformation?.productInformation
															?.length -
															1 && ","}
												</p>
											</div>
										);
									}
								)}
							</Box>,
							<Box>
								{item.productInformation?.productInformation?.map(
									(subItem, index) => {
										return (
											<div>
												<p>
													{subItem?.quantity}{" "}
													{index <
														item.productInformation.productInformation?.length -
															1 && ","}
												</p>
											</div>
										);
									}
								)}
							</Box>,
							item.productInformation?.totalPriceAmount +
								" " +
								item.productInformation?.currency,
							item.miscellaneousInformation?.actualWeight + "KG",
							item.miscellaneousInformation?.bulkWeight + "KG",
							item.miscellaneousInformation?.applicableWeight + "KG",
							item.shippingNumber,
							item.payeeInformation?.shippingCountry?.code,
							item.shippingType?.shippingType,
							item.status === "waiting_warehousing"
								? "입고대기"
								: item.status === "warehousing_completed"
								? "입고완료"
								: item.status === "waiting_delivery"
								? "출고대기"
								: item.status === "started_delivery"
								? "출고완료"
								: item.status === "completed_delivery" && "배송완료",
							item.deliveryFee?.toLocaleString("en-US"),
							<Box style={{ whiteSpace: "nowrap" }}>
								{item.createdAt &&
									moment(item.createdAt).format("YYYY-MM-DD Thh.mm.ss")}
							</Box>,
							<Box style={{ whiteSpace: "nowrap" }}>
								{item.processReleaseDate &&
									moment(item.processReleaseDate).format(
										"YYYY-MM-DD Thh.mm.ss"
									)}
							</Box>,
							// <CustomButton
							//   onClick={() => {
							//     setOpenDeleteConfirmModal(true);
							//     setSelectedDeleteId(item.id);
							//   }}
							//   styles={{
							//     backgroundColor: "#FF000F",
							//     color: "#FFFFFF",
							//     borderRadius: 4,
							//     height: "25px",
							//     width: "80px",
							//   }}
							// >
							//   삭제
							// </CustomButton>,
						];
					})}
				/>
			</div>
		</Stack>
	);
}
