import { Divider, Grid, MenuItem, Typography } from "@mui/material";
import CustomInput from "../../../../components/CustomInput";
import React, { useState } from "react";

export const CreateStepFour = ({
	handleChange,
	handleBlur,
	shippingTypes,
	values,
	errors,
}) => {
	return (
    <>
      <div
        style={{
          borderRadius: 10,
          overflow: "hidden",
          border: "1px solid #E5E5E5",
          overflowX: "auto",
        }}
      >
        <Grid container>
          <Grid item xs={12}>
            <div>
              <CustomInput
                stepper={true}
                placeholder="배송메시지를 입력해주세요."
                label="배송메시지"
                labelWidth="175px"
                name="miscellaneousInformation.shippingMessage"
                value={values.miscellaneousInformation.shippingMessage}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {/* 
       {errors?.miscellaneousInformation?.shippingMessage && <Typography fontSize={"14px"} margin="10px " color="#ff0000">
          {"배송메시지를 입력해주세요."}
        </Typography> } */}
            </div>
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={6}>
            <div>
              <CustomInput
                stepper={true}
                placeholder="가로 길이를 입력해주세요."
                label="가로(CM)"
                type={"number"}
                step={0.01}
                name="miscellaneousInformation.horizontal"
                value={values.miscellaneousInformation.horizontal}
                onChange={handleChange}
                onBlur={handleBlur}
                labelWidth="200px"
              />
            </div>
            {/* <Typography fontSize={"14px"} margin="10px " color="#ff0000">
          {errors?.miscellaneousInformation?.horizontal}
        </Typography> */}
          </Grid>

          <Grid item xs={6}>
            <div>
              <CustomInput
                stepper={true}
                placeholder="세로 길이를 입력해주세요."
                label="세로(CM)"
                type={"number"}
                step={0.01}
                name="miscellaneousInformation.portrait"
                value={values.miscellaneousInformation.portrait}
                onChange={handleChange}
                onBlur={handleBlur}
                labelWidth="200px"
              />

              {/* <Typography fontSize={"14px"} margin="10px " color="#ff0000">
          {errors?.miscellaneousInformation?.portrait}
        </Typography> */}
            </div>
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={6}>
            <div>
              <CustomInput
                stepper={true}
                placeholder="높이를 입력해주세요."
                label="높이(CM)"
                type={"number"}
                step={0.01}
                name="miscellaneousInformation.height"
                value={values.miscellaneousInformation.height}
                onChange={handleChange}
                onBlur={handleBlur}
                labelWidth="200px"
              />

              {/* <Typography fontSize={"14px"} margin="10px " color="#ff0000">
          {errors?.miscellaneousInformation?.height}
        </Typography> */}
            </div>
          </Grid>

          <Grid item xs={6}>
            <div>
              <CustomInput
                stepper={true}
                placeholder="실제 무게를 입력해주세요."
                label="실제 무게(KG)"
                required={true}
                type={"number"}
                step={0.01}
                name="miscellaneousInformation.actualWeight"
                value={values.miscellaneousInformation.actualWeight}
                onChange={handleChange}
                onBlur={handleBlur}
                labelWidth="200px"
              />
              {errors?.miscellaneousInformation?.actualWeight && (
                <Typography fontSize={"14px"} margin="10px " color="#ff0000">
                  {"실제 무게를 입력해주세요."}
                </Typography>
              )}
            </div>
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={6}>
            <div>
              <CustomInput
                stepper={true}
                placeholder="적용 무게를 입력해주세요."
                label="적용 무게(KG)"
                type={"number"}
                step={0.01}
                required={false}
                name="miscellaneousInformation.applicableWeight"
                value={values.miscellaneousInformation.applicableWeight}
                onChange={handleChange}
                onBlur={handleBlur}
                labelWidth="200px"
              />

              {/* <Typography fontSize={"14px"} margin="10px " color="#ff0000">
          {errors?.miscellaneousInformation?.applicableWeight}
        </Typography> */}
            </div>
          </Grid>

          <Grid item xs={6}>
            <div>
              <CustomInput
                stepper={true}
                placeholder="부피 무게를 입력해주세요."
                label="부피 무게(KG)"
                type={"number"}
                step={0.01}
                required={false}
                name="miscellaneousInformation.bulkWeight"
                value={values.miscellaneousInformation.bulkWeight}
                onChange={handleChange}
                onBlur={handleBlur}
                labelWidth="200px"
              />

              {/* <Typography fontSize={"14px"} margin="10px " color="#ff0000">
          {errors?.miscellaneousInformation?.bulkWeight}
        </Typography> */}
            </div>
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          {/* <Grid item xs={6}>
        <div>
          <CustomInput
            select
            label="배송타입"
            required={true}
            name="miscellaneousInformation.shippingTypeId"
            defaultValue={values.miscellaneousInformation.shippingTypeId}
            onChange={handleChange}
            onBlur={handleBlur}
          >
            {shippingTypes.map((item, index) => {
              return (
                <MenuItem key={index} value={item.id}>
                  {item.deliveryType}
                </MenuItem>
              );
            })}
          </CustomInput>
          {errors?.miscellaneousInformation?.shippingTypeId && (
            <Typography fontSize={"14px"} margin="10px " color="#ff0000">
              {errors?.miscellaneousInformation?.shippingTypeId}
            </Typography>
          )}
        </div>
      </Grid> */}
          <Grid item xs={6}>
            <div>
              <CustomInput
                stepper={true}
                placeholder="사용자 데이터를 입력해주세요."
                label="사용자 데이터1"
                name="miscellaneousInformation.userData"
                value={values.miscellaneousInformation.userData}
                onChange={handleChange}
                onBlur={handleBlur}
                labelWidth="200px"
              />

              {/* <Typography fontSize={"14px"} margin="10px " color="#ff0000">
          {errors?.miscellaneousInformation?.userData}
        </Typography> */}
            </div>
          </Grid>
          <Grid item xs={6}>
            <div>
              <CustomInput
                placeholder="사용자 데이터를 입력해주세요."
                label="사용자 데이터2"
                name="miscellaneousInformation.userDate2"
                value={values.miscellaneousInformation.userDate2}
                onChange={handleChange}
                onBlur={handleBlur}
                labelWidth="200px"
                stepper={true}
              />

              {/* <Typography fontSize={"14px"} margin="10px " color="#ff0000">
          {errors?.miscellaneousInformation?.userDate2}
        </Typography> */}
            </div>
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={6}>
            <div>
              <CustomInput
                stepper={true}
                placeholder="사용자 데이터를 입력해주세요."
                label="사용자 데이터3"
                onBlur={handleBlur}
                name="miscellaneousInformation.userData3"
                value={values.miscellaneousInformation.userData3}
                onChange={handleChange}
                labelWidth="200px"
              />

              {/* <Typography fontSize={"14px"} margin="10px " color="#ff0000">
          {errors?.miscellaneousInformation?.userData3}
        </Typography> */}
            </div>
          </Grid>
          <Grid item xs={6}>
            <div>
              <CustomInput
                stepper={true}
                type="number"
                placeholder="박스 수량을 입력해주세요."
                label="박스 수량"
                onBlur={handleBlur}
                required={true}
                name="miscellaneousInformation.boxQuantity"
                value={values.miscellaneousInformation.boxQuantity}
                onChange={handleChange}
                labelWidth="200px"
              />
              {errors?.miscellaneousInformation?.boxQuantity && (
                <Typography fontSize={"14px"} margin="10px " color="#ff0000">
                  {"박스 수량을 입력해주세요."}
                </Typography>
              )}
            </div>
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={6}>
            <div>
              <CustomInput
                stepper={true}
                select
                label="상품종류"
                name="miscellaneousInformation.productType"
                onChange={handleChange}
                onBlur={handleBlur}
                required={true}
                defaultValue={values.miscellaneousInformation.productType}
                labelWidth="200px"
              >
                <MenuItem value={"Commercial"}>Commercial</MenuItem>
                <MenuItem value={"Gift"}>Gift</MenuItem>
                <MenuItem value={"ReturnedGood"}>ReturnedGood</MenuItem>
                <MenuItem value={"Documents"}>Documents</MenuItem>
                <MenuItem value={"Other"}>Other</MenuItem>
              </CustomInput>
            </div>
          </Grid>
          <Grid item xs={6}>
            <div>
              <CustomInput
                stepper={true}
                placeholder="수출 신고 번호를 입력해주세요."
                label="수출 신고 번호"
                name="miscellaneousInformation.exportDeclarationNumber"
                value={values.miscellaneousInformation.exportDeclarationNumber}
                onChange={handleChange}
                onBlur={handleBlur}
                labelWidth="200px"
              />

              {/* <Typography fontSize={"14px"} margin="10px " color="#ff0000">
          {errors?.miscellaneousInformation?.exportDeclarationNumber}
        </Typography> */}
            </div>
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={12}>
            <div>
              <CustomInput
                stepper={true}
                placeholder="쿠폰 정보를 입력해주세요."
                label="쿠폰 적용"
                name="miscellaneousInformation.coupon"
                value={values.miscellaneousInformation.coupon}
                onChange={handleChange}
                onBlur={handleBlur}
                labelWidth="175px"
              />

              {/* <Typography fontSize={"14px"} margin="10px " color="#ff0000">
          {errors?.miscellaneousInformation?.exportDeclarationNumber}
        </Typography> */}
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
};
