import * as React from "react";
import { useEffect } from "react";
import { styled, useTheme } from "@mui/material/styles";
import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { Divider } from "@mui/material";
import { gql } from "@apollo/client/core";
import { APIClient } from "../utils/apiGraphql";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import { Layout as LayoutBox, MenuItemLink, useLogout } from "react-admin";
import {
	AccountCircleOutlined,
	ExpandLess,
	ExpandMore,
} from "@mui/icons-material";
import { Button, Collapse, Link, Menu, MenuItem } from "@mui/material";
import { useUtils } from "../utils/utils";
import { useMemo } from "react";
import { useTopBarTitle } from "../hooks/index";
import {
	Link as RouterLink,
	Navigate,
	useLocation,
	useNavigate,
} from "react-router-dom";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
	focusColor: {
		// "&:focus::after": { bgcolor: "#E8E8E8 " },
		"&:hover:active::after": { opacity: 0.1 },
	},
	accordian: {
		backgroundColor: "red",
	},
});

const drawerWidth = 240;

const openedMixin = (theme) => ({
	width: drawerWidth,
	transition: theme.transitions.create("width", {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.enteringScreen,
	}),
	overflowX: "hidden",
});

const closedMixin = (theme) => ({
	transition: theme.transitions.create("width", {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	overflowX: "hidden",
	width: `calc(${theme.spacing(7)} + 1px)`,
	[theme.breakpoints.up("sm")]: {
		width: `calc(${theme.spacing(8)} + 1px)`,
	},
});

const DrawerHeader = styled("div")(({ theme }) => ({
	display: "flex",
	alignItems: "center",
	justifyContent: "space-between",
	padding: theme.spacing(0, 1),
	// necessary for content to be below app bar
	...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
	shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
	zIndex: theme.zIndex.drawer + 1,
	transition: theme.transitions.create(["width", "margin"], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	...(open && {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(["width", "margin"], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	}),
}));

const Drawer = styled(MuiDrawer, {
	shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
	width: drawerWidth,
	flexShrink: 0,
	whiteSpace: "nowrap",
	boxSizing: "border-box",
	...(open && {
		...openedMixin(theme),
		"& .MuiDrawer-paper": openedMixin(theme),
	}),
	...(!open && {
		...closedMixin(theme),
		"& .MuiDrawer-paper": closedMixin(theme),
	}),
}));

const Main = styled("main", {
	shouldForwardProp(propName) {
		return propName !== "open";
	},
})(({ theme, open }) => {
	return {
		minHeight: `calc(100vh - ${theme.mixins.toolbar.minHeight}px)`,
		width: `calc(100% - ${open ? drawerWidth : 65}px)`,
		padding: theme.spacing(2, 3),
	};
});

export default function Layout({ children, ...props }) {
	const navigate = useNavigate();
	const classes = useStyles();
	let location = useLocation();

	let pathname = location.pathname;

	const theme = useTheme();
	const [open, setOpen] = React.useState(true);
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [openedMenu, setOpenedMenu] = React.useState("");
	const [expanded, setExpanded] = React.useState(true);
	const [koreanName, setKoreanName] = React.useState("");
	const [balance, setBalance] = React.useState(null);
	const logout = useLogout();
	const { userData } = useUtils();
	const { MenuLinks } = useUtils();
	//
	const [isSidebarOpened, setIsSidebarOpened] = React.useState(false);

	const fetchUser = async () => {
		const Query = gql(`query User{
      User{
        company_name_kr
        balance
      }
    }`);

		try {
			const {
				data: {
					User: { company_name_kr, balance },
				},
			} = await APIClient.query({
				query: Query,
			});

			setKoreanName(company_name_kr);
			setBalance(balance);
		} catch (e) {
			console.log(e);
		}
	};

	useEffect(() => {
		fetchUser();
	}, []);

	const handleMenu = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const userDetails = useMemo(() => {
		return userData;
	}, [userData]);

	const handleClose = async () => {
		setAnchorEl(null);
	};

	const handleDrawerOpen = () => {
		setOpen(true);
	};

	const handleDrawerClose = () => {
		setOpen(false);
		setOpenedMenu(false);
	};

	const handleTempChange = (type) => {
		if (type) {
			setIsSidebarOpened(type);
		} else {
			setIsSidebarOpened(type);
			setOpenedMenu(false);
		}
	};

	const commify = (number) => {
		let result = "";
		for (var i = 0; i < number.length; i++) {
			result += number.charAt(i);
			if ((i + 1) % 3 === 0 && number.charAt(i + 1) !== undefined) {
				result += ",";
			}
		}
		return result;
	};

	const checkIsActive = (link) => {
		if (link.children) {
			return link.children.some(
				(subLink) => pathname.toLowerCase() === subLink.path.toLowerCase()
			);
		} else {
			return pathname.toLowerCase() === link.path.toLowerCase();
		}
	};

	const topBarTitle = useTopBarTitle();

	if (!userData) {
		navigate("/login");
	}

	if (!userData) return null;

	return (
		<LayoutBox
			{...props}
			sx={{
				backgroundColor: "#fff",
				padding: 0,
				minWidth: "100%",
				overflow: "hidden",
			}}
			appBar={() => ""}
			sidebar={() => ""}
		>
			<Box
				width={`calc(100% - ${theme.spacing(-1)})`}
				sx={{ display: "flex", background: "#fff" }}
			>
				<CssBaseline />
				<AppBar
					elevation={0}
					style={{ background: "#fff" }}
					position="fixed"
					open={open || isSidebarOpened}
				>
					<Toolbar style={{ color: "#000" }}>
						<IconButton
							color="inherit"
							aria-label="open drawer"
							onClick={handleDrawerOpen}
							edge="start"
							sx={{
								marginRight: 5,
								...((open || isSidebarOpened) && { display: "none" }),
							}}
						>
							<MenuIcon />
						</IconButton>

						<Typography
							sx={{ flexGrow: 1, fontWeight: "bold" }}
							variant="h6"
							noWrap
							component="div"
						>
							{topBarTitle}
						</Typography>
					</Toolbar>
				</AppBar>
				<Drawer
					className="sidebar-drawer"
					elevation={0}
					variant="permanent"
					open={isSidebarOpened || open}
				>
					<DrawerHeader>
						<Link sx={{ textDecoration: "none", color: "black" }} href="/">
							<Typography
								sx={{ flexGrow: 1, padding: "0 20px" }}
								variant="h6"
								noWrap
								component="div"
							>
								SHIP<b>TER.</b>
							</Typography>
						</Link>

						<IconButton onClick={handleDrawerClose}>
							{theme.direction === "rtl" ? (
								<ChevronRightIcon />
							) : (
								<ChevronLeftIcon />
							)}
						</IconButton>
					</DrawerHeader>

					<div>
						<Accordion
							style={{
								margin: "auto",
								marginBottom: "10px",
								display: !open && "none",
							}}
							sx={{ backgroundColor: "#EDF2F7", width: "80%" }}
							expanded={expanded}
							onChange={() => setExpanded(!expanded)}
						>
							<AccordionSummary
								expandIcon={<ExpandMoreIcon />}
								aria-controls="content"
								id="header"
							>
								<Typography sx={{ fontWeight: "bold" }}>
									{koreanName}
								</Typography>
							</AccordionSummary>
							<AccordionDetails sx={{ pt: 0.5, m: "auto" }}>
								<Typography sx={{ mb: 0.5, fontSize: "12px" }}>
									{userData?.username}
								</Typography>
								<Divider />
								{userDetails.role != "admin" && (
									<Grid
										container
										sx={{ my: 0.5 }}
										style={{ justifyContent: "space-between" }}
									>
										<Typography sx={{ fontSize: "12px" }}>예치금</Typography>
										<Typography sx={{ fontSize: "12px" }}>
											{/* {commify(userDetails?.balance?.toString())} */}
											{balance?.toLocaleString("en-US")}원
										</Typography>
									</Grid>
								)}
								<Divider />
								<Typography
									sx={{ mt: 0.5, cursor: "pointer", fontSize: "12px" }}
									onClick={async () => await logout()}
								>
									로그아웃
								</Typography>
							</AccordionDetails>
						</Accordion>
					</div>
					<List
						{...(!open && {
							onMouseOver: () => handleTempChange(true),
							onMouseLeave: () => handleTempChange(false),
						})}
						style={{ paddingTop: "0px" }}
					>
						{MenuLinks.map((link, index) => {
							// console.log({ link });
							return (
								<React.Fragment key={index}>
									<ListItemButton
										component={RouterLink}
										onClick={() => {
											if (openedMenu === link.name) {
												setOpenedMenu(false);
											} else {
												setOpenedMenu(link.name);
											}
										}}
										to={link.path}
										target={`${link.children ? "" : "_blank"}`}
										sx={{
											minHeight: 48,
											justifyContent:
												isSidebarOpened || open ? "initial" : "center",
											px: 2.5,
											color: checkIsActive(link) ? "#000" : "#C1C1C1",
											fontWeight: 600,
											"&:focus::after": { bgcolor: "#E8E8E8 " },
										}}
										className={classes.focusColor}
									>
										<ListItemIcon
											sx={{
												minWidth: 0,
												mr: isSidebarOpened || open ? 3 : "auto",
												justifyContent: "center",
												fill: checkIsActive(link) ? "#5B8FF7" : "#C1C1C1",
												color: checkIsActive(link) ? "#5B8FF7" : "#C1C1C1",
											}}
										>
											{link.icon}
										</ListItemIcon>
										<ListItemText
											primary={link.name}
											sx={{
												opacity: isSidebarOpened || open ? 1 : 0,
												color: checkIsActive(link) ? "#5B8FF7" : "#C1C1C1",
												fontWeight: checkIsActive(link) ? "700" : "400",
											}}
										/>
										{link.children &&
											(open || isSidebarOpened) &&
											(openedMenu === link.name ? (
												<ExpandLess />
											) : (
												<ExpandMore />
											))}
									</ListItemButton>
									<Collapse
										in={openedMenu === link.name}
										timeout="auto"
										unmountOnExit
									>
										<List
											component="div"
											style={{ padding: "0 20px", paddingLeft: "32px" }}
											disablePadding
										>
											{link.children?.map((subLink, i) => (
												<MenuItemLink
													key={i}
													to={subLink.path}
													target="_blank"
													primaryText={subLink.name}
													style={{
														borderRadius: 10,
														paddingLeft: "28px",
														color: checkIsActive(subLink)
															? "#5B8FF7"
															: "#C1C1C1",
													}}
													sx={{ "&:focus::after": { bgcolor: "#E8E8E8 " } }}
													className={classes.focusColor}
												/>
											))}
										</List>
									</Collapse>
								</React.Fragment>
							);
						})}
					</List>
				</Drawer>
				<Main open={isSidebarOpened || open} sx={{ marginTop: -6 }}>
					<DrawerHeader />
					{children}
				</Main>
			</Box>
		</LayoutBox>
	);
}
