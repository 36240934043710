import { useLocation } from "react-router-dom";
import { useState, useEffect } from "react";

export const useTopBarTitle = () => {
	const { pathname } = useLocation();

	const moduleName = pathname
		.split("/")
		.slice(1)
		.reduce((str, each) => (parseInt(each) ? str : str + `/${each}`));
	switch (moduleName) {
		case "notice/show":
			return "공지사항";

		case "notice":
			return "공지사항";

		case "Notice":
			return "공지를 작성하다";

		case "Notice/create":
			return "공지사항 작성";

		case "CompanyList":
			return "업체 리스트";

		case "fee-deduction":
			return "배송비 차감내역";

		case "deposit-history":
			return "예치금 입금 내역";

		case "shipping-summary":
			return "업체별 접수내역";

		case "Transaction":
			return "입출금내역";

		case "shipping-fees":
			return "배송비 차감 내역";

		case "settings-api":
			return "수출신고 API 설정";

		case "settlement-history":
			return "정산내역";

		case "whereabout":
			return "행방조사";

		case "Bag":
			return "BAG 관리";

		case "MasterManagement":
			return "마스터 관리";

		case "depositHistory":
			return "예치금 입금 내역";

		case "shipping-request":
			return "배송 신청 리스트";

		case "shipping-request/list":
			return "배송 신청 리스트";

		case "settings-password":
			return "비밀번호 변경";

		case "delivery-parcels":
			return "해외배송 소포 수령증";

		case "shipping-suspend":
			return "배송 보류";

		case "suspend-shipping":
			return "배송 보류";

		case "shipping-processing":
			return "배송 처리";

		case "general-rates":
			return "일반 요율";

		case "rates-by-company":
			return "업체그룹별 요율";

		case "waybill-list":
			return "운송장 리스트";

		case "upload-waybill":
			return "운송장 업로드";

		case "ShippingTypes":
			return "배송 타입 관리";

		case "ShippingCountries":
			return "배송 국가 관리";

		case "request-shipping":
			return "배송 신청 리스트";

		case "master-bag-list":
			return "마스터 BAG 리스트";

		case "reception-list-master":
			return "마스터 BAG에 담긴 배송 접수 리스트";

		case "reception-list":
			return "BAG에 담긴 배송 접수 리스트";

		case "RequestShipping":
			return "배송 신청";

		case "RequestShippingBulk":
			return "배송 대량 신청";

		case "stats":
			return "통계";

		case "settings-branchinfo":
			return "지점 정보";

		case "settings-customerinfo":
			return "고객사 정보";
		case "export-declaration":
			return "수출신고 게시판";

		case "settings-shippingrate":
			return "배송 요율표";

		case "StatsByCountries":
			return "통계";

		case "request-shipping-details":
			return "업체 배송신청 리스트";

		case "relabeling":
			return "리라벨링";

		default:
			return "대쉬보드";
	}
};

export const useWindowSize = () => {
	// Initialize state with undefined width/height so server and client renders match
	// Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
	const [windowSize, setWindowSize] = useState({
		width: undefined,
		height: undefined,
	});
	useEffect(() => {
		// Handler to call on window resize
		function handleResize() {
			// Set window width/height to state
			setWindowSize({
				width: window.innerWidth,
				height: window.innerHeight,
			});
		}
		// Add event listener
		window.addEventListener("resize", handleResize);
		// Call handler right away so state gets updated with initial window size
		handleResize();
		// Remove event listener on cleanup
		return () => window.removeEventListener("resize", handleResize);
	}, []); // Empty array ensures that effect is only run on mount
	return windowSize;
};
