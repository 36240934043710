/**
 *  Return equivalent korean word for given data
 */
const statusSwitch = (data) => {
  let returnRes = "";
  switch (data) {
    case "waiting_warehousing":
      returnRes = "입고대기";
      break;

    case "warehousing_completed":
      returnRes = "입고완료";
      break;

    case "waiting_delivery":
      returnRes = "출고대기";
      break;

    case "started_delivery":
      returnRes = "출고완료";
      break;

    case "completed_delivery":
      returnRes = "배송완료";
      break;

    default:
      returnRes = "NA";
      break;
  }
  return returnRes;
};

export default statusSwitch;
