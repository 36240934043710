import { Navigate, useLocation } from "react-router-dom";
import { usePermissions } from "react-admin";
import { permissionsMap } from "../utils/PermissionsMap";

export default function CatchAll() {
  const { permissions } = usePermissions();
  const { pathname } = useLocation();

  const find = permissionsMap.find((res) => res.role === permissions);

  if (find) {
    const { urls } = find;
    if (
      urls.findIndex((res) => res.toLowerCase() === pathname.toLowerCase()) <=
      -1
    ) {
      return <Navigate to={"/"} />;
    }
  }
}
