import React from "react";

const ExclamationIcon = () => {
	return (
		<svg
			width="71"
			height="71"
			viewBox="0 0 71 71"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<circle opacity="0.1" cx="35.5" cy="35.5" r="35.5" fill="#F2994A" />
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M18.1143 35.863C18.1143 26.0668 26.0663 18.113 35.8643 18.113C45.68 18.113 53.6143 26.0668 53.6143 35.863C53.6143 45.6628 45.68 53.613 35.8643 53.613C26.0663 53.613 18.1143 45.6628 18.1143 35.863ZM34.3027 29.1358C34.3027 28.2856 35.0127 27.5738 35.8647 27.5738C36.7167 27.5738 37.4089 28.2856 37.4089 29.1358V36.9813C37.4089 37.8351 36.7167 38.5255 35.8647 38.5255C35.0127 38.5255 34.3027 37.8351 34.3027 36.9813V29.1358ZM35.882 44.1718C35.0122 44.1718 34.32 43.4618 34.32 42.6098C34.32 41.7578 35.0122 41.0656 35.8642 41.0656C36.734 41.0656 37.4262 41.7578 37.4262 42.6098C37.4262 43.4618 36.734 44.1718 35.882 44.1718Z"
				fill="#F2994A"
			/>
		</svg>
	);
};
export default ExclamationIcon;
