import { Divider, MenuItem, Stack } from "@mui/material";
import readXlsxFile from "read-excel-file";
import CustomButton from "../../../components/CustomButton";
import CustomInput, { SelectBox } from "../../../components/CustomInput";
import FileUpload from "../../../components/FileUpload";
import ListHOC from "../../../SharedComponents/ListHOC";
import ShowHOC from "../../../SharedComponents/ShowHOC";
import { useGetList, useCreate } from "react-admin";
import { useState, useEffect } from "react";
import { CREATE_WAY_BILL } from "../../../utils/constants";
import { gql } from "@apollo/client/core";
import { APIClient } from "../../../utils/apiGraphql";
import Toaster from "../../../utils/Toaster";
import ExcelDownload from "../../../Common/download/운송장 업로드 양식.xlsx";

export function UploadWaybill() {
	const [openModal, setOpenModal] = useState(true);
	const [deliveryType, setDeliveryType] = useState("");
	const [shippingType, setShippingType] = useState("");
	const [shippingList, setShippingList] = useState([]);
	const [file, setFile] = useState(null);
	const [wayBillData, setWayBillData] = useState([
		{ trackingNumber: "", deliveryTypeId: 0 },
	]);

	const [create] = useCreate();
	// const [createWaybill, res] = useMutation(CREATE_WAY_BILL);

	const handleClose = () => {
		setOpenModal(false);
	};

	const { data, total, isLoading, error } = useGetList("ShippingTypes", {
		sort: { field: "published_at", order: "DESC" },
		pagination: { page: 1, perPage: {} },
	});

	useEffect(() => {
		if (deliveryType) {
			setShippingList(data.filter((item) => item.id === deliveryType));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [deliveryType]);

	//reading from excel file && making way bill data
	useEffect(() => {
    let arr = [];
    if (file) {
      readXlsxFile(file).then((rows, index) => {
        rows.forEach((item, index) => {
          if (index !== 0) {
            const indexOf = arr.findIndex((res) => res.trackingNumber === item[1]);
            if (indexOf < 0) {
              arr.push({
                trackingNumber: item[1],
                deliveryTypeId: shippingType,
              });
            }
          }
        });
      });
      setWayBillData(arr);
    }
  }, [file]);

  // console.log("waybillData", wayBillData);

  const addWayBill = async () => {
    // const data1 = { trackingNumber: "1234test", deliveryTypeId: 1 };
    // console.log("data", wayBillData);

    if (wayBillData.length === 1) {
      ///single bill entry
      const createNewWayBill = gql(`mutation
  createWaybill(
		$createInput: WaybillCreateInput!
  ){
    createWaybill(createInput: $createInput){
    	createStatus
  }
}
	`);

      try {
        const res = await APIClient.mutate({
          mutation: createNewWayBill,
          variables: {
            createInput: {
              ...wayBillData[0],
            },
          },
        });
        Toaster("success", "운송장번호가 등록되었습니다.");
        reset();
      } catch (e) {
        Toaster("error", e.message);
      }
    } else {
      ///multiple bill entry
      const createNewWayBillMany = gql(`mutation
  createWaybillMany(
		$createInput: WaybillCreateManyInput!
  ){
    createWaybillMany(createInput: $createInput){
    	createStatus
  }
}
	`);

      try {
        const res = await APIClient.mutate({
          mutation: createNewWayBillMany,
          variables: {
            createInput: {
              wayBills: wayBillData.map((item) => item),
            },
          },
        });
        Toaster("success", "운송장번호가 등록되었습니다.");
        reset();
      } catch (e) {
        Toaster("error", e.message);
      }
    }
  };

	function reset() {
		setTimeout(() => {
			window.location.href = "/waybill-list";
		}, 2000);
	}
	return (
		<Stack spacing="40px">
			<div
				style={{
					borderRadius: "10px",
					overflow: "hidden",
					border: "1px solid #E5E5E5",
				}}
			>
				<CustomInput
					input={
						<SelectBox
							defaultValue="선택"
							// value={deliveryType}
							styles={{ width: 316, marginLeft: 10 }}
							onChange={(e) => {
								setDeliveryType(e.target.value);
							}}
						>
							<MenuItem value="선택" disabled hidden>
								선택
							</MenuItem>
							{data &&
								data.map((item, index) => (
									<MenuItem value={item.id} key={index}>
										{item.deliveryType}
									</MenuItem>
								))}
						</SelectBox>
					}
					label="배송 타입"
					labelWidth="136px"
				/>

				<Divider />

				<CustomInput
					input={
						<SelectBox
							defaultValue="선택"
							// value={shippingType}
							styles={{ width: 316, marginLeft: 10 }}
							onChange={(e) => setShippingType(e.target.value)}
						>
							<MenuItem value="선택" disabled hidden>
								선택
							</MenuItem>
							{shippingList &&
								shippingList[0]?.details?.map((item, index) => (
									<MenuItem value={item.id} key={index}>
										{item.shippingType}
									</MenuItem>
								))}
						</SelectBox>
					}
					label="배송 서비스"
					labelWidth="136px"
				/>

				<Divider />

				<CustomInput
					input={
						<FileUpload
							// setFile={setFile}
							file={file}
							disabled={!shippingType || !deliveryType}
							onChange={setFile}
						/>
					}
					label="엑셀 업로드"
					labelWidth="136px"
				/>
			</div>

			<div style={{ display: "flex", justifyContent: "flex-end", gap: "20px" }}>
				<a
					href={ExcelDownload}
					download="운송장 업로드 양식.xlsx"
					style={{ textDecoration: "none" }}
				>
					<CustomButton
						width="100px"
						styles={{
							backgroundColor: "white",
							color: "rgba(0, 0, 0, 0.6)",
							whiteSpace: "nowrap",
							border: "1px solid #E5E5E5",
							width: "170px",
							padding: "10px",
						}}
					>
						<img
							src="./assets/images/excel.png"
							alt="img"
							style={{ marginRight: "5px" }}
						/>
						엑셀 양식 다운로드
					</CustomButton>
				</a>
				<CustomButton
					width="100px"
					style={{ padding: "5px 10px", backgroundColor: "#5B8FF7" }}
					disabled={!file || !deliveryType || !shippingType}
					onClick={addWayBill}
				>
					운송장 등록
				</CustomButton>
			</div>
		</Stack>
	);
}
