import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer as TableContainerBox,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Loader from "./Loader";

export default function TableContainerNew({
  isLoading,
  requestCompleted,
  data = [
    [1, 1, 1],
    [1, 1, 1],
  ],
  headers = ["Hello", "World", "Welcome", "Here"],
  title = null,
}) {
  return !isLoading && requestCompleted ? (
    <TableContainerBox
      style={{
        
        borderRadius: 10,
        overflow: "hidden",
        border: "1px solid #E5E5E5",
        overflowX: "auto",
      }}
    >
      {title && (
        <div
          style={{
            textAlign: "center",
            padding: 10,
            backgroundColor: "#F8F8FA",
            borderBottom: "1px solid #E5E5E5",
          }}
        >
          <Typography fontSize={"12px"} fontWeight={"700"}>
            {title}
          </Typography>
        </div>
      )}
      <Table>
        <TableHead
          style={{
            backgroundColor: "#F8F8FA",
            borderBottom: "1px solid #E5E5E5",
          }}
        >
          <TableRow>
            {headers.map((item, index) => (
              <TableCell
                align= {index!=1 ? "start" : "center"}
                style={{
                  width: index == 0 ? "15%" : index == 1 ? "10%" : "75%",
                  whiteSpace: "nowrap",
                  borderRight:
                    index + 1 !== headers.length ? "1px solid #E5E5E5" : "none",
                }}
                key={"header" + index}
                
              >
                {item}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => (
            <TableRow key={"row" + index}>
              {row.map((item, index) => (
                <TableCell
                size="small"
                  key={"cell" + index}
                  align= {index!=1 ? "start" : "center"}
                  style={{
                    borderRight:
                      index + 1 !== row.length ? "1px solid #E5E5E5" : "none",
                  }}
                >
                  {item}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainerBox>
  ) : (
    <Loader />
  );
}
