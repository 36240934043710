import {
  Box,
  Divider,
  FormControl,
  MenuItem,
  Modal,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import CustomButton from "../CustomButton";
import CustomCard from "../CustomCard";
import { TextFieldBox } from "../CustomInput";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import CreateHOC from "../../SharedComponents/CreateHOC";
import UpdateHOC from "../../SharedComponents/UpdateHOC";
import { useDataProvider } from "react-admin";
import { styled } from "@mui/styles";
import { makeStyles } from "@mui/styles";
import FormHelperText from "@mui/material/FormHelperText";
import { useApolloClient } from "@apollo/client";
import { ALL_BAG } from "./queries";

const MyMenuItem = styled(MenuItem)({
  "&:focus::after": {
    opacity: 0,
  },
});

const useStyles = makeStyles({
  formHelper: { color: "#d32f2f !important" },
});

function BagManagementModal({ selected, onClose, ...props }) {
  return React.createElement(selected ? UpdateHOC : CreateHOC, {
    ...props,
    selected,
    handleClose: onClose,
    children: BagManagementModalContainer,
  });
}

export default React.memo(BagManagementModal);

const BagManagementModalContainer = ({
  isOpen,
  handleClose,
  selected,
  onSuccess,
  additionalFormDetails,
  componentFor,
  ...hocProps
}) => {
  const classes = useStyles();
  const [initialValues, setInitialValues] = useState({
    name: "",
    shippingCountry: null,
    deliveryType: null,
    deliveryService: null,
    howToPayCustomDuties: "",
    dgPackagingManagementCode: "",
    weight: 1,
  });

  const [deliveryTypes, setDeliveryTypes] = useState([]);
  const [deliveryServices, setDeliveryServices] = useState([]);

  const dataProvider = useDataProvider();
  const client = useApolloClient();

  React.useEffect(() => {
    if (selected) {
      const {
        id,
        "shippingCountry.id": shippingCountry,
        "deliveryService.id": deliveryService,
        "deliveryType.id": deliveryType,
        name,
        howToPayCustomDuties,
        dgPackagingManagementCode,
        weight,
      } = selected;
      setInitialValues({
        id,
        shippingCountry,
        deliveryService,
        // master,
        deliveryType,
        name,
        howToPayCustomDuties,
        dgPackagingManagementCode,
        weight,
      });
      getDetails(shippingCountry, "ShippingCountries", "update");
    }
  }, [selected]);

  const validationSchema = () => {
    return Yup.object().shape({
      name: Yup.string(),
      shippingCountry: Yup.number().required("country is required"),
      deliveryType: Yup.number().required("type is required"),
      deliveryService: Yup.number().required("service is required"),
      // master:
      // 	componentFor !== "shipping-processing"
      // 		? Yup.number().required("master is required")
      // 		: Yup.number().nullable(true),
      howToPayCustomDuties: Yup.string(),
      dgPackagingManagementCode: Yup.string(),
      weight: Yup.number().min(1),
    });
  };

  const handleSubmit = async (values, formikUtils) => {
    let input = {};
    if (selected) {
      input = {
        updateInput: {
          ...values,
        },
      };
    } else {
      input = {
        createInput: {
          ...values,
        },
      };
    }
    const res = await hocProps.handleSubmit(
      { ...input },
      componentFor !== "shipping-processing" ? formikUtils : undefined,
      componentFor !== "shipping-processing"
    );
    if (res) {
      const { data, reason } = res;
      await client.refetchQueries({ include: [ALL_BAG] });

      onSuccess(data, reason);
    }
  };

  const getDetails = async (id, provider, type) => {
    return new Promise((resolve, reject) => {
      dataProvider
        .getOne(provider, { id })
        .then(({ data }) => {
          setDeliveryTypes(() => {
            return [data.deliveryType];
          });
          if (type === "update") {
            const { details } = data.deliveryType;
            if (details) {
              setDeliveryServices(details);
            }
          }
          resolve(data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  };
  return (
    <>
      <Modal
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ overflow: "scroll" }}
      >
        <Box
          style={{
            padding: 20,
            overflowX: "hidden",
            minHeight: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            enableReinitialize
            validationSchema={validationSchema}
          >
            {({
              errors,
              handleChange,
              touched,
              handleSubmit,
              values,
              submitCount,
              isSubmitting,
              isValid,
            }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <CustomCard>
                    <Stack spacing={"20px"} width={500} p="20px">
                      <Typography fontSize={"20px"} fontWeight="700">
                        BAG 생성
                      </Typography>
                      <Divider />
                      <Box
                        my={2}
                        display={"flex"}
                        flexDirection={"column"}
                        gap={1.5}
                      >
                        <Box
                          display={"flex"}
                          flexDirection={"column"}
                          gap={1.5}
                        >
                          <label>
                            <Typography variant={"body2"} fontWeight={"bold"}>
                              배송 국가*
                            </Typography>
                          </label>
                          <FormControl
                            size="small"
                            sx={{
                              width: "100%",
                              bgcolor: "#fff",
                              m: 0,
                            }}
                            error={
                              submitCount > 0 &&
                              !isSubmitting &&
                              !isValid &&
                              !!errors.shippingCountry
                            }
                            variant="outlined"
                          >
                            <Select
                              sx={{ borderRadius: 8 }}
                              name={"shippingCountry"}
                              value={values.shippingCountry}
                              MenuProps={{
                                sx: {
                                  "&& .Mui-selected": {
                                    backgroundColor: "#E0E0E0",
                                  },
                                },
                              }}
                              onChange={async (e) => {
                                await getDetails(
                                  e.target.value,
                                  "ShippingCountries"
                                );
                                handleChange(e);
                              }}
                              style={{
                                borderRadius: "5px",
                                boxShadow: "0px 0px 5px rgba(31, 65, 115, 0.1)",
                                border: "1px solid #EBEBEB",
                                width: "100%",

                                "& .MuiInputBase-input": {
                                  borderRadius: 4,
                                  position: "relative",
                                  backgroundColor: "yellow",
                                },
                              }}
                            >
                              {additionalFormDetails.ShippingCountries.map(
                                (item, index) => (
                                  <MyMenuItem value={item.id} key={index}>
                                    {item.name}
                                  </MyMenuItem>
                                )
                              )}
                            </Select>
                            <FormHelperText
                              className={classes.formHelper}
                              sx={{ ml: "0px" }}
                            >
                              {errors.shippingCountry &&
                                touched.shippingCountry &&
                                errors.shippingCountry}
                            </FormHelperText>
                          </FormControl>
                        </Box>

                        <Box
                          display={"flex"}
                          flexDirection={"column"}
                          gap={1.5}
                        >
                          <label>
                            <Typography variant={"body2"} fontWeight={"bold"}>
                              배송 타입*
                            </Typography>
                          </label>
                          <FormControl
                            size="small"
                            sx={{
                              width: "100%",
                              m: 0,
                            }}
                            error={
                              submitCount > 0 &&
                              !isSubmitting &&
                              !isValid &&
                              !!errors.deliveryType
                            }
                            helperText={
                              !!errors.deliveryType &&
                              touched.deliveryType &&
                              errors.deliveryType
                            }
                            variant="outlined"
                          >
                            <Select
                              sx={{ borderRadius: 8 }}
                              name={"deliveryType"}
                              value={values.deliveryType}
                              onChange={(e) => {
                                const { value } = e.target;
                                const find = deliveryTypes.find(
                                  (res) => res.id === value
                                );
                                if (find) {
                                  setDeliveryServices(find.details);
                                  handleChange(e);
                                }
                              }}
                              style={{
                                borderRadius: "5px",
                                boxShadow: "0px 0px 5px rgba(31, 65, 115, 0.1)",
                                border: "1px solid #EBEBEB",
                                width: "100%",
                              }}
                            >
                              {deliveryTypes.map((item, index) => (
                                <MenuItem value={item.id} key={index}>
                                  {item.deliveryType}
                                </MenuItem>
                              ))}
                            </Select>
                            <FormHelperText
                              className={classes.formHelper}
                              sx={{ ml: "0px" }}
                            >
                              {errors.deliveryType &&
                                touched.deliveryType &&
                                errors.deliveryType}
                            </FormHelperText>
                          </FormControl>
                        </Box>

                        <Box
                          display={"flex"}
                          flexDirection={"column"}
                          gap={1.5}
                        >
                          <label>
                            <Typography variant={"body2"} fontWeight={"bold"}>
                              배송 서비스*
                            </Typography>
                          </label>
                          <FormControl
                            size="small"
                            sx={{
                              width: "100%",
                              m: 0,
                            }}
                            error={
                              submitCount > 0 &&
                              !isSubmitting &&
                              !isValid &&
                              !!errors.deliveryService
                            }
                            helperText={
                              !!errors.deliveryService &&
                              touched.deliveryService &&
                              errors.deliveryService
                            }
                            variant="outlined"
                          >
                            <Select
                              sx={{ borderRadius: 8 }}
                              name={"deliveryService"}
                              value={values.deliveryService}
                              onChange={handleChange}
                              style={{
                                borderRadius: "5px",
                                boxShadow: "0px 0px 5px rgba(31, 65, 115, 0.1)",
                                border: "1px solid #EBEBEB",
                                width: "100%",
                              }}
                            >
                              {deliveryServices.map((item, index) => (
                                <MenuItem value={item.id} key={index}>
                                  {item.shippingType}
                                </MenuItem>
                              ))}
                            </Select>
                            <FormHelperText
                              className={classes.formHelper}
                              sx={{ ml: "0px" }}
                            >
                              {errors.deliveryService &&
                                touched.deliveryService &&
                                errors.deliveryService}
                            </FormHelperText>
                          </FormControl>
                        </Box>

                        <Box
                          display={"flex"}
                          flexDirection={"column"}
                          gap={1.5}
                        >
                          <label>
                            <Typography variant={"body2"} fontWeight={"bold"}>
                              관세 지불 방식
                            </Typography>
                          </label>
                          <TextFieldBox
                            sx={{ borderRadius: 8 }}
                            name={"howToPayCustomDuties"}
                            value={values.howToPayCustomDuties}
                            onChange={handleChange}
                            error={
                              submitCount > 0 &&
                              !isSubmitting &&
                              !isValid &&
                              !!errors.howToPayCustomDuties
                            }
                            helperText={
                              !!errors.howToPayCustomDuties &&
                              touched.howToPayCustomDuties &&
                              errors.howToPayCustomDuties
                            }
                            variant="outlined"
                          />
                        </Box>

                        <Box
                          display={"flex"}
                          flexDirection={"column"}
                          gap={1.5}
                        >
                          <label>
                            <Typography variant={"body2"} fontWeight={"bold"}>
                              DG 포장 관리코드
                            </Typography>
                          </label>
                          <TextFieldBox
                            sx={{ borderRadius: 8 }}
                            name={"dgPackagingManagementCode"}
                            value={values.dgPackagingManagementCode}
                            onChange={handleChange}
                            error={
                              submitCount > 0 &&
                              !isSubmitting &&
                              !isValid &&
                              !!errors.dgPackagingManagementCode
                            }
                            helperText={
                              !!errors.dgPackagingManagementCode &&
                              touched.dgPackagingManagementCode &&
                              errors.dgPackagingManagementCode
                            }
                          />
                        </Box>

                        <Box
                          display={"flex"}
                          flexDirection={"column"}
                          gap={1.5}
                        >
                          <label>
                            <Typography variant={"body2"} fontWeight={"bold"}>
                              무게*
                            </Typography>
                          </label>
                          <TextFieldBox
                            sx={{ borderRadius: 8 }}
                            name={"weight"}
                            type={"number"}
                            placeholder={"Weight"}
                            value={values.weight}
                            onChange={handleChange}
                            error={
                              submitCount > 0 &&
                              !isSubmitting &&
                              !isValid &&
                              !!errors.weight
                            }
                            helperText={
                              !!errors.weight && touched.weight && errors.weight
                            }
                          />
                        </Box>
                      </Box>

                      <Divider />

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          gap: "20px",
                        }}
                      >
                        <CustomButton
                          onClick={handleClose}
                          styles={{
                            backgroundColor: "#F8F8FA",
                            color: "#000",
                            width: "auto",
                          }}
                        >
                          닫기
                        </CustomButton>
                        <CustomButton
                          styles={{
                            width: "auto",
                          }}
                          type={"submit"}
                        >
                          생성
                        </CustomButton>
                      </div>
                    </Stack>
                  </CustomCard>
                </Form>
              );
            }}
          </Formik>
        </Box>
      </Modal>
    </>
  );
};
