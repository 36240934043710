import { Box, Divider, Grid, Link, Stack, Typography } from "@mui/material";
import * as React from "react";
import { useEffect, useState } from "react";

import { APIClient } from "../../utils/apiGraphql";
import { gql } from "@apollo/client/core";
import Toaster from "../../utils/Toaster";
import CustomInput from "../../components/CustomInput";
import FileUpload from "../../components/FileUpload";
import TableContainer from "../../components/TableContainer";
import Moment from "moment";
import CustomButton from "../../components/CustomButton";
import { Button } from "@mui/material";
import ListHOC from "../../SharedComponents/ListHOC";
import ShowHOC from "../../SharedComponents/ShowHOC";
import { useRedirect, CreateButton } from "react-admin";
import { useUtils, getRemoteFileURL, downloadFile } from "../../utils/utils";
import LinkIcon from "@mui/icons-material/Link";
import { makeStyles } from "@mui/styles";
import CustomSelect from "../../components/CustomSelect";

const useStyles = makeStyles({
  createButon: { padding: "12px !important", fontSize: "16px !important" },
  headerBox: { display: "flex", justifyContent: "end" },
});

export const NoticeList = React.memo((props) => {
  const classes = useStyles();
  const { role } = useUtils();
  const redirect = useRedirect();
  const [notices, setNotices] = useState([]);

  const getNotices = async () => {
    const Query = gql(`
		query{
			allNotices{
				id
				title
				description
			}
		}
      `);

    try {
      const {
        data: { allNotices: notices },
      } = await APIClient.query({
        query: Query,
      });
      setNotices(notices);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getNotices();
  }, []);

  return (
    <ListHOC
      {...props}
      width="1150px"
      render={({
        perPage,
        page,
        handlePageChange,
        data: tableData,
        handleSetPerPage,
        ...hocProps
      }) => {
        return (
          <>
            <Box
              textAlign={"right"}
              className={classes.headerBox}
              maxWidth="1150px"
            >
              {role === "admin" && (
                <CreateButton
                  className={classes.createButon}
                  label={"공지사항 등록"}
                  {...hocProps}
                />
              )}
              <Grid item xs={4}>
                <CustomSelect
                  value={String(perPage)}
                  label=""
                  onChange={({ target }) => {
                    handleSetPerPage(target.value);
                  }}
                  options={["10", "20", "30", "40", "50", "100"]}
                ></CustomSelect>
              </Grid>
            </Box>
            <Box maxWidth="1150px">
              <TableContainer
                {...hocProps}
                titlesShouldBeBold
                headers={["No.", "제목", "날짜"]}
                data={tableData
                  .sort((a, b) => a.id - b.id)
                  .reverse()
                  .map((item, index) => [
                    tableData.length - index,
                    <Link
                      className={"limit-text"}
                      sx={{ color: "black" }}
                      key={index}
                      component={"button"}
                      style={{ textDecoration: "none", fontSize: "12px" }}
                      onClick={() => redirect(`/notice/${item.id}/show`)}
                    >
                      {item?.title}
                    </Link>,
                    Moment(item.date).format("YYYY.MM.DD"),
                  ])}
              />
            </Box>
          </>
        );
      }}
    ></ListHOC>
  );
});

export const NoticeAdd = () => {
  return (
    <Stack spacing={"50px"}>
      <div
        style={{
          borderRadius: "10px",
          overflow: "hidden",
          border: "1px solid #E5E5E5",
        }}
      >
        <CustomInput placeholder="제목을 입력하세요." label="제목" />

        <Divider />

        <CustomInput
          placeholder="내용을 입력하세요."
          label="내용"
          multi
          height="270px"
        />

        <Divider />

        <CustomInput
          label="첨부파일"
          input={<FileUpload label="파일 선택" />}
        />
      </div>

      <div style={{ display: "flex", justifyContent: "flex-end", gap: "20px" }}>
        <CustomButton bg="#F8F8FA" color="#000" width="100px">
          취소
        </CustomButton>
        <CustomButton width="100px">취소</CustomButton>
      </div>
    </Stack>
  );
};

const handleDownload = (data) => {
  downloadFile(data);
};

export const NoticeView = (props) => {
  const redirect = useRedirect();
  const handleClick = () => {
    redirect("/notice");
  };

  const { role } = useUtils();

  const handleNavigation = (id) => {
    redirect("/Notice/create?id=" + id);
  };

  const deleteNotice = async (id) => {
    const Mutation = gql(`
      mutation deleteNotice($id: Float!) {
        deleteNotice(id: $id) {
          id
        }
      }
    `);

    try {
      // eslint-disable-next-line no-unused-vars
      const { data } = await APIClient.mutate({
        mutation: Mutation,
        variables: { id },
      });
      Toaster("success", "해당 공지사항이 삭제되었습니다.");
      redirect(-1);
    } catch (e) {
      Toaster("error", e.message);
    }
  };

  return (
    <ShowHOC {...props}>
      {({ data, ...hocProps }) => {
        // console.log({ data });
        let attach;
        try {
          attach = JSON.parse(data.attachment);
        } catch {
          const dataNew = [
            {
              url: data.attachment,
              name: "Random",
            },
          ];
          attach = dataNew;
        }
        // console.log({ attach });
        return (
          <Stack spacing={2.5} maxWidth="1150px">
            <div>
              <Typography
                fontWeight={"700"}
                maxWidth={"75ch"}
                overflow={"hidden"}
                sx={{ wordBreak: "break-word", hyphens: "auto" }}
              >
                {data.title}
              </Typography>
              <Typography fontSize={"11px"} color="#C1C1C1">
                {Moment(data.date).format("DD-MM-YYYY HH:mm")}
              </Typography>
            </div>

            <Divider />

            <Grid container style={{ justifyContent: "space-between" }}>
              <Grid item xs={6}>
                <Typography
                  maxWidth={"75ch"}
                  overflow={"hidden"}
                  sx={{
                    wordBreak: "break-word",
                    hyphens: "auto",
                  }}
                >
                  {data.description}
                </Typography>
              </Grid>

              <Grid
                display={"flex"}
                // justifyContent={"flex-end"}
                style={{
                  flexDirection: "column",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                }}
                item
                xs={6}
              >
                {attach.map((item, index) => {
                  return (
                    <Button
                      style={{ color: "#5B8FF7" }}
                      startIcon={<LinkIcon />}
                      onClick={() => handleDownload(item)}
                    >
                      {data?.attachment
                        ? `다운로드 (${item?.name})`
                        : "다운로드"}
                    </Button>
                  );
                })}
              </Grid>
            </Grid>

            <Divider />

            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                gap: "20px",
              }}
            >
              <CustomButton
                onClick={handleClick}
                styles={{
                  backgroundColor: "#F8F8FA",
                  color: "#000",
                  width: "auto",
                  padding: "12px",
                }}
              >
                목록으로 돌아가기
              </CustomButton>
              {role === "admin" && (
                <CustomButton
                  onClick={() => handleNavigation(data.id)}
                  styles={{ width: "auto" }}
                >
                  수정
                </CustomButton>
              )}
              {role === "admin" && (
                <CustomButton
                  styles={{
                    width: "auto",
                    padding: "10px",
                    backgroundColor: "#D5495A",
                  }}
                  onClick={() => deleteNotice(data.id)}
                >
                  삭제
                </CustomButton>
              )}
            </div>
          </Stack>
        );
      }}
    </ShowHOC>
  );
};
