import * as React from "react";
import Box from "@mui/material/Box";
import CustomButton from "../../../components/CustomButton";
import Typography from "@mui/material/Typography";
import { Grid, Divider } from "@mui/material";
import Modal from "@mui/material/Modal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 420,
  bgcolor: "background.paper",
  boxShadow: "0px 0px 5px rgba(31, 65, 115, 0.1)",
  borderRadius: "10px",
  textAlign: "center",
  p: 4,
};

export default function Modals({ open, handleClose, data }) {
  const saveGroup = () => {};

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            sx={{ mt: 2 }}
            style={{
              textAlign: "center",
              fontWeight: "bold",
              fontSize: "20px",
            }}
          >
              {`입력하신 접수건(${data})은 확인할 수 없는 운송장번호입니다.`}
            {/*{data !== null*/}
            {/*  ? `입력하신 접수건 (${data}) 의 리라벨링을 진행하시겠습니까?`*/}
            {/*  : "입력하신 접수건(H3000000KR)은 확인할 수 없는 운송장번호입니다."}*/}
          </Typography>
          <Typography
            sx={{ mt: 2 }}
            style={{
              textAlign: "center",
              fontWeight: "bold",
              fontSize: "20px",
            }}
          >
              (Not exists)
            {/*{data !== null*/}
            {/*  ? "출력 버튼을 누르시면, 새로운 라벨이 출력됩니다."*/}
            {/*  : "(Not exists)"}*/}
          </Typography>
          <Divider sx={{ mt: 8 }} />
          <Grid container sx={{ justifyContent: "right" }}>
            <CustomButton
              width="100px"
              onClick={handleClose}
              style={{
                marginTop: "30px",
                borderRadius: "10px",
                backgroundColor: "#F8F8FA",
                color: "#000000",
              }}
            >
              취소
            </CustomButton>
            <CustomButton
              width="100px"
              style={{
                marginTop: "30px",
                borderRadius: "10px",
                marginLeft: "10px",
                backgroundColor: "#5B8FF7",
              }}
              onClick={saveGroup}
            >
              출력
            </CustomButton>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}
