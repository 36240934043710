import { gql } from "@apollo/client/core";

export const graphqlUrl = process.env.REACT_APP_API_ENDPOINT;
export const wsUrl = process.env.REACT_APP_WS_ENDPOINT;
export const REMOTE_FILES_BASE_URL = process.env.REACT_APP_REMOTE_FILES_URL;

export const CREDENTIALS_LOCAL_STORAGE_ITEM = "credentials";
export const USER_DATA_LOCAL_STORAGE_ITEM = "userData";

export const MasterProcessReleaseMutation = gql(`
      mutation masterManagementProcessUpdate(
        $input: MasterManagementProcessUpdate!
      ) {
        masterManagementProcessUpdate(updateInput: $input) {
          id
          masterNumber
          flightNumber
          departureAirPort
          arrivalAirPort
          DepartureTime
          arrivalTime
          deliveryStatus
          userId
          userProfile {
            id
            userID
            username
            company_name_en
            company_name_kr
            phone_number
            company_registration_number
            __typename
          }
          bags {
            id
            name
            howToPayCustomDuties
            dgPackagingManagementCode
            numberOfUses
            receptionLists {
              id
              __typename
            }
            weight
            usedWeight
            updatedAt
            createdAt
            __typename
          }
          deleted
          createdAt
          updatedAt
          __typename
        }
      }
    `);

export const CREATE_WAY_BILL = gql(`
 mutation createWaybill(
  $createInput: WaybillCreateInput!
){
  createWaybill(createInput: $createInput){
    trackingNumber
    deliveryTypeId
}
}
`);

export const STATS_BY_YEAR = gql(`
  {
    allStatsByYear {
      from
      to
      data {
        time
        startedDelivery
        deletedData
        onHoldCount
        warehousingCompleted
        completedDelivery
        totalShipping
        exportReport
      }
    }
  }
`);

export const GET_SETTLEMENT_EXCEL_DATA = gql(`
  query getSettlementExcelData($ids: FindMany!){
  getSettlementExcelData(ids: $ids) {
        id
        userID
        username
        company_name_en
        company_name_kr
        phone_number
        company_registration_number
        balance
        english_address_full_address
        masterManagement{
        id
        masterNumber
        flightNumber
        departureAirPort
        arrivalAirPort
        DepartureTime
        arrivalTime
        deliveryStatus
        releaseDate
        userId
        bags {
          receptionLists {
            id
            waybillPrintStatus
            packingListPrintStatus
            masterNo
            deliveryFee
            senderInformation {
              id
              name
              phoneNumber
              address
              requestShippingId
              deleted
              createdAt
              updatedAt
              __typename
            }
            payeeInformation {
              id
              name
              shippingType{
                shippingWeight
                shippingType
              }
              shippingCountryId
              shippingCountry {
                id
                name
                code
                monetaryUnit
                country_name_en
                __typename
              }
              phoneNumber1
              phoneNumber2
              zipCode
              ssn
              ukeori
              euioss
              states
              cities
              streetAddress1
              streetAddress2
              email
              requestShippingId
              mexicoColony
              deleted
              createdAt
              updatedAt
              __typename
            }
            miscellaneousInformation {
              id
              shippingMessage
              horizontal
              portrait
              height
              actualWeight
              applicableWeight
              bulkWeight
              userData
              userDate2
              userData3
              boxQuantity
              productType
              exportDeclarationNumber
              requestShippingId
              deleted
              coupon
              createdAt
              updatedAt
              __typename
            }
            productInformation {
              id
              orderNo1
              orderNo2
              departureCountryCourierNumber
              currency
              productInformation {
                id
                barcode
                productName
                quantity
                unitPrice
                brandName
                sku
                purchaseURL
                hscode
                deleted
                createdAt
                updatedAt
                __typename
              }
              totalPriceAmount
              requestShippingId
              deleted
              createdAt
              updatedAt
              __typename
            }
        
            userId
            deleted
            deliveryFee
            createdAt
            updatedAt
            status
            trackingNumber
            shippingNumber
            additionalFee
            orderNumber
            weightValidation
            printWaybillAfterWeightChange
            sampoongAmountCheck
            treatmentAfterWeightChange
            processReleaseDate
          }
        }
        
  }

  }
}

`);

export const UBI_REQUEST_SHIPPING_LABEL = gql(`
  mutation ubiRequestShippingLabel($id: Float!) {
    ubiRequestShippingLabel(id: $id) {
      base64
    }
  }
`);

export const POSTPLUS_REQUEST_SHIPPING_LABEL = gql(`
  mutation postplusRequestShippingLabel($id: Float!) {
    postplusRequestShippingLabel(id: $id) {
      base64
    }
  }
`);

export const PACKK_LABEL = gql(`
mutation paackRequestShippingLabel($id:Float!){
  paackRequestShippingLabel(id:$id){
    base64
  }
}
`);

export const UPDATE_REQUEST_WEIGHT = gql(`
mutation updateWeight($updateInput:UpdateWeightInput!){
  updateWeight(updateInput: $updateInput){
    id
    actualWeight
    applicableWeight
    bulkWeight
    horizontal
    portrait
    height
  }
}
`);

export const LABEL_CHECK = gql(`
  query labelCheck($id: Float!) {
    labelCheck(id: $id) {
      labelStatus
    }
  }
`);

export const BULK_CREATE_PROCESS_DETAILS = gql(`
  subscription bulkCreateProcessDetails(
    $subscriptionNumber: Float!
  ) {
    bulkCreateProcessDetails(subscriptionNumber: $subscriptionNumber) {
      line
      reason
      orderNumber
    }
  }
`);

export const getUserInfo = gql(`
  query User {
    User {
      id
      receptionMethodCheck
      aproval
      balance
    }
  }
`);

export const CHANGE_USER_PASSWORD_BY_ADMIN = gql(`
  mutation changeUserPasswordByAdmin($userId: Float!, $newPassword: String!) {
    changeUserPasswordByAdmin(userId: $userId, newPassword: $newPassword) {
      status
    }
  }
`);

export const changeUserPasswordFromAdmin = gql(`
  mutation changeUserPasswordByAdmin($userId: Float!, $newPassword: String!) {
    changeUserPasswordByAdmin(userId: $userId, newPassword: $newPassword) {
      status
    }
  }
`);

export const allSettlementHistoryStats = gql(`
  query ($filter: SettlementHistoryStatsFilter) {
    allSettlementHistoryStats(filter: $filter) {
      history {
        shippingStarted
        companyName
        id
        deliveryCompleted
        overall
      }
    }
  }
`);

export const allShippingSummary = gql(`
  query ($filter: shippingSummaryFilter) {
    allShippingSummary(filter: $filter) {
      summary {
        unstacked
        quantityReceived
        companyName
        overall
      }
    }
  }
`);

export const DASHBOARD_SHIPPING_STATS = gql(`
 query clientDashboardShippingStats{
  clientDashboardShippingStats{
   monthlyData{
    id
    overallShipped
    shippingStarted
    overall
    deliveryCompleted
    quantityReceived
    unstacked
  }
    weeklyData{
    id
    overallShipped
    shippingStarted
    overall
    deliveryCompleted
    quantityReceived
    unstacked
    }
  }
}


`);
