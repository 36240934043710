import ListHOC from "../../SharedComponents/ListHOC";
import React from "react";
import StatsContainer from "../../components/Stats/Container";

function ClientStats(props) {
  return (
    <ListHOC {...props}>
      <StatsContainer isAdmin={false} />
    </ListHOC>
  );
}

export default ClientStats;
