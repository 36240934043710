import React from "react";
import {
	Page,
	Text,
	View,
	Document,
	StyleSheet,
	Image,
	Font,
} from "@react-pdf/renderer";

import shipter from "../../Common/assets/Images/shipter.png";
import Moment from "moment";
import PackageTable from "./PackageTable";

export const NovaLabel = (props) => {
	const { barcode, selectedRequests } = props;
	// console.log("barcode", barcode, "selectedRequests", selectedRequests);
	// console.log(typeof barcode);
	let { tableData, ...item1 } = selectedRequests;
	// console.log(props);

	const calculateTotal = (products) => {
		let total = 0;
		products.forEach((item) => {
			let product = parseInt(item.quantity) * parseInt(item.unitPrice);

			total = total + product;
		});

		return total;
	};

	return (
		<>
			{/* return ( */}
			{tableData && <PackageTable tableData={tableData} />}
			<Page size="A4" style={styles.page} orientation="landscape">
				{/* section 1 */}
				<View style={styles.sec1}>
					<View style={styles.sec11}>
						<View style={styles.wrapperDetail}>
							<Text>
								{item1?.productInformation?.productInformation?.reduce(
									(previousValue, currentValue) =>
										previousValue + currentValue.quantity,
									0
								)}
							</Text>
							<Text> /</Text>
							<Text>
								{item1?.productInformation?.productInformation?.reduce(
									(previousValue, currentValue) =>
										previousValue + currentValue.quantity,
									0
								)}
							</Text>
						</View>
						<Text style={styles.pad}>Pcs</Text>
					</View>
					<View style={styles.sec12}>
						<Text style={styles.pad}>
							OrderNumber: {item1?.productInformation?.orderNo1}
						</Text>
						<Text style={styles.pad}>
							OrderDate: {Moment().format("DD-MM-YYYY")}
						</Text>
					</View>
				</View>

				{/* section 2 */}

				<View style={styles.wrapperDetail}>
					<View style={styles.sec3Wrapper}>
						<View style={styles.sec3Expand}>
							<Text style={styles.bold}>Sender:</Text>
							<Text style={styles.red}>{item1?.senderInformation?.name}</Text>
							<Text style={styles.red}>
								{item1?.senderInformation?.address}
							</Text>
						</View>
						<View style={styles.sec3Expand}>
							<Text style={styles.red}>
								Contact: {item1?.senderInformation?.name}
							</Text>
							<Text style={styles.red}>
								Phone: {item1?.senderInformation?.phoneNumber}
							</Text>
						</View>
					</View>
					<View style={styles.sec4Wrapper}>
						<View style={styles.sec4Expand}>
							<Text style={styles.bold}>Receiver:</Text>
							<Text style={styles.red}>{item1?.payeeInformation?.name}</Text>
							<View style={styles.wrapperDetail}>
								<Text style={styles.red}>
									{item1?.payeeInformation?.streetAddress1}{" "}
								</Text>
								<Text style={styles.red}>
									{item1?.payeeInformation?.streetAddress2}
								</Text>
							</View>
						</View>
						<View style={styles.sec4Expand}>
							<View style={styles.wrapperDetail}>
								<Text style={styles.red}>
									{item1?.payeeInformation?.shippingCountry?.country_name_en}
								</Text>
								<Text style={styles.red}>
									{" "}
									{item1?.payeeInformation?.zipCode}
								</Text>
							</View>
						</View>
					</View>
				</View>

				{/* section 3 */}
				<View style={styles.wrapperDetail}>
					<View style={styles.sec3Wrapper}>
						<View style={styles.sec3Expand}>
							<Text style={styles.bold}>Content:</Text>
							<View style={styles.wrapperWrap}>
								{item1.productInformation?.productInformation.map(
									(item2, index) => {
										return (
											<>
												<Text style={styles.red} key={index}>
													{item2.productName.length > 25
														? item2.productName.slice(0, 25).concat("...")
														: item2.productName}{" "}
													* {item2.quantity}(pcs),
												</Text>
											</>
										);
									}
								)}
							</View>
						</View>
					</View>

					<View style={styles.sec4Wrapper}>
						<View style={styles.sec4Expand}>
							<Text style={styles.bold}>Ship To:</Text>
							<View style={styles.wrapperDetail}>
								<Text style={styles.red}>
									{item1?.payeeInformation?.streetAddress1}
								</Text>
								<Text style={styles.red}>
									{item1?.payeeInformation?.streetAddress2}
								</Text>
							</View>
							<View style={styles.sec3a}>
								<Text style={styles.red}>
									{item1?.payeeInformation?.zipCode}
								</Text>
								<Text style={styles.red}>
									,{item1?.payeeInformation?.states}
								</Text>
								<Text style={styles.red}>
									,{item1?.payeeInformation?.cities}
								</Text>
								{item1?.payeeInformation?.mexicoColony && (
									<Text style={styles.red}>
										,{item1?.payeeInformation?.mexicoColony}
									</Text>
								)}
							</View>
						</View>
						<View style={styles.sec4Expand}>
							<Text style={styles.red}>{item1?.payeeInformation?.cities}</Text>
							<Text style={styles.red}>
								Contact: {item1?.payeeInformation?.name}
							</Text>
							<Text style={styles.red}>
								Phone: {item1?.payeeInformation?.phoneNumber1}
							</Text>
						</View>
					</View>
				</View>

				{/* section 4 */}
				<View style={styles.wrapperDetail}>
					<View style={styles.Col1}>
						<Text>origin: </Text>
						<Text>KR</Text>
					</View>
					<View style={styles.Col2}>
						<Text>destination: </Text>
						<Text>UA</Text>
					</View>
					<View style={styles.Col3}>
						<Text>{item1?.miscellaneousInformation?.actualWeight} KG</Text>
					</View>
				</View>

				{/* section 5 */}
				<View style={styles.wrapperDetail}>
					<View style={styles.sec5}>
						<Image src={barcode} width={"100%"} height={50} />
						<Text style={{ fontSize: "18px" }}>{item1.trackingNumber}</Text>
						{/* <Text style={styles.red}>{item1.shippingNumber}</Text> */}
					</View>
					<View style={styles.sec6}>
						<Text>Customer Value:</Text>
						<View style={styles.wrapperDetail}>
							<Text>
								{calculateTotal(item1.productInformation.productInformation)}{" "}
							</Text>
							<Text>{item1.productInformation?.currency}</Text>
						</View>
						<Text>E-Commerce</Text>
					</View>
				</View>
			</Page>
			{/* ); */}
		</>
	);
};

Font.register({
	family: "SpoqaHanSans",
	src: "https://cdn.jsdelivr.net/gh/spoqa/spoqa-han-sans@01ff0283e4f36e159ffbf744b36e16ef742da6d8/Subset/SpoqaHanSans/SpoqaHanSansLight.ttf",
});
const fRow = {
	diaplay: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
};
const fCol = {
	diaplay: "flex",
	flexDirection: "column",
	alignItems: "center",
	justifyContent: "center",
};

const styles = StyleSheet.create({
	page: {
		border: "2px solid #000",
		fontFamily: "SpoqaHanSans",
	},
	sec1: {
		display: "flex",
		flexDirection: "row",
		width: "100%",
		color: "#fff",
	},
	sec11: {
		...fCol,
		backgroundColor: "white",
		paddingLeft: 15,
		paddingRight: 15,
		color: "black",
		fontSize: 16,
		width: "20%",
		border: "2px solid #000",
	},
	sec12: {
		...fCol,
		backgroundColor: "white",
		width: "80%",
		alignItems: "flex-start",
		justifyContent: "flex-start",
		paddingLeft: 15,
		paddingRight: 15,
		color: "black",
		fontSize: 16,
		fontWeight: 700,
		border: "2px solid #000",
	},
	pad: {
		padding: 5,
	},
	red: {
		color: "black",
		fontSize: 13,
	},
	bold: {
		color: "black",
		fontSize: 14,
		fontWeight: "bold",
	},
	col: {
		...fCol,
	},
	Col1: {
		...fRow,
		justifyContent: "space-between",
		alignItems: "center",
		border: "2px solid #000",
		width: "40%",
		padding: 15,
	},
	Col2: {
		...fRow,
		justifyContent: "space-between",
		alignItems: "center",
		border: "2px solid #000",
		width: "30%",
		padding: 15,
	},
	Col3: {
		...fRow,
		justifyContent: "center",
		alignItems: "center",
		border: "2px solid #000",
		width: "30%",
		paddingTop: 15,
		paddingBottom: 15,
	},
	sec2: {
		...fRow,
		color: "black",
		fontSize: 30,
		fontWeight: 700,
		padding: 10,
		alignItems: "center",
		justifyContent: "center",
		border: "2px solid #000",
	},
	wrapperDetail: {
		...fRow,
	},
	wrapperWrap: {
		...fRow,
		flexWrap: "wrap",
	},
	sec3Wrapper: {
		...fCol,
		alignItems: "flex-start",
		justifyContent: "space-between",
		paddingLeft: 15,
		paddingRight: 15,
		width: "40%",
		border: "2px solid #000",
		minHeight: 120,
	},
	sec4Wrapper: {
		...fCol,
		alignItems: "flex-start",
		justifyContent: "space-between",
		minHeight: 120,
		paddingLeft: 15,
		paddingRight: 15,
		width: "60%",
		border: "2px solid #000",
	},
	sec3: {
		...fCol,
		alignItems: "flex-start",
		justifyContent: "flex-start",
		minHeight: 120,
		paddingLeft: 15,
		paddingRight: 15,
		border: "2px solid #000",
		width: "40%",
	},
	sec3Expand: {
		...fCol,
		alignItems: "flex-start",
		justifyContent: "flex-start",
		width: "100%",
		paddingTop: 10,
		paddingBottom: 10,
	},
	sec3a: {
		...fRow,
	},
	sec4: {
		...fCol,
		minHeight: 120,
		alignItems: "flex-start",
		justifyContent: "flex-start",
		paddingLeft: 15,
		paddingRight: 15,
		border: "2px solid #000",
		width: "60%",
	},
	sec4Expand: {
		...fCol,

		alignItems: "flex-start",
		justifyContent: "flex-start",
		width: "100%",
		paddingTop: 10,
		paddingBottom: 10,
	},

	sec5: {
		...fCol,
		padding: 15,
		width: "70%",
		border: "2px solid #000",
	},
	sec6: {
		...fCol,
		padding: 15,
		width: "30%",
		border: "2px solid #000",
	},
	body: {
		padding: 10,
	},
	row: {
		...fRow,
	},
	center: {
		...fCol,
		marginTop: 25,
	},
	image: {
		maxWidth: 130,
		height: "auto",
	},
});
