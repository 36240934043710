import { updateReqShipping } from "../query";

/**
 *
 * @param rows of selected data
 */
export const updateAllReqShipping = async (rows) => {
	let result = await Promise.all(
		rows.map(async (d) => {
			await updateReqShipping(d.id);
		})
	);
	return result;
};
