import React from "react";
import { Avatar, Grid } from "@mui/material";
import Images from "../Common/Images";
import Link from "@mui/material/Link"; 

function SocialLinks() {
  return (
    <Grid
      container
      justifyContent="center"
      spacing={3}
      xs={12}
      sm={12}
      sx={{
        mb: "60px",
      }}
    >
      <Grid item>
        <Avatar
          alt="shipter"
          src={Images.shipter}
          variant="square"
          sx={{ width: "auto" }}
        />
      </Grid>

      <Grid item>
        <Link href="http://pf.kakao.com/_xjxobxjxb/chat">
        <Avatar alt="ch" src={Images.ch} variant="square" sx={{ width: 78 }} />

        </Link>
      </Grid>
      <Grid item>
        <Link href="https://www.instagram.com/shipter_korea/">
        <Avatar
          alt="instagram"
          src={Images.instagram}
          variant="square"
          sx={{ width: 78 }}
        />
        </Link>
      
      </Grid>
      <Grid item>
        <Link href="https://blog.naver.com/shipter">
        <Avatar
          alt="Blog"
          src={Images.blog}
          variant="square"
          sx={{ width: 78 }}
        />
        </Link>
       
      </Grid>
    </Grid>
  );
}

export default SocialLinks;
