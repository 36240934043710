import React from "react";

const ConfirmIcon = () => {
	return (
		<svg
			width="71"
			height="71"
			viewBox="0 0 71 71"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<circle opacity="0.1" cx="35.5" cy="35.5" r="35.5" fill="#15A336" />
			<path
				d="M35.5 16.6875C25.1111 16.6875 16.6875 25.1111 16.6875 35.5C16.6875 45.8889 25.1111 54.3125 35.5 54.3125C45.8889 54.3125 54.3125 45.8889 54.3125 35.5C54.3125 25.1111 45.8889 16.6875 35.5 16.6875ZM43.6255 29.3565L34.7819 41.6183C34.6583 41.7908 34.4954 41.9314 34.3066 42.0283C34.1178 42.1253 33.9087 42.1759 33.6964 42.1759C33.4842 42.1759 33.275 42.1253 33.0863 42.0283C32.8975 41.9314 32.7345 41.7908 32.6109 41.6183L27.3745 34.362C27.2149 34.1395 27.3745 33.8287 27.6475 33.8287H29.6169C30.0452 33.8287 30.4525 34.0345 30.7045 34.3872L33.6943 38.536L40.2955 29.3817C40.5475 29.0332 40.9506 28.8232 41.3831 28.8232H43.3525C43.6255 28.8232 43.7851 29.134 43.6255 29.3565V29.3565Z"
				fill="#15A336"
			/>
		</svg>
	);
};
export default ConfirmIcon;
