// import { CalendarMonth } from "@mui/icons-material";
// import { makeStyles } from "@material-ui/core";
import { useState, forwardRef } from "react";
import {
	InputAdornment,
	Select,
	TextField,
	Typography,
	MenuItem,
} from "@mui/material";
import React from "react";
import styled from "styled-components";

const CustomInput = forwardRef(
	(
		{
			children,
			customLabel = false,
			label,
			placeholder,
			labelColor = "#F7F7F7",
			padding = "10px 20px",
			multi = false,
			input = null,
			select = false,
			labelWidth = "150px",
			labelHeight = "auto",
			type = "text",
			defaultValue,
			iconStart = null,
			iconEnd = null,
			required,
			flexDirection,
			labelWeight = 500,
			selectValue = "",
			disable = false,
			dropDownList = [],
			stepper = false,
			...props
		},
		ref
	) => {
		const [item, setItem] = useState(selectValue ? selectValue : 1);

		return (
			<CustomComponentContainer>
				{label && (
					<div
						style={{
							height: labelHeight,
							width: labelWidth,
							background: labelColor,
							display: "flex",
							alignItems: "center",
							padding: padding,

							borderRight:
								"1px solid " + labelColor !== "" ? "#E5E5E5" : "transparent",
						}}
					>
						<Typography
							sx={{ padding: "5px", fontWeight: labelWeight }}
							fontSize={"14px"}
							component={"span"}
						>
							{label}
							{required && <span style={{ color: "red" }}>*</span>}
						</Typography>
					</div>
				)}
				{customLabel && (
					// {
					/* {dropDownList.length > 0 ? (
                                                                                                      <SelectBox
                                                                                                          defaultValue={selectValue}
                                                                                                          value={item}
                                                                                                          onChange={(event) => setItem(event.target.value)}
                                                                                                          style={{
                                                                                                              marginTop: "11px",
                                                                                                              marginRight: "10px",
                                                                                                              height: "38px",
                                                                                                              borderRadius: "10px",
                                                                                                          }}
                                                                                                      >
                                                                                                          {dropDownList.map((item, index) => (
                                                                                                              <MenuItem key={index} value={index + 1}>
                                                                                                                  {item}
                                                                                                              </MenuItem>
                                                                                                          ))}
                                                                                                      </SelectBox>
                                                                                                  ) : ( */
					// }
					<SelectBox
						ref={ref}
						defaultValue={selectValue}
						value={item}
						required={required}
						onChange={(event) => setItem(event.target.value)}
						style={{
							marginRight: "10px",
							height: "38px",
							borderRadius: "10px",
						}}
					>
						<MenuItem value={1}>접수일</MenuItem>
						<MenuItem value={2}>출고일</MenuItem>
						<MenuItem value={3}>입금일</MenuItem>
					</SelectBox>
					// {/* ) */}}
				)}
				<div style={{ width: "100%", display: "flex", alignItems: "center" }}>
					<div
						style={{
							// minHeight: 60,
							display: "flex",
							alignItems: "center",
							width: "100%",
						}}
					>
						{input ? (
							input
						) : select ? (
							<SelectBox
								ref={ref}
								{...props}
								value={defaultValue}
								defaultValue={defaultValue}
								required={required}
								disable={disable}
								stepper={stepper}
							>
								{children}
							</SelectBox>
						) : (
							<TextFieldBox
								ref={ref}
								type={type}
								required={required}
								multiline={multi}
								placeholder={placeholder}
								iconStart={iconStart}
								iconEnd={iconEnd}
								disable={disable}
								stepper={stepper}
								{...props}
							/>
						)}
					</div>
				</div>
			</CustomComponentContainer>
		);
	}
);

export default CustomInput;

export const TextFieldBox = forwardRef(
	(
		{
			styles,
			type,
			required,
			iconStart = null,
			iconEnd = null,
			disable = false,
			readonly,
			min = 0,
			step = 1,
			stepper,
			inputProps = {},
			...props
		},
		ref
	) => {
		return (
			<StyledTextField
				inputRef={ref}
				style={{
					boxShadow: "none",
					width: "100%",
					backgroundColor: "transparent",

					borderRadius: "10px",
					marginTop: !stepper && 0,
					marginBottom: !stepper && 0,
					margin: stepper && 10,
					...styles,
				}}
				required={required}
				disabled={disable}
				type={type}
				variant="outlined"
				size="small"
				margin="none"
				InputProps={{
					...(readonly && { readOnly: true }),
					inputProps: { min: min, step: step, ...inputProps },
					startAdornment: iconStart ? (
						<InputAdornment position="start">{iconStart}</InputAdornment>
					) : null,
					endAdornment: iconEnd ? (
						<InputAdornment position="end">{iconEnd}</InputAdornment>
					) : null,
					...inputProps,
				}}
				{...props}
			/>
		);
	}
);

export const StyledTextField = styled(TextField)`
	fieldset {
		border-radius: 10px;
		outline: none;
		border: 1px solid #e5e5e5;
	}

	p.MuiFormHelperText-root {
		margin-left: 0px;
	}

	.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
		height: 100%;
		border-radius: 10px;
	}

	input {
		padding-left: 16px;
	}
`;

export const SelectBox = forwardRef(
	({ children, styles, value, defaultValue, stepper, ...props }, ref) => (
		<Select
			style={{
				width: "100%",
				borderRadius: "12px",
				margin: stepper && 10,
				...styles,
			}}
			size="small"
			defaultValue={defaultValue}
			value={value}
			{...props}
		>
			{children}
		</Select>
	)
);

const CustomComponentContainer = styled.div`
	display: flex;
	height: 100%;
	width: 100%;

	.custom-container-label {
		font-size: 11px;
		display: flex;
		align-items: center;
	}

	.MuiInputBase-root-MuiOutlinedInput-root {
		border-radius: 10px;
	}

	.MuiOutlinedInput-root-MuiSelect-root {
		border-radius: 10px;
	}
`;
const SelectDecoy = styled.div`
	.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
		border: none;
	}
`;
