import { useEffect, useState } from "react";
import * as yup from "yup";
import { Formik } from "formik";
import { CreateShippingForm } from "./CreateShippingForm";
import { useGetById } from "../../../../utils/utils";
import { useRedirect } from "react-admin";
import { useLocation } from "react-router-dom";
import { gql } from "@apollo/client";
import { APIClient } from "../../../../utils/apiGraphql";
import { useNavigate } from "react-router-dom";
import { useUtils } from "../../../../utils/utils";

const formiKInitialState = {
	// first step

	senderInformation: {
		name: "",
		phoneNumber: "",
		address: "",
	},
	payeeInformation: {
		name: "",
		shippingCountry: "",
		phoneNumber1: "",
		phoneNumber2: "",
		zipCode: "",
		ssn: "",
		ukeori: "",
		euioss: "",
		states: "",
		cities: "",
		mexicoColony: "",
		streetAddress1: "",
		streetAddress2: "",
		email: "",
		shippingTypeId: "",
	},
	productInformation: {
		orderNo1: "",
		orderNo2: "",
		departureCountryCourierNumber: "",
		currency: "",
		productInformation: [
			{
				barcode: "",
				productName: "",
				quantity: "",
				unitPrice: "",
				brandName: "",
				sku: "",
				purchaseURL: "",
				hscode: "",
			},
		],
	},
	miscellaneousInformation: {
		shippingMessage: "",
		actualWeight: "",
		userData: "",
		userDate2: "",
		userData3: "",
		boxQuantity: null,
		productType: "",
		exportDeclarationNumber: "",
		coupon: "",
	},
};

export function ClientCreateShippingContainer({
	editMode,
	setEditMode,
	backendErrors,
	setBackendErrors,
	errorMessage,
	...hocProps
}) {
	const [initialState, setInitialState] = useState(formiKInitialState);
	const { userData } = useUtils();

	const fetchUser = async () => {
		const Query = gql(`query User{
      User{
        username
        email
        role
        customer_name
        id
        business_registration
        company_registration_number
        phone_number
        business_item
        contact_name
        address_1
        address_2
        company_name_en
        website_address
        english_address_city
        english_address_street
        english_address_state
        english_address_full_address
        english_address_zip_code
        code
      }
    }`);

		try {
			const {
				data: {
					User: { customer_name, phone_number, english_address_full_address },
				},
			} = await APIClient.query({
				query: Query,
			});

			setInitialState({
				...initialState,
				senderInformation: {
					name: customer_name,
					phoneNumber: phone_number,
					address: english_address_full_address,
				},
			});
		} catch (e) {
			console.log(e);
		}
	};

	useEffect(() => {
		fetchUser();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const navigate = useNavigate();

	const handleSubmit = async (values, formikUtils) => {
		// const formInit = {
		//   // first step

		//   "senderInformation": {
		//     "name": "nauman",
		//     "phoneNumber": "0307513513",
		//     "address": "house 15 street 14"
		//   },
		//   "payeeInformation": {
		//     "name": "nauman",
		//     "shippingCountry": "1-UAE",
		//     "phoneNumber1": "0307513513 ",
		//     "phoneNumber2": "0307513513 ",
		//     "zipCode": "45000",
		//     "ssn": "7888",
		//     "ukeori": "788",
		//     "euioss": "888",
		//     "states": "Punjab",
		//     "cities": "rawalpindi",
		//     "streetAddress1": "street 15",
		//     "streetAddress2": "house 15",
		//     "email": "naumanshk4@gmail.com",
		//     "shippingTypeId": 9
		//   },
		//   "productInformation": {
		//     "orderNo1": "0900",
		//     "orderNo2": "0900",
		//     "departureCountryCourierNumber": "90300",
		//     "currency": "PKR",
		//     "productInformation": [
		//       {
		//         "barcode": "90019992",
		//         "productName": "Lays",
		//         "quantity": 2,
		//         "unitPrice": 3000,
		//         "brandName": "Nestle",
		//         "sku": "1110",
		//         "purchaseURL": "nauman.com",
		//         "hscode": "98890"
		//       }
		//     ],
		//     "totalPriceAmount": 20000
		//   },
		//   "miscellaneousInformation": {
		//     "shippingMessage": "nothing",
		//     "horizontal": "yes",
		//     "portrait": "yes",
		//     "height": "84",
		//     "actualWeight": 73,
		//     "applicableWeight": 84,
		//     "bulkWeight": 84,
		//     "userData": "nauman",
		//     "userDate2": "nauman",
		//     "userData3": "nauman",
		//     "boxQuantity": "1",
		//     "productType": "Commercial",
		//     "exportDeclarationNumber": "hh"
		//   }

		// };

		if (editMode) {
			const updateInput = {
				id: values.id,
				// status: "status",
				senderInformation: {
					name: values.senderInformation.name,
					phoneNumber: values.senderInformation.phoneNumber,
					address: values.senderInformation.address,
					id: values.senderInformation.id,
				},
				payeeInformation: {
					id: values.payeeInformation.id,
					name: values.payeeInformation.name,
					// shippingCountry: values.payeeInformation.shippingCountry,
					phoneNumber1: values.payeeInformation.phoneNumber1,
					phoneNumber2: values.payeeInformation.phoneNumber2,
					zipCode: values.payeeInformation.zipCode,
					ssn: values.payeeInformation.snn,
					ukeori: values.payeeInformation.ukeori,
					euioss: values.payeeInformation.euioss,
					states: values.payeeInformation.states,
					cities: values.payeeInformation.cities,
					mexicoColony: values.payeeInformation.mexicoColony,
					streetAddress1: values.payeeInformation.streetAddress1,
					streetAddress2: values.payeeInformation.streetAddress2,
					email: values.payeeInformation.email,
					shippingTypeId: values.payeeInformation.shippingTypeId,
				},
				productInformation: {
					id: values.productInformation.id,
					orderNo1: values.productInformation.orderNo1,
					orderNo2: values.productInformation.orderNo2,
					departureCountryCourierNumber:
						values.productInformation.departureCountryCourierNumber,
					currency: values.productInformation.currency,
					productInformation: values.productInformation.productInformation,
				},
				miscellaneousInformation: {
					id: values.miscellaneousInformation.id,
					shippingMessage: values.miscellaneousInformation.shippingMessage,
					actualWeight: values.miscellaneousInformation.actualWeight,
					userData: values.miscellaneousInformation.userData,
					userDate2: values.miscellaneousInformation.userDate2,
					userData3: values.miscellaneousInformation.userData3,
					boxQuantity: values.miscellaneousInformation.boxQuantity,
					productType: values.miscellaneousInformation.productType,
					exportDeclarationNumber:
						values.miscellaneousInformation.exportDeclarationNumber,
					coupon: values.miscellaneousInformation.coupon,
				},
			};

			const { data, reason } = await hocProps.handleSubmit(
				{ updateInput },
				formikUtils
			);
			if (reason === "create") {
				setInitialState(formiKInitialState);
			}
		} else {
			const createInput = {
				...values,
			};
			const { data, reason } = await hocProps.handleSubmit(
				{ createInput },
				formikUtils
			);
			if (reason === "create") {
				setInitialState(formiKInitialState);
			}
		}

		if (userData.role === "admin") {
			navigate("/request-shipping");
		} else navigate("/shipping-request/list");
	};
	const { search } = useLocation();

	const { getData } = useGetById();

	useEffect(() => {
		(async () => {
			if (search) {
				const params = new URLSearchParams(search);
				const id = params.get("id");
				if (id) {
					const data = await getData("RequestShipping", Number(id));
					if (data) {
						setEditMode(true);
						handleInitialiseValues(data);
					}
				}
			}
		})();
	}, [search]);

	const handleInitialiseValues = (data) => {
		setInitialState((prevState) => {
			const obj = {};
			Object.keys(prevState).forEach((res) => {
				obj[res] = data[res];
			});
			obj.payeeInformation.shippingCountry =
				data.shippingCountry.id + "-" + data.shippingCountry.monetaryUnit;

			return { ...obj, ...(editMode && { id: data.id }) };
		});
	};

	const validation = yup.object().shape({
		// step 1
		// sender info
		// SenderInformationCreateInput
		senderInformation: yup.object().shape({
			// name
			name: yup.string(),
			phoneNumber: yup.string(),
			address: yup.string(),
		}),
		// step two validation
		payeeInformation: yup.object().shape({
			// normal
			name: yup.string(),
			shippingCountry: yup.string(),
			phoneNumber1: yup.string(),
			phoneNumber2: yup.string(),
			shippingTypeId: yup.number(),
			zipCode: yup.string(),
			ssn: yup.string(),
			ukeori: yup.string(),
			eUioss: yup.string(),
			states: yup.string(),
			cities: yup.string(),
			mexicoColony: yup.string(),
			streetAddress1: yup.string(),
			streetAddress2: yup.string(),
			email: yup.string().email("Invalid email").optional(),
		}),

		// step 3 productInformation
		productInformation: yup.object().shape({
			orderNo1: yup.string(),
			orderNo2: yup.string(),
			departureCountryCourierNumber: yup.string(),
			currency: yup.string(),
			totalPriceAmount: yup.number(),
		}),

		// step 4
		miscellaneousInformation: yup.object().shape({
			shippingMessage: yup.string(),
			horizontal: yup.number(),
			portrait: yup.number(),
			height: yup.number(),
			actualWeight: yup.number(),
			applicableWeight: yup.number(),
			bulkWeight: yup.number(),
			userData: yup.string(),
			userDate2: yup.string(),
			userData3: yup.string(),
			boxQuantity: yup.number().required(),
			productType: yup.string(),
			exportDeclarationNumber: yup.string(),
			requestShippingId: yup.string(),
		}),
	});

	return (
		<Formik
			initialValues={initialState}
			validationSchema={validation}
			onSubmit={handleSubmit}
			enableReinitialize
		>
			{(formikProps) => (
				<CreateShippingForm
					validation={validation}
					initialValues={initialState}
					editMode={editMode}
					backendErrors={backendErrors}
					setBackendErrors={setBackendErrors}
					errorMessage={errorMessage}
					{...formikProps}
				/>
			)}
		</Formik>
	);
}
