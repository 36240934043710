import { Card, Paper } from "@mui/material";
import React from "react";

export default function CustomCard({
	children,
	elevation = 0,
	border = false,
	backgroundColor,
}) {
	return (
		<Paper
			elevation={elevation}
			style={{
				borderRadius: "20px",
				border: border ? "1px solid #E5E5E5" : "0px",
				backgroundColor: backgroundColor ? backgroundColor : "#fff",
			}}
		>
			{children}
		</Paper>
	);
}
