import { useState } from "react";
import { Divider, Stack } from "@mui/material";
import CustomInput from "../../../components/CustomInput";
import CustomButton from "../../../components/CustomButton";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Toaster from "../../../utils/Toaster";
import { APIClient } from "../../../utils/apiGraphql";
import { gql } from "@apollo/client/core";
import {  useLogout } from "react-admin";
export function ClientPasswordSettings(props) {
  const logout = useLogout();

  const [initialValues, setInitialValues] = useState({
    verifyPassword: "",
    confirmPassword: "",
    newPassword: "",
  });
  const validationSchema = () => {
    return Yup.object().shape({
      verifyPassword: Yup.string().required("새 비밀번호를 다시 한 번 입력해주세요."),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("newPassword")], "Password must be same")
        .required("입력하신 새 비밀번호가 일치하여야 합니다."),
      newPassword: Yup.string().required("새 비밀번호를 입력해주세요."),
    });
  };

  const handleSubmit = async (values, { resetForm }) => {
    
    delete values.confirmPassword;
    const ChangePasswordMutation = gql(`
      mutation changeUserPassword($input: ChangePasswordInput!) {
        changeUserPassword(updateInput: $input) {
          status
        }
      }
    `);
    try {
      const res = await APIClient.mutate({
        mutation: ChangePasswordMutation,
        variables: {
          input: {
            ...values,
          },
        },
      });
      Toaster("success", "Updated");
      // resetForm();
      logout();
    } catch (e) {
      resetForm();
      Toaster("error", e.message);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {({ handleSubmit, values, errors, handleChange }) => {
        return (
          <Form onSubmit={handleSubmit}>
            <Stack spacing={"50px"} maxWidth="1100px" padding="0px" sx={{ ml: "-7px" }}>
              <div
                style={{
                  borderRadius: "10px",
                  overflow: "hidden",
                  border: "1px solid #E5E5E5",
                }}
              >


<CustomInput
                  name={"verifyPassword"}
                  value={values.verifyPassword}
                  onChange={handleChange}
                  error={!!errors.verifyPassword}
                  helperText={!!errors.verifyPassword && errors.verifyPassword}
                  type={"password"}
                  label="새 비밀번호"
                  placeholder="새 비밀번호를 입력해주세요."
                  style={{padding: "10px", width: "100%"}}
                />


<Divider />
                <CustomInput
                  name={"newPassword"}
                  value={values.newPassword}
                  onChange={handleChange}
                  error={!!errors.newPassword}
                  helperText={!!errors.newPassword && errors.newPassword}
                  type={"password"}
                  label="비밀번호 확인"
                  placeholder="새 비밀번호를 다시 한번 입력해주세요."
                  style={{padding: "10px", width: "100%"}}
                />
                
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: "20px",
                }}
              >
                <CustomButton type={"submit"} width="100px">
                  수정
                </CustomButton>
              </div>
            </Stack>
          </Form>
        );
      }}
    </Formik>
  );
}
