import {
  Divider,
  Popover,
  styled,
  Card,
  CardContent,
  CardActions,
  Typography,
  Box,
} from "@mui/material";
import React, { useState } from "react";
import FileUpload from "../../../components/FileUpload";
import CustomButton from "../../../components/CustomButton";

const PopOverContent = styled(CardContent)(({ theme }) => {
  return {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
    minWidth: 320,
  };
});

export default function UploadRateCardPopover({ sendFile }) {
  const [elRef, setElRef] = useState(null);
  const [file, setFile] = useState(null);

  const handleClose = () => {
    setElRef(null);
  };

  const inputChange = (files) => {
    setFile(files);
  };

  const onClose = () => {
    setFile(null);
    setElRef(null);
  };

  const selectFile = () => {
    sendFile(file);
    setElRef(null);
  };

  return (
    <>
      <CustomButton
        onClick={({ currentTarget }) => setElRef(currentTarget)}
        variant={"outlined"}
        styles={{
          color: "#000",
          border: "1px solid #C4C4C4",
          padding: "7px",
          borderRadius: 5,
        }}
      >
        요율표 등록
      </CustomButton>
      <Popover
        anchorReference="anchorPosition"
        open={!!elRef}
        anchorPosition={(() => {
          if (elRef) {
            // getting position of element
            const details = elRef.getBoundingClientRect();
            return {
              top: details.top + details.height + 10,
              left: details.left - 10,
            };
          }
        })()}
        // anchorEl={elRef}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        onClose={handleClose}
      >
        <Card>
          <PopOverContent>
            <Box display={"flex"} alignItems={"center"} gap={2}>
              <Typography fontWeight={"bold"}>요율표 업로드</Typography>
              <FileUpload onChange={inputChange} />
            </Box>

            <Divider />
          </PopOverContent>
          <CardActions sx={{ justifyContent: "flex-end" }}>
            <CustomButton
              onClick={onClose}
              styles={{
                background: "#F8F8FA",
                color: "#000",
                width: "auto",
              }}
            >
              취소
            </CustomButton>
            <CustomButton
              styles={{ width: "auto" }}
              onClick={selectFile}
              disabled={file === null ? true : false}
            >
              전송
            </CustomButton>
          </CardActions>
        </Card>
      </Popover>
    </>
  );
}
