import React, { useEffect, useState } from "react";
import UpdateHOC from "../SharedComponents/UpdateHOC";
import CreateHOC from "../SharedComponents/CreateHOC";
import {
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  Checkbox,
  MenuItem,
  Modal,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import CustomCard from "../components/CustomCard";
import { Form, Formik } from "formik";
import CustomButton from "../components/CustomButton";
import * as Yup from "yup";
import { monetaryUnit } from "../utils/utils";
import { makeStyles } from "@mui/styles";

import countries from "../json-data/countries.json";

const useStyles = makeStyles({
  modalScroll: { overflow: "scroll" },
});

function ShippingCountriesModal({
  isOpen,
  onClose,
  selected = null,
  deliveryTypes = [],
  onSuccess = (data, reason) => {},
  ...rest
}) {
  const classes = useStyles();
  const [initialState, setInitialValue] = useState({
    ...(selected && { id: null }),
    name: "",
    code: "",
    monetaryUnit: "",
    deliveryType: "",
    shippingTypesDetailsIds: "",
  });

  useEffect(() => {
    if (selected) {
      let {
        id,
        name,
        code,
        monetaryUnit,
        deliveryType,
        shippingTypesDetailsIds,
        country_name_en,
      } = selected;

      deliveryType = deliveryType.id;
      shippingTypesDetailsIds = JSON.parse(shippingTypesDetailsIds);

      setInitialValue({
        id,
        name,
        code,
        monetaryUnit,
        deliveryType,
        shippingTypesDetailsIds,
        country_name_en,
      });
    }
  }, [selected]);

  return React.createElement(selected ? UpdateHOC : CreateHOC, {
    handleClose: onClose,
    ...rest,
    children: (hocProps) => {
      const handleSubmit = async (values, formikUtils) => {
        let input = {};
        if (selected) {
          input = {
            updateInput: {
              ...values,
              shippingTypesDetailsIds: JSON.stringify(
                values.shippingTypesDetailsIds
              ),
              isActive: true,
            },
          };
        } else {
          input = {
            createInput: {
              ...values,
              shippingTypesDetailsIds: JSON.stringify(
                values.shippingTypesDetailsIds
              ),
              isActive: true,
            },
          };
        }

        const res = await hocProps.handleSubmit({ ...input }, formikUtils);
        if (res) {
          const { data, reason } = res;
          onSuccess(data, reason);
        }
      };

      const validationSchema = () => {
        return Yup.object().shape({
          name: Yup.string().required(),
          country_name_en: Yup.string().required(),
          code: Yup.string().required(),
          monetaryUnit: Yup.string().required(),
          deliveryType: Yup.number().required(),
          shippingTypesDetailsIds: Yup.array()
            .of(Yup.mixed())
            .min(1)
            .required(),
        });
      };

      const handleShippingTypes = (
        target,
        currentValues,
        index,
        setFieldValue
      ) => {
        const temp = [...currentValues];
        const { name, value, checked } = target;

        if (checked) {
          setFieldValue(name, [...temp, +value]);
        } else {
          const index1 = temp.indexOf(+value);
          if (index1 > -1) {
            temp.splice(index1, 1);
            setFieldValue(name, temp);
          }
        }
      };

      return (
        <Modal
          open={isOpen}
          onClose={onClose}
          aria-labelledby="Shipping countries Edit/Create"
          aria-describedby="This modal is used for edit and create shipping countries"
          className={classes.modalScroll}
        >
          <Box
            style={{
              padding: 20,
              // overflowX: "hidden",
              minHeight: "100vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CustomCard>
              <Stack spacing={"20px"} minWidth={400} maxWidth={900} p="20px">
                <Typography
                  fontSize={"1.5rem"}
                  varient={"h1"}
                  fontWeight={"bold"}
                >
                  배송 국가 추가
                </Typography>

                <Formik
                  initialValues={initialState}
                  onSubmit={handleSubmit}
                  enableReinitialize={selected}
                  validationSchema={validationSchema}
                >
                  {({
                    values,
                    errors,
                    handleChange,
                    handleSubmit,
                    setFieldValue,
                    submitCount,
                    isSubmitting,
                    isValid,
                  }) => {
                    return (
                      <Form onSubmit={handleSubmit}>
                        <Box
                          my={2}
                          display={"flex"}
                          flexDirection={"column"}
                          gap={2.5}
                        >
                          {/*<Box*/}
                          {/*  display={"flex"}*/}
                          {/*  flexDirection={"column"}*/}
                          {/*  gap={2}*/}
                          {/*>*/}
                          {/*  <label>*/}
                          {/*    <Typography fontWeight={"bold"}>*/}
                          {/*      국가명*/}
                          {/*    </Typography>*/}
                          {/*  </label>*/}
                          {/*  <TextFieldBox*/}
                          {/*    sx={{ borderRadius: 8 }}*/}
                          {/*    name={"name"}*/}
                          {/*    placeholder={"국가명을 입력해주세요."}*/}
                          {/*    value={values.name}*/}
                          {/*    onChange={handleChange}*/}
                          {/*    error={*/}
                          {/*      submitCount > 0 &&*/}
                          {/*      !isSubmitting &&*/}
                          {/*      !isValid &&*/}
                          {/*      !!errors.name*/}
                          {/*    }*/}
                          {/*  />*/}
                          {/*</Box>*/}

                          <Box
                            display={"flex"}
                            flexDirection={"column"}
                            gap={2}
                          >
                            <label>
                              <Typography fontWeight={"bold"}>
                                배송 국가
                              </Typography>
                            </label>
                            <FormControl
                              size="small"
                              sx={{
                                width: "100%",
                                m: 0,
                              }}
                              error={
                                submitCount > 0 &&
                                !isSubmitting &&
                                !isValid &&
                                !!errors.code
                              }
                            >
                              <Select
                                sx={{ borderRadius: 8 }}
                                name={"code"}
                                value={values.code}
                                onChange={(e) => {
                                  const { value } = e.target;
                                  handleChange(e);
                                  const find = countries.find((res) => {
                                    return res.COUNTRY_CODE === value;
                                  });
                                  if (find) {
                                    setFieldValue("name", find.COUNTRY);
                                    setFieldValue(
                                      "country_name_en",
                                      find.COUNTRY_ENGLISH
                                    );
                                    setFieldValue(
                                      "monetaryUnit",
                                      find.CURRENCY_CODE
                                    );
                                  }
                                }}
                                style={{
                                  borderRadius: "5px",
                                  boxShadow:
                                    "0px 0px 5px rgba(31, 65, 115, 0.1)",
                                  border: "1px solid #EBEBEB",
                                  width: "100%",
                                }}
                                variant="outlined"
                              >
                                {countries.map((item, index) => (
                                  <MenuItem
                                    value={item.COUNTRY_CODE}
                                    key={index}
                                  >
                                    {`${item.COUNTRY_CODE} (${item.COUNTRY})`}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Box>
                        </Box>

                        <Box
                          my={2}
                          display={"flex"}
                          flexDirection={"column"}
                          gap={2.5}
                        >
                          <Box
                            display={"flex"}
                            flexDirection={"column"}
                            gap={2}
                          >
                            <label>
                              <Typography fontWeight={"bold"}>
                                화폐 단위
                              </Typography>
                            </label>
                            <FormControl
                              size="small"
                              sx={{
                                width: "100%",
                                m: 0,
                              }}
                              error={
                                submitCount > 0 &&
                                !isSubmitting &&
                                !isValid &&
                                !!errors.monetaryUnit
                              }
                            >
                              <Select
                                sx={{ borderRadius: 8 }}
                                name={"monetaryUnit"}
                                value={values.monetaryUnit}
                                onChange={handleChange}
                                style={{
                                  borderRadius: "5px",
                                  boxShadow:
                                    "0px 0px 5px rgba(31, 65, 115, 0.1)",
                                  border: "1px solid #EBEBEB",
                                  width: "100%",
                                }}
                                variant="outlined"
                              >
                                {countries
                                  .map((res) => res.CURRENCY_CODE)
                                  .filter((res) => res !== "-")
                                  .reduce((a, b) => {
                                    return a.includes(b) ? a : [...a, b];
                                  }, [])
                                  .sort()
                                  .map((item, index) => (
                                    <MenuItem value={item} key={index}>
                                      {item}
                                    </MenuItem>
                                  ))}
                              </Select>
                            </FormControl>
                          </Box>
                        </Box>

                        <Box
                          my={2}
                          display={"flex"}
                          flexDirection={"column"}
                          gap={2.5}
                        >
                          <Box
                            display={"flex"}
                            flexDirection={"column"}
                            gap={2}
                          >
                            <label>
                              <Typography fontWeight={"bold"}>
                                배송 타입
                              </Typography>
                            </label>
                            <FormControl
                              size="small"
                              sx={{
                                width: "100%",
                                m: 0,
                              }}
                              error={
                                submitCount > 0 &&
                                !isSubmitting &&
                                !isValid &&
                                !!errors.deliveryType
                              }
                            >
                              <Select
                                sx={{ borderRadius: 8 }}
                                name={"deliveryType"}
                                value={values.deliveryType}
                                onChange={(e) => {
                                  handleChange(e);
                                  setFieldValue("shippingTypesDetailsIds", []);
                                }}
                                style={{
                                  borderRadius: "5px",
                                  boxShadow:
                                    "0px 0px 5px rgba(31, 65, 115, 0.1)",
                                  border: "1px solid #EBEBEB",
                                  width: "100%",
                                }}
                                variant="outlined"
                              >
                                {deliveryTypes.map((item, index) => (
                                  <MenuItem value={item.id} key={index}>
                                    {item.deliveryType}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Box>

                          {values.deliveryType && (
                            <Box
                              display={"flex"}
                              flexDirection={"column"}
                              gap={2}
                            >
                              <label>
                                <Typography fontWeight={"bold"}>
                                  배송 종류
                                </Typography>
                              </label>

                              <Box
                                ml={1}
                                display={"flex"}
                                flexWrap={"wrap"}
                                gap={1}
                              >
                                {(() => {
                                  const find = deliveryTypes.find(
                                    (res) => res.id === values.deliveryType
                                  );

                                  if (find) {
                                    return find.details.map((res, index) => {
                                      return (
                                        <FormControlLabel
                                          key={index}
                                          control={
                                            <Checkbox
                                              value={res.id}
                                              onChange={({ target }) => {
                                                handleShippingTypes(
                                                  target,
                                                  values.shippingTypesDetailsIds,
                                                  index,
                                                  setFieldValue
                                                );
                                              }}
                                              name={`shippingTypesDetailsIds`}
                                              checked={
                                                Array.isArray(
                                                  values.shippingTypesDetailsIds
                                                )
                                                  ? values.shippingTypesDetailsIds.some(
                                                      (value) =>
                                                        value === res.id
                                                    )
                                                  : false
                                              }
                                            />
                                          }
                                          label={res.shippingType}
                                        />
                                      );
                                    });
                                  } else {
                                    return <></>;
                                  }
                                })()}
                              </Box>
                            </Box>
                          )}
                        </Box>
                        <Divider sx={{ marginBlock: "1rem" }} />
                        <Box
                          display={"flex"}
                          justifyContent={"flex-end"}
                          gap={2.5}
                        >
                          <CustomButton
                            onClick={onClose}
                            styles={{
                              background: "#F8F8FA",
                              color: "#000",
                              width: "auto",
                            }}
                          >
                            취소
                          </CustomButton>
                          <CustomButton
                            type={"submit"}
                            styles={{ width: "auto" }}
                          >
                            전송
                          </CustomButton>
                        </Box>
                      </Form>
                    );
                  }}
                </Formik>
              </Stack>
            </CustomCard>
          </Box>
        </Modal>
      );
    },
  });
}

export default ShippingCountriesModal;
