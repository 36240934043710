import { MoreHoriz } from "@mui/icons-material";
import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, memo, useState } from "react";
import CustomButton from "../../components/CustomButton";
import CustomInput, { SelectBox } from "../../components/CustomInput";
import CustomPopover from "../../components/CustomPopover";
import TableContainer from "../../components/TableContainer";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import BagDrawer from "./BagDrawer";
import ListHOC from "../../SharedComponents/ListHOC";
import BagManagementModal from "../../modals/BagManagementModal";
import { useDataProvider } from "react-admin";
import Toaster from "../../utils/Toaster";
import { onDropDownSearchChange } from "../../utils/onDropDownSearchChange";
import DropdownAndSearchInput from "../../components/DropdownAndSearchInput2";

import {
  generateSearchData,
  getQueryParams,
  handleChange,
  handleOtherSearch,
  useGetList,
  useDeleteManyWithIds,
  useDeleteManyBagsWithIds,
} from "../../utils/utils";
import DateRangePicker from "../../components/DateRangePicker";
import Moment from "moment";
import { Formik, Form } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import { gql } from "@apollo/client/core";
import ConfirmModal from "../../modals/ConfirmModal";
import CustomModal from "../../components/CustomModal";
import CustomInputField from "../../components/CustomInputField";
import styled from "styled-components";
import SentIcon from "../../Common/assets/Images/SentIcon";
import BagItems from "../../components/Bag/BagItems";
import CustomSelect from "../../components/CustomSelect";
import UpdateMasterModal from "../../modals/updateMasterModal";
import MoveBagModal from "../../modals/moveBagModal";

import Loader from "../../components/Loader";

import { useSearchParams } from "react-router-dom";

export default function ReceptionList(props) {
  return (
    <ListHOC _resource="RequestShipping" {...props}>
      <BagManagementContainer />
    </ListHOC>
  );
}

const BagManagementContainer = memo(
  ({ data: _Data, handleSetPerPage, perPage, handleSearch, ...hocProps }) => {
    const dataProvider = useDataProvider();
    const [data, setData] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [selected, setSelected] = useState(null);
    const [shippingServices, setShippingServices] = useState([]);
    const navigate = useNavigate();
    const [additionalFormDetails, setAdditionalFormDetails] = useState({
      BagList: [],
      ShippingCountries: [],
    });

    const [filterQuery, setFilterQuery] = useState(null);
    const [searchQuery, setSearchQuery] = useState(null);
    const [checkedList, setCheckedList] = useState([]);
    const [selectedDeleteId, setSelectedDeleteId] = useState(null);
    const { getData } = useGetList();
    const { state, search, pathname } = useLocation();
    const { isLoading, deleteMany } = useDeleteManyBagsWithIds();
    const [selectedBag, setSelectedBag] = useState(null);
    const [isMasterPackagingModalOpen, setIsMasterPackagingModalOpen] =
      useState(false);
    const [isMasterPackagingSentModalOpen, setIsMasterPackagingSentModalOpen] =
      useState(false);
    const [isMasterCreationModalOpen, setIsMasterCreationModalOpen] =
      useState(false);
    const [isAddBagModalOpen, setIsAddBagModalOpen] = useState(false);
    const [isChangeBagNameModalOpen, setIsChangeBagNameModalOpen] =
      useState(false);
    const [confirmModal, setConfirmModal] = useState(false);
    const [currentScreen, setCurrentScreen] = useState("main");
    const [openDeleteConfirmModal, setOpenDeleteConfirmModal] = useState(false);

    const [searchParams] = useSearchParams();

    // loader for bag in advance
    const [bagLoader, setBagLoader] = useState(
      searchParams.get("bag") ? true : false
    );

    // console.log({ pathname });
    useEffect(() => {
      dataProvider
        .getList("Bag", {})
        .then(({ data }) => {
          setAdditionalFormDetails((prevState) => {
            const tempObj = { ...prevState };
            tempObj["BagList"] = data;
            return tempObj;
          });
        })
        .catch((error) => {
          Toaster("error", error.message);
        });
    }, []);

    // useEffect(() => {
    //   dataProvider
    //     .getList("MasterManagement", {})
    //     .then(({ data }) => {
    //       setAdditionalFormDetails((prevState) => {
    //         const tempObj = { ...prevState };
    //         tempObj["MasterManagement"] = data;
    //         return tempObj;
    //       });
    //     })
    //     .catch((error) => {
    //       Toaster("error", error.message);
    //     });

    //   dataProvider
    //     .getList("ShippingCountries", {})
    //     .then(({ data }) => {
    //       setAdditionalFormDetails((prevState) => {
    //         const tempObj = { ...prevState };
    //         tempObj["ShippingCountries"] = data;
    //         return tempObj;
    //       });
    //     })
    //     .catch((error) => {
    //       Toaster("error", error.message);
    //     });

    //   (async () => {
    //     try {
    //       const data = await getData("ShippingTypeDetails");
    //       setShippingServices(data);
    //     } catch (e) {
    //       Toaster("error", e.message);
    //     }
    //   })();
    // }, []);

    const handleOpenModal = () => {
      setOpenModal(true);
    };
    const handelCloseUpdateModal = () => {
      setIsMasterPackagingModalOpen(false);
    };

    const handleClose = (reason) => {
      if ((reason = "confirm")) {
        setConfirmModal(false);
      }
      if (reason === "update") {
        setSelected(null);
      }
      setOpenModal(false);
    };

    useEffect(() => {
      setData(_Data);
    }, [_Data]);

    const doSearch = useCallback(() => {
      let additionalValues = {};

      if (state) {
        // console.log({ state });
        const { bag_id } = state;
        const queryParams = getQueryParams(search);
        const bag = queryParams.get("bag");
        // console.log({ searchParams });

        additionalValues = {
          ...additionalValues,
          ...(bag === bag_id && { masterBagName: bag }),
        };
        // console.log({ additionalValues });
      }

      generateSearchData(handleSearch, filterQuery, additionalValues);
      // setFilterQuery({});
    }, [filterQuery]);

    const doSearch2 = useCallback(() => {
      if (searchQuery) {
        const queryParams = getQueryParams(search);
        const bag = queryParams.get("bag");
        generateSearchData(handleSearch, {
          ...searchQuery,
          ...(bag && { masterBagName: bag }),
        });
      }
      // setFilterQuery({});
    }, [searchQuery]);

    useEffect(() => {
      doSearch();
    }, [filterQuery]);

    useEffect(() => {
      doSearch2();
    }, [searchQuery]);

    useEffect(() => {
      if (data?.length > 0 && bagLoader) {
        setFilterQuery({
          ...filterQuery,
          masterBagName: searchParams.get("bag"),
        });

        setTimeout(() => {
          setBagLoader(false);
        }, 1000);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const handleSuccess = (data, reason) => {
      if (reason === "update") {
        setData((prevState) => {
          const temp = [...prevState];
          const index = prevState.findIndex((res) => res.id === data.id);
          if (index > -1) {
            temp[index] = data;
          }
          return temp;
        });
      } else {
        setData((prevState) => {
          const temp = [...prevState];
          temp.push(data);
          return temp;
        });
      }
    };

    const handleSelect = (item) => {
      setSelected(item);
    };
    const handleCheck = ({ target }) => {
      const { value, checked } = target;
      setCheckedList((prevState) => {
        const temp = [...prevState];
        if (checked) {
          temp.push(+value);
          return temp;
        } else {
          return temp.filter((res) => res !== +value);
        }
      });
    };

    const handleDeleteSuccess = () => {
      setCheckedList([]);
      setOpenDeleteConfirmModal(false);
      if (selectedDeleteId) setSelectedDeleteId(null);

      generateSearchData(handleSearch, filterQuery);
    };

    const handleDelete = () => {
      if (!(selectedDeleteId || checkedList.length)) {
        return Toaster(
          "error",
          "You have to select atleast one bag to perform this action."
        );
      }
      const deleteMutation = gql(`
      mutation deleteManyFromBag($ids:RequestShippingDeleteManyInput!){
				deleteManyFromBag(ids:$ids){
					id
				}
			}`);
      deleteMany({
        ids: selectedDeleteId ? [selectedDeleteId] : checkedList,
        setData,
        deleteMutation,
        onSuccess: handleDeleteSuccess,
        responseKey: "deleteManyRequestShipping",
      });
    };

    const handleMenuNameActionClick = (popup, setState) => {
      popup.close();
      setState(true);
    };

    const getFreshData = () => {
      dataProvider
        .getList("RequestShipping", {})
        .then(({ data }) => {
          setData(data);
        })
        .catch((error) => {
          Toaster("error", error.message);
        });
    };
    return !bagLoader ? (
      <>
        <BagItems
          handleSetPerPage={handleSetPerPage}
          perPage={perPage}
          hocProps={hocProps}
          data={data}
          setOpenDeleteConfirmModal={setOpenDeleteConfirmModal}
          setSelectedDeleteId={setSelectedDeleteId}
          handleCheck={handleCheck}
          handleSelect={handleSelect}
          checkedList={checkedList}
          setCheckedList={setCheckedList}
          filterQuery={searchQuery}
          setFilterQuery={setSearchQuery}
          setIsMasterPackagingModalOpen={setIsMasterPackagingModalOpen}
        />

        {openDeleteConfirmModal && (
          <ConfirmModal
            isLoading={isLoading}
            isOpen={openDeleteConfirmModal}
            onClose={() => setOpenDeleteConfirmModal(false)}
            onConfirm={handleDelete}
            title={"삭제"}
            description={"BAG에서 해당 접수건을 삭제 하시겠습니까?"}
          />
        )}

        {isMasterPackagingModalOpen && (
          <MoveBagModal
            additionalFormDetails={additionalFormDetails}
            selected={selected}
            bagData={data}
            onClose={setIsMasterPackagingModalOpen}
            setIsMasterPackagingModalOpen={setIsMasterPackagingModalOpen}
            setCheckedList={setCheckedList}
            isOpen={isMasterPackagingModalOpen}
            checkedList={checkedList}
            setData={setData}
            selectedDeleteId={selectedDeleteId}
            freshData={doSearch}
            onSuccess={() => {
              setIsMasterPackagingModalOpen(false);
              navigate("/Bag");
            }}
            {...hocProps}
          />
        )}
      </>
    ) : (
      <div style={{ height: "90vh" }}>
        <Loader />
      </div>
    );
  }
);

const ModalLayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  max-height: 70vh;
  overflow-y: auto;
  overflow-x: hidden;
`;

const ModalBodyText = styled(Typography)`
  font-family: "Spoqa Han Sans Neo";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  /* identical to box height */

  text-align: center;

  color: #3e3e3e;
`;
