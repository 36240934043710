import { Grid, MenuItem, Pagination, Stack } from "@mui/material";
import React, { useState } from "react";
import Moment from "moment";
import { Form, Formik } from "formik";
import { ReceptionHistoryTable } from "../../../components/ReceptionHistoryTable";
import CustomButton from "../../../components/CustomButton";
import DateRangePicker from "../../../components/DateRangePicker";
import CalendarIcon from "../../../Common/assets/Images/CalendarIcon";
import { SearchBox } from "../../../components/Searchbox";
import { SelectBox } from "../../../components/CustomInput";
import { APIClient } from "../../../utils/apiGraphql";
import { DOWNLOAD_EXCEL_OVERSEA_DELIVERY_PARCEL } from "../../../utils/deliveryParcels";
import useExcelToPdf from "./utils";
import { blobToBase64 } from "../../../utils/utils";
import useFetch from "../../../hooks/useFetch";
import image from "../../../Common/assets/Images/overseasInfo.png";
import moment from "moment";

export default function DeliveryParcels() {
	const [sortingOrder, setSortingOrder] = useState("ascending");
	const [filterQuery, setFilterQuery] = useState(null);
	const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));
	const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
	const [filterType, setFilterType] = useState("접수일");
	const [excelToPdf] = useExcelToPdf();
	const [data, setData] = useState([
		[1, "엠비케이 엠비케이투", 10, 25, 32],
		[2, "두두키", 20, 30, 34],
		[3, "누리하우스", 30, 35, 36],
		[4, "엠비케이", 40, 40, 38],
		[5, "두두키", 50, 45, 40],
		[6, "누리하우스", 60, 50, 42],
		[7, "엠비케이", 70, 55, 44],
		[8, "두두키", 80, 60, 46],
		[9, "누리하우스", 90, 65, 48],
		[10, "엠비케이", 100, 70, 50],
	]);

	const ImageBlob = useFetch(image);
	// console.log({ ImageBlob });

	const changeSorting = () => {
		if (sortingOrder === "ascending") {
			setData((prevData) => prevData.sort((a, b) => b[2] - a[2]));
			setSortingOrder("descending");
		} else {
			setData((prevData) => prevData.sort((a, b) => a[2] - b[2]));
			setSortingOrder("ascending");
		}
	};

	const downloadExcel = async () => {
		try {
			const { data } = await APIClient.mutate({
				mutation: DOWNLOAD_EXCEL_OVERSEA_DELIVERY_PARCEL,
				variables: {
					filterType: filterType,
					startDate: startDate,
					endDate: endDate,
				},
			});
			const res = await blobToBase64(ImageBlob);
			const { createOverseaReceipt } = data;
			// console.log(createOverseaReceipt);
			if ({ createOverseaReceipt } && res) {
				const outputFilename = `hello.xls`;

				// window.open(createOverseaReceipt, "_blank");

				// console.log({ res });
				excelToPdf(createOverseaReceipt, res);
			}
		} catch (e) {
			console.log("GQ EXCEL FILE error");
			console.log(e.message);
		}
	};

	const onDateChange = (obj) => {
		let { createdAt } = obj;

		console.log("created At");
		console.log(createdAt);

		if (createdAt) {
			createdAt = createdAt.split(" - ");
			setStartDate(createdAt[0]);
			setEndDate(createdAt[1]);
		}
	};

	const handleFilterChange = (e) => {
		setFilterType(e.target.value);
	};

	return (
		<Stack spacing={"35px"}>
			<Grid container justifyContent={"space-between"}>
				<Grid item xs={12}>
					<Grid
						container
						style={{
							display: "flex",
							justifyContent: "start",
						}}
					>
						<Grid item xs={1} style={{ margin: "auto 10px" }}>
							<SelectBox
								defaultValue={filterType}
								onChange={handleFilterChange}
							>
								<MenuItem value="출고일">출고일</MenuItem>
								<MenuItem value="접수일">접수일</MenuItem>
							</SelectBox>
						</Grid>

						<Grid item xs={3} style={{ margin: "auto 10px" }}>
							<Formik
								enableReinitialize
								initialValues={
									filterQuery || {
										createdAt: `${Moment().format(
											"YYYY-MM-DD"
										)} - ${Moment().format("YYYY-MM-DD")}`,
									}
								}
								onSubmit={(values) => {}}
							>
								{({ values, handleSubmit, setFieldValue, resetForm }) => {
									return (
										<Form onSubmit={handleSubmit}>
											<div>
												<DateRangePicker
													showLabel={false}
													name={"createdAt"}
													value={startDate + " - " + endDate}
													// setFieldValue={setFieldValue}
													handleChange={onDateChange}
													iconEnd={<CalendarIcon />}
												/>
											</div>
										</Form>
									);
								}}
							</Formik>
						</Grid>

						<Grid item xs={0.5} sx={{ ml: "9px" }}>
							<CustomButton
								style={{ height: "38px", borderRadius: 10, width: "200px" }}
								onClick={downloadExcel}
							>
								해외 소포 수령증 다운로드
							</CustomButton>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Stack>
	);
}
