import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Font,
  Image,
} from "@react-pdf/renderer";
import { Fragment } from "react";

export default function MultipleLabels({ data = [] }) {
  console.log(data)
  return (
    <Document>
      {data.map((Element, index) => {
        return <Fragment key={index}>{Element}</Fragment>;
      })}
    </Document>
  );
}

const styles = StyleSheet.create({
  page: {
    textTransform: "uppercase",
    border: "2px solid #000",
    fontFamily: "SpoqaHanSans",
    height: "100%",
  },
});
