import { Box } from "@mui/material";
import { useState } from "react";
import { APIClient } from "../utils/apiGraphql";
import Typography from "@mui/material/Typography";
import CustomInputLabel from "./CustomInputLabel";
import Toaster from "../utils/Toaster";
import { gql } from "@apollo/client/core";
import Button from "@mui/material/Button";

import { CircularProgress } from "@mui/material";
import CustomInput from "./CustomInput";
import { useDaumPostcodePopup } from "react-daum-postcode";
import styled from "styled-components";
import _ from "lodash";

const Step3 = ({ changeForm, submittedValues }) => {
	const [email, setEmail] = useState("");
	const [taxEmail, setTaxEmail] = useState("");
	const [websiteAddress, setWebsiteAddress] = useState("");
	const [businessRegistration, setBusinessRegistration] = useState("");
	const [companyNameEnglish, setCompanyNameEnglish] = useState("");
	const [detailAddress, setDetailAddress] = useState("");
	const [errors, setErrors] = useState([]);
	const [daumAddress, setDaumAddress] = useState({});

	const [loading, setLoading] = useState(false);

	const evaluateFields = () => {
		let filteredErrors = [];
		email === "" && filteredErrors.push("email");
		taxEmail === "" && filteredErrors.push("taxEmail");
		businessRegistration === "" && filteredErrors.push("businessRegistration");
		companyNameEnglish === "" && filteredErrors.push("companyNameEnglish");
		detailAddress === "" && filteredErrors.push("detailAddress");
		returnAddress === "" && filteredErrors.push("returnAddress");
		setErrors(filteredErrors);
		console.log(filteredErrors);
		return filteredErrors.length;
	};

	const [returnAddress, setReturnAddress] = useState("");
	const daumOpen = useDaumPostcodePopup(
		"https://t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js"
	);
	const hasDaumAddress = !_.isEmpty(daumAddress);

	const handleAddress = (data) => {
		const address = {
			address1:
				data.sido +
				" " +
				data.sigungu +
				" " +
				data.bname1 +
				" " +
				data.roadname,
			postalcode: data.zonecode,
			englishAddressState: data.sidoEnglish,
			englishAddressCity: data.sigunguEnglish,
			englishAddressStreet: data.roadnameEnglish + ", " + data.bname1English,
		};
		setDaumAddress(address);
	};

	const findDaumAddress = () => {
		daumOpen({ onComplete: handleAddress });
	};

	const createUser = async (values, file) => {
		const Mutation = gql(`
      mutation signUp($signup: UserCreateInput!, $business_registration: Upload!) {
        signUp(signup: $signup, business_registration: $business_registration) {
          id
        }
      }
    `);
		try {
			await APIClient.mutate({
				mutation: Mutation,
				variables: {
					business_registration: file,
					signup: {
						...values,
					},
				},
			});
			Toaster("success", `계정이 성공적으로 생성되었습니다.`);
			changeForm("CompletedSignUp");
		} catch (e) {
			Toaster("error", e.message);
		}
	};

	return (
		<Box>
			<Box>
				<Box
					display={"flex"}
					flexDirection={"column"}
					sx={{
						marginTop: "30px",
						justifyContent: "left",
						marginLeft: 0,
						paddingLeft: 0,
						width: "100%",
						height: "350px",
						overflow: "auto",
					}}
				>
					<CustomInputLabel name="이메일 주소" required={true} />
					<CustomInput
						type={"email"}
						onChange={(event) => setEmail(event.target.value)}
						values={email}
						placeholder="이메일 주소를 입력하세요."
						sx={{
							width: "850px",
							border: errors.includes("email") ? "2px solid red" : "none",
						}}
					/>
					<br />
					<CustomInputLabel
						name="담당자 이메일 및 세금계산서 이메일"
						required={true}
					/>
					<CustomInput
						type={"email"}
						onChange={(event) => setTaxEmail(event.target.value)}
						values={taxEmail}
						placeholder="세금계산서 이메일을 입력해주세요."
						sx={{
							width: "850px",
							border: errors.includes("taxEmail") ? "2px solid red" : "none",
						}}
					/>
					<br />
					<CustomInputLabel name="홈페이지 주소" />
					<CustomInput
						type={"text"}
						onChange={(event) => setWebsiteAddress(event.target.value)}
						values={websiteAddress}
						placeholder="홈페이지 주소를 입력하세요."
						sx={{
							width: "850px",
						}}
					/>
					<br />
					<CustomInputLabel name="사업자등록증" required={true} />
					<CustomInput
						type={"file"}
						onChange={(event) => setBusinessRegistration(event.target.files[0])}
						values={businessRegistration}
						placeholder="사업자 등록"
						sx={{
							width: "850px",
							border: errors.includes("businessRegistration")
								? "2px solid red"
								: "none",
						}}
						iconStart={
							<img
								style={{ width: "20px", cursor: "pointer" }}
								src="/assets/images/btn-ic.png"
								alt="img"
							></img>
						}
					/>
					<br />

					<CustomInputLabel name="영문업체명" required={true} />
					<CustomInput
						type={"text"}
						onChange={(event) => setCompanyNameEnglish(event.target.value)}
						values={companyNameEnglish}
						placeholder="영문업체명을 입력하세요."
						sx={{
							width: "850px",
							border: errors.includes("companyNameEnglish")
								? "2px solid red"
								: "none",
						}}
					/>
					<br />
					<Typography
						onClick={findDaumAddress}
						sx={{
							textAlign: "start",
							marginTop: "20px",
							color: "#427DF3",
							fontWeight: "600",
							cursor: "pointer",
						}}
					>
						{hasDaumAddress ? "주소 변경" : "주소 검색"}{" "}
						<span style={{ color: "red" }}>*</span>
					</Typography>
					{hasDaumAddress && (
						<>
							<AddressTable>
								<AddressLabel>({daumAddress.postalcode})</AddressLabel>
								<AddressLabel>주소(한글)</AddressLabel>
								<AddressText>{daumAddress.address1}</AddressText>
								<AddressLabel>주소(영문)</AddressLabel>
								<AddressText>
									{daumAddress.englishAddressStreet}
									{daumAddress.englishAddressCity},{" "}
									{daumAddress.englishAddressState}
								</AddressText>
							</AddressTable>
							<br />
							<CustomInputLabel name="상세 영문주소" required={true} />
							<CustomInput
								type={"text"}
								onChange={(event) => setDetailAddress(event.target.value)}
								values={returnAddress}
								placeholder="반송받을 전체 주소를 입력하세요."
								sx={{
									width: "850px",
									border: errors.includes("detailAddress")
										? "2px solid red"
										: "none",
								}}
							/>
							<br />
							<CustomInputLabel name="반송받을 주소" required={true} />
							<CustomInput
								type={"text"}
								onChange={(event) => setReturnAddress(event.target.value)}
								values={returnAddress}
								placeholder="반송받을 전체 주소를 입력하세요."
								sx={{
									width: "850px",
									border: errors.includes("returnAddress")
										? "2px solid red"
										: "none",
								}}
							/>
						</>
					)}
					<br />
				</Box>
				<Box
					xs={12}
					md={6}
					sm={12}
					display="flex"
					flexDirection="row"
					justifyContent="center"
					sx={{ width: "100%" }}
				>
					<Button
						variant="contained"
						onClick={() => {
							if (!evaluateFields()) {
								createUser(
									{
										...submittedValues,
										email,
										e_tax_invoice_email: taxEmail,
										website_address: websiteAddress,
										company_name_en: companyNameEnglish,
										english_address_state: daumAddress.englishAddressState,
										english_address_city: daumAddress.englishAddressCity,
										english_address_street: daumAddress.englishAddressStreet,
										english_address_detail: detailAddress,
										english_address_full_address:
											detailAddress +
											", " +
											daumAddress.englishAddressStreet +
											", " +
											daumAddress.englishAddressCity +
											", " +
											daumAddress.englishAddressState,
										english_address_zip_code: daumAddress.postalcode,
										accepted_term_and_conditions: true,
										address_1: daumAddress.address1,
										return_address: returnAddress,
									},
									businessRegistration
								);
							}
						}}
						sx={{
							paddingTop: "12px",
							paddingBottom: "12px",
							marginTop: "51px",
							backgroundColor: "#427DF3",
							borderRadius: "8px",
							width: "350px",
							height: "60px",
						}}
					>
						{loading ? (
							<CircularProgress size={20} color="primary" />
						) : (
							<Typography
								component="p"
								variant="p"
								sx={{
									fontSize: "16px",
									fontWeight: "700",
									lineHeight: "24px",
									color: "#ffffff",
								}}
							>
								등록
							</Typography>
						)}
					</Button>
				</Box>
			</Box>
			<Box sx={{}}>
				<Typography
					component="p"
					variant="button"
					onClick={() => {
						changeForm("LoginForm");
					}}
					sx={{
						fontSize: "16px",
						fontWeight: "600",
						lineHeight: "20px",
						color: "#427DF3",
						marginTop: "24px",
						textAlign: "center",
					}}
				>
					{"로그인으로 돌아가기"}
				</Typography>
			</Box>
		</Box>
	);
};

const AddressTable = styled.div`
	display: flex;
	flex-direction: column;
`;
const AddressLabel = styled.p`
	margin: 0;
	font-weight: 400;
	font-family: "Roboto", "Helvetica", "Arial", sans-serif;
	font-size: 1.25rem;
	line-height: 1.6;
	letter-spacing: 0.0075em;
	color: #2e2e2e;
	font-size: 16px;
	font-weight: 600;
	line-height: 21px;
	margin-bottom: 8px;
	padding: 0;
	margin: 0;
`;
const AddressText = styled.p`
	padding: 0;
	margin: 0;
`;
export default Step3;
