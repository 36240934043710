import { Divider, Grid, MenuItem, Stack, Typography } from "@mui/material";
import React, { useCallback, useEffect, memo, useState } from "react";
import CustomButton from "../../components/CustomButton";
import { SelectBox } from "../../components/CustomInput";
import CustomPopover from "../../components/CustomPopover";
import TableContainer from "../../components/TableContainer";
import Loader from "../../components/Loader";

// import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
// import BagDrawer from "./BagDrawer";
import ListHOC from "../../SharedComponents/ListHOC";
// import BagManagementModal from "../../modals/BagManagementModal";
// import { useDataProvider } from "react-admin";
// import Toaster from "../../utils/Toaster";
// import { onDropDownSearchChange } from "../../utils/onDropDownSearchChange";
// import DropdownAndSearchInput from "../../components/DropdownAndSearchInput2";
// import DocumentIcon from "../../Common/assets/Images/DocIcon";
import { APIClient } from "../../utils/apiGraphql";

import {
	generateSearchData,
	// getQueryParams,
	// handleChange,
	handleOtherSearch,
	// useGetList,
	// useDeleteManyWithIds,
} from "../../utils/utils";
import DateRangePicker from "../../components/DateRangePicker";
import Moment from "moment";
import { Formik, Form } from "formik";
import { useLocation } from "react-router-dom";
import { gql } from "@apollo/client/core";
// import ConfirmModal from "../../modals/ConfirmModal";
// import CustomModal from "../../components/CustomModal";
// import CustomInputField from "../../components/CustomInputField";
import styled from "styled-components";
// import SentIcon from "../../Common/assets/Images/SentIcon";
// import BagItems from "../../components/Bag/BagItems";
// import CustomSelect from "../../components/CustomSelect";
// import UpdateMasterModal from "../../modals/updateMasterModal";

// import fro upper filter
import { getAllCountries } from "./RatesManagament/queries";
import { SearchBox } from "../../components/Searchbox";

import { useSearchParams } from "react-router-dom";

export default function Bag(props) {
	return (
		<ListHOC _resource="RequestShipping" {...props} showSort={true}>
			<BagManagementContainer />
		</ListHOC>
	);
}

const BagManagementContainer = memo(
	({
		data: _Data,
		handleSetPerPage,
		perPage,
		handleSearch,
		setActiveScreen,
		screens,
		handleSort,
		countTotal,
		page,
		...hocProps
	}) => {
		// const dataProvider = useDataProvider();
		const [data, setData] = useState([]);
		// allShippingTypes data
		const [shippingData, setShippingData] = useState([]);
		// search params
		const [searchParams] = useSearchParams();

		// loader for company name in advance
		const [countryLoader, setcountryLoader] = useState(
			searchParams.get("company_name_kr") ? true : false
		);

		// all filter query
		const [filterQuery, setFilterQuery] = useState({
			receiptDateType: "1",
			createdAt: "",
			registrationType: "1",
			registrationValue: "",
			user123company_name_kr: "",
		});

		// sort
		const [sort, setSort] = useState({
			sortOrder: { sortOrder: "asc" },
			sortField: { sortField: "trackingNumber" },
		});

		const filterOnchange = (e) => {
			setFilterQuery({ ...filterQuery, [e.target.name]: e.target.value });
		};

		const { receiptDateType, createdAt, registrationType } = filterQuery;

		// calender date range change
		const rangeChange = (value) => {
			setFilterQuery({
				...filterQuery,
				createdAt: value.createdAt,
			});
		};

		// on search from filter
		// company serach submit
		const companySubmit = (e) => {
			e.preventDefault();
			setFilterQuery({
				...filterQuery,
				registrationValue: e.target.querySelector("input[type=text]").value,
			});
		};

		useEffect(() => {
			setData(_Data);
		}, [_Data]);

		const doSearch = useCallback(() => {
			let filterCopy = { ...filterQuery };
			let { createdAt, receiptDateType, registrationValue, registrationType } =
				filterCopy;

			// for receipt data | process date
			if (createdAt) {
				if (receiptDateType === "1") {
					// console.log("fsf");
					const [startDate, endDate] = createdAt.split(" - ");

					filterCopy.processReleaseDate = {
						gte: Moment(startDate).utc(true).toDate(),
						lte: Moment(endDate).utc(true).toDate(),
					};
					delete filterCopy.createdAt;
				}
			}

			// search by orderNumber and shippingNumber

			if (registrationValue) {
				if (registrationType !== "1") {
					filterCopy.productInformation.orderNo1 = registrationValue;
					filterCopy.productInformation.orderNo2 = registrationValue;
				} else {
					filterCopy.trackingNumber = registrationValue;
				}
			}

			// trash clean
			filterCopy.receiptDateType = "";
			filterCopy.registrationType = "";
			filterCopy.registrationValue = "";
			filterCopy.sort = "";

			console.log(sort);
			handleSort({
				sortOrder: sort.sortOrder,
				sortField: sort.sortField,
			});
			// filter is doing from here
			generateSearchData(handleSearch, filterCopy);
		}, [filterQuery]);

		useEffect(() => {
			doSearch();
		}, [filterQuery]);

		// countries data
		const [countries, setCountries] = useState([]);
		// for once need to call

		useEffect(() => {
			// get countries data
			getAllCountries().then((res) => {
				setCountries(res.data.allShippingCountries);
			});

			// setShippingData
			getAllShippingType().then((res) => {
				setShippingData(res.data.allShippingTypeDetails);
			});

			return () => {};
		}, []);
		// change country name came form urls

		// modal form submit
		const onSubmit = (values) => {
			console.log("form submit", values);
			handleOtherSearch(values, setFilterQuery);
			// for sort value
			switch (values?.sort) {
				case 1:
					setSort({
						sortOrder: { sortOrder: "asc" },
						sortField: { sortField: "trackingNumber" },
					});
					break;
				case 2:
					setSort({
						sortOrder: { sortOrder: "desc" },
						sortField: { sortField: "trackingNumber" },
					});
					break;
				case 3:
					setSort({
						sortOrder: { sortOrder: "asc" },
						sortField: { sortField: "productInformation95orderNo1" },
					});
					break;
				case 4:
					setSort({
						sortOrder: { sortOrder: "desc" },
						sortField: { sortField: "productInformation95orderNo1" },
					});
					break;
				default:
					setSort({});
				// code block
			}
		};

		useEffect(() => {
			if (data.length > 0 && countryLoader) {
				setFilterQuery({
					...filterQuery,
					user123company_name_kr: searchParams.get("company_name_kr"),
				});
				setTimeout(() => {
					setcountryLoader(false);
				}, 1000);
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [data]);

		let initialFil = {
			sort: "",
			status: "",
			payeeInformation123shippingCountryId: "",
			payeeInformation123shippingTypeId: "",
		};

		const calculateTotal = (products) => {
			let total = 0;
			products.forEach((item) => {
				let product = parseInt(item.quantity) * parseInt(item.unitPrice);
				total = total + product;
			});
			return total;
		};

		const resetFormInitial = () => {
			setFilterQuery({
				receiptDateType: "1",
				createdAt: "",
				registrationType: "1",
				registrationValue: "",
				user123company_name_kr: searchParams.get("company_name_kr"),
			});
		};

		return !countryLoader ? (
			<Stack spacing={"35px"}>
				{/* top filter */}
				<Grid container justifyContent={"space-between"}>
					<Grid item xs={6}>
						<Grid
							container
							style={{
								display: "flex",
							}}
						>
							{/* model open */}
							<Grid item xs={2} style={{ margin: "auto 10px" }}>
								<CustomPopover label="필터">
									{({ close }) => {
										return (
											<Formik
												enableReinitialize
												initialValues={initialFil}
												onSubmit={(values) => {
													onSubmit(values);
													close();
												}}
											>
												{({
													values,
													handleSubmit,
													resetForm,
													handleChange,
												}) => {
													// console.log("inside val", values);
													return (
														<Form onSubmit={handleSubmit}>
															<Stack spacing={"20px"} width={370} p="20px">
																{/* country */}
																<div className={"custom-component-container"}>
																	<div
																		style={{
																			display: "flex",
																			alignItems: "center",
																			width: "140px",
																		}}
																	>
																		<Typography
																			sx={{ padding: "1px" }}
																			fontSize={"1rem"}
																			component={"span"}
																		>
																			배송국가
																		</Typography>
																	</div>

																	<div
																		style={{
																			width: "100%",
																			display: "flex",
																			alignItems: "center",
																		}}
																	>
																		<div
																			style={{
																				minHeight: 60,
																				display: "flex",
																				alignItems: "center",
																				width: "100%",
																			}}
																		>
																			<SelectBox
																				// value={
																				// 	values.payeeInformation123shippingCountryId
																				// }
																				onChange={handleChange}
																				name="payeeInformation123shippingCountryId"
																				defaultValue={"default"}
																			>
																				<MenuItem
																					value="default"
																					hidden
																					disabled
																				>
																					배송국가
																				</MenuItem>
																				{countries.map((country) => (
																					<MenuItem
																						key={country.code}
																						value={country.id}
																					>
																						{country.name}
																					</MenuItem>
																				))}
																			</SelectBox>
																		</div>
																	</div>
																</div>

																{/* shipping types */}
																<div className={"custom-component-container"}>
																	<div
																		style={{
																			display: "flex",
																			alignItems: "center",
																			width: "140px",
																		}}
																	>
																		<Typography
																			sx={{ padding: "1px" }}
																			fontSize={"1rem"}
																			component={"span"}
																		>
																			배송종류
																		</Typography>
																	</div>
																	<div
																		style={{
																			width: "100%",
																			display: "flex",
																			alignItems: "center",
																		}}
																	>
																		<div
																			style={{
																				minHeight: 60,
																				// padding: "10px",
																				display: "flex",
																				alignItems: "center",
																				width: "100%",
																			}}
																		>
																			<SelectBox
																				// value={
																				// 	values.payeeInformation123shippingTypeId
																				// }
																				onChange={handleChange}
																				name="payeeInformation123shippingTypeId"
																				defaultValue={"default"}
																			>
																				<MenuItem
																					value="default"
																					hidden
																					disabled
																				>
																					배송종류
																				</MenuItem>
																				{/* map */}
																				{shippingData.map((data) => (
																					<MenuItem
																						value={data.id}
																						key={data.id}
																					>
																						{data.shippingType}
																					</MenuItem>
																				))}
																			</SelectBox>
																		</div>
																	</div>
																</div>

																{/* delivery status */}
																<div className={"custom-component-container"}>
																	<div
																		style={{
																			display: "flex",
																			alignItems: "center",
																			width: "140px",
																		}}
																	>
																		<Typography
																			sx={{ padding: "1px" }}
																			fontSize={"1rem"}
																			component={"span"}
																		>
																			배송상태
																		</Typography>
																	</div>

																	<div
																		style={{
																			width: "100%",
																			display: "flex",
																			alignItems: "center",
																		}}
																	>
																		<div
																			style={{
																				minHeight: 60,
																				// padding: "10px",
																				display: "flex",
																				alignItems: "center",
																				width: "100%",
																			}}
																		>
																			<SelectBox
																				// value={values.status}
																				defaultValue={"default"}
																				onChange={handleChange}
																				name="status"
																			>
																				<MenuItem
																					value="default"
																					disabled
																					hidden
																				>
																					배송상태
																				</MenuItem>
																				<MenuItem value="waiting_warehousing">
																					입고대기
																				</MenuItem>
																				<MenuItem value="warehousing_completed">
																					입고완료
																				</MenuItem>
																				<MenuItem value="waiting_delivery">
																					출고대기
																				</MenuItem>
																				<MenuItem value="started_delivery">
																					출고완료
																				</MenuItem>
																				<MenuItem value="completed_delivery">
																					배송완료
																				</MenuItem>
																			</SelectBox>
																		</div>
																	</div>
																</div>

																{/* order */}
																<div className={"custom-component-container"}>
																	<div
																		style={{
																			display: "flex",
																			alignItems: "center",
																			width: "140px",
																		}}
																	>
																		<Typography
																			sx={{ padding: "1px" }}
																			fontSize={"1rem"}
																			component={"span"}
																		>
																			정렬
																		</Typography>
																	</div>

																	<div
																		style={{
																			width: "100%",
																			display: "flex",
																			alignItems: "center",
																		}}
																	>
																		<div
																			style={{
																				minHeight: 60,
																				display: "flex",
																				alignItems: "center",
																				width: "100%",
																			}}
																		>
																			<SelectBox
																				name="sort"
																				defaultValue={"default"}
																				// value={values.sort}
																				onChange={handleChange}
																			>
																				<MenuItem value="default" disabled>
																					정렬
																				</MenuItem>
																				<MenuItem value={1}>
																					접수번호 오름차순
																				</MenuItem>
																				<MenuItem value={2}>
																					접수번호 내림차순
																				</MenuItem>
																				<MenuItem value={3}>
																					주문번호 오름차순
																				</MenuItem>
																				<MenuItem value={4}>
																					주문번호 내림차순
																				</MenuItem>
																			</SelectBox>
																		</div>
																	</div>
																</div>

																<Divider />

																<div
																	style={{
																		display: "flex",
																		justifyContent: "flex-end",
																		gap: "20px",
																	}}
																>
																	<CustomButton
																		onClick={close}
																		styles={{
																			backgroundColor: "#F8F8FA",
																			padding: "5px",
																			color: "#000",
																			width: "auto",
																		}}
																	>
																		취소
																	</CustomButton>
																	<CustomButton
																		variant={"outlined"}
																		onClick={resetFormInitial}
																		styles={{
																			width: "auto",
																			padding: "8px",
																		}}
																	>
																		필터 초기화
																	</CustomButton>
																	<CustomButton
																		type={"submit"}
																		onClick={handleSubmit}
																		styles={{ padding: "5px", width: "auto" }}
																	>
																		전송
																	</CustomButton>
																</div>
															</Stack>
														</Form>
													);
												}}
											</Formik>
										);
									}}
								</CustomPopover>
							</Grid>

							{/* receiptDateType */}
							<Grid item xs={2} style={{ margin: "auto 10px" }}>
								<SelectBox
									value={receiptDateType}
									onChange={filterOnchange}
									name="receiptDateType"
								>
									<MenuItem value="1">출고일 </MenuItem>
									<MenuItem value="2">접수일</MenuItem>
								</SelectBox>
							</Grid>

							{/* receiptDateTypeValues */}
							<Grid item xs={4}>
								<DateRangePicker
									showLabel={false}
									name={"createdAt"}
									value={createdAt}
									handleChange={rangeChange}
								/>
							</Grid>
						</Grid>
					</Grid>
					<Grid
						item
						xs={6}
						style={{
							display: "flex",
							justifyContent: "end",
						}}
					>
						<Grid item xs={2} style={{ margin: "auto 10px" }}>
							<SelectBox
								value={registrationType}
								defaultValue={1}
								name="registrationType"
								onChange={filterOnchange}
							>
								<MenuItem value="1">접수번호</MenuItem>
								<MenuItem value="2">주문번호</MenuItem>
							</SelectBox>
						</Grid>

						<Grid item xs={5} style={{ margin: "auto 10px" }}>
							<form
								style={{ display: "flex", height: "100%", gap: "15px" }}
								onSubmit={companySubmit}
							>
								<SearchBox
									onChange={() => {}}
									showSearchIcon={false}
									inputFieldPlaceHolder="."
								/>
								<CustomButton
									style={{ height: "38px", borderRadius: 10 }}
									type="submit"
								>
									검색
								</CustomButton>
							</form>
						</Grid>
					</Grid>
				</Grid>

				<TableContainer
					{...hocProps}
					headers={[
						<CustomHeading>특이사항(메모)</CustomHeading>,
						<CustomHeading>번호</CustomHeading>,
						<CustomHeading>운송장 출력</CustomHeading>,
						<CustomHeading>타입</CustomHeading>,
						<CustomHeading>고객사 이름</CustomHeading>,
						<CustomHeading padding="0 35px">배송국가</CustomHeading>,
						<CustomHeading>배송종류</CustomHeading>,
						<CustomHeading>관세지불방식</CustomHeading>,
						<CustomHeading padding="0 35px">상태</CustomHeading>,
						<CustomHeading padding="0 35px">접수일</CustomHeading>,
						<CustomHeading padding="0 35px">출고일</CustomHeading>,
						<CustomHeading>접수번호</CustomHeading>,
						<CustomHeading>배송번호</CustomHeading>,
						<CustomHeading>적용무게(KG)</CustomHeading>,
						<CustomHeading>Master No</CustomHeading>,
						<CustomHeading>SUB MASTER</CustomHeading>,
						<CustomHeading>주문번호1</CustomHeading>,
						<CustomHeading>주문번호2</CustomHeading>,
						<CustomHeading>배송회사</CustomHeading>,
						<CustomHeading>CUSTOM NUMBER</CustomHeading>,
						<CustomHeading>출발국가 택배번호</CustomHeading>,
						<CustomHeading>배송비</CustomHeading>,
						<CustomHeading>택배 추가요금</CustomHeading>,
						<CustomHeading>화폐</CustomHeading>,
						<CustomHeading>가로(Cm)</CustomHeading>,
						<CustomHeading>세로(Cm)</CustomHeading>,
						<CustomHeading>높이(Cm)</CustomHeading>,
						<CustomHeading>Box 수량</CustomHeading>,
						<CustomHeading>실제무게(KG)</CustomHeading>,
						<CustomHeading>부피무게(KG)</CustomHeading>,
						<CustomHeading>적용무게(KG)</CustomHeading>,
						<CustomHeading>주민번호/통관번호/CPF</CustomHeading>,
						<CustomHeading>IOSS</CustomHeading>,
						<CustomHeading>EORI</CustomHeading>,
						<CustomHeading>VOEC</CustomHeading>,
						<CustomHeading>상품 바코드</CustomHeading>,
						<CustomHeading>상품목록</CustomHeading>,
						<CustomHeading>상품수량</CustomHeading>,
						<CustomHeading>총 상품금액</CustomHeading>,
						<CustomHeading>화폐단위</CustomHeading>,
						<CustomHeading>발송인 이름</CustomHeading>,
						<CustomHeading>발송인 전화번호</CustomHeading>,
						<CustomHeading>발송인 주소</CustomHeading>,
						<CustomHeading>수취인 이름</CustomHeading>,
						<CustomHeading>수취인 이름 (YOMIGANA)</CustomHeading>,
						<CustomHeading>수취인 휴대폰 번호</CustomHeading>,
						<CustomHeading>수취인 전화번호</CustomHeading>,
						<CustomHeading>수취인 이메일</CustomHeading>,
						<CustomHeading>수취인 우편번호</CustomHeading>,
						<CustomHeading>Colony</CustomHeading>,
						<CustomHeading>수취인 주소1</CustomHeading>,
						<CustomHeading>수취인 주소2</CustomHeading>,
						<CustomHeading padding="0 35px">배송메세지</CustomHeading>,
						<CustomHeading>HSCODE</CustomHeading>,
						<CustomHeading>
							상품종류(1:Commercial,2:Gift,3:ReturnedGood,4:Documents,5:Other)
						</CustomHeading>,
						<CustomHeading>통관구분</CustomHeading>,
						<CustomHeading>수출신고번호</CustomHeading>,
						<CustomHeading>판매 site</CustomHeading>,
						<CustomHeading>사용자 데이터 1</CustomHeading>,
						<CustomHeading>사용자 데이터 2</CustomHeading>,
						<CustomHeading>사용자 데이터 3</CustomHeading>,
						<CustomHeading>배송추적</CustomHeading>,
					]}
					data={
						data.length > 0
							? data.map((item, index) => [
									item.miscellaneousInformation?.coupon &&
										`고객사 쿠폰 적용(${item.miscellaneousInformation.coupon})`,
									countTotal - ((page - 1) * 10 + index),
									item.waybillPrintStatus ? "O" : "X",
									"일반",
									item.user?.company_name_kr,
									item.payeeInformation?.shippingCountry.code,
									item.shippingType?.shippingType,
									item.bag?.howToPayCustomDuties,
									item.status === "waiting_warehousing"
										? "입고대기"
										: item.status === "warehousing_completed"
										? "입고완료"
										: item.status === "waiting_delivery"
										? "출고대기"
										: item.status === "started_delivery"
										? "출고완료"
										: item.status === "completed_delivery" && "배송완료",
									Moment(item.senderInformation?.createdAt).format(
										"DD-MM-YYYY"
									),
									item.processReleaseDate &&
										Moment(item.processReleaseDate).format("DD-MM-YYYY"),
									item.trackingNumber,
									item.shippingNumber,
									item.miscellaneousInformation?.applicableWeight,
									item.masterNo,
									"",
									item.productInformation?.orderNo1,
									item.productInformation?.orderNo2,
									"",
									"",
									item.productInformation?.departureCountryCourierNumber,
									item.deliveryFee,
									item.additionalFee,
									"KRW",
									item.miscellaneousInformation?.horizontal,
									item.miscellaneousInformation?.portrait,
									item.miscellaneousInformation?.height,
									item.miscellaneousInformation?.boxQuantity,
									item.miscellaneousInformation?.actualWeight,
									item.miscellaneousInformation?.bulkWeight,
									item.miscellaneousInformation?.applicableWeight,
									item.payeeInformation?.ssn,
									item.payeeInformation?.euioss,
									item.payeeInformation?.ukeori,
									item.payeeInformation?.ssn,
									item.productInformation?.productInformation?.map(
										(subItem, subIndex) => {
											return (
												<div>
													<p>
														{subItem.barcode}{" "}
														{subItem.barcode !== "" &&
															subIndex <
																item.productInformation.productInformation
																	.length -
																	1 &&
															","}
													</p>
												</div>
											);
										}
									),
									item.productInformation?.productInformation?.map(
										(subItem, subIndex) => {
											return (
												<div>
													<p>
														{subItem.productName}{" "}
														{subItem.productName !== "" &&
															subIndex <
																item.productInformation.productInformation
																	.length -
																	1 &&
															","}
													</p>
												</div>
											);
										}
									),

									// item.productInformation?.productInformation?.forEach(
									// 	(item, index) => {
									// 		let sum = 0;
									// 		console.log("sum", sum);
									// 		if (index >= 0) {
									// 			console.log("inside");
									// 			sum = sum + item.quantity;
									// 		}
									// 		if (
									// 			item.productInformation?.productInformation.length >
									// 			index - 1
									// 		)
									// 			console.log("sum", sum);
									// 	}
									// ),
									item?.productInformation?.productInformation?.reduce(
										(previousValue, currentValue) =>
											previousValue + currentValue.quantity,
										0
									),
									calculateTotal(item.productInformation?.productInformation),
									item.payeeInformation?.shippingCountry.monetaryUnit,
									item.senderInformation?.name,
									<a href={"tel:" + item.senderInformation?.phoneNumber}>
										{item.senderInformation?.phoneNumber}
									</a>,
									item.senderInformation?.address,
									item.payeeInformation?.name,
									"",
									<a href={"tel:" + item.senderInformation?.phoneNumber1}>
										{item.payeeInformation?.phoneNumber1}
									</a>,
									item.payeeInformation?.phoneNumber2,
									<a href={"mailto:" + item.senderInformation?.mail}>
										{item.payeeInformation?.email}
									</a>,
									item.payeeInformation?.zipCode,
									item.payeeInformation?.mexicoColony,
									item.payeeInformation?.streetAddress1,
									item.payeeInformation?.streetAddress2,
									item.miscellaneousInformation?.shippingMessage,
									item.productInformation?.productInformation?.map(
										(subItem, subIndex) => {
											return (
												<>
													{item.hscode}
													{subItem.hscode !== "" &&
														subIndex <
															item.productInformation?.productInformation
																?.length -
																1 &&
														","}
												</>
											);
										}
									),
									item.miscellaneousInformation?.productType,
									"",
									item.miscellaneousInformation?.exportTypeDeclaration,
									item.productInformation?.productInformation?.map(
										(subItem, subIndex) => {
											return (
												<div>
													<p>
														{subItem.purchaseURL}{" "}
														{subItem.purchaseURL !== "" &&
															subIndex <
																item.productInformation?.productInformation
																	?.length -
																	1 &&
															","}
													</p>
												</div>
											);
										}
									),
									item.miscellaneousInformation?.userData,
									item.miscellaneousInformation?.userData2,
									item.miscellaneousInformation?.userData3,
									<CustomButton
										onClick={() => {
											setActiveScreen(screens.DELIVERY_TRACKING);
										}}
										styles={{
											backgroundColor: "#0055FF",
											color: "#FFFFFF",
											borderRadius: 4,
											height: "25px",
											width: "120px",
										}}
									>
										배송추적
									</CustomButton>,
							  ])
							: []
					}
				/>
				{/* 
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: 30,
          }}
        >
          <Pagination
            count={count}
            page={page}
            onChange={handlePageChange}
            color="primary"
            shape="rounded"
          />
        </div> */}
			</Stack>
		) : (
			<div style={{ height: "90vh" }}>
				<Loader />
			</div>
		);
	}
);

// const ModalLayout = styled.div`
//   display: flex;
//   flex-direction: column;
//   gap: 30px;
//   max-height: 70vh;
//   overflow-y: auto;
//   overflow-x: hidden;
// `;

// const ModalBodyText = styled(Typography)`
//   font-family: "Spoqa Han Sans Neo";
//   font-style: normal;
//   font-weight: 500;
//   font-size: 18px;
//   line-height: 26px;
//   /* identical to box height */

//   text-align: center;

//   color: #3e3e3e;
// `;

const CustomHeading = styled.div`
	padding: ${(props) => (props.padding ? props.padding : "0 30px")};
`;

// gql
// data without filter
const getAllShippingType = async () => {
	const queryAllShippingFeeHistory = gql(`query {
    allShippingTypeDetails{
      id
      shippingType
    }
  }
    `);

	try {
		const res = await APIClient.query({
			query: queryAllShippingFeeHistory,
		});
		return res;
	} catch (e) {
		return e;
	}
};
