import React, { useState } from "react";
import { useDataProvider, useResourceContext } from "react-admin";
import Toaster from "../utils/Toaster";

function CreateHOC({
	children: Children,
	_resource,
	handleClose = () => {},
	...rest
}) {
	const dataProvider = useDataProvider();
	const resource = useResourceContext();
	const [backendErrors, setBackendErrors] = useState(false);
	const [errorMessage, setErrorMessage] = useState(false);

	const handleSubmit = async (values,formikUtils, disableAutoClose= true) => {
		try {
			return new Promise((resolve, reject) => {
				dataProvider
					.create(_resource || resource, {
						data: values,
						// ...values
					})
					.then(({ data }) => {
						Toaster("Success", "새로운 항목이 성공적으로 생성되었습니다.");
						if (formikUtils){
							const {resetForm}= formikUtils
							resetForm();
						}
						if (disableAutoClose) handleClose();
						resolve({ data, reason: "create" });
					})
					.catch((e) => {
						console.log(e.message);
						console.log("not completed");
						setBackendErrors(true);
						setErrorMessage(e.message);
						reject(e);
					});
			});
		} catch (e) {
			Toaster("error", e.message);
		}
	};
	return (
		<Children
			handleSubmit={handleSubmit}
			handleClose={handleClose}
			backendErrors={backendErrors}
			setBackendErrors={setBackendErrors}
			errorMessage={errorMessage}
			{...rest}
		/>
	);
}

export default CreateHOC;
