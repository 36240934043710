import * as React from "react";
import { useState } from "react";
import {
  Box,
  Checkbox,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { CircularProgress } from "@mui/material";
import {
  AGREEMENT_FIRST,
  AGREEMENT_SECOND,
  AGREEMENT_THIRD,
} from "./TermsAndConditionsConstants";

export default function TermsAndConditions({ changeForm }) {
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false);
  const [checked1, setChecked1] = useState(false);
  const [checked2, setChecked2] = useState(false);
  const [checked3, setChecked3] = useState(false);
  const [checked4, setChecked4] = useState(false);
  const [expanded1, setExpanded1] = useState(false);
  const [expanded2, setExpanded2] = useState(false);
  const [expanded3, setExpanded3] = useState(false);

  return (
    <Box
      sx={{
        width: "500px",
      }}
    >
      <Typography
        sx={{
          fontSize: "24px",
          fontWeight: "700",
          lineHeight: "32px",
          color: "#427DF3",
          marginTop: "24px",
          textAlign: "center",
        }}
      >
        회원가입 약관동의
      </Typography>
      <Checkbox
        onClick={() => {
          setChecked1(!checked1);
          if (!checked1) {
            setChecked2(true);
            setChecked3(true);
            setChecked4(true);
          } else {
            setChecked2(false);
            setChecked3(false);
            setChecked4(false);
          }
        }}
        checked={checked1}
      />
      <Typography
        sx={{
          display: "inline-block",
          fontWeight: "700",
          mt: 3,
        }}
      >
        회원가입 약관동의
      </Typography>
      <Divider />
      <Accordion
        sx={{
          border: "none",
          boxShadow: "none",
          mt: 2,
        }}
        expanded={expanded1}
        onClick={() => {
          setExpanded1(!expanded1);
          setExpanded2(false);
          setExpanded3(false);
        }}
      >
        <AccordionSummary
          sx={{ padding: "0px" }}
          expandIcon={<ExpandMoreIcon sx={{ color: "#427DF3" }} />}
        >
          <Typography>
            <Checkbox
              onClick={() => setChecked2(!checked2)}
              checked={checked2}
            />
            <Typography
              sx={{
                display: "inline-block",
                fontWeight: "700",
              }}
            >
              쉽터 이용약관 동의
              <Typography
                sx={{
                  display: "inline-block",
                  fontWeight: "700",
                  color: "#427DF3",
                }}
              >
                (필수)
              </Typography>
            </Typography>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography
            sx={{
              backgroundColor: "#F7FAFC",
              color: "#797B89",
              padding: "20px",
              borderRadius: "5px",
              height: "150px",
              whiteSpace: "pre-line",
              overflowY: "scroll",
              overflowX: "hidden",
              "&::-webkit-scrollbar": { width: "5px" },
              "&::-webkit-scrollbar-track": {
                boxShadow: "inset 0 0 5px #d6d6cd",
                borderRadius: "5px",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "#427DF3",
                borderRadius: "5px",
              },
            }}
          >
            {AGREEMENT_FIRST}
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        sx={{
          border: "none",
          boxShadow: "none",
          mt: 2,
        }}
        expanded={expanded2}
        onClick={() => {
          setExpanded1(false);
          setExpanded2(!expanded2);
          setExpanded3(false);
        }}
      >
        <AccordionSummary
          sx={{ padding: "0px" }}
          expandIcon={<ExpandMoreIcon sx={{ color: "#427DF3" }} />}
        >
          <Typography>
            <Checkbox
              onClick={() => setChecked3(!checked3)}
              checked={checked3}
            />
            <Typography
              sx={{
                display: "inline-block",
                fontWeight: "700",
              }}
            >
              개인정보 수집 및 이용 동의
              <Typography
                sx={{
                  display: "inline-block",
                  fontWeight: "700",
                  color: "#427DF3",
                }}
              >
                (필수)
              </Typography>
            </Typography>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography
            sx={{
              backgroundColor: "#F7FAFC",
              color: "#797B89",
              padding: "20px",
              borderRadius: "5px",
              height: "150px",
              whiteSpace: "pre-line",
              overflowY: "scroll",
              overflowX: "hidden",
              "&::-webkit-scrollbar": { width: "5px" },
              "&::-webkit-scrollbar-track": {
                boxShadow: "inset 0 0 5px #d6d6cd",
                borderRadius: "5px",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "#427DF3",
                borderRadius: "5px",
              },
            }}
          >
            {AGREEMENT_SECOND}
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        sx={{
          border: "none",
          boxShadow: "none",
          mt: 2,
        }}
        expanded={expanded3}
        onClick={() => {
          setExpanded1(false);
          setExpanded2(false);
          setExpanded3(!expanded3);
        }}
      >
        <AccordionSummary
          sx={{ padding: "0px" }}
          expandIcon={<ExpandMoreIcon sx={{ color: "#427DF3" }} />}
        >
          <Typography>
            <Checkbox
              onClick={() => setChecked4(!checked4)}
              checked={checked4}
            />
            <Typography
              sx={{
                display: "inline-block",
                fontWeight: "700",
              }}
            >
              전자금융거래 이용악관 동의
              <Typography
                sx={{
                  display: "inline-block",
                  fontWeight: "700",
                  color: "#427DF3",
                }}
              >
                (필수)
              </Typography>
            </Typography>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography
            sx={{
              backgroundColor: "#F7FAFC",
              color: "#797B89",
              padding: "20px",
              borderRadius: "5px",
              height: "150px",
              whiteSpace: "pre-line",
              overflowY: "scroll",
              overflowX: "hidden",
              "&::-webkit-scrollbar": { width: "5px" },
              "&::-webkit-scrollbar-track": {
                boxShadow: "inset 0 0 5px #d6d6cd",
                borderRadius: "5px",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "#427DF3",
                borderRadius: "5px",
              },
            }}
          >
            {AGREEMENT_THIRD}
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Box
        xs={12}
        md={6}
        sm={12}
        display="flex"
        flexDirection="row"
        justifyContent="center"
        sx={{ width: "100%" }}
      >
        <Button
          variant="contained"
          onClick={() => changeForm("SignUpForm")}
          disabled={!checked2 || !checked3 || !checked4}
          sx={{
            paddingTop: "15px",
            paddingBottom: "15px",
            marginTop: "51px",
            backgroundColor: "#427DF3",
            borderRadius: "8px",
            width: "500px",
            height: "64px",
          }}
        >
          {loading ? (
            <CircularProgress size={20} color="primary" />
          ) : (
            <Typography
              component="p"
              variant="p"
              sx={{
                fontSize: "16px",
                fontWeight: "700",
                lineHeight: "24px",
                color: "#ffffff",
              }}
            >
              다음
            </Typography>
          )}
        </Button>
      </Box>
    </Box>
  );
}
