import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		fontWeight: "bold",
		backgroundColor: "#F8F8FA",
		color: "rgba(0, 0, 0, 0.6)",
	},
	[`&.${tableCellClasses.body}`]: {
		backgroundColor: "white",
		fontSize: 14,
	},
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
	"&:nth-of-type(odd)": {
		backgroundColor: theme.palette.action.hover,
	},
	// hide last border
	"&:last-child td, &:last-child th": {
		border: 0,
	},
}));

function createData(
	number,
	companyName,
	depositAmount,
	withdrawalAmount,
	balance,
	memo,
	depositProcessingDate,
	shippingNumber,
	contents
) {
	return {
		number,
		companyName,
		depositAmount,
		withdrawalAmount,
		balance,
		memo,
		depositProcessingDate,
		shippingNumber,
		contents,
	};
}

const rows = [
	createData(
		100,
		"엠비케이 엠비케이투",
		"",
		"-9,800",
		0,
		"",
		"2022-07-19 17:43:55",
		"TESTUS3248966",
		"(AUS000026258KR) 출고"
	),
	createData(
		100,
		"엠비케이 엠비케이투",
		"",
		"-9,800",
		0,
		"",
		"2022-07-19 17:43:55",
		"TESTUS3248966",
		"(AUS000026258KR) 출고"
	),
	createData(
		100,
		"엠비케이 엠비케이투",
		"",
		"-9,800",
		0,
		"",
		"2022-07-19 17:43:55",
		"TESTUS3248966",
		"(AUS000026258KR) 출고"
	),
	createData(
		100,
		"엠비케이 엠비케이투",
		"",
		"-9,800",
		0,
		"",
		"2022-07-19 17:43:55",
		"TESTUS3248966",
		"(AUS000026258KR) 출고"
	),
	createData(
		100,
		"엠비케이 엠비케이투",
		"",
		"-9,800",
		0,
		"",
		"2022-07-19 17:43:55",
		"TESTUS3248966",
		"(AUS000026258KR) 출고"
	),
	createData(
		100,
		"엠비케이 엠비케이투",
		"",
		"-9,800",
		0,
		"",
		"2022-07-19 17:43:55",
		"TESTUS3248966",
		"(AUS000026258KR) 출고"
	),
	createData(
		100,
		"엠비케이 엠비케이투",
		"",
		"-9,800",
		0,
		"",
		"2022-07-19 17:43:55",
		"TESTUS3248966",
		"(AUS000026258KR) 출고"
	),
	createData(
		100,
		"엠비케이 엠비케이투",
		"",
		"-9,800",
		0,
		"",
		"2022-07-19 17:43:55",
		"TESTUS3248966",
		"(AUS000026258KR) 출고"
	),
	createData(
		100,
		"엠비케이 엠비케이투",
		"",
		"-9,800",
		0,
		"",
		"2022-07-19 17:43:55",
		"TESTUS3248966",
		"(AUS000026258KR) 출고"
	),
	createData(
		100,
		"엠비케이 엠비케이투",
		"",
		"-9,800",
		0,
		"",
		"2022-07-19 17:43:55",
		"TESTUS3248966",
		"(AUS000026258KR) 출고"
	),
	createData(
		100,
		"엠비케이 엠비케이투",
		"",
		"-9,800",
		0,
		"",
		"2022-07-19 17:43:55",
		"TESTUS3248966",
		"(AUS000026258KR) 출고"
	),
	createData(
		100,
		"엠비케이 엠비케이투",
		"",
		"-9,800",
		0,
		"",
		"2022-07-19 17:43:55",
		"TESTUS3248966",
		"(AUS000026258KR) 출고"
	),
	createData(
		100,
		"엠비케이 엠비케이투",
		"",
		"-9,800",
		0,
		"",
		"2022-07-19 17:43:55",
		"TESTUS3248966",
		"(AUS000026258KR) 출고"
	),
	createData(
		100,
		"엠비케이 엠비케이투",
		"",
		"-9,800",
		0,
		"",
		"2022-07-19 17:43:55",
		"TESTUS3248966",
		"(AUS000026258KR) 출고"
	),
	createData(
		100,
		"엠비케이 엠비케이투",
		"",
		"-9,800",
		0,
		"",
		"2022-07-19 17:43:55",
		"TESTUS3248966",
		"(AUS000026258KR) 출고"
	),
];

export const DepositHistoryTable = () => {
	return (
		<TableContainer component={Paper}>
			<Table
				sx={{ minWidth: 700, border: "1px solid rgba(224, 224, 224, 1)" }}
				aria-label="customized table"
			>
				<TableHead>
					<TableRow>
						<StyledTableCell align="center">No.</StyledTableCell>
						<StyledTableCell align="center">업체명</StyledTableCell>
						<StyledTableCell align="center">입금액</StyledTableCell>
						<StyledTableCell align="center">출금액</StyledTableCell>
						<StyledTableCell align="center">잔액</StyledTableCell>
						<StyledTableCell align="center">메모</StyledTableCell>
						<StyledTableCell align="center">입금처리날짜</StyledTableCell>
						<StyledTableCell align="center">배송번호</StyledTableCell>
						<StyledTableCell align="center">내용</StyledTableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{rows.map((row) => (
						<StyledTableRow key={row.date}>
							<StyledTableCell align="center">{row.number}</StyledTableCell>
							<StyledTableCell align="center">
								{row.companyName}
							</StyledTableCell>
							<StyledTableCell align="center">
								{row.depositAmount}
							</StyledTableCell>
							<StyledTableCell align="center" style={{ color: "#0055FF" }}>
								{row.withdrawalAmount}
							</StyledTableCell>
							<StyledTableCell align="center">{row.balance}</StyledTableCell>
							<StyledTableCell align="center">{row.memo}</StyledTableCell>
							<StyledTableCell align="center">
								{row.depositProcessingDate}
							</StyledTableCell>
							<StyledTableCell align="center">
								{row.shippingNumber}
							</StyledTableCell>
							<StyledTableCell align="center">{row.contents}</StyledTableCell>
						</StyledTableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
};
