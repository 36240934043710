import { gql } from "@apollo/client";
import { useCallback } from "react";
import { APIClient } from "../utils/apiGraphql";

const BARCODE_MUTATION = gql`
  mutation createBarcode($barcodeCreateInput: BarcodeCreateInput!) {
    createBarcode(barcodeCreateInput: $barcodeCreateInput) {
      url
    }
  }
`;

export const useBarCode = () => {
  const execute = useCallback(async (options) => {
    const response = await APIClient.mutate({
      mutation: BARCODE_MUTATION,
      variables: {
        barcodeCreateInput: options,
      },
    });
    return response?.data?.createBarcode;
  }, []);
  return execute;
};
