import * as React from "react";
import moment from "moment";

export default function ShippingRequestTable({ bags }) {

  const Counting = ({bags, index, i}) => {
    let count = 0;

    if(index !== 0) {
      for(let i=index-1; i >= 0; i--) {
        let bag = bags[i];
        count = count + bag.receptionLists.length;
      }
    }

    count = count + (i+1);

    return count;
  }

  return (
    <table>
      <tr>
        <td data-t="s">번호</td>
        <td data-t="s">접수번호</td>
        <td data-t="s">주문번호</td>
        <td data-t="s">배송번호</td>
        <td data-t="s">통관번호</td>
        <td data-t="s">업로드 날짜</td>
        <td data-t="s">배송국가</td>
        <td data-t="s">
          배송
          <br />
          타입
        </td>
        <td data-t="s">타입</td>
        <td data-t="s">
          수취인
          <br />
          이름
        </td>
        <td data-t="s">
          적용무게
          <br />
          (KG)
        </td>
        <td data-t="s">
          배송비
          <br />
          (KRW)
        </td>
        <td data-t="s">
          택배 추가요금
          <br />
          (KRW)
        </td>
        <td data-t="s">
          기타비용
          <br />
          (KRW)
        </td>
        <td data-t="s">
          실제무게
          <br />
          (KG)
        </td>
        <td data-t="s">
          부피무게
          <br />
          (KG)
        </td>
        <td data-t="s" data-v="총 상품금액">
          총 상품금액
        </td>
        <td data-t="s">
          화폐
          <br />
          단위
        </td>
        <td data-t="s" data-v="메모">
          메모
        </td>
        <td data-t="s">
          출고
          <br />
          (재고감소)
        </td>
        <td></td>
      </tr>
      {bags &&
        bags.map((bag, index) => {
          return (
            <React.Fragment key={index}>
              {bag?.receptionLists.map((request, i) => {
                return (
                  <tr key={index + "" + i}>
                    <td data-t="n">
                      <Counting bags={bags} index={index} i={i} />
                    </td>
                    <td data-t="s">{request.trackingNumber}</td>
                    <td data-t="n">{request.orderNo1}</td>
                    <td data-t="s">{request.shippingNumber}</td>
                    <td>
                      {
                        request?.miscellaneousInformation
                          ?.exportDeclarationNumber
                      }
                    </td>
                    <td data-t="n">
                      {moment(request?.createdAt).format("YYYYMMDD")}
                    </td>
                    <td data-t="s">
                      {request?.payeeInformation?.shippingCountry?.code}
                    </td>
                    <td data-t="s">
                      {request?.payeeInformation?.shippingType?.shippingType}
                    </td>
                    <td data-t="s">
                      일반
                    </td>
                    <td data-t="s">{request?.payeeInformation?.name}</td>
                    <td data-t="n">
                      {request?.miscellaneousInformation?.applicableWeight || 0}
                    </td>
                    <td data-t="s">{request?.deliveryFee || 0}</td>
                    <td data-t="n">{request?.additionalFee || 0}</td>
                    <td data-t="n">0</td>
                    <td data-t="n">
                      {request?.miscellaneousInformation?.actualWeight || 0}
                    </td>
                    <td data-t="n">
                      {request?.miscellaneousInformation?.bulkWeight || 0}
                    </td>
                    <td data-t="n">
                      {request?.productInformation?.totalPriceAmount || 0}
                    </td>
                    <td data-t="s">
                      {request?.productInformation?.currency}
                    </td>
                    <td></td>
                    <td>
                      {request?.releaseDate
                        ? moment(request?.releaseDate).format("YYYY-MM-DD")
                        : ""}
                    </td>
                    <td></td>
                  </tr>
                );
              })}
              }
            </React.Fragment>
          );
        })}
      <tr>
        <td data-t="s" data-v="합계">
          합계
        </td>
        <td data-v="" data-t="s"></td>
        <td data-v="" data-t="s"></td>
        <td data-v="" data-t="s"></td>
        <td data-v="" data-t="s"></td>
        <td data-v="" data-t="s"></td>
        <td data-v="" data-t="s"></td>
        <td data-v="" data-t="s"></td>
        <td data-v="" data-t="s"></td>
        <td data-v="" data-t="s"></td>
        <td data-t="n">
          {bags.reduce((a, bag) => {
            return (
              a +
              bag.receptionLists.reduce((value, request) => {
                return (
                  value + request?.miscellaneousInformation?.applicableWeight ||
                  0
                );
              }, 0)
            );
          }, 0)}
        </td>
        <td data-t="s">
          {bags.reduce((a, bag) => {
            return (
              a +
              bag.receptionLists.reduce((value, request) => {
                return value + request?.deliveryFee || 0;
              }, 0)
            );
          }, 0)}
        </td>
        <td data-t="n">
          {bags.reduce((a, bag) => {
            return (
              a +
              bag.receptionLists.reduce((value, request) => {
                return value + request?.additionalFee || 0;
              }, 0)
            );
          }, 0)}
        </td>
        <td data-t="n" data-v="0">
          0
        </td>
        <td data-t="s" data-v=""></td>
        <td data-t="s" data-v=""></td>
        <td data-v="" data-t="s"></td>
        <td data-v="" data-t="s"></td>
        <td data-v="" data-t="s"></td>
        <td data-v="" data-t="s"></td>
        <td data-v="" data-t="s"></td>
      </tr>
      <tr>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
    </table>
  );
}
