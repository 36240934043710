import { Grid, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import CustomButton from "../../../components/CustomButton";
import CustomInput from "../../../components/CustomInput";
import { Formik, Form } from "formik";
import Modals from "./Modals";
import styled from "styled-components";
import { gql } from "@apollo/client/core";
import { APIClient } from "../../../utils/apiGraphql";
import usePrintWaybill from "../../../resources/WaybillPrint";

function Relabeling({ ...hocProps }) {
  const [isOpen, setOpen] = useState(false);
  const [code, setCode] = useState(null);
  const { printRequestedLabels } = usePrintWaybill(null);

  const handleClose = () => {
    setOpen(false);
  };
  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const requestData = await APIClient.mutate({
        mutation,
        variables: {
          term: values.code,
        },
      });
      const { findOneRequestShipping } = requestData.data;
      await printRequestedLabels([[findOneRequestShipping]], null)

    } catch (e) {
      setCode(values.code);
      setOpen(true);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={{
          code: "",
        }}
        onSubmit={handleSubmit}
      >
        {({ values, handleChange, handleSubmit, isSubmitting }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <Stack spacing="40px" style={{ maxWidth: "1200px" }}>
                <Stack spacing={"20px"}>
                  <Typography style={{ fontWeight: "bold" }}>
                    리라벨링 처리
                  </Typography>
                  <Grid
                    container
                    style={{
                      borderRadius: "10px",
                      overflow: "hidden",
                      border: "1px solid #E5E5E5",
                    }}
                  >
                    <Grid item xs={12}>
                      <CustomInput
                        input={
                          <Grid
                            container
                            xs={12}
                            style={{ margin: 10 }}
                            columnGap={1}
                          >
                            <div style={{ width: "auto" }}>
                              <Input
                                name="code"
                                type="text"
                                value={values.code}
                                onChange={handleChange}
                                placeholder="H3 운송장번호를 입력해주세요 (바코드 스캔 시, 자동 입력)"
                              />
                            </div>
                            <CustomButton
                              width={80}
                              variant="outlined"
                              type="submit"
                              disabled={isSubmitting}
                            >
                              {isSubmitting ? "Loading.." : "확인"}
                            </CustomButton>
                          </Grid>
                        }
                        name="id"
                        label="리라벨링 처리"
                        padding="14px 20px 10px 20px"
                        labelWidth="22%"
                        style={{ margin: "10px" }}
                      />
                    </Grid>
                  </Grid>
                </Stack>
              </Stack>
            </Form>
          );
        }}
      </Formik>
      {isOpen ? (
        <Modals open={isOpen} handleClose={handleClose} data={code} />
      ) : null}
    </>
  );
}

export default Relabeling;

const Input = styled(CustomInput)`
  width: 600px !important;
`;

const mutation = gql(`mutation findOneRequestShipping($term:String!){
			findOneRequestShipping(term:$term){
          id
          bag{
            id
            name
            bagNumber
          }
          senderInformation {
            id
            name
            phoneNumber
            address
            requestShippingId
            deleted
            createdAt
            updatedAt
            __typename
          }
          shippingType{
            id
            shippingType
            apiType_{
              id
              name
            }
            
          }
          payeeInformation {
            id
            name
            shippingCountryId
            shippingCountry {
              name
              __typename
            }
            phoneNumber1
            phoneNumber2
            zipCode
            ssn
            ukeori
            euioss
            states
            cities
            streetAddress1
            streetAddress2
            email
            requestShippingId
            deleted
            createdAt
            updatedAt
            __typename
          }
          trackingNumber
          weightValidation
          printWaybillAfterWeightChange
          sampoongAmountCheck
          treatmentAfterWeightChange
          status
          weightValidation
          shippingNumber
          isDraft
          miscellaneousInformation {
            id
            shippingMessage
            horizontal
            portrait
            height
            actualWeight
            applicableWeight
            bulkWeight
            userData
            userDate2
            userData3
            boxQuantity
            productType
            exportDeclarationNumber
            requestShippingId
            deleted
            createdAt
            updatedAt
            __typename
          }
          productInformation {
            id
            orderNo1
            orderNo2
            departureCountryCourierNumber
            currency
            productInformation {
              id
              barcode
              productName
              quantity
              unitPrice
              brandName
              sku
              purchaseURL
              hscode
              deleted
              createdAt
              updatedAt
              __typename
            }
            totalPriceAmount
            requestShippingId
            deleted
            createdAt
            updatedAt
            __typename
          }
          bag {
            id
            weight
            __typename
          }
          user {
            id
            __typename
          }
          userId
          deleted
          createdAt
          updatedAt
          shippingCountry {
            id
            name
            code
            __typename
          }
          
          __typename
		}
	}
`);
