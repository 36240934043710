import {
	Checkbox,
	Divider,
	Grid,
	Link,
	MenuItem,
	Pagination,
	Stack,
} from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import CustomButton from "../../components/CustomButton";
import CustomInput, {
	SelectBox,
	TextFieldBox,
} from "../../components/CustomInput";
import CustomPopover from "../../components/CustomPopover";
import TableContainer from "../../components/TableContainer";
import ListHOC from "../../SharedComponents/ListHOC";
import { gql } from "@apollo/client/core";
import { APIClient } from "../../utils/apiGraphql";
import styled from "styled-components";
import moment from "moment";
import Toaster from "../../utils/Toaster";
import { useDelete } from "../../utils/utils";
import CustomSelect from "../../components/CustomSelect";

export default function ClientShippingSuspend() {
	return (
		<ListHOC _resource={"RequestShipping"} showFilter={{ isDraft: true }}>
			<ClientShippingHalt />
		</ListHOC>
	);
}

function ClientShippingHalt({
	countTotal,
	handleChangePage,
	handleChangeRowsPerPage,
	handleSetPerPage,
	page,
	perPage,
	...hocProps
}) {
	// const [page, setPage] = React.useState(1);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);
	const [filteredData, setFilteredData] = useState([]);
	const [idList, setIdList] = useState([]);
	const [checkedList, setCheckedList] = useState([]);
	const [selectedDeleteId, setSelectedDeleteId] = useState(null);
	const { isLoading, deleteOne } = useDelete();

	const [shippings, setShippings] = useState([]);

	const getShippings = async () => {
		const Query = gql(`
			query allRequestShippings($filter: RequestShippingFilterInput!){
				allRequestShippings(filter: $filter){
					id
				}
			}
      `);

		try {
			const {
				data: { allRequestShippings: shippings },
			} = await APIClient.query({
				query: Query,
				variables: {
					filter: { isDraft: true },
				},
			});
			setShippings(shippings);
		} catch (e) {
			console.log(e);
		}
	};

	useEffect(() => {
		getShippings();
	}, []);

	const [data, setData] = useState([]);

	// const handleChangePage = (event, newPage) => {
	// 	setPage(newPage);
	// };

	// const handleChangeRowsPerPage = (event) => {
	// 	setRowsPerPage(parseInt(event.target.value));
	// 	setPage(0);
	// };

	useEffect(() => {
		if (hocProps) {
			setData(hocProps.data);
			setFilteredData(hocProps.data);
		}
	}, [hocProps]);

	async function unHold() {
		const setRequestShippingUnHold = gql(`mutation
    setRequestShippingtoRelease($ids: RequestShippingUpdateIsDraftStatusInput!)
    {
      setRequestShippingtoRelease(ids:$ids)
      {
        id
      }
    }
    
	`);
		try {
			const res = await APIClient.mutate({
				mutation: setRequestShippingUnHold,
				variables: {
					ids: {
						ids: checkedList,
					},
				},
			});
			Toaster("success", "보류 취소가 완료되었습니다");
			setTimeout(() => {
				window.location.reload();
			}, 2000);
			// reset();
		} catch (e) {
			Toaster("error", e.message);
		}
	}

	useEffect(() => {
		const filtered = data.filter((item) => item.status);
		setFilteredData(filtered);
	}, []);

	const handleCheck = ({ target }) => {
		const { value, checked } = target;
		setCheckedList((prevState) => {
			const temp = [...prevState];
			if (checked) {
				temp.push(+value);
				return temp;
			} else {
				return temp.filter((res) => res !== +value);
			}
		});
	};

	// const handleDeleteSuccess = () => {
	// 	setCheckedList([]);
	// 	setOpenDeleteConfirmModal(false);
	// 	if (selectedDeleteId) setSelectedDeleteId(null);
	// };

	const handleDeleteSuccess = () => {
		setCheckedList([]);
		window.location.reload();
	};

	const handleDelete = () => {
		if (checkedList.length === 0) {
			return Toaster(
				"error",
				"You have to select atleast one row to perform this action."
			);
		}
		const deleteMutation = gql(`
    mutation deleteRequestShipping($input: RequestShippingDeleteInput!){
      deleteRequestShipping(deleteInput: $input){
        id
      }
    }`);

		if (checkedList.length === 1) {
			deleteOne({
				id: checkedList[0],
				setData,
				deleteMutation,
				onSuccess: handleDeleteSuccess,
				responseKey: "deleteRequestShipping",
			});
		}
	};

	return (
		<Stack spacing={"35px"}>
			<Grid container justifyContent={"space-between"} alignItems={"center"}>
				<Grid xs={12} alignItems={"center"} justifyContent={"flex-end"}>
					<Grid
						container
						columnGap={1}
						xs={12}
						alignItems={"center"}
						justifyContent={"flex-end"}
						sx={{ mr: "280px", ml: "auto" }}
					>
						<Grid item xs={1.5} padding="0">
							<CustomButton1 onClick={() => unHold()}>보류 취소</CustomButton1>
						</Grid>
						<Grid item xs={1} padding="0">
							<CustomButton1 onClick={() => handleDelete()}>삭제</CustomButton1>
						</Grid>
						<Grid item xs={2}>
							<CustomSelect
								value={String(perPage)}
								label=""
								onChange={({ target }) => {
									handleSetPerPage(target.value);
								}}
								options={["10", "20", "30", "40", "50", "100"]}
							></CustomSelect>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<div style={{ position: "relative" }}>
				<TableContainer
					// {...{ isLoading: false, requestCompleted: true }}
					{...hocProps}
					headers={[
						<>
							<Checkbox
								sx={{ mr: 3 }}
								checked={data.every((res, index) =>
									checkedList.includes(res.id)
								)}
								onChange={({ target }) => {
									if (target.checked) {
										setCheckedList(data.map((res, index) => res.id));
									} else {
										setCheckedList([]);
									}
								}}
							/>
						</>,
						"No.",
						"업로드 날짜",
						"배송번호",
						"접수번호",
						"주문번호",
						"수취인 이름",
						"수취인 전화번호",
						"수취인 주소",
					]}
					data={
						data &&
						data.map((item, index) => [
							<>
								<Checkbox
									sx={{ mr: 3.5 }}
									key={index}
									onChange={handleCheck}
									value={item.id}
									checked={checkedList.some((res) => res === item.id)}
								/>
							</>,
							countTotal - ((page - 1) * perPage + index),
							item.createdAt ? moment(item.createdAt).format("YYYY-MM-DD") : "",
							item.shippingNumber,
							item.trackingNumber,
							item.productInformation?.orderNo1,
							item.payeeInformation?.name,
							item.payeeInformation?.phoneNumber1,
							`${item.payeeInformation?.streetAddress1}, ${item.payeeInformation?.streetAddress2}, ${item.payeeInformation?.cities}, ${item.payeeInformation?.states}, ${item.payeeInformation?.shippingCountry?.name}`,
						])
					}
				/>
			</div>
			{/* <div
				style={{
					display: "flex",
					justifyContent: "center",
					marginTop: 30,
				}}
			>
				<Pagination count={10} color="primary" shape="rounded" />
			</div> */}
		</Stack>
	);
}

const CustomButton1 = styled(CustomButton)`
	border: 1px solid #ff000f !important;
	background-color: transparent !important;
	color: #ff000f !important;
	border-radius: 5px;
	align-self: center;
	min-width: 100%;
`;
