import { MoreHoriz } from "@mui/icons-material";
import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Stack,
  Typography,
  Select,
} from "@mui/material";
import React, { useCallback, useEffect, memo, useState, useRef } from "react";
import fx from "money";

import CustomButton from "../../components/CustomButton";
import CustomInput, { SelectBox } from "../../components/CustomInput";
import CustomPopover from "../../components/CustomPopover";
import TableContainer from "../../components/TableContainer";
import BagDrawer from "./BagDrawer";
import ListHOC from "../../SharedComponents/ListHOC";
import BagManagementModal from "../../modals/BagManagementModal";
import { useDataProvider, useUpdate } from "react-admin";
import Toaster from "../../utils/Toaster";
import { onDropDownSearchChange } from "../../utils/onDropDownSearchChange";
import DropdownAndSearchInput from "../../components/DropdownAndSearchInput2";
import {
  generateSearchData,
  getQueryParams,
  handleChange,
  handleOtherSearch,
  useDeleteMany,
  useGetList,
  useUpdateBagName,
} from "../../utils/utils";
import { useNavigate } from "react-router-dom";

import DateRangePicker from "../../components/DateRangePicker";
import Moment from "moment";
import { Formik, Form } from "formik";
import { useLocation } from "react-router-dom";
import { gql } from "@apollo/client/core";
import ConfirmModal from "../../modals/ConfirmModal";
import CustomModal from "../../components/CustomModal";
import CustomInputField from "../../components/CustomInputField";
import styled from "styled-components";
import SentIcon from "../../Common/assets/Images/SentIcon";
import BagItems from "../../components/Bag/BagItems";
import { BagManagementCustomRightClickMenu } from "../../components/CustomRightClickMenu";
import CustomSelect from "../../components/CustomSelect";
import UpdateMasterModal from "../../modals/updateMasterModal";
import MasterManagementModal from "../../modals/MasterManagementModalMoveBags";
import ErrorModal from "../../modals/ErrorModal";
import BagShippedErrorModal from "../../modals/BagShippedErrorModal";

export default function Bag(props) {
  return (
    <ListHOC {...props} _resource={"Bag"}>
      <BagManagementContainer />
    </ListHOC>
  );
}

const BagManagementContainer = memo(
  ({
    data: _Data,
    handleSetPerPage,
    page,
    perPage,
    handleSearch,
    handleSort,
    countTotal,
    ...hocProps
  }) => {
    const dataProvider = useDataProvider();
    const [data, setData] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [selected, setSelected] = useState([]);
    const [selected2, setSelected2] = useState(null);
    const [shippingServices, setShippingServices] = useState([]);
    const [sort, setSort] = useState("default");
    const [sortFilter, setSortFilter] = useState(null);
    const [additionalFormDetails, setAdditionalFormDetails] = useState({
      MasterManagement: [],
      ShippingCountries: [],
    });
    const navigate = useNavigate();

    const [filterQuery, setFilterQuery] = useState(null);
    const [checkedList, setCheckedList] = useState([]);
    const [selectedDeleteId, setSelectedDeleteId] = useState(null);
    const { getData } = useGetList();
    const { state, search } = useLocation();
    const { isLoading, deleteMany } = useDeleteMany();
    const { updateName } = useUpdateBagName();
    const [selectedBag, setSelectedBag] = useState(null);
    const [shippedBags, setShippedBags] = useState([]);
    const [dateChanged, setDateChanged] = useState(null);
    const [shippedBagsError, setShippedBagsError] = useState(false);

    const [isMasterPackagingModalOpen, setIsMasterPackagingModalOpen] =
      useState(false);
    const [isMasterPackagingSentModalOpen, setIsMasterPackagingSentModalOpen] =
      useState(false);
    const [isMasterCreationModalOpen, setIsMasterCreationModalOpen] =
      useState(false);
    const [isAddBagModalOpen, setIsAddBagModalOpen] = useState(false);
    const [isChangeBagNameModalOpen, setIsChangeBagNameModalOpen] =
      useState(false);
    const [confirmModal, setConfirmModal] = useState(false);
    const [openBagError, setOpenBagError] = useState(false);
    const [bagName, setBagName] = useState("");
    const [currentScreen, setCurrentScreen] = useState("main");
    const [productQuantity, setProductQuantity] = useState(null);

    useEffect(() => {
      dataProvider
        .getList("MasterManagement", {})
        .then(({ data }) => {
          // console.log("here");
          setAdditionalFormDetails((prevState) => {
            const tempObj = { ...prevState };
            tempObj["MasterManagement"] = data;
            return tempObj;
          });
        })
        .catch((error) => {
          Toaster("error", error.message);
        });

      dataProvider
        .getList("ShippingCountries", {})
        .then(({ data }) => {
          setAdditionalFormDetails((prevState) => {
            const tempObj = { ...prevState };
            tempObj["ShippingCountries"] = data;
            return tempObj;
          });
        })
        .catch((error) => {
          Toaster("error", error.message);
        });

      (async () => {
        try {
          const data = await getData("ShippingTypeDetails");
          setShippingServices(data);
        } catch (e) {
          Toaster("error", e.message);
        }
      })();
    }, []);

    const handleOpenModal = () => {
      setOpenModal(true);
    };
    const handleRedirect = (url, id) => {
      window.open(`${url}?bag=${id}`, { state: { bag_id: id } });
    };

    const handleClose = (reason) => {
      if ((reason = "confirm")) {
        setConfirmModal(false);
      }
      if (reason === "update") {
        setSelected2(null);
        setSelected([]);
      }
      setOpenModal(false);
    };
    const handelCloseUpdateModal = () => {
      setIsMasterPackagingModalOpen(false);
    };
    const handelCloseMasterModal = () => {
      setIsMasterCreationModalOpen(false);
    };
    useEffect(() => {
      setData(_Data);
    }, [_Data]);

    const doSearch = useCallback(() => {
      let additionalValues = {};

      if (state) {
        const { master_id } = state;
        const queryParams = getQueryParams(search);
        const master = queryParams.get("master");
        additionalValues = {
          ...additionalValues,
          ...(master == master_id && { master_id }),
        };
      }
      if (filterQuery) {
        if (filterQuery?.createdAt) {
          const splitted = filterQuery?.createdAt?.split(" ");
          // console.log(splitted);
          if (splitted[0] === splitted[2]) {
            if (splitted[0] === Moment().format("YYYY-MM-DD")) {
              let tempFilter = {};

              Object?.entries(filterQuery).forEach(([key, value]) => {
                if (key !== "createdAt") tempFilter[key] = value;
              });
              // console.log({ tempFilter });
              generateSearchData(handleSearch, tempFilter, additionalValues);
            }
          } else
            generateSearchData(handleSearch, filterQuery, additionalValues);
        } else {
          generateSearchData(handleSearch, filterQuery, additionalValues);
        }
        if (sort !== "default") {
          handleSort(sortFilter);
        }
      }
    }, [filterQuery]);

    useEffect(() => {
      doSearch();
    }, [filterQuery]);

    const handleSuccess = (data, reason) => {
      if (reason === "update") {
        // setData((prevState) => {
        // 	const temp = [...prevState];
        // 	const index = prevState.findIndex((res) => res.id === data.id);
        // 	if (index > -1) {
        // 		temp[index] = data;
        // 	}
        // 	return temp;
        // });
        generateSearchData(handleSearch, {});
      } else {
        // setData((prevState) => {
        // 	const temp = [...prevState];
        // 	temp.push(data);
        // 	return temp;
        // });
        generateSearchData(handleSearch, {});
      }
    };

    const handleSelect = (item) => {
      // console.log("item");
      // console.log(item);
      setSelected(item);
      setOpenModal(true);
    };
    const handleSelectUpdate = ({ target }, item) => {
      const { value, checked } = target;
      setBagName(item.name);
      setSelected2(item);
      // setSelected((old) => [...old, item]);
      setSelected((prevState) => {
        const temp = [...prevState];
        if (checked) {
          temp.push(item);
          return temp;
        } else {
          return temp.filter((res) => res.id !== +item.id);
        }
      });
    };

    // console.log({ selected });

    const handleCheck = ({ target }) => {
      const { value, checked } = target;
      setCheckedList((prevState) => {
        const temp = [...prevState];
        if (checked) {
          temp.push(+value);
          return temp;
        } else {
          return temp.filter((res) => res !== +value);
        }
      });
    };

    useEffect(() => {
      // console.log({ checkedList });
      if (checkedList) {
        const filtered = data.filter((item) => checkedList.includes(item.id));
        // console.log({ filtered });
        let statusArr = [];
        filtered.forEach((item, index) => {
          if (item.master?.deliveryStatus === "shipped") {
            let data = {
              id: item.id,
              status: item.master?.deliveryStatus,
              master: item.master?.masterNumber,
              bagNo: item.bagNumber,
            };
            // console.log({ data });
            statusArr.push(data);
          }
        });
        setShippedBags(statusArr);
      }
    }, [checkedList]);

    // console.log({ shippedBags });

    const handleDeleteSuccess = () => {
      setCheckedList([]);
      setConfirmModal(false);
      if (selectedDeleteId) setSelectedDeleteId(null);
      generateSearchData(handleSearch, null);
    };
    const handleBagUpdate = () => {
      Toaster("success", "마스터 패키징이 성공적으로 완료되었습니다");
      doSearch();
      setCheckedList([]);
      setIsChangeBagNameModalOpen(false);
      window.location.reload();
    };

    const handleUpdateBagName = () => {
      if (!(selectedDeleteId || checkedList.length)) {
        return Toaster("error", "BAG을 선택해주세요.");
      }
      try {
        const updateNameMutation = gql(`
    mutation updateBag($input: BagManagementUpdateInput!){
      updateBag(updateInput: $input){
        id
      }
    }`);

        updateName({
          id: checkedList,
          bagName,
          setData,
          updateNameMutation,
          onSuccess: handleBagUpdate,
          responseKey: "updateBag",
        });
      } catch (e) {
        Toaster("error", e.message);
      }
    };

    const handleDelete = () => {
      if (!(selectedDeleteId || checkedList.length)) {
        return Toaster("error", "BAG을 선택해주세요.");
      }
      const deleteMutation = gql(`
    mutation deleteBagMany($input: BagManagementDeleteManyInput!){
    deleteBagMany(deleteInput: $input){
        ids
      }
    }`);
      const ids = selectedDeleteId ? [selectedDeleteId] : checkedList;
      const find = data.find((bag) => {
        const include = ids.includes(bag.id);
        if (include) {
          return bag.totalRequests;
        }
      });
      if (find) {
        return Toaster(
          "error",
          ids.length > 1
            ? `선택하신 BAG 중, [${find.bagNumber}]에는 배송신청건이 담겨 있어 삭제가 불가합니다.`
            : "선택하신 BAG에는 배송신청건이 담겨 있어 삭제가 불가합니다."
        );
      }
      deleteMany({
        ids,
        setData,
        deleteMutation,
        onSuccess: handleDeleteSuccess,
        responseKey: "deleteBagMany",
      });
    };

    const handleMenuNameActionClick = (popup, setState) => {
      popup.close();
      setState(true);
    };

    const [position, setPosition] = useState(null);
    const handleRightClick = (e) => {
      e.preventDefault();
      const { pageX: left, pageY: top } = e;
      setPosition({ top, left });
    };

    const handleMenuClose = () => {
      setPosition(null);
    };

    // const rangeChange = (value) => {
    // 	console.log("value", value["createdAt"]);
    // 	if (value["createdAt"]) {
    // 		const splitted = value["createdAt"]?.split(" ");

    // 		const data = {
    // 			gte: splitted[0],
    // 			lte: splitted[2],
    // 		};

    // 		setFilterQuery({
    // 			createdAt: data,
    // 		});
    // 	}
    // };

    const formReset = () => {
      setFilterQuery({
        createdAt: `${Moment().format("YYYY-MM-DD")} - ${Moment().format(
          "YYYY-MM-DD"
        )}`,
      });
      setSortFilter(null);
      setSort("default");
    };

    const handleDateChange = () => {
      setDateChanged(true);
      // console.log("chnage");
    };

    const handleSortChange = (value) => {
      switch (value) {
        case "1":
          setSort(value);
          let sortData = {
            sortOrder: { sortOrder: "asc" },
            sortField: { sortField: "createdAt" },
          };
          setSortFilter(sortData);
          break;

        case "2":
          setSort(value);
          let sortData2 = {
            sortOrder: { sortOrder: "desc" },
            sortField: { sortField: "createdAt" },
          };
          // handleSort(sortData2);
          setSortFilter(sortData2);
          break;

        case "3":
          setSort(value);
          let sortData3 = {
            sortOrder: { sortOrder: "asc" },
            sortField: { sortField: "receptionLists_count" },
          };
          // handleSort(sortData3);
          setSortFilter(sortData3);
          break;

        case "4":
          setSort(value);
          let sortData4 = {
            sortOrder: { sortOrder: "desc" },
            sortField: { sortField: "receptionLists_count" },
          };
          // handleSort(sortData);
          setSortFilter(sortData4);
          break;

        case "5":
          setSort(value);
          let sortData5 = {
            sortOrder: { sortOrder: "asc" },
            sortField: { sortField: "weight" },
          };
          // handleSort(sortData5);
          setSortFilter(sortData5);
          break;

        case "6":
          setSort(value);
          let sortData6 = {
            sortOrder: { sortOrder: "desc" },
            sortField: { sortField: "weight" },
          };
          // handleSort(sortData6);
          setSortFilter(sortData6);
          break;

        default:
          setSort({});
          setSortFilter(null);
          break;
      }
    };

    // useEffect(() => {
    // 	if (sort) handleSort(sort);
    // }, [sort]);

    // console.log({ sort });

    const calculateSumofQuantities = (value) => {
      if (value) {
        const sumUSD = value
          .filter((item) => item.productInformation.currency === "USD")
          .reduce(
            (previousValue1, currentValue1) =>
              parseInt(previousValue1) +
              // currentValue1.productInformation.productInformation?.reduce(
              // 	(previousValue, currentValue) =>
              // 		parseInt(previousValue) + parseInt(currentValue.quantity),
              // 	0
              // ),
              parseInt(currentValue1.productInformation.totalPriceAmount),
            0
          );

        const sumEUR = value
          .filter((item) => item.productInformation.currency === "EUR")
          .reduce(
            (previousValue1, currentValue1) =>
              parseInt(previousValue1) +
              // currentValue1.productInformation.productInformation?.reduce(
              // 	(previousValue, currentValue) =>
              // 		parseInt(previousValue) + parseInt(currentValue.quantity),
              // 	0
              // ),
              parseInt(
                // fx(currentValue1.productInformation?.totalPriceAmount)
                // 	.from("EUR")
                // 	.to("USD")
                currentValue1.productInformation?.totalPriceAmount
              ) *
                0.98,
            0
          );
        // console.log("Sum: ", { sumUSD }, { sumEUR });
        const sum = sumUSD + sumEUR;
        return sum.toFixed(2);
      }
    };

    return (
      <>
        <BagManagementCustomRightClickMenu
          openPosition={position}
          length={checkedList.length}
          handleMenuClose={handleMenuClose}
          setIsMasterPackagingModalOpen={setIsMasterPackagingModalOpen}
          setIsMasterCreationModalOpen={setIsMasterCreationModalOpen}
          setIsAddBagModalOpen={setIsChangeBagNameModalOpen}
          setOpenBagError={setOpenBagError}
          setShippedBagsError={setShippedBagsError}
          shippedLength={shippedBags.length}
        />

        {currentScreen === "main" ? (
          <>
            <Stack spacing={"35px"}>
              <Grid container justifyContent={"space-between"}>
                <Grid item xs={7}>
                  <Grid
                    item
                    xs={12}
                    container
                    alignItems={"center"}
                    spacing="10px"
                  >
                    <Grid item xs={3}>
                      <CustomPopover>
                        {({ close }) => {
                          return (
                            <Formik
                              enableReinitialize
                              initialValues={
                                filterQuery || {
                                  createdAt: `${Moment().format(
                                    "YYYY-MM-DD"
                                  )} - ${Moment().format("YYYY-MM-DD")}`,
                                }
                              }
                              onSubmit={(values) => {
                                handleOtherSearch(values, setFilterQuery);
                              }}
                            >
                              {({
                                values,
                                handleSubmit,
                                setFieldValue,
                                resetForm,
                              }) => {
                                return (
                                  <Form onSubmit={handleSubmit}>
                                    <Stack
                                      spacing={"20px"}
                                      width={370}
                                      p="20px"
                                    >
                                      <div>
                                        <CustomInput
                                          label={"배송국가"}
                                          labelColor={""}
                                          padding="10px 0"
                                          input={
                                            <SelectBox
                                              name={"shippingCountry_name"}
                                              onChange={(e) =>
                                                handleChange(e, setFieldValue)
                                              }
                                              defaultValue="default"
                                              value={
                                                values?.["shippingCountry_name"]
                                              }
                                            >
                                              <MenuItem
                                                value="default"
                                                disabled
                                                hidden
                                              >
                                                배송국가 선택
                                              </MenuItem>
                                              {additionalFormDetails?.ShippingCountries.map(
                                                (res, index) => {
                                                  return (
                                                    <MenuItem
                                                      key={index}
                                                      value={res.name}
                                                    >
                                                      {res.name}
                                                    </MenuItem>
                                                  );
                                                }
                                              )}
                                            </SelectBox>
                                          }
                                        />
                                      </div>

                                      <div>
                                        <CustomInput
                                          label={"배송서비스"}
                                          labelColor={""}
                                          padding="10px 0"
                                          input={
                                            <SelectBox
                                              name={"deliveryService_id"}
                                              onChange={(e) =>
                                                handleChange(
                                                  e,
                                                  setFieldValue,
                                                  true
                                                )
                                              }
                                              defaultValue="default"
                                              value={
                                                values?.["deliveryService_id"]
                                              }
                                            >
                                              <MenuItem
                                                value="default"
                                                disabled
                                                hidden
                                              >
                                                배송서비스 선택
                                              </MenuItem>
                                              {shippingServices.map(
                                                (res, index) => {
                                                  return (
                                                    <MenuItem
                                                      key={index}
                                                      value={res.id}
                                                    >
                                                      {res.shippingType}
                                                    </MenuItem>
                                                  );
                                                }
                                              )}
                                            </SelectBox>
                                          }
                                        />
                                      </div>

                                      <div>
                                        <DateRangePicker
                                          padding={"0"}
                                          name={"createdAt"}
                                          value={values?.["createdAt"]}
                                          setFieldValue={setFieldValue}

                                          // DateSetter={handleDateChange}
                                        />
                                      </div>

                                      <div>
                                        <CustomInput
                                          label={"정렬"}
                                          labelColor={""}
                                          padding="10px 0"
                                          input={
                                            <SelectBox
                                              name={"sort"}
                                              onChange={(e) =>
                                                handleSortChange(e.target.value)
                                              }
                                              // defaultValue={"default"}
                                              value={sort}
                                            >
                                              <MenuItem
                                                value={"default"}
                                                disabled
                                                hidden
                                              >
                                                정렬기준 선택
                                              </MenuItem>
                                              <MenuItem value={"1"}>
                                                생성날짜 오름차순
                                              </MenuItem>
                                              <MenuItem value={"2"}>
                                                생성날짜 내림차순
                                              </MenuItem>
                                              <MenuItem value={"3"}>
                                                사용갯수 오름차순
                                              </MenuItem>
                                              <MenuItem value={"4"}>
                                                사용갯수 내림차순
                                              </MenuItem>
                                              <MenuItem value={"5"}>
                                                무게 오름차순
                                              </MenuItem>
                                              <MenuItem value={"6"}>
                                                무게 내림차순
                                              </MenuItem>
                                            </SelectBox>
                                          }
                                        />
                                      </div>

                                      <Divider />

                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "flex-end",
                                          gap: "20px",
                                        }}
                                      >
                                        <CustomButton
                                          onClick={close}
                                          styles={{
                                            backgroundColor: "#F8F8FA",
                                            padding: "5px",
                                            color: "#000",
                                            width: "auto",
                                          }}
                                        >
                                          취소
                                        </CustomButton>
                                        <CustomButton
                                          styles={{
                                            width: "auto",
                                            padding: "8px",
                                          }}
                                          variant={"outlined"}
                                          onClick={formReset}
                                        >
                                          필터 초기화
                                        </CustomButton>
                                        <CustomButton
                                          type={"submit"}
                                          onClick={handleSubmit}
                                          styles={{
                                            padding: "5px",
                                            width: "auto",
                                          }}
                                        >
                                          적용
                                        </CustomButton>
                                      </div>
                                    </Stack>
                                  </Form>
                                );
                              }}
                            </Formik>
                          );
                        }}
                      </CustomPopover>
                    </Grid>

                    <Grid item xs={9}>
                      <DropdownAndSearchInput
                        onChange={(data) =>
                          onDropDownSearchChange(data, setFilterQuery)
                        }
                        value={filterQuery}
                        defaultValue={{ key: "name", value: "" }}
                        dropDownValues={[{ value: "name", title: "BAG 이름" }]}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={4}>
                  <Grid container spacing="10px" alignItems={"center"}>
                    <Grid item xs={4}>
                      <CustomButton
                        onClick={handleOpenModal}
                        variant={"outlined"}
                        styles={{
                          color: "#0055FF",
                          border: "1px solid #C4C4C4",
                          borderRadius: 10,
                          height: "40px",
                        }}
                      >
                        BAG 생성
                      </CustomButton>
                    </Grid>
                    <Grid item xs={4}>
                      <CustomButton
                        onClick={() => setConfirmModal(true)}
                        disabled={!checkedList.length}
                        variant={"outlined"}
                        styles={{
                          color: "#FF000F",
                          border: "1px solid #C4C4C4",
                          borderRadius: 10,
                          height: "40px",
                        }}
                      >
                        BAG 삭제
                      </CustomButton>
                    </Grid>
                    <Grid item xs={4}>
                      <CustomSelect
                        value={String(perPage)}
                        label=""
                        onChange={({ target }) => {
                          handleSetPerPage(target.value);
                        }}
                        options={["10", "20", "30", "40", "50", "100"]}
                      ></CustomSelect>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <div
                style={{ position: "relative" }}
                id={"bag-table"}
                onContextMenu={handleRightClick}
              >
                <TableContainer
                  {...hocProps}
                  headers={[
                    <Checkbox
                      checked={data.every((res) =>
                        checkedList.includes(res.id)
                      )}
                      onChange={({ target }) => {
                        if (target.checked) {
                          setCheckedList(data.map((res) => res.id));
                        } else {
                          setCheckedList([]);
                        }
                      }}
                    />,
                    "No.",
                    "BAG 이름",
                    "배송서비스",
                    "관세지불방식",
                    "DG포장관리코드",
                    "도착국가",
                    "배송타입",
                    "사용갯수",
                    "무게",
                    "사용무게",
                    "총 금액 (USD)",
                    "마스터 번호",
                    "생성날짜",
                    "접수 리스트",
                  ]}
                  data={data.map((item, index) => {
                    return [
                      <Checkbox
                        onChange={(e) => {
                          handleCheck(e);
                          handleSelectUpdate(e, item);
                        }}
                        value={item.id}
                        checked={checkedList.some((res) => res === item.id)}
                      />,
                      <div
                        style={{ cursor: "pointer" }}
                        // onClick={() => handleSelect(item)}
                      >
                        {countTotal - ((page - 1) * 10 + index)}
                      </div>,
                      item.name,

                      item.deliveryService.shippingType,
                      item.howToPayCustomDuties,
                      item.dgPackagingManagementCode,
                      item.shippingCountry?.name,
                      item.deliveryType?.deliveryType,
                      item.totalRequests,
                      item.weight,
                      item.usedWeight,
                      <div>
                        {calculateSumofQuantities(item.receptionLists) + "$"}
                      </div>,
                      <div>{item.master?.masterNumber}</div>,
                      new Date(item?.createdAt).toLocaleString(),
                      <CustomButton
                        onClick={() =>
                          handleRedirect("/reception-list", item.name)
                        }
                        styles={{
                          backgroundColor: "#0055FF",
                          color: "#FFFFFF",
                          borderRadius: 4,
                          height: "25px",
                          width: "120px",
                        }}
                      >
                        접수 리스트
                      </CustomButton>,
                    ];
                  })}
                />
              </div>
            </Stack>
          </>
        ) : (
          <BagItems hocProps={hocProps} />
        )}

        {isMasterPackagingSentModalOpen && (
          <CustomModal
            isOpen={isMasterPackagingSentModalOpen}
            handleClose={() => {
              setIsMasterPackagingSentModalOpen(false);
              window.location.reload();
            }}
            updateBagStatus={true}
          >
            <Box
              sx={{
                width: 300,
                height: "auto",
                alignItems: "center",
                justifyContent: "flex-start",
                display: "flex",
                flexDirection: "column",
                gap: 2,
                marginTop: "-40px",
              }}
            >
              <SentIcon />
              <ModalBodyText>마스터 패키징이 완료되었습니다</ModalBodyText>
            </Box>
          </CustomModal>
        )}

        {isAddBagModalOpen && (
          <CustomModal
            title="BAG에 같은 타입 담기"
            onSuccess={() => {
              setIsAddBagModalOpen(false);
            }}
            isOpen={isAddBagModalOpen}
            size="small"
            handleClose={() => {
              setIsAddBagModalOpen(false);
            }}
            hasFooter
          >
            <ModalLayout>
              <CustomInputField
                label="접수 시작 날짜"
                placeholder="출발 날짜를 입력해주세요.."
                type="date"
              />
              <CustomInputField
                label="접수 종료 날짜"
                placeholder="종료 날짜를 입력해주세요.."
                type="date"
              />
              <FormControlLabel
                value="end"
                control={<Checkbox checked />}
                label="배송 처리 된 건만 담기"
                labelPlacement="end"
              />
              <Divider />
            </ModalLayout>
          </CustomModal>
        )}

        {isChangeBagNameModalOpen && (
          <CustomModal
            title="BAG 이름 변경"
            buttonText="저장"
            onSuccess={() => {
              setIsChangeBagNameModalOpen(false);
            }}
            isOpen={isChangeBagNameModalOpen}
            handleClose={() => {
              setIsChangeBagNameModalOpen(false);
            }}
            updateBag={handleUpdateBagName}
            hasFooter
          >
            <ModalLayout>
              <InputField
                type={"text"}
                style={{ paddingLeft: "10px", paddingRight: "10px" }}
                value={bagName}
                onChange={(e) => setBagName(e.target.value)}
                placeholder={"Enter Bag Name"}
              />

              <Divider />
            </ModalLayout>
          </CustomModal>
        )}

        {isMasterPackagingModalOpen && (
          <UpdateMasterModal
            additionalFormDetails={additionalFormDetails}
            selected={selected}
            onClose={handelCloseUpdateModal}
            isOpen={isMasterPackagingModalOpen}
            onSuccess={() => {
              setIsMasterPackagingModalOpen(false);
              setIsMasterPackagingSentModalOpen(true);
            }}
            {...hocProps}
          />
        )}
        {isMasterCreationModalOpen && (
          <MasterManagementModal
            isOpen={isMasterCreationModalOpen}
            onSuccess={() => {
              setIsMasterCreationModalOpen(false);
              window.location.reload();
            }}
            searchQ
            onClose={handelCloseMasterModal}
            checkedList={checkedList}
            {...hocProps}
          />
        )}
        {openModal && (
          <BagManagementModal
            additionalFormDetails={additionalFormDetails}
            selected={selected2}
            onClose={handleClose}
            isOpen={openModal}
            onSuccess={handleSuccess}
            {...hocProps}
          />
        )}
        {selectedBag && (
          <BagDrawer
            selectedBag={selectedBag}
            isOpen={!!selectedBag}
            onClose={() => {
              setSelectedBag(null);
            }}
          />
        )}

        {confirmModal && (
          <ConfirmModal
            isLoading={isLoading}
            isOpen={confirmModal}
            onClose={() => handleClose("confirm")}
            onConfirm={handleDelete}
            title={"BAG 삭제하기"}
            description={"선택하신 BAG을 삭제하시겠습니까?"}
          />
        )}
        {openBagError && (
          <ErrorModal
            isLoading={isLoading}
            isOpen={openBagError}
            onClose={() => setOpenBagError(false)}
            // title={"BAG 삭제하기"}
            errorText={
              "BAG 이름 변경을 원하실 경우, 하나의 BAG만 선택해주시길 바랍니다"
            }
          />
        )}

        {shippedBagsError && (
          <BagShippedErrorModal
            isLoading={isLoading}
            isOpen={shippedBagsError}
            onClose={() => setShippedBagsError(false)}
            shipped={shippedBags}
            // title={"BAG 삭제하기"}
          />
        )}
      </>
    );
  }
);

const ModalLayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  max-height: 70vh;
  overflow-y: auto;
  overflow-x: hidden;
`;

const ModalBodyText = styled(Typography)`
  font-family: "Spoqa Han Sans Neo";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  /* identical to box height */

  text-align: center;

  color: #3e3e3e;
`;

const InputField = styled.input`
  width: 300px;
  height: 44px;
  border-radius: 10px;
  border: 1px solid #c1c1c1;

  ::placeholder {
    font-family: "Spoqa Han Sans Neo" !important;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    color: #c1c1c1;
  }
`;
