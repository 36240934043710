import { required } from "ra-core";

export default {
  loginPageLeftImage: require("./assets/Images/Rectangle113.png"),
  shipter: require("./assets/Images/shipter.png"),
  instagram: require("./assets/Images/instagram.png"),
  ch: require("./assets/Images/ch.png"),
  blog: require("./assets/Images/blog.png"),
  AlertSuccess: require("./assets/Images/AlertSuccess.png"),
};
