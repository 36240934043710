import { APIClientNoCache } from "../../../../utils/apiGraphql";
import { gql } from "@apollo/client/core";

//Gets all the shipping countries
export const getGeneralRatesByCountry = async (id) => {
  const getGeneralRates = gql(`query allGeneralRates($countryId: Float!){
      allGeneralRates(countryId: $countryId){
        rates{
            weight
            deliveryFee
            shippingTypeDetailId
          }
            shippingTypes{
              id
              shippingType
            }
          
      }
    }
      `);

  const res = await APIClientNoCache.query({
    query: getGeneralRates,
    variables: {
      countryId: id,
    },
  });
  return res;
};
