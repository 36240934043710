import {
	Checkbox,
	Divider,
	Grid,
	MenuItem,
	Pagination,
	Stack,
} from "@mui/material";
import TablePagination from "@mui/material/TablePagination";
import { Link } from "react-router-dom";
import React, { useState, useEffect, useCallback } from "react";
import CustomButton from "../../components/CustomButton";
import CustomInput, {
	SelectBox,
	TextFieldBox,
} from "../../components/CustomInput";
import CustomPopover from "../../components/CustomPopover";
import TableContainer from "../../components/TableContainer";
import ConfirmModal from "../../modals/whereAboutModal";
import ListHOC from "../../SharedComponents/ListHOC";
import Moment from "moment";
import { APIClient } from "../../utils/apiGraphql";
import Toaster from "../../utils/Toaster";
import { gql } from "@apollo/client/core";
import styled from "styled-components";
import CustomSelect from "../../components/CustomSelect";
import { generateSearchData, handleOtherSearch } from "../../utils/utils";
import statusSwitch from "../../utils/statusSwitch";

export default function WhereAbout(props) {
	return (
		<ListHOC _resource={"WhereAbout"} {...props}>
			<WhereAboutList />
		</ListHOC>
	);
}

function WhereAboutList({
	count,
	countTotal,
	page,
	perPage,
	handlePageChange,
	handleSearch,
	handleSort,
	handleSetPerPage,
	...hocProps
}) {
	const [filterQuery, setFilterQuery] = useState({ status: "default" });
	const [searchQuery, setSearchQuery] = useState(null);
	const [openDialog, setOpenDialog] = useState(false);
	const [data, setData] = useState([]);
	const [checkedList, setCheckedList] = useState([]);
	const [memo, setMemo] = useState(null);
	const [productQuantity, setProductQuantity] = useState(null);
	const [sort, setSort] = useState("default");
	const [sortFilter, setSortFilter] = useState(null);

	useEffect(() => {
		if (hocProps.data) {
			setData(hocProps.data);
			const initialValue = 0;
			const quantity =
				hocProps.data.requestShipping?.productInformation?.productInformation?.reduce(
					(previousValue, currentValue) =>
						previousValue + currentValue.quantity,
					initialValue
				);
			setProductQuantity(quantity);
		}
	}, [hocProps]);

	const handleOpen = (data) => {
		setOpenDialog(true);
		// setSelectedData(data);
	};

	const handleCloseConfirmDialog = () => {
		setOpenDialog(false);
		// setSelectedData(null);
	};

	const doSearch = useCallback(() => {
		// if (sort.sortOrder) {
		// 	handleSort(sort);
		// } else
		console.log("in");
		if (sort !== "default") {
			handleSort(sortFilter);
		} else if (filterQuery.status !== "default") {
			generateSearchData(handleSearch, filterQuery);
		} else if (sort === "default" && filterQuery.status === "default") {
			const filter = {};
			generateSearchData(handleSearch, filter);
		}
	}, [filterQuery, sortFilter]);

	const doTheSearch = useCallback(() => {
		generateSearchData(handleSearch, searchQuery);
	}, [searchQuery]);

	// const doSearch = useCallback(() => {
	// 	if (filterQuery) {
	// 		const query = {};
	// 		Object.keys(filterQuery).forEach((res) => {
	// 			const value = filterQuery[res];
	// 			if (value) {
	// 				query[res] = value;
	// 			}
	// 		});
	// 		handleSearch(query);
	// 	}
	// }, [filterQuery]);

	useEffect(() => {
		if (sort === "default" && filterQuery.status === "default") {
			doSearch();
		}
	}, [filterQuery]);

	const handleCheck = ({ target }) => {
		const { value, checked } = target;
		setCheckedList((prevState) => {
			const temp = [...prevState];
			if (checked) {
				temp.push(+value);
				return temp;
			} else {
				return temp.filter((res) => res !== +value);
			}
		});
	};

	const ResolvedWhereAbout = async (item) => {
		const updateWhereAboutStatus = gql(`mutation
		updateWhereAbout(
			$updateInput: WhereAboutUpdateInput!
			){
				updateWhereAbout(updateInput:$updateInput){
					id
				}
			}
	`);
		try {
			const res = await APIClient.mutate({
				mutation: updateWhereAboutStatus,
				variables: {
					updateInput: {
						requestShippingId: item.requestShippingId,
						status: "RESOLVED",
						memo: item.memo || "TEST",
					},
				},
			});
			Toaster("success", "해당 행방조사 접수건을 해결 처리하였습니다");
			setTimeout(() => {
				window.location.reload();
			}, 2000);
		} catch (e) {
			Toaster("error", e.message);
		}
	};

	const sorting = (value) => {
		// console.log("value", String(e.target.value));
		// setSort({ ...sort, sortOrder: e.target.value });
		if (value === "1") {
			setSort(value);
			let sortData = {
				sortOrder: { sortOrder: "asc" },
				sortField: { sortField: "createdAt" },
			};
			setSortFilter(sortData);
		} else if (value === "2") {
			setSort(value);
			let sortData = {
				sortOrder: { sortOrder: "desc" },
				sortField: { sortField: "createdAt" },
			};
			setSortFilter(sortData);
		} else {
			setSort("");
			setSortFilter({});
		}
	};

	const resetForm = () => {
		setSort("default");
		setFilterQuery({ status: "default" });
		setSortFilter(null);
		// doSearch();
	};
	return (
		<Stack spacing={"35px"}>
			<Grid container justifyContent={"space-between"} alignItems={"center"}>
				<Grid xs={10}>
					<Grid container xs={12} gap={2}>
						<Grid item xs={2} alignItems={"center"}>
							<CustomPopover>
								{({ close }) => {
									return (
										<Stack spacing={"20px"} width={500} p="20px">
											<div style={{ display: "flex", alignItems: "center" }}>
												<p
													style={{
														display: "flex",
														width: "40%",
														margin: "0",
													}}
												>
													행방조사 접수날짜
												</p>

												<SelectBox
													// defaultValue={"default"}
													value={sort}
													onChange={(e) => sorting(e.target.value)}
												>
													<MenuItem value="default" disabled hidden>
														행방조사 접수날짜 정렬
													</MenuItem>
													<MenuItem value={"1"}>
														행방조사 접수날짜 오름차순
													</MenuItem>
													<MenuItem value={"2"}>
														행방조사 접수날짜 내림차순
													</MenuItem>
												</SelectBox>
											</div>
											{/* </CustomInput> */}
											<div style={{ display: "flex", alignItems: "center" }}>
												<p
													style={{
														display: "flex",
														width: "50%",
														margin: "0",
													}}
												>
													행방조사 현황
												</p>

												<SelectBox
													defaultValue={"default"}
													name={"status"}
													value={filterQuery.status}
													onChange={(e) =>
														setFilterQuery({ status: e.target.value })
													}
												>
													<MenuItem value="default" disabled hidden>
														행방조사 접수날짜 정렬
													</MenuItem>
													<MenuItem value={"RESOLVED"}>해결건</MenuItem>
													<MenuItem value={"REQUESTED"}>미해결건</MenuItem>
												</SelectBox>
											</div>
											{/* <CustomInput
													label={"행방조사 현황"}
													labelColor=""
													labelWidth="200px"
													padding="10px 0"
													select
													defaultValue={"default"}
													onChange={({ target }) => {
														const { name, value } = target;
														handleOtherSearch(
															{ status: value },
															setFilterQuery
														);
													}}
												>
													<MenuItem value="default" disabled hidden>
														옵션 선택
													</MenuItem>
													<MenuItem value="RESOLVED">해결건</MenuItem>
													<MenuItem value="REQUESTED">미해결건</MenuItem>
												</CustomInput> */}
											{/* </div> */}

											<Divider />

											<div
												style={{
													display: "flex",
													justifyContent: "flex-end",
													gap: "20px",
												}}
											>
												<CustomButton
													styles={{
														backgroundColor: "#F8F8FA",
														padding: "5px",
														color: "#000",
														width: "auto",
													}}
													onClick={() => {
														// resetForm();
														close();
													}}
												>
													취소
												</CustomButton>
												<CustomButton
													styles={{
														backgroundColor: "#F8F8FA",
														color: "black",
														width: "auto",
														padding: "8px",
													}}
													onClick={resetForm}
												>
													필터 초기화
												</CustomButton>
												<CustomButton
													styles={{ padding: "5px", width: "auto" }}
													onClick={doSearch}
												>
													전송
												</CustomButton>
											</div>
										</Stack>
									);
								}}
							</CustomPopover>
						</Grid>
						<Grid item xs={2} alignItems={"center"}>
							<SelectBox defaultValue={"업체명"} value="업체명">
								<MenuItem value="업체명">업체명</MenuItem>
							</SelectBox>
						</Grid>
						<Grid item xs={4}>
							<TextFieldBox
								placeholder="업체명을 입력해주세요."
								onChange={({ target }) => {
									const { name, value } = target;
									handleOtherSearch(
										{ requestShipping95user_95company_name_kr: value },
										setSearchQuery
									);
								}}
							/>
						</Grid>
						<Grid item xs={1}>
							<CustomButton
								styles={{
									backgroundColor: "#5B8FF7",
									color: "white",
									borderRadius: 5,
									alignSelf: "center",
								}}
								onClick={doTheSearch}
							>
								검색
							</CustomButton>
						</Grid>
					</Grid>
				</Grid>

				<Grid item xs={2} alignItems={"end"}>
					<CustomSelect
						value={String(perPage)}
						label=""
						onChange={({ target }) => {
							handleSetPerPage(target.value);
						}}
						options={["10", "20", "30", "40", "50", "100"]}
					></CustomSelect>
				</Grid>
			</Grid>

			<div style={{ position: "relative" }}>
				<TableContainer
					{...hocProps}
					headers={[
						<Checkbox
							checked={data.every((res, index) => checkedList.includes(res.id))}
							onChange={({ target }) => {
								if (target.checked) {
									setCheckedList(data.map((res, index) => res.id));
								} else {
									setCheckedList([]);
								}
							}}
						/>,
						<HeaderName>No.</HeaderName>,
						<HeaderName>업체명</HeaderName>,
						<HeaderName>출고날짜</HeaderName>,
						<HeaderName>행방조사 접수날짜</HeaderName>,
						<HeaderName>운송장번호</HeaderName>,
						<HeaderName>배송국가</HeaderName>,
						<HeaderName>배송지주소</HeaderName>,
						<HeaderName>총상품금액</HeaderName>,
						<HeaderName>접수 날짜</HeaderName>,
						<HeaderName>접수번호</HeaderName>,
						<HeaderName>유저명</HeaderName>,
						<HeaderName>업체 전화번호</HeaderName>,
						<HeaderName>상품명</HeaderName>,
						<HeaderName>상품수량</HeaderName>,
						<HeaderName>실제무게</HeaderName>,
						<HeaderName>적용무게</HeaderName>,
						<HeaderName>배송번호</HeaderName>,
						<HeaderName>배송종류</HeaderName>,
						<HeaderName>배송상태</HeaderName>,
						<HeaderName>배송비</HeaderName>,
						<HeaderName>배송추적</HeaderName>,
						<HeaderName>고객 문의사항</HeaderName>,
						<HeaderName>행방조사 현황</HeaderName>,
					]}
					data={data.map((item, index) => [
						<Checkbox
							key={index}
							onChange={handleCheck}
							value={item.id}
							checked={checkedList.some((res) => res === item.id)}
						/>,

						countTotal - ((page - 1) * 10 + index),
						<div>{item.requestShipping?.user?.company_name_kr}</div>,
						Moment(item.requestShipping?.processReleaseDate).format(
							"YYYY-MM-DD"
						),
						<div>
							{Moment(item.requestShipping?.WhereAbout?.createdAt).format(
								"YYYY-MM-DD"
							)}
						</div>,
						<div>{item.requestShipping?.shippingNumber}</div>,
						<div>
							{item.requestShipping?.payeeInformation?.shippingCountry?.name}
						</div>,
						<div
							style={{
								width: "200px",
							}}
						>
							{`
								${item.requestShipping?.payeeInformation?.streetAddress2},
								${item.requestShipping?.payeeInformation?.streetAddress1},
								${item.requestShipping?.payeeInformation?.cities},
								${item.requestShipping?.payeeInformation?.states},
								${item.requestShipping?.payeeInformation?.zipCode}
                `}
						</div>,
						<div>
							{item.requestShipping?.productInformation?.totalPriceAmount}
						</div>,
						<div>
							{Moment(item.requestShipping?.createdAt).format("YYYY-MM-DD")}
						</div>,
						<div>{item.requestShipping?.trackingNumber}</div>,
						<div>{item.requestShipping?.user?.customer_name}</div>,
						<div>{item.requestShipping?.user?.phone_number}</div>,
						item.requestShipping?.productInformation?.productInformation?.map(
							(item1) => {
								return <>{item1.productName} ,</>;
							}
						),

						item?.requestShipping?.productInformation?.productInformation?.reduce(
							(previousValue, currentValue) =>
								previousValue + currentValue.quantity,
							0
						),

						// item?.productInformation?.productInformation?.reduce(
						// 	(previousValue, currentValue) =>
						// 		previousValue.quantity + currentValue.quantity,
						// 	0
						// ),
						// <div>{productQuantity}</div>,
						<div>
							{item.requestShipping?.miscellaneousInformation?.actualWeight}
						</div>,
						<div>
							{item.requestShipping?.miscellaneousInformation?.applicableWeight}
						</div>,
						<div>{item.requestShipping?.shippingNumber} </div>,
						<div>{item.requestShipping?.shippingType?.shippingType} </div>,
						<div>{statusSwitch(item.requestShipping?.status)} </div>,
						<div>{item.requestShipping?.deliveryFee}</div>,
						<Link
							to={`/delivery-tracking?trackingnumber=${item.requestShipping?.trackingNumber}`}
						>
							<CustomButton
								// onClick={() => {
								// 	setActiveScreen(screens.DELIVERY_TRACKING);
								// }}
								styles={{
									backgroundColor: "#0055FF",
									color: "#FFFFFF",
									borderRadius: 4,
									height: "25px",
									width: "120px",
								}}
							>
								배송추적
							</CustomButton>
						</Link>,

						<div
							onClick={(e) => {
								setMemo(item.memo);
								handleOpen(index);
							}}
						>
							{" "}
							<img
								style={{
									width: "30px",
									paddingRight: "10px",
									cursor: "pointer",
								}}
								src="/assets/images/btn-ic.png"
							></img>{" "}
						</div>,
						<div>
							{item.status === "RESOLVED" ? (
								<CustomButton
									variant={"outlined"}
									styles={{
										backgroundColor: "blue",
										color: "white",
										borderRadius: 5,
										alignSelf: "center",
										border: "none",
									}}
									disabled
								>
									해결건
								</CustomButton>
							) : (
								<CustomButton
									onClick={(e) => ResolvedWhereAbout(item)}
									variant={"outlined"}
									styles={{
										backgroundColor: "red",
										color: "white",
										borderRadius: 5,
										alignSelf: "center",
										border: "none",
									}}
								>
									미해결건
								</CustomButton>
							)}
						</div>,
					])}
				/>
			</div>

			{/* <div
				style={{
					display: "flex",
					justifyContent: "center",
					marginTop: 30,
				}}
			>
				<Pagination
					count={count}
					page={page}
					onChange={handlePageChange}
					color="primary"
					shape="rounded"
				/>
			</div> */}

			<ConfirmModal
				description={memo}
				onClose={handleCloseConfirmDialog}
				isOpen={openDialog}
				// onConfirm={handleConfirm}
			/>
		</Stack>
	);
}

const HeaderName = styled.div`
	padding: ${(props) => (props.padding ? props.padding : "25px")};
`;
