import { Grid, MenuItem, Pagination, Stack } from "@mui/material";
import React, { useState, useEffect, useCallback } from "react";
import Moment from "moment";
import { Form, Formik } from "formik";
import { ReceptionHistoryTable } from "../../components/ReceptionHistoryTable";
import CustomButton from "../../components/CustomButton";
import DateRangePicker from "../../components/DateRangePicker";
import CalendarIcon from "../../Common/assets/Images/CalendarIcon";
import { SearchBox } from "../../components/Searchbox";
import { SelectBox } from "../../components/CustomInput";
import ListHOC from "../../SharedComponents/ListHOC";
import { gql } from "@apollo/client";
import { APIClient } from "../../utils/apiGraphql";

// export default function ReceptionHistory(props) {
// 	return (
// 		<ListHOC _resource={"ShippingSummaryStats"}>
// 			<ReceptionHistoryList />
// 		</ListHOC>
// 	);
// }

export default function ShippingSummary() {
	const [sortingOrder, setSortingOrder] = useState("ascending");
	const [filterQuery, setFilterQuery] = useState({});
	const [filterQuery2, setFilterQuery2] = useState(null);
	const [selected, setSelected] = useState("company_name");
	const [isLoading, setIsLoading] = useState(false);
	const [checkedList, setCheckedList] = useState([]);
	const [perPage, setPerPage] = useState(20);
	const [sort, setSort] = useState(null);

	const [data, setData] = useState([
		[1, "엠비케이 엠비케이투", 10, 25, 32],
		[2, "두두키", 20, 30, 34],
		[3, "누리하우스", 30, 35, 36],
		[4, "엠비케이", 40, 40, 38],
		[5, "두두키", 50, 45, 40],
		[6, "누리하우스", 60, 50, 42],
		[7, "엠비케이", 70, 55, 44],
		[8, "두두키", 80, 60, 46],
		[9, "누리하우스", 90, 65, 48],
		[10, "엠비케이", 100, 70, 50],
	]);

	const getShippingStats = async (filter, _sort) => {
		setIsLoading(true);
		const Query = gql(`
        query($filter:shippingSummaryFilter,$sortOrder:SortOrder, $sortField:SortField) {
					allShippingSummary(filter:$filter,sortOrder:$sortOrder, sortField:$sortField){
						summary{
							id
							unstacked
							quantityReceived
							companyName
							overall
						}
					}
        }
      `);

		try {
			const res = await APIClient.query({
				query: Query,
				variables: {
					filter: filter,
					sortOrder: _sort?.sortOrder,
					sortField: _sort?.sortField,
				},
			});
			setData(res.data?.allShippingSummary?.summary);
			setIsLoading(false);
		} catch (e) {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		getShippingStats();
	}, []);

	const handleSearch = (filterParams) => {
		getShippingStats(JSON.parse(JSON.stringify(filterParams)), {}, {});
	};

	const doSearch = useCallback(() => {
		handleSearch({
			[selected]: selected === "id" ? parseInt(filterQuery) : filterQuery,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filterQuery]);

	const doSearch2 = useCallback(() => {
		handleSearch(filterQuery2);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filterQuery2]);

	useEffect(() => {
		doSearch2();
	}, [filterQuery2]);

	const changeSorting = (value) => {
		if (value === "1") {
			setSort(value);
			let sortData = {
				sortOrder: { sortOrder: "asc" },
				sortField: { sortField: "quantityReceived" },
			};
			getShippingStats(filterQuery, sortData);
		} else if (value === "2") {
			// setSort({ sortOrder: "desc", sortField: "deposit_processing_date" });
			setSort(value);
			let sortData = {
				sortOrder: { sortOrder: "desc" },
				sortField: { sortField: "quantityReceived" },
			};
			getShippingStats(filterQuery, sortData);
		} else setSort("");
	};

	const rangeChange = (value) => {
		const splitted = value["createdAt"]?.split(" ");

		const data = {
			gte: splitted[0],
			lte: splitted[2],
		};

		setFilterQuery2({
			createdAt: data,
		});
	};

	return (
		<Stack spacing={"35px"}>
			<Grid container justifyContent={"space-between"}>
				<Grid item xs={12}>
					<Grid
						container
						style={{
							display: "flex",
							justifyContent: "space-between",
						}}
					>
						<Grid item xs={2}>
							<SelectBox
								defaultValue={"default"}
								// value={sort}
								onChange={(e) => changeSorting(e.target.value)}
							>
								<MenuItem value={"default"} disabled hidden>
									접수수량 정렬
								</MenuItem>
								<MenuItem value="1">접수수량 오름차순</MenuItem>
								<MenuItem value="2">접수수량 내림차순</MenuItem>
							</SelectBox>
						</Grid>

						<Grid item xs={1}>
							<SelectBox defaultValue={"createdAt"} value="createdAt">
								<MenuItem value="createdAt">접수일</MenuItem>
							</SelectBox>
						</Grid>

						<Grid item xs={3}>
							<Formik
								enableReinitialize
								initialValues={
									filterQuery2 || {
										createdAt: {
											gte: `${Moment().format("YYYY-MM-DD")}`,

											lte: `${Moment().format("YYYY-MM-DD")}`,
										},
									}
								}
								onSubmit={(values) => {}}
							>
								{({ values, handleSubmit, setFieldValue, resetForm }) => {
									return (
										<Form onSubmit={handleSubmit}>
											<div>
												<DateRangePicker
													showLabel={false}
													name={"createdAt"}
													value={`${values?.["createdAt"]?.gte} - ${values?.["createdAt"]?.lte}`}
													// setFieldValue={setFieldValue}
													handleChange={rangeChange}
													// setFieldValue={setFieldValue}
													iconEnd={<CalendarIcon />}
												/>
											</div>
										</Form>
									);
								}}
							</Formik>
						</Grid>

						<Grid item xs={2}>
							<SelectBox
								defaultValue={selected}
								value={selected}
								onChange={(e) => setSelected(e.target.value)}
							>
								<MenuItem value="company_name">업체명</MenuItem>
							</SelectBox>
						</Grid>

						<Grid item xs={3} container gap={1}>
							<Grid item xs={9}>
								<SearchBox
									onChange={() => {}}
									defaultValue={{ key: "name", value: "업체명" }}
									dropDownValues={[{ value: "name", title: "가방 이름" }]}
									showSearchIcon={false}
									inputFieldPlaceHolder={"검색어를 입력해주세요"}
									setFilterQueryFunc={setFilterQuery}
									doSearch={doSearch}
								/>
							</Grid>
							<Grid item xs={0.5}>
								<CustomButton
									style={{
										height: "38px",
										borderRadius: 10,
										backgroundColor: "#5B8FF7",
									}}
									onClick={doSearch}
								>
									검색
								</CustomButton>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>

			<ReceptionHistoryTable
				data={data}
				sortingOrder={sortingOrder}
				isLoading={isLoading}
				checkedList={checkedList}
				setCheckedList={setCheckedList}
			/>

			<div
				style={{
					display: "flex",
					justifyContent: "center",
					marginTop: 30,
				}}
			>
				<Pagination
					count={Math.ceil(data?.length / perPage)}
					color="primary"
					shape="rounded"
				/>
			</div>
		</Stack>
	);
}
