import { Button, FormHelperText } from "@mui/material";
import LinkIcon from "@mui/icons-material/Link";
import React, { useState, useEffect } from "react";
import styled from "styled-components";

export default function FileUploadMultiple({
	label = "파일 선택",
	onChange,
	helperText,
	error,
	setFile,
	file,
	disabled,
}) {
	const inputRef = React.useRef();
	// const [FileList] = file;
	// console.log({ file });
	// const [value, setValue] = useState(null);
	const [testing, setTesting] = useState(file);

	const handleValue = (value) => {
		const attachment = Array.from(testing);
		// console.log({ value });
		// console.log("before", attachment);
		attachment.splice(value, 1);
		// console.log(attachment);

		setTesting(attachment);
		onChange(attachment);
	};

	// console.log({ testing });

	return (
		<Flex>
			<Wrapper>
				<Button
					style={{ color: "#5B8FF7" }}
					startIcon={<LinkIcon />}
					onClick={() => {
						inputRef.current.click();
					}}
				>
					{label}
				</Button>
				<FormHelperText error={error}>{helperText}</FormHelperText>

				<input
					type={"file"}
					ref={inputRef}
					hidden
					multiple
					onChange={({ target: { files } }) => {
						// console.log({ files });
						// const [file] = files;
						onChange && onChange(files);
						setTesting(files);
					}}
					disabled={disabled}
				/>
			</Wrapper>
			{testing &&
				Object.values(testing).map((item, index) => (
					<Container>
						<Span>{item?.name}</Span>
						{/* <Circle onClick={(e) => handleValue(index)}>x</Circle> */}
						<img
							src="https://img.icons8.com/external-flaticons-lineal-color-flat-icons/64/000000/external-close-web-flaticons-lineal-color-flat-icons-3.png"
							alt="close-icon"
							style={{ height: "20px", width: "20px", margin: "10px" }}
							onClick={(e) => handleValue(index)}
						/>
					</Container>
				))}
		</Flex>
	);
}

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	margin-left: 10px;
`;
const Flex = styled.div`
	display: flex;

	align-items: center;
	justify-content: center;
`;
const Span = styled.div`
	font-size: 12px;
	color: #93a7cd;
	/* margin-left: 15px; */
	width: 100%;
`;

const Circle = styled.div`
	display: flex;
	background-color: #5b8ff7;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	width: 20px;
	height: 20px;
	/* position: absolute; */
	/* right: -22px; */
	top: 0;
	border-radius: 50%;
	font-size: 12px;
	color: #efefef;
`;
const Container = styled.div`
	position: relative;
	padding-left: 25px;
	display: flex;
	align-items: center;
	&:first-child {
		padding-left: 10px;
	}
`;
