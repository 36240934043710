export const generateSameStyleInCount = (
  asciiChar = 65,
  count = 1,
  line = 1,
  style = {}
) => {
  const obj = {};
  for (let i = asciiChar; i <= asciiChar + count; i++) {
    obj[`${String.fromCharCode(i)}${line}`] = style;
  }
  return obj;
};

export const generateSameStyleInCountVertical = (
  asciiChar = 65,
  count = 1,
  line = 1,
  style = {}
) => {
  const obj = {};
  for (let i = line; i <= line + count; i++) {
    obj[`${String.fromCharCode(asciiChar)}${i}`] = style;
  }
  return obj;
};

const allBorders = (style) => {
  return {
    top: {
      style,
    },
    bottom: {
      style,
    },
    left: {
      style,
    },
    right: {
      style,
    },
  };
};
export const masterStyles = (lastline) => {
  return {
    A1: {
      font: {
        name: "맑은 고딕",
        sz: 22,
        bold: true,
      },
      alignment: {
        vertical: "center",
        horizontal: "center",
      },
    },
    H1: {
      font: {
        name: "맑은 고딕",
        sz: 18,
        bold: true,
      },
      alignment: {
        vertical: "center",
        horizontal: "center",
      },
    },
    H2: {
      font: {
        sz: 9,
      },
      alignment: {
        vertical: "center",
        horizontal: "left",
      },
    },
    H3: {
      font: {
        sz: 9,
      },
      alignment: {
        vertical: "center",
        horizontal: "left",
      },
    },
    H4: {
      font: {
        sz: 9,
      },
      alignment: {
        vertical: "center",
        horizontal: "left",
      },
    },

    ...generateSameStyleInCount(76, 1, 6, {
      border: {
        top: {
          style: "thin",
        },
      },
    }),
    ...generateSameStyleInCount(76, 1, 9, {
      border: {
        top: {
          style: "thin",
        },
      },
    }),
    ...generateSameStyleInCount(76, 1, 9, {
      border: {
        top: {
          style: "thin",
        },
      },
    }),
    ...generateSameStyleInCountVertical(78, 2, 6, {
      border: {
        left: {
          style: "thin",
        },
      },
    }),

    ...generateSameStyleInCountVertical(65, 4, 7, {
      border: {
        left: {
          style: "thin",
        },
      },
    }),

    ...generateSameStyleInCountVertical(65 + 8, 4, 6, {
      border: {
        left: {
          style: "thick",
        },
      },
    }),

    A6: {
      border: {
        top: {
          style: "thick",
        },
        left: {
          style: "thin",
        },
        bottom: {
          style: "thin",
        },
      },
    },

    ...generateSameStyleInCount(66, 6, 6, {
      font: {
        bold: true,
      },
      border: {
        top: {
          style: "thick",
        },
        bottom: {
          style: "thin",
        },
      },
    }),

    // A10: {
    //   border: {
    //     left: {
    //       style: "thin",
    //     },
    //     bottom: {
    //       style: "thick",
    //     },
    //   },
    // },

    ...generateSameStyleInCount(66, 6, 10, {
      border: {
        bottom: {
          style: "thick",
        },
      },
    }),
    ...generateSameStyleInCount(65, 0, 12 + lastline + 1, {
      font: {
        bold: true,
      },
      alignment: {
        vertical: "center",
        horizontal: "center",
      },
    }),

    K6: {
      font: {
        bold: true,
      },
      border: {
        ...allBorders("thin"),
      },
    },
    L7: {
      alignment: {
        vertical: "center",
        horizontal: "center",
      },
    },
    K7: {
      font: {
        bold: true,
      },
      border: {
        ...allBorders("thin"),
      },
    },
    K8: {
      font: {
        bold: true,
      },
      border: {
        ...allBorders("thin"),
      },
    },

    K10: {
      font: {
        bold: true,
      },
    },
    L10: {
      font: {
        bold: true,
      },
    },
    B8: {
      alignment: {
        vertical: "top",
      },
    },
    ...generateSameStyleInCount(66, 12, 12, {
      font: { bold: true },
      border: {
        top: {
          style: "medium",
        },
        bottom: {
          style: "medium",
        },
      },
    }),
    ...generateSameStyleInCount(65, 0, 12, {
      font: { bold: true },
      border: {
        top: {
          style: "medium",
        },
        bottom: {
          style: "medium",
        },
        left: {
          style: "medium",
        },
      },
    }),
    ...generateSameStyleInCount(77, 0, 12, {
      font: { bold: true },
      border: {
        top: {
          style: "medium",
        },
        bottom: {
          style: "medium",
        },
        right: {
          style: "medium",
        },
      },
    }),
    ...generateSameStyleInCount(65, 0, lastline + 12 + 8, {
      font: {
        sz: 20,
        bold: true,
      },
      alignment: {
        vertical: "center",
        horizontal: "center",
      },
      fill: { fgColor: { rgb: "E9E9E9" } },
    }),
  };
};


export const NLPostMasterStyles = (lastline) => {
  return {
    A1: {
      font: {
        name: "맑은 고딕",
        sz: 13,
        bold: true,
      },
      alignment: {
        vertical: "center",
        horizontal: "center",
      },
    },
    A2: {
      font: {
        name: "맑은 고딕",
        sz: 13,
        bold: true,
      },
      alignment: {
        vertical: "center",
        horizontal: "center",
      },
    },
    A3: {
      font: {
        name: "맑은 고딕",
        sz: 10,
      },
      alignment: {
        vertical: "center",
        horizontal: "left",
        wrapText: true
      },
    },
    A8: {
      font: {
        name: "맑은 고딕",
        sz: 10,
        bold: true
      },
      alignment: {
        vertical: "bottom",
        horizontal: "left",
      },
    },
    A10: {
      font: {
        name: "맑은 고딕",
        sz: 10,
      },
      alignment: {
        vertical: "bottom",
        horizontal: "left",
        wrapText: true
      },
    },
    A23: {
      font: {
        name: "맑은 고딕",
        sz: 10,
        bold: true
      },
      fill: {
        fgColor: {rgb: "A9A9A9"}
      },
      alignment: {
        vertical: "center",
        horizontal: "center",
      },
    },
    B23: {
      font: {
        name: "맑은 고딕",
        sz: 10,
        bold: true
      },
      fill: {
        fgColor: {rgb: "A9A9A9"}
      },
      alignment: {
        vertical: "center",
        horizontal: "center",
      },
    },
    C23: {
      font: {
        name: "맑은 고딕",
        sz: 10,
        bold: true
      },
      fill: {
        fgColor: {rgb: "A9A9A9"}
      },
      alignment: {
        vertical: "center",
        horizontal: "center",
      },
    },
    D23: {
      font: {
        name: "맑은 고딕",
        sz: 10,
        bold: true
      },
      fill: {
        fgColor: {rgb: "A9A9A9"}
      },
      alignment: {
        vertical: "center",
        horizontal: "center",
      },
    },
    E23: {
      font: {
        name: "맑은 고딕",
        sz: 10,
        bold: true
      },
      fill: {
        fgColor: {rgb: "A9A9A9"}
      },
      alignment: {
        vertical: "center",
        horizontal: "center",
      },
    },
    F23: {
      font: {
        name: "맑은 고딕",
        sz: 10,
        bold: true
      },
      fill: {
        fgColor: {rgb: "A9A9A9"}
      },
      alignment: {
        vertical: "center",
        horizontal: "center",
      },
    },
    // H1: {
    //   font: {
    //     name: "맑은 고딕",
    //     sz: 18,
    //     bold: true,
    //   },
    //   alignment: {
    //     vertical: "center",
    //     horizontal: "center",
    //   },
    // },
    // H2: {
    //   font: {
    //     sz: 9,
    //   },
    //   alignment: {
    //     vertical: "center",
    //     horizontal: "left",
    //   },
    // },
    // H3: {
    //   font: {
    //     sz: 9,
    //   },
    //   alignment: {
    //     vertical: "center",
    //     horizontal: "left",
    //   },
    // },
    // H4: {
    //   font: {
    //     sz: 9,
    //   },
    //   alignment: {
    //     vertical: "center",
    //     horizontal: "left",
    //   },
    // },

    // ...generateSameStyleInCount(76, 1, 6, {
    //   border: {
    //     top: {
    //       style: "thin",
    //     },
    //   },
    // }),
    // ...generateSameStyleInCount(76, 1, 9, {
    //   border: {
    //     top: {
    //       style: "thin",
    //     },
    //   },
    // }),
    // ...generateSameStyleInCount(76, 1, 9, {
    //   border: {
    //     top: {
    //       style: "thin",
    //     },
    //   },
    // }),
    // ...generateSameStyleInCountVertical(78, 2, 6, {
    //   border: {
    //     left: {
    //       style: "thin",
    //     },
    //   },
    // }),

    // ...generateSameStyleInCountVertical(65, 4, 7, {
    //   border: {
    //     left: {
    //       style: "thin",
    //     },
    //   },
    // }),

    // ...generateSameStyleInCountVertical(65 + 8, 4, 6, {
    //   border: {
    //     left: {
    //       style: "thick",
    //     },
    //   },
    // }),

    // A6: {
    //   border: {
    //     top: {
    //       style: "thick",
    //     },
    //     left: {
    //       style: "thin",
    //     },
    //     bottom: {
    //       style: "thin",
    //     },
    //   },
    // },

    // ...generateSameStyleInCount(66, 6, 6, {
    //   font: {
    //     bold: true,
    //   },
    //   border: {
    //     top: {
    //       style: "thick",
    //     },
    //     bottom: {
    //       style: "thin",
    //     },
    //   },
    // }),

    // A10: {
    //   border: {
    //     left: {
    //       style: "thin",
    //     },
    //     bottom: {
    //       style: "thick",
    //     },
    //   },
    // },

    // ...generateSameStyleInCount(66, 6, 10, {
    //   border: {
    //     bottom: {
    //       style: "thick",
    //     },
    //   },
    // }),
    // ...generateSameStyleInCount(65, 0, 12 + lastline + 1, {
    //   font: {
    //     bold: true,
    //   },
    //   alignment: {
    //     vertical: "center",
    //     horizontal: "center",
    //   },
    // }),

    // K6: {
    //   font: {
    //     bold: true,
    //   },
    //   border: {
    //     ...allBorders("thin"),
    //   },
    // },
    // L7: {
    //   alignment: {
    //     vertical: "center",
    //     horizontal: "center",
    //   },
    // },
    // K7: {
    //   font: {
    //     bold: true,
    //   },
    //   border: {
    //     ...allBorders("thin"),
    //   },
    // },
    // K8: {
    //   font: {
    //     bold: true,
    //   },
    //   border: {
    //     ...allBorders("thin"),
    //   },
    // },

    // K10: {
    //   font: {
    //     bold: true,
    //   },
    // },
    // L10: {
    //   font: {
    //     bold: true,
    //   },
    // },
    // B8: {
    //   alignment: {
    //     vertical: "top",
    //   },
    // },
    // ...generateSameStyleInCount(66, 12, 12, {
    //   font: { bold: true },
    //   border: {
    //     top: {
    //       style: "medium",
    //     },
    //     bottom: {
    //       style: "medium",
    //     },
    //   },
    // }),
    // ...generateSameStyleInCount(65, 0, 12, {
    //   font: { bold: true },
    //   border: {
    //     top: {
    //       style: "medium",
    //     },
    //     bottom: {
    //       style: "medium",
    //     },
    //     left: {
    //       style: "medium",
    //     },
    //   },
    // }),
    // ...generateSameStyleInCount(77, 0, 12, {
    //   font: { bold: true },
    //   border: {
    //     top: {
    //       style: "medium",
    //     },
    //     bottom: {
    //       style: "medium",
    //     },
    //     right: {
    //       style: "medium",
    //     },
    //   },
    // }),
    // ...generateSameStyleInCount(65, 0, lastline + 12 + 8, {
    //   font: {
    //     sz: 20,
    //     bold: true,
    //   },
    //   alignment: {
    //     vertical: "center",
    //     horizontal: "center",
    //   },
    //   fill: { fgColor: { rgb: "E9E9E9" } },
    // }),

    // AH1: {
    //   font: {
    //     sz: 9,
    //   },
    //   alignment: {
    //     vertical: "center",
    //     horizontal: "left",
    //   },
    // },
    // AH2: {
    //   font: {
    //     sz: 20,
    //     bold: true
    //   },
    //   alignment: {
    //     vertical: "center",
    //     horizontal: "center",
    //   },
    // }
  };
};

export const shippingRequestStyles = (lastLine) => {
  return {
    ...generateSameStyleInCount(66, 18, 1, {
      font: { bold: true },
      fill: { fgColor: { rgb: "E9E9E9" } },
      border: {
        top: {
          style: "medium",
        },
        bottom: {
          style: "medium",
        },
      },
    }),
    ...generateSameStyleInCount(65, 0, 1, {
      font: { bold: true },
      fill: { fgColor: { rgb: "E9E9E9" } },
      border: {
        top: {
          style: "medium",
        },
        bottom: {
          style: "medium",
        },
        left: {
          style: "thick",
        },
      },
    }),
    ...generateSameStyleInCount(84, 0, 1, {
      font: { bold: true },
      fill: { fgColor: { rgb: "E9E9E9" } },
      border: {
        top: {
          style: "medium",
        },
        bottom: {
          style: "medium",
        },
        right: {
          style: "thick",
        },
      },
    }),
    ...generateSameStyleInCount(66, 18, lastLine, {
      font: { bold: true },
      fill: { fgColor: { rgb: "E9E9E9" } },
      border: {
        top: {
          style: "medium",
        },
        bottom: {
          style: "thick",
        },
      },
    }),
    ...generateSameStyleInCount(65, 0, lastLine, {
      font: { bold: true },
      fill: { fgColor: { rgb: "E9E9E9" } },
      border: {
        top: {
          style: "medium",
        },
        bottom: {
          style: "thick",
        },
        left: {
          style: "thick",
        },
      },
    }),
    ...generateSameStyleInCount(84, 0, lastLine, {
      font: { bold: true },
      fill: { fgColor: { rgb: "E9E9E9" } },
      border: {
        top: {
          style: "medium",
        },
        bottom: {
          style: "thick",
        },
        right: {
          style: "thick",
        },
      },
    }),
  };
};

export const NLPostShippingRequestStyles = (lastLine) => {
  return {
    A1: {
      font: {
        name: "맑은 고딕",
        sz: 15,
        bold: true
      },
      fill: {
        fgColor: {rgb: "FFDFBF"}
      },
      alignment: {
        vertical: "center",
        horizontal: "center",
      },
    },
    A2: {
      font: {
        name: "맑은 고딕",
        sz: 15,
        bold: true
      },
      fill: {
        fgColor: {rgb: "FFDFBF"}
      },
      alignment: {
        vertical: "center",
        horizontal: "center",
      },
    },
    A3: {
      font: {
        name: "맑은 고딕",
        sz: 11,
        bold: true
      },
      alignment: {
        vertical: "center",
        horizontal: "center",
      },
    },
    A4: {
      font: {
        name: "맑은 고딕",
        sz: 11,
        bold: true
      },
    },
    A6: {
      font: {
        name: "맑은 고딕",
        sz: 11,
        bold: true
      },
    },
    B6: {
      font: {
        name: "맑은 고딕",
        sz: 11,
        bold: true
      },
    },
    E6: {
      font: {
        name: "맑은 고딕",
        sz: 11,
        bold: true
      },
    },
    A15: {
      font: {
        name: "맑은 고딕",
        sz: 11,
        bold: true
      },
    },
    E15: {
      font: {
        name: "맑은 고딕",
        sz: 11,
        bold: true
      },
    },
    F15: {
      font: {
        name: "맑은 고딕",
        sz: 11,
        bold: true
      },
    },
    G15: {
      font: {
        name: "맑은 고딕",
        sz: 11,
        bold: true
      },
    },
    H15: {
      font: {
        name: "맑은 고딕",
        sz: 11,
        bold: true
      },
    },
    E19: {
      font: {
        name: "맑은 고딕",
        sz: 11,
        bold: true
      },
      fill: {
        fgColor: {rgb: "808080"}
      },
    },
    F19: {
      font: {
        name: "맑은 고딕",
        sz: 11,
        bold: true
      },
      fill: {
        fgColor: {rgb: "808080"}
      },
    },
    G19: {
      font: {
        name: "맑은 고딕",
        sz: 11,
        bold: true
      },
      fill: {
        fgColor: {rgb: "808080"}
      },
    },
    H19: {
      font: {
        name: "맑은 고딕",
        sz: 11,
        bold: true
      },
      fill: {
        fgColor: {rgb: "808080"}
      },
    },
    I19: {
      font: {
        name: "맑은 고딕",
        sz: 11,
        bold: true
      },
      fill: {
        fgColor: {rgb: "808080"}
      },
    },
    
    A34: {
      fill: {
        fgColor: {rgb: "FFFF00"}
      },
    }

  };
};


export const NLPostPackingListStyles = (lastLine) => {
  return {
    A1: {
      font: {
        name: "맑은 고딕",
        sz: 13,
        bold: true
      },
      fill: {
        fgColor: {rgb: "A9A9A9"}
      },
      alignment: {
        vertical: "center",
        horizontal: "center",
      },
    },
    A2: {
      font: {
        sz: 13,
        bold: true
      },
      alignment: {
        vertical: "center",
        horizontal: "center",
      },
    },
    A3: {
      font: {
        sz: 10,
        bold: true
      },

    },
    A4: {
      font: {
        sz: 10,
        bold: true
      },
    },
    B4: {
      font: {
        sz: 10,
      },
      alignment: {
        vertical: "left",
        horizontal: "left",
        wrapText: true
      },
    },
    A7: {
      font: {
        sz: 10,
        bold: true
      },
    },
    A8: {
      font: {
        sz: 10,
        bold: true
      },
    },
    A9: {
      font: {
        sz: 10,
        bold: true
      },
    },
    A12: {
      font: {
        sz: 10,
        bold: true
      },
      alignment: {
        vertical: "left",
        horizontal: "left",
      },
    },
    C12: {
      font: {
        sz: 10,
        bold: true
      },
      alignment: {
        vertical: "left",
        horizontal: "left",
      },
    },
    G12: {
      font: {
        sz: 10,
        bold: true
      },
      alignment: {
        vertical: "left",
        horizontal: "left",
      },
    },
    C18: {
      font: {
        sz: 10,
        bold: true
      },
      alignment: {
        vertical: "left",
        horizontal: "left",
      },
    },
    A19: {
      font: {
        sz: 10,
        bold: true
      },
      alignment: {
        vertical: "left",
        horizontal: "left",
      },
    },
    C19: {
      font: {
        sz: 10,
        bold: true
      },
      alignment: {
        vertical: "left",
        horizontal: "left",
      },
    },
    A23: {
      font: {
        sz: 10,
        bold: true
      },
      alignment: {
        vertical: "left",
        horizontal: "left",
      },
      fill: { fgColor: { rgb: "00FF00" } },
    },
    A25: {
      font: {
        sz: 10,
        bold: true
      },
      alignment: {
        vertical: "left",
        horizontal: "left",
      },
      fill: { fgColor: { rgb: "00FF00" } },
    },
    A26: {
      font: {
        sz: 10,
        bold: true
      },
      alignment: {
        vertical: "left",
        horizontal: "left",
      },
      fill: { fgColor: { rgb: "00FF00" } },
    },
    A27: {
      font: {
        sz: 10,
        bold: true
      },
      alignment: {
        vertical: "left",
        horizontal: "left",
      },
      fill: { fgColor: { rgb: "00FF00" } },
    },

    B25: {
      font: {
        sz: 10,
        bold: true
      },
      alignment: {
        vertical: "left",
        horizontal: "left",
      },
      fill: { fgColor: { rgb: "00FF00" } },
    },
    B26: {
      font: {
        sz: 10,
        bold: true
      },
      alignment: {
        vertical: "left",
        horizontal: "left",
      },
      fill: { fgColor: { rgb: "00FF00" } },
    },
    B27: {
      font: {
        sz: 10,
        bold: true
      },
      alignment: {
        vertical: "left",
        horizontal: "left",
      },
      fill: { fgColor: { rgb: "00FF00" } },
    },

    C25: {
      font: {
        sz: 10,
        bold: true
      },
      alignment: {
        vertical: "left",
        horizontal: "left",
      },
      fill: { fgColor: { rgb: "00FF00" } },
    },
    C26: {
      font: {
        sz: 10,
        bold: true
      },
      alignment: {
        vertical: "left",
        horizontal: "left",
      },
      fill: { fgColor: { rgb: "00FF00" } },
    },
    C27: {
      font: {
        sz: 10,
        bold: true
      },
      alignment: {
        vertical: "left",
        horizontal: "left",
      },
      fill: { fgColor: { rgb: "00FF00" } },
    },

    D25: {
      font: {
        sz: 10,
        bold: true
      },
      alignment: {
        vertical: "left",
        horizontal: "left",
      },
      fill: { fgColor: { rgb: "00FF00" } },
    },
    D26: {
      font: {
        sz: 10,
        bold: true
      },
      alignment: {
        vertical: "left",
        horizontal: "left",
      },
      fill: { fgColor: { rgb: "00FF00" } },
    },
    D27: {
      font: {
        sz: 10,
        bold: true
      },
      alignment: {
        vertical: "left",
        horizontal: "left",
      },
      fill: { fgColor: { rgb: "00FF00" } },
    },

    E25: {
      font: {
        sz: 10,
        bold: true
      },
      alignment: {
        vertical: "left",
        horizontal: "left",
      },
      fill: { fgColor: { rgb: "00FF00" } },
    },
    E26: {
      font: {
        sz: 10,
        bold: true
      },
      alignment: {
        vertical: "left",
        horizontal: "left",
      },
      fill: { fgColor: { rgb: "00FF00" } },
    },
    E27: {
      font: {
        sz: 10,
        bold: true
      },
      alignment: {
        vertical: "left",
        horizontal: "left",
      },
      fill: { fgColor: { rgb: "00FF00" } },
    },


    F25: {
      font: {
        sz: 10,
        bold: true
      },
      alignment: {
        vertical: "left",
        horizontal: "left",
      },
      fill: { fgColor: { rgb: "00FF00" } },
    },
    F26: {
      font: {
        sz: 10,
        bold: true
      },
      alignment: {
        vertical: "left",
        horizontal: "left",
      },
      fill: { fgColor: { rgb: "00FF00" } },
    },
    F27: {
      font: {
        sz: 10,
        bold: true
      },
      alignment: {
        vertical: "left",
        horizontal: "left",
      },
      fill: { fgColor: { rgb: "00FF00" } },
    },
  };
};