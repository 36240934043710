import Icons from "./icon";
import AirplanemodeActiveOutlinedIcon from "@mui/icons-material/AirplanemodeActiveOutlined";

const url = typeof window && window.location.hash;

export const adminLinks = [
	{
		name: "대쉬보드",
		path: "/",
		icon: <Icons.Analysis />,
		children: null,
	},
	{
		name: "공지사항 관리",
		path: "/notice",
		icon: <Icons.Document />,
		children: null,
	},
	{
		name: "업체 관리",
		path: "#",
		icon: <Icons.School />,
		children: [
			{
				name: "업체 리스트",
				path: "/CompanyList",
			},
			{
				name: "업체별 접수내역",
				path: "/shipping-summary",
			},
			{
				name: "입출금내역",
				path: "/Transaction",
			},
			{
				name: "배송비 차감 내역",
				path: "/shipping-fees",
			},
			{
				name: "정산내역",
				path: "/settlement-history",
			},
			{
				name: "행방조사",
				path: "/whereabout",
			},
		],
	},
	{
		name: "마스터 관리",
		path: "/MasterManagement",
		icon: (
			<AirplanemodeActiveOutlinedIcon
				style={{
					marginRight: "-6px",
					paddingRight: "4px",
				}}
			/>
		),
		children: null,
	},
	{
		name: "BAG 관리",
		path: "/Bag",
		icon: <Icons.Suitcase />,
		children: null,
	},

	{
		name: "배송 접수 관리",
		path: "#",
		icon: <Icons.Truck />,
		children: [
			{
				name: "배송 신청 리스트",
				path: "/request-shipping",
			},
			{
				name: "배송 보류",
				path: "/suspend-shipping",
			},
			{
				name: "배송 처리",
				path: "/shipping-processing",
			},
			{
				name: "리라벨링",
				path: "/relabeling",
			},
		],
	},
	{
		name: "요율 관리",
		path: "#",
		icon: <Icons.Money />,
		children: [
			{
				name: "일반 요율",
				path: "/general-rates",
			},
			{
				name: "업체그룹별 요율",
				path: "/rates-by-company",
			},
		],
	},
	{
		name: "운송장 관리",
		path: "#",
		icon: <Icons.DocumentAdd />,
		children: [
			{
				name: "운송장 리스트",
				path: "/waybill-list",
			},
			{
				name: "운송장 업로드",
				path: "/upload-waybill",
			},
		],
	},
	{
		name: "설정",
		path: "#",
		icon: <Icons.Settings />,
		children: [
			{
				name: "배송 타입 관리",
				path: "/ShippingTypes",
			},
			{
				name: "배송 국가 관리",
				path: "/ShippingCountries",
			},
		],
	},
	{
		name: "통계",
		path: "/StatsByCountries",
		icon: <Icons.Pie />,
		children: null,
	},
];

export const clientLinks = [
	{
		name: "대쉬보드",
		path: "/",
		icon: <Icons.Analysis />,
		children: null,
	},
	{
		name: "공지사항",
		path: "/notice",
		icon: <Icons.Document />,
		children: null,
	},
	{
		name: "배송 관리",
		path: "#!",
		icon: <Icons.Truck />,
		children: [
			{
				name: "배송 신청",
				path: "RequestShipping",
			},
			{
				name: "배송 대량 신청",
				path: "RequestShippingBulk",
			},
			{
				name: "배송 신청 리스트",
				path: "/shipping-request/list",
			},
			{
				name: "배송 보류",
				path: "/shipping-suspend",
			},
			{
				name: "해외배송 소포 수령증",
				path: "/delivery-parcels",
			},
			{
				name: "수출입신고 게시판",
				path: "/export-declaration",
			},
		],
	},
	{
		name: "예치금 관리",
		path: "#",
		icon: <Icons.Money />,
		children: [
			{
				name: "예치금 입금 내역",
				path: "/deposit-history",
			},
			{
				name: "배송비 차감내역",
				path: "/fee-deduction",
			},
		],
	},
	{
		name: "설정",
		path: "#1",
		icon: <Icons.Settings />,
		children: [
			{
				name: "지점정보",
				path: "/settings-branchinfo",
			},
			{
				name: "배송 요율표",
				path: "/settings-shippingrate",
			},
			{
				name: "고객사 정보",
				path: "/settings-customerinfo",
			},
			{
				name: "비밀번호 변경",
				path: "/settings-password",
			},
			{
				name: "수출신고 API 설정",
				path: "/settings-api",
			},
		],
	},
	{
		name: "통계",
		path: "/StatsByCountries",
		icon: <Icons.Pie />,
		children: null,
	},
];
