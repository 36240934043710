import { adminLinks, clientLinks } from "./links";

export const getPaths = (data) => {
  const paths = [];
  data.forEach((res) => {
    if (res.children) {
      const _paths = getPaths(res.children);
      paths.push(..._paths);
    } else {
      paths.push(res.path);
    }
  });
  return paths;
};

export const permissionsMap = [
  {
    role: "admin",
    urls: getPaths(adminLinks),
  },
  {
    role: "client",
    urls: getPaths(clientLinks),
  },
];
