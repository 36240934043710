import {
	Button,
	Divider,
	Grid,
	Link,
	MenuItem,
	Pagination,
	Stack,
	Typography,
	Paper,
} from "@mui/material";

import CustomButton from "../../../components/CustomButton";
import CustomInput, {
	SelectBox,
	TextFieldBox,
} from "../../../components/CustomInput";
import FileUpload from "../../../components/FileUpload";
import TableContainer from "../../../components/TableContainer";
import React, { useEffect, useState } from "react";
import { useDataProvider } from "react-admin";
import Toaster from "../../../utils/Toaster";
import { useGetList, useCreate } from "react-admin";
import styled from "styled-components";
import ListHOC from "../../../SharedComponents/ListHOC";
import { gql } from "@apollo/client/core";
import { APIClient } from "../../../utils/apiGraphql";
import { getGeneralRatesByCountry } from "./queries";

// export function ClientInfoSettings({ ...props }) {
//   return React.createElement(UpdateHOC, {
//     children: ClientBranchSettings,
//     _resource: "User",
//     ...props,
//   });
// }

export const ClientBranchSettings = (props) => {
	const dataProvider = useDataProvider();
	const [data, setData] = useState(null);

	useEffect(() => {
		const { id } = localStorage.getItem("userData");
		dataProvider
			.getOne("User", { id: id })
			.then(({ data }) => {
				setData(data);
			})
			.catch((error) => {
				Toaster("error", error.message);
			});
	}, []);

	return (
		<>
			<Stack spacing={"50px"}>
				<Container>
					<CustomInput
						value={data?.branchName}
						label="지점명"
						disabled
						style={{ padding: "10px", width: "100%" }}
					/>

					<Divider />

					<CustomInput
						value={data?.branchAddress}
						label="주소"
						disabled
						style={{ padding: "10px", width: "100%" }}
					/>

					<Divider />

					<CustomInput
						value={data?.branchPhoneNumber}
						label="전화번호"
						disabled
						style={{ padding: "10px", width: "100%" }}
					/>

					<Divider />

					<CustomInput
						value={data?.branchEmail}
						label="이메일"
						disabled
						style={{ padding: "10px", width: "100%" }}
					/>
				</Container>
			</Stack>
		</>
	);
};

// export const ClientShippingRateSettings = (props) => {
// 	return (
// 		<ListHOC _resource={"GeneralRates"}>
// 			<ClientShippingRate />
// 		</ListHOC>
// 	);
// };

export const ClientShippingRateSettings = ({ ...hocProps }) => {
	const { data, total, isLoading, error } = useGetList("ShippingCountries", {
		pagination: { page: 1, perPage: 1000 },
		sort: { field: "published_at", order: "DESC" },
	});
	const [column, setColumn] = useState([]);
	const [id, setId] = useState(null);
	const [tableData, setTableData] = useState(null);
	const [page, setPage] = useState(1);
	// useE;

	useEffect(() => {
		if (id)
			getGeneralRatesByCountry(id).then(
				(res) => {
					if (res.data.allGeneralRates.rates.length === 0) {
						Toaster(
							"error",
							"선택하신 국가에 해당하는 배송 요율표가 없습니다."
						);
						setTableData([]);
						return;
					}
					//Process column for table
					let col = [{ id: 0, shippingType: "무게(KG)" }];
					res.data.allGeneralRates.shippingTypes.forEach((d) => {
						let mod = {
							id: d.id,
							shippingType: d.shippingType + " (배송비/KRW)",
						};
						col.push(mod);
					});
					setColumn(col);

					//Process data for table
					let procData = [];
					res.data.allGeneralRates.rates.forEach((r) => {
						let row = [];
						//Checking if weight is already there
						let indexOfWeight = procData.findIndex((el) =>
							el.includes(r.weight)
						);
						switch (indexOfWeight) {
							case -1: //If index not found we create new row
								row.push(r.weight);
								res.data.allGeneralRates.shippingTypes.forEach((c, i) => {
									if (c.id === r.shippingTypeDetailId && r.weight !== 0) {
										let dFee = "";
										if(r.deliveryFee) {
											dFee = r.deliveryFee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, match => `${match},`);
										}

										dFee = dFee.replace(/,*$/, '');

										
										row.push(dFee);
									} else {
										row.push("NA");
									}
								});
								procData.push(row);
								break;

							default: //Index found we push to the found row
								row = [...procData[indexOfWeight]];
								res.data.allGeneralRates.shippingTypes.forEach((c, i) => {
									if (c.id === r.shippingTypeDetailId && r.weight !== 0) {
										let dFee = "";

										if(r.deliveryFee) {
											dFee = r.deliveryFee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, match => `${match},`);
										}

										dFee = dFee.replace(/,*$/, '');

										row[i + 1] = dFee;
									}
								});
								procData[indexOfWeight] = row;
								break;
						}
					});
					setTableData(procData);
				},
				(err) => {
					console.log(err);
				}
			);
	}, [id]);

	const handleChange = (e, data) => {
		setPage(data);
	};

	return (
		<Paper
			sx={{
				maxWidth: "1150px",
				flexGrow: 1,
				boxShadow: "unset",
			}}
		>
			<Stack spacing={"20px"}>
				<Box>
					<SelectBox
						defaultValue={"국가 선택"}
						value={id}
						onChange={(e) => setId(e.target.value)}
					>
						<MenuItem value="국가 선택" disabled hidden>
							국가 선택
						</MenuItem>
						{data &&
							data
								.filter((el) => el.isActive === true)
								.map((item, index) => (
									<MenuItem value={item.id} key={index}>
										{item.name}
									</MenuItem>
								))}
					</SelectBox>
				</Box>

				{id === null && (
					<Grid
						item
						xs={12}
						container
						justifyContent={"space-between"}
						alignItems={"center"}
					>
						국가를 선택하세요.
					</Grid>
				)}
				{/* slice((page - 1) * 10, page * 10). */}
				{tableData?.length > 0 && (
					<>
						<TableContainer
							{...{ isLoading: false, requestCompleted: true }}
							headers={column.map((c) => c.shippingType)}
							data={tableData.map((r) => r)}
						/>
						{/* <Flex>
							<Pagination
								count={Math.ceil(tableData.length / 10)}
								color="primary"
								shape="rounded"
								onChange={handleChange}
							/>
						</Flex> */}
					</>
				)}

				{tableData !== null && tableData.length === 0 && (
					<Flex>
						<span>선택하신 국가에 해당하는 배송 요율표가 없습니다</span>
					</Flex>
				)}
			</Stack>
		</Paper>
	);
};

const Container = styled.div`
	border-radius: 10px;
	overflow: hidden;
	border: 1px solid #e5e5e5;
`;
const Box = styled.div`
	width: 180px;
`;
const Flex = styled.div`
	display: flex;
	justify-content: center;
	margin-top: 30px;
`;
