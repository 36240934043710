import * as React from "react";
import { Box, Grid } from "@mui/material";
import Stepper from "@mui/material/Stepper";
import { useState } from "react";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import Button from "@mui/material/Button";
import { APIClient } from "../utils/apiGraphql";
import Typography from "@mui/material/Typography";
import CustomInputLabel from "./CustomInputLabel";
import CustomPasswordInput from "./CustomPasswordInput";
import Toaster from "../utils/Toaster";

import { Formik, Form } from "formik";
import OutlinedInput from "@mui/material/OutlinedInput";
import { gql } from "@apollo/client/core";
import Images from "../Common/Images";

import { CircularProgress } from "@mui/material";
import CustomInput from "./CustomInput";
import Step3 from "./SignUpFormThird";
const steps = ["1단계", "2단계", "3단계"];

export default function HorizontalNonLinearStepper({ changeForm, showForm }) {
	const [activeStep, setActiveStep] = React.useState(0);
	const [completed, setCompleted] = React.useState({});
	const [submittedValues, setSubmittedValues] = React.useState({});
	// eslint-disable-next-line no-unused-vars
	const [loading, setLoading] = useState(false);

	const totalSteps = () => {
		return steps.length;
	};

	const completedSteps = () => {
		return Object.keys(completed).length;
	};

	const isLastStep = () => {
		return activeStep === totalSteps() - 1;
	};

	const allStepsCompleted = () => {
		return completedSteps() === totalSteps();
	};

	const handleNext = () => {
		const newActiveStep =
			isLastStep() && !allStepsCompleted()
				? // It's the last step, but not all steps have been completed,
				  // find the first step that has been completed
				  steps.findIndex((step, i) => !(i in completed))
				: activeStep + 1;
		setActiveStep(newActiveStep);
	};

	// eslint-disable-next-line no-unused-vars
	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	const handleStep = (step) => () => {
		setActiveStep(step);
	};

	// eslint-disable-next-line no-unused-vars
	const handleComplete = () => {
		const newCompleted = completed;
		newCompleted[activeStep] = true;
		setCompleted(newCompleted);
		handleNext();
	};

	const handleReset = () => {
		setActiveStep(0);
		setCompleted({});
	};

	const handleSubmit = (values, form) => {
		console.log("Submit", values);
		// form.setTouched({ ...form.touched, [field.name]: true });
		console.log(form);

		try {
		} catch (e) {
			console.log(e);
		}
	};

	const Step1 = () => {
		const [userName, setUserName] = useState("");
		const [password, setPassword] = useState("");
		const [confirmPassword, setConfirmPassword] = useState("");
		const [errors, setErrors] = useState([]);

		const evaluateFields = () => {
			let filteredErrors = [];
			userName === "" && filteredErrors.push("userName");
			password === "" && filteredErrors.push("password");
			confirmPassword === "" && filteredErrors.push("confirmPassword");
			password !== "" &&
				confirmPassword !== "" &&
				password !== confirmPassword &&
				filteredErrors.push("password", "confirmPassword");
			setErrors(filteredErrors);
			return filteredErrors.length;
		};

		return (
			<Box>
				<Box
					display={"flex"}
					flexDirection={"column"}
					sx={{
						marginTop: "30px",
						justifyContent: "left",
						marginLeft: 0,
						paddingLeft: 0,

						width: "100%",
						height: "350px",
						overflow: "auto",
					}}
				>
					<CustomInputLabel name="아이디" required={true} />
					<OutlinedInput
						values={userName}
						size="small"
						placeholder="아이디를 입력해주세요."
						type={"text"}
						notched={false}
						onChange={(event) => setUserName(event.target.value)}
						OutlinedInput={false}
						color="primary"
						sx={{
							mb: "10px",
							backgroundColor: "white",
							border: errors.includes("userName") ? "2px solid red" : "none",
							borderRadius: errors.includes("userName") ? "0px" : "4px",
						}}
					/>

					<CustomInputLabel name="비밀번호" required={true} />
					<CustomPasswordInput
						placeholder="비밀번호를 입력해주세요."
						inputValue={password}
						onChange={(event) => setPassword(event.target.value)}
						sx={{
							border: errors.includes("password") ? "2px solid red" : "none",
							borderRadius: errors.includes("password") ? "0px" : "4px",
						}}
						flex={3}
					/>

					<CustomInputLabel name="비밀번호 확인" required={true} />
					<CustomPasswordInput
						placeholder="비밀번호를 다시 한 번 입력해주세요."
						onChange={(event) => setConfirmPassword(event.target.value)}
						inputValue={confirmPassword}
						sx={{
							border: errors.includes("confirmPassword")
								? "2px solid red"
								: "none",
							borderRadius: errors.includes("confirmPassword") ? "0px" : "4px",
						}}
					/>

					<Button
						variant="contained"
						onClick={() => {
							if (!evaluateFields()) {
								setSubmittedValues({
									...submittedValues,
									username: userName,
									password,
								});
								setActiveStep(1);
							}
						}}
						sx={{
							paddingTop: "12px",
							paddingBottom: "12px",
							backgroundColor: "#427DF3",
							borderRadius: "8px",
							width: "350px",
							height: "60px",
						}}
					>
						{loading ? (
							<CircularProgress size={20} color="primary" />
						) : (
							<Typography
								component="p"
								variant="p"
								sx={{
									fontSize: "16px",
									fontWeight: "700",
									lineHeight: "24px",
									color: "#ffffff",
								}}
							>
								다음
							</Typography>
						)}
					</Button>
				</Box>
				<Box>
					<Typography
						component="p"
						variant="button"
						onClick={() => changeForm("LoginForm")}
						sx={{
							fontSize: "16px",
							fontWeight: "600",
							lineHeight: "20px",
							color: "#427DF3",
							marginTop: "24px",
							textAlign: "center",
						}}
					>
						{"로그인 페이지로 돌아가기"}
					</Typography>
				</Box>
			</Box>
		);
	};

	const Step2 = () => {
		const [companyNameKorean, setCompanyNameKorean] = useState("");
		const [companyRegistrationNumber, setCompanyRegistrationNumber] =
			useState("");
		const [customerName, setCustomerName] = useState("");
		const [businessCondition, setBusinessCondition] = useState("");
		const [businessItem, setBusinessItem] = useState("");
		const [phoneNumber, setPhoneNumber] = useState("");
		const [mobileNumber, setMobileNumber] = useState("");
		const [faxNumber, setFaxNumber] = useState("");
		const [contactName, setContactName] = useState("");
		const [errors, setErrors] = useState([]);

		const evaluateFields = () => {
			let filteredErrors = [];
			companyNameKorean === "" && filteredErrors.push("companyNameKorean");
			companyRegistrationNumber === "" &&
				filteredErrors.push("companyRegistrationNumber");
			customerName === "" && filteredErrors.push("customerName");
			businessCondition === "" && filteredErrors.push("businessCondition");
			businessItem === "" && filteredErrors.push("businessItem");
			phoneNumber === "" && filteredErrors.push("phoneNumber");
			mobileNumber === "" && filteredErrors.push("mobileNumber");
			contactName === "" && filteredErrors.push("contactName");
			setErrors(filteredErrors);
			return filteredErrors.length;
		};

		const mobileNumberFormatting = (value) => {
			if (!value) return value;

			const phoneNumber = value.replace(/[^\d]/g, "");

			const phoneNumberLength = phoneNumber.length;

			if (phoneNumberLength < 4) return phoneNumber;

			if (phoneNumberLength < 7) {
				return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3)}`;
			}

			return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(
				3,
				7
			)}-${phoneNumber.slice(7, 11)}`;
		};

		const handleMobileNumber = (value) => {
			// console.log({ value });
			const formatted = mobileNumberFormatting(value);
			// console.log({ formatted });
			setMobileNumber(formatted);
		};

		const numbersFormatting = (value) => {
			if (!value) return value;

			const phoneNumber = value.replace(/[^\d]/g, "");

			return phoneNumber;
		};

		const handlePhoneNumber = (value) => {
			// console.log({ value });
			const formatted = numbersFormatting(value);
			// console.log({ formatted });
			setPhoneNumber(formatted);
		};

		const handleFaxNumber = (value) => {
			// console.log({ value });
			const formatted = numbersFormatting(value);
			// console.log({ formatted });
			setFaxNumber(formatted);
		};

		return (
			<Box>
				<Box
					display={"flex"}
					flexDirection={"column"}
					sx={{
						marginTop: "30px",
						justifyContent: "left",
						marginLeft: 0,
						paddingLeft: 0,
						width: "100%",
						height: "350px",
						overflow: "auto",
					}}
				>
					<CustomInputLabel name="고객사명" required={true} />
					<CustomInput
						type={"text"}
						onChange={(event) => setCompanyNameKorean(event.target.value)}
						values={companyNameKorean}
						placeholder="고객사명을 입력해주세요."
						sx={{
							width: "850px",
							border: errors.includes("companyNameKorean")
								? "2px solid red"
								: "none",
						}}
					/>
					<br />

					<CustomInputLabel name="사업자등록번호" required={true} />
					<CustomInput
						type={"text"}
						onChange={(event) =>
							setCompanyRegistrationNumber(event.target.value)
						}
						values={companyRegistrationNumber}
						placeholder="사업자등록번호를 입력해주세요."
						sx={{
							width: "850px",
							border: errors.includes("companyRegistrationNumber")
								? "2px solid red"
								: "none",
						}}
					/>
					<br />

					<CustomInputLabel name="대표자명" required={true} />
					<CustomInput
						type={"text"}
						onChange={(event) => setCustomerName(event.target.value)}
						values={customerName}
						placeholder="대표자명을 입력해주세요."
						sx={{
							width: "850px",
							border: errors.includes("customerName")
								? "2px solid red"
								: "none",
						}}
					/>
					<br />

					<CustomInputLabel name="사업업태" required={true} />
					<CustomInput
						type={"text"}
						onChange={(event) => setBusinessCondition(event.target.value)}
						values={businessCondition}
						placeholder="사업 업태를 입력해주세요."
						sx={{
							width: "850px",
							border: errors.includes("businessCondition")
								? "2px solid red"
								: "none",
						}}
					/>
					<br />

					<CustomInputLabel name="사업종목" required={true} />
					<CustomInput
						type={"text"}
						onChange={(event) => setBusinessItem(event.target.value)}
						values={businessItem}
						placeholder="사업종목을 입력하세요."
						sx={{
							width: "850px",
							border: errors.includes("businessItem")
								? "2px solid red"
								: "none",
						}}
					/>
					<br />

					{/* scroll starts */}
					<CustomInputLabel name="전화번호" required={true} />
					<CustomInput
						type={"tel"}
						onChange={(event) => handlePhoneNumber(event.target.value)}
						value={phoneNumber}
						placeholder="전화번호를 입력하세요."
						sx={{
							width: "850px",
							border: errors.includes("phoneNumber") ? "2px solid red" : "none",
						}}
					/>
					<br />

					<CustomInputLabel name="담당자 핸드폰번호" required={true} />
					<CustomInput
						type={"text"}
						onChange={(event) => handleMobileNumber(event.target.value)}
						value={mobileNumber}
						placeholder="핸드폰번호를 입력하세요."
						sx={{
							width: "850px",
							border: errors.includes("mobileNumber")
								? "2px solid red"
								: "none",
						}}
					/>
					<br />

					<CustomInputLabel name="팩스번호" />
					<CustomInput
						type={"tel"}
						onChange={(event) => handleFaxNumber(event.target.value)}
						value={faxNumber}
						placeholder="팩스번호를 입력하세요."
						sx={{
							width: "850px",
						}}
					/>
					<br />

					<CustomInputLabel name="담당자 이름" required={true} />
					<CustomInput
						type={"text"}
						onChange={(event) => setContactName(event.target.value)}
						values={contactName}
						placeholder="담당자 이름을 입력하세요."
						sx={{
							width: "850px",
							border: errors.includes("contactName") ? "2px solid red" : "none",
						}}
					/>
					<br />

					<Button
						variant="contained"
						onClick={() => {
							if (!evaluateFields()) {
								setSubmittedValues({
									...submittedValues,
									company_name_kr: companyNameKorean,
									company_registration_number: companyRegistrationNumber,
									customer_name: customerName,
									business_condition: businessCondition,
									business_item: businessItem,
									phone_number: phoneNumber,
									mobile_number: mobileNumber,
									fax_number: faxNumber,
									contact_name: contactName,
								});
								setActiveStep(2);
							}
						}}
						sx={{
							paddingTop: "12px",
							paddingBottom: "12px",
							marginTop: "51px",
							backgroundColor: "#427DF3",
							borderRadius: "8px",
							width: "350px",
							height: "60px",
						}}
					>
						{loading ? (
							<CircularProgress size={20} color="primary" />
						) : (
							<Typography
								component="p"
								variant="p"
								sx={{
									fontSize: "16px",
									fontWeight: "700",
									lineHeight: "24px",
									color: "#ffffff",
								}}
							>
								다음
							</Typography>
						)}
					</Button>
				</Box>
				<Box sx={{}}>
					<Typography
						component="p"
						variant="button"
						onClick={() => {
							changeForm("LoginForm");
						}}
						sx={{
							fontSize: "16px",
							fontWeight: "600",
							lineHeight: "20px",
							color: "#427DF3",
							marginTop: "24px",
							textAlign: "center",
						}}
					>
						{"로그인 페이지로 돌아가기"}
					</Typography>
				</Box>
			</Box>
		);
	};

	const Completed = ({ changeForm, handleNext }) => {
		return (
			<Box sx={{}}>
				<Box component="form" noValidate onSubmit={handleSubmit}>
					<Formik
						initialValues={{}}
						onSubmit={handleSubmit}
						validationSchema={{}}
					>
						{({ values, handleChange, errors, handleSubmit }) => {
							return (
								<Form onSubmit={handleSubmit}>
									<Box
										display={"flex"}
										flexDirection={"column"}
										sx={{
											marginTop: "30px",
											justifyContent: "left",
											marginLeft: 0,
											paddingLeft: 0,
											width: "100%",
											height: "350px",
											overflow: "hidden",
										}}
									>
										<Typography
											sx={{
												fontSize: "24px",
												fontWeight: "700",
												lineHeight: "32px",
												color: "#427DF3",
												marginTop: "24px",
												textAlign: "center",
											}}
										>
											가입하기
										</Typography>
										<img
											src={Images.AlertSuccess}
											style={{
												maxWidth: "183px",
												maxHeight: "183px",
												alignSelf: "center",
											}}
											alt="img"
										/>
										<Typography
											component="p"
											variant="p"
											sx={{
												fontSize: "24px",
												fontWeight: "700",
												lineHeight: "31px",
												color: "#141416",
												marginTop: "60px",
												alignSelf: "center",
											}}
										>
											계정이 성공적으로 생성되었습니다.
										</Typography>
									</Box>
									<Box
										xs={12}
										md={6}
										sm={12}
										display="flex"
										flexDirection="row"
										justifyContent="center"
										sx={{ width: "100%" }}
									>
										<Button
											variant="contained"
											onClick={() => {
												changeForm("LoginForm");
											}}
											sx={{
												paddingTop: "15px",
												paddingBottom: "15px",
												marginTop: "51px",
												backgroundColor: "#427DF3",
												borderRadius: "8px",
												width: "400px",
												height: "64px",
											}}
										>
											{loading ? (
												<CircularProgress size={20} color="primary" />
											) : (
												<Typography
													component="p"
													variant="p"
													sx={{
														fontSize: "16px",
														fontWeight: "700",
														lineHeight: "24px",
														color: "#ffffff",
													}}
												>
													대시보드로 이동
												</Typography>
											)}
										</Button>
									</Box>
								</Form>
							);
						}}
					</Formik>
				</Box>
			</Box>
		);
	};

	const Connector = () => {
		return (
			<Typography sx={{ paddingBottom: "25px" }}>
				...................
			</Typography>
		);
	};

	return (
		<box>
			<Box
				sx={{
					maxWidth: "478px",
					paddingRight: 12,
					paddingLeft: 12,
				}}
				component="form"
				noValidate
				onSubmit={handleSubmit}
			>
				<Typography
					sx={{
						fontSize: "24px",
						fontWeight: "700",
						lineHeight: "32px",
						color: "#427DF3",
						marginTop: "24px",
						textAlign: "center",
					}}
				>
					회원가입
				</Typography>
				<Stepper
					activeStep={activeStep}
					connector={<Connector />}
					sx={{
						alignContent: "center",
						justifyContent: "center",
					}}
				>
					{steps.map((label, index) => (
						<Step key={label} completed={completed[index]}>
							<br />
							<StepButton
								color="inherit"
								// onClick={handleStep(index)}
							></StepButton>
							<Typography
								sx={{
									fontSize: "12px",
									fontWeight: "600",

									textAlign: "center",
									marginTop: "16px",
								}}
							>
								{label}
							</Typography>
						</Step>
					))}
				</Stepper>
				<div>
					{allStepsCompleted() ? (
						<React.Fragment>
							<Typography sx={{ mt: 2, mb: 1 }}>
								All steps completed - you&apos;re finished
							</Typography>
							<Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
								<Box sx={{ flex: "1 1 auto" }} />
								<Button onClick={handleReset}>Reset</Button>
							</Box>
						</React.Fragment>
					) : (
						<React.Fragment>
							<Formik
								initialValues={{}}
								onSubmit={handleSubmit}
								validationSchema={{}}
							>
								{({ values, handleChange, errors, handleSubmit, Formik }) => {
									return (
										<Box>
											{activeStep === 0 ? (
												<Step1 />
											) : activeStep === 1 ? (
												<Step2 />
											) : activeStep === 2 ? (
												<Step3
													changeForm={changeForm}
													submittedValues={submittedValues}
												/>
											) : null}
										</Box>
									);
								}}
							</Formik>
						</React.Fragment>
					)}
				</div>
			</Box>
		</box>
	);
}
