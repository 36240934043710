import * as React from "react";
import { Box, Grid } from "@mui/material";
import Stepper from "@mui/material/Stepper";
import { useState } from "react";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CustomInputLabel from "./CustomInputLabel";
import CustomPasswordInput from "./CustomPasswordInput";
import Link from "@mui/material/Link";

import { Formik, Form, yupToFormErrors } from "formik";
import * as Yup from "yup";
import OutlinedInput from "@mui/material/OutlinedInput";
import CheckCircleSharpIcon from "@mui/icons-material/CheckCircleSharp";
import Images from "../Common/Images";
import OtpComp from "./OTP";
import { CircularProgress } from "@mui/material";
import CustomInput from "./CustomInput";
// import OtpInput from 'react-otp-input';
import OTPInput, { ResendOTP } from "otp-input-react";

const steps = ["1 단계", "2 단계", "3 단계"];

export default function CompleteScreen({heading,buttonText}) {
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});
  const [loading, setLoading] = useState(false);
  const [otpError, setOtpError] = useState("");
  var otp = "";

  return (
    <box>
      <Box
        sx={{
          maxWidth: "478px",
          paddingRight: 12,
          paddingLeft: 12,
          textAlign: "center",
        }}
      >
        <Typography
          sx={{
            fontSize: "24px",
            fontWeight: "700",
            lineHeight: "32px",
            color: "#427DF3",
            marginTop: "24px",
            textAlign: "center",
          }}
        >
          회원가입
        </Typography>

        <img
          style={{ marginTop: "120px" }}
          src="assets/images/complete.png"
        ></img>
        <Typography
          component="p"
          variant="p"
          sx={{
            fontSize: "24px",
            fontWeight: "700",
            lineHeight: "31px",
            color: "#141416",
            marginTop: "60px",
            alignSelf: "center",
          }}
        >
          {heading ? heading : `새 비밀번호가 \n 성공적으로 설정되었습니다.`}

          
        </Typography>

        <Box
                    xs={12}
                    md={6}
                    sm={12}
                    display="flex"
                    flexDirection="row"
                    justifyContent="center"
                    sx={{ width: "100%" }}
                  >
                    <Link href="/login">
                    <Button
                      variant="contained"
                     
                      sx={{
                        paddingTop: "15px",
                        paddingBottom: "15px",
                        marginTop: "51px",
                        backgroundColor: "#427DF3",
                        borderRadius: "8px",
                        width: "400px",
                        height: "64px",
                      }}
                    >
                      {loading ? (
                        <CircularProgress size={20} color="primary" />
                      ) : (
                        <Typography
                          component="p"
                          variant="p"
                          sx={{
                            fontSize: "16px",
                            fontWeight: "700",
                            lineHeight: "24px",
                            color: "#ffffff",
                          }}
                        >
                          {buttonText ? buttonText : "로그인 페이지로 이동"}
                      
                        </Typography>
                      )}
                    </Button>
                    </Link>
                  </Box>
      </Box>
    </box>
  );
}
