import { APIClient } from "../../../../utils/apiGraphql";
import { gql } from "@apollo/client/core";
import Moment from "moment";

//Get all rates management
export const getFilteredTransaction = async (filter, additionalValues) => {
	let finalFilter = {};
	if (filter !== null) {
		const query = { ...(additionalValues && additionalValues) };
		Object.keys(filter).forEach((res) => {
			const value = filter[res];
			if (value) {
				if (res === "createdAt") {
					const [startDate, endDate] = value.split(" - ");
					query[res] = {
						gte: Moment(startDate).utc(true).toDate(),
						lte: Moment(endDate).utc(true).toDate(),
					};
				} else {
					query[res] = value;
				}
			}
		});
		finalFilter = query;
	}
	const Query = gql(`
      query allTransactions($filter: TransactionsFilterInput!){
        allTransactions(filter: $filter){
            id
            user{
              company_name_kr
            }
            deposit_processing_date
            amount
            type
            leftBalance
            memo
            shippingFeeDeductionHistory{
              id
              shipping_number
              shipping_fee
            }
            shipping_number
            reason
        }
      }
      `);

	const res = await APIClient.query({
		query: Query,
		variables: {
			filter: finalFilter,
		},
	});
	return res;
};
