import { Stack, Typography } from "@mui/material";
import React, { useEffect, useRef } from "react";
import { Formik } from "formik";
import UpdateHOC from "../../SharedComponents/UpdateHOC";
import NumberSubmit from "../../components/shipping-processing/NumberSubmit";
import ResultMessage from "../../components/shipping-processing/ResultMessage";
import RequestInfo from "../../components/shipping-processing/RequestInfo";
import BagInfo from "../../components/shipping-processing/BagInfo";
import WeightInfo from "../../components/shipping-processing/WeightInfo";

export default function ShippingProcessing(props) {
  return React.createElement(UpdateHOC, {
    children: ShippingProcessingContainer,
    _resource: "RequestShipping",
    ...props,
  });
}

function ShippingProcessingContainer({ ...hocProps }) {
  const numberInputRef = useRef(null);

  useEffect(() => {
    const keydown = (event) => {
      if (document?.activeElement?.tagName !== "INPUT") {
        numberInputRef.current.focus();
      }
    };
    document.addEventListener("keydown", keydown);
    return () => {
      document.removeEventListener("keydown", keydown);
    };
  }, []);

  return (
    <Formik>
      <Stack spacing="40px" style={{ maxWidth: "1200px" }}>
        <Stack spacing={"20px"}>
          <Typography>배송번호 조회 및 BAG 담기</Typography>
          <NumberSubmit inputRef={numberInputRef} />
        </Stack>
        <Stack spacing={"20px"}>
          <Typography>배송 접수 처리</Typography>
          <ResultMessage />
        </Stack>

        <Stack spacing={"20px"}>
          <Typography>접수 정보</Typography>
          <RequestInfo />
        </Stack>

        <Stack spacing={"20px"}>
          <Typography>무게 정보</Typography>
          <WeightInfo />
        </Stack>

        <Stack spacing={"20px"}>
          <Typography>BAG 정보</Typography>
          <BagInfo />
        </Stack>
      </Stack>
    </Formik>
  );
}
