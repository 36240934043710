import { Button, FormHelperText } from "@mui/material";
import LinkIcon from "@mui/icons-material/Link";
import React, { useState } from "react";
import styled from "styled-components";

export default function FileUpload({
	label = "파일 선택",
	onChange,
	helperText,
	error,
	setFile,
	file,
	disabled,
	
}) {
	const inputRef = React.useRef();
	const [fileName,setFileName]=useState("")
	console.log({ file });
	return (
		<Flex>
			<Wrapper>
				<Button
					style={{ color: "#5B8FF7" }}
					startIcon={<LinkIcon />}
					onClick={() => {
						inputRef.current.click();
					}}
				>
					{label}
				</Button>
				<FormHelperText error={error}>{helperText}</FormHelperText>

				<input
					type={"file"}
					ref={inputRef}
					hidden
					onChange={({ target: { files } }) => {
						const [file] = files;
						onChange && onChange(file);
					
						setFileName(file.name)
						// setFile(file);
					}}
					disabled={disabled}
				/>
			</Wrapper>
		
			{fileName && <Span style={{ marginLeft: "10px" }}>{fileName}</Span>}
		</Flex>
	);
}

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	margin-left: 10px;
`;
const Flex = styled.div`
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
`;
const Span = styled.div`
	font-size: 12px;
	color: #93a7cd;
	margin-left: 15px;
`;
