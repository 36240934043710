import writeXlsxFile from "write-excel-file";
import { gql } from "@apollo/client";
import { APIClient } from "../../../../utils/apiGraphql";

const getlength = (number) => number.toString().length;

const getData =
    gql(`
        query allMasterManagements(
        $page: Float
        $perPage: Float
        $filter: MasterManagementFilterInput
        ) {
        items: allMasterManagements(
            page: $page
            perPage: $perPage
            filter: $filter
        ) {
            id
            masterNumber

            bags {
            id
            name
            shippingCountry {
                id
                __typename
            }
            deliveryType {
                id
                __typename
            }
            deliveryService {
                id
                shippingType
                apiType
                shippingWeight
                shippingTypeId
                createdAt
                updatedAt
                __typename
            }
            masters {
                id
                __typename
            }
            howToPayCustomDuties
            dgPackagingManagementCode
            numberOfUses
            receptionLists {
                id
                shippingNumber
                trackingNumber
                user {
                    id
                    userID
                    customer_name
                    phone_number
                    address_1
                    address_2
                    code
                    english_address_state
                    english_address_city
                    english_address_street
                    english_address_detail
                    english_address_full_address
                    english_address_zip_code
                    email
                }
                productInformation {
                    productInformation {
                        id
                        productName
                        quantity
                        unitPrice
                        sku
                        hscode
                    }
                    currency
                    totalPriceAmount
                }
                payeeInformation {
                    euioss
                    name
                    phoneNumber1
                    phoneNumber2
                    email
                    zipCode
                    states
                    cities
                    streetAddress1
                    streetAddress2
                    shippingCountry {
                        id
                        name
                        code
                    }
                }
                __typename
            }
            weight
            usedWeight
            updatedAt
            createdAt
            __typename
            }
            totalBags
            totalRequests
            deleted
            createdAt
            updatedAt
            __typename
        }
        total: _allMasterManagementsMeta(
            page: $page
            perPage: $perPage
            filter: $filter
        ) {
            count
            __typename
        }
        }`
    );

//Export xlsx with multiple table in one sheet
const exportUAServiceXlsx = async (ids) => {
    //First fetch the desired data for the excel sheets using the ids
    let data = [];

  /**
   * Creating header
   */
  let header = [
    {
      value: "배송번호",
      backgroundColor: "#f1d8be",
      align: "center",
    },
    {
      value: "Sender type",
      backgroundColor: "#f1d8be",
      align: "center",
    },
    {
      value: "Sender full name",
      backgroundColor: "#f1d8be",
      align: "center",
    },
    {
      value: "Sender contact name",
      backgroundColor: "#f1d8be",
      align: "center",
    },
    {
      value: "Sender phones",
      backgroundColor: "#f1d8be",
      align: "center",
    },
    {
      value: "Sender email",
      backgroundColor: "#f1d8be",
      align: "center",
    },
    {
      value: "Sender postcode",
      backgroundColor: "#f1d8be",
      align: "center",
    },
    {
      value: "Sender country",
      backgroundColor: "#f1d8be",
      align: "center",
    },
    {
      value: "Sender region",
      backgroundColor: "#f1d8be",
      align: "center",
    },
    {
      value: "Sender city",
      backgroundColor: "#f1d8be",
      align: "center",
    },
    {
      value: "Sender address",
      backgroundColor: "#f1d8be",
      align: "center",
    },
    {
      value: "Sender warehouse owner",
      backgroundColor: "#f1d8be",
      align: "center",
    },
    {
      value: "Sender warehouse",
      backgroundColor: "#f1d8be",
      align: "center",
    },
    {
      value: "Receiver type",
      backgroundColor: "#f1d8be",
      align: "center",
    },
    {
      value: "Receiver full name",
      backgroundColor: "#f1d8be",
      align: "center",
    },
    {
      value: "Receiver contact name",
      backgroundColor: "#f1d8be",
      align: "center",
    },
    {
      value: "Receiver phones",
      backgroundColor: "#f1d8be",
      align: "center",
    },
    {
      value: "Receiver email",
      backgroundColor: "#f1d8be",
      align: "center",
    },
    {
      value: "Receiver postcode",
      backgroundColor: "#f1d8be",
      align: "center",
    },
    {
      value: "Receiver country",
      backgroundColor: "#f1d8be",
      align: "center",
    },
    {
      value: "Receiver region",
      backgroundColor: "#f1d8be",
      align: "center",
    },
    {
      value: "Receiver city",
      backgroundColor: "#f1d8be",
      align: "center",
    },
    {
      value: "Receiver address",
      backgroundColor: "#f1d8be",
      align: "center",
    },
    {
      value: "Receiver warehouse owner",
      backgroundColor: "#f1d8be",
      align: "center",
    },
    {
      value: "Receiver warehouse",
      backgroundColor: "#f1d8be",
      align: "center",
    },
    {
      value: "Invoice description",
      backgroundColor: "#f1d8be",
      align: "center",
    },
    {
      value: "Full description",
      backgroundColor: "#f1d8be",
      align: "center",
    },
    {
      value: "Place description",
      backgroundColor: "#f1d8be",
      align: "center",
    },
    {
      value: "Actual weight, kg",
      backgroundColor: "#f1d8be",
      align: "center",
    },
    {
      value: "Related order",
      backgroundColor: "#f1d8be",
      align: "center",
    },
    {
      value: "Receiver postcode",
      backgroundColor: "#f1d8be",
      align: "center",
    },
    {
      value: "Receiver country",
      backgroundColor: "#f1d8be",
      align: "center",
    },
    {
      value: "Receiver region",
      backgroundColor: "#f1d8be",
      align: "center",
    },
    {
      value: "Receiver city",
      backgroundColor: "#f1d8be",
      align: "center",
    },
    {
      value: "Receiver address",
      backgroundColor: "#f1d8be",
      align: "center",
    }
  ];
  data.push(header);

    const res = await APIClient.query({
        query: getData,
        variables: {
            filter: {
                id: ids[0]
            }
        },
    });

    console.log("response",res);
    

    if(res.data && res.data.items) {
        // if(res.data.items.length == 1) {
            const value = res.data.items[0];
            let masterNumber = "";
            let masterCreatedAt = "";
            let uploadQuantity = 0;

            for(let i=0; i < value.bags.length; i++) {
                const bag = value.bags[i];
                
                const totalWeight = bag.usedWeight;
                masterNumber = value.masterNumber;
                masterCreatedAt = value.createdAt;
                

                const receptionLists = bag.receptionLists;
                
                receptionLists.forEach((receptionList) => {
                    
                    uploadQuantity++;
                    let data1 = [];

                    data1.push({value: receptionList.shippingNumber, align: "center"}); //Parcel ID === Shipping number (A)
                    data1.push({value: "1", align: "center"}); //Sender Type (B)
                    data1.push({value: receptionList.payeeInformation.name, align: "center"}); //Sender full name (C)
                    data1.push({value: receptionList.payeeInformation.name, align: "center"}); //Sender contact name (D)
                    data1.push({value: receptionList.payeeInformation.phoneNumber1+","+receptionList.payeeInformation.phoneNumber2, align: "center"}); //Sender phones (E)
                    data1.push({value: receptionList.payeeInformation.email, align: "center"}); //Sender email (F)
                    data1.push({value: receptionList.payeeInformation.zipCode, align: "center"}); //Sender postal (G)
                    data1.push({value: receptionList.payeeInformation.shippingCountry.code, align: "center"}); //Sender country (H)
                    data1.push({value: receptionList.payeeInformation.states, align: "center"}); //Sender region (I)
                    data1.push({value: receptionList.payeeInformation.cities, align: "center"}); //Sender cities (J)
                    data1.push({value: receptionList.payeeInformation.streetAddress1+","+receptionList.payeeInformation.streetAddress2, align: "center"}); //Sender full address (K)
                    data1.push({value: "", align: "center"}); //Sender warehouse ownser (L)
                    data1.push({value: "", align: "center"}); //Sender warehouse (M)
                    data1.push({value: "1", align: "center"}); //Reciever Type (N)

                    data1.push({value: receptionList.user.customer_name, align: "center"}); //Reciever full name (O)
                    data1.push({value: receptionList.user.customer_name, align: "center"}); //Reciever contactl name (P)
                    data1.push({value: receptionList.user.phone_number, align: "center"}); //Reciever phones (Q)
                    data1.push({value: receptionList.user.email, align: "center"}); //Reciever email (R)
                    data1.push({value: receptionList.user.code, align: "center"}); //Reciever post code (S)
                    data1.push({value: "", align: "center"}); //Reciever country (T)
                    data1.push({value: receptionList.user.english_address_state, align: "center"}); //Reciever region (U)
                    data1.push({value: receptionList.user.english_address_city, align: "center"}); //Reciever city (V)
                    data1.push({value: receptionList.user.address_1+","+receptionList.user.address_2, align: "center"}); //Reciever address (W)
                    data1.push({value: "", align: "center"}); //Reciever warehouse owner (X)
                    data1.push({value: "", align: "center"}); //Reciever warehouse (Y)

                    let invoiceDescription = "";
                    let fullDescription = "";

                    if(receptionList.productInformation && receptionList.productInformation.productInformation) {
                      const productInfos = receptionList.productInformation.productInformation;
                      const currency = receptionList.productInformation.currency;
                      

                      productInfos.map((pInfo) => {
                        invoiceDescription = invoiceDescription === "" ? "" : " , "+(pInfo.productName+" "+pInfo.quantity+" "+currency+pInfo.unitPrice);
                        fullDescription = fullDescription === "" ? "" : " , "+(pInfo.productName+" "+pInfo.quantity);
                      })
                    }

                    data1.push({value: invoiceDescription, align: "center"}); //Invoice description (Z)
                    data1.push({value: fullDescription, align: "center"}); //Full description (AA)

                    let actualWeight = totalWeight.toFixed(2);

                    data1.push({value: "10x10x10 "+actualWeight+"kg", align: "center"}); //Place description (AB)

                    actualWeight = actualWeight.toString();
                    actualWeight = actualWeight.replace(".",",");

                    data1.push({value: actualWeight, align: "center"}); //Actual Weight (AC)
                    data1.push({ value: receptionList.shippingNumber, align: "center"}); //Related order (AD)
                    data1.push({value: receptionList.user.code, align: "center"}); //Reciever post code (AE)
                    data1.push({value: "", align: "center"}); //Reciever country (AF)
                    data1.push({value: receptionList.user.english_address_state, align: "center"}); //Reciever region (AG)
                    data1.push({value: receptionList.user.english_address_city, align: "center"}); //Reciever city (AH)
                    data1.push({value: receptionList.user.address_1+","+receptionList.user.address_2, align: "center"}); //Reciever address (AI)

                    data.push(data1);
                })
            }

            /**
                * Columns
            */
            const columns = [];
            [...Array(23)].forEach((d) => {
                columns.push({
                width: 20,
                });
            });

            let uploadQuantityString = "";
            if(getlength(uploadQuantity) === 1) {
                uploadQuantityString = "00"+(uploadQuantity.toString());
            }
            else if(getlength(uploadQuantity) === 2) {
                uploadQuantityString = "0"+(uploadQuantity.toString());
            }
            else {
                uploadQuantityString = (uploadQuantity.toString());
            }

            writeXlsxFile(data, {
                columns,
                fileName: `H3_UA_SERVICE_Match_${uploadQuantityString}_${masterNumber}_${masterCreatedAt}.xlsx`,
            });
            console.log(data);
        // }
    }


};

export default exportUAServiceXlsx;