import CustomInput from "../../components/CustomInput";
import { ALL_BAG } from "./queries";
import { nodeBagIdAtom } from "./atom";
import { MenuItem } from "@mui/material";

import { SelectBox } from "../../components/CustomInput";

import { useAtom } from "jotai";
import { useQuery } from "@apollo/client";

const BagInfoBagSelection = () => {
  const [_, setNodeBagId] = useAtom(nodeBagIdAtom);
  const { data } = useQuery(ALL_BAG, {
    fetchPolicy: "cache-and-network",
    variables: { filter: { isActive: true } },
  });
  const onChange = (e) => {
    const { value } = e.target;
    setNodeBagId(value);
  };
  return (
    <CustomInput
      input={
        <SelectBox
          name={"bag"}
          onChange={onChange}
          styles={{ width: 316, marginLeft: "10px" }}
        >
          {data?.items?.map((item, index) => {
            return (
              <MenuItem key={item.id} value={item.id}>
                {item.name}
              </MenuItem>
            );
          })}
        </SelectBox>
      }
      label="BAG 선택"
      padding="14px 20px 10px 20px"
      labelWidth="20%"
    />
  );
};

export default BagInfoBagSelection;
