import writeXlsxFile from "write-excel-file";
import statusSwitch from "../../../../utils/statusSwitch";
import React, { useState } from "react";
import { CustomMasterQuery } from "../queries";
import moment from "moment";

const DetailedExport = async (ids, masterData) => {
	const master = masterData[0];
	// console.log({ master });
	let allReceptions = [];

	master.bags?.forEach((bag, index) =>
		bag.receptionLists?.forEach((reception) => allReceptions.push(reception))
	);
	console.log({ allReceptions });

	const calculateTotal = (products) => {
		let total = 0;
		products.forEach((item) => {
			let product = parseInt(item.quantity) * parseInt(item.unitPrice);
			total = total + product;
		});
		return total;
	};

	const masterNumber = masterData[0].masterNumber;
	let data = [];

	let header = [
		{
			value: "특이사항(메모)",
			align: "center",
		},
		{
			value: "번호",

			align: "center",
		},
		{
			value: "운송장 출력",

			align: "center",
		},
		{
			value: "타입",

			align: "center",
		},
		{
			value: "고객사 이름",

			align: "center",
		},
		{
			value: "배송국가",

			align: "center",
		},
		{
			value: "배송종류",

			align: "center",
		},
		{
			value: "관세지불방식",

			align: "center",
		},
		{
			value: "상태",

			align: "center",
		},
		{
			value: "접수일",

			align: "center",
		},
		{
			value: "출고일",

			align: "center",
		},
		{
			value: "접수번호",

			align: "center",
		},
		{
			value: "배송번호",

			align: "center",
		},
		{
			value: "적용무게(KG)",

			align: "center",
		},
		{
			value: "출고가격무게(KG)",

			align: "center",
		},
		{
			value: "Master No",

			align: "center",
		},
		{
			value: "SUB MASTER",

			align: "center",
		},
		{
			value: "주문번호1",

			align: "center",
		},
		{
			value: "주문번호2",

			align: "center",
		},
		{
			value: "배송회사",

			align: "center",
		},
		{
			value: "CUSTOM NUMBER",

			align: "center",
		},
		{
			value: "출발국가 택배번호",

			align: "center",
		},
		{
			value: "배송비 ",

			align: "center",
		},
		{
			value: "택배 추가요금",

			align: "center",
		},
		{
			value: "화폐 ",

			align: "center",
		},
		{
			value: "가로(Cm)",

			align: "center",
		},

		{
			value: "세로(Cm)",

			align: "center",
		},
		{
			value: "높이(Cm)",

			align: "center",
		},
		{
			value: "Box 수량",

			align: "center",
		},
		{
			value: "실제무게(KG)",

			align: "center",
		},
		{
			value: "부피무게(KG)",

			align: "center",
		},
		{
			value: "적용무게(KG) ",

			align: "center",
		},
		{
			value: "주민번호/통관번호/CPF",

			align: "center",
		},
		{
			value: "IOSS",

			align: "center",
		},
		{
			value: "EORI",

			align: "center",
		},
		{
			value: "VOEC",

			align: "center",
		},
		{
			value: "상품 바코드",

			align: "center",
		},
		{
			value: "상품목록",

			align: "center",
		},
		{
			value: "상품수량",

			align: "center",
		},
		{
			value: "총 상품금액",

			align: "center",
		},
		{
			value: "화폐단위",

			align: "center",
		},
		{
			value: "발송인 이름",

			align: "center",
		},
		{
			value: "발송인 전화번호",

			align: "center",
		},
		{
			value: "발송인 주소",

			align: "center",
		},
		{
			value: "수취인 이름",

			align: "center",
		},
		{
			value: "수취인 이름 (YOMIGANA)",

			align: "center",
		},
		{
			value: "수취인 전화번호",

			align: "center",
		},
		{
			value: "수취인 휴대폰 번호",

			align: "center",
		},
		{
			value: "수취인 이메일",

			align: "center",
		},
		{
			value: "수취인 우편번호",

			align: "center",
		},
		{
			value: "Colony",

			align: "center",
		},
		{
			value: "주",

			align: "center",
		},
		{
			value: "도시",

			align: "center",
		},
		{
			value: "수취인 주소1",

			align: "center",
		},
		{
			value: "수취인 주소2",

			align: "center",
		},
		{
			value: "배송메세지",
			align: "center",
		},
		{
			value: "HSCODE",

			align: "center",
		},
		{
			value: "상품종류(1:Commercial,2:Gift,3:ReturnedGood,4:Documents,5:Other)",

			align: "center",
		},
		{
			value: "통관구분",

			align: "center",
		},
		{
			value: "수출신고 유형",

			align: "center",
		},
		{
			value: "판매 site",

			align: "center",
		},
		{
			value: "사용자 데이터 1",

			align: "center",
		},
		{
			value: "사용자 데이터 2",

			align: "center",
		},
		{
			value: "사용자 데이터 3",

			align: "center",
		},
	];
	data.push(header);

	const columns = [];
	[...Array(40)].forEach((d) => {
		columns.push({
			width: 30,
		});
	});

	//columnData
	let receptionList = [];
	allReceptions.forEach((item, index) => {
		let reception = [];
		var productNames = [];
		var productQuantity = [];
		var productHscode = [];
		var productPurchaseUrl = [];
		var productBarcode = [];
		var totalQuantity = 0;
		item.productInformation.productInformation.forEach((subItem) => {
			totalQuantity =
				totalQuantity + subItem.quantity ? parseInt(subItem.quantity) : 0;
			subItem.productName && productNames.push(`${subItem.productName}`);
			subItem.hscode && productHscode.push(`${subItem.hscode}`);
			subItem.quantity && productQuantity.push(`${subItem.quantity}`);
			subItem.purchaseUrl && productPurchaseUrl.push(`${subItem.purchaseURL}`);
			subItem.barcode && productBarcode.push(`${subItem.barcode}`);
		});

		reception.push({
			value:
				item.miscellaneousInformation?.coupon &&
				`고객사 쿠폰 적용(${item.miscellaneousInformation.coupon})`,
			align: "center",
		});
		reception.push({ value: index + 1, align: "center" });
		reception.push({
			value: item.waybillPrintStatus ? "O" : "X",
			align: "center",
		});
		reception.push({
			value: "일반",
			align: "center",
		});
		reception.push({
			value: item.user?.company_name_kr,
			align: "center",
		});
		reception.push({
			value: item.payeeInformation?.shippingCountry.code,
			align: "center",
		});
		reception.push({
			value: item.shippingType?.shippingType,
			align: "center",
		});
		reception.push({ value: item.bag?.howToPayCustomDuties, align: "center" });
		reception.push({ value: statusSwitch(item.status), align: "center" });
		reception.push({
			value: item.createdAt ? moment(item.createdAt).format("YYYY-MM-DD") : "",
			align: "center",
		});
		reception.push({
			value: item.processReleaseDate
				? moment(item.processReleaseDate).format("YYYY-MM-DD")
				: "",
			align: "center",
		});
		reception.push({
			value: item.trackingNumber,
			align: "center",
		});
		reception.push({ value: item.shippingNumber, align: "center" });
		reception.push({
			value: item.miscellaneousInformation?.applicableWeight,
			align: "center",
		});
		reception.push({ value: item.feeDeductionWeight, align: "center" });
		reception.push({ value: item.masterNo, align: "center" });
		reception.push({ value: "", align: "center" });
		reception.push({
			value: item.productInformation?.orderNo1,
			align: "center",
		});
		reception.push({
			value: item.productInformation?.orderNo2,
			align: "center",
		});
		reception.push({ value: "", align: "center" });
		reception.push({ value: "", align: "center" });
		reception.push({
			value: item.productInformation?.departureCountryCourierNumber,
			align: "center",
		});
		reception.push({ value: item.deliveryFee, align: "center" });
		reception.push({ value: item.additionalFee, align: "center" });
		reception.push({ value: "KRW", align: "center" });
		reception.push({
			value: item.miscellaneousInformation?.horizontal,
			align: "center",
		});
		reception.push({
			value: item.miscellaneousInformation?.portrait,
			align: "center",
		});
		reception.push({
			value: item.miscellaneousInformation?.height,
			align: "center",
		});
		reception.push({
			value: item.miscellaneousInformation?.boxQuantity,
			align: "center",
		});
		reception.push({
			value: item.miscellaneousInformation?.actualWeight,
			align: "center",
		});
		reception.push({
			value: item.miscellaneousInformation?.bulkWeight,
			align: "center",
		});
		reception.push({
			value: item.miscellaneousInformation?.applicableWeight,
			align: "center",
		});
		reception.push({ value: item.payeeInformation?.ssn, align: "center" });
		reception.push({ value: item.payeeInformation?.euioss, align: "center" });
		reception.push({ value: item.payeeInformation?.ukeori, align: "center" });
		reception.push({ value: item.payeeInformation?.ssn, align: "center" });
		reception.push({ value: productBarcode, align: "center" });
		reception.push({ value: productNames, align: "center" });
		reception.push({ value: productQuantity, align: "center" });
		reception.push({
			value: calculateTotal(item.productInformation?.productInformation),
			align: "center",
		});
		reception.push({
			value: item.payeeInformation?.shippingCountry.monetaryUnit,
			align: "center",
		});
		reception.push({ value: item.senderInformation?.name, align: "center" });
		reception.push({
			value: item.senderInformation?.phoneNumber,
			align: "center",
		});
		reception.push({ value: item.senderInformation?.address, align: "center" });
		reception.push({ value: item.payeeInformation?.name, align: "center" });
		reception.push({ value: "", align: "center" });
		reception.push({
			value: item.payeeInformation?.phoneNumber2,
			align: "center",
		});
		reception.push({
			value: item.payeeInformation?.phoneNumber1,
			align: "center",
		});
		reception.push({ value: item.payeeInformation?.email, align: "center" });
		reception.push({ value: item.payeeInformation?.zipCode, align: "center" });
		reception.push({
			value: item.payeeInformation?.mexicoColony,
			align: "center",
		});
		reception.push({ value: item.payeeInformation?.states, align: "center" });
		reception.push({ value: item.payeeInformation?.cities, align: "center" });
		reception.push({
			value: item.payeeInformation?.streetAddress1,
			align: "center",
		});
		reception.push({
			value: item.payeeInformation?.streetAddress2,
			align: "center",
		});
		reception.push({
			value: item.miscellaneousInformation?.shippingMessage,
			align: "center",
		});
		reception.push({ value: productHscode, align: "center" });
		reception.push({
			value: item.miscellaneousInformation?.productType,
			align: "center",
		});
		reception.push({ value: "", align: "center" });
		reception.push({
			value: item.miscellaneousInformation?.exportTypeDeclaration,
			align: "center",
		});
		reception.push({ value: productPurchaseUrl, align: "center" });
		reception.push({
			value: item.miscellaneousInformation?.userData,
			align: "center",
		});
		reception.push({
			value: item.miscellaneousInformation?.userData2,
			align: "center",
		});
		reception.push({
			value: item.miscellaneousInformation?.userData3,
			align: "center",
		});
		// console.log({ reception });
		receptionList.push(reception);
		data.push(reception);
	});
	// console.log({ receptionList });

	writeXlsxFile(data, {
		columns,
		fileName: masterNumber + ".xlsx",
	});
};
export default DetailedExport;
